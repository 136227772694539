import React, { useState, useEffect, Fragment } from "react";
/**Importar configuracion axios */
import clienteAxios from "../../Config/Axios";
import { Grid } from "@material-ui/core";
import Select from "react-select";

const useSelectProductsPerBrand = (stateInicial) => {
  //state del custom hook
  const [state, actualizarstate] = useState(stateInicial.stateInicial);
  const [products, saveProducts] = useState([]);
  const id = stateInicial.branchID;
  const typeSale = stateInicial.typeSale;

  useEffect(() => {
    const getProducts = async () => {
      await clienteAxios
        .get(`/productsPerBrand/${id}/${typeSale}`)
        .then((res) => {
          saveProducts(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getProducts();
  }, [typeSale]);

  const productArray = products.map((product) => {
    return {
      key: product.id,
      value: product.id,
      label: product.tag + " - " + product.name,
    };
  });
  //console.log(productArray, 'productArray');
  const SelectProduct = () => (
    <Fragment>
      <Grid item xs={12} >
        <Select
          autofocus
          value={state}
          placeholder="Selecciona un producto"
          options={productArray}
          onChange={(value) => actualizarstate(value)}
        />
      </Grid>
    </Fragment>
  );

  return [state, SelectProduct];
};

export default useSelectProductsPerBrand;
