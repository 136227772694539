import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import Categories from "./Categories"


export default function Categoriesindex() {


  return (
    <LayoutDashboard>
<Categories/>


    </LayoutDashboard>
  );
}
