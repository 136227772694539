import React, { useEffect, useState, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Button,
  TextField,
  Dialog,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "../../Complements/Spinner";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import MethodGet from "../../Config/Services";
import { useForm } from "react-hook-form";
import ClassroomContext from "../../Reducers/Classrooms/ClassroomContext";
import GridItem from "../../Components/Grid/GridItem";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardBody from "../../Components/Card/CardBody";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    //Mantiene el margen entre la vista final a 2 espaciados de los margenes de la pantalla fisica
    //deben ser igual en dodas las vistar de card para evitar diferentes alineaciones entre contenedores
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingInlineEnd: theme.spacing(2),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  /**
   * identificar Codigo de FORMS estilo para formularios
   */
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
function Edit({ modal, handleClose, id, }) {
  const classes = useStyles();

  const formClassroomContext = useContext(ClassroomContext);

  const [cargando, spinnerCargando] = useState(false);
  const [classroom, saveClassroom] = useState({});

  const { register, errors, handleSubmit } = useForm();

  const { ErrorsAPI, success, UpdateClassroom } = formClassroomContext;

  //obtenemos el producto de session storage
  // let ClassroomStorageID = JSON.parse(sessionStorage.getItem("classroom"));

  //Trabajar con el state de office
  const [officeId, saveOffice] = useState({
    id_branch_office: "",
  });

  //extraer las propiedades del objeto Desctructuracion
  const { name, capacity } = classroom;

  useEffect(() => {
    let url = `/classrooms/${id}`;
    MethodGet(url)
      .then((res) => {
        saveClassroom(res.data.data);
        saveOffice({ id_branch_office: res.data.data.branch_office_id });
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de escuelas
    if (success) {
      handleClose();
    }
    //eslint-disable-next-line
  }, [success]);

  //Guardar los cambios del cliente
  const onSubmit = (data, e) => {
    e.preventDefault();
    data.school_id = officeId.id_branch_office;
    data.id = id;
    //console.log(data);
    if (officeId.id_branch_office !== "") {
      //enviamos la data a la funcion que se encarga de guardar los registros editados
      UpdateClassroom(data);
    } else {
      Swal.fire({
        title: "Error",
        text: "Todos los campos son Obligatorios",
        icon: "error",
      });
    }
  };

  //spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modal}
        maxWidth={1200}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ textAlign: "center", background: "#3F51B5", color: "white", }}>
          Editar salón
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden', overflowX: 'hidden' }} >
          <div onSubmit={handleSubmit(onSubmit)}>
            <form noValidate>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre del Salon"
                      name="name"
                      defaultValue={name}
                      autoComplete="name"
                      autoFocus
                      error={!!errors.classroom}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre del Salon es requerido",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 5 caracteres",
                        },
                        maxLength: {
                          value: 45,
                          message: "Maximo 45 caracteres",
                        },
                        pattern: {
                          value:
                            /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                          message: "Unicamente carácteres alfabéticos",
                        },
                      })}
                    />
                    <p>{errors?.classroom?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="capacity"
                      label="Capacidad del Salon"
                      name="capacity"
                      defaultValue={capacity}
                      autoComplete="name"
                      autoFocus
                      type="number"
                      error={!!errors.classroom}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La Capacidad del Salon es requerida",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 1 digito",
                        },
                        maxLength: {
                          value: 45,
                          message: "Maximo 45 digito",
                        },
                      })}
                    />
                    <p>{errors?.classroom?.message}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justify="center">
                  <Grid item spacing={3} xs={12} sm={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.submit}
                    >
                      ACTUALIZAR
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withRouter(Edit);