import React, { Fragment, useState, useEffect } from "react";
/**Importar configuracion axios */
import clienteAxios from "../../Config/Axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ClientsContext from "../../Reducers/Clients/ClientsContext";
import { useContext } from "react";

const animatedComponents = makeAnimated();
const useSelectStudent = (props) => {
  //state del custom hook
  const [error, guardarError] = useState(true);
  let id = props.SchoolStorageID;
  const { getstudents, clients } = useContext(ClientsContext);
  useEffect(() => {
    getstudents(id);
  }, []);
  const detectarCambiosStudent = (value) => {
    if (value.key) {
      guardarError(false);
    }
    props.detectarCambiosStudent(value);
  };
  const studentsArray = clients.map((user) => {
    return {
      key: user.id,
      value: user.id,
      label: user.name + " " + user.first_lastname + " " + user.second_lastname,
    };
  });

  return (
    <Fragment>
      {props.studentID ? (
        <Select
          autofocus
          placeholder="Estudiante"
          fullWidth
          components={animatedComponents}
          options={studentsArray}
          onChange={(value) => detectarCambiosStudent(value)}
          value={props.studentID}
        ></Select>
      ) : (
        <Select
          autofocus
          placeholder="Estudiante"
          fullWidth
          components={animatedComponents}
          options={studentsArray}
          onChange={(value) => detectarCambiosStudent(value)}
        ></Select>
      )}

      {error ? (
        <p style={{ color: "red" }}>El Estudiante es un Campo Requerido</p>
      ) : null}
    </Fragment>
  );
};

export default useSelectStudent;
