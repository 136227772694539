import React, { useState, useEffect, Fragment } from "react";
import CantidadProductos from "./CantidadProductos";
import {
  Grid,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Paper,
} from "@material-ui/core";
import MethodGet from "../../Config/Services";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  TableRow: {
    background: "indigo",
  },
  TableTxt: {
    color: "white",
    fontSize: "13pt",
    fontFamily: "arial",
    "&:hover": {
      color: "whitesmoke",
      fontSize: "14pt",
      fontFamily: "arial",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const BrandSales = (props) => {
  const {
    subtotal,
    productsBranded,
    saveProductsBranded,
    guardarTotal,
    totalDescuento,
    id,
    descuentoporcentaje,
    guardarTotalDescuento,
  } = props;

  useEffect(() => {
    actualizarTotal();
    calcularTotalDescuento();
  }, [subtotal, descuentoporcentaje, productsBranded]);

  const classes = useStyles();

  //Actualizar la cantidad de Productos
  const restarProductos = (i) => {
    //copiar el arreglo original de productos
    const todosProducto = [...productsBranded];

    //validar si esta en 0
    if (todosProducto[i].cantidad === 1) return;

    //decremento
    todosProducto[i].cantidad--;
    //final-price
    todosProducto[i].final_price =
      todosProducto[i].cantidad * todosProducto[i].price;

    //almacenarlo en el state
    saveProductsBranded(todosProducto);
  };

  const [branch, guardarBranch] = useState({});

  const calcularTotalDescuento = () => {
    let descuentoPorcentajeNew = 0;
    descuentoPorcentajeNew = descuentoporcentaje.discount;

    const descuento = (descuentoPorcentajeNew * subtotal) / 100;
    const TotalDescuento = subtotal - descuento;
    guardarTotalDescuento(Math.round(TotalDescuento),);
  };

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const aumentarProductos = (i) => {
    //copiar el arreglo para no mutar el original
    const todosProducto = [...productsBranded];

    //validar si es menor al stock actual
    if (todosProducto[i].cantidad >= productsBranded[i].quantity) return;

    //incremento
    todosProducto[i].cantidad++;

    //final-price
    todosProducto[i].final_price =
      todosProducto[i].cantidad * todosProducto[i].price;

    //almacenar en el state
    saveProductsBranded(todosProducto);
  };

  //Eliminar un producto del state
  const eliminarProductoVenta = (id) => {
    const todosProductos = productsBranded.filter(
      (venta) => venta.product_id !== id
    );
    if (todosProductos.length === 0) {
      localStorage.removeItem("EnterProducts");
    }
    saveProductsBranded(todosProductos);
  };

  //Actualizar el total a pagar
  //si el arreglo de productsBranded es igual a 0 : total es 0
  const actualizarTotal = () => {
    if (productsBranded.length === 0) {
      guardarTotal(0);
      return;
    }

    //calcular el nuevo total
    let nuevoTotal = 0;

    //recorrer todos los productos
    productsBranded.map(
      (venta) => (nuevoTotal += venta.cantidad * venta.price)
    );

    //almacenar el total
    guardarTotal(nuevoTotal);
  };

  const subtotalProduct = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(subtotal);

  const numbernew = isNaN(totalDescuento) ? 0 : totalDescuento;
  const FinalTotal =  
  new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 0,
  }).format(numbernew);

  const handleChangeQuantity = (e, i) => {
    const newQuantity = Number(e.target.value);
    const todosProducto = [...productsBranded];
    if (newQuantity > todosProducto[i].quantity) {
      Swal.fire({
        title: "Error",
        html: `<p>
          La cantidad no puede ser mayor al stock disponible, unicamente hay
          <b>
          ${todosProducto[i].quantity}
          </b>
          pzs de <b>
          ${todosProducto[i].name}  
          </b> 
           Disponibles 
          </p>`,
        icon: "error",
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }
    todosProducto[i].cantidad = newQuantity;
    todosProducto[i].final_price =
      todosProducto[i].cantidad * todosProducto[i].price;
    saveProductsBranded(todosProducto);
  };
  return (
    <>
      <Grid item xs={12}>
        <div class="scrollbar" id="style-1">
          <div class="force-overflow">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow className={classes.TableRow}>
                    <TableCell align="center" className={classes.TableTxt}>
                      Clave
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Producto
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Cantidad
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Stock
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Precio
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Total
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Opciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!productsBranded.length ? (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={6}
                        style={{
                          color: "black",
                          fontSize: "16pt",
                          fontFamily: "arial",
                        }}
                      >
                        Aún no has agregado productos
                      </TableCell>
                    </TableRow>
                  ) : (
                    productsBranded.map((venta, index) => (
                      <CantidadProductos
                        key={venta.producto}
                        venta={venta}
                        handleChangeQuantity={handleChangeQuantity}
                        restarProductos={restarProductos}
                        aumentarProductos={aumentarProductos}
                        eliminarProductoVenta={eliminarProductoVenta}
                        index={index}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Grid>
      <Grid container>
        <Fragment xs={12} md={12}>
          <Grid item xs={12} md={3}>
            <Box style={{ background: "#ececec91" }}>
              <Typography
                align="left"
                style={{ paddingLeft: 20, paddingTop: 20 }}
              >
                Subtotal
              </Typography>
              <Typography
                align="right"
                style={{
                  paddingRight: 20,
                  paddingBottom: 20,
                  marginTop: -20,
                }}
              >
                {subtotalProduct}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={5}>
            <Box style={{ background: "#ececec91" }}>
              <Typography
                align="left"
                style={{ paddingLeft: 20, paddingTop: 20 }}
              >
                Descuento
              </Typography>

              {Object.keys(descuentoporcentaje).length ? (
                <Typography
                  align="right"
                  style={{
                    paddingRight: 20,
                    paddingBottom: 20,
                    marginTop: -20,
                  }}
                >
                  {descuentoporcentaje.discount}%
                </Typography>
              ) : (
                <Typography
                variant="body2"                  align="right"
                  color="secondary"
                  style={{
                    paddingRight: 20,
                    paddingBottom: 20,
                    marginTop: -20,
                  }}
                >
                  Debes seleccionar un descuento
                </Typography>
              )}
            </Box>
          </Grid>
            <Grid xs={12} md={4}>
            <Box style={{ background: "#ececec91" }}>
              <Typography
                align="left"
                style={{ paddingLeft: 20, paddingTop: 20 }}
              >
                Total
              </Typography>
              <Typography
                align="right"
                style={{
                  paddingRight: 20,
                  paddingBottom: 20,
                  marginTop: -20,
                }}
              >
                {FinalTotal === NaN ? "$0.00" : FinalTotal}
              </Typography>
            </Box>
          </Grid>
        </Fragment>
      </Grid>
    </>
  );
};

export default BrandSales;
