import React, { useState } from "react";
import * as XLSX from "xlsx";

const ExcelReader = () => {
  const [excelData, setExcelData] = useState(null);
  const [felterout, filter] = useState("null");

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });

        // Obtener la primera hoja
        const firstSheetName = workbook.SheetNames[0];
        const firstSheet = workbook.Sheets[firstSheetName];

        // Convertir la hoja en un objeto con las llaves de la primera fila
        const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        // Obtener las llaves de la primera fila
        const keys = jsonData[0];

        // Eliminar la primera fila del array (contiene las llaves)
        jsonData.shift();

        // Convertir las filas restantes en un array de objetos usando las llaves
        const formattedData = jsonData.map((row) => {
          const obj = {};
          keys.forEach((key, index) => {
            obj[key] = row[index];
          });
          return obj;
        });

        const filter = formattedData.filter(function (objeto) {
          return objeto.cantidadsuma !== "0";
        });

        // Almacenar el array de objetos en el estado
        setExcelData(filter);
      };

      reader.readAsBinaryString(file);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
    </div>
  );
};

export default ExcelReader;
