import React, { useReducer } from 'react';
import SchoolContext from './SchoolContext'
import SchoolReducer from './SchoolReducer';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../Config/Services";
import { ADD_NEW_SCHOOL, CURRENT_SCHOOL, DELETE_SCHOOL, GET_ALL_SCHOOLS, GET_MUNICIPALITIES_SELECT,
    GET_STATES_SELECT, SHOW_ERRORS_API, UPDATE_SCHOOL, UTILITY_SCHOOL, INCOME_SCHOOL } from "../../types";
import Swal from "sweetalert2";
import headerConfig from "../../Config/imageHeaders";
import clienteAxios from '../../Config/Axios';
import fileDownload from 'js-file-download';

const SchoolState = ({children}) => {
  // estado inicial
  const initialSate = {
    schools: [],
    incomes: [],
    school: null,
    utility: {},
    states: [],
    municipalities: [],
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(SchoolReducer, initialSate);

  //obtener la lista de proveedores
  const GetSchools = () => {
    sessionStorage.removeItem('school');
    let url = `schools`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_ALL_SCHOOLS,
        payload: response.data.data,
      });
    });
  };
  
  const GetSchoolUtility = (school) => {
    let url = `schoolUtility/${school}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: UTILITY_SCHOOL,
        payload: response.data,
      });
    });
  };
  
  const GetBranchUtility = (branch) => {
    let url = `branchUtility/${branch}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: UTILITY_SCHOOL,
        payload: response.data,
      });
    });
  };
  
  const GetBranchIncomes = (branch) => {
    let url = `branchIncomes/${branch}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: INCOME_SCHOOL,
        payload: response.data.data,
      });
    });
  };

  const GenerateBranchIncome = (id) => {
      Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Desea Generar El Reporte Del Corte De Caja?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Generar',
        cancelButtonText: 'No, Cancelar',
      }).then((result) => {
        if (result.value) {
          let timerInterval;
          Swal.fire({
            title: "Generando",
            html: "El reporte se está generando",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            willClose: () => {
              clearInterval(timerInterval);
            },
          })
          clienteAxios.get(`/income-branch-report/${id}`, { responseType: 'blob' }).then(response => {
            fileDownload(response.data, 'Reporte Corte De Caja.pdf');

          }).catch(error => {
            if (error.response.status === 500) {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Por Favor, Contacta Con Soporte Técnico'
              })
            }
          });
        }
      });
    }

  const GenerateSchoolIncome = (id) => {
      Swal.fire({
        title: '¿Estás seguro?',
        text: '¿Desea Generar El Reporte Del Corte De Caja?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Generar',
        cancelButtonText: 'No, Cancelar',
      }).then((result) => {
        if (result.value) {
          let timerInterval;
          Swal.fire({
            title: "Generando",
            html: "El reporte se está generando",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            willClose: () => {
              clearInterval(timerInterval);
            },
          })
          clienteAxios.get(`/income-school-report/${id}`, { responseType: 'blob' }).then(response => {
            fileDownload(response.data, 'Reporte Corte De Caja.pdf');

          }).catch(error => {
            if (error.response.status === 500) {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Por Favor, Contacta Con Soporte Técnico'
              })
            }
          });
        }
      });
    }

  //Obtener la data para el select Estados
  const GetSelectStates = () => {
    let url = "states";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_STATES_SELECT,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Obtener la data para el select Municipio
  const GetSelectMunicipalities = (state) => {
    let url = `municipalities/${state}`;
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_MUNICIPALITIES_SELECT,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //función para crear una nueva escuela
  const AddSchool = (school) => {
    //console.log(school, image, municipality, state);
    const formData = new FormData();
		formData.append("name", school.name);
		formData.append("direction", school.direction);
		formData.append("number_phone", school.number_phone);
		formData.append("state_id", school.state_id);
		formData.append("shop_id", school.shop_id);
		formData.append("municipality_id", school.municipality_id);
		formData.append("image", school.image);
		formData.append("type", 2);
    //console.log(school.image);
    let url = "/schools";
    MethodPost(url, formData, { headerConfig })
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_SCHOOL,
        });
      })
      .catch((error) => {
        Swal.fire({
					title: 'Error',
          text: error.response.data.error,
    			icon: 'error'
				});
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //función para seleccionar un proveedor a editar
  const SaveCurrentSchool = (school) => {
    sessionStorage.setItem('school',JSON.stringify(school));
    dispatch({
      type: CURRENT_SCHOOL,
      payload: school,
    });
  };

  //función para editar un Proveedor
  const UpdateSchool = (school) => {
    const formData = new FormData();
		formData.append("name", school.name);
		formData.append("direction", school.direction);
		formData.append("number_phone", school.number_phone);
		formData.append("state_id", school.state_id);
    formData.append("shop_id", school.shop_id);
		formData.append("municipality_id", school.municipality_id);
		formData.append("image", school.image);
		formData.append("_method", "PATCH");

    let url = `schools/${school.id}`;
    MethodPost(url, formData, { headerConfig })
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_SCHOOL,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //Eliminar Proveedor por id
  const DeleteSchool = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La escuela ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `schools/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_SCHOOL,
            payload: id,
          });
        });
      }
    });
  };

  return (
    <SchoolContext.Provider
      value={{
        schools: state.schools,
        school: state.school,
        states: state.states,
        utility: state.utility,
        municipalities: state.municipalities,
        ErrorsAPI: state.ErrorsAPI,
        incomes: state.incomes,
        success: state.success,
        GetSchools,
        GenerateBranchIncome,
        GetBranchIncomes,
        GenerateSchoolIncome,
        GetBranchUtility,
        GetSchoolUtility,
        AddSchool,
        SaveCurrentSchool,
        UpdateSchool,
        DeleteSchool,
        GetSelectStates,
        GetSelectMunicipalities,
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
}

export default SchoolState;