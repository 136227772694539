import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import HomeIcon from "@material-ui/icons/Home";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CancelIcon from "@material-ui/icons/Cancel";
import StorefrontIcon from "@material-ui/icons/Storefront";
import StoreIcon from "@material-ui/icons/Store";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CategoryIcon from "@material-ui/icons/Category";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EventIcon from "@material-ui/icons/Event";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import GavelIcon from "@material-ui/icons/Gavel";
import MoneyIcon from "@material-ui/icons/Money";
import ClassIcon from "@material-ui/icons/Class";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import DevicesIcon from "@material-ui/icons/Devices";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import PaymentIcon from "@material-ui/icons/Payment";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SchoolIcon from "@material-ui/icons/School";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import MonetizationOnTwoToneIcon from "@material-ui/icons/MonetizationOnTwoTone";
import PostAddTwoToneIcon from "@material-ui/icons/PostAddTwoTone";
import PictureAsPdfTwoToneIcon from "@material-ui/icons/PictureAsPdfTwoTone";
import ReceiptIcon from "@material-ui/icons/Receipt";
import StreetviewIcon from "@material-ui/icons/Streetview";
import InsertChartOutlinedTwoToneIcon from "@material-ui/icons/InsertChartOutlinedTwoTone";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  Collapse,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 300,
    backgroundColor: "black",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  Link: {
    textDecoration: "none",
    color: "white",
  },
  subMenu: {
    backgroundColor: "#00000042",
    color: "white",
  },
  Iconwhite: {
    color: "white",
  },
}));

export default function ListItems() {
  const classes = useStyles();
  /**
   * @comment Se crean los estados iniciales para el cada menu desplegable
   * @autor DanielRM
   * @type {*}
   *
   * */
  const [openMenuEstablishments, setOpenMenuEstablishments] =
    React.useState(false);
  const subMenuOpensetOpenMenuEstablishments = () => {
    setOpenMenuEstablishments(!openMenuEstablishments);
  };

  const [openSchoolAdministration, setOpenMenuSchoolAdministration] =
    React.useState(false);
  const subMenuOpenSchoolAdministration = () => {
    setOpenMenuSchoolAdministration(!openSchoolAdministration);
  };

  const [openSubMenuSales, setOpenMenuSales] = React.useState(false);
  const subMenuOpenSales = () => {
    setOpenMenuSales(!openSubMenuSales);
  };

  const [openSubMenuProductControl, setOpenMenuProductControl] =
    React.useState(false);
  const subMenuOpenProductControl = () => {
    setOpenMenuProductControl(!openSubMenuProductControl);
  };

  const [openSubMenuGraphics, setOpenMenuGraphics] = React.useState(false);
  const subMenuOpenGraphics = () => {
    setOpenMenuGraphics(!openSubMenuGraphics);
  };

  const [openSubMenuReports, setOpenMenuReports] = React.useState(false);
  const subMenuOpenReports = () => {
    setOpenMenuReports(!openSubMenuReports);
  };

  const [openSubMenuexpenses, setOpenMenuexpenses] = React.useState(false);
  const subMenuOpenexpenses = () => {
    setOpenMenuexpenses(!openSubMenuexpenses);
  };

  const [openSubMenu, setOpenMenu] = React.useState(false);
  const subMenuOpen = () => {
    setOpenMenu(!openSubMenu);
  };

  return (
    <div>
      <List component="nav">
        {/**
         * @Comment Vista home con graficas
         * @Note Crear Vista de Bienvenida y dividir las Graficas
         * @Author DanielRM
         * @export
         * @return {*}
         */}
        <Link to="/" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Inicio" aria-label="Inicio">
                <HomeIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Inicio" />
          </ListItem>
        </Link>
        <Divider />
        {/**
         * @Comment Menu desplegable de establecimientos contenedor de Sucursales y Escuelas
         * @Author DanielRM
         * @export
         * @return {*}
         */}
        <ListItem
          button
          onClick={subMenuOpensetOpenMenuEstablishments}
          className={classes.Iconwhite}
        >
          <ListItemIcon>
            <HomeWorkIcon className={classes.Iconwhite} />
          </ListItemIcon>
          <ListItemText primary="Establecimientos" />
          {openMenuEstablishments ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openMenuEstablishments} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            <Link to="/Sucursales" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Sucursales" aria-label="Sucursales">
                    <StorefrontIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Sucursales" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Escuelas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon className={classes.Iconwhite}>
                  <Tooltip title="Escuelas" aria-label="Escuelas">
                    <SchoolIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Escuelas" />
              </ListItem>
            </Link>
            <Divider />
          </List>
        </Collapse>
        <Divider />

        {/**
         * @Comment Menu desplegable de Administracion escolar contenedor de Salones,Cursos,inscripciones,Programar Cursos
         * @Note none
         * @Author DanielRM
         * @export
         * @return {*}
         */}
        <ListItem
          button
          onClick={subMenuOpenSchoolAdministration}
          className={classes.Iconwhite}
        >
          <ListItemIcon>
            <ArtTrackIcon className={classes.Iconwhite} />
          </ListItemIcon>
          <ListItemText primary="Control Escolar" />
          {openSchoolAdministration ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSchoolAdministration} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            <Link to="/Salones" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Salones" aria-label="Salones">
                    <MeetingRoomIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Salones" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Cursos" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Cursos" aria-label="Cursos">
                    <ClassIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Cursos" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Programaciones" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Programaciones" aria-label="Programaciones">
                    <DateRangeIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Programar Cursos" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Inscripciones" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Inscripciones" aria-label="Inscripciones">
                    <AssignmentIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Inscripciones" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Utilidad/Escuela" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Corte De Caja" aria-label="Corte De Caja">
                    <MonetizationOnIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Corte De Caja Escolar" />
              </ListItem>
            </Link>
            <Divider />
          </List>
        </Collapse>
        <Divider />

        <Link to="/CuentasBancarias" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <AccountBalanceIcon className={classes.Iconwhite} />
            </ListItemIcon>
            <ListItemText primary="Cuentas Bancarias" />
          </ListItem>
        </Link>
        <Divider />

        <ListItem
          button
          onClick={subMenuOpenSales}
          className={classes.Iconwhite}
        >
          <ListItemIcon>
            <MonetizationOnTwoToneIcon className={classes.Iconwhite} />
          </ListItemIcon>
          <ListItemText primary="Gestor de Ventas" />
          {openSubMenuSales ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSubMenuSales} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            <Link to="/Ventas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Ventas" aria-label="Ventas">
                    <AttachMoneyIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Ventas" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Ventas/Canceladas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Ventas Canceladas"
                    aria-label="Ventas Canceladas"
                  >
                    <CancelIcon className={classes.subMenu} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Ventas Canceladas" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Reglas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Reglas de negocio"
                    aria-label="Reglas de negocio"
                  >
                    <LocalAtmIcon className={classes.subMenu} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Reglas de negocio" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Cupones" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Cupones de descuento"
                    aria-label="Cupones de descuento"
                  >
                    <MoneyIcon className={classes.subMenu} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Cupones de descuento" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Comisiones" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Comisiones" aria-label="Comisiones">
                    <PaymentIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Comisiones" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Utilidad/Tienda" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Corte De Caja" aria-label="Corte De Caja">
                    <MonetizationOnIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Corte De Caja Tiendas" />
              </ListItem>
            </Link>
            <Divider />
          </List>
        </Collapse>
        <Divider />
        <Divider />
        <Link to="/Clientes" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Clientes" aria-label="Clientes">
                <PeopleAltIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItem>
        </Link>
        <Divider />

        {/**
         * @Comment Menu desplegable de Control de Productos contenedor de Sucursales y Escuelas
         * @Author DanielRM
         * @export
         * @return {*}
         */}
        <ListItem
          button
          onClick={subMenuOpenProductControl}
          className={classes.Iconwhite}
        >
          <ListItemIcon>
            <PostAddTwoToneIcon className={classes.Iconwhite} />
          </ListItemIcon>
          <ListItemText primary="Control de Producto" />
          {openSubMenuProductControl ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSubMenuProductControl} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            {/* <Divider />
            <Link to="/Categorias" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Categorias" aria-label="Categorias">
                    <BookmarksIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Categorias" />
              </ListItem>
            </Link>
            <Divider /> */}
            {/* <Divider />
            <Link to="/Subcategorias" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Subcategorias" aria-label="Subcategorias">
                    <StreetviewIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Subcategorias" />
              </ListItem>
            </Link> */}
            <Divider />
            <Link to="/Productos" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Mis Productos" aria-label="Mis Productos">
                    <ShoppingCartIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Mis Productos" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Stock de Productos" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Stock de Productos"
                    aria-label="Stock de Productos"
                  >
                    <FormatColorFillIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Stock de Productos" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/MovimientosStock" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Movimientos Del Stock"
                    aria-label="Movimientos Del Stock"
                  >
                    <GavelIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Movimientos Del Stock" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Salidas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Salida De Productos"
                    aria-label="Salida De Productos"
                  >
                    <RemoveShoppingCartIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Salida De Productos" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Inventarios" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Inventario De Productos"
                    aria-label="Inventario De Productos"
                  >
                    <CategoryIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Inventario De Productos" />
              </ListItem>
            </Link>
          </List>
        </Collapse>
        <Divider />

        {/*
      <Link to="/Servicios" className={classes.Link}>
        <ListItem button>
          <ListItemIcon>
            <DevicesIcon/>
          </ListItemIcon>
          <ListItemText primary="Servicios"/>
        </ListItem>
      </Link>
      <Divider />
      
      */}
        <Divider />
        <Link to="/Traspaso" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip
                title="Traspaso de Productos"
                aria-label="Traspaso de Productos"
              >
                <SendIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Traspaso de Productos" />
          </ListItem>
        </Link>
        <Divider />
        {/**
         * @Comment Menu desplegable de establecimientos contenedor de Sucursales y Escuelas
         * @Author DanielRM
         * @export
         * @return {*}
         */}
        <ListItem
          button
          onClick={subMenuOpenReports}
          className={classes.Iconwhite}
        >
          <ListItemIcon>
            <PictureAsPdfTwoToneIcon className={classes.Iconwhite} />
          </ListItemIcon>
          <ListItemText primary="Reportes PDF" />
          {openSubMenuReports ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSubMenuReports} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            <Link to="/reportes" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Reportes" aria-label="Reportes">
                    <PictureAsPdfIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Reportes" />
              </ListItem>
            </Link>
            {/* <Link to="/EntradaProductos" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Entrada de Productos"
                    aria-label="Entrada de Productos"
                  >
                    <PictureAsPdfIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Entrada de Productos" />
              </ListItem>
            </Link> */}
            {/* <Divider />
            <Link to="/Utilidad" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Reportes De Corte De Caja (Sucursales)"
                    aria-label="Reportes De Corte De Caja (Sucursales)"
                  >
                    <PictureAsPdfIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Reportes De Corte De Caja (Sucursales)" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Reportes/Utilidad/Escuelas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Reportes De Corte De Caja (Escuelas)"
                    aria-label="Reportes De Corte De Caja (Escuelas)"
                  >
                    <PictureAsPdfIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Reportes De Corte De Caja (Escuelas)" />
              </ListItem>
            </Link>
            <Divider /> */}
          </List>
        </Collapse>
        <Divider />

        {/**
         * @Comment Menu desplegable de establecimientos contenedor de Sucursales y Escuelas
         * @Author DanielRM
         * @export
         * @return {*}
         */}
        <ListItem
          button
          onClick={subMenuOpenexpenses}
          className={classes.Iconwhite}
        >
          <ListItemIcon>
            <ReceiptIcon className={classes.Iconwhite} />
          </ListItemIcon>
          <ListItemText primary="Gastos" />
          {openSubMenuexpenses ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSubMenuexpenses} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            <Link to="/Gastos/Tienda" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Gastos - Tienda" aria-label="Gastos - Tienda">
                    <MoneyOffIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Gastos - Tienda" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Gastos/Escuela" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Gastos - Escuela"
                    aria-label="Gastos - Escuela"
                  >
                    <MoneyOffIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Gastos - Escuela" />
              </ListItem>
            </Link>
            <Divider />
          </List>
        </Collapse>
        <Divider />
        <Link to="/Usuarios" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Usuarios" aria-label="Usuarios">
                <PersonIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Graficas" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Graficas" aria-label="Graficas">
                <InsertChartOutlinedTwoToneIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Graficas" />
          </ListItem>
        </Link>
        <Divider />
        <Divider />
      </List>
    </div>
  );
}
