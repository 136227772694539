import React, { useState, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { useForm } from 'react-hook-form';
import FormControl from '@material-ui/core/FormControl';

/**Habilitar redirecciones */
import { withRouter } from 'react-router-dom';

import Swal from 'sweetalert2';

import { MethodPost } from '../../Config/Services';

import SelectBranchOffice from '../../Containers/SelectOptions/SelectBranchOffice';
import SelectTypeUser from '../../Containers/SelectOptions/SelectTypeUser';

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://www.digital-pineapple.com.mx">
				By Digital-Pineapple
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(https://source.unsplash.com/random)',
		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'light'
				? theme.palette.grey[50]
				: theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	input: {
		display: 'none',
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		background: '#3537DB',
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			background: '#5902CF',
		},
		color: 'white',
	},
}));

const name_branch = [{ title: 'Studio GlamOur' }];
const type = [
	{ title: 'Aplicador' },
	{ title: 'Vendedor' },
	{ title: 'Maestro' },
];

export default function SignInSide(props) {
	const classes = useStyles();

	const [archivo, guardarArchivo] = useState();
	const leerArchivo = (e) => {
		guardarArchivo(e.target.files[0]);
	};

	const [nameOffice, guardarSucursal] = useState({
		id_branch_office: '',
	});

	const [nameTypeUsers, guardarType] = useState({
		type_user: '',
	});

	const detectarCambiosOffice = (e) => {
		guardarSucursal({ id_branch_office: e.target.value });
	};

	const detectarCambiosTypeUser = (e) => {
		guardarType({ type_user: e.target.value });
	};
	//Funcion para validaciones
	const { register, handleSubmit, errors, watch } = useForm();
	const password = useRef({});
	password.current = watch('password', '');

	const onSubmit = async (data, e) => {
		e.preventDefault();

		if (nameOffice.id_branch_office !== '' && nameTypeUsers.type_user) {
			const dat = {
				...data,
				...nameOffice,
				...nameTypeUsers,
				archivo,
			};

			let url = '/register';
			MethodPost(url, dat)
				.then((res) => {
					Swal.fire({
						title: 'Se te ha enviado un correo, verificalo',
						text: res.data.message,
						icon: 'success',
						timer: 2000,
						showConfirmButton: false,
					});

					props.history.push('/');
				})
				.catch((error) => {
					Swal.fire({
						title: 'Error',
						text: error.response.data.error,
						icon: 'error',
					});
				});
		} else {
			Swal.fire({
				title: 'Error',
				text: 'Todos los campos son Obligatorios',
				icon: 'error',
			});
		}
	};

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<PersonAddIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Registrarme
					</Typography>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className={classes.form}
						noValidate
					>
						<label htmlFor="outlined-button-file">
							<Button variant="outlined" color="primary" component="span">
								Selecciona Foto
							</Button>
						</label>
						<input
							accept="image/*"
							className={classes.input}
							id="icon-button-file"
							type="file"
						/>
						<label htmlFor="icon-button-file">
							<IconButton
								color="primary"
								aria-label="upload-picture"
								component="span"
							>
								<PhotoCamera />
							</IconButton>
						</label>

						<FormControl variant="outlined" className={classes.formControl}>
							<SelectBranchOffice
								detectarCambiosOffice={detectarCambiosOffice}
							/>
						</FormControl>

						<FormControl variant="outlined" className={classes.formControl}>
							<SelectTypeUser
								detectarCambiosTypeUser={detectarCambiosTypeUser}
							/>
						</FormControl>

						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="name"
							autoComplete="name"
							label="Nombre Completo"
							name="name"
							autoFocus
							inputRef={register({
								required: {
									value: true,
									message: 'El Nombre del Nombre es requerido',
								},
								minLength: {
									value: 1,
									message: 'Minimo 5 caracteres',
								},
								maxLength: {
									value: 45,
									message: 'Maximo 45 caracteres',
								},
								pattern: {
									value: /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
									message: 'Unicamente carácteres alfabéticos',
								},
							})}
						/>
						<p>{errors?.name?.message}</p>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							autoComplete="number_phone"
							id="number_phone"
							label="Teléfono"
							name="number_phone"
							autoFocus
							inputRef={register({
								required: {
									value: true,
									message: 'El Teléfono es requerido',
								},
								maxLength: {
									value: 10,
									message: 'Maximo 10 digitos',
								},
							})}
						/>
						<p>{errors?.number_phone?.message}</p>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Correo Electronico"
							name="email"
							autoComplete="email"
							autoFocus
							inputRef={register({
								required: {
									value: true,
									message: 'El correo es requerido',
								},
								type: 'email',
								pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							})}
						/>
						<p>{errors?.email?.message}</p>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Contraseña"
							type="password"
							id="password"
							autoComplete="current-password"
							inputRef={register({
								required: {
									value: true,
									message: 'La contraseña es requerida',
								},
								minLength: {
									value: 8,
									message: 'Minimo 8 caracteres',
								},
								maxLength: {
									value: 16,
									message: 'Maximo 16 caracteres',
								},
							})}
						/>
						<p>{errors?.password?.message}</p>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="c_password"
							label="Confirmar Contraseña"
							name="c_password"
							type="password"
							autoComplete="name"
							autoFocus
							error={!!errors.c_password}
							inputRef={register({
								required: {
									value: true,
									message: 'Confirmar Contraseña es requerida',
								},
								minLength: {
									value: 8,
									message: 'Minimo 8 caracteres',
								},
								maxLength: {
									value: 16,
									message: 'Maximo 16 caracteres',
								},
							})}
						/>
						<p>{errors?.c_password?.message}</p>

						<FormControlLabel
							control={<Checkbox value="remember" color="primary" />}
							label="Al registrarte aceptas nuestros terminos y condiciones"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							className={classes.submit}
						>
							Registrarme
						</Button>
						<Grid>
							<Grid item>
								<Link href="/" variant="body2">
									{'¿Ya tienes cuenta? Iniciar Sesión'}
								</Link>
							</Grid>
						</Grid>
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
				</div>
			</Grid>
		</Grid>
	);
}
