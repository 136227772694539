import React, { useEffect, useState, useContext } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import { 
	Grid, 
	Box, 
	Paper,
	Container, 
	Tooltip, 
	Button, 
	TextField, 
	Typography, 
	IconButton,
	InputLabel,
	FormControl
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
/**importar spinner */
import Spinner from "../../Complements/Spinner";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
import MethodGet, { MethodPut } from '../../Config/Services';
import { useForm } from 'react-hook-form';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import CouponsContext from '../../Reducers/Coupons/CouponsContext';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingLeft: theme.spacing(10),
		//marginBottom: theme.spacing(10),
	},
	typography: {
		margin: theme.spacing(1),
		//paddingLeft: theme.spacing(30),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(9),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		background: 'indigo',
		'&:hover': {
			background: '#5902CF',
		},
		color: 'white',
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	input: {
		display: 'none',
	},
	containerImageProfile:{
		display:'flex',
		flexDirection:'row',
		flexWrap:'wrap',
		justifyContent:'space-around',

	},
	PaperFormAdd:{
	background:'white',
	color:'black',
	boxShadow: '2px 4px 4px #cacaca',
	},
}));



function CouponsEdit(props) {
	const classes = useStyles();

    const [ expiration_date , saveExpirationDate ] = useState(Moment().format('YYYY-MM-DD'));
    const [selectedDateStart, handleDateChangeStart] = useState(new Date());
    const formCouponsContext = useContext(CouponsContext);

    const { success, updateCoupon } = formCouponsContext;

    const changeDate = (date) => {
        const DateFormat = Moment(date).format('YYYY-MM-DD');
        saveExpirationDate(DateFormat);
        handleDateChangeStart(date);
    };

    const [type, setType] = useState('');

    const ChangeType = (event) => {
        setType(event.target.value);
    };
  
  const [cargando, spinnerCargando] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  
  const [coupon, saveCoupon] = useState(false);
  //obtenemos el producto de session storage
  let CouponStorageID = JSON.parse(sessionStorage.getItem("coupon"));

	//extraer las propiedades del objeto Desctructuracion
	const { code, discount } = coupon;
	
	useEffect(() => {
		let url = `/coupons/${CouponStorageID}`;
	
		MethodGet(url)
			.then((res) => {
				saveCoupon(res.data.data);
                setType(res.data.data.type);
                handleDateChangeStart(res.data.data.expiration)
                saveExpirationDate(res.data.data.expiration_date)
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
	}, []);

	useEffect(() => {
		//si success retorna verdadero la pagina redireccionara al index de cupones
		if (success) {
		  props.history.push("/Cupones"); 
		}
	  }, [success]);

    const onSubmit = (data, e) => {
		e.preventDefault();
        data.expiration_date = expiration_date;
        data.type = type;
        data.id = CouponStorageID;

		updateCoupon(data);
	};

    useEffect(() => {
	    //si success retorna verdadero la pagina redireccionara al index de cupones
	    if (success) {
	      props.history.push(`/Cupones`); 
	    }
	}, [success]);

	//spinner de carga
	if (!cargando) return <Spinner />;

	return (
		<LayoutDashboard>
			<div className={classes.root} onSubmit={handleSubmit(onSubmit)} >
				<Paper className={classes.PaperFormAdd}>
				<form
					className={classes.form}
					noValidate
				>
					<Box>
						<Typography
							component="h1"
							variant="h4"
							align="center"
							className={classes.typography}
						>
							Editar Cupón
						</Typography>
					</Box>
					<Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} >
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="code"
								label="Código para aplicar el descuento"
								name="code"
								defaultValue={code}
								autoComplete="code"
								autoFocus
								error={!!errors.money_discount_min}
								inputRef={register({
									required: {
										value: true,
										message: 'El código de descuento es requerido',
									},
									minLength: {
										value: 1,
										message: 'Minimo 1 carácter',
									},
								})}
							/>
							<p>{errors?.code?.message}</p>
						</Grid>

						<Grid item xs={12} sm={12} md={6} lg={6} >
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="discount"
								label="Porcentaje de descuento"
								name="discount"
							    defaultValue={discount}
								autoComplete="discount"
								autoFocus
								type="number"
								error={!!errors.money_discount_max}
								inputRef={register({
									required: {
										value: true,
										message: 'El porcentaje de descuento es requerido',
									},
									min: {
										value: 1,
										message: 'Minimo 1% para aplicar un descuento',
									},
                                    max: {
										value: 50,
										message: 'Maximo 50% de descuento',
									},
								})}
							/>
							<p>{errors?.discount?.message}</p>
						</Grid>

						<MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid item xs={12} sm={6}>
                              <KeyboardDatePicker
                                  autoOk
                                  fullWidth
                                  inputVariant="outlined"
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  disablePast
                                      label="Fecha De Expiración"
                                  value={selectedDateStart}
                                  onChange={changeDate}
                                  InputAdornmentProps={{ position: "start" }}
                              />
                          </Grid>
                        </MuiPickersUtilsProvider>

                        <Grid item xs={12} sm={12} md={6} lg={6} >
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Selecciona El Tipo De Cupón</FormLabel>
                              <RadioGroup row aria-label="type" name="type" value={type.toString()} onChange={ChangeType}>
                                <FormControlLabel value="1" control={<Radio />} label="Ventas" />
                                <FormControlLabel value="2" control={<Radio />} label="Inscripciones" />
                                <FormControlLabel value="3" control={<Radio />} label="Ambos" />
                              </RadioGroup>
                            </FormControl>
                        </Grid>

					</Grid>
					<Grid container spacing={3} justify="center">
						<Grid item spacing={3} xs={12} sm={12} md={6}>
							<Button
								type="submit"
								fullWidth
								variant="outlined"
								className={classes.submit}
							>
								ACTUALIZAR
							</Button>
						</Grid>
					</Grid>
				</form>
				</Paper>

			</div>
		</LayoutDashboard>
	);
}

export default withRouter(CouponsEdit)