import React , { Fragment ,useState , useEffect } from 'react';
/**Importar configuracion axios */
import clienteAxios from '../../Config/Axios';
import { Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useSelectAdmin = (props) => {    //state del custom hook
	const [error, guardarError] = useState(true);
	const {id} = props;
    const [ users , saveUsers ] = useState([]);

    useEffect(() => {
      const getUsers = async () => {
        await clienteAxios
          .get('/admins/' + id)
          .then((res) => {
            saveUsers(res.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
          if (props.nameUser) {
            guardarError(false);
        }
      };
      getUsers();
    }, []);

    const detectarCambiosUser = (e) => {
		if (e.target.value) {
			guardarError(false);
		}
		props.detectarCambiosUser(e);
	};
    return (
		<Fragment>
			<InputLabel fullWidth>Autoriza*</InputLabel>
			{props.nameTypeClients ? (
				<Select
					label="Usuarios"
					fullWidth
					onChange={detectarCambiosUser}
					value={props.user_id}
				>
					<MenuItem disabled>Selecciona el Usuario</MenuItem>
					{users.map((user) => (
						<MenuItem key={user.id} value={user.id}>
							{user.name}

						</MenuItem>
					))}
				</Select>
			) : (
				<Select
					label="Usuarios"
					fullWidth
					onChange={detectarCambiosUser}
				>
					<MenuItem disabled>Selecciona el Usuario</MenuItem>
					{users.map((user) => (
						<MenuItem key={user.id} value={user.id}>
							{user.name}
						</MenuItem>
					))}
				</Select>
			)}

			{error ? (
				<p style={{ color: 'red' }}>El Usuario que autoriza es un Campo Requerido</p>
			) : null}
		</Fragment>
	);
};

export default useSelectAdmin;