import React, { Component, useState, useEffect, useContext } from "react";
import LayoutDashboard from "../../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  Button,
  Tooltip,
  Hidden,
  Grid,
  Typography,
  Box,
  ButtonBase,
  Paper,
  Fab,
  Container,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import Icon from "@material-ui/icons/PictureAsPdf";
//BOTON DE AGREGAR
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
//IMPORTACION DE TABS MATERIAL UI
/**Importacion de tabs */
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";

import Swal from "sweetalert2";

import MisProductos from "./TableCanceledSalesSeller";

import { useForm } from "react-hook-form";
import fileDownload from "js-file-download";
import clienteAxios from "../../../Config/Axios";
import AuthContext from "../../../Context/autenticacion/authContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    padding: theme.spacing(5),
  },
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperTitle: {
    background: "#424242",
  },
  rootTab: {
    backgroundColor: theme.palette.background.paper,
  },
  Button: {
    background: "#3537DB",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#5902CF",
    },
    color: "white",
    width: theme.spacing(16),
  },
  Button2: {
    background: "#3537DB",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#5902CF",
    },
    color: "white",
    width: theme.spacing(5),
  },
  formControl: {
    width: "100%",
  },
  listButton: {
    position: "relative",
    backgroundColor: "#5B009C",
    color: "white",
    height: "35px",
    marginTop: "3%",
    "&:hover": {
      background: "#6800B3",
      color: "white",
    },
    zIndex: 3,
  },
  // Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "80px",
    borderRadius: "15px",
    paddingTop: "5px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "40px",
    zIndex: 2,
    position: "relative",
    marginRight: -20,
  },
  btnReverse: {
    display: "absolute",
    marginTop: "40px",
    zIndex: 2,
    position: "relative",
    marginLeft: -20,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  fabReverse: {
    color: "white",
    backgroundColor: "#dc6daf",
    "&:hover": {
      backgroundColor: "#bd5d96",
    },
  },
  // Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
  /** 
// Estilo de tablas Container
	titleTbl:{
//backgroundColor:'#8115CF',
		background:'linear-gradient(50deg, #0288d1 33%, #26c6da 100%)',
		color:'white',
		fontSize:'large',
		height:'60px',
		borderRadius:'5px',
		paddingTop:'10px',
		marginBottom:'20px',
		paddingLeft:'1%',
	},
	btnPlus:{
		display:'absolute',
		marginTop:'10%',
		marginRight:'-5%',
		zIndex:2,
		position: 'relative',
	},
	IconArrowBack:{
		paddingTop:'8px',
		marginRight:'-2%'
	},
	fabGreen: {
		color: theme.palette.common.white,
		backgroundColor: green[500],
		'&:hover': {
		  backgroundColor: green[600],
		},
	  },
	  fabBlue: {
		color: theme.palette.common.white,
		backgroundColor: '#0d47a1',
		'&:hover': {
		  backgroundColor: '#0A3678',
		},
	  },
	  btnAgregar:{
		color:'white',
		marginLeft:20,
		fontSize:'small',
		height:'60px',
		borderRadius:'5px',
		paddingLeft:20,
		paddingRight:20,
		backgroundColor: 'indigo',
		'&:hover': {
		  backgroundColor: '#5C009E',
		},
	  },*/
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function SalesSeller(props) {
  const classes = useStyles();

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#f44336",
      },
      primary: {
        main: "#eeeeee",
      },
    },
  });

  const [value, setValue] = React.useState(0);
  const onSubmit = (data, e) => {
    e.preventDefault();
    console.log(data);
  };
  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario, usuarioAutenticado, cerrarSesion } = authContext;
  useEffect(() => {
    usuarioAutenticado();
  }, []);

  return (
    <LayoutDashboard>

              {/**
               * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
               *
               */}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    marginTop: 100,
                    background:
                      "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
                    color: "white",
                    borderRadius: 15,
                    marginLeft: 25,
                    marginRight: 25,
                  }}
                >
                  <Typography component="h1" variant="h4">
                    {usuario &&
                      (usuario.type == 3
                        ? "Ventas Canceladas de la Sucursal"
                        : "Mis Ventas Canceladas")}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Todos los registros de Ventas Canceladas de la Sucursal
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  align="right"
                  style={{ marginRight: 30, marginTop: -30 }}
                >
                  <Fab
                    className={classes.fabReverse}
                    aria-label="PDF"
                    size="large"
                    endIcon={<Icon>send</Icon>}
                  >
                    <Tooltip
                      title="Generar corte de caja diario"
                      aria-label="Generar corte de caja diario"
                    >
                      <PictureAsPdfOutlinedIcon size="large" />
                    </Tooltip>
                  </Fab>
                </Grid>
              </Grid>
              {/**
               * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
               *
               */}

              <div>
                <div className={classes.rootTab}>
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <MisProductos usuario={usuario} />
                  </TabPanel>
                </div>
              </div>
    </LayoutDashboard>
  );
}
