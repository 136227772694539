import React, { useState, useEffect, Fragment, useContext } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import SchoolSellerClassrooms from './SchoolSellerClassrooms';

const ClassroomSeller = () => {

	const authContext = useContext(AuthContext);
	const { getSchoolID, schoolID } = authContext;

	useEffect(() => {
		getSchoolID();
    }, []);

	return (

		<SchoolSellerClassrooms 
			schoolID={schoolID}
		/>

	);
};

export default ClassroomSeller;
