import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import clienteAxios from '../../Config/Axios';
import MethodGet from '../../Config/Services';
/**Importar Sweetalert2 * */
import Swal from 'sweetalert2';
/**Importar componente token headers */
import tokenAuth from '../../Config/tokenAuth';

import {
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    VERIFY_RECAPTCHA,
    GET_SCHOOL_ID
} from '../../types/index';

const AuthState = props => {
    //Agregar state inicial 
    const initialState = {
        token: localStorage.getItem('token'),
        autenticado: null,
        usuario: null,
        schoolID: null,
        verificationRecaptcha: false,
        cargando: true
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState);
    //funciones
    //Retorna el usuario autenticado
    const usuarioAutenticado = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            //Todo: Funcion para enviar el token por headers
            tokenAuth(token);
        }
        try {

            const res = await clienteAxios.get('/user');
            dispatch({
                type: OBTENER_USUARIO,
                payload: res.data
            })
        } catch (error) {
            dispatch({
                type: LOGIN_ERROR
            })
        }
    }

    const verifyRecaptcha = code =>{
        let url = `verify-recaptcha?code=${code}`;
        MethodGet( url ).then(response=>{
          dispatch({
              type:VERIFY_RECAPTCHA,
              payload: response.data.success
          })
        }).catch(error => {
           console.log(error);
        });
    }

    //cuando el usuario inicia sesion 
    const iniciarSesion = async datos => {
        try {
            const res = await clienteAxios.post('/login', datos);
            //console.log(datos);
            dispatch({
                type: LOGIN_EXITOSO,
                payload: res.data.token,
                expires_at: res.data.expires_at
            });

            usuarioAutenticado();
        } catch (error) {

            error.response.data.message ? (
                Swal.fire(
                    'Inicio de Sesión Fallido',
                    error.response.data.message,
                    'error'
                )
            ) : Swal.fire(
                'Inicio de Sesión Fallido',
                error.response.data.error,
                'error'
            )
        }
    }

    //
    const getSchoolID = async () => {
        const res = await clienteAxios.get('/user');
            dispatch({
                type: GET_SCHOOL_ID,
                payload: res.data.school_user.id
            })
    }

    //Cierrra sesion del usuario
    const cerrarSesion = () => {
        dispatch({
            type: CERRAR_SESION
        })
    }
    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                schoolID: state.schoolID,
                cargando: state.cargando,
                verificationRecaptcha: state.verificationRecaptcha,
                iniciarSesion,
                getSchoolID,
                verifyRecaptcha,
                usuarioAutenticado,
                cerrarSesion
            }}
        >
            {props.children}

        </AuthContext.Provider>
    )
}

export default AuthState;