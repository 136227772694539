import React, { useState, useEffect, Fragment } from "react";
/**Importar componentes select */
import MenuItem from "@material-ui/core/MenuItem";
import Select from "react-select";
import InputLabel from "@material-ui/core/InputLabel";

export default function SelectTypeSale(props) {
  const [error, guardarError] = useState(true);
  const [tipos, saveType] = useState([]);

  const typeSales = [
    { type_sale: 1, name: "Venta Productos Mixtos" },
    { type_sale: 2, name: "Venta Productos MerryColor" },
    { type_sale: 3, name: "Venta Productos Extra" },
  ];

  useEffect(() => {
    saveType(typeSales);
    if (props.type_sale) {
      guardarError(false);
    }
  }, [error]);
  const detectarCambiosTypeSale = (value) => {
    props.detectarCambiosTypeSale(value);
  };
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  return (
    <>
      <Select
        onChange={(value) => detectarCambiosTypeSale(value)}
        className="basic-single"
        classNamePrefix="select"
        name="client"
        styles={selectStyles}
        placeholder="Selecciona un tipo de venta"
        options={typeSales.map((typeSale) => {
          let attribute = {
            label: typeSale?.name,
            value: typeSale?.type_sale,
            // typeClient: client?.customer_type ? client?.customer_type : null,
          };
          return attribute;
        })}
      />
      {/* <InputLabel fullWidth>Tipo de Venta</InputLabel>
      {props.type_sale ? (
        <Select
          variant="outlined"
          label="Tipo de Venta"
          onChange={detectarCambiosTypeSale}
          value={props.type_sale}
        >
          <MenuItem disabled>Selecciona el Tipo de Venta</MenuItem>
          {tipos.map((type) => (
            <MenuItem key={type.type_sale} value={type.type_sale}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          label="Tipo de Venta"
          fullWidth
          onChange={detectarCambiosTypeSale}
          variant="outlined"
        >
          <MenuItem disabled>Selecciona el Tipo de Venta</MenuItem>
          {tipos.map((type) => (
            <MenuItem key={type.type_sale} value={type.type_sale}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      )} */}
    </>
  );
}
