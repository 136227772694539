import React, {useState, useEffect, Fragment, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import FormControl from "@material-ui/core/FormControl";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { green } from "@material-ui/core/colors";
import SelectAdmin from '../SelectOptions/SelectAdmin';
import AuthContext from '../../Context/autenticacion/authContext';
import { useForm } from "react-hook-form";
import SelectMethodsPayments from '../SelectOptions/SelectMethodsPayments';

import { Tooltip } from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
const theme = createMuiTheme({
    palette: {
      primary: green,
    },
  });

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
  }));

export default function ModalAddCashOut(props) {
    const classes = useStyles();
    const {open, 
           handleClose,
           AddCashOut,
           SchoolStorageID,
           success,
        } = props;

    const [disableButton, setDisableButton] = useState(false);
    //Extraer la informacion de autenticación
     const authContext = useContext(AuthContext);
     const { usuario, usuarioAutenticado } = authContext;

     useEffect(() => {
      usuarioAutenticado();
    }, []);
    const { register, errors, handleSubmit } = useForm();

    const [user, setUser] = useState('');

	  const [method, saveMethods] = useState({
		  id_payment_method: '',
	  });
	
	  const detectarCambiosMethods = (e) => {
		  saveMethods({ id_payment_method: e.target.value });
      //console.log(method,'method')
	  };

	  const detectarCambiosUser = (e) => {
	  	setUser(e.target.value);
	  };

    const onSubmit = (data, e) => {
      e.preventDefault();
      data.authorized_person_id = user;
      data.destination_person_id = usuario.id;
      data.branch_office_id = SchoolStorageID;
      data.type = 2;
      data.method = method.id_payment_method;

      //enviamos la data a la funcion que se encarga de guardar los nuevos registros
      AddCashOut(data);
      handleClose();
    }

    return (
        <Fragment>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title" style={{textAlign:'center'}}>{"Agregar Salida De Efectivo"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Grid Container spacing={2}>
            <ThemeProvider theme={theme}>
            <Grid item xs={12}>
              <TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="destination_person_id"
								label="Emite"
                defaultValue={usuario ? usuario.name : null}
								name="destination_person_id"
                disabled="true"
								autoComplete="destination_person_id"
								autoFocus
							/>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
         	      <SelectAdmin detectarCambiosUser={detectarCambiosUser} id={SchoolStorageID} />
        	    </FormControl>
            </Grid>

						<Grid item xs={12}>
							<FormControl variant="outlined" className={classes.formControl} fullWidth>
								<SelectMethodsPayments
									detectarCambiosMethods={detectarCambiosMethods}
								/>
							</FormControl>
						</Grid>

            <Grid item xs={12}>
              <TextField
						  		variant="outlined"
						  		margin="normal"
						  		required
						  		fullWidth
						  		id="total"
						  		label="Dinero Saliente"
						  		name="total"
						  		autoComplete="total"
						  		autoFocus
						  		error={!!errors.number_phone}
						  		inputRef={register({
						  			required: {
						  				value: true,
						  				message: 'El dinero saliente es requerido',
						  			},
						  		})}
						  	/>
						  	<p>{errors?.total?.message}</p>
            </Grid>

            <Grid item xs={12}>
              <TextField
						  		variant="outlined"
						  		margin="normal"
						  		required
						  		fullWidth
						  		id="description"
						  		label="Descripción"
						  		name="description"
						  		autoComplete="description"
						  		autoFocus
						  		error={!!errors.description}
						  		inputRef={register({
						  			required: {
						  				value: true,
						  				message: 'La Descripción es requerida',
						  			},
						  			minLength: {
						  				value: 4,
						  				message: 'Minimo 4 caracteres',
						  			},
						  			maxLength: {
						  				value: 255,
						  				message: 'Maximo 255 caracteres',
						  			},
						  		})}
						  	/>
						  	<p>{errors?.description?.message}</p>
            </Grid>

            </ThemeProvider>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => handleClose()} color="secondary" variant="contained">
            Cancelar
          </Button>
          {!disableButton ?
            <Button type="submit" color="primary" autoFocus  variant="contained">
              Guardar
            </Button>
            :
            <Button type="submit" disabled color="primary" autoFocus  variant="contained">
              Cargando...
            </Button>
          }
        </DialogActions>
        </form>
      </Dialog>
        </Fragment>
    )
}
