import React, { useState, useEffect, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
import ClassroomContext from "../../Reducers/Classrooms/ClassroomContext";
import GridItem from "../../Components/Grid/GridItem";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardBody from "../../Components/Card/CardBody";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

function ClassroomAddAdmin(props) {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);

  const formClassroomContext = useContext(ClassroomContext);

  const { ErrorsAPI, success, AddClassroom } = formClassroomContext;

  const [school, saveSchool] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name } = school;

  //obtenemos la escuela de session storage
  let SchoolStorageID = JSON.parse(sessionStorage.getItem("school"));

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${SchoolStorageID}`;
    MethodGet(url)
      .then((res) => {
        saveSchool(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();
    data.school_id = SchoolStorageID;

    AddClassroom(data);
  };

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de salones
    if (success) {
      //console.log("Retorna");
      props.history.push(`/SalonesEscuela`);
    }
    //eslint-disable-next-line
  }, [success]);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 100 }}
        >
          {/**
           * identificar Codigo de FORMS
           */}
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>
                  Agregar Nuevo Salon A {name}
                </h2>
                <p className={classes.cardCategoryWhite}>
                  Completa correctamente los datos
                </p>
              </CardHeader>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre del Salon"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre del Salon es requerido",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 5 caracteres",
                        },
                        maxLength: {
                          value: 45,
                          message: "Maximo 45 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="capacity"
                      label="Capacidad del Salon"
                      name="capacity"
                      type="number"
                      min="1"
                      max="45"
                      autoComplete="capacity"
                      autoFocus
                      error={!!errors.capacity}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La Capacidad del Salon es requerida",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 1 digito",
                        },
                        maxLength: {
                          value: 6,
                          message: "Maximo 6 digitos",
                        },
                      })}
                    />
                    <p>{errors?.capacity?.message}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justify="center">
                  <Grid item spacing={3} xs={12} sm={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.submit}
                    >
                      GUARDAR
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </form>
    </LayoutDashboard>
  );
}

export default withRouter(ClassroomAddAdmin);
