import React, { useReducer } from "react";
import SchedulesContext from "./SchedulesContext";
import SchedulesReducer from "./SchedulesReducer";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../Config/Services";
import {
  ADD_NEW_SCHEDULE,
  CURRENT_SCHEDULE,
  DELETE_SCHEDULE,
  GET_ALL_SCHEDULES,
  GET_ALL_SCHEDULES_STUDENTS,
  GET_ALL_CLASSES,
  SHOW_ERRORS_API,
  UPDATE_SCHEDULE,
  GET_INFO_SCHEDULE,
  GET_ALL_SCHEDULES_GRADUATES,
  UPDATE_CLASS,
  GET_ALL_OPENSCHEDULE,
} from "../../types";
import Swal from "sweetalert2";

const SchedulesState = ({ children }) => {
  // estado inicial
  const initialSate = {
    schedules: [],
    schedule: {},
    classesSchedule: [],
    classSchedule: {},
    students: [],
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(SchedulesReducer, initialSate);

  //obtener la lista de programaciones
  const GetSchedules = (id) => {
    sessionStorage.removeItem("schedule");
    let url = `schoolschedules/${id}`;
    MethodGet(url).then((response) => {
      //console.log(response);
      dispatch({
        type: GET_ALL_SCHEDULES,
        payload: response.data.data,
      });
    });
  };
  const getsschedule = (id) => {
    let url = `/openschedules/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_OPENSCHEDULE,
        payload: res.data.data,
      });
    });
  };

  const GetClasses = (id) => {
    sessionStorage.removeItem("classSchedule");
    let url = `schedulegraduatedetails/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_ALL_CLASSES,
        payload: response.data.data,
      });
    });
  };

  const GetStudentsSchedule = (id) => {
    //sessionStorage.removeItem('schedule');
    let url = `studentsDetailCourse/${id}`;
    MethodGet(url).then((response) => {
      //console.log(response);
      dispatch({
        type: GET_ALL_SCHEDULES_STUDENTS,
        payload: response.data.data,
      });
    });
  };

  //obtener la lista de programaciones de diplomados
  const GetSchedulesGraduates = (id) => {
    sessionStorage.removeItem("schedule");
    let url = `schoolschedulesgraduates/${id}`;
    MethodGet(url).then((response) => {
      //console.log(response);
      dispatch({
        type: GET_ALL_SCHEDULES_GRADUATES,
        payload: response.data.data,
      });
    });
  };

  const GetInfoSchedule = (id) => {
    let url = `scheduleinfo/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_INFO_SCHEDULE,
        payload: response.data.data,
      });
    });
  };

  //función para crear una nueva programacion
  const AddSchedule = (schedule) => {
    //console.log(school, image, municipality, state);
    const formData = new FormData();
    if (schedule.type == 1) {
      formData.append("course_id", schedule.course);
      formData.append("actual_capacity", 0);
      formData.append("classes_list", schedule.classes_list);
      formData.append("status", 1);
      formData.append("capacity", schedule.capacity);
      formData.append("type", schedule.type);
    } else {
      formData.append("actual_capacity", 0);
      formData.append("capacity", schedule.capacity);
      formData.append("course_id", schedule.course);
      formData.append("courses_list", schedule.courses_list);
      formData.append("type", schedule.type);
      formData.append("status", 1);
    }
    //console.log(schedule);
    let url = "/schedules";
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Programación Registrada",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_SCHEDULE,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //función para seleccionar una programacion
  const SaveCurrentSchedule = (schedule) => {
    sessionStorage.setItem("schedule", JSON.stringify(schedule));
    dispatch({
      type: CURRENT_SCHEDULE,
      payload: schedule,
    });
  };

  //función para seleccionar una programacion
  const SaveCurrentClass = (classSchedule) => {
    sessionStorage.setItem("classSchedule", JSON.stringify(classSchedule));
    dispatch({
      type: CURRENT_SCHEDULE,
      payload: classSchedule,
    });
  };

  //función para editar una programacion
  const UpdateSchedule = (schedule) => {
    const formData = new FormData();
    if (schedule.course != undefined) {
      formData.append("course_id", schedule.course);
    }
    formData.append("capacity", schedule.capacity);
    formData.append("_method", "PATCH");
    let url = `schedules/${schedule.id}`;
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_SCHEDULE,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //función para editar una programacion
  const UpdateClass = (schedule) => {
    const formData = new FormData();
    formData.append("start_time", schedule.start_time);
    formData.append("end_time", schedule.end_time);
    formData.append("start_date", schedule.start_date);
    formData.append("end_date", schedule.end_date);
    formData.append("classroom_id", schedule.classroom);
    formData.append("_method", "PATCH");
    let url = `updateClass/${schedule.id}`;
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_CLASS,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //Eliminar Programacion por id
  const DeleteSchedule = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La programacion ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `schedules/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_SCHEDULE,
            payload: id,
          });
        });
      }
    });
  };

  return (
    <SchedulesContext.Provider
      value={{
        schedules: state.schedules,
        schedule: state.schedule,
        students: state.students,
        classesSchedule: state.classesSchedule,
        classSchedule: state.classSchedule,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        GetSchedules,
        GetSchedulesGraduates,
        GetStudentsSchedule,
        AddSchedule,
        GetClasses,
        SaveCurrentSchedule,
        GetInfoSchedule,
        UpdateSchedule,
        SaveCurrentClass,
        UpdateClass,
        DeleteSchedule,
        getsschedule
      }}
    >
      {children}
    </SchedulesContext.Provider>
  );
};

export default SchedulesState;
