import React, { useState, useEffect, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Button,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import MethodGet from "../../Config/Services";
import Spinner from "../../Complements/Spinner";
import ClientsContext from "../../Reducers/Clients/ClientsContext";
import { withRouter } from "react-router-dom";
import GridItem from "../../Components/Grid/GridItem";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardBody from "../../Components/Card/CardBody";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
  },
  typography: {
    paddingTop: theme.spacing(4),
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(9),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  widgetContentHeader: {
    marginBottom: "0px !important",
    backgroundImage:
      "linear-gradient(to right, #434343 0%, indigo 100%) !important",
    padding: "16px",
    flexDirection: "row",
    alignItems: "center",
    color: "#fff",
  },
  widgetContentBody: {
    marginTop: "10pxpx !important",
    backgroundImage: "#fff",
    padding: "16px",
    flexDirection: "row",
    alignItems: "center",
  },
  titleSeccion: {
    borderBottom: "5px solid indigo",
    marginBottom: "20px",
  },
  submit: {
    background: "indigo",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  ContainerSubmit: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

function ClientAdd(props) {
  const { CreateClient } = useContext(ClientsContext);
  const classes = useStyles();
  const id = props;
  const [cargando, spinnerCargando] = useState(false);

  const [nameOffice, guardarSucursal] = useState({
    id_branch_office: id.id,
  });

  const [branch, guardarBranch] = useState({});

  const [school, saveSchool] = useState({ school_id: "" });

  const detectarCambiosSchool = (e) => {
    saveSchool({ school_id: e.target.value });
  };
  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${nameOffice.id_branch_office}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //Extraer valores de branch(destructuracion)
  const { name, direction, number_phone, state_id, municipality_id } = branch;

  const onSubmit = (data, e) => {
    data.id_branch_office = nameOffice.id_branch_office;

    CreateClient(data);
    props.history.push("/SucursalClientes/" + id.id);
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <div>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid style={{ marginTop: 100 }}>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardHeader color="primary">
                  <h2 className={classes.cardTitleWhite}>
                    Registrar Nuevo Cliente En La Sucursal {name}
                  </h2>
                  <p className={classes.cardCategoryWhite}>
                    Completa correctamente los datos
                  </p>
                </CardHeader>
                <CardBody>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <h3 className={classes.titleSeccion}>Datos Personales</h3>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel>Ingresa Nombre: *</InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="name"
                        label="Nombre(s)"
                        required
                        name="name"
                        autoComplete="name"
                        autoFocus
                        error={!!errors.name}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "El Nombre es requerido",
                          },
                          minLength: {
                            value: 4,
                            message: "Minimo 4 caracteres",
                          },
                          maxLength: {
                            value: 255,
                            message: "Maximo 255 caracteres",
                          },
                          pattern: {
                            value:
                              /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                            message: "Unicamente carácteres alfabéticos",
                          },
                        })}
                      />
                      <p>{errors?.name?.message}</p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel>Ingresa Apellido Paterno: *</InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        id="first_lastname"
                        label="Apellido Paterno"
                        name="first_lastname"
                        autoComplete="first_lastname"
                        autoFocus
                        error={!!errors.first_lastname}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "El Apellido Paterno es requerido",
                          },
                          minLength: {
                            value: 4,
                            message: "Minimo 4 caracteres",
                          },
                          maxLength: {
                            value: 255,
                            message: "Maximo 255 caracteres",
                          },
                          pattern: {
                            value:
                              /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                            message: "Unicamente carácteres alfabéticos",
                          },
                        })}
                      />
                      <p>{errors?.first_lastname?.message}</p>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel>Ingresa Apellido Materno: *</InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        id="second_lastname"
                        label="Apellido Materno"
                        name="second_lastname"
                        autoComplete="second_lastname"
                        autoFocus
                        error={!!errors.second_lastname}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "El Apellido Materno es requerido",
                          },
                          minLength: {
                            value: 4,
                            message: "Minimo 4 caracteres",
                          },
                          maxLength: {
                            value: 255,
                            message: "Maximo 255 caracteres",
                          },
                          pattern: {
                            value:
                              /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                            message: "Unicamente carácteres alfabéticos",
                          },
                        })}
                      />
                      <p>{errors?.second_lastname?.message}</p>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <InputLabel>Número Telefónico: *</InputLabel>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="number_phone"
                          label="Teléfono"
                          name="number_phone"
                          autoComplete="phone"
                          type="number"
                          autoFocus
                          error={!!errors.number_phone}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El Teléfono es requerido",
                            },
                            minLength: {
                              value: 10,
                              message: "Mínimo 10 digitos",
                            },
                            maxLength: {
                              value: 10,
                              message: "Maximo 10 digitos",
                            },
                          })}
                        />
                        <p>{errors?.number_phone?.message}</p>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputLabel>Ingresa su RFC:</InputLabel>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="RFC"
                          label="RFC"
                          name="RFC"
                          autoComplete="RFC"
                          autoFocus
                          error={!!errors.RFC}
                          inputRef={register({
                            minLength: {
                              value: 10,
                              message: "Minimo 10 caracteres",
                            },
                            maxLength: {
                              value: 13,
                              message: "Maximo 13 caracteres",
                            },
                          })}
                        />
                        <p>{errors?.RFC?.message}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={12} lg={12}>
                      <h3 className={classes.titleSeccion}>Dirección</h3>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={12}>
                        <InputLabel>Ingresa su Calle:</InputLabel>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="street"
                          label="Calle"
                          name="street"
                          autoComplete="street"
                          autoFocus
                          error={!!errors.street}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El Nombre de la Calle es requerida",
                            },
                            minLength: {
                              value: 4,
                              message: "Minimo 4 caracteres",
                            },
                            maxLength: {
                              value: 255,
                              message: "Maximo 255 caracteres",
                            },
                          })}
                        />
                        <p>{errors?.street?.message}</p>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <InputLabel>Ingresa Código Postal: *</InputLabel>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="postal_code"
                          label="Código Postal"
                          name="postal_code"
                          autoComplete="postal_code"
                          type="number"
                          autoFocus
                          error={!!errors.postal_code}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El codigo postal es requerido",
                            },
                            minLength: {
                              value: 5,
                              message: "Mínimo 5 digitos",
                            },
                            maxLength: {
                              value: 5,
                              message: "Maximo 5 digitos",
                            },
                          })}
                        />
                        <p>{errors?.postal_code?.message}</p>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <InputLabel>Ingresa Número Exterior:</InputLabel>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="number_ext"
                          label="Numero Exterior"
                          name="number_ext"
                          autoComplete="number_ext"
                          type="number"
                          autoFocus
                          error={!!errors.number_ext}
                          inputRef={register({
                            minLength: {
                              value: 1,
                              message: "Mínimo 1 digito",
                            },
                            maxLength: {
                              value: 10,
                              message: "Maximo 10 digitos",
                            },
                          })}
                        />
                        <p>{errors?.number_ext?.message}</p>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <InputLabel>Ingresa Número Interior:</InputLabel>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="number_int"
                          label="Numero Interior"
                          name="number_int"
                          autoComplete="number_int"
                          type="number"
                          autoFocus
                          error={!!errors.number_int}
                          inputRef={register({
                            minLength: {
                              value: 1,
                              message: "Mínimo 1 digito",
                            },
                            maxLength: {
                              value: 10,
                              message: "Maximo 10 digitos",
                            },
                          })}
                        />
                        <p>{errors?.number_int?.message}</p>
                      </Grid>
                    </Grid>
                    <Grid container xs={12}>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="outlined"
                          className={classes.submit}
                        >
                          GUARDAR
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>
        </form>
      </div>
    </LayoutDashboard>
  );
}

export default withRouter(ClientAdd);
