import React, { useState, useEffect, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import {
  Button,
  Tooltip,
  Hidden,
  Grid,
  Box,
  Fab,
  Typography,
  IconButton,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { green } from "@material-ui/core/colors";

import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {},
  paper: {
    //padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
    //marginBlockEnd: theme.spacing(1),
  },
  submit: {
    //margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    //margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
}));

export default function PublicClients(props) {
  const classes = useStyles();
  const { id, deleteClient, name } = props;

  const [clients, guardarClient] = useState([]);
  const [cargando, spinnerCargando] = useState(false);

  //query a la api
  useEffect(() => {
    let url = `/clientsBranchPublic/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarClient(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  //console.log(clients)
  // spinner de carga
  if (!cargando) return <Spinner />;
  return (
    <LayoutDashboard>
      {/* titulo */}
      <Grid container >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Grid style={{padding:10}}>
          <Typography component="h1" variant="h5">
            {"Clientes de la sucursal " + name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {"Registro  de Clientes de la sucursal " + name}
          </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            href={"/Agregar_Cliente/" + id}
          >
            <Tooltip title="Agregar Cliente" aria-label="Agregar Cliente">
              <AddIcon size="large" />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid Container  style={{ marginTop: 10, padding:20 }}>
          <MaterialTable
            title={""}
            columns={[
              { title: "Nombre Completo", field: "full_name" },
              { title: "Teléfono", field: "number_phone" },
              { title: "Calle", field: "street" },
              {
                title: "#Ext",
                field: "number_ext",
                render: (rowData) =>
                  rowData.number_ext ? (
                    <p> {rowData.number_ext} </p>
                  ) : (
                    <p> S/N </p>
                  ),
              },
              {
                title: "#Int",
                field: "number_int",
                render: (rowData) =>
                  rowData.number_int ? (
                    <p> {rowData.number_int} </p>
                  ) : (
                    <p> S/N </p>
                  ),
              },
              { title: "C.P", field: "postal_code" },
              {
                title: "RFC",
                field: "RFC",
                render: (rowData) =>
                  rowData.RFC ? <p> {rowData.RFC} </p> : <p> No registrado </p>,
              },
            ]}
            data={clients}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "delete",
                tooltip: "Eliminar cliente",
                onClick: () => deleteClient(rowData.id),
              }),
            ]}
            components={{
              Action: (props) => (
                <Fragment>
                  <Link
                    to={`/Editar_Cliente/${props.data.id}`}
                    style={{ color: "#3f51b5" }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "none",
                        background: "white",
                      }}
                      size="small"
                    >
                      <Tooltip
                        title="Editar Cliente"
                        aria-label="Editar Cliente"
                      >
                        <EditIcon style={{ color: "blue" }} />
                      </Tooltip>
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", background: "white" }}
                    size="small"
                    onClick={() => deleteClient(props.data.id)}
                  >
                    <Tooltip
                      title="Eliminar Cliente"
                      aria-label="Editar Cliente"
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Tooltip>
                  </Button>
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultíma Página",
              },
              toolbar: {
                searchTooltip: "Buscar ",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No hay Clientes que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title={""}
            columns={[
              { title: "Nombre Completo", field: "full_name" },
              { title: "Teléfono", field: "number_phone" },
            ]}
            data={clients}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Calle: {rowData.street}</p>
                      <p>
                        #Ext:{" "}
                        {rowData.number_int ? (
                          <> {rowData.number_int} </>
                        ) : (
                          <> S/N </>
                        )}
                      </p>
                      <p>
                        #Int:{" "}
                        {rowData.number_int ? (
                          <> {rowData.number_int} </>
                        ) : (
                          <> S/N </>
                        )}
                      </p>
                      <p>C.P: {rowData.postal_code}</p>
                      <p>
                        RFC:{" "}
                        {rowData.RFC ? (
                          <> {rowData.RFC} </>
                        ) : (
                          <> No registrado </>
                        )}
                      </p>
                      <p>Opciones: </p>
                      <p>
                        {
                          <Fragment>
                            <Link to={`/Editar_Cliente/${rowData.id}`}>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 50,
                                }}
                                size="small"
                              >
                                <Tooltip
                                  title="Editar Cliente"
                                  aria-label="Editar Cliente"
                                >
                                  <EditIcon style={{ color: "blue" }} />
                                </Tooltip>
                              </IconButton>
                            </Link>
                            <IconButton
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 100,
                              }}
                              size="small"
                              onClick={() => deleteClient(rowData.id)}
                            >
                              <Tooltip
                                title="Eliminar Cliente"
                                aria-label="Editar Cliente"
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </Tooltip>
                            </IconButton>
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultíma Página",
              },
              toolbar: {
                searchTooltip: "Buscar ",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No hay Clientes que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </LayoutDashboard>
  );
}
