import React, { Fragment, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../Config/Services";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";
import { TextField, Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  buttonDiscountCoupon: {
    color: "#fff",
    background: "#44b700",
    border: "none",
    borderRadius: "10px",
    fontSize: "15px",
    padding: "10px",
    width: "100%",
    "&:hover": {
      background: "#44b900",
    },
  },
  couponInput: {
    marginLeft: 7,
    padding: "5px",
    borderRadius: "4px",
    marginRight: "5px",
    width: "100%",
    font: "inherit",
    height: "2.1876em",
    display: "block",
    boxSizing: "content-box",
    border: "1px solid #c1c1c1",
    "&:hover": {
      border: "1px solid #44b700",
    },
  },
}));

export default function DiscountCouponForm(props) {
  const classes = useStyles();

  const {
    saveCouponDiscount,
    disableCouponButton,
    saveCouponInfo,
    branch,
    couponDiscount,
  } = props;
  const [code, saveCode] = useState('');
  const onSubmit = (data, e) => {
    // e.preventDefault();
    let url = `validateCouponSale?code=${code}&branch_id=${branch.id}`;
    MethodGet(url)
      .then((res) => {
        saveCouponDiscount(res.data.data.discount);
        saveCouponInfo(res.data.data);
        toast.success(
          <div>
            <DoneIcon /> Cupón Aplicado Exitosamente!{" "}
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      })
      .catch((error) => {
        toast.warn(
          <div>
            <ErrorIcon /> {error.response.data.error}{" "}
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      });
  };

  const { register, errors, handleSubmit } = useForm();

  return (
    <Fragment>
        {couponDiscount == "" ? (
          <>
            <Grid container xs={12} md={12} xl={12} spacing={1}>
              <Grid container item xs={9} md={9} xl={9}>
                <input
                  className={classes.couponInput}
                  id="code"
                  type="text"
                  name="code"
                  variant="outlined"
                  placeholder="Ingresa el códígo del cupón"
                  onChange={(e)=> {
                    saveCode(e.target.value);
                  }}
                  ref={register({
                    required: {
                      value: true,
                      message: "El Códígo es Requerido",
                    },
                  })}
                />
              </Grid>
              <Grid container item xs={3} md={3} xl={3}>
                <button onClick={onSubmit} className={classes.buttonDiscountCoupon}>
                  Aplicar
                </button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <button
              type="submit"
              disabled
              className={classes.buttonDiscountCoupon}
            >
              Cupón Aplicado
            </button>
          </>
        )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
}
