import React, { useReducer } from 'react';
import DeparturesContext from './DeparturesContext'
import DeparturesReducer from './DeparturesReducer';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../Config/Services";
import { DELETE_PRODUCT_TRANSFER,GET_ALL_BRANCH_DEPARTURES,SHOW_BRANCH_DEPARTURES_PENDING, EDIT_DEPARTURES_PENDING, ADD_NEW_DEPARTURE,GET_ALL_BRANCH_DEPARTURES_PENDING, GET_ALL_USER_DEPARTURES,ADD_NEW_DEPARTURE_BRANCH,GET_ALL_BRANCH_DEPARTURES_BRANCH } from "../../types";
import Swal from "sweetalert2";

const DeparturesState = ({children}) => {
  // estado inicial
  const initialSate = {
    departures: [],
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(DeparturesReducer, initialSate);
  const ShowPendingDeparture = (id) => {
    sessionStorage.removeItem('departure');
    let url = `ShowDepartureTransaction/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: SHOW_BRANCH_DEPARTURES_PENDING,
        payload: response.data.data,
      });
    });
  };
  //obtener la lista de proveedores
  const GetDepartures = (id) => {
    sessionStorage.removeItem('departure');
    let url = `departuresBranch/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_ALL_BRANCH_DEPARTURES,
        payload: response.data.data,
      });
    });
  };
  //obtener la lista de proveedores
  const GetDeparturesPending = (id) => {
    sessionStorage.removeItem('departure');
    let url = `departuresBranchPending/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_ALL_BRANCH_DEPARTURES_PENDING,
        payload: response.data.data,
      });
    });
  };

  const GetDeparturesBranch = (id) => {
    sessionStorage.removeItem('departure');
    let url = `departuresBranchAndBranch/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_ALL_BRANCH_DEPARTURES_BRANCH,
        payload: response.data.data,
      });
    });
  };
  


  const GetDeparturesUser = () => {
    sessionStorage.removeItem('departure');
    let url = `departures`;
    MethodGet(url).then((response) => {
        //console.log(response);
      dispatch({
        type: GET_ALL_USER_DEPARTURES,
        payload: response.data.data,
      });
    });
  };
  const DeleteProductTransfer = (departure) => {
    const formData = new FormData();
		formData.append("id_Product", departure.id_product);

    //console.log(school.image);
    let url = `DeleteIndividualPTransfer/${departure.departure_id}`;

    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: DELETE_PRODUCT_TRANSFER,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        Swal.fire({
			  title: 'Error',
        text: error.response.data.error,
    	  icon: 'error'
		    });
      });
  };

  //función para crear una nueva escuela
  const AddDeparture = (departure) => {
    const formData = new FormData();
		formData.append("authorized_person", departure.authorized_person);
		formData.append("destination_person", departure.destination_person);
		formData.append("description", departure.description);
		formData.append("products_list", departure.products_list);
		formData.append("discount", departure.descuento);
		formData.append("percentage", departure.porcentaje);
		formData.append("branch_office_id", departure.branch_office_id);

    //console.log(school.image);
    let url = "/departures";
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_DEPARTURE,
        });
      })
      .catch((error) => {
        Swal.fire({
			  title: 'Error',
        text: error.response.data.error,
    	  icon: 'error'
		    });
      });
  };

  const AddDepartureperBranch = (departure) => {
    const formData = new FormData();
		formData.append("authorized_person", '');
		formData.append("destination_person", departure.seller);
		formData.append("description", '');
		formData.append("products_list", departure.products_list);
		formData.append("discount", departure.descuento);
		formData.append("percentage", departure.porcentaje);
		formData.append("branch_office_id", departure.branch_office_id);
		formData.append("branchout", departure.branch);
		formData.append("status", departure.status);


    //console.log(school.image);
    let url = "/departureswhitbranch";
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_DEPARTURE_BRANCH,
        });
      })
      .catch((error) => {
        Swal.fire({
			  title: 'Error',
        text: error.response.data.error,
    	  icon: 'error'
		    });
      });
  };

  const EditPendingDeparture = (departure) => {
    const formData = new FormData();
		formData.append("authorized_person", departure.authorized_person);
		formData.append("status", departure.status);
		formData.append("id", departure.id);

    let url = `EditDepartureTransaction/${departure.id}`;
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: EDIT_DEPARTURES_PENDING,
        });
      })
      .catch((error) => {
        Swal.fire({
			  title: 'Error',
        text: error.response.data.error,
    	  icon: 'error'
		    });
      });
  };

  return (
    <DeparturesContext.Provider
      value={{
        departures: state.departures,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        GetDepartures,
        AddDeparture,
        GetDeparturesUser,
        AddDepartureperBranch,
        GetDeparturesBranch,
        GetDeparturesPending,
        ShowPendingDeparture,
        EditPendingDeparture,
        DeleteProductTransfer
      }}
    >
      {children}
    </DeparturesContext.Provider>
  );
}

export default DeparturesState;