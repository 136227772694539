import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import AddAdminSchoolExpense from './AddAdminSchoolExpense';
import AddSellerSchoolExpense from './AddSellerSchoolExpense';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';

export default function AddSchoolExpense(props) {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;
   const { id } = props.match.params;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		  case '1':
			component = <AddAdminSchoolExpense {...props} id={id} />
			break;
		  case '2': 
			component = <AddSellerSchoolExpense {...props} id={id} />
			break;
		  case '3': 
			component = <AddSellerSchoolExpense {...props} id={id} />
			break;
		  default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
