import React, { useState } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Grid, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useForm } from 'react-hook-form';
/**Habilitar redirecciones */
import { withRouter } from 'react-router-dom';

import Swal from 'sweetalert2';

import { MethodPost } from '../../Config/Services';

import SelectProducts from '../SelectOptions/SelectProducts';
import SelectStatusProductStock from '../SelectOptions/SelectStatusProductStock';

const useStyles = makeStyles((theme) => ({
	
root: {
        flexGrow: 1,
    },
    container: {
        paddingBottom: theme.spacing(4),
        paddingInlineEnd: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(30),
    },
    typography: {
        paddingTop: theme.spacing(4),
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(30)

    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(9),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBlockEnd: theme.spacing(1),
    },
    submit: {
        
        background: "#3537DB",
        "&:hover": {
          //you want this to be the same as the backgroundColor above
          background: "#5902CF"
      },
      color: 'white'
	  },
	  ContainerSubmit: {
		marginLeft: theme.spacing(30),
		marginTop: theme.spacing(1)
	},
	formControl: {
		margin: theme.spacing(1),
		width:'100%'
	},
}));


const name_product = [{ title: 'Acetona 100% pura' }];

function ProductStockAdd(props) {
	const classes = useStyles();
	const [nameProduct, saveProduct] = useState({
		id_product: '',
	});

	const [nameTypeClients, saveStatus] = useState({
		status: '',
	});

	const detectarCambiosProduct = (e) => {
		saveProduct({ id_product: e.target.value });
	};

	const detectarCambioStatusProductStock = (e) => {
		saveStatus({ status: e.target.value });
	};
	//Funcion para validaciones
	const { register, handleSubmit, errors } = useForm();

	const onSubmit = (data, e) => {
		e.preventDefault();

		if (nameProduct.id_product !== '' && nameTypeClients.status) {
			const dat = {
				...data,
				...nameProduct,
				...nameTypeClients,
			};

			let url = '/stocks';
			MethodPost(url, dat)
				.then((res) => {
					Swal.fire({
						title: 'Stock de Producto Registrado Exitosamente',
						text: res.data.message,
						icon: 'success',
						timer: 2000,
						showConfirmButton: false,
					});

					props.history.push('/Stock de Productos');
				})
				.catch((error) => {
					Swal.fire({
						title: 'Error',
						text: error.response.data.error,
						icon: 'error',
					});
				});
		} else {
			Swal.fire({
				title: 'Error',
				text: 'Todos los campos son Obligatorios',
				icon: 'error',
			});
		}
	};

	return (
		<LayoutDashboard>
			<div className={classes.root}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className={classes.form}
					noValidate
				>
					<Box>
						<Typography
							component="h1"
							variant="h5"
							align="center"
							className={classes.typography}
						>
							Agregar Nuevo Stock de Producto
						</Typography>
					</Box>
					<Grid container spacing={2} className={classes.container}>
						<Grid item xs={12} sm={6} style={{ paddingTop: 16 }}>
							<FormControl variant="outlined" className={classes.formControl}>
								<SelectProducts
									detectarCambiosProduct={detectarCambiosProduct}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="quantity"
								label="Cantidad"
								name="quantity"
								type="number"
								autoComplete="name"
								error={!!errors.quantity}
								inputRef={register({
									required: {
										value: true,
										message: 'La cantidad es requerida',
									},
									minLength: {
										value: 1,
										message: 'Minimo 1 caracteres',
									},
									maxLength: {
										value: 45,
										message: 'Maximo 45 caracteres',
									},
								})}
							/>
							<p>{errors?.quantity?.message}</p>
						</Grid>

						<Grid item xs={12} sm={6}>
							<FormControl variant="outlined" className={classes.formControl}>
								<SelectStatusProductStock
									detectarCambioStatusProductStock={detectarCambioStatusProductStock}
								/>
							</FormControl>

							</Grid>

							</Grid>
					
					<Grid container spacing={2} >
						<Grid item xs={3} className={classes.ContainerSubmit}>
							<Button
								type="submit"
								fullWidth
								variant="outlined"
								className={classes.submit}
							>
								GUARDAR
							</Button>
						</Grid>
					</Grid>
					</form>
				</div>
			</LayoutDashboard>
		);
}

export default withRouter(ProductStockAdd);

