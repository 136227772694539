import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SelectMonth from '../../SelectOptions/SelectMonth';

const useStyles = makeStyles(() => ({
    content:{
       display:'flex',
       padding:'16px',
       overflow:'auto',
       flexDirection:'column'
    },
    typography:{
       fontSize:'20px',
       fontFamily:'Roboto',
       lineHeight:'1.6',
       margin:'0',
       color: '#1976d2' 
    },
    selectMounth:{
       marginTop:'20px'
    }
 }));
const TableInfoSales = () => {
    const classes = useStyles();
    const currentdate = new Date();
    const datetime = (currentdate.getMonth()+1);

    const [ month , useSelectMonth ] = SelectMonth(datetime);


    return (
        <Fragment>
            <div className={classes.content}>
              <h2 className={classes.typography}>
                  <font>Consulta tus Ventas Por Mes</font>
              </h2>
              <div className={classes.selectMonth}>
                <useSelectMonth/>
              </div>
            </div>
            
        </Fragment>
    );
};

export default TableInfoSales;