import { hexToRgb, blackColor } from "../../material-dashboard-react";

const cardAvatarStyle = {
  cardAvatar: {
    "&$cardAvatarProfile img": {
      width: 195,
      height: 195,
      marginBottom: "-20px",
    },
  },
  cardAvatarProfile: {
    borderRadius: "50%",
    margin: "auto",
    display: "block",
    with: "110%",
    height: "105%",
    maxWidth: "110%",
    maxHeight: "110%",
    backgroundSize: "cover",
    overflow: "hidden",
    objectFit: "cover",
    objectPosition: "center center",
    boxShadow:
      "0 16px 38px -12px rgba(" +
      hexToRgb(blackColor) +
      ", 0.56), 0 4px 25px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    "&$cardAvatarPlain": {
      marginTop: "10px",
      paddingTop: "20px",
    },
  },
  cardAvatarPlain: {},
};

export default cardAvatarStyle;
