import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import ExpensesAdmin from './AdminExpensesSchool';
import ExpensesSubAdmin from './IndexSubAdminExpenses';
import ExpensesSeller from './IndexSellerExpenses';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';

export default function IndexExpensesSchool() {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		case '1':
			component = <ExpensesAdmin />
			break;
		default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
