import React , {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';


const useStyles = makeStyles((theme) => ({
  contentWrapper:{
     display:'flex',
     position:'relative',
     alignItems:'center',
     color:'#fff'
  },
  contentLeft:{
     display:'block'
 },
  contentRigth:{
      marginLeft:'auto'
  },
  widgetHeading:{
      opacity:'.',
      fontWeight:'bold'
  },
  widgetSubheading:{
      opacity:'.6'
  },
  widgetNumber:{
      fontWeight:'bold',
      fontSize:'1.8rem',
      color: '#fff !important;'
  },
}));

const BoxMySales = ({noSales}) => {
    const classes = useStyles();
  

    return (
        <Fragment>
            <div className={classes.contentWrapper}>
                <div className={classes.contentLeft}>
                  <div className={classes.widgetHeading}>
                    Comisiones Generadas
                  </div>
                  <div className={classes.widgetSubheading}>
                    Comisiones Mensuales
                  </div>
                </div>
                <div className={classes.contentRigth}>
                  <div className={classes.widgetNumber}>
                     
                     <span>{noSales}<TrendingUpIcon fontSize="large"/></span>
                  </div> 
                </div>
            </div>
        </Fragment>
        
    );
};

export default BoxMySales;