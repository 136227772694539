import React, { useContext, useEffect, Fragment } from "react";
import AuthContext from "../../Context/autenticacion/authContext";
import AddAdminCategories from "./AddAdmin";
/**importar spinner */
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import { Container, Typography } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  //
  // Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
  container: {
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  // Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    //margin: theme.spacing(0, 1, 2, 2),
  },
}));
export default function AddExpenses(props) {
  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario, usuarioAutenticado } = authContext;
  const classes = useStyles();

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if (usuario) {
    switch (usuario.type) {
      case 1:
        component = <AddAdminCategories {...props} />;
        break;
      case 2:
        component = <AddAdminCategories {...props} />;
        break;
      case 3:
        component = <AddAdminCategories {...props} />;
        break;
      default:
        component = (
          <LayoutDashboard>
            <div className={classes.root}>
              <CssBaseline />
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container}>
        
                </Container>
              </main>
            </div>
          </LayoutDashboard>
        );
        break;
    }
  }
  return <Fragment>{component}</Fragment>;
}
