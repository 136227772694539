import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import DrawerAdmin from './DrawerAdmin';
import DrawerSeller from './DrawerSeller';
import DrawerSubAdmin from './DrawerSubAdmin';
export default function Listitems() {
    //Extraer la informacion de autenticación
    const authContext = useContext(AuthContext);
    const { usuario, usuarioAutenticado } = authContext;
    
    useEffect(() => {
      usuarioAutenticado();
    }, []);
  
  //console.log(usuario)
  let component;
  // renderizado condicional de componentes
  if(usuario){
  switch ( usuario.type) {
    case '1':
      component = <DrawerAdmin/>
      break;
    case '2':
      component = <DrawerSeller/>
      break;   
    case '3':
      component = <DrawerSubAdmin/>
      break;
    default:
      break;
  }
}

  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
