import React, { useState, useEffect } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Grid, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import MethodGet, { MethodPut } from '../../Config/Services';
import Swal from 'sweetalert2';
import Spinner from '../../Complements/Spinner';
import FormControl from '@material-ui/core/FormControl';
import SelectProducts from '../SelectOptions/SelectProducts';
import SelectStatusProductStock from '../SelectOptions/SelectStatusProductStock';
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(30),
	},
	typography: {
		paddingTop: theme.spacing(4),
		margin: theme.spacing(1),
		paddingLeft: theme.spacing(30),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(9),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		background: '#3537DB',
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			background: '#5902CF',
		},
		color: 'white',
	},
	ContainerSubmit: {
		marginLeft: theme.spacing(30),
		marginTop: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
}));

const status = [
	{ title: 'Disponible' },
	{ title: 'Pocas Piezas' },
	{ title: 'Agotado' },
];
const name = [{ title: 'Studio GlamOur' }];
const name_product = [{ title: 'Acetona 100% pura' }];

function ProductStockEdit(props) {
	const classes = useStyles();
	const [cargando, spinnerCargando] = useState(false);

	const { id } = props.match.params;

	//Funcion para validaciones
	const { register, handleSubmit, errors } = useForm();

	// const [operator, setOperator] = useState({});
	const [stocks, saveStock] = useState({});

	//Trabajar con el state de office
	const [productId, saveProduct] = useState({
		id_product: '',
	});

	const [statusStockId, saveStatus] = useState({
		status: '',
	});

	const { quantity } = stocks;

	//Funciones para almacenar el id de Office
	const detectarCambiosProduct = (e) => {
		saveProduct({ id_product: e.target.value });
	};

	const detectarCambioStatusProductStock = (e) => {
		saveStatus({ status: e.target.value });
	};

	const handleChange = (e) => {
		saveStock({ operator: e.target.value });
	};

	

	useEffect(() => {
		let url = `/stocks/${id}`;

		MethodGet(url)
			.then((res) => {
				saveStock(res.data.data);
				saveProduct({ id_product: res.data.data.id_product });
				saveStatus({ status: res.data.data.status });
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const onSubmit = (data, e) => {
		e.preventDefault();

		const dat = {
			...data,
			...productId,
			...statusStockId,
		};

		let url = `/stocks/${id}`; //cambiar la url completa
		MethodPut(url, dat)
			.then((res) => {
				Swal.fire({
					title: 'Cliente Editado Exitosamente',
					text: res.data.message,
					icon: 'success',
					timer: 2000,
					showConfirmButton: false,
				});
				props.history.push('/Stock de Productos');
			})
			.catch((error) => {
				Swal.fire({
					title: 'Error',
					text: error.response.data.error,
					icon: 'error',
				});
			});
	};

	//  spinner de carga
	if (!cargando) return <Spinner />;

	return (
		<LayoutDashboard>
			<div className={classes.root}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className={classes.form}
					noValidate
				>
					<Box>
						<Typography
							component="h1"
							variant="h5"
							align="center"
							className={classes.typography}
						>
							Editar Stock de Producto
						</Typography>
					</Box>
					<Grid container spacing={2} className={classes.container}>
						<Grid item xs={12} sm={6} style={{ paddingTop: 24 }}>
							<FormControl variant="outlined" className={classes.formControl}>
								<SelectProducts
									productId={productId}
									detectarCambiosProduct={detectarCambiosProduct}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="quantity"
								label="Cantidad"
								name="quantity"
								type="number"
								autoComplete="quantity"
								defaultValue={quantity}
								error={!!errors.quantity}
								inputRef={register({
									required: {
										value: true,
										message: 'La cantidad es requerida',
									},
									minLength: {
										value: 1,
										message: 'Minimo 1 caracteres',
									},
									maxLength: {
										value: 45,
										message: 'Maximo 45 caracteres',
									},
								})}
							/>
							<p>{errors?.quantity?.message}</p>
						</Grid>

						<Grid item xs={12} sm={6}>
							<FormControl variant="outlined" className={classes.formControl}>
								<SelectStatusProductStock
									statusStockId={statusStockId}
									detectarCambioStatusProductStock={detectarCambioStatusProductStock}
								/>
							</FormControl>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={3} className={classes.ContainerSubmit}>
							<Button
								type="submit"
								fullWidth
								variant="outlined"
								className={classes.submit}
							>
								ACTUALIZAR
							</Button>
						</Grid>
					</Grid>
				</form>
			</div>
		</LayoutDashboard>
	);
}

export default withRouter(ProductStockEdit);
