import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import Utility from './UtilityAdmin';
import UtilitySeller from './UtilitySeller';
import Spinner from '../../Complements/Spinner';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import { SignalCellularNull } from '@material-ui/icons';

export default function UtilityIndex() {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  if (usuario == null) return <Spinner />;

  let component;
  if(usuario){
    switch (usuario.type) {
		case '1':
			component = <Utility />
			break;
		case '2':
			component = <UtilitySeller usuario={usuario} />
			break;
		case '3':
			component = <UtilitySeller usuario={usuario} />
			break;
		default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
