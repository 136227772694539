import React, { useState, useEffect, Fragment } from 'react';
/**Importar configuracion axios */
import clienteAxios from '../../Config/Axios';
/**Importar componentes select */
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function SelectGraduateCourses(props) {
	const [courses, saveCourses] = useState([]);
	const [error, guardarError] = useState(true);
	useEffect(() => {
		const getOffices = async () => {
			await clienteAxios
				.get(`/graduatecourses/${props.SchoolStorageID}`)
				.then((res) => {
					saveCourses(res.data.data);
				})
				.catch((error) => {
					console.log(error);
				});

			if (props.officeId) {
				guardarError(false);
			}
		};
		getOffices();
	}, []);

	const detectarCambiosGraduateCourses = (value) => {
		if (value.key) {
			guardarError(false);
		}
		props.detectarCambiosGraduateCourses(value);
	};

	const graduatesArray = courses.map(course=>{
		return {key:course.id , value:course.id , label: course.name}
	});

	return (
		<Fragment>

			<Select
            	autofocus
				placeholder="Diplomados"
				fullWidth
      			components={animatedComponents}
				options={graduatesArray}
            	onChange={ value => detectarCambiosGraduateCourses(value)}
			>
			</Select>

			{error ? (
				<p style={{ color: 'red' }}>El diplomado un Campo Requerido</p>
			) : null}
		</Fragment>
	);
}
