import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ReactExport from "react-export-excel";
import { Button, Tooltip, Hidden,Grid } from "@material-ui/core";
import MethodGet from "../../Config/Services";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  buttonExcel: {
    margin: theme.spacing(1),
    backgroundColor: "green",
    borderColor: "#green",
    color: "#fff",
    "&:hover": {
      backgroundColor: "green",
      borderColor: "#green",
    },
  },
  fill: { patternType: "solid", fgColor: { rgb: "FFD3D3D3" } },
}));

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const InventoryProductsExcel = (props) => {
  const classes = useStyles();
  const { id } = props;
  const [products, saveProducts] = useState([]);

  useEffect(() => {
    let url = `inventories/missing/${id}`;
    MethodGet(url)
      .then((res) => {
        saveProducts(res.data.new_products);
        //console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <ExcelFile
      element={
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: 10, marginLeft: 25 }}
          startIcon={<CloudDownloadIcon />}
        >
          Exportar
        </Button>
      }
      filename={"Productos Inventariados"}
    >
      <ExcelSheet data={products} name="Productos Inventariados">
        <ExcelColumn label="codigo" value="tag" className={classes.fill} />
        <ExcelColumn label="producto" value="name" className={classes.fill} />
        <ExcelColumn
          label="descripcion"
          value="description"
          className={classes.fill}
        />
        <ExcelColumn
          label="precio_publico"
          value="price"
          className={classes.fill}
        />
        <ExcelColumn
          label="precio_costo"
          value="price_purchase"
          className={classes.fill}
        />
        <ExcelColumn
          label="cantidad"
          value="cantidad"
          className={classes.fill}
        />
        <ExcelColumn label="imagen" value="image" className={classes.fill} />
        <ExcelColumn
          label="categoria_id"
          value="category_id"
          className={classes.fill}
        />
        <ExcelColumn label="id" value="id" className={classes.fill} />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default InventoryProductsExcel;
