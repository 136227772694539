import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import DeparturesAdmin from './DeparturesAdmin';
import DeparturesSeller from './DeparturesSeller';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import SchoolContext from '../../Reducers/Schools/SchoolContext';

export default function DeparturesIndex(props) {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;
   const { id } = props.match.params;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		case '1':
			component = <DeparturesAdmin id={id}  />
			break;
		case '2':
			component = <DeparturesSeller {...props} />
			break;
		case '3':
			component = <DeparturesSeller {...props} />
			break;
		  default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
