import React, { Fragment, useState, useEffect, useContext } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import { 
	Grid, 
	Box, 
	Paper,
	Container, 
	Tooltip, 
	Button, 
	TextField, 
	Typography, 
	IconButton,
	InputLabel,
	FormControl
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm } from "react-hook-form";
import SchedulesContext from "../../Reducers/Schedules/SchedulesContext";
import SelectIndividualCourse from '../SelectOptions/SelectIndividualCourses';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import EditPhoto from "../../Complements/Image/FormClass/EditPhoto.svg"
import AddFile from "../../Complements/Image/FormClass/AddFile.svg"

import MethodGet from '../../Config/Services';
/**Importar Sweetalert2 * */
import Swal from 'sweetalert2';

/**importar spinner */
import Spinner from '../../Complements/Spinner';
import SelectSchools from '../SelectOptions/SelectSchools';

/** Importar Moment */
import Moment from 'moment';


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingLeft: theme.spacing(10),
		//marginBottom: theme.spacing(10),
	},
	typography: {
		margin: theme.spacing(1),
    marginBottom:theme.spacing(2),
		//paddingLeft: theme.spacing(30),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(9),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		background: 'indigo',
		'&:hover': {
			background: '#5902CF',
		},
		color: 'white',
	},
	formControl: {
		//margin: theme.spacing(1),
		width: '100%',
	},
	input: {
		display: 'none',
	},
	containerImageProfile:{
		display:'flex',
		flexDirection:'row',
		flexWrap:'wrap',
		justifyContent:'space-around',

	},
	PaperFormAdd:{
	background:'white',
	color:'black',
	boxShadow: '2px 4px 4px #cacaca',
	},
	cardCategoryWhite: {
		color: "rgba(255,255,255,.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0"
	  },
	  cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none"
	  },

}));

export default function ScheduleEdit(props) {
  const classes = useStyles();

		const [cargando, spinnerCargando] = useState(false);
    const [schedule, saveSchedule] = useState({});

	const formScheduleContext = useContext(SchedulesContext);
  
  const { ErrorsAPI, success, UpdateSchedule } = formScheduleContext;
  
  let SchoolStorageID = JSON.parse(sessionStorage.getItem("school"));

  const [course, saveCourse] = useState('');
  
    const detectarCambiosIndividualCourses = (e) => {
      saveCourse(e.target.value);
    };

    const {capacity, start_time, end_time, actual_capacity, status} = schedule;

  //obtenemos el producto de session storage
	let ScheduleStorageID = JSON.parse(sessionStorage.getItem("schedule"));

	//Funcion para validaciones
	const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
		  let url = `/schedules/${ScheduleStorageID}`;
	  
		  MethodGet(url)
			  .then((res) => {
				  saveSchedule(res.data.data);
				  saveCourse(res.data.data.course_id)
				  spinnerCargando(true);
			  })
			  .catch((error) => {
				  console.log(error);
			  });
	  }, []);

	const onSubmit = (data, e) => {
		e.preventDefault();
    data.course = course;
    data.id = ScheduleStorageID;

    if(course != '')
    {
      UpdateSchedule(data);
    } else {
      Swal.fire({
        title: 'Error',
        text: "Todos los campos son Obligatorios",
        icon: 'error'
      });
    }

	};

	useEffect(() => {
		//si success retorna verdadero la pagina redireccionara al index de Programaciones
		if (success) {
		  //console.log("Retorna");
		  props.history.push(`/ProgramacionesCursos`); 
		}
	  }, [success]);

	//  spinner de carga
	if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <div className={classes.root}>
        <Container className={classes.container}>
		<Paper className={classes.PaperFormAdd}>
            <form onSubmit={handleSubmit(onSubmit)} className={classes.form} noValidate>
			<GridContainer>
					<GridItem xs={12} sm={12} md={8}>
						<Card>
							<CardHeader color="primary">

            					<h2 className={classes.cardTitleWhite}>Edición De Programación De Cursos</h2>
            					<p className={classes.cardCategoryWhite}>Verifica que los datos que cambies sean correctos</p>
            				</CardHeader>
							<CardBody>
              <Grid container xs={12} sm={12} spacing={2}>
                <Grid item xs={6} sm={6} md={6} style={{marginTop:'15px'}}>
				    <FormControl variant="outlined" className={classes.formControl}>
					    <SelectIndividualCourse
          					course={course}
						    detectarCambiosIndividualCourses={detectarCambiosIndividualCourses}
						    SchoolStorageID={SchoolStorageID}
					    />
				    </FormControl>
			    </Grid>
              <Grid item xs={6} sm={6} md={6} >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="capacity"
                  label="Capacidad Del Modulo"
                  name="capacity"
                  autoComplete="capacity"
                  type="number"
                  defaultValue={capacity}
                  autoFocus
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  error={!!errors.end_time}
                  inputRef={register({
                    required:{
                      value:true,
                      message: 'La Capacidad es requerida',
                    },
                  })}
                />
                <p>{errors?.capacity?.message}</p>
              </Grid>
			  </Grid>
			  	<Grid container spacing={3} justify="center">
						<Grid item spacing={3} xs={12} sm={12} md={6}>
							<Button
								type="submit"
								fullWidth
								variant="outlined"
								className={classes.submit}
							>
								ACTUALIZAR
							</Button>
						</Grid>
					</Grid>
					</CardBody>
					</Card> 
					</GridItem>
					{			/**
 * identificar Codigo de FORMS
 */}
  {			/**
 * identificar Codigo de FORMS
 */}
					<GridItem xs={12} sm={12} md={4}>
        			  	<Card profile style={{marginTop:'26%'}}>
        			    	<CardBody profile>
								<img src={EditPhoto} alt="Foto Editar" width="95%" height="95%"/>
        			    	</CardBody>
        			  	</Card>
        			</GridItem>
				</GridContainer>
            </form>
          </Paper>
				</Container>
      </div>
    </LayoutDashboard>
  );
}