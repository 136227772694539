import React, { Fragment , useEffect, useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

const DatePickerComponent = (props) => {
    const {guardarFecha, label} = props;

    const [selectedDateStart, handleDateChangeStart] = useState(new Date());
    useEffect(() => {
        const DateFormat = Moment(selectedDateStart).format('YYYY-MM-DD');
        guardarFecha(DateFormat);
    }, [selectedDateStart])

    return (
    <Fragment>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
           <KeyboardDatePicker
                autoOk
                fullWidth
                inputVariant="outlined"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date"
                disablePast
                label={label}
                value={selectedDateStart}
                name="date"
                onChange={handleDateChangeStart}
                InputAdornmentProps={{ position: "start" }}
           />
        </MuiPickersUtilsProvider>      
    </Fragment>
    )

}

export default DatePickerComponent;