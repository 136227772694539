import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import Spinner from "../../Complements/Spinner";
import "react-tabs/style/react-tabs.css";
import MethodGet, { MethodDelete, MethodPost } from "../../Config/Services";
import { Button, Grid, Hidden, IconButton, Tooltip } from "@material-ui/core";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ModalPayments from "./ModalPaymentsRest";
import ErrorIcon from "@material-ui/icons/Error";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import headerConfig from "../../Config/imageHeaders";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  table: {
    minWidth: 650,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  iconOptionblue: {
    color: "#0d47a1",
  },
}));

function MisProductosAdmin(props) {
  const classes = useStyles();

  const id = props.id;
  const [sales, saveSale] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  const [error, guardarError] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [totalPagado, guardarTotalPagado] = useState(0);
  const [totalDescuento, guardarTotalDescuento] = useState(0);
  const [r, setR] = useState(false);
  useEffect(() => {
    let url = `/sucursalventas/${id}`;
    MethodGet(url)
      .then((res) => {
        saveSale(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [r]);

  const generarTicket = (id, folio) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Generar El Ticket De Venta?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Generar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        //Cunsume API de Back-end en laravel para generar tiket de venta
        clienteAxios
          .get(`/ticket/${id}`, { responseType: "blob" })
          .then((response) => {
            let pdfname = "Ticket Folio " + folio + ".pdf"
            fileDownload(response.data, pdfname);

            guardarError(false);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              Swal.fire({
                icon: "error",
                title: "Algo sucedio",
                text: "No se cuenta con suficiente información para generar el reporte",
              });
            } else if (error.response.status === 422) {
              guardarError(true);
            }
          });
      }
    });
  };

  const DeleteSale = (id) => {
    //console.log(id, 'idsale')
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La venta ya no se podrá recuperar, los productos pasarán en existencia y los pagos serán eliminados",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        spinnerCargando(false);
        ///Eliminar en la Api
        let url = `sales/${id}`;
        MethodDelete(url).then((response) => {
          if (response.status === 200) {
            const CambioIndexSales = sales.filter((sale) => sale.id !== id);
            saveSale(CambioIndexSales);
            spinnerCargando(true);
            Swal.fire("Venta Eliminado", response.data.message, "success");
          }
        });
      }
    });
  };
  const [data, setData] = useState({});
  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };
  const obtenerInformacionPago = (e) => {
    guardarMetodoPago({
      ...informacionMetodoPago,
      [e.target.name]: e.target.value,
    });
  };
  const [disableButton, setDisabledButton] = useState(false);
  const [informacionMetodoPago, guardarMetodoPago] = useState({
    amount: "0",
    change: "0",
    cash_income: "0",
    card_income: "0",
    transfer_income: "0",
    reference: "",
    invoice: "",
  });
  const [saleID, saveSaleID] = useState(0);
  const [currentSale, SaveCurrentSale] = useState(0);

  const SelectSalePayment = (saleID, total, total_paid) => {
    SaveCurrentSale(saleID);
    guardarTotalDescuento(total);
    guardarTotalPagado(total_paid);
    setOpen(true);
  };
  const handleClose = () => {
    guardarMetodoPago({
      amount: "",
      change: "0",
      cash_income: "0",
      card_income: "0",
      transfer_income: "0",
    });
    setOpen(false);
    setDisabledButton(false);
  };

  const addPayment = (e) => {
    if (
      totalDescuento < informacionMetodoPago.amount &&
      informacionMetodoPago.cash_income < totalDescuento
    ) {
      toast.warn(
        <div>
          <ErrorIcon /> No puedes pagar mas del monto total
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.amount < 0) {
      toast.warn(
        <div>
          <ErrorIcon /> No puedes ingresar valores negativos
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.card_income > 0) {
      if (image.image == "") {
        toast.warn(
          <div>
            <ErrorIcon /> Debes seleccionar la imagen del ticket de pago por
            tarjeta
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
    } else if (informacionMetodoPago.transfer_income > 0) {
      if (informacionMetodoPago.reference == "") {
        toast.warn(
          <div>
            <ErrorIcon /> Debes introducir una referencia válida
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
    }
    setDisabledButton(true);
    data.id = saleID;
    data.cash_income = informacionMetodoPago.cash_income;
    data.card_income = informacionMetodoPago.card_income;
    data.transfer_income = informacionMetodoPago.transfer_income;
    data.image = image.image;
    data.change = informacionMetodoPago.change;
    data.reference = informacionMetodoPago.reference;
    data.invoice = informacionMetodoPago.invoice;
    AddInPayment(data);
    handleClose();
  };

  const AddInPayment = (data) => {
    const formData = new FormData();
    formData.append("card_income", data.card_income);
    formData.append("cash_income", data.cash_income);
    formData.append("transfer_income", data.transfer_income);
    if (data.card_income > 0) {
      formData.append("image", data.image);
    }
    if (data.transfer_income > 0) {
      formData.append("reference", data.reference);
    }
    if (data.change > 0) {
      formData.append("change", data.change);
    }
    let url = `addPayments/${data.id}`;
    MethodPost(url, formData, { headerConfig })
      .then((response) => {
        let folio = response.data.data.folio;
        let timerInterval;
        Swal.fire({
          title: "Pago Registrado",
          html: "El ticket se esta generando",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          //Generar tiket de pago
          clienteAxios
            .get(`/ticketp/${data.id}`, { responseType: "blob" })
            .then((response) => {
              let pdfname = "AbonoVenta" + folio + ".pdf";
              fileDownload(response.data, pdfname);
              // Swal.fire({
              //   title: t("Ticket generado"),
              //   icon: "success",
              //   timer: 3000,
              //   showConfirmButton: false,
              // });
            })
            .catch((error) => {
              if (error.response.status === 409) {
                Swal.fire({
                  icon: "error",
                  title: "Error inesperado. Si persiste, contacta con el administrador",
                  text: "No cuentas con suficiente información para generar reporte",
                });
                return;
              } else if (error.response.status === 422) {
                console.log(error);
                return;
              }
              return;
            });
        });
        setR(true);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <>
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Folio", field: "folio" },
              {
                title: "Cliente", field: "full_name",
                render: (rowData) => {
                  return (
                    <Fragment>
                      <p>{rowData.full_name}</p>{" "}
                    </Fragment>
                  );
                },
              },
              { title: "Fecha Realizada", field: "created_at", type: "date", },
              { title: "Vendedor", field: "seller" },
              {
                title: "Total",
                field: "total",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.total),
              },
              {
                title: "Total Pagado",
                field: "total_paid",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.total_paid),
              },
              {
                title: "Cambio",
                field: "change",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.change ? "$" + rowData.change : <p>No Aplica</p>}
                    </Fragment>
                  );
                },
              },
              {
                title: "Cupón",
                field: "coupon",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.coupon ? rowData.coupon.code : <p>No Aplica</p>}
                    </Fragment>
                  );
                },
              },
            ]}
            data={sales}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: "search",
                tooltip: "Detalle de venta",
                onClick: (rowData) =>
                  (window.location.href = `/DetalleVenta/${rowData.id}`),
              },
            ]}
            components={{
              Action: (props) => (
                <Fragment>
                  <Link
                    to={`/DetalleVenta/${props.data.id}`}
                    style={{ color: "#3f51b5" }}
                  >
                    <Button style={{ textTransform: "none" }} size="small">
                      <Tooltip
                        title="Detalle de venta"
                        aria-label="Detalle de venta"
                      >
                        <VisibilityIcon className={classes.iconOptionblue} />
                      </Tooltip>
                    </Button>
                  </Link>
                  {props.data.total > props.data.total_paid && (
                    <Button
                      variant="contained"
                      style={{ textTransform: "none", background: "white" }}
                      size="small"
                      type="submit"
                      onClick={() =>
                        {saveSaleID(props.data.id);
                        SelectSalePayment(
                          props.data.id,
                          props.data.total,
                          props.data.total_paid
                        )
                      }
                      }
                    >
                      <Tooltip title="Agregar pago" aria-label="Agregar Pago">
                        <MonetizationOnIcon style={{ color: "indigo" }} />
                      </Tooltip>
                    </Button>
                  )}
                  <Button
                    style={{ textTransform: "none" }}
                    size="small"
                    onClick={() =>
                      generarTicket(props.data.id, props.data.folio)
                    }
                  >
                    <Tooltip
                      title="Generar ticket de venta"
                      aria-label="Generar ticket de venta"
                    >
                      <PictureAsPdf style={{ color: "indigo" }} />
                    </Tooltip>
                  </Button>
                  <Button
                    style={{ textTransform: "none" }}
                    size="small"
                    onClick={() => DeleteSale(props.data.id)}
                  >
                    <Tooltip title="Eliminar Venta" aria-label="Eliminar Venta">
                      <DeleteIcon style={{ color: "red" }} />
                    </Tooltip>
                  </Button>
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultíma Página",
              },
              toolbar: {
                searchTooltip: "Buscar ",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No hay Ventas que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Folio", field: "folio" },
              {
                title: "Cliente", field: "full_name",
                render: (rowData) => {
                  return (
                    <Fragment>
                      <p>
                        {rowData.full_name}
                      </p>{" "}
                    </Fragment>
                  );
                },
              },
              { title: "Vendedor: ", field: "seller", hidden: true, searchable: true },
              { title: "Total: ", field: "total", hidden: true, searchable: true },
              { title: "Total Pagado: ", field: "total_paid", hidden: true, searchable: true },
              { title: "Cambio: ", field: "change", hidden: true, searchable: true },
            ]}
            data={sales}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Vendedor: {rowData.seller}</p>
                      <p>Total: ${rowData.total}</p>
                      <p>Total Pagado: ${rowData.total_paid}</p>
                      <p>
                        Cambio:{" "}
                        {
                          <Fragment>
                            {rowData.change
                              ? "$" + rowData.change
                              : "No Aplica"}
                          </Fragment>
                        }
                      </p>
                      <p>
                        Cupón:{" "}
                        {
                          <>
                            <Fragment>
                              {rowData.coupon ? (
                                rowData.coupon.code
                              ) : (
                                <>No Aplica</>
                              )}
                            </Fragment>
                          </>
                        }
                      </p>
                      <p> Opciones:</p>
                      <p>
                        {
                          <Fragment>
                            <Link
                              to={`/DetalleVenta/${rowData.id}`}
                              style={{ color: "#3f51b5" }}
                            >
                              <Button
                                style={{ textTransform: "none" }}
                                size="small"
                              >
                                <Tooltip
                                  title="Detalle de venta"
                                  aria-label="Detalle de venta"
                                >
                                  <VisibilityIcon
                                    className={classes.iconOptionblue}
                                  />
                                </Tooltip>
                              </Button>
                            </Link>
                            {rowData.total > rowData.total_paid && (
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 40,
                                }}
                                size="small"
                                type="submit"
                                onClick={() =>
                                  {saveSaleID(rowData.id);
                                    SelectSalePayment(
                                      props.data.id,
                                      props.data.total,
                                      props.data.total_paid
                                    )
                                  }
                                }
                              >
                                <Tooltip
                                  title="Agregar Pago"
                                  aria-label="Agregar Pago"
                                >
                                  <MonetizationOnIcon
                                    style={{ color: "indigo" }}
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                            <Button
                              style={{ textTransform: "none" }}
                              size="small"
                              onClick={() =>
                                generarTicket(rowData.id, rowData.folio)
                              }
                            >
                              <Tooltip
                                title="Generar ticket de venta"
                                aria-label="Generar ticket de venta"
                              >
                                <PictureAsPdf style={{ color: "indigo" }} />
                              </Tooltip>
                            </Button>
                            <Button
                              style={{ textTransform: "none" }}
                              size="small"
                              onClick={() => DeleteSale(rowData.id)}
                            >
                              <Tooltip
                                title="Eliminar Venta"
                                aria-label="Eliminar Venta"
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </Tooltip>
                            </Button>
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultíma Página",
              },
              toolbar: {
                searchTooltip: "Buscar ",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No hay Ventas que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <ModalPayments
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        totalDescuento={totalDescuento}
        totalPagado={totalPagado}
        informacionMetodoPago={informacionMetodoPago}
        image={image}
        obtenerInformacionPago={obtenerInformacionPago}
        addPayment={addPayment}
        handleChangeImage={handleChangeImage}
        disableButton={disableButton}
      />
    </>
  );
}

export default MisProductosAdmin;
