import React, { useState, useEffect, Fragment } from 'react';
/**Importar configuracion axios */
import clienteAxios from '../../Config/Axios';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function SelectSchools(props) {
	const [schools, saveSchool] = useState([]);
	const [error, guardarError] = useState(true);
	useEffect(() => {
		const getSchools = async () => {
			await clienteAxios
				.get('/schools')
				.then((res) => {
					saveSchool(res.data.data);
				})
				.catch((error) => {
					console.log(error);
				});

			if (props.school) {
				guardarError(false);
			}
		};
		getSchools();
	}, []);

	const detectarCambiosSchool = (e) => {
		if (e.target.value) {
			guardarError(false);
		}
		props.detectarCambiosSchool(e);
	};

	return (
		<Fragment>
			<InputLabel fullWidth>Asignar Escuela</InputLabel>
			{props.school ? (
				<Select
					label="Escuelas"
					fullWidth
					onChange={detectarCambiosSchool}
					value={props.school.school_id}
				>
					<MenuItem disabled>Selecciona la Escuela</MenuItem>
					{schools.map((school) => (
						<MenuItem key={school.id} value={school.id} name={school.name}>
							{school.name}
						</MenuItem>
					))}
				</Select>
			) : (
				<Select label="Escuelas" fullWidth onChange={detectarCambiosSchool}>
					<MenuItem disabled>Selecciona la Escuela</MenuItem>
					{schools.map((school) => (
						<MenuItem key={school.id} value={school.id} name={school.name}>
							{school.name}
						</MenuItem>
					))}
				</Select>
			)}

			{error ? (
				<p style={{ color: 'red' }}>La Escuela es un Campo Requerido</p>
			) : null}
		</Fragment>
	);
}
