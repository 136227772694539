import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ReactExport from "react-export-excel";
import ProductContext from "../../Reducers/Products/ProductContext";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  buttonExcel: {
    margin: theme.spacing(1),
    backgroundColor: "green",
    borderColor: "#green",
    color: "#fff",
    "&:hover": {
      backgroundColor: "green",
      borderColor: "#green",
    },
  },
  fill: { patternType: "solid", fgColor: { rgb: "FFD3D3D3" } },
}));

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ProductsExportExcel = (props) => {
  const classes = useStyles();
  const { id, branch } = props;

  //Extraer los productos del state inicial
  const ProductFunctions = useContext(ProductContext);
  const { getProductsDataExcelSale, productsDataExcel } = ProductFunctions;
  useEffect(() => {
    getProductsDataExcelSale(id);
  }, [id]);
  let prod = [];
  productsDataExcel.map((p, index) => {
    prod.push({
      id: p.id,
      name: p.name,
      categorie: p.categoria,
      subcategorie: p.subcategoria,
      codigo: p.tag,
      descripcion: p.description,
      /* ubicacion: p.ubication, */
      precio_publico: p.price,
      precio_costo: p.price_purchase,
      cantidad: p.quantity,
      cantidadsuma: 0,
      type: p.type,
    });
  });
  return (
    <ExcelFile
      element={
        <Tooltip title="Descargar Plantilla">
          <IconButton variant="outlined" fullWidth color="secondary">
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      }
      filename={"Plantilla_venta_excel"}
    >
      <ExcelSheet data={prod} name="Productos">
        <ExcelColumn
          label="product_id"
          value="id"
          className={classes.fill}
          isBold
        />
        <ExcelColumn
          label="tag"
          value="codigo"
          className={classes.fill}
          isBold
        />
        <ExcelColumn
          label="name"
          value="name"
          className={classes.fill}
          isBold
        />
          <ExcelColumn
          label="categoria"
          value="categorie"
          className={classes.fill}
          isBold
        />
          <ExcelColumn
          label="subcategoria"
          value="subcategorie"
          className={classes.fill}
          isBold
        />
        <ExcelColumn
          label="description"
          value="descripcion"
          className={classes.fill}
          isBold
        />
        <ExcelColumn
          label="price"
          value="precio_publico"
          className={classes.fill}
          isBold
        />
        <ExcelColumn
          label="stock_disponible"
          value="cantidad"
          className={classes.fill}
          isBold
        />
        <ExcelColumn
          label="type"
          value="type"
          className={classes.fill}
          isBold
        />
        <ExcelColumn
          label="cantidad"
          value="cantidadsuma"
          className={classes.fill}
          isBold
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ProductsExportExcel;
