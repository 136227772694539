import React, { Fragment, useState, useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {
  FormControl,
  Grid,
  Paper,
  Box,
  TextField,
  InputLabel,
} from "@material-ui/core";
import Swal from "sweetalert2";
import MethodGet, { MethodPost } from "../../Config/Services";
import { useForm } from "react-hook-form";
import ClientsContext from "../../Reducers/Clients/ClientsContext";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SchedulesContext from "../../Reducers/Schedules/SchedulesContext";
import SelectIndividualCourses from "../SelectOptions/SelectIndividualCourses";
import IndividualCoursesList from "../Schedule/IndividualCoursesList";
import IndividualClassesList from "../Schedule/IndividualClassesList";
import SelectGraduateCourses from "../SelectOptions/SelectGraduateCourses";

/**importar spinner */
import Spinner from "../../Complements/Spinner";
/**componente DatePicker */
import DatePickerComponent from "../Schedule/DatePicker";

/** Importar Moment */
import Moment from "moment";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ModalInscriptionGraduate({ modal, handleClose, id }) {
  const classes = useStyles();

  const [Fecha, guardarFecha] = useState(Moment().format("YYYY-MM-DD"));
  const [FechaFinal, guardarFechaFinal] = useState(
    Moment().format("YYYY-MM-DD")
  );

  const [cargando, spinnerCargando] = useState(false);

  const [capacity, saveCapacity] = useState(0);

  const [courseInfo, setCourseInfo] = React.useState("");

  const [disableButton, saveDisableButton] = useState(false);

  const handleChangeCapacity = (e) => {
    saveCapacity(e.target.value);
  };

  const formScheduleContext = useContext(SchedulesContext);

  const { ErrorsAPI, success, AddSchedule } = formScheduleContext;

  const [school, saveSchool] = useState({});

  const [courses, saveCourses] = useState([]);

  //Extraer valores de branch(destructuracion)
  const { name } = school;

  const [individualCourse, saveIndividualCourse] = useState("");

  const detectarCambiosIndividualCourses = (e) => {
    saveIndividualCourse(e.target.value);
  };

  const [graduateCourse, saveGraduateCourse] = useState("");

  const detectarCambiosGraduateCourses = (value) => {
    saveGraduateCourse(value.key);
  };

  const [classroom, saveClassroom] = useState("");

  const detectarCambiosClassrooms = (e) => {
    saveClassroom(e.target.value);
  };

  //obtenemos la escuela de session storage
  let SchoolStorageID = JSON.parse(sessionStorage.getItem("school"));

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${SchoolStorageID}`;
    MethodGet(url)
      .then((res) => {
        saveSchool(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //obtener los cursos de un diplomado
  useEffect(() => {
    let url = `/coursesDetails/${graduateCourse}`;
    MethodGet(url)
      .then((res) => {
        saveCourses(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [graduateCourse]);

  const onSubmit = (data, e) => {
    data.course = graduateCourse;
    var courses_list = JSON.stringify(courses);
    data.courses_list = courses_list;
    data.type = 2;
    if (data.course != "") {
      AddSchedule(data);
    } else {
      Swal.fire({
        title: "Error",
        text: "Todos los campos son Obligatorios",
        icon: "error",
      });
    }
    handleClose();
  };

 

 
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modal}
        maxWidth={1200}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Registrar programacion de Diplomado
        </DialogTitle>
        <DialogContent dividers>
          <Paper className={classes.PaperFormAdd}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
            noValidate
          >
            <Box>
              <Typography
                component="h1"
                variant="h5"
                align="center"
                className={classes.typography}
              >
                Agregar Programacion de Curso En La Escuela {name}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ marginTop: "25px" }}
                >
                  <SelectGraduateCourses
                    detectarCambiosGraduateCourses={
                      detectarCambiosGraduateCourses
                    }
                    SchoolStorageID={SchoolStorageID}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="capacity"
                  label="Capacidad Del Diplomado"
                  name="capacity"
                  onChange={handleChangeCapacity}
                  autoComplete="capacity"
                  type="number"
                  defaultValue="0"
                  autoFocus
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  error={!!errors.end_time}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "La Capacidad es requerida",
                    },
                  })}
                />
                <p>{errors?.end_time?.message}</p>
              </Grid>
              <Grid item xs={12}>
                <IndividualCoursesList
                  courses={courses}
                  saveCourses={saveCourses}
                  SchoolStorageID={SchoolStorageID}
                  capacity={capacity}
                  saveDisableButton={saveDisableButton}
                />
              </Grid>
            </Grid>
            {!disableButton ? (
              <Grid
                container
                spacing={3}
                justify="center"
                style={{ marginBottom: 10 }}
              >
                <Grid item spacing={3} xs={12} sm={12} md={6}>
                  <Button
                    style={{ color: "white", background: "grey" }}
                    type="submit"
                    fullWidth
                    disabled
                    variant="outlined"
                    className={classes.submit}
                  >
                    GUARDAR DIPLOMADO
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                spacing={3}
                justify="center"
                style={{ marginBottom: 10 }}
              >
                <Grid item spacing={3} xs={12} sm={12} md={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    className={classes.submit}
                  >
                    GUARDAR DIPLOMADO
                  </Button>
                </Grid>
              </Grid>
            )}
          </form>
          </Paper>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default ModalInscriptionGraduate;
