import React, { useState, useEffect, Fragment, useContext } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import ImageProduct from "../Sales/ImageProduct";
import ProductInventory from "../Sales/ProductInventory";
import { Grid } from "@material-ui/core";
import MethodGet from "../../Config/Services";
import Swal from "sweetalert2";
import { makeStyles } from "@material-ui/core/styles";
import InventoriesContext from "../../Reducers/Inventories/InventoriesContext";

const useStyles = makeStyles((theme) => ({
  image: {
    width: 250,
    height: 300,
    display: "block",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "80%",
    maxHeight: "100%",
  },
}));

const ProductInfo = ({
  productoID,
  ventas,
  guardarVenta,
  guardarProductoID,
  box,
  id,
}) => {
  const [cargandoAnimacion, guardarAnimacion] = useState(false);
  const [producto, guardarProducto] = useState({});
  const [cantidad, guardarCantidad] = useState(1);
  const [errorCantidad, guardarError] = useState(false);
  const classes = useStyles();
  const InventoriesContextList = useContext(InventoriesContext);
  const { AddProductInventory } = InventoriesContextList;

  useEffect(() => {
    guardarAnimacion(true);
    setTimeout(() => {
      guardarAnimacion(false);
    }, 3000);
  }, []);
  //console.log(productoID,'info')
  let url = `/products/${productoID}`;
  useEffect(() => {
    if (productoID) {
      MethodGet(url)
        .then((response) => {
          guardarProducto(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [productoID]);
  const AgregarProductosState = () => {

    let productoResultado = producto;
    //generar una llave unica para cada producto
    productoResultado.product_id = producto.id;
    //generar cantidad productos por adquirir
    productoResultado.cantidad = Number(cantidad);
    //generar precio final por producto
    productoResultado.final_price = Number(cantidad) * Number(producto.price);
    //guardarlo en el state
    guardarVenta([productoResultado, ...ventas]);
    //recetear el state del producto
    guardarProductoID("");
    guardarProducto({});
    guardarCantidad(1);

    var data = {};
    data.inventory_id = id;
    data.product_id = producto.id;
    AddProductInventory(data);
  };

  return (
    <Grid container>
      {box == 1 ? (
        <Grid item xs={12} md={12} xl={12} className={classes.image}>

        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={12}>
            <ProductInventory
              producto={producto}
              AgregarProductosState={AgregarProductosState}
              guardarCantidad={guardarCantidad}
              errorCantidad={errorCantidad}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProductInfo;
