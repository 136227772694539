import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ReactExport from "react-export-excel";
import ProductContext from "../../Reducers/Products/ProductContext";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonExcel: {
    margin: theme.spacing(1),
    backgroundColor: "green",
    borderColor: "#green",
    color: "#fff",
    "&:hover": {
      backgroundColor: "green",
      borderColor: "#green",
    },
  },
  fill: { patternType: "solid", fgColor: { rgb: "FFD3D3D3" } },
}));

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ProductsExportExcelempty = () => {
  const classes = useStyles();

  //Extraer los productos del state inicial
  const ProductFunctions = useContext(ProductContext);
  const { productsDataExcel} = ProductFunctions;
  return (
    <ExcelFile
      element={
        <Button
          variant="outlined"
          startIcon={<CloudDownloadIcon />}
          fullWidth
          color="secondary"
          style={{ margin: 5 }}
        >
          Plantilla para Nuevos Productos
        </Button>
      }
      filename={"Productos Nuevos Sucursal"}
    >
      <ExcelSheet data={productsDataExcel} name="Productos"   >
        <ExcelColumn label="codigo" className={classes.fill}  />
        <ExcelColumn label="producto" className={classes.fill} />
        <ExcelColumn label="descripcion" className={classes.fill} />
        {/* <ExcelColumn label="ubicacion" className={classes.fill} /> */}
        <ExcelColumn label="precio_publico" className={classes.fill} />
        <ExcelColumn label="precio_costo" className={classes.fill} />
        <ExcelColumn label="cantidad" className={classes.fill} />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ProductsExportExcelempty;
