import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import OfficeStocksAdmin from '../Products_Stocks/OfficeStocksAdmin';
import OfficeStocksSubAdmin from '../Products_Stocks/OfficeStocksSubAdmin';

export default function OfficeProducts(props) {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { id } = props.match.params;
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		case '1':
			component = <OfficeStocksAdmin id={id} />
			break;
    	case '3': 
			component = <OfficeStocksAdmin id={id} />
			break;
		default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
