import React, { useState, useEffect, Fragment, useContext } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { Button, Tooltip,Hidden } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardsUtility from './CardsUtility';

import ClassroomContext from '../../Reducers/Classrooms/ClassroomContext';
/**Importar metodo Get */
import MethodGet, { MethodDelete } from '../../Config/Services';
/**Importar Sweetalert2 * */
import Swal from 'sweetalert2';
/**importar spinner */
import Spinner from '../../Complements/Spinner';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(5),
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(4),
		//paddingInlineEnd: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		//marginBlockEnd: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	input: {
		display: 'none',
	},
	typography: {
		margin: theme.spacing(0, 1, 2, 2),
	},
	// Estilo de tablas Container
	titleTbl:{
		//backgroundColor:'#8115CF',
		background:'linear-gradient(50deg, #3f51b5 33%, indigo 100%)',
		color:'white',
		fontSize:'large',
		height:'60px',
		borderRadius:'5px',
		paddingTop:'10px',
		marginBottom:'20px',
		whiteSpace: 'nowrap',

	},
	btnPlus:{
		display:'absolute',
		marginTop:'10%',
		marginRight:'-5%',
		zIndex:2,
		position: 'relative',
	},
	fabGreen: {
		color: theme.palette.common.white,
		backgroundColor: green[500],
		'&:hover': {
		  backgroundColor: green[600],
		},
	  },

}));

const BranchUtilitySeller = (props) => {
    const classes = useStyles();

    const {usuario} = props;
    
    const [cargando, spinnerCargando] = useState(false);

    const [branch, saveBranch] = useState({});

    //Extraer valores de branch(destructuracion)
	const { name } = branch;


  //obtener los campos de la sucursal
  useEffect(() => {
        let url = `/branches/${usuario.id_branch_office}`;
        MethodGet(url)
            .then((res) => {
                saveBranch(res.data.data);
                //spinnerCargando(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [usuario]);

	//  spinner de carga
	

	return (
		<LayoutDashboard>
		      {/**
       *
       * Aqui renderiza los titulos de esta view dependiendo la medida
       */}
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        justify="center"
        style={{ marginTop: 70 }}
      >
        <Typography component="h3" variant="h3" align="center">
          {"Total de efectivo de la sucursal " + name}
        </Typography>
      </Grid>
      {/**
       *
       * Aqui es donde aplica el cambio de cards que renderizan las sucursales para los clientes
       */}
      <Grid container>
	  <CardsUtility usuario={usuario.type} schoolID={usuario.id_branch_office} />
      </Grid>
		</LayoutDashboard>
	);
};

export default BranchUtilitySeller;
