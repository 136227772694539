import React, { useState, useEffect, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import {
  Button,
  Tooltip,
  Fab,
  Typography,
  Grid,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { green } from "@material-ui/core/colors";
import ModalAdd from "../Branch_Offices/ModalAdd";
import ModalRemove from "../Branch_Offices/ModalRemove";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Icon from "@material-ui/icons/PictureAsPdf";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import FormControl from "@material-ui/core/FormControl";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import DateFnsUtils from "@date-io/date-fns";
import PrintIcon from "@material-ui/icons/Print";
import ProductsExportExcelStock from "../Products/ProductsExportExcelStock";
import MethodGet from "../../Config/Services";
import Swal from "sweetalert2";
import Spinner from "../../Complements/Spinner";
import Editproduct from "./Editproduct";
import EditIcon from "@material-ui/icons/Edit";
import ModalImportStock from "./ModalImportStock";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  Link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  reportmodal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reportpaper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    width: "80%",
    height: "60%",
    padding: theme.spacing(2, 1, 1, 3),
  },
}));

const OfficeStocksAdmin = (props) => {
  const classes = useStyles();

  //sucursales = state, saveStocks =funcion para guardar en el state;
  const [stocks, saveStocks] = useState([]);
  const id = props.id; //Se toma el ID de la Sucursal para mostrar sus stocks
  const [cargando, spinnerCargando] = useState(false);

  //abrirModal
  const [modalAdd, abrirModalAdd] = useState(false);
  const [modalRemove, abrirModalRemove] = useState(false);
  const [stockid, editarStockId] = useState("");
  const [productId, editarProductId] = useState(null);

  const [branch, guardarBranch] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name } = branch;

  const [openImp, setOpenImp] = useState(false);

  const handleOpen = () => {
    setOpenImp(true);
  };

  const handleClose = () => {
    setOpenImp(false);
  };

  const handleClickOpenRemove = (id) => {
    editarStockId(id);
    abrirModalRemove(true);
  };

  const handleClickOpenAdd = (id) => {
    editarStockId(id);
    abrirModalAdd(true);
  };

  const handleCloseRemove = () => {
    abrirModalRemove(false);
  };

  const handleCloseAdd = () => {
    abrirModalAdd(false);
  };

  const [actualizar, actualizarStock] = useState(false);

  const actualizarCambios = () => {
    editarStockId("");
    actualizarStock(true);
  };

  //query a la api
  useEffect(() => {
    let url = `/sucursalstocks/${id}`;
    MethodGet(url)
      .then((res) => {
        saveStocks(res.data.data);
        spinnerCargando(true);
        actualizarStock(false);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [actualizar, openImp]);

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  const [error, guardarError] = useState(false);
  const [nameOffice, guardarSucursal] = useState({
    id_branch_office: "",
  });

  const GenerarReporteGlobal = (e) => {
    e.preventDefault();
    let timerInterval;
    Swal.fire({
      title: "Generando",
      html: "El reporte se está generando",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      willClose: () => {
        clearInterval(timerInterval);
      },
    })
    clienteAxios
      .get("/reporte-productos-general", { responseType: "blob" })
      .then((response) => {
        fileDownload(response.data, "Reporte General.pdf");
        guardarError(false);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Algo sucedio",
            text: "No cuentas con ventas suficientes el dia de hoy para generar el corte de caja",
          });
        } else if (error.response.status === 422) {
          guardarError(true);
        }
      });
  };

  const detectarCambiosOffice = (e) => {
    guardarSucursal({ id_branch_office: e.target.value });
  };

  const GenerarReporteOffice = (e) => {
    e.preventDefault();

    if (nameOffice.id_branch_office !== "") {
      const data = {
        branch_office_id: nameOffice.id_branch_office,
      };
      let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El report se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
      clienteAxios
        .post("/reporte-productos-sucursal", data, { responseType: "blob" })
        .then((response) => {
          fileDownload(
            response.data,
            "Reporte Stock Productos por Sucursal.pdf"
          );
          guardarError(false);
        })
        .catch((error) => {
          if (error.response.status === 409) {
            Swal.fire({
              icon: "error",
              title: "Algo sucedio",
              text: "No se cuenta con suficiente información para generar el reporte",
            });
          } else if (error.response.status === 422) {
            guardarError(true);
          }
        });
    }
  };

  const [open, setOpen] = React.useState(false);

  const reportmodalOpen = () => {
    setOpen(true);
  };

  const reportmodalClose = () => {
    setOpen(false);
  };
  //modal sALONS
  const [openModalEdit, setOpenModalEdit] = React.useState(false);

  const handleClickOpenModalEdit = (id_product) => {
    editarProductId(id_product);
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    editarProductId(null);
  };
  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* titulo */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 90,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
            marginBottom: -50,
          }}
        >
          <Typography
            style={{ marginTop: 10 }}
            className={classes.typography}
            component="h1"
            variant="h4"
          >
            {"Stocks de la Sucursal " + name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -40 }}
        >
          <Fab
            aria-label="back"
            fontSize="medium"
            onClick={reportmodalOpen}
            className={classes.fabGreen}
          >
            <Tooltip
              title="Generar Reporte de Stock"
              aria-label="Generar Reporte de Stock"
            >
              <PrintIcon />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} md={6} style={{ padding: 40 }}>
          <ProductsExportExcelStock id={id} />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 35 }}>
          <Button
            variant="outlined"
            onClick={handleOpen}
            startIcon={<CloudUploadIcon />}
            fullWidth
            color="primary"
            style={{ margin: 5 }}
          >
            Importar Stock
          </Button>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.reportmodal}
        open={open}
        onClose={reportmodalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.reportpaper}>
            <Grid container xs={12} xl={12}>
              <Grid item xs={11} xl={11} justify="center">
                <Typography variant="h4" component="h4" gutterBottom>
                  Generar Reportes de Stock
                </Typography>
              </Grid>
              <Hidden only={["xs", "sm"]}>
                <Grid item xs={1} xl={1} justify="flex-end">
                  <Typography>
                    <Button onClick={reportmodalClose}>x</Button>
                  </Typography>
                </Grid>
              </Hidden>
            </Grid>
            <form
              onSubmit={GenerarReporteOffice}
              className={classes.form}
              noValidate
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container xs={12} xl={12} spacing={2}>
                  <Grid item container xs={12} sm={12}>
                    <Typography
                      variant="subtitle1"
                      component="subtitle1"
                      gutterBottom
                    >
                      Generar Reporte de Stock por Sucursal
                    </Typography>
                    <Grid item xs={12} md={10}>
                      <FormControl
                        variant="outlined"
                        style={{
                          position: "relative",
                          width: "95%",
                          whiteSpace: "nowrap",
                        }}
                        className={classes.formControl}
                      >
                        <SelectBranchOffice
                          detectarCambiosOffice={detectarCambiosOffice}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Tooltip
                        title="Generar Reporte"
                        aria-label="Generar Reporte"
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{
                            position: "relative",
                            height: "50px",
                            whiteSpace: "nowrap",
                          }}
                          /// onClick={GenerarReportOffice}
                          endIcon={<Icon>send</Icon>}
                        >
                          Generar
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  xl={12}
                  spacing={2}
                  style={{ marginTop: "4%" }}
                >
                  <Grid xs={12} md={9} xl={9} justify="flex-start">
                    <Typography
                      variant="subtitle1"
                      component="subtitle1"
                      gutterBottom
                    >
                      Generar un Reporte de Stock General
                    </Typography>
                  </Grid>
                  <Grid xs={12} md={3} xl={3} justify="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={GenerarReporteGlobal}
                      endIcon={<Icon>send</Icon>}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Reporte General
                    </Button>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </form>
          </div>
        </Fade>
      </Modal>

      {/* MODAL */}

      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: -30 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Clave", field: "clave" },
              { title: "Producto", field: "products_name" },
              {
                title: "Cantidad existente",
                field: "quantity",
                render: (rowData) => {
                  return (
                    <b
                      className={classes.Link}
                      style={{ color: "indigo", align: "center" }}
                    >
                      {rowData.quantity}
                    </b>
                  );
                },
              },
              {
                title: "Precio",
                field: "products_price",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.products_price),
              },

              {
                title: "Estatus",
                field: "quantity",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.quantity == 0 ? (
                        <p
                          style={{
                            color: "white",
                            background: "red",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Productos Agotados
                        </p>
                      ) : rowData.quantity <= 200 ? (
                        <p
                          style={{
                            color: "white",
                            background: "#ffc400",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Productos por Agotarse
                        </p>
                      ) : rowData.quantity >= 201 ? (
                        <p
                          style={{
                            color: "white",
                            background: "#43a047",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Producto Suficiente en Existencia
                        </p>
                      ) : null}
                    </Fragment>
                  );
                },
              },
            ]}
            data={stocks}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "add",
                tooltip: "Agregar Existencias",
                onClick: () => handleClickOpenAdd(rowData.id),
              }),
              (rowData) => ({
                icon: "remove",
                tooltip: "Remover Existencias",
                onClick: () => handleClickOpenRemove(rowData.id),
              }),
              (rowData) => ({
                icon: "edit",
                tooltip: "Editar Producto",
                onClick: () => handleClickOpenModalEdit(rowData.id_product),
              }),
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Stock de productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Clave", field: "clave" },
              { title: "Producto", field: "products_name" },
            ]}
            data={stocks}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>
                        Cantidad Existente:{" "}
                        <p className={classes.Link} style={{ color: "blue" }}>
                          {rowData.quantity}
                        </p>{" "}
                      </p>
                      <p>
                        Estatus:{" "}
                        {
                          <Fragment>
                            {rowData.quantity == 0 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "red",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align="center"
                              >
                                Productos Agotados
                              </p>
                            ) : rowData.quantity <= 200 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "#ffc400",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align="center"
                              >
                                Productos Por Agotarse
                              </p>
                            ) : rowData.quantity >= 201 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "#43a047",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align="center"
                              >
                                Productos Suficientes En Existencia
                              </p>
                            ) : null}
                          </Fragment>
                        }
                      </p>
                      <p>Opciones: </p>

                      <p>
                        {" "}
                        {
                          <>
                            <IconButton
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 50,
                              }}
                              size="small"
                              onClick={() => handleClickOpenAdd(rowData.id)}
                            >
                              <Tooltip
                                title="Agregar Existencias"
                                aria-label="Agregar Existencias"
                              >
                                <AddIcon style={{ color: "gray" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 100,
                              }}
                              size="small"
                              onClick={() => handleClickOpenRemove(rowData.id)}
                            >
                              <Tooltip
                                title="Remover Existencias"
                                aria-label="Remover Existencias"
                              >
                                <RemoveIcon style={{ color: "gray" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 100,
                              }}
                              size="small"
                              onClick={() =>
                                handleClickOpenModalEdit(rowData.id_product)
                              }
                            >
                              <Tooltip
                                title="Editar Producto"
                                aria-label="Editar Producto"
                              >
                                <EditIcon style={{ color: "gray" }} />
                              </Tooltip>
                            </IconButton>
                          </>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Stock de productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <ModalAdd
        id={id}
        modal={modalAdd}
        handleClickOpen={handleClickOpenAdd}
        handleClose={handleCloseAdd}
        stockid={stockid}
        actualizarCambios={actualizarCambios}
      />

      <ModalRemove
        id={id}
        modal={modalRemove}
        handleClickOpen={handleClickOpenRemove}
        handleClose={handleCloseRemove}
        stockid={stockid}
        actualizarCambios={actualizarCambios}
      />
      {productId && (
        <Editproduct
          modal={openModalEdit}
          handleClose={handleCloseModalEdit}
          setOpenModalEdit={setOpenModalEdit}
          idproduct={productId}
        />
      )}
      <ModalImportStock
        openImp={openImp}
        handleOpen={handleOpen}
        handleClose={handleClose}
        spinnerCargando={spinnerCargando}
        id={id}
      />
    </LayoutDashboard>
  );
};

export default OfficeStocksAdmin;
