import React, { useState, useEffect } from "react";

import SelectClient from "../SelectOptions/SelectClient";
import SelectTypeSale from "../SelectOptions/SelectTypeSale";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import { Grid, Button } from "@material-ui/core";
import Exceltojson from "./exceltojson";
import PlantillaExcel from "./ProductsExportExcel";
import ModalClient from "./ModalClient";

const SelectsComponent = ({
  guardarProductoID,
  guardarClienteID,
  id,
  clienteID,
  saveProductsBranded,
  detectarCambiosTypeSale,
}) => {
  const detectarCambiosClient = (value) => {
    guardarClienteID(value.value);
  };
  const [cargando, spinnerCargando] = useState(false);

  //cliente
  useEffect(() => {
    spinnerCargando(true);
    guardarClienteID(clienteID);
  }, [clienteID]);

  //MODAL CLIENT
  const [openModalClient, setOpenModalClient] = React.useState(false);

  const handleClickOpenModalClient = () => {
    setOpenModalClient(true);
  };
  const handleCloseModalClient = () => {
    setOpenModalClient(false);
  };

  if (!cargando) return <Spinner />;

  return (
    <>
      <Grid container style={{ padding:2, paddingLeft:"10px" }} >
        <Grid item xs={10} md={2} xl={2} style={{ padding:2, paddingLeft:"30px", }}>
          <PlantillaExcel id={id} />
          <Exceltojson saveProductsBranded={saveProductsBranded} />
        </Grid>
        <Grid item xs={10} md={4} xl={4}>
          <SelectClient
            detectarCambiosClient={detectarCambiosClient}
            id_branch_office={id}
          />
        </Grid>

        <Grid item xs={2} md={2} xl={2} style={{ padding:2 }}>
          <Button
            fullWidth
            style={{
              marginBottom: 5,
              backgroundColor: "#4b0082",
              color: "white",
            }}
            variant="contained"
            color="secondary"
            onClick={() => handleClickOpenModalClient()}
          >
            Nuevo
          </Button>
        </Grid>
        <Grid item xs={12} md={4} xl={4} style={{ marginBottom: 10}}>
          <SelectTypeSale detectarCambiosTypeSale={detectarCambiosTypeSale} />
        </Grid>
      </Grid>
      <ModalClient
        modal={openModalClient}
        handleClose={handleCloseModalClient}
        id={id}
      />
    </>
  );
};

export default SelectsComponent;
