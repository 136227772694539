import React, { useState, useEffect, Fragment, useContext } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Button, Tooltip } from '@material-ui/core';
import ModalPayments from './ModalPayments';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import SelectStudent from '../SelectOptions/SelectStudent';
import SelectSchedule from '../SelectOptions/SelectSchedule';
import FormControl from '@material-ui/core/FormControl';

/**Importar metodo Get */
import MethodGet, { MethodDelete } from '../../Config/Services';
/**importar spinner */
import Spinner from '../../Complements/Spinner';
import InscriptionsContext from '../../Reducers/Inscriptions/InscriptionsContext';
import SchedulesContext from '../../Reducers/Schedules/SchedulesContext';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(30),
	},
	typography: {
		paddingTop: theme.spacing(4),
		margin: theme.spacing(1),
		paddingLeft: theme.spacing(30),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(9),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		background: '#3537DB',
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			background: '#5902CF',
		},
		color: 'white',
	},
	ContainerSubmit: {
		marginLeft: theme.spacing(30),
		marginTop: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
}));
export default function InscriptionEdit(props) {
	const classes = useStyles();
    const [cargando, spinnerCargando] = useState(false);

    const [ open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        guardarMetodoPago({
        amount: '',
        change: '0',
		cash_income: '0',
		card_income: '0',
		transfer_income: '0',
        });
      setOpen(false);
      setDisabledButton(false);
  };

  //Leer los datos del Modal
  const obtenerInformacionPago = e => {
    guardarMetodoPago({
    ...informacionMetodoPago,
    [e.target.name]: e.target.value
    })
}
    const [ informacionMetodoPago, guardarMetodoPago] = useState({
        amount: '0',
        change: '0',
		cash_income: '0',
		card_income: '0',
		transfer_income: '0',
        reference: '',
      });
      const [ totalDescuento, guardarTotalDescuento] = useState(0);

      const [image, setImage] = useState({
		urlPhoto: 'https://pisouniversitario.com/template/images/page-404.jpg',
		image: ''
  });
  const [ totalPagado, guardarTotalPagado] = useState(0);

  const [data, setData] = useState({});


	//funcion para guardar la imagen y obtener la vista previa
    const handleChangeImage = (e) => {
        setImage({ ...image, urlPhoto: URL.createObjectURL(e.target.files[0]), 
                  image: e.target.files[0]
        });
    }
    const [ disableButton, setDisabledButton] = useState(false);

	const formInscriptionsContext = useContext(InscriptionsContext);
	
	  const [scheduleID, saveSchedule] = useState('');
	
		const detectarCambiosSchedule = (e) => {
			saveSchedule(e.target.value);
		};
	
  const { ErrorsAPI, success, AddInscription } = formInscriptionsContext;

	const formSchedulesContext = useContext(SchedulesContext);
  
  const { GetInfoSchedule, schedule } = formSchedulesContext;

  useEffect(() => {
	let url = `schedules/${scheduleID}`;
	MethodGet(url)
		.then((res) => {
			guardarTotalDescuento(res.data.data.course_cost);
		})
		.catch((error) => {
			console.log(error);
		});
}, [scheduleID]);


  const [school, saveSchool] = useState({});

    //Extraer valores de branch(destructuracion)
	const { name } = school;

    //obtenemos la escuela de session storage
  let SchoolStorageID = JSON.parse(sessionStorage.getItem("school"));
  //console.log(SchoolStorageID)

  const [studentID, saveStudent] = useState('');

	const detectarCambiosStudent = (e) => {
		saveStudent(e.target.value);
	};

  const addPayment = (e) => {
    if(totalDescuento < informacionMetodoPago.amount && informacionMetodoPago.cash_income < totalDescuento)
    {
      return;
    } else if(informacionMetodoPago.amount < 0)
    {
      return;
    }
    setDisabledButton(true);
    data.cash_income = informacionMetodoPago.cash_income;
    data.card_income = informacionMetodoPago.card_income;
    data.transfer_income = informacionMetodoPago.transfer_income;
    data.image = image.image;
    data.change = informacionMetodoPago.change;
    data.reference = informacionMetodoPago.reference;
	data.client_id = studentID;
	data.schedule_id = scheduleID;
	data.total = totalDescuento;
    //console.log(data);
    AddInscription(data);
    handleClose();
  }

	//Funcion para validaciones
	const { register, handleSubmit, errors } = useForm();

	 //obtener los campos de la sucursal
	 useEffect(() => {
		let url = `/branches/${SchoolStorageID}`;
		MethodGet(url)
			.then((res) => {
				saveSchool(res.data.data);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
			});
    }, []);

	useEffect(() => {
		//si success retorna verdadero la pagina redireccionara al index de salones
		if (success) {
		  //console.log("Retorna");
		  props.history.push(`/InscripcionesEscuela`); 
		}
		//eslint-disable-next-line
	  }, [success]);

	//  spinner de carga
	if (!cargando) return <Spinner />;

	return (
		<LayoutDashboard>
			<div className={classes.root}>
				<Box>
					<Typography
						component="h1"
						variant="h5"
						align="center"
						className={classes.typography}
					>
						Edición De Inscripción
					</Typography>
				</Box>
				<Grid container spacing={2} className={classes.container}>
					<Grid item xs={12} sm={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
							<SelectSchedule
								detectarCambiosSchedule={detectarCambiosSchedule}
								SchoolStorageID={SchoolStorageID}
							/>
						</FormControl>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid item xs={3} className={classes.ContainerSubmit}>
                    <Button
                        type="submit"
                        fullWidth
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpen}
                        >
                        Continuar
                    </Button>
					</Grid>
				</Grid>
			</div>
		</LayoutDashboard>
	);
}
