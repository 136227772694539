import React, { useState, useEffect, Fragment } from 'react';
/**Importar configuracion axios */
import clienteAxios from '../../Config/Axios';
/**Importar componentes select */
import Select from 'react-select';

export default function SelectClassroom(props) {
	const [classrooms, saveClassrooms] = useState([]);
	const [error, guardarError] = useState(true);
	useEffect(() => {
		if(!props.capacity)
		{
			const getClassrooms = async () => {
				await clienteAxios
					.get(`/schoolclassrooms/${props.SchoolStorageID}`)
					.then((res) => {
						saveClassrooms(res.data.data);
					})
					.catch((error) => {
						console.log(error);
					});
	
				if (props.classroom) {
					guardarError(false);
				} else {
					guardarError(true);
				}
			};
			getClassrooms();
		} else {
			const getClassrooms = async () => {
				await clienteAxios
					.get(`/classroomsCapacity/${props.SchoolStorageID}/${props.capacity}`)
					.then((res) => {
						saveClassrooms(res.data.data);
					})
					.catch((error) => {
						console.log(error);
					});
	
				if (props.classroom) {
					guardarError(false);
				} else {
					guardarError(true);
				}
			};
			getClassrooms();
		}
	}, [props.SchoolStorageID, props.capacity]);

	const detectarCambiosClassrooms = (value) => {
		if (value.key) {
			guardarError(false);
		}
		props.detectarCambiosClassrooms(value);
	};

	const classroomsArray = classrooms.map(classroom=>{
		return {key:classroom.id , value:classroom.id , label: classroom.name + ' - ' + classroom.capacity + ' Personas'}
	});
	const selectStyles = {
		menu: base => ({
		  ...base,
		  zIndex: 100
		}),
		control: base => ({
			...base,
			height: 50,
			widht: 50,
			minHeight: 35
		  })
	  };
	return (
		<Fragment >
			{!classrooms.length ? (
					(<p>No tienes ningún salón disponible con la capacidad actual</p>)
			) : (
				<div style={{width: '300px'}}>

				<Select
				styles={selectStyles}
					autofocus
					placeholder="Salones"
					options={classroomsArray}
					onChange={ value => detectarCambiosClassrooms(value)}
				>
				</Select>
				</div>

			)}

			{error ? (
				<p style={{ color: 'red' }}>El Salón Es Un Campo Requerido</p>
			) : null}
		</Fragment>
	);
}
