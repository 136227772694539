import React, { useState, useEffect, Fragment } from "react";
/**Importar configuracion axios */
import clienteAxios from "../../Config/Axios";
/**Importar componentes select */
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

export default function SelectBranchOffice(props) {
  const [offices, saveOffices] = useState([]);
  const [error, guardarError] = useState(true);
  useEffect(() => {
    const getOffices = async () => {
      await clienteAxios
        .get("/branches")
        .then((res) => {
          saveOffices(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });

      if (props.officeId) {
        guardarError(false);
      }
    };
    getOffices();
  }, []);

  const detectarCambiosOffice = (e) => {
    if (e.target.value) {
      guardarError(false);
    }
    props.detectarCambiosOffice(e);
  };
  return (
    <Fragment>
      <InputLabel fullWidth>Asignar Sucursal</InputLabel>
      {props.officeId ? (
        <Select
          label="Sucursales"
          fullWidth
          onChange={detectarCambiosOffice}
          value={props.officeId.id_branch_office}

        >
          <MenuItem disabled>Selecciona la sucursal</MenuItem>
          {offices.map((office) => (
            <MenuItem key={office.id} value={office.id}>
              {office.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select label="Sucursales" fullWidth onChange={detectarCambiosOffice}>
          <MenuItem disabled>Selecciona la sucursal</MenuItem>
          {offices.map((office) => (
            <MenuItem key={office.id} value={office.id}>
              {office.name}
            </MenuItem>
          ))}
        </Select>
      )}

      {error ? <p style={{ color: "red" }}>La sucursal es Requerida</p> : null}
    </Fragment>
  );
}
