import React, { useState, useEffect, Fragment, useContext } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import { Button, Tooltip, Grid, Typography, Container, Box, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TableIndividualCourses from './TableIndividualCourses';
import TableGraduatesCourses from './TableGraduatesCourses';

/**Importar metodo Get */
import MethodGet, { MethodDelete } from '../../Config/Services';
/**Importar Sweetalert2 * */
import Swal from 'sweetalert2';
/**importar spinner */
import Spinner from '../../Complements/Spinner';
import SchedulesContext from '../../Reducers/Schedules/SchedulesContext';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '105vh',
		overflow: 'auto',
		width:'120vw',
	},
	container: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(11),
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	input: {
		display: 'none',
	},
	typography: {
		margin: theme.spacing(0, 1, 2, 2),
	},
	// Estilo de tablas Container
	titleTbl:{
		//backgroundColor:'#8115CF',
		background:'linear-gradient(50deg, #3f51b5 33%, indigo 100%)',
		color:'white',
		fontSize:'large',
		height:'60px',
		borderRadius:'5px',
		paddingTop:'10px',
		marginBottom:'20px',
		whiteSpace: 'nowrap',

	},
	btnPlus:{
		display:'absolute',
		marginTop:'10%',
		marginRight:'-5%',
		zIndex:2,
		position: 'relative',
	},
	IconArrowBack:{
		paddingTop:'8px',
		marginRight:'-2%'
	},
	AppBarrProps:{
	alignItems:'center',
	color:'white',
	background:'indigo'
	},
	OpctionTbl:{
		alignItems:'center',
		color:'white',
		background:'indigo'	
	},
	ControlWidth:{
		width:'96%',
		//whiteSpace:'nowrap'
	}


}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const SchoolSchedules = (props) => {
	const classes = useStyles();
	
    const [cargando, spinnerCargando] = useState(false);

    const [school, saveSchool] = useState({});

    //Extraer valores de branch(destructuracion)
	const { name } = school;

    //obtenemos la escuela de session storage
  let SchoolStorageID = JSON.parse(sessionStorage.getItem("school"));
  //console.log(SchoolStorageID);

  /**Extraer los proveedores del state inicial */
  const SchedulesList = useContext(SchedulesContext);
  const { schedules, GetSchedules, success, GetSchedulesGraduates } = SchedulesList;

  const StateSchedulesContext = useContext(SchedulesContext);
  const { SaveCurrentSchedule , DeleteSchedule } = StateSchedulesContext;

  //obtener los campos de la sucursal
	useEffect(() => {
		let url = `/branches/${SchoolStorageID}`;
		MethodGet(url)
			.then((res) => {
				saveSchool(res.data.data);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
			});
    }, []);

	const [value, setValue] = useState(0);

  	const handleChange = (event, newValue) => {
  	  setValue(newValue);
  	};

	//  spinner de carga
	if (!cargando) return <Spinner />;

	return (
		<LayoutDashboard>

					<div className={classes.appBarSpacer} />
					<Grid container 
  						direction="row"
  						justify="center"
  						alignItems="center"
						style={{marginTop:10}}
					>
					<Grid container  className={classes.ControlWidth}>
						<AppBar position="static" className={classes.AppBarrProps} >
      					  <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example" className={classes.OpctionTbl}>
								<Tab label="Talleres" {...a11yProps(0)} />
								<Tab label="Diplomados" {...a11yProps(1)} />
      					  </Tabs>
      					</AppBar>
					 </Grid>
					</Grid>

      					<TabPanel value={value} index={0}>
      					  <TableIndividualCourses 
							name={name}
							SchoolStorageID={SchoolStorageID}
							/>
      					</TabPanel>
      					<TabPanel value={value} index={1}>
						  <TableGraduatesCourses 
							name={name}
							SchoolStorageID={SchoolStorageID}
							/>
      					</TabPanel>

		</LayoutDashboard>
	);
};

export default SchoolSchedules;
