import { DELETE_PRODUCT_TRANSFER,GET_ALL_BRANCH_DEPARTURES,EDIT_DEPARTURES_PENDING,GET_ALL_BRANCH_DEPARTURES_PENDING,SHOW_BRANCH_DEPARTURES_PENDING, ADD_NEW_DEPARTURE, GET_ALL_USER_DEPARTURES,ADD_NEW_DEPARTURE_BRANCH ,GET_ALL_BRANCH_DEPARTURES_BRANCH} from "../../types";

export default function DeparturesReducer(state, action) {
    
switch (action.type) {
case GET_ALL_BRANCH_DEPARTURES: 
    return{
        ...state,
        departures: action.payload,
        success:false,
        ErrorsAPI: [],
    }
    case DELETE_PRODUCT_TRANSFER: 
    return{
        ...state,
        departures: action.payload,
        success:false,
        ErrorsAPI: [],
    }
    case GET_ALL_BRANCH_DEPARTURES_PENDING: 
    return{
        ...state,
        departures: action.payload,
        success:false,
        ErrorsAPI: [],
    }
    case SHOW_BRANCH_DEPARTURES_PENDING: 
    return{
        ...state,
        departures: action.payload,
        success:false,
        ErrorsAPI: [],
    }
    case GET_ALL_BRANCH_DEPARTURES_BRANCH: 
    return{
        ...state,
        departures: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case GET_ALL_USER_DEPARTURES: 
    return{
        ...state,
        departures: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case ADD_NEW_DEPARTURE:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }
        case EDIT_DEPARTURES_PENDING:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }


        case ADD_NEW_DEPARTURE_BRANCH:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }


default:
    return state; 
}
}