import React, { useEffect, useState, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import AuthContext from "../../Context/autenticacion/authContext";
import ComponentsBarcode from "./ComponentsBarcode";
import ProductsList from "./ProductsList";
import Button from "@material-ui/core/Button";
import MethodGet from "../../Config/Services";
import ProductInfo from "./ProductInfo";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { Grid, Box, Hidden, Paper, Fab } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import InventoryProductsExcel from "./InventoryProductsExcel";
import useSelectProducts from "../SelectOptions/SelectProductsInventary";

import { makeStyles } from "@material-ui/core/styles";
import { useDebounce } from "use-debounce";
/*****Importo estilos de cabezzal titulos  */
import InputLabel from "@material-ui/core/InputLabel";

import CardHeader from "../../Components/Card/CardHeader";
import CardIcon from "../../Components/Card/CardIcon";
//import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../Components/Card/CardFooter";
/***Importo los ICONOS de la barra titulo */
import LoyaltyIcon from "@material-ui/icons/Loyalty";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
import { MethodPost } from "../../Config/Services";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    marginTop: theme.spacing(11),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  paperView: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  TableRow: {
    background: "Orange",
  },
  h2: {
    background: "#394ED1",
    color: "white",
  },
}));

function InventoryProducts(props) {
  const classes = useStyles();
  const [productoID, guardarProductoID] = useState("");
  const [ventas, guardarVenta] = useState([]);
  const [subtotal, guardarTotal] = useState(0);
  const [totalDescuento, guardarTotalDescuento] = useState(0);
  const [inventory, setInventory] = useState(false);
  const { branch_office_id, status_report } = inventory;

  const { id } = props.match.params;
  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [remaining, saveRemaining] = useState("");
  const [selectedOption, setSelectedOption] = useState("opcion1");
  const [product, SelectProduct] = useSelectProducts({
    stateInicial: "",
    branchID: branch_office_id,
    option: selectedOption,
  });

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `inventories/products/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarVenta(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let url = `inventories/${id}`;
    MethodGet(url)
      .then((res) => {
        setInventory(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `inventories/validate/${id}`;
    MethodGet(url)
      .then((res) => {
        saveRemaining(res.data.products_remaining);
        //console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [ventas]);

  //Extraer valores de branch(destructuracion)

  const onSubmit = () => {
    let url = `inventories/end/${id}`;
    MethodPost(url)
      .then((res) => {
        Swal.fire({
          title: "Inventario Finalizado Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        window.location.replace("");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
    props.history.push("/Inventarios/Sucursal/" + branch_office_id);
  };

  return (
    <LayoutDashboard>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 100,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            className={classes.typography}
          >
            Inventario De Sucursal
          </Typography>
        </Grid>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          {status_report !== 3 ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: 10, marginLeft: 25 }}
              onClick={onSubmit}
            >
              Terminar Inventario
            </Button>
          ) : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InventoryProductsExcel id={id} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid spacing={2} className={classes.paperView}>
        {status_report !== 3 ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel fullWidth>Formas de agregar productos</InputLabel>
              <Select
                value={selectedOption}
                fullWidth
                onChange={handleChange}
                variant="outlined"
              >
                <MenuItem value="opcion1">Codigo de Barras</MenuItem>
                <MenuItem value="opcion2">Busqueda de Producto</MenuItem>
              </Select>
            </Grid>

            {selectedOption === "opcion1" && (
              <Grid item xs={12} sm={12} md={12}>
                <ComponentsBarcode
                  guardarProductoID={guardarProductoID}
                  ventas={ventas}
                  guardarVenta={guardarVenta}
                  status_report={status_report}
                  id={id}
                  branch_office_id={branch_office_id}
                />
              </Grid>
            )}

            {selectedOption === "opcion2" && (
              <Grid item sm={12} md={12}>
                <SelectProduct id={branch_office_id} />
                <ProductInfo
                  id={id}
                  productoID={product.value}
                  ventas={ventas}
                  guardarVenta={guardarVenta}
                  guardarProductoID={guardarProductoID}
                />
              </Grid>
            )}
          </Grid>
        ) : null}

        <Grid item xs={12} sm={12} md={12}>
          <ProductsList
            status_report={status_report}
            id={id}
            branch_office_id={branch_office_id}
            ventas={ventas}
            guardarVenta={guardarVenta}
            subtotal={subtotal}
            guardarTotal={guardarTotal}
            totalDescuento={totalDescuento}
            guardarTotalDescuento={guardarTotalDescuento}
          />
        </Grid>
      </Grid>
    </LayoutDashboard>
  );
}

export default InventoryProducts;
