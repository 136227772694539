import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import HomeIcon from "@material-ui/icons/Home";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MoneyIcon from "@material-ui/icons/Money";
import ClassIcon from "@material-ui/icons/Class";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import CancelIcon from "@material-ui/icons/Cancel";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PaymentIcon from "@material-ui/icons/Payment";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import CategoryIcon from "@material-ui/icons/Category";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  Link: {
    textDecoration: "none",
    color: "white",
  },
  subMenu: {
    backgroundColor: "#ffffff22",
    color: "white",
  },
  Iconwhite: {
    color: "white",
  },
}));

export default function ListItems() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <List component="nav" className={classes.Iconwhite}>
        <Link to="/" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Inicio" aria-label="Inicio">
                <HomeIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Inicio" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Salones" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Salones" aria-label="Salones">
                <MeetingRoomIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Salones" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Cursos" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Cursos" aria-label="Cursos">
                <ClassIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Cursos" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Inscripciones" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Inscripciones" aria-label="Inscripciones">
                <AssignmentIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Inscripciones" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Programaciones" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Programaciones" aria-label="Programaciones">
                <DateRangeIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Programar Cursos" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Clientes" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Clientes" aria-label="Clientes">
                <PeopleAltIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Productos" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Mis Productos" aria-label="Mis Productos">
                <ShoppingCartIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Mis Productos" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Utilidad/Escuela" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Corte De Caja" aria-label="Corte De Caja">
                <MonetizationOnIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Corte De Caja Escolar" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Salidas" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip
                title="Salida De Productos"
                aria-label="Salida De Productos"
              >
                <RemoveShoppingCartIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Salida De Productos" />
          </ListItem>
        </Link>
        <Divider />
        <Divider />
        <Link to="/Traspaso" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip
                title="Traspaso de Productos"
                aria-label="Traspaso de Productos"
              >
                <SendIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Traspaso de Productos" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Inventarios" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip
                title="Inventario De Productos"
                aria-label="Inventario De Productos"
              >
                <CategoryIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Inventario De Productos" />
          </ListItem>
        </Link>
        <Divider />
        {/* <Link to="/Categorias" className={classes.Link}>
        <ListItem button>
          <ListItemIcon>
            <Tooltip
						title="Categorias"
						aria-label="Categorias"
						>
              <BookmarksIcon className={classes.Iconwhite}/>
            </Tooltip>
          </ListItemIcon>
            <ListItemText primary="Categorias"/>
        </ListItem>
      </Link> */}
        <Divider />
        <Link to="/Stock de Productos" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip
                title="Stock de Productos"
                aria-label="Stock de Productos"
              >
                <FormatColorFillIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Stock de Productos" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Comisiones" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Mis Comisiones" aria-label="Mis Comisiones">
                <PaymentIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Mis Comisiones" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Ventas" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Ventas" aria-label="Ventas">
                <AttachMoneyIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Ventas" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Ventas/Canceladas" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Ventas Canceladas" aria-label="Ventas Canceladas">
                <CancelIcon className={classes.subMenu} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Ventas Canceladas" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Cupones" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip
                title="Cupones de descuento"
                aria-label="Cupones de descuento"
              >
                <MoneyIcon className={classes.subMenu} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Cupones de descuento" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Utilidad/Tienda" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Corte De Caja" aria-label="Corte De Caja">
                <MonetizationOnIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Corte De Caja Tiendas" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Gastos/Tienda" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Gastos" aria-label="Gastos">
                <MoneyOffIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Gastos" />
          </ListItem>
        </Link>
      </List>
    </div>
  );
}
