import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import OfficeStockSubA from './Branch_Offices_SubA';
import OfficeStocks from './OfficeStocks';
import ProductStockIndex from '../Products_Stocks/Index';

export default function Branch_index() {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		  case '1':
			component = <OfficeStocks />
			break;
		  case '2':
			component = <ProductStockIndex />
			break;
		  case '3': 
			component = <OfficeStockSubA />
      break;
		  default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}