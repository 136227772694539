import { ADD_NEW_SCHOOL, CURRENT_SCHOOL, DELETE_SCHOOL, GET_ALL_SCHOOLS, GET_MUNICIPALITIES_SELECT,
    GET_STATES_SELECT, SHOW_ERRORS_API, UPDATE_SCHOOL, UTILITY_SCHOOL, INCOME_SCHOOL } from "../../types";

export default function SchoolReducer(state, action) {

switch (action.type) {
case GET_ALL_SCHOOLS: 
    //sessionStorage.removeItem('selectedSchools');
    return{
        ...state,
        schools: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case ADD_NEW_SCHOOL:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }

case INCOME_SCHOOL:
    return{
            ...state,
            incomes: action.payload,
            success:false,
            ErrorsAPI: []
        }

case UTILITY_SCHOOL:
    return{
            ...state,
            utility: action.payload,
            success:false,
            ErrorsAPI: []
        }

case SHOW_ERRORS_API:

    return{
        ...state,
        ErrorsAPI: action.payload
    }

case CURRENT_SCHOOL:

    //sessionStorage.setItem('selectedSchools',JSON.stringify(action.payload))
    return{
        ...state,
        school:action.payload,
    }

case UPDATE_SCHOOL:

    //sessionStorage.removeItem('selectedSchools');
    return{
        ...state,
        success: true,
        ErrorsAPI: []
    }

case DELETE_SCHOOL:

    return{
        ...state,
        schools: state.schools.filter(school => school.id !== action.payload)
    } 

case GET_STATES_SELECT:
    return{
        ...state,
        states: action.payload
    } 

case GET_MUNICIPALITIES_SELECT:
    return{
        ...state,
        municipalities: action.payload
    } 

default:
    return state; 
}
}