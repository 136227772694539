import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Box from "@material-ui/core/Box";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { Button, Hidden, IconButton, Tooltip } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import ModalImage from "react-modal-image";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";

import SchoolContext from "../../Reducers/Schools/SchoolContext";
/**importar spinner */
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  ImgTblDetail: {
    display: "flex",
    width: "150px",
    height: "150px",
  },
}));

export default function SchoolIndex() {
  const classes = useStyles();

  /**Extraer los proveedores del state inicial */
  const SchoolsList = useContext(SchoolContext);
  const { schools, GetSchools, success } = SchoolsList;

  const StateSchoolsContext = useContext(SchoolContext);
  const { SaveCurrentSchool, DeleteSchool } = StateSchoolsContext;

  const SelectSchool = (school) => {
    SaveCurrentSchool(school);
  };

  //obtener los proveedores carga el componente
  useEffect(() => {
    GetSchools();
    //eslint-disable-next-line
  }, [success]);

  return (
    <LayoutDashboard>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            marginTop: 100,
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography component="h1" variant="h4">
            Mis Escuelas
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Todos los registros de Escuelas
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            href="/AgregarEscuela"
          >
            <Tooltip title="Agregar Escuela" aria-label="Agregar Escuela">
              <AddIcon size="large" />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Nombre", field: "name" },
              {
                title: "Imagen",
                field: "image",
                render: (rowData) =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      className={classes.ImgTblDetail}
                      alt="Sucursal"
                    />
                  ) : (
                    <ModalImage
                      small={
                        "https://pisouniversitario.com/template/images/page-404.jpg"
                      }
                      large={
                        "https://pisouniversitario.com/template/images/page-404.jpg"
                      }
                      className={classes.ImgTblDetail}
                      alt="Sucursal"
                    />
                  ),
              },
              { title: "Tienda Asignada", field: "shop" },
              { title: "Dirección", field: "direction" },
              { title: "Teléfono", field: "number_phone" },
              { title: "Estado", field: "state" },
              { title: "Municipio", field: "municipality" },
            ]}
            data={schools}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "delete",
                tooltip: "Eliminar Escuela",
                onClick: () => DeleteSchool(rowData.id),
              }),
            ]}
            components={{
              Action: (props) => (
                <Fragment>
                  <Link to={`EditarEscuela`} style={{ color: "#3f51b5" }}>
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "none",
                        background: "white",
                      }}
                      size="small"
                      onClick={() => SelectSchool(props.data.id)}
                    >
                      <Tooltip
                        title="Editar Escuela"
                        aria-label="Editar Escuela"
                      >
                        <EditIcon style={{ color: "blue" }} />
                      </Tooltip>
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", background: "white" }}
                    size="small"
                    onClick={() => DeleteSchool(props.data.id)}
                  >
                    <Tooltip
                      title="Eliminar Escuela"
                      aria-label="Editar Escuela"
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Tooltip>
                  </Button>
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Escuelas que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Nombre", field: "name" },
              {
                title: "Imagen",
                field: "image",
                render: (rowData) =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      alt="Sucursal"
                      className={classes.ImgTblDetail}
                    />
                  ) : (
                    <ModalImage
                      small={
                        "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png"
                      }
                      large={
                        "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png"
                      }
                      alt="Sucursal"
                      className={classes.ImgTblDetail}
                    />
                  ),
              },
            ]}
            data={schools}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Tienda Asignada: {rowData.shop}</p>
                      <p>Dirección: {rowData.direction}</p>
                      <p>Teléfono: {rowData.number_phone}</p>
                      <p>Estado: {rowData.state}</p>
                      <p>Municipio: {rowData.municipality}</p>
                      <p>
                        Opciones:
                        <p>
                          {
                            <Fragment>
                              <Link to={`EditarEscuela`}>
                                <IconButton
                                  variant="contained"
                                  style={{
                                    textTransform: "none",
                                    background: "white",
                                    marginLeft: 40,
                                  }}
                                  size="small"
                                  onClick={() => SelectSchool(rowData.id)}
                                >
                                  <Tooltip
                                    title="Editar Escuela"
                                    aria-label="Editar Escuela"
                                  >
                                    <EditIcon style={{ color: "blue" }} />
                                  </Tooltip>
                                </IconButton>
                              </Link>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 70,
                                }}
                                size="small"
                                onClick={() => DeleteSchool(rowData.id)}
                              >
                                <Tooltip
                                  title="Eliminar Escuela"
                                  aria-label="Editar Escuela"
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </Tooltip>
                              </IconButton>
                            </Fragment>
                          }
                        </p>
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Escuelas que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </LayoutDashboard>
  );
}
