import React, { useState, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardsUtility from "./CardsUtility";
/**Importar metodo Get */
import MethodGet from "../../Config/Services";
import Spinner from "../../Complements/Spinner";

const BranchAdminUtilities = (props) => {
  const { id } = props.match.params;

  const [cargando, spinnerCargando] = useState(false);

  const [branch, saveBranch] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name } = branch;
  //console.log(SchoolStorageID);

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        saveBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/**
       *
       * Aqui renderiza los titulos de esta view dependiendo la medida
       */}
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        justify="center"
        style={{ marginTop: 70 }}
      >
        <Typography component="h3" variant="h3" align="center">
          {"Total de efectivo de la sucursal " + name}
        </Typography>
      </Grid>
      {/**
       *
       * Aqui es donde aplica el cambio de cards que renderizan las sucursales para los clientes
       */}

      <Grid container>
        <CardsUtility  schoolID={id} />
   
      </Grid>
    </LayoutDashboard>
  );
};

export default BranchAdminUtilities;
