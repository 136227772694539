import React, { useEffect, useState, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import { Grid, Box, Card } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**Importar metodo Post */
import { MethodPost } from "../../Config/Services";
import AuthContext from "../../Context/autenticacion/authContext";

import headerConfig from "../../Config/imageHeaders";
import { useForm } from "react-hook-form";
import GridItem from "../../Components/Grid/GridItem";
import CardHeader from "../../Components/Card/CardHeader";
import clienteAxios from '../../Config/Axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(30),
  },
  typography: {
    paddingTop: theme.spacing(4),
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "#3537DB",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  ContainerSubmit: {
    marginLeft: theme.spacing(30),
    marginTop: theme.spacing(1),
  },
}));

function AddSellerExpenses(props) {
  const classes = useStyles();

  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const { id } = props;
  const [archivo, guardarArchivo] = useState("");

  //colocar la imagen en el state
  const leerArchivo = (e) => {
    guardarArchivo(e.target.files[0]);
  };
  const [gtotal, saveTotal] = useState([]);

  const [error, guardarError] = useState(true);
  useEffect(() => {
    const getotals = async () => {
      await clienteAxios
        .get(`branchUtility/${id}`)
        .then((res) => {
          saveTotal(res.data);
        })
        .catch((error) => {
          console.log(error);
        });

      if (props.course) {
        guardarError(false);
      }
    };
    getotals();
  }, []);
  // Funcion para validaciones

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data, e) => {
    e.preventDefault();



    





    //crear un formdata
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("total", data.total);
    formData.append("type", 1);
    formData.append("image", archivo);
    formData.append("branch_office_id", usuario.id_branch_office);
    formData.append("user_id", usuario.id);
    formData.append("previous_balance", gtotal.total_money);

    //console.log(estadoId)
    let url = "/expenses";
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Gasto Registrado Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        props.history.push("/Gastos/Tienda");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  return (
    <LayoutDashboard>
      <div onSubmit={handleSubmit(onSubmit)}>
        <form noValidate>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 70 }}
          >
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardHeader color="primary">
                  <h1 className={classes.cardTitleWhite}>
                    {"Agregar Nuevo Gasto "}
                  </h1>
                  <p className={classes.cardCategoryWhite}>
                    Completa correctamente los datos
                  </p>
                </CardHeader>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                        pattern: {
                          value:
                            /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                          message: "Unicamente carácteres alfabéticos",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="description"
                      label="Descripción"
                      name="description"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.description}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La Descripción es requerida",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.description?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="total"
                      label="Total De Gasto"
                      name="total"
                      autoComplete="total"
                      type="number"
                      autoFocus
                      error={!!errors.total}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El total del gasto es requerido",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 1 digito",
                        },
                      })}
                    />
                    <p>{errors?.total?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <p>Seleccionar Ticket del Gasto</p>
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                    />
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="icon-button-file"
                      type="file"
                      onChange={leerArchivo}
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    className={classes.submit}
                  >
                    GUARDAR
                  </Button>
                </Grid>
              </Card>
            </GridItem>
          </Grid>
        </form>
      </div>
    </LayoutDashboard>
  );
}

export default withRouter(AddSellerExpenses);
