import React, { useReducer } from "react";
import ClientsContext from "./ClientsContext";
import ClientsReducer from "./ClientsReducer";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../Config/Services";
import Swal from "sweetalert2";
import headerConfig from "../../Config/imageHeaders";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";
import {
  ADD_NEW_CLIENT,
  DELETE_CLIENT,
  GET_ALL_CLIENTS,
  GET_ALL_CLIENTS_SELLER,
  GET_CLIENT_ALUMN,
  GET_CLIENT_PUBLIC,
  GET_ALL_STUDENTS,
  GET_ALL_TYPECLIENTS,
  CREATE_TYPECLIENT,
  DELETE_TYPECLIENT,
  UPDATE_TYPECLIENT,
  SHOW_TYPECLIENT,

} from "../../types";

const ClientsState = ({ children }) => {
  // estado inicial
  const initialSate = {
    clients: [],
    typeclient: [],
    errors: [],
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(ClientsReducer, initialSate);
  const getclients = (id) => {
    let url = `/clientsBranch/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_CLIENTS,
        payload: res.data.data,
      });
    });
  };
  const GetTypeClient = (id) => {
    let url = `/showtypeclient/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: SHOW_TYPECLIENT,
        payload: res.data.data,
      });
    });
  };
  const gettypeclients = (id) => {
    let url = `/typeclientsBranch`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_TYPECLIENTS,
        payload: res.data,
      });
    });
  };
  const getstudents = (id) => {
    let url = `/studentsSchool/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_STUDENTS,
        payload: res.data.data,
      });
    });
  };

  const getclientsSeller = (id) => {
    let url = `/clients`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_CLIENTS_SELLER,
        payload: res.data.data,
      });
    });
  };

  const getclientsPublic = (id) => {
    let url = `/clientsBranchPublic/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_CLIENT_PUBLIC,
        payload: res.data.data,
      });
    });
  };

  const getclientsStudent = (id) => {
    let url = `/clientsBranchStudent/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_CLIENT_ALUMN,
        payload: res.data.data,
      });
    });
  };

  const CreateClient = (data) => {
    let url = "/clients";
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: ADD_NEW_CLIENT,
          payload: res.data.data,
        });
        Swal.fire({
          title: "Cliente Registrado Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };
  const CreateTypeClient = (data) => {
    const formData = new FormData();
    formData.append("id_branch_office", data.id_branch_office);
    formData.append("type_rule", data.type_rule);
    let url = "/typeclientsCreate";
    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: CREATE_TYPECLIENT,
          payload: res.data.data,
        });
        Swal.fire({
          title: "Tipo de Cliente Registrado Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        toast.warning(
          <div>
            <ErrorIcon /> {error.response.data.error}{" "}
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      });
  };
  const EditTypeClient = (data) => {
    const formData = new FormData();
    formData.append("type_rule", data.type_rule);
    let url = `/updatetypeclients/${data.id}`;
    formData.append("_method", "PATCH");

    MethodPost(url, data)
      .then((res) => {
        dispatch({
          type: UPDATE_TYPECLIENT,
          payload: res.data.data,
        });
        Swal.fire({
          title: "Tipo de Cliente Editado Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        toast.warning(
          <div>
            <ErrorIcon /> {error.response.data.error}{" "}
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      });
  };

  const DeleteTypeClient = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El Tipo de Cliente ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/deletetypeclients/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_TYPECLIENT,
            payload: id,
          });
        }).catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error,
                icon: "error",
              });
            });
      }
    });
  };




  const DeleteClient = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El cliente ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        let url = `/clients/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_CLIENT,
            payload: id,
          });
        });
      }
    });
  };

  return (
    <ClientsContext.Provider
      value={{
        typeclient: state.typeclient,
        clients: state.clients,
        errors: state.errors,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        getclients,
        CreateClient,
        getclientsPublic,
        getclientsStudent,
        getclientsSeller,
        getstudents,
        gettypeclients,
        CreateTypeClient,
        DeleteTypeClient,
        EditTypeClient,
        GetTypeClient
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

export default ClientsState;
