import React, { useState, useEffect, Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button, Tooltip } from "@material-ui/core";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import clienteAxios from "../../Config/Axios";
import Moment from "moment";
import fileDownload from "js-file-download";
import { PictureAsPdf } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MaterialTable from "material-table";
import DateFnsUtils from "@date-io/date-fns";
import { MethodPost } from "../../Config/Services";
import Swal from "sweetalert2";
export default function CashMovements(props) {
  const { id } = props.match.params;
  const [fechaInicio, GuardarFechaInicio] = useState("");
  const [fechaFin, GuardarFechaFin] = useState("");
  const [selectedDateStart, handleDateChangeStart] = useState(new Date());
  const [selectedDateEnd, handleDateChangeEnd] = useState(new Date());

  const [accountSt, saveAccountSt] = useState([]);
  const [movements, saveMovements] = useState([]);


  const handleClearData = () => {
    setShowData(false);
  };

  const [mostrar, setShowData] = useState(true);
  //Detecta cambios de la fecha de inicio
  const cambiarFechaInicio = (date) => {
    var fecha = Moment(date).format("DD-MM-YYYY");
    GuardarFechaInicio(fecha);
    handleDateChangeStart(date);
  };

  //Detecta cambios de la fecha de fin
  const cambiarFechaFin = (date) => {
    var fecha = Moment(date).format("DD-MM-YYYY");
    GuardarFechaFin(fecha);
    handleDateChangeEnd(date);
  };

  //State de todos los movimientos
  const [gmovenets, saveMovement] = useState([]);

  const [boton, saveBoton] = useState(false);

  const [error, guardarError] = useState(true);
  useEffect(() => {
    //Trae los movimientos de dinero
    const getmovement = async () => {
      await clienteAxios
        .get(`branchUtility/${id}`)
        .then((res) => {
          saveMovement(res.data);
        })
        .catch((error) => {
          console.log(error);
        });

      if (props.course) {
        guardarError(false);
      }
    };
    getmovement();
  }, []);

  const GenerarReporte = (e) => {
    //Trae los movimientos con los filtrosde fecha
    e.preventDefault();
    saveBoton(true);
    setShowData(true);
    const data = {
      start_date: fechaInicio,
      end_date: fechaFin,
    };
    let timerInterval;
    Swal.fire({
      title: "Generando",
      html: "El reporte se está generando",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      willClose: () => {
        clearInterval(timerInterval);
      },
    })
    clienteAxios
      .post(`/accountstate/${id}`, data, { responseType: "blob" })
      .then((response) => {
        fileDownload(response.data, "EstadoDeCuenta.pdf");
        guardarError(false);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Algo sucedio",
            text: "No se cuenta con suficiente información para generar el reporte",
          });
        } else if (error.response.status === 422) {
          guardarError(true);
        }
      });
    MethodPost(`cash_Movements_day/${id}`, data)
      .then((res) => {
        saveAccountSt(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
      MethodPost(`cashmovemnts/${id}`, data)
      .then((res) => {
        saveMovements(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Trae la info de la branch para nombre
  const [gbranch, saveBranch] = useState([]);
  useEffect(() => {
    const getotals = async () => {
      await clienteAxios
        .get(`schools/${id}`)
        .then((res) => {
          saveBranch(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      if (props.course) {
        guardarError(false);
      }
    };
    getotals();
  }, []);

  return (
    <LayoutDashboard>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: "15px" }}
      >
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 70 }}>
          <Typography align="center" component="h2" variant="h2">
            Detalle Movimientos Sucursal {gbranch.name}
          </Typography>
        </Grid>

        <Grid container>
          <Grid item xs={3} style={{ padding: 10 }}>
            <Box style={{ backgroundColor: "#303F9F" }} borderRadius={10}>
              <center>
                <Typography style={{ color: "white" }} variant="h5">
                  Saldo Anterior
                </Typography>
                <Typography style={{ color: "white" }} variant="h5">
                  {Object.keys(gmovenets).length != 0 ? (
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(gmovenets.last_money_day)
                  ) : (
                    <LinearProgress />
                  )}
                </Typography>
              </center>
            </Box>
          </Grid>
          <Grid item xs={3} style={{ padding: 10 }}>
            <Box style={{ backgroundColor: "#303F9F" }} borderRadius={10}>
              <center>
                <Typography style={{ color: "white" }} variant="h5">
                  Ventas Actuales
                </Typography>
                <Typography style={{ color: "white" }} variant="h5">
                  {Object.keys(gmovenets).length != 0 ? (
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(gmovenets.total_money_today)
                  ) : (
                    <LinearProgress />
                  )}
                </Typography>
              </center>
            </Box>
          </Grid>
          <Grid item xs={3} style={{ padding: 10 }}>
            <Box style={{ backgroundColor: "#303F9F" }} borderRadius={10}>
              <center>
                <Typography style={{ color: "white" }} variant="h5">
                  {" "}
                  Salidas/Gastos Actuales
                </Typography>
                <Typography style={{ color: "white" }} variant="h5">
                  {Object.keys(gmovenets).length != 0 ? (
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(gmovenets.salida_gasto)
                  ) : (
                    <LinearProgress />
                  )}
                </Typography>
              </center>
            </Box>
          </Grid>
          <Grid item xs={3} style={{ padding: 10 }}>
            <Box style={{ backgroundColor: "#303F9F" }} borderRadius={10}>
              <center>
                <Typography style={{ color: "white" }} variant="h5">
                  Saldo Actual
                </Typography>
                <Typography style={{ color: "white" }} variant="h5">
                  {Object.keys(gmovenets).length != 0 ? (
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(gmovenets.saldo_actual)
                  ) : (
                    <LinearProgress />
                  )}
                </Typography>
              </center>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
          color: "white",
          marginTop: 10,
          borderRadius: 10,
          marginLeft: 10,
          marginRight: 10,

          paddingLeft: 100,
          paddingRight: 100,
        }}
      >
        <Typography align="center" component="h1" variant="h4">
          Movimientos de caja por periodo
        </Typography>
      </Grid>
      <Grid
        style={{
          marginTop: 10,
          borderRadius: 10,
          marginLeft: 10,
          marginRight: 10,
          paddingLeft: 7,
          paddingRight: 7,
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={GenerarReporte} noValidate>
            <Grid
              container
              spacing={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} sm={5}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  inputVariant="outlined"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  label="Fecha Inicio"
                  disableFuture
                  value={selectedDateStart}
                  onChange={cambiarFechaInicio}
                  InputAdornmentProps={{ position: "start" }}
                />
                {!fechaInicio ? (
                  <p style={{ color: "red" }}>
                    La Fecha inicial aún no ha sido seleccionada
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={5}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  inputVariant="outlined"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  disableFuture
                  label="Fecha Fin"
                  value={selectedDateEnd}
                  onChange={cambiarFechaFin}
                  InputAdornmentProps={{ position: "start" }}
                />
                {!fechaFin ? (
                  <p style={{ color: "red" }}>
                    La Fecha final aún no ha sido seleccionada
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={1} style={{ marginTop: "22px" }}>
                <Tooltip title="Generar Reporte" aria-label="Generar Reporte">
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{ backgroundColor: "#3F51B5" }}
                  >
                    Reporte
                    <Tooltip
                      title="Generar Reporte"
                      aria-label="Generar Reporte"
                    >
                      <PictureAsPdf style={{ color: "rgb(250, 250, 250)" }} />
                    </Tooltip>
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={1} style={{ marginTop: "22px" }}>
                <Tooltip title="Limpiar" aria-label="Limpiar">
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    style={{ backgroundColor: "#4B0183" }}
                    onClick={handleClearData}
                  >
                    Limpiar
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </form>
        </MuiPickersUtilsProvider>
        {/* Resúmen de movimientos ---se deben mostrar solo cuando se haya consultado la fecha--- */}
        {boton && mostrar === true ? (
          <Grid
            container
            spacing={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs="12">
              <Typography align="left" variant="h5">
                Resúmen de movimientos durante el periodo seleccionado
              </Typography>
            </Grid>

            <Grid
              container
              style={{
                marginLeft: "20px",
                marginBottom: "30px",
                marginTop: "20px",
              }}
            >
              <Grid item xs="6">
                <Typography
                  align="left"
                  variant="h6"
                  style={{ fontSize: "20px" }}
                >
                  Saldo inicial = $ {accountSt.initialBalance}
                </Typography>
              </Grid>
              <Grid item xs="6">
                <Typography
                  align="left"
                  variant="h6"
                  style={{ fontSize: "20px" }}
                >
                  Saldo final = $ {accountSt.finalBalance}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginLeft: "20px", marginBottom: "10px" }}
            >
              <Grid item xs="6">
                <Grid item xs="12" style={{ marginLeft: "40px" }}>
                  <Typography align="left" style={{ fontSize: "18px" }}>
                    Dinero entrante = (+) ${accountSt.totalsales}
                  </Typography>
                </Grid>
                <Grid item xs="12" style={{ marginLeft: "60px" }}>
                  <Typography align="left">
                    Ventas = (+) ${accountSt.totalsales}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs="6">
                <Grid item xs="12">
                  <Typography align="left" style={{ fontSize: "18px" }}>
                    Dinero saliente = (-) ${accountSt.outgoings}
                  </Typography>
                </Grid>
                <Grid item xs="12" style={{ marginLeft: "60px" }}>
                  <Typography align="left">
                    Gastos = (-) ${accountSt.totalexpenses}
                  </Typography>
                </Grid>
                <Grid item xs="12" style={{ marginLeft: "60px" }}>
                  <Typography align="left">
                    Salidas de caja = (-) ${accountSt.totalcashouts}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid style={{ marginTop: 10 }}>
          <MaterialTable
            title=""
            columns={[
              {
                title: "Fecha de Movimiento",
                field: "created_at",
                type: "date",
              },
              {
                title: "Dinero Anterior",
                field: "previous_balance",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.previous_balance),
              },
              {
                title: "Tipo de Movimiento",
                field: "sale_id",
                field: "branch_office_id",
                field: "id_expense",
                field: "inscription_id",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.id_expense != null ? (
                        <p
                          style={{
                            color: "white",
                            background: "red",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Gastos
                        </p>
                      ) : rowData.id_cash_out != null ? (
                        <p
                          style={{
                            color: "white",
                            background: "#ffc400",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Salidas de Dinero
                        </p>
                      ) : rowData.sale_id != null ? (
                        <p
                          style={{
                            color: "white",
                            background: "#43a047",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Venta
                        </p>
                      ) : rowData.inscription_id != null ? (
                        <p
                          style={{
                            color: "white",
                            background: "#43a047",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Inscripcion
                        </p>
                      ) : null}
                    </Fragment>
                  );
                },
              },
              {
                title: "Diferencia",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.current_balance - rowData.previous_balance),
              },
              {
                title: "Dinero Posterior",
                field: "current_balance",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.current_balance),
              },
        
            ]}
            data={movements}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
              actionsColumnIndex: -1,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Stock de productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
    </LayoutDashboard>
  );
}
