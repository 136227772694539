import React, { useState, useEffect, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
/**Importar MethodPut */
import MethodGet from "../../Config/Services";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from "@material-ui/core";

import CourseContext from "../../Reducers/Courses/CoursesContext";
import SelectTypeCourse from "../SelectOptions/SelectTypeCourse";
import SelectSchools from "../SelectOptions/SelectSchools";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
import SelectIndividualCourses from "../SelectOptions/SelectIndividualCourses";
import CourseInfo from "./CourseInfo";
import CoursesList from "./CoursesList";
/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import EditPhoto from "../../Complements/Image/FormClass/EditPhoto.svg";
import AddFile from "../../Complements/Image/FormClass/AddFile.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

function CourseEdit(props) {
  const classes = useStyles();

  const formCourseContext = useContext(CourseContext);

  const [cargando, spinnerCargando] = useState(false);
  const [classroom, saveClassroom] = useState({});

  const { register, errors, handleSubmit } = useForm();

  const { ErrorsAPI, success, UpdateCourse } = formCourseContext;

  //obtenemos el producto de session storage
  let CourseStorageID = JSON.parse(sessionStorage.getItem("course"));



  //Trabajar con el state de office
  const [officeId, saveOffice] = useState({
    id_branch_office: "",
  });

  const [nameTypeCourses, guardarTypeCourse] = useState({
    type_course: "",
  });

  //extraer las propiedades del objeto Desctructuracion
  const { name, cost, description } = classroom;

  const [courses, saveCourses] = useState([]);

  //Funciones para almacenar el id de classroom
  const detectarCambiosSchool = (e) => {
    saveOffice({ id_branch_office: e.target.value });
  };

  const [individualCourse, saveIndividualCourse] = useState({
    course: "",
  });

  const detectarCambiosIndividualCourses = (value) => {
    saveIndividualCourse(value.key);
  };
  //Query para consultar el detalle de cursos

  useEffect(() => {
    let url = `/coursesDetails/${CourseStorageID}`;

    MethodGet(url)
      .then((res) => {
        saveCourses(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let url = `/courses/${CourseStorageID}`;

    MethodGet(url)
      .then((res) => {
        saveClassroom(res.data.data);
        saveOffice({ id_branch_office: res.data.data.branch_office_id });
        guardarTypeCourse({ type_course: res.data.data.type_course });
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de escuelas
    if (success) {
      //console.log("Retorna");
      props.history.push("/CursosEscuela");
    }
    //eslint-disable-next-line
  }, [success]);

  //Guardar los cambios del cliente
  const onSubmit = (data, e) => {
    e.preventDefault();
    data.school_id = officeId.id_branch_office;
    data.type_course = nameTypeCourses.type_course;
    data.id = CourseStorageID;
    data.courses = courses;
    if (
      officeId.id_branch_office !== "" &&
      nameTypeCourses.type_course !== ""
    ) {
      //enviamos la data a la funcion que se encarga de guardar los registros editados
      UpdateCourse(data);
    } else {
      Swal.fire({
        title: "Error",
        text: "Todos los campos son Obligatorios",
        icon: "error",
      });
    }
  };

  //spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 70 }}
        >
          {/**
           * identificar Codigo de FORMS
           */}
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardHeader color="primary">
                {nameTypeCourses.type_course == 1 ? (
                  <h2 className={classes.cardTitleWhite}>Editar Curso Individual</h2>
                ) : (
                  <h2 className={classes.cardTitleWhite}>Editar Diplomado</h2>
                )}
                <p className={classes.cardCategoryWhite}>
                  Completa correctamente los datos
                </p>
              </CardHeader>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre Curso"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.name}
                      defaultValue={name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre del curso es requerido",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 45,
                          message: "Maximo 45 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="description"
                      label="Descripción"
                      name="description"
                      defaultValue={description}
                      autoComplete="description"
                      autoFocus
                      error={!!errors.description}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La Descripcion es requerida",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 200,
                          message: "Maximo 200 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.description?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="cost"
                      label="Costo"
                      name="cost"
                      defaultValue={cost}
                      autoComplete="name"
                      type="number"
                      error={!!errors.cost}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Costo es requerido",
                        },
                        maxLength: {
                          value: 5,
                          message: "Maximo 5 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.cost?.message}</p>
                  </Grid>
                  {/* <Grid item xs={12} sm={3} style={{ paddingTop: 24 }}>
                    <FormControl
                    
                      variant="outlined"
                      className={classes.FormControl}
                      fullWidth
                    >
                      <SelectSchools
                        officeId={officeId}
                        detectarCambiosSchool={detectarCambiosSchool}
                      />
                    </FormControl>
                  </Grid> */}
                  {nameTypeCourses.type_course == 2 && (
                    <>
                      <Grid item xs={12} sm={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <SelectIndividualCourses
                            detectarCambiosIndividualCourses={
                              detectarCambiosIndividualCourses
                            }
                            SchoolStorageID={officeId.id_branch_office}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <CourseInfo
                          style={{ marginLeft: -9 }}
                          courses={courses}
                          saveCourses={saveCourses}
                          individualCourse={individualCourse}
                          saveIndividualCourse={saveIndividualCourse}
                        />
                      </Grid>
                      <hr />
                      <Grid item xs={12}>
                        <CoursesList
                          courses={courses}
                          saveCourses={saveCourses}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container spacing={3} justify="center">
                  <Grid item spacing={3} xs={12} sm={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.submit}
                    >
                      ACTUALIZAR
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
          {/**
           * identificar Codigo de FORMS
           */}
          {/**
           * identificar Codigo de FORMS
           */}
          
        </Grid>
      </form>
    </LayoutDashboard>
  );
}

export default withRouter(CourseEdit);
