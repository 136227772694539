import React, { useState, useEffect, Fragment } from 'react';
/**Importar configuracion axios */
import clienteAxios from '../../Config/Axios';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function SelectBranch(props) {
	const [offices, saveOffices] = useState([]);
	const [error, guardarError] = useState(true);
	useEffect(() => {
		const getOffices = async () => {
			await clienteAxios
				.get('/branches')
				.then((res) => {
					saveOffices(res.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		};
		getOffices();
	}, []);

	const detectarCambiosOffice = (e) => {
		if (e.target.value) {
			guardarError(false);
		}
		props.detectarCambiosOffice(e);
	};

	return (
		<Fragment>
			<InputLabel fullWidth>Selecciona una sucursal</InputLabel>
				<Select label="Selecciona una sucursal" fullWidth onChange={detectarCambiosOffice}>
					<MenuItem disabled>Selecciona la sucursal</MenuItem>
					{offices.map((office) => (
						<MenuItem key={office.id} value={office.id}>
							{office.name}
						</MenuItem>
					))}
				</Select>
		</Fragment>
	);
}
