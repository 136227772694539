import React, { useContext, useEffect, Fragment } from "react";
import AuthContext from "../../Context/autenticacion/authContext";
import SubCategories from "./SubCategories";
/**importar spinner */
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import { Container, Typography } from "@material-ui/core/";

export default function Subcategoriesindex() {


  return (
    <LayoutDashboard>
<SubCategories/>


    </LayoutDashboard>
  );
}