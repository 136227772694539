import React, { useState, useEffect, Fragment } from 'react';
import clienteAxios from '../../Config/Axios';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

const PurpleCheckbox = withStyles({
    root: {
      color: purple[400],
      '&$checked': {
        color: purple[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

export default function CheckboxIndividualCourses(props) {
	const [courses, saveCourses] = useState([]);
  var xd = [];
    const {saveDisableButton, selectedCourses} = props;
	const [error, guardarError] = useState(true);
	useEffect(() => {
		const getCourses = async () => {
			await clienteAxios
				.get(`/individualcourses/${props.SchoolStorageID}`)
				.then((res) => {
					saveCourses(res.data.data);
				})
				.catch((error) => {
					console.log(error);
				});

			if (props.course) {
				guardarError(false);
			}
		};
		getCourses();
	}, []);

    const handleChange = (event) => {
        // updating an object instead of a Map
        const {value, name, checked, index} = event.target;
        if(checked === true){
            selectedCourses.push({course_id: value, name: name, isChecked: checked});
          } else {
            selectedCourses.splice(index, 1);
        }
        //setSelectedCourses({...selectedCourses, [event.target.name] : event.target.checked, isChecked: event.target.checked });
        saveDisableButton(true);
      }
      
        return (
          <FormGroup row>

						<Grid item xs={12} sm={12}>
              <h2 align="center">Selecciona Los Cursos A Incluir En El Diplomado</h2>
            </Grid>

						<Grid item xs={12} sm={12}>
            {
                courses.map((course , index)=>(
                    <FormControlLabel
                      control={<PurpleCheckbox index={index} onChange={handleChange} value={course.id} checked={selectedCourses[course.name]} name={course.name} />}
                      label={course.name}
                    />
                ))
            }
            </Grid>
          </FormGroup>
        );
      }
