import React, { useState, useEffect, Fragment, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import LocalOffer from "@material-ui/icons/LocalOffer";
import { Button, Tooltip } from "@material-ui/core";
import AlertNotAuthorized from "../../Complements/AlertNotAuthorized";
/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import SchoolContext from "../../Reducers/Schools/SchoolContext";
import imagedefault from "../images/default_image.png";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
}));

export default function CardSchoolUtilities(props) {
  const classes = useStyles();

  const [cargando, spinnerCargando] = useState(false);

  /**Extraer las escuelas del state inicial */
  const SchoolsList = useContext(SchoolContext);
  const { schools, GetSchools, success } = SchoolsList;

  const StateSchoolsContext = useContext(SchoolContext);
  const { SaveCurrentSchool } = StateSchoolsContext;

  const SelectSchool = (school) => {
    SaveCurrentSchool(school);
  };

  //obtener los proveedores carga el componente
  useEffect(() => {
    GetSchools();
    spinnerCargando(true);
  }, [success]);
  //console.log(schools)
  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <>
      {schools.length != 0 ? (
        schools.map((school) => (
          <Grid
            style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 20 }}
            xs={12}
            sm={4}
            md={3}
            lg={3}
            xl={2}
          >
            <Card>
              <CardActionArea
                component={RouterLink}
                to={`/Utilidad/Escolar`}
                onClick={() => SelectSchool(school.id)}
              >
                <CardMedia
                  component="img"
                  height="200"

                  alt={school.name}
                  image={school.image ? school.image : imagedefault}
                  title={school.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {school.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Dirección: {school.direction}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Telefono: {school.number_phone}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))
      ) : (
        <AlertNotAuthorized
          mensaje={
            <p>
              No puedes consultar los cortes de caja escolares, debes contar con
              al menos una escuela!{" "}
              <Link to="/AgregarEscuela" style={{ color: "#3f51b5" }}>
                Da Clic Aqui para registrar una Escuela
              </Link>
            </p>
          }
        />
      )}
    </>
  );
}
