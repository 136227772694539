import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import Coupons from './Coupons';
import CouponsSeller from './CouponsSeller';

export default function CouponsIndex() {

    //Extraer la informacion de autenticación
    const authContext = useContext(AuthContext);
    const { usuario, usuarioAutenticado } = authContext;

    useEffect(() => {
        usuarioAutenticado();
    }, []);

    //definir una variable let para asignarle un componente
    let component;
    //Evaluar que usuario esta autenticado y retornarlo a una vista segun el tipo de usuario
    if(usuario){
      switch (usuario.type) {
          case '1':
              component = <Coupons/>
          break;
          case '2':
              component = <CouponsSeller/>
          break;
          case '3':
              component = <CouponsSeller/>
          break;
          default:
            component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
          break;
     }
   }

    return (
        <Fragment>
          {component}
        </Fragment>
    )
}
