import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../Context/autenticacion/authContext";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import BoxMySales from "./seller/BoxMySales";
import BoxTotalSale from "./seller/BoxTotalSale";
import BoxCommisionsGenerated from "./seller/BoxCommisionsGenerated";
import ChartMySales from "./seller/ChartMySales";
import TableInfoSales from "./seller/TableInfoSales";
import TableRecentComissions from "./seller/TableRecentComissions";
import TableRecentPaymentsComissions from "./seller/TableRecentPaymentsComissions";
import MethodGet from "../../Config/Services";
import Typography from "@material-ui/core/Typography";
/**importar spinner */
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    marginTop: "80px",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(15),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  widgetContent: {
    display: "relative",
    height: "80px",
    marginBottom: "30px !important",
    //background:'linear-gradient(70deg, #3f51b5 30%, indigo 100%)',
    background: "linear-gradient(to right, #3f51b5 15%, indigo 55%) !important",
    padding: "16px",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default function AdminComissions() {
  const classes = useStyles();

  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;
  const [cargando, spinnerCargando] = useState(false);

  const [noSales, saveNoSales] = useState("");
  const [totalSales, saveTotalSales] = useState("");
  const [commisionRate, saveCommisionRate] = useState({});
  const [comissions, saveComissions] = useState("");

  const [month_start, saveMonthStart] = useState("");
  const [month_end, saveMonthEnd] = useState("");

  const [lastComissionStartDate, saveLastComissionStartDate] = useState("");
  const [lastComissionStartHour, saveLastComissionStartHour] = useState("");

  useEffect(() => {
    const APIQuery = async () => {
      let url = `SalesWeekSellerCount`;
      MethodGet(url)
        .then((response) => {
          saveNoSales(response.data.countSalesWeekSeller);
          saveTotalSales(response.data.totalSalesWeekSeller);
          saveComissions(response.data.comissions);
          saveCommisionRate(response.data.commisionRate);
          saveMonthStart(response.data.month_start_date);
          saveMonthEnd(response.data.month_end_date);
          saveLastComissionStartDate(response.data.lastComissionStartDate);
          saveLastComissionStartHour(response.data.lastComissionStartHour);
          spinnerCargando(true);
        })
        .catch((error) => {
          console.log(error);
          spinnerCargando(true);
        });
    };
    APIQuery();
  }, []);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <main>
      <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          justify="center"
          style={{ marginBottom: "15px", marginTop: 70 }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            className={classes.typography}
          >
            Mis Comisiones
          </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={classes.widgetContent}>
                <BoxMySales noSales={noSales} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={classes.widgetContent}>
                <BoxTotalSale
                  comissions={comissions}
                  lastComissionStartDate={lastComissionStartDate}
                  lastComissionStartHour={lastComissionStartHour}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={classes.widgetContent}>
                <BoxCommisionsGenerated
                  totalSales={totalSales}
                  commisionRate={commisionRate}
                  month_start={month_start}
                  month_end={month_end}
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper>
                <TableRecentComissions />
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Paper>
                <TableRecentPaymentsComissions />
              </Paper>
            </Grid>
          </Grid>
      </main>
    </LayoutDashboard>
  );
}
