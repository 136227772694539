import React, {useState , useEffect , Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MethodGet from '../../../Config/Services';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {Link} from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    content:{
        display:'flex',
        padding:'16px',
        overflow:'hidden',
        flexDirection:'column'
     },
    typography:{
       fontSize:'20px',
       fontFamily:'Roboto',
       lineHeight:'1.6',
       marginBottom:'20px',
       marginTop:'20px',
       marginLeft:'0px',
       marginRight:'0px',
       color: '#1976d2' 
    }
 }));

const TableSellersComissions = ({id}) => {
    const classes = useStyles();
    const [ comissions , saveComissions ] = useState([]);

    useEffect(() => {
      const QueryAPI = async () =>{
        let url = `sellersComissionsBranch/${id}`;
        MethodGet(url).then(response=>{
          saveComissions(response.data.data);
        });
      }    
      QueryAPI();
    }, []);


    return (
        <Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <div className={classes.content}>
                    <h2 className={classes.typography}>
                     <font>Vendedores En La Sucursal </font>
                    </h2>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="center">Vendedor</TableCell>
                                <TableCell align="center">Comisiones Sin Cobrar</TableCell>
                                <TableCell align="center">Detalle</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                              !comissions.length ?
                              <TableRow>
                                <TableCell align="center" colSpan={3}>Aún no has cobrado ninguna comisión </TableCell>
                              </TableRow>
                              :
                              comissions.map(comission=>(
                                <TableRow key={comission.id}>
                                <TableCell align="center" component="th" scope="row">
                                  {comission.name}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                  $ {comission.comissions}
                                </TableCell>
                                <TableCell align="center">
                                <Link to={`/DetalleComision/${comission.id}`} style={{ color: "#3f51b5" }}>
                                  <Button
                                    style={{ textTransform: 'none' }}
                                    size="small"
                                  >
                                    <Tooltip title="Detalle de Comisión" aria-label="Detalle de Comisión">
                                      <VisibilityIcon style={{ color: '#01579b' }} />
                                    </Tooltip>
                                  </Button>
                                </Link></TableCell>
                                </TableRow>
                              ))
                                

                            }   
                            </TableBody>
                        </Table>
                    </TableContainer>
              

                </div>
                
            </Grid>
        </Fragment>
        
    );
};

export default TableSellersComissions;