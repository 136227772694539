import { GET_ALL_COUPONS ,  ADD_NEW_COUPON , CURRENT_COUPON ,
    UPDATE_COUPON ,  DELETE_COUPON, APPLY_COUPON } from '../../types/index';

export default function CouponsReducer ( state , action ){

    switch (action.type) {

        case GET_ALL_COUPONS:
            sessionStorage.removeItem('coupon');

            //Funciones que obtienen la fecha actual y la hora actual para posteriormente evaluar si un cupón de descuento esta vigente o no
            const currentdate = new Date(); 
            const date = currentdate.getFullYear() + "-"
                   + ((currentdate.getMonth()+1) < 10 ? "0" + (currentdate.getMonth()+1)  : currentdate.getMonth())  + "-" 
                   + (currentdate.getDate() < 10 ? "0" + currentdate.getDate()  : currentdate.getDate() );
            return{
                ...state,
                coupons: action.payload,
                success:false,
                validationErrorsAPI: [],
                date:date,
            }
        case ADD_NEW_COUPON:
            return{
                ...state,
                success:true,
                validationErrorsAPI: []
            }
        case CURRENT_COUPON:
            sessionStorage.setItem('coupon',JSON.stringify(action.payload))
            return{
                ...state,
            }
        case UPDATE_COUPON:
            sessionStorage.removeItem('coupon');
            return{
                ...state, 
                success: true,
                validationErrorsAPI: []
            }
        case DELETE_COUPON:
            return{
                ...state,
                coupons: state.coupons.filter(coupon => coupon.id !== action.payload)
            }
        default:
            return state;
    }
}