import React, { useState, useEffect, Fragment } from "react";
import Spinner2 from "../../Complements/SpinnerLoading";
import { Grid, Typography } from "@material-ui/core";
import GridItem from "../../Components/Grid/GridItem";

export default function Imageproduct({ producto }) {
  const { image, name } = producto;

  const [cargando, spinnerCargando] = useState(false);

  useEffect(() => {
    if (Object.keys(producto).length !== 0) {
      spinnerCargando(true);
      setTimeout(() => {
        spinnerCargando(false);
      }, 3000);
    }
  }, [producto]);

  //console.log('iamgen', image)
  return (
    <Grid content>
      <GridItem>
        <center>
          <img src={image} alt={name} width={80} height={80} style={{marginBottom:"-10%", opacity: 0.5}}/>
        </center>
      </GridItem>
      {image != null && (
      <GridItem align="center" style={{marginTop:"-5%"}}>
        <b>{name}</b>
      </GridItem>
      )}

    </Grid>
  );
}
