import React, { useContext, useState, useEffect, Fragment } from "react";
import ProductsRow from "./ProductsRow";
import {
  Grid,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Paper,
} from "@material-ui/core";
import MethodGet from "../../Config/Services";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import InventoriesContext from "../../Reducers/Inventories/InventoriesContext";
import ProductContext from "../../Reducers/Products/ProductContext";
import { MethodPost } from "../../Config/Services";
import { TablePagination } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  TableRow: {
    background: "indigo",
  },
  TableTxt: {
    color: "white",
    fontSize: "13pt",
    fontFamily: "arial",
    "&:hover": {
      color: "whitesmoke",
      fontSize: "14pt",
      fontFamily: "arial",
    },
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(30),
  },
  typography: {
    paddingTop: theme.spacing(4),
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#3f51b5",
    },
    color: "inherit",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 615,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
}));


const ProductsList = (props) => {
  const { ventas, guardarVenta, branch_office_id, id, status_report } = props;
  const InventoriesContextList = useContext(InventoriesContext);

  const { AddProductInventory } = InventoriesContextList;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reinicia a la primera página con el nuevo número de filas por página
  };

  const handleChangeQuantity = (e, i) => {
    const newQuantity = Number(e.target.value);
    const todosProducto = [...ventas];
    todosProducto[i].cantidad = newQuantity;

    var data = {};
    data.inventory_id = id;
    data.products = todosProducto;
    AddProductInventory(data);
    guardarVenta(todosProducto);
  };
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const handleDeleteProduct = (index) => {
    const productoEliminado = ventas[index];
    let url = `inventories/rest/${id}`;
    MethodPost(url, productoEliminado)
      .then((res) => {
        Swal.fire({
          title: "Producto Eliminado Correctamente",
          text: res.data.message,
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
    const newVentas = ventas.filter((_, i) => i !== index);
    guardarVenta(newVentas); // Actualiza el estado con la nueva lista de ventas
  };
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow className={classes.TableRow}>
                <TableCell align="center" className={classes.TableTxt}>
                  Clave
                </TableCell>
                <TableCell align="center" className={classes.TableTxt}>
                  Producto
                </TableCell>
                <TableCell align="center" className={classes.TableTxt}>
                  Descripción
                </TableCell>
                <TableCell align="center" className={classes.TableTxt}>
                  Cantidad
                </TableCell>
                {status_report !== 3 ? (
                  <TableCell align="center" className={classes.TableTxt}>
                    Opciones
                  </TableCell>
                ) : null}

                {/* Nueva columna de opciones */}
              </TableRow>
            </TableHead>
            <TableBody>
              {!ventas.length ? (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={6}
                    style={{
                      color: "black",
                      fontSize: "16pt",
                      fontFamily: "arial",
                    }}
                  >
                    Aún no has agregado productos
                  </TableCell>
                </TableRow>
              ) : (
                ventas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((venta, index) => (
                    <ProductsRow
                      status_report={status_report}
                      handleChangeQuantity={handleChangeQuantity}
                      handleDeleteProduct={handleDeleteProduct}
                      key={venta.producto}
                      venta={venta}
                      index={index}
                    />
                  ))
              )}
            </TableBody>
            <TablePagination
      rowsPerPageOptions={[5, 10, 25, { label: 'Todo', value: -1 }]}
      count={ventas.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage} // Asegúrate de que esta prop esté correctamente asignada
      onChangeRowsPerPage={handleChangeRowsPerPage} // Asegúrate de que esta prop esté correctamente asignada
    />
          </Table>
      </Grid>
    </Grid>
  );
};

export default ProductsList;
