import React,{useEffect,useState,Fragment} from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import MethodGet, { MethodPut } from '../../Config/Services';
import ModalImage from 'react-modal-image';
import {Grid, Box} from '@material-ui/core';
import Spinner from '../../Complements/Spinner';
import CourseContext from "../../Reducers/Courses/CoursesContext";
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme) => ({

    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(15),
      paddingBottom: theme.spacing(1),
      paddingInlineEnd: theme.spacing(3),
      paddingRight:theme.spacing(1),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBlockEnd: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    input: {
      display: 'none',
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    paperDetalle: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    paperTitle: {
      background: '#9966cc'
  
    },
    formControl: {
          margin: theme.spacing(1),
          width:'100%'
      },
    papercard: {
      padding: theme.spacing(3),
      margin: 'auto',
      maxWidth: 'auto',
      backgroundColor:'#fff',
    },
    image: {
      width: '200px',
      height: '250px',
      maxWidth:'150px',
      maxHeight:'200px'
    },
    ticketimage: {
      width: 100,
      height: 120,
      maxWidth:100,
      maxHeight:120,
    },
    img: {
      margin: 'auto',
      display: 'flex',
      maxWidth: '96%',
      maxHeight: '96%',
    },
    papercardtitle: {
     background: '#6f33ab',
     //background: '#9966cc',
      padding: 7,
      fontSize:'21pt',
      color:'white',
    },
    Tblpapercard: {
        padding: theme.spacing(3),
        margin: 'auto',
        Width: '100%',
        backgroundColor:'#fff',
    },
  }));

export default function PaymentHistory(props) {

  //obtenemos el producto de session storage
	let InscriptionStorageID = JSON.parse(sessionStorage.getItem("inscription"));

  const classes = useStyles();
  
  const [payments, savePayments] = useState([]);
	const [cargando, spinnerCargando] = useState(false);

	useEffect(() => {
		let url = `/paymenthistory/${InscriptionStorageID}`;
		MethodGet(url)
			.then((res) => {
				savePayments(res.data.data);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
  }, []);

  //  spinner de carga
	if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

<Container className={classes.container}>
  <Grid container xs={12} sm={12} md={12} lg={12} xl={12} >
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography component="h1" variant="h4" align="center" className={classes.papercardtitle}>
            Pagos Realizados
        </Typography>
      </Grid>
    <Paper className={classes.papercard}>
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item container  xs={12} sm={12} md={6} lg={6} xl={6}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
          <Typography component="h5" variant="h5" style={{marginBottom:'3%'}}>
            Fecha
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{marginBottom:'3%'}}>
          <Typography component="h5" variant="h5">
            Tipo de pago
          </Typography>
        </Grid>
  {payments.map(payment => (
    <Grid key={payment.id}
      container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography component="h3" variant="p" color="textSecondary">
              {payment.fecha} <hr/>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography component="h3" variant="p" color="textSecondary">
              {payment.name}<hr/>
            </Typography>
          </Grid>
        </Grid>
    </Grid>
  ))}
</Grid>
<Grid item container xs={12} sm={12} md={6} lg={6} xl={6}>
  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
    <Typography component="h5" variant="h5" style={{marginBottom:'3%'}}>
      Monto
    </Typography>
  </Grid>
  <Grid item xs={3} sm={3} md={3} lg={3} xl={3} style={{marginBottom:'3%'}}>
    <Typography component="h5" variant="h5">
      Referencia
    </Typography>
  </Grid>
  <Grid item xs={3} sm={3} md={3} lg={3} xl={3} style={{marginBottom:'3%'}}>
    <Typography component="h5" variant="h5">
      Folio
    </Typography>
  </Grid>
  {payments.map(payment => (
    <Grid key={payment.id}
      container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Typography component="h3" variant="p" color="textSecondary">
            {
              new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
                minimumFractionDigits: 2
              }).format(payment.amount)
          } <hr/>
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Typography component="h3" variant="p" color="textSecondary">
              { payment.reference
                ? payment.reference
                : (`No Aplica`)
              } <hr/>
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Typography component="h3" variant="p" color="textSecondary">
              { payment.invoice
                ? payment.invoice
                : (`No Aplica`)
              } <hr/>
            </Typography>
          </Grid>
        </Grid>
    </Grid>
  ))}
      </Grid>
    </Grid>
      </Paper>
    </Grid>
  </Grid>
</Container>

<Container className={classes.container}>
  <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography component="h1" variant="h4" align="center" className={classes.papercardtitle}>
            Tickets Pago Por Tarjeta
        </Typography>
      </Grid>
      <Paper className={classes.Tblpapercard}>
        <Grid container>
        {payments.map(payment => (
        payment.ticket &&
        <Grid container xs={12} sm={12} md={12} lg={12} xl={12} 
          key={payment.id}
          direction="row"
          justify="space-around"
          alignItems="center"
          >
            <Grid item container xs={6} sm={6} md={3} lg={3} xl={3}>
              <Grid item >
                <Typography component="h5" variant="h5">
                {payment.fecha}
                </Typography>
              </Grid>          
            </Grid>
            <Grid item container xs={6} sm={6} md={3} lg={3} xl={3}>
              <Grid item >
                <Typography component="h5" variant="h5">
                {payment.name}
                </Typography>
              </Grid>          
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Grid item >
                <Typography component="h5" variant="h5">
                { payment.ticket 
                  ?<ButtonBase className={classes.ticketimage}>
                      <ModalImage 
			    			        small={payment.ticket}
			    			        large={payment.ticket}
			    			        alt="ticket"
                        className={classes.img}
			    			      />
                  </ButtonBase> 
			    			  : (`No Aplica`)
                } 
                </Typography>
              </Grid>  
            </Grid>
          </Grid>
        ))}
        </Grid>
      </Paper>
    </Grid>
  </Grid>
</Container>
        </main>
    </div>
</LayoutDashboard>
  );
}