import React from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom';
import { MethodPost } from '../../Config/Services';
/**Importar Sweetalert2 * */
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(30),
	},
	typography: {
		paddingTop: theme.spacing(4),
		margin: theme.spacing(1),
		paddingLeft: theme.spacing(30),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(9),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		background: '#3537DB',
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			background: '#5902CF',
		},
		color: 'white',
	},
	ContainerSubmit: {
		marginLeft: theme.spacing(30),
		marginTop: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
}));

function PaymentMethodsAdd(props) {
	const classes = useStyles();

	const [operator, setOperator] = React.useState({});

	const detectarOperador = (e) => {
		setOperator({ operator: e.target.value });
	};

	//Funcion para validaciones
	const { register, handleSubmit, errors } = useForm();
	const onSubmit = (data, e) => {
		e.preventDefault();

		//Guardar en la base de datos
		const dat = {
			...operator,
			...data,
		};

		let url = '/methods';
		MethodPost(url, dat)
			.then((res) => {
				Swal.fire({
					title: 'Método de Pago Registrado Exitosamente',
					text: res.data.message,
					icon: 'success',
					timer: 2000,
					showConfirmButton: false,
				});
				props.history.push('/Metodos de pago');
			})
			.catch((error) => {
				Swal.fire({
					icon: 'error',
					title: error.response.data.error,
				});
			});
	};

	return (
		<LayoutDashboard>
			<div className={classes.root}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className={classes.form}
					noValidate
				>
					<Box>
						<Typography
							component="h1"
							variant="h5"
							align="center"
							className={classes.typography}
						>
							Agregar Nuevo Metodo de Pago
						</Typography>
					</Box>
					<Grid container spacing={2} className={classes.container}>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="name"
								label="Metodo de Pago"
								name="name"
								autoComplete="name"
								autoFocus
								error={!!errors.name}
								inputRef={register({
									required: {
										value: true,
										message: 'El metodo de Pago es requerido',
									},
									minLength: {
										value: 3,
										message: 'Minimo 5 caracteres',
									},
									maxLength: {
										value: 45,
										message: 'Maximo 45 caracteres',
									},
									pattern: {
										value: /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
										message: 'Unicamente carácteres alfabéticos',
									},
								})}
							/>
							<p>{errors?.name?.message}</p>
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="description"
								label="Descripción"
								name="description"
								autoComplete="name"
								autoFocus
								error={!!errors.description}
								inputRef={register({
									required: {
										value: true,
										message: 'La Descripción es requerida',
									},
									minLength: {
										value: 5,
										message: 'Minimo 5 caracteres',
									},
									maxLength: {
										value: 45,
										message: 'Maximo 45 caracteres',
									},
								})}
							/>
							<p>{errors?.description?.message}</p>
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="reference"
								label="Referencia"
								name="reference"
								autoComplete="name"
								autoFocus
								error={!!errors.reference}
								inputRef={register({
									required: {
										value: true,
										message: 'La referencia es requerida',
									},
									maxLength: {
										value: 45,
										message: 'Maximo 45 caracteres',
									},
								})}
							/>
							<p>{errors?.reference?.message}</p>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={3} className={classes.ContainerSubmit}>
							<Button
								type="submit"
								fullWidth
								variant="outlined"
								className={classes.submit}
							>
								GUARDAR
							</Button>
						</Grid>
					</Grid>
				</form>
			</div>
		</LayoutDashboard>
	);
}

export default withRouter(PaymentMethodsAdd);
