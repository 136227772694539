import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Moment from "moment";
import Swal from "sweetalert2";
import clienteAxios from "../../Config/Axios";
import fileDownload from "js-file-download";
import { Button, FormControl, Grid, Icon, Tooltip } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Alert } from "@material-ui/lab";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import SelectProducts from './SelectProducts'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function ReportSales() {
    const classes = useStyles();
    const [fechaInicio, GuardarFechaInicio] = useState("");
    const [fechaFin, GuardarFechaFin] = useState("");
    const [selectedDateStart, handleDateChangeStart] = useState(new Date());
    const [selectedDateEnd, handleDateChangeEnd] = useState(new Date());
    const [prod_id, saveProd] = useState(null);
    const [error, guardarError] = useState(false);
    const [id_branch, saveBranch] = useState(null);
    const detectarCambiosBranch = (e) => {
        saveBranch(e.target.value);
    };
    const cambiarFechaInicio = (date) => {
        var fecha = Moment(date).format("DD-MM-YYYY");
        GuardarFechaInicio(fecha);
        handleDateChangeStart(date);
    };
    const cambiarFechaFin = (date) => {
        var fecha = Moment(date).format("DD-MM-YYYY");
        GuardarFechaFin(fecha);
        handleDateChangeEnd(date);
    };
    const reset = () => {
        GuardarFechaInicio("");
        GuardarFechaFin("");
        saveProd(null);
        guardarError(false);
    };
    const detectarCambiosProd = (value) => {
        saveProd(value.value);
    };
    const GenerarReporteUsuario = (e) => {
        e.preventDefault();
        if (prod_id !== null) {
            const data = {
                start_date: fechaInicio,
                end_date: fechaFin,
                product_id: prod_id.toString(),
            };
            let timerInterval;
            Swal.fire({
            title: "Generando",
            html: "El reporte se está generando",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            willClose: () => {
                clearInterval(timerInterval);
            },
            })
            clienteAxios
                .post("/ventasProd", data, { responseType: "blob" })
                .then((response) => {
                    fileDownload(
                        response.data,
                        "VentasProduct " + fechaInicio + " - " + fechaFin + ".pdf"
                    );
                    guardarError(false);
                })
                .catch((error) => {
                    if (error.response.status === 409) {
                        Swal.fire({
                            icon: "error",
                            title: "Algo sucedio",
                            text: "No se cuenta con suficiente información para generar el reporte",
                        });
                    } else if (error.response.status === 422) {
                        guardarError(true);
                    }
                });
            reset();
        }
    };
    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading} fontWeight="bold">
                        Ventas por producto
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {error ? (
                            <Alert severity="warning">
                                Todos los campos son obligatorios!
                            </Alert>
                        ) : null}
                        <form
                            onSubmit={GenerarReporteUsuario}
                            className={classes.form}
                            noValidate
                        >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <KeyboardDatePicker
                                            autoOk
                                            fullWidth
                                            inputVariant="outlined"
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            label="Fecha Inicio"
                                            disableFuture
                                            value={selectedDateStart}
                                            onChange={cambiarFechaInicio}
                                            InputAdornmentProps={{ position: "start" }}
                                        />
                                        {!fechaInicio ? (
                                            <p style={{ color: "red" }}>
                                                La Fecha inicial aún no ha sido seleccionada
                                            </p>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <KeyboardDatePicker
                                            autoOk
                                            fullWidth
                                            inputVariant="outlined"
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            disableFuture
                                            label="Fecha Fin"
                                            value={selectedDateEnd}
                                            onChange={cambiarFechaFin}
                                            InputAdornmentProps={{ position: "start" }}
                                        />
                                        {!fechaFin ? (
                                            <p style={{ color: "red" }}>
                                                La Fecha inicial aún no ha sido seleccionada
                                            </p>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} sm={6} style={{ paddingTop: 16 }}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            className={classes.formControl}
                                        >
                                            <SelectBranchOffice
                                                detectarCambiosOffice={detectarCambiosBranch}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {id_branch !== null && (

                                        <Grid item xs={12} sm={6} style={{ paddingTop: 16 }}>
                                        <FormControl
                                            variant="outlined"
                                            fullWidth
                                            className={classes.formControl}
                                            >
                                            <SelectProducts 
                                                detectarCambiosProd={detectarCambiosProd}
                                                id_branch={id_branch} 
                                                />
                                        </FormControl>
                                    </Grid>
                                            )}
                                    <Grid item xs={12} sm={12}>
                                        <Tooltip
                                            title="Generar Reporte"
                                            aria-label="Generar Reporte"
                                        >
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                endIcon={<Icon>send</Icon>}
                                            >
                                                Generar Reporte
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </form>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
