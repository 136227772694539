import React, { useState } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CardSchoolExpenses from '../../Components/CardsBranches/CardSchoolExpenses';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Grid, Typography, Hidden } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	input: {
		display: 'none',
	},
	typography: {
		margin: theme.spacing(0, 1, 2, 2),
	},
}));

const IndexAdminExpenses = (props) => {
	const classes = useStyles();

	return (
		<LayoutDashboard>
		
			    {/**
       *
       * Aqui es donde aplica el cambio de cards que renderizan las sucursales para los clientes
       */}
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: "15px" }}
      >
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 70 }}>
          <Typography align="center" component="h2" variant="h2">
            Selecciona una Escuela
          </Typography>
        </Grid>

		<CardSchoolExpenses />
      </Grid>
	  {/* separacion */}
		</LayoutDashboard>
	);
};

export default IndexAdminExpenses;
