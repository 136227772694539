import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import GraphicSales from '../Graphics/GraphicSales';
import GraphicStock from '../Graphics/GraphicStock';
import GraphicSalesUser from '../Graphics/GraphicSalesUser';
import GraphicStockUser from '../Graphics/GraphicStockUser';

import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import { Grid } from '@material-ui/core';

export default function Graphics() {
    //Extraer la informacion de autenticación
    const authContext = useContext(AuthContext);
    const { usuario, usuarioAutenticado } = authContext;

    useEffect(() => {
        usuarioAutenticado();
    }, []);

    let component;
    if (usuario) {
        switch (usuario.type) {
            case '1':
                component = [
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg={12}>
                            <GraphicSales />
                        </Grid>
                        
                        <Grid item xs={12} sm={12} lg={12}>
                            <GraphicStock />
                        </Grid>
                        
                    </Grid>]
                break;
            case '2':
                component = [
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg={12}>
                            <GraphicSalesUser />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <GraphicStockUser />
                        </Grid>
                    </Grid>
                ]
                break;
            case '3':
                component = [
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} lg={12}>
                            <GraphicSalesUser />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <GraphicStockUser />
                        </Grid>
                    </Grid>
                ]
                break;
            default:
                component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"} />
                break;
        }
    }
    return (
        <Fragment>
            {component}
        </Fragment>
    )
}
