import React, { useState, useEffect, useContext, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Enlace from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Particles from "react-tsparticles";
/**Importar configuracion Context */
import AuthContext from "../../Context/autenticacion/authContext";
//GOOGLE RECAPTCHA
import ReCAPTCHA from "react-google-recaptcha";
import InputAdornment from "@material-ui/core/InputAdornment";
import clienteAxios from "../../Config/Axios";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import VpnKeyRoundedIcon from "@material-ui/icons/VpnKeyRounded";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Enlace color="inherit" href="https://www.digital-pineapple.com.mx">
        By Digital-Pineapple
      </Enlace>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    height:"100vh",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    fontSize: 70,
    width: 70,
    height: 70,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props) => {
  const classes = useStyles();

  // Auth y token 5509
  //  const authContext = useContext(AuthContext);
  //  const { autenticado, iniciarSesion } = authContext;
  //  const [tokenCaptcha, setToken] = useState("");
  //  const reCaptcha = useRef();


    // Auth y token
     const authContext = useContext(AuthContext);
     const { autenticado, verificationRecaptcha , verifyRecaptcha , iniciarSesion } = authContext;
     const [ disabled , setDisabled ] = useState(true);

  //State para iniciar Sesión
  const [usuario, guardarUsuario] = useState({
    email: "",
    password: "",
  });
  const { email, password } = usuario;

  const onChange = (e) => {
    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    guardarUsuario({
      ...usuario,
      [event.target.name]: event.target.value,
    });
  };

  //cuando el usuario quiere iniciar sesión
  const onSubmit = async (e) => {
    e.preventDefault();
    //pasarlo al action
    iniciarSesion({email, password});
  };

   //valida si el recaptcha  no es de un robot
   const handleClickRecaptcha = value =>{
    verifyRecaptcha(value)
  }

  useEffect(() => {
    if(verificationRecaptcha){
      setDisabled(false)
    }else{
      setDisabled(true)
    }
  }, [verificationRecaptcha])

  //cuando el usuario es valido y sera redirigo al admin
  useEffect(() => {
    // if (autenticado) {
    //   props.history.push("/");
    // }
    // if (localStorage.getItem("token")) {
    //   props.history.push("/");

    if(autenticado){
      setDisabled(true)
      props.history.push('/');
    }
    if(localStorage.getItem('token')){
      setDisabled(true)
      props.history.push('/');
    }
  }, [autenticado, props.history]);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <Particles
          id="tsparticles"
          className={classes.image}
          options={{
            fpsLimit: 60,
            interactivity: {
              detectsOn: "canvas",
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: false,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 400,
                  duration: 2,
                  opacity: 0.8,
                  size: 40,
                },
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#f7f7f78a",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 0.5,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                random: true,
                value: 5,
              },
            },
            detectRetina: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon style={{ fontSize: 50 }} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Iniciar Sesión
          </Typography>
          <form className={classes.form} onSubmit={onSubmit}>
            <div className={classes.margin}>
              <Grid container xs={12} spacing={1} alignItems="center">
                <Grid item xs={2} style={{ paddingTop: 20 }}>
                  <AccountCircleOutlinedIcon
                    style={{ fontSize: 50, color: "#3f51b5", paddingTop: 10 }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    margin="normal"
                    type="email"
                    required
                    fullWidth
                    id="email"
                    label="Correo Electronico"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.margin}>
              <Grid container xs={12} spacing={2} alignItems="center">
                <Grid item xs={2} style={{ paddingTop: 10 }}>
                  <VpnKeyRoundedIcon
                    style={{ fontSize: 50, color: "#3f51b5", paddingTop: 10 }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Input
                    margin="normal"
                    required
                    type={values.showPassword ? "text" : "password"}
                    fullWidth
                    name="password"
                    label="Contraseña"
                    id="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handlePasswordChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
            </div>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Recordar mi contraseña"
            />
            <Grid
              container
              xs={12}
              md={11}
              xl={11}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={6}
                xl={6}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <ReCAPTCHA
                  sitekey={"6LfyZ1kaAAAAACpTz6jxMN1GQmjlSPq5gM4UlcVz"}
                  onChange={handleClickRecaptcha}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={disabled}
            >
              {!disabled
                ? "Iniciar Sesión"
                : "Completa la casilla de verificación"}
            </Button>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              xs={10}
            >
              <Grid item justify="flex-start" xs={10}>
                <Link
                  to={"/password/forgot"}
                  variant="body2"
                  style={{ color: "#3f51b5" }}
                >
                  ¿Olvidaste tu contraseña?
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;