import React from "react";
import Layout from "../../Components/Layout/LayoutDashboard";
import { Grid, Typography } from "@material-ui/core";
import ProductsEntry from "./ProductsEntry";
import ReportBoxCut from "./ReportBoxCut";
import ReportExpenses from "./ReportExpenses";
import ReportSales from "./ReportSales";
import ReportOutputs from "./ReportOutputs";
import Resurtido from "./Resurtido";

const Reports = () => {
  return (
    <Layout>
      <Grid
        container
        spacing={2}
        style={{ marginTop: 80, paddingLeft: 25, paddingRight: 25 }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ backgroundColor: "#283593", color: "white" }}
        >
          <Typography fontWeigth="bold" variant="h4" align="center">
            Panel de reportes
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography fontWeigth="bold" variant="h4" align="center">
            Productos
          </Typography>
          <ProductsEntry />
          <Resurtido/>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography fontWeigth="bold" variant="h4" align="center">
            Corte de caja
          </Typography>
          <ReportBoxCut />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography fontWeigth="bold" variant="h4" align="center">
            Gastos
          </Typography>
          <ReportExpenses />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography fontWeigth="bold" variant="h4" align="center">
            Ventas
          </Typography>
          <ReportSales />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography fontWeigth="bold" variant="h4" align="center">
            Traspasos
          </Typography>
          <ReportOutputs />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Reports;
