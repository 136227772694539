import React, { useEffect, useState, useRef, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Swal from "sweetalert2";
import Spinner from "../../Complements/Spinner";
import headerConfig from "../../Config/imageHeaders";
import MethodGet, { MethodPost } from "../../Config/Services";
import { useForm } from "react-hook-form";
import AuthContext from "../../Context/autenticacion/authContext";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from "@material-ui/core";

import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import CustomInput from "../../Components/CustomInput/CustomInput";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import CardFooter from "../../Components/Card/CardFooter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "#3f51b5",
    "&:hover": {
      background: "#4b0082",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

const type = [{ title: "Administrador" }, { title: "Vendedor" }];

export default function EditProfile(props) {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);

  const authContext = useContext(AuthContext);
  const { usuario, cerrarSesion } = authContext;

  //Funcion para validaciones
  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");

  const [image, setImage] = useState({
    urlPhoto:
      "https://www.adl-logistica.org/wp-content/uploads/2019/07/imagen-perfil-sin-foto-300x300.png",
    image: "",
  });
  const imageDefault =
    "https://www.adl-logistica.org/wp-content/uploads/2019/07/imagen-perfil-sin-foto-300x300.png";

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  useEffect(() => {
    if (usuario) {
      spinnerCargando(true);
      setImage({ urlPhoto: usuario.image });
    }
  }, [usuario]);

  const onSubmit = (data, e) => {
    //crear un formdata
    const formData = new FormData();
    formData.append("name", data.name);
    if (image !== "") {
      formData.append("image", image.image);
    }
    formData.append("number_phone", data.number_phone);
    formData.append("_method", "PATCH");

    if (usuario.email != data.email) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Si cambia su correo electrónico deberá volver a verificarlo e iniciar sesión",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Cambiar",
        cancelButtonText: "No, Cancelar",
      }).then((result) => {
        if (result.value) {
          formData.append("email", data.email);
          let url = `/users/${usuario.id}`; //cambiar la url completa
          MethodPost(url, formData, { headerConfig })
            .then((res) => {
              Swal.fire({
                title: "Por favor, verifica tu nuevo correo electrónico",
                text: res.data.message,
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
              });
              cerrarSesion();
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error,
                icon: "error",
              });
            });
        } else {
          e.preventDefault();
        }
      });
    } else {
      let url = `/users/${usuario.id}`; //cambiar la url completa
      MethodPost(url, formData, { headerConfig })
        .then((res) => {
          Swal.fire({
            title: "Usuario Editado Exitosamente",
            text: res.data.message,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          props.history.push("/");
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.error,
            icon: "error",
          });
        });
    }
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <div className={classes.root}>
        <Container className={classes.container}>
          <Paper className={classes.PaperFormAdd}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={classes.form}
              noValidate
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <Card>
                    <CardHeader color="primary">
                      <h2 className={classes.cardTitleWhite}>Editar Usuario</h2>
                      <p className={classes.cardCategoryWhite}>
                        Completa correctamente los datos
                      </p>
                    </CardHeader>
                    <CardBody>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Nombre Completo"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            defaultValue={usuario.name}
                            error={!!errors.name}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "El Nombre es requerido",
                              },
                              minLength: {
                                value: 4,
                                message: "Minimo 4 caracteres",
                              },
                              maxLength: {
                                value: 255,
                                message: "Maximo 255 caracteres",
                              },
                              pattern: {
                                value:
                                  /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                                message: "Unicamente carácteres alfabéticos",
                              },
                            })}
                          />
                          <p>{errors?.name?.message}</p>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            id="number_phone"
                            label="Teléfono"
                            name="number_phone"
                            autoComplete="phone"
                            type="number"
                            autoFocus
                            defaultValue={usuario.number_phone}
                            error={!!errors.number_phone}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "El Teléfono es requerido",
                              },
                              maxLength: {
                                value: 10,
                                message: "Maximo 10 digitos",
                              },
                            })}
                          />
                          <p>{errors?.number_phone?.message}</p>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo Electronico"
                            name="email"
                            autoComplete="name"
                            autoFocus
                            defaultValue={usuario.email}
                            error={!!errors.email}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "El correo es requerido",
                              },
                              type: "email",
                              pattern:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                          />
                          <p>{errors?.email?.message}</p>
                        </Grid>
                      </Grid>
                    </CardBody>
                    <CardFooter>
                      <Grid container spacing={3} justify="center">
                        <Grid item spacing={3} xs={12} sm={12} md={6}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="filled"
                            className={classes.submit}
							style={{padding:'10px'}}
                          >
                            ACTUALIZAR MIS DATOS
                          </Button>
                        </Grid>
                      </Grid>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Card profile >
                    <CardAvatar profile>
                      <img
                        src={image.urlPhoto}
                        alt="User"
                        width="100%"
                        height="100%"
                      />
                    </CardAvatar>
                    <CardBody profile>
                      <input
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        name="image"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={handleChangeImage}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Tooltip
                            title="seleccionar imagen"
                            aria-label="seleccionar imagen"
                          >
                            <PhotoCamera />
                          </Tooltip>
                        </IconButton>
                      </label>
                      <InputLabel>Selecciona Imagen De Pefil</InputLabel>
                      <h2 className={classes.cardTitle}>Editando mi Perfil:</h2>
                      <h3 className={classes.cardTitle}> {usuario.name}</h3>
                      <p className={classes.description}>
                        Recuerda que la informacion que cambies o elimines
                        afectará los datos de tu usuario.
                      </p>
                      <p className={classes.description}>
					  Al modificar el correo electrónico tendrás que verificarlo nuevamente.                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </form>
          </Paper>
        </Container>
      </div>
    </LayoutDashboard>
  );
}
