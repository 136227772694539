import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import AdminComissions from './AdminComissions';
import SellerComissions from './SellerComissions';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import SubAdminComissions from './SubAdminComissions';

export default function ComissionsUser() {

   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);


  let component;
  if(usuario){
    switch (usuario.type) {
      case '1': 
        component = <AdminComissions/>
        break;
      case '2': 
        component = <SellerComissions/>
        break;
        case '3': 
        component = <SubAdminComissions/>
        break;
      default:
        component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
        break;
    }
  }
  return (
      <Fragment>
          {component} 
      </Fragment>
     

  )
}
