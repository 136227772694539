import {
  ADD_NEW_SCHEDULE,
  CURRENT_SCHEDULE,
  DELETE_SCHEDULE,
  GET_ALL_SCHEDULES,
  GET_ALL_SCHEDULES_STUDENTS,
  GET_ALL_CLASSES,
  SHOW_ERRORS_API,
  UPDATE_SCHEDULE,
  GET_INFO_SCHEDULE,
  GET_ALL_SCHEDULES_GRADUATES,
  UPDATE_CLASS,
  GET_ALL_OPENSCHEDULE,
} from "../../types";

export default function SchedulesReducer(state, action) {
  switch (action.type) {
    case GET_ALL_SCHEDULES:
      return {
        ...state,
        schedules: action.payload,
        success: false,
        ErrorsAPI: [],
      };

    case GET_ALL_CLASSES:
      return {
        ...state,
        classesSchedule: action.payload,
        success: false,
        ErrorsAPI: [],
      };

    case GET_ALL_SCHEDULES_GRADUATES:
      return {
        ...state,
        schedules: action.payload,
        success: false,
        ErrorsAPI: [],
      };
      case GET_ALL_OPENSCHEDULE:
        return {
          ...state,
          schedules: action.payload,
          success: false,
          ErrorsAPI: [],
        };

    case GET_ALL_SCHEDULES_STUDENTS:
      return {
        ...state,
        students: action.payload,
        success: false,
        ErrorsAPI: [],
      };

    case GET_INFO_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
        success: false,
        ErrorsAPI: [],
      };

    case ADD_NEW_SCHEDULE:
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };

    case SHOW_ERRORS_API:
      return {
        ...state,
        ErrorsAPI: action.payload,
      };

    case CURRENT_SCHEDULE:
      //sessionStorage.setItem('selectedSchools',JSON.stringify(action.payload))
      return {
        ...state,
        schedule: action.payload,
      };

    case UPDATE_SCHEDULE:
      //sessionStorage.removeItem('selectedSchools');
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };

    case UPDATE_CLASS:
      //sessionStorage.removeItem('selectedSchools');
      return {
        ...state,
        success: true,
        ErrorsAPI: [],
      };

    case DELETE_SCHEDULE:
      return {
        ...state,
        schedules: state.schedules.filter(
          (schedule) => schedule.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
