import React, { useReducer } from 'react';
import ClassroomContext from './ClassroomContext'
import ClassroomReducer from './ClassroomReducer';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../Config/Services";
import { ADD_NEW_CLASSROOM, CURRENT_CLASSROOM, DELETE_CLASSROOM, GET_ALL_CLASSROOMS,
    SHOW_ERRORS_API, UPDATE_CLASSROOM, GET_INFO_CLASSROOM } from "../../types";
import Swal from "sweetalert2";

const ClassroomState = ({children}) => {
  // estado inicial
  const initialSate = {
    classrooms: [],
    classroom: null,
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(ClassroomReducer, initialSate);

  //obtener la lista de proveedores
  const GetClassrooms = (id) => {
    sessionStorage.removeItem('classroom');
    let url = `schoolclassrooms/${id}`;
    MethodGet(url).then((response) => {
        //console.log(response);
      dispatch({
        type: GET_ALL_CLASSROOMS,
        payload: response.data.data,
      });
    });
  };

  const GetInfoClassroom = (id) => {
    let url = `classrooms/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_INFO_CLASSROOM,
        payload: response.data.data,
      });
    });
  };

  //función para crear una nueva escuela
  const AddClassroom = (classroom) => {
    //console.log(school, image, municipality, state);
    const formData = new FormData();
		formData.append("name", classroom.name);
		formData.append("capacity", classroom.capacity);
		formData.append("branch_office_id", classroom.school_id);
    //console.log(school.image);
    let url = "/classrooms";
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_CLASSROOM,
        });
      })
      .catch((error) => {
        Swal.fire({
					title: 'Error',
                    text: error.response.data.error,
    			    icon: 'error'
				});
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //función para seleccionar un salon
  const SaveCurrentClassroom = (classroom) => {
    sessionStorage.setItem('classroom',JSON.stringify(classroom));
    dispatch({
      type: CURRENT_CLASSROOM,
      payload: classroom,
    });
  };

  //función para editar un salon
  const UpdateClassroom = (classroom) => {
    const formData = new FormData();
		formData.append("name", classroom.name);
		formData.append("capacity", classroom.capacity);
		formData.append("branch_office_id", classroom.school_id);
		formData.append("_method", "PATCH");

    let url = `classrooms/${classroom.id}`;
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_CLASSROOM,
        });
      })

      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  //Eliminar Proveedor por id
  const DeleteClassroom = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El salón ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `classrooms/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_CLASSROOM,
            payload: id,
          });
        });
      }
    });
  };

  return (
    <ClassroomContext.Provider
      value={{
        classrooms: state.classrooms,
        classroom: state.classroom,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        GetClassrooms,
        AddClassroom,
        SaveCurrentClassroom,
        GetInfoClassroom,
        UpdateClassroom,
        DeleteClassroom,
      }}
    >
      {children}
    </ClassroomContext.Provider>
  );
}

export default ClassroomState;