import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

//import Login from './Components/Forms/Auth/Login';

import BusinessRulesAdd from "../Containers/Business_Rules/Add";
import BusinessRulesEdit from "../Containers/Business_Rules/Edit";
import BusinessRulesIndex from "../Containers/Business_Rules/Index";
import BusinessRulesAdmin from "../Containers/Business_Rules/BusinessRulesAdmin";

import InventoriesIndex from "../Containers/Inventories/Index";
import OfficeInventoriesAdmin from "../Containers/Inventories/OfficeInventoriesAdmin";
import InventoryProducts from "../Containers/Inventories/InventoryProducts";

import CouponsIndex from "../Containers/Coupons/Index";
import CouponsAdd from "../Containers/Coupons/Add";
import CouponsEdit from "../Containers/Coupons/Edit";

import CategoriesIndex from "../Containers/Categories/Index";

import CoursesIndex from "../Containers/Courses/Index";
import CoursesEdit from "../Containers/Courses/Edit";
import CoursesAdd from "../Containers/Courses/Add";
import SchoolCourses from "../Containers/Courses/SchoolCourses";
import GraduateDetail from "../Containers/Courses/GraduateDetail";

import ClientIndex from "../Containers/Clients/Index";
import MiddlewareClients from "../Containers/Clients/BranchMiddlewareClients";
import ClientEdit from "../Containers/Clients/Edit";

// subcategorias
import SubCategoriesIndex from "../Containers/SubCategories/Index";
import SubCategoriesAdd from "../Containers/SubCategories/Add";
import SubCategoriesEdit from "../Containers/SubCategories/Edit";


import ClassroomsAdd from "../Containers/Classrooms/Add";
import ClassroomsEdit from "../Containers/Classrooms/Edit";
import ClassroomsIndex from "../Containers/Classrooms/Index";
import SchoolClassrooms from "../Containers/Classrooms/SchoolClassrooms";

import ScheduleAdd from "../Containers/Schedule/Add";
import ScheduleAddIndividual from "../Containers/Schedule/AddIndividualCourse";
import ScheduleAddGraduate from "../Containers/Schedule/AddGraduateCourse";
import ScheduleEdit from "../Containers/Schedule/Edit";
import ScheduleEditGraduate from "../Containers/Schedule/EditGraduate";
import ScheduleEditIndividual from "../Containers/Schedule/EditIndividual";
import EditClass from "../Containers/Schedule/EditClass";
import ScheduleIndex from "../Containers/Schedule/Index";
import SchoolSchedules from "../Containers/Schedule/SchoolSchedules";
import ScheduleGraduateDetail from "../Containers/Schedule/ScheduleGraduateDetail";
import IndividualSheduleDetail from "../Containers/Schedule/IndividualSheduleDetail";

import PaymentMethodsIndex from "../Containers/Payments_Methods/Index";
import PaymentMethodsEdit from "../Containers/Payments_Methods/Edit";
import PaymentMethodsAdd from "../Containers/Payments_Methods/Add";

import ProductStockAdd from "../Containers/Products_Stocks/Add";
import ProductStockEdit from "../Containers/Products_Stocks/Edit";
import ProductStockIndex from "../Containers/Products_Stocks/Index";

import MiddlewareProducts from "../Containers/Products/BranchMiddlewareProducts";
import ProductEdit from "../Containers/Products/Edit";
import ProductIndex from "../Containers/Products/Index";

import BankAccountsIndex from "../Containers/BankAccounts/Index";
import BankAccountsEdit from "../Containers/BankAccounts/Edit";
import BankAccountsAdd from "../Containers/BankAccounts/Add";
// Productos desactivados
import Products_Desc from "../Containers/Products_Desc/Index";
import SucursalProductDesc from "../Containers/Products_Desc/OfficeProductsDescAdmin";

//INDEX PARA LOS TABS
import SalesIndex from "../Containers/Sales/Index";
//Middleware Sales
import MiddlewareAddSales from "../Containers/Sales/MiddlewareAddSales";
//IMPORTAMOS COMPONENTES DE PRODUCTOS
import SalesAddProduct from "../Containers/Sales/Add_SaleProduct";
import SalesEditProduct from "../Containers/Sales/Edit_SaleProduct";
//IMPORTAMOS COMPONENTES DE APLICACION DE UÑAS
import SalesAddApplication from "../Containers/Sales/Add_SaleApplication";
import SalesEditApplication from "../Containers/Sales/Edit_SaleApplication";
//IMPORTAMOS COMPONENTES DE INSCRIPCIONES
import InscriptionIndex from "../Containers/Inscriptions/Index";
import SchoolInscriptions from "../Containers/Inscriptions/SchoolInscriptions";
import InscriptionAdd from "../Containers/Inscriptions/Add";
import InscriptionEdit from "../Containers/Inscriptions/Edit";
import PaymentHistory from "../Containers/Inscriptions/PaymentHistory";

import DetailsInscriptions from "../Containers/Sales_Details/Inscriptions";
import DetailsSales from "../Containers/Sales_Details/SalesProducts";
import DetailsAplication from "../Containers/Sales_Details/Aplication";

import UserIndex from "../Containers/Users/Index";
import EditProfile from "../Containers/Users/EditProfile";
import ChangePassword from "../Containers/Users/ChangePassword";
import UserEdit from "../Containers/Users/Edit";
import UserAdd from "../Containers/Users/Add";

import ExpensesIndex from "../Containers/Expenses/Index";
import ExpensesEdit from "../Containers/Expenses/Edit";
import ExpensesAdd from "../Containers/Expenses/Add";
import AddSchoolExpense from "../Containers/Expenses/AddSchoolExpense";
import ExpensesIndexSchool from "../Containers/Expenses/IndexSchool";

import SchoolIndex from "../Containers/Schools/Index";
import SchoolAdd from "../Containers/Schools/Add";
import SchoolEdit from "../Containers/Schools/Edit";

import UtilityIndex from "../Containers/Schools/UtilityIndex";
import SchoolUtility from "../Containers/Schools/SchoolUtility";

import UtilityBranchIndex from "../Containers/Branch_Offices/UtilityIndex";
import BranchAdminUtilities from "../Containers/Branch_Offices/BranchAdminUtilities";
import CashMovements from "../Containers/Branch_Offices/CashMovements";
import CashMovementsSchool from "../Containers/Schools/CashMovementsSchool";

import DepartureIndex from "../Containers/Departures/Index";
import DepartureIndex2 from "../Containers/Departures/Index2";

import BranchDepartures from "../Containers/Departures/BranchDepartures";
import BranchDeparturesBranch from "../Containers/Departures/BranchDeparturesBranch";

import DepartureAdd from "../Containers/Departures/Add";
import DepartureAdd2 from "../Containers/Departures/Add2";
import PendingBranchDepartures from "../Containers/Departures/PendingBranchDepartures";
import ViewPendingBranchDepartures from "../Containers/Departures/ViewPendingBranchDepartures";
import ShowTransfer from "../Containers/Departures/ShowTransfer";



import OfficeIndex from "../Containers/Branch_Offices/Index";
import OfficeAdd from "../Containers/Branch_Offices/Add";
import OfficeEdit from "../Containers/Branch_Offices/Edit";
//Productos de una Sucursal
import OfficeProducts from "../Containers/Branch_Offices/OfficeProducts";
//Clientes de una Sucursal
import OfficeClients from "../Containers/Branch_Offices/OfficeClients";

//Stocks de una Sucursal
import OfficeStocks from "../Containers/Branch_Offices/OfficeStocks";
//Movimientos de stock de una Sucursal
import OfficeStockMovements from "../Containers/Branch_Offices/OfficeStockMovements";
//Comisiones de una Sucursal
import OfficeComissions from "../Containers/Comissions/OfficeComissions";
//Comisiones de una Sucursal
import OfficeSales from "../Containers/Sales/OfficeSales";
//Ventas Canceladas
import OfficeCanceledSales from "../Containers/Sales/Canceled/Index";
import OfficeCanceledSalesAdmin from "../Containers/Sales/Canceled/BranchCanceledSales";
//Gastos de una Sucursal
import OfficeExpenses from "../Containers/Branch_Offices/OfficeExpenses";
import SchoolExpenses from "../Containers/Expenses/SchoolExpenses";

import ServiceIndex from "../Containers/Services/Index";
import ServiceAdd from "../Containers/Services/Add";
import ServiceEdit from "../Containers/Services/Edit";

import ServiceScheduleIndex from "../Containers/Service_Schedules/Index";
import ServiceScheduleAdd from "../Containers/Service_Schedules/Add";
import ServiceScheduleEdit from "../Containers/Service_Schedules/Edit";

// Detalle de movimientos de stocks
import StockMovementsIndex from "../Containers/Stock_Movements/Index";

// Comisiones
import ComissionsUser from "../Containers/Comissions/Index";
import SellerComissionDetails from "../Containers/Comissions/SellerComissionDetails";

// Reportes
import ReportProductEntry from "../Containers/Reports/ReportProductEntry";
import UtilitySales from "../Containers/Reports/UtilitySales";
import UtilityInscriptions from "../Containers/Reports/UtilityInscriptions";

/* Componente para el registros de un usuario */
import SignInSide from "../Components/Auth/Register";

/* Componente de Login */
import Login from "../Components/Auth/Login";

/**Not Found Page */
import NotFoundPage from "../Containers/NotFoundPage/NotFoundPage";

/*  Import componentente PasswordReset */
import PasswordReset from "../Components/PasswordReset/PasswordReset";
import UpdatePassword from "../Components/PasswordReset/UpdatePassword";

/**Importar el authState */
import AuthState from "../Context/autenticacion/authState";

/**Importar token header axios */
import tokenAuth from "../Config/tokenAuth";

import RutaPrivada from "./RoutePrivate";

import Branch_index from "../Containers/Branch_Offices/Branch_index";

//States
/**Importar SchoolState */
import SchoolState from "../Reducers/Schools/SchoolState";
/**Importar ClassroomState */
import ClassroomState from "../Reducers/Classrooms/ClassroomState";
/**Importar CoursesState */
import CoursesState from "../Reducers/Courses/CoursesState";
/**Importar SchedulesState */
import SchedulesState from "../Reducers/Schedules/SchedulesState";
/**Importar InscriptionsState */
import InscriptionsState from "../Reducers/Inscriptions/InscriptionsState";
/**Importar BankAccountsState */
import BankAccountsState from "../Reducers/BankAccounts/BankAccountsState";
/**Importar ProductsState */
import ProductState from "../Reducers/Products/ProductState";
/**Importar ExpensesState */
import ExpensesState from "../Reducers/Expenses/ExpensesState";
/**Importar CouponsState */
import CouponsState from "../Reducers/Coupons/CouponsState";
/**Importar DeparturesState */
import DeparturesState from "../Reducers/Departures/DeparturesState";
/**Importar CashOutsState */
import CashOutsState from "../Reducers/CashOuts/CashOutsState";
/**Importar InventoriesState */
import InventoriesState from "../Reducers/Inventories/InventoriesState";
/**Importar CategoryState */
import CategoryState from "../Reducers/Categories/CategoryState";
/**Importar ResetPasswordsState */
import ResetPasswordsState from "../Reducers/ResetPasswords/ResetPasswordsState";
import ClientsState from "../Reducers/Clients/ClientsState";
import StudentClientsDetails from "../Containers/Clients/StudentClientsDetails";

//import Login from './Components/Forms/Auth/Login';
import ViewGraphic from "../Containers/Principal/ViewGraphic";
import Home from "../Containers/Home/Home";
import SubCategoriesState from "../Reducers/SubCategories/SubCategoryState";
import SalesGraphic from "../Containers/Branch_Offices/SalesGraphic";
import SalesGraphicDays from "../Containers/Branch_Offices/SalesGraphicDays";
import SalesGraphicDaysSchool from "../Containers/Schools/SalesGraphicDaysSchool";
import SalesGraphicSchool from "../Containers/Schools/SalesGraphicSchool";
import Reports from "../Containers/Reports/Reports";
import { ToastContainer } from "react-toastify";
//Revisar si tenemos algun token
const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}

function App() {
  //console.log(process.env.REACT_APP_BACKENTD_URL); //revisamos el entorno de la api
  return (
    <AuthState>
      <ResetPasswordsState>
        <SchoolState>
          <ClassroomState>
            <CoursesState>
              <SchedulesState>
                <InscriptionsState>
                  <BankAccountsState>
                    <ProductState>
                      <ExpensesState>
                        <CouponsState>
                          <DeparturesState>
                            <CashOutsState>
                              <InventoriesState>
                                <CategoryState>
                                  <SubCategoriesState>

                                    <ClientsState>
                                      <Router>

                                        <Switch>
                                          {/**RUTA PARA PAGINA PRINCIPAL */}
                                          <RutaPrivada
                                            exact
                                            path="/"
                                            component={Home}
                                          />
                                          {/**  GRAFICAS DEL SISTEMA*/}
                                          <RutaPrivada
                                            exact
                                            path="/Graficas"
                                            component={ViewGraphic}
                                          />

                                          {/*RUTA USERS*/}

                                          <RutaPrivada
                                            exact
                                            path="/Usuarios"
                                            component={UserIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarPerfil"
                                            component={EditProfile}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarContraseña"
                                            component={ChangePassword}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Usuario"
                                            component={UserAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_Usuario/:id"
                                            component={UserEdit}
                                          />
                                          {/**RUTA COURSES */}
                                          <RutaPrivada
                                            exact
                                            path="/Cursos"
                                            component={CoursesIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Curso"
                                            component={CoursesAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_Curso"
                                            component={CoursesEdit}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/CursosEscuela"
                                            component={SchoolCourses}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Diplomado"
                                            component={GraduateDetail}
                                          />
                                          {/**RUTA CLASSROOMS */}
                                          <RutaPrivada
                                            exact
                                            path="/Salones"
                                            component={ClassroomsIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/SalonesEscuela"
                                            component={SchoolClassrooms}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Salon"
                                            component={ClassroomsAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_Salon"
                                            component={ClassroomsEdit}
                                          />
                                          {/**RUTA BRANCH_OFFICES */}
                                          <RutaPrivada
                                            exact
                                            path="/Sucursales"
                                            component={OfficeIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Sucursal"
                                            component={OfficeAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_Sucursal/:id"
                                            component={OfficeEdit}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/SucursalProductos/:id"
                                            component={OfficeProducts}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/SucursalStocks/:id"
                                            component={OfficeStocks}
                                          />

                                          <RutaPrivada
                                            exact
                                            path="/Branch_index/:id"
                                            component={Branch_index}
                                          />

                                          <RutaPrivada
                                            exact
                                            path="/Categorias"
                                            component={CategoriesIndex}
                                          />
                                          {/* rutas SubCategories */}
                                          <RutaPrivada
                                            exact
                                            path="/SubCategorias"
                                            component={SubCategoriesIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/AgregarSubCategoria/:id_category"
                                            component={SubCategoriesAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarSubCategoria"
                                            component={SubCategoriesEdit}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Inventarios"
                                            component={InventoriesIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Inventarios/Sucursal/:id"
                                            component={OfficeInventoriesAdmin}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Inventario/Productos/:id"
                                            component={InventoryProducts}
                                          />

                                          {/**RUTAS SCHOOLS */}
                                          <RutaPrivada
                                            exact
                                            path="/Escuelas"
                                            component={SchoolIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/AgregarEscuela"
                                            component={SchoolAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarEscuela"
                                            component={SchoolEdit}
                                          />

                                          <RutaPrivada
                                            exact
                                            path="/Utilidad/Escuela"
                                            component={UtilityIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Utilidad/Escolar"
                                            component={SchoolUtility}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Utilidad/Movimientos/:id"
                                            component={CashMovementsSchool}
                                          />

                                          <RutaPrivada
                                            exact
                                            path="/Utilidad/GraficasVentasDiasSchool/:id"
                                            component={SalesGraphicDaysSchool}
                                          />

                                          <RutaPrivada
                                            exact
                                            path="/Utilidad/GraficasVentasSchool/:id"
                                            component={SalesGraphicSchool}
                                          />

                                          {/**RUTAS COUPONS */}
                                          <RutaPrivada
                                            exact
                                            path="/Cupones"
                                            component={CouponsIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/AgregarCupon"
                                            component={CouponsAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarCupon"
                                            component={CouponsEdit}
                                          />

                                          {/**Rutas Movimientos Stocks */}
                                          <RutaPrivada
                                            exact
                                            path="/SucursalStocksMovimientos/:id"
                                            component={OfficeStockMovements}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/SucursalComisiones/:id"
                                            component={OfficeComissions}
                                          />

                                          {/**RUTAS Schedule */}
                                          <RutaPrivada
                                            exact
                                            path="/Programaciones"
                                            component={ScheduleIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/AgregarProgramacion"
                                            component={ScheduleAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/AgregarProgramacion/Individual"
                                            component={ScheduleAddIndividual}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/AgregarProgramacion/Diplomado"
                                            component={ScheduleAddGraduate}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarProgramacion"
                                            component={ScheduleEdit}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarProgramacion/Individual"
                                            component={ScheduleEditIndividual}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarProgramacion/Diplomado"
                                            component={ScheduleEditGraduate}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarProgramacion/Clase"
                                            component={EditClass}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/ProgramacionesCursos"
                                            component={SchoolSchedules}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/ProgramacionDiplomado"
                                            component={ScheduleGraduateDetail}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/DetalleCursoIndividual/:id"
                                            component={IndividualSheduleDetail}
                                          />

                                          {/**RUTA CLIENTS */}
                                          <RutaPrivada
                                            exact
                                            path="/Clientes"
                                            component={ClientIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Cliente/:id"
                                            component={MiddlewareClients}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_Cliente/:id"
                                            component={ClientEdit}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/SucursalClientes/:id"
                                            component={OfficeClients}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/StudentClientsDetails/:id"
                                            component={StudentClientsDetails}
                                          />
                                          {/*Tipo de clientes*/}
                                          {/* <RutaPrivada
                                            exact
                                            path="/TipoClientes"
                                            component={TypeClientsIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/SucursalTiposClientes/:id"
                                            component={OfficeTypeClients}
                                          /> */}

                                          {/*RUTA REGLAS DE NEGOCIOS */}
                                          <RutaPrivada
                                            exact
                                            path="/Reglas"
                                            component={BusinessRulesIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/ReglasNegocio/:id"
                                            component={BusinessRulesAdmin}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Regla/:id"
                                            component={BusinessRulesAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/ReglasNegocio/Editar_Regla/:id"
                                            component={BusinessRulesEdit}
                                          />

                                          {/**RUTA Bank Accounts */}
                                          <RutaPrivada
                                            exact
                                            path="/AgregarCuentaBancaria"
                                            component={BankAccountsAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarCuentaBancaria"
                                            component={BankAccountsEdit}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/CuentasBancarias"
                                            component={BankAccountsIndex}
                                          />

                                          {/*RUTA PAYMENTS_METHODS*/}
                                          <RutaPrivada
                                            exact
                                            path="/Metodos de Pago"
                                            component={PaymentMethodsIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Metodo"
                                            component={PaymentMethodsAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_Metodo/:id"
                                            component={PaymentMethodsEdit}
                                          />
                                          {/**RUTAS PRODUCTS */}
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Producto/:id"
                                            component={MiddlewareProducts}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_Producto/:id"
                                            component={ProductEdit}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Productos"
                                            component={ProductIndex}
                                          />
                                          {/**RUTAS Salidas */}
                                          <RutaPrivada
                                            exact
                                            path="/Salidas/Agregar/:id"
                                            component={DepartureAdd}
                                          />
                                              <RutaPrivada
                                            exact
                                            path="/Traspaso/Agregar/:id"
                                            component={DepartureAdd2}
                                          />
                                           <RutaPrivada
                                            exact
                                            path="/Traspaso/Pendiente/:id"
                                            component={PendingBranchDepartures}
                                          />
                                          
                                          <RutaPrivada
                                            exact
                                            path="/Traspaso/Detalle/:id"
                                            component={ViewPendingBranchDepartures}
                                          />
                                               
                                               <RutaPrivada
                                            exact
                                            path="/Traspaso/Detall/:id"
                                            component={ShowTransfer}
                                          />
                                           <RutaPrivada
                                            exact
                                            path="/Salidas"
                                            component={DepartureIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Traspaso"
                                            component={DepartureIndex2}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Salidas/Productos/:id"
                                            component={BranchDepartures}
                                          />
                                           <RutaPrivada
                                            exact
                                            path="/Traspaso/Productos/:id"
                                            component={BranchDeparturesBranch}
                                          />
                                          {/*RUTAS PRODUCTS_STOCK*/}
                                          <RutaPrivada
                                            exact
                                            path="/Stock de Productos"
                                            component={ProductStockIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Stock"
                                            component={ProductStockAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_Stock/:id"
                                            component={ProductStockEdit}
                                          />
                                          {/* rutas de productos desactivados */}
                                          <RutaPrivada
                                            exact
                                            path="/Productos_Desactivados"
                                            component={Products_Desc}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/SucursalProductDesc/:id"
                                            component={SucursalProductDesc}
                                          />

                                          {/**RUTAS SALES_DETAILS */}
                                          <RutaPrivada
                                            exact
                                            path="/Detalle_Inscripcion"
                                            component={DetailsInscriptions}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/DetalleVenta/:id"
                                            component={DetailsSales}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Detalle_AplicacionUñas"
                                            component={DetailsAplication}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Ventas/Canceladas"
                                            component={OfficeCanceledSales}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/SucursalVentas/Canceladas/:id"
                                            component={OfficeCanceledSalesAdmin}
                                          />
                                          {/**RUTAS SALES PRODUCTOS Y APLICACIONES */}
                                          <RutaPrivada
                                            exact
                                            path="/Ventas"
                                            component={SalesIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/SucursalVentas/:id"
                                            component={OfficeSales}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_VentaProducto/:id"
                                            component={MiddlewareAddSales}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_VentaProducto/:id"
                                            component={SalesEditProduct}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_VentaAplicacion"
                                            component={SalesAddApplication}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_VentaAplicacion/:id"
                                            component={SalesEditApplication}
                                          />

                                          <RutaPrivada
                                            exact
                                            path="/Utilidad/Tienda"
                                            component={UtilityBranchIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Utilidad/Sucursal/:id"
                                            component={BranchAdminUtilities}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Utilidad/Sucursal/Movimientos/:id"
                                            component={CashMovements}
                                          />

                                          <RutaPrivada
                                            exact
                                            path="/Utilidad/Sucursal/GraficasVentas/:id"
                                            component={SalesGraphic}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Utilidad/Sucursal/GraficasVentasDias/:id"
                                            component={SalesGraphicDays}
                                          />

                                          {/**RUTA INSCRIPTIONS */}
                                          <RutaPrivada
                                            exact
                                            path="/Inscripciones"
                                            component={InscriptionIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/InscripcionesEscuela"
                                            component={SchoolInscriptions}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/HistorialPagos"
                                            component={PaymentHistory}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/AgregarInscripcion"
                                            component={InscriptionAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarInscripcion"
                                            component={InscriptionEdit}
                                          />
                                          {/*RUTA SERVICE_SCHEDULES */}
                                          <RutaPrivada
                                            exact
                                            path="/Citas"
                                            component={ServiceScheduleIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Cita"
                                            component={ServiceScheduleAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_Cita/:id"
                                            component={ServiceScheduleEdit}
                                          />
                                          {/*RUTA SERVICES */}
                                          <RutaPrivada
                                            exact
                                            path="/Servicios"
                                            component={ServiceIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Agregar_Servicio"
                                            component={ServiceAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Editar_Servicio/:id"
                                            component={ServiceEdit}
                                          />

                                          {/*RUTAS EXPENSES */}
                                          <RutaPrivada
                                            exact
                                            path="/Gastos/Tienda"
                                            component={ExpensesIndex}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Gastos/Escuela"
                                            component={ExpensesIndexSchool}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/AgregarGasto/:id"
                                            component={ExpensesAdd}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/AgregarGasto/Escolar/:id"
                                            component={AddSchoolExpense}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/EditarGasto/:id"
                                            component={ExpensesEdit}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/SucursalGastos/:id"
                                            component={OfficeExpenses}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/GastosEscolares/:id"
                                            component={SchoolExpenses}
                                          />

                                          {/* RUTAS Movimientos stocks */}
                                          <RutaPrivada
                                            exact
                                            path="/MovimientosStock"
                                            component={StockMovementsIndex}
                                          />

                                          {/* RUTAS Comisiones De Usuarios */}
                                          <RutaPrivada
                                            exact
                                            path="/Comisiones"
                                            component={ComissionsUser}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/DetalleComision/:id"
                                            component={SellerComissionDetails}
                                          />

                                          {/* RUTAS REPORTES */}
                                          <RutaPrivada
                                            exact
                                            path="/EntradaProductos"
                                            component={ReportProductEntry}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Utilidad"
                                            component={UtilitySales}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Reportes/Utilidad/Escuelas"
                                            component={UtilityInscriptions}
                                          />
                                          <RutaPrivada
                                            exact
                                            path="/Reportes"
                                            component={Reports}
                                          />

                                          {/**Ruta Login */}
                                          <Route
                                            exact
                                            path="/login"
                                            component={Login}
                                          ></Route>
                                          {/**Ruta Recuperar password */}
                                          <Route
                                            exact
                                            path="/password/forgot"
                                            component={PasswordReset}
                                          ></Route>
                                          <Route
                                            exact
                                            path="/password/reset/:token"
                                            component={UpdatePassword}
                                          ></Route>

                                          <Route
                                            exact
                                            path="/Registro"
                                            component={SignInSide}
                                          />

                                          {/**Ruta Pagina No encontrada */}
                                          <Route
                                            exact
                                            path="*"
                                            component={NotFoundPage}
                                          />
                                        </Switch>
                                      </Router>
                                      <ToastContainer
                                        position="top-right"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop
                                        closeOnClick
                                        rtl={false}
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover
                                      />
                                    </ClientsState>
                                  </SubCategoriesState>
                                </CategoryState>
                              </InventoriesState>
                            </CashOutsState>
                          </DeparturesState>
                        </CouponsState>
                      </ExpensesState>
                    </ProductState>
                  </BankAccountsState>
                </InscriptionsState>
              </SchedulesState>
            </CoursesState>
          </ClassroomState>
        </SchoolState>
      </ResetPasswordsState>
    </AuthState>
  );
}

export default App;
