import React, {useState , useEffect , Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MethodGet from '../../../Config/Services';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(() => ({
    content:{
        display:'flex',
        padding:'16px',
        overflow:'hidden',
        flexDirection:'column'
     },
    typography:{
       fontSize:'20px',
       //fontFamily:'Roboto',
       //fontFamily:'Roboto',
       lineHeight:'1.6',
       marginBottom:'20px',
       marginTop:'20px',
       marginLeft:'0px',
       marginRight:'0px',
       color: 'black' 
    }
 }));

const TableRecentPaymentsComissions = () => {
    const classes = useStyles();
    const [ comissions , saveComissions ] = useState([]);

    useEffect(() => {
      const QueryAPI = async () =>{
        let url = `comissionPayments`;
        MethodGet(url).then(response=>{
          saveComissions(response.data.data);
        });
      }    
      QueryAPI();
    }, []);


    return (
        <Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <div className={classes.content}>
                    <h2 className={classes.typography}>
                     <font>Mis Pagos Recientes</font>
                    </h2>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Fecha</TableCell>
                                <TableCell align="center">Monto Pagado</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                              !comissions.length ?
                              <TableRow>
                                <TableCell align="center" colSpan={5}>Aún no has cobrado ninguna comisión </TableCell>
                              </TableRow>
                              :
                              comissions.map(comission=>(
                                <TableRow key={comission.id}>
                                <TableCell component="th" scope="row">
                                  {comission.fechaventa}
                                </TableCell>
                                <TableCell align="center">$ {comission.total}</TableCell>
                                </TableRow>
                              ))
                                

                            }   
                            </TableBody>
                        </Table>
                    </TableContainer>
              

                </div>
                
            </Grid>
        </Fragment>
        
    );
};

export default TableRecentPaymentsComissions;