import React, { useState, useEffect, Fragment } from 'react';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function SelectTypeClient(props) {
	const [error, guardarError] = useState(true);
	const [tipos, saveType] = useState([]);

	const type_Usuarios = [
		{ type_user: '1', name: 'Administrador' },
		{ type_user: '2', name: 'Vendedor' },
		{ type_user: '3', name: 'SubAdministrador' },
		
	];

	useEffect(() => {
		saveType(type_Usuarios);
		if (props.nameTypeUsers) {
			guardarError(false);
		}
	}, [error]);
	const detectarCambiosTypeUser = (e) => {
		if (e.target.value) {
			guardarError(false);
		}
		props.detectarCambiosTypeUser(e);
	};
	return (
		<Fragment>
			<InputLabel fullWidth>Tipo de Usuario*</InputLabel>

			{props.nameTypeUsers ? (
				<Select
					label="Tipo de Usuario"
					fullWidth
					onChange={detectarCambiosTypeUser}
					value={props.nameTypeUsers.type_user}
				>
					<MenuItem disabled>Selecciona el Tipo de Usuario</MenuItem>
					{tipos.map((tipo) => (
						<MenuItem key={tipo.type_user} value={tipo.type_user}>
							{tipo.name}
						</MenuItem>
					))}
				</Select>
			) : (
				<Select label="Tipo de Usuario" fullWidth onChange={detectarCambiosTypeUser}>
					<MenuItem disabled>Selecciona el Tipo de Usuario</MenuItem>
					{tipos.map((tipo) => (
						<MenuItem key={tipo.type_user} value={tipo.type_user}>
							{tipo.name}
						</MenuItem>
					))}
				</Select>
			)}

			{error ? (
				<p style={{ color: 'red' }}>El Tipo de Usuario es un Campo Requerido</p>
			) : null}
		</Fragment>
	);
}
