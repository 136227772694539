import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import ModalPayments from "./ModalPayments";
import { useForm } from "react-hook-form";
import SelectStudent from "../SelectOptions/SelectStudent";
import SelectSchedule from "../SelectOptions/SelectSchedule";
import ModalClient from "./ModalClient";
import ModalIndividual from "./ModalInscriptionIndividual";
import ModalGraduate from "./ModalInscriptionGraduate";



import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from "@material-ui/core";

/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import InscriptionsContext from "../../Reducers/Inscriptions/InscriptionsContext";
import SchedulesContext from "../../Reducers/Schedules/SchedulesContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorIcon from "@material-ui/icons/Error";

import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import Inscriptions from "../../Complements/Image/FormClass/Inscriptions.svg";
import clienteAxios from "../../Config/Axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

export default function InscriptionAdd(props) {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    guardarMetodoPago({
      amount: "",
      change: "0",
      cash_income: "0",
      card_income: "0",
      transfer_income: "0",
    });
    setOpen(false);
    setDisabledButton(false);
  };

  //Leer los datos del Modal
  const obtenerInformacionPago = (e) => {
    guardarMetodoPago({
      ...informacionMetodoPago,
      [e.target.name]: e.target.value,
    });
  };
  const [informacionMetodoPago, guardarMetodoPago] = useState({
    amount: "0",
    change: "0",
    cash_income: "0",
    card_income: "0",
    transfer_income: "0",
    reference: "",
    invoice: "",
  });
  const [totalDescuento, guardarTotalDescuento] = useState(0);

  const [image, setImage] = useState({
    urlPhoto:
      "https://www.sinrumbofijo.com/wp-content/uploads/2016/05/default-placeholder.png",
    image: "",
  });

  const [imageReference, setImageReference] = useState({
    urlPhotoReference:
      "https://www.sinrumbofijo.com/wp-content/uploads/2016/05/default-placeholder.png",
    imageReference: "",
  });

  const [totalPagado, guardarTotalPagado] = useState(0);

  const [data, setData] = useState({});

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const handleChangeImageReference = (e) => {
    setImageReference({
      ...imageReference,
      urlPhotoReference: URL.createObjectURL(e.target.files[0]),
      imageReference: e.target.files[0],
    });
  };
  const [disableButton, setDisabledButton] = useState(false);
  const [couponInfo, saveCouponInfo] = useState("");

  const formInscriptionsContext = useContext(InscriptionsContext);

  const [scheduleID, saveSchedule] = useState("");

  const detectarCambiosSchedule = (value) => {
    saveSchedule(value.key);
  };

  const [bank_account, saveBankAccount] = useState("");

  const detectarCambiosBancos = (e) => {
    saveBankAccount(e.target.value);
  };

  const { disabling, success, AddInscription } = formInscriptionsContext;



  useEffect(() => {
    let url = `schedules/${scheduleID}`;
    MethodGet(url)
      .then((res) => {
        guardarTotalDescuento(res.data.data.course_cost);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [scheduleID]);


  const [school, saveSchool] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name, shop_id } = school;
  //obtenemos la escuela de session storage
  let SchoolStorageID = JSON.parse(sessionStorage.getItem("school"));
  //console.log(SchoolStorageID)

  const [studentID, saveStudent] = useState("");

  const detectarCambiosStudent = (value) => {
    saveStudent(value.key);
  };

  const [gtotal, saveTotal] = useState([]);

  const [error, guardarError] = useState(true);
  useEffect(() => {
    const getotals = async () => {
      await clienteAxios
        .get(`schoolUtility/${SchoolStorageID}`)
        .then((res) => {
          saveTotal(res.data);
        })
        .catch((error) => {
          console.log(error);
        });

      if (props.course) {
        guardarError(false);
      }
    };
    getotals();
  }, []);

  const addPayment = (e) => {
    if (
      totalDescuento < informacionMetodoPago.amount &&
      informacionMetodoPago.cash_income < totalDescuento
    ) {
      toast.warn(
        <div>
          <ErrorIcon /> No puedes pagar mas del monto total
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.amount < 0) {
      toast.warn(
        <div>
          <ErrorIcon /> No puedes ingresar valores negativos
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.amount == 0) {
      toast.warn(
        <div>
          <ErrorIcon /> Debes ingresar al menos un monto de pago
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (bank_account == "") {
      toast.warn(
        <div>
          <ErrorIcon /> Debes seleccionar una cuenta bancaria
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.card_income > 0) {
      if (image.image == "") {
        toast.warn(
          <div>
            <ErrorIcon /> Debes seleccionar la imagen del ticket de pago por
            tarjeta
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      if (informacionMetodoPago.invoice == "") {
        toast.warn(
          <div>
            <ErrorIcon /> Debes introducir un folio válido
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
    } else if (informacionMetodoPago.transfer_income > 0) {
      if (imageReference.imageReference == "") {
        toast.warn(
          <div>
            <ErrorIcon /> Debes seleccionar la imagen del ticket de la
            transferencia
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      if (informacionMetodoPago.reference == "") {
        toast.warn(
          <div>
            <ErrorIcon /> Debes introducir una referencia válida
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
    }
    setDisabledButton(true);
    data.cash_income = informacionMetodoPago.cash_income;
    data.card_income = informacionMetodoPago.card_income;
    data.transfer_income = informacionMetodoPago.transfer_income;
    data.image = image.image;
    data.imageReference = imageReference.imageReference;
    data.change = informacionMetodoPago.change;
    data.reference = informacionMetodoPago.reference;
    data.invoice = informacionMetodoPago.invoice;
    data.client_id = studentID;
    data.schedule_id = scheduleID;
    data.total = totalDescuento;
    data.bank_account = bank_account;
    data.previous_balance = gtotal.last_current_balance;

    if (couponInfo != "") {
      data.coupon_id = couponInfo.id;
    }
    //console.log(data);
    AddInscription(data);
  };

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${SchoolStorageID}`;
    MethodGet(url)
      .then((res) => {
        saveSchool(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (success) {
      //console.log("Retorna");
      props.history.push(`/InscripcionesEscuela`);
    }
    if (disabling) {
      setDisabledButton(false);
    }
    //eslint-disable-next-line
  }, [success, disabling]);
//modal clientes
  const [openModalClient, setOpenModalClient] = React.useState(false);

  const handleClickOpenModalClient = () => {
    setOpenModalClient(true);
  };
  const handleCloseModalClient = () => {
    setOpenModalClient(false);
  };
//modal programaciones
const [openModalIndividual, setOpenModalIndividual] = React.useState(false);

const handleClickOpenModalIndividual = () => {
  setOpenModalIndividual(true);
};
const handleCloseModalIndividual = () => {
  setOpenModalIndividual(false);
};
//modal programacionesgraduaciones
const [openModalGraduate, setOpenModalGraduate] = React.useState(false);

const handleClickOpenModalGraduate = () => {
  setOpenModalGraduate(true);
};
const handleCloseModalGraduate = () => {
  setOpenModalGraduate(false);
};



  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 70 }}
      >
        {/**
         * identificar Codigo de FORMS
         */}
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card>
            <CardHeader color="primary">
              <h2 className={classes.cardTitleWhite}>
                Agregar Nueva Inscripción A {name}
              </h2>
              <p className={classes.cardCategoryWhite}>
                Completa correctamente los datos
              </p>
            </CardHeader>
            <CardBody>
              <Grid container>
                <Grid item xs={12} md={9} style={{ padding: 10 }}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <SelectStudent
                      detectarCambiosStudent={detectarCambiosStudent}
                      SchoolStorageID={SchoolStorageID}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} style={{ padding: 11 }}>
                  <Button
                    fullWidth
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      backgroundColor: "#4b0082",
                      color: "white",
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClickOpenModalClient()}
                  >
                    Alumno Nuevo
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 10 }}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <SelectSchedule
                      detectarCambiosSchedule={detectarCambiosSchedule}
                      SchoolStorageID={SchoolStorageID}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} style={{ padding: 11 }}>
                  <Button
                    fullWidth
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      backgroundColor: "#4b0082",
                      color: "white",
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClickOpenModalIndividual()}
                  >
                    Programacion Individual Nueva
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} style={{ padding: 11 }}>
                  <Button
                    fullWidth
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      backgroundColor: "#4b0082",
                      color: "white",
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClickOpenModalGraduate()}
                  >
                    Programacion de Diplomado Nueva
                  </Button>
                </Grid>
              </Grid>
              <ModalClient
                modal={openModalClient}
                handleClose={handleCloseModalClient}
                id={shop_id}
              />
                 <ModalIndividual
                modal={openModalIndividual}
                handleClose={handleCloseModalIndividual}
                id={shop_id}
              />
                   <ModalGraduate
                modal={openModalGraduate}
                handleClose={handleCloseModalGraduate}
                id={shop_id}
              />

              <ModalPayments
                open={open}
                setOpen={setOpen}
                SchoolStorageID={SchoolStorageID}
                saveCouponInfo={saveCouponInfo}
                couponInfo={couponInfo}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                totalDescuento={totalDescuento}
                informacionMetodoPago={informacionMetodoPago}
                image={image}
                guardarTotalDescuento={guardarTotalDescuento}
                obtenerInformacionPago={obtenerInformacionPago}
                addPayment={addPayment}
                handleChangeImage={handleChangeImage}
                disableButton={disableButton}
                totalPagado={totalPagado}
                detectarCambiosBancos={detectarCambiosBancos}
                imageReference={imageReference}
                handleChangeImageReference={handleChangeImageReference}
              />

              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />

              {scheduleID != "" && (
                <Grid container spacing={3} justify="center">
                  <Grid item spacing={3} xs={12} sm={12} md={12}>
                    <Button
                      type="submit"
                      fullWidth
                      className={classes.submit}
                      variant="contained"
                      color="primary"
                      onClick={handleClickOpen}
                    >
                      Continuar
                    </Button>
                  </Grid>
                </Grid>
              )}
            </CardBody>
          </Card>
          <Card profile>
            <CardBody profile>
              <img
                src={Inscriptions}
                alt="Foto Editar"
                width="35%"
                height="35%"
              />
            </CardBody>
          </Card>
        </GridItem>
        {/**
         * identificar Codigo de FORMS
         */}
        {/**
         * identificar Codigo de FORMS
         */}
      </Grid>
    </LayoutDashboard>
  );
}
