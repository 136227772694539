import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import SelectDate from "../Graphics/SelectDate";
import SelectBranch from "../Graphics/SelectBranch";
import { MethodPost } from "../../Config/Services";
import MethodGet from "../../Config/Services";
import { Grid, Typography, Hidden, Paper } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    //marginLeft: theme.spacing(-1),
    paddingBottom: theme.spacing(1),
    paddingInlineEnd: theme.spacing(1),
    paddingRight: theme.spacing(0),
    marginLeft: "-50px",
  },
  formControl: {
    width: "100%",
    heigth: "100%",
    margin: theme.spacing(1),
    maxHeight: 400,
  },
  papercard: {
    padding: theme.spacing(2),
    margin: "auto",
    maxHeight: "470px",
    backgroundColor: "transparent",
    flexWrap: "nowrap",
    display: "relative",
    boxSizing: "border-box",
  },
  containerGraphic: {
    width: "100%",
    height: "440px",
    boxSizing: "border-box",
    position: "relative",
    display: "absolute",
  },
  GraphicDetails: {
    display: "absolute",
    width: "100%",
    height: "440px",
    maxHeight: "450px",
    position: "absolute",
    boxSizing: "border-box",
  },
}));

function GraphicSales() {
  const classes = useStyles();

  const [dateformat, guardardateformat] = useState("");

  const [nameOffice, guardarSucursal] = useState({
    branch_office_id: "",
  });

  const detectarcambioFecha = (event) => {
    guardardateformat(event.target.value);
  };

  const detectarCambiosOffice = (e) => {
    guardarSucursal({ id_branch_office: e.target.value });
  };

  const data = {
    date_format: dateformat,
    branch_office_id: nameOffice.id_branch_office,
  };
  const options = {
    maintainAspectRatio: false, // Don't maintain w/h ratio
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  const [chartData, setChartData] = useState({});

  const chart = () => {
    let total_Sales = [];
    let Date = [];
    //Query a la api
    let url = "/graphics/sales";
    MethodPost(url, data)
      .then((res) => {
        for (const dataObj of res.data.data) {
          total_Sales.push(parseInt(dataObj.total_sales));
          Date.push(String(dataObj.date));
        }
        //propiedades de la grafica
        setChartData({
          labels: Date,
          datasets: [
            {
              label: "Ventas Totales",
              data: total_Sales,
              backgroundColor: [
                "#aed581",
                "#dce775",
                "#fff176",
                "#ffd54f",
                "#ffb74d",
                "#ff8a65",
                "#f06292",
                "#f44336",
                "#ec407a",
                "#ab47bc",
                "#ff1744",
                "#f50057",
                "#d500f9",
                "#673ab7",
                "#3f51b5",
                "#2196f3",
                "#03a9f4",
                "#00bcd4",
                "#009688",
                "#4caf50",
                "#8bc34a",
                "#cddc39",
                "#ffeb3b",
                "#ffc107",
                "#ff9800",
                "#ff5722",
                "#795548",
                "#9e9e9e",
                "#607d8b",
                "#8e24aa",
                "#ad1457",
              ],
              borderColor: [
                "#aed581",
                "#dce775",
                "#fff176",
                "#ffd54f",
                "#ffb74d",
                "#ff8a65",
                "#f06292",
                "#f44336",
                "#ec407a",
                "#ab47bc",
                "#ff1744",
                "#f50057",
                "#d500f9",
                "#673ab7",
                "#3f51b5",
                "#2196f3",
                "#03a9f4",
                "#00bcd4",
                "#009688",
                "#4caf50",
                "#8bc34a",
                "#cddc39",
                "#ffeb3b",
                "#ffc107",
                "#ff9800",
                "#ff5722",
                "#795548",
                "#9e9e9e",
                "#607d8b",
                "#8e24aa",
                "#ad1457",
              ],
              pointBorderColor: [
                "#aed581",
                "#dce775",
                "#fff176",
                "#ffd54f",
                "#ffb74d",
                "#ff8a65",
                "#f06292",
                "#f44336",
                "#ec407a",
                "#ab47bc",
                "#ff1744",
                "#f50057",
                "#d500f9",
                "#673ab7",
                "#3f51b5",
                "#2196f3",
                "#03a9f4",
                "#00bcd4",
                "#009688",
                "#4caf50",
                "#8bc34a",
                "#cddc39",
                "#ffeb3b",
                "#ffc107",
                "#ff9800",
                "#ff5722",
                "#795548",
                "#9e9e9e",
                "#607d8b",
                "#8e24aa",
                "#ad1457",
              ],
              pointBackgroundColor: [
                "#aed581",
                "#dce775",
                "#fff176",
                "#ffd54f",
                "#ffb74d",
                "#ff8a65",
                "#f06292",
                "#f44336",
                "#ec407a",
                "#ab47bc",
                "#ff1744",
                "#f50057",
                "#d500f9",
                "#673ab7",
                "#3f51b5",
                "#2196f3",
                "#03a9f4",
                "#00bcd4",
                "#009688",
                "#4caf50",
                "#8bc34a",
                "#cddc39",
                "#ffeb3b",
                "#ffc107",
                "#ff9800",
                "#ff5722",
                "#795548",
                "#9e9e9e",
                "#607d8b",
                "#8e24aa",
                "#ad1457",
              ],

              pointHoverBackgroundColor: [
                "#aed581",
                "#dce775",
                "#fff176",
                "#ffd54f",
                "#ffb74d",
                "#ff8a65",
                "#f06292",
                "#f44336",
                "#ec407a",
                "#ab47bc",
                "#ff1744",
                "#f50057",
                "#d500f9",
                "#673ab7",
                "#3f51b5",
                "#2196f3",
                "#03a9f4",
                "#00bcd4",
                "#009688",
                "#4caf50",
                "#8bc34a",
                "#cddc39",
                "#ffeb3b",
                "#ffc107",
                "#ff9800",
                "#ff5722",
                "#795548",
                "#9e9e9e",
                "#607d8b",
                "#8e24aa",
                "#ad1457",
              ],
              pointHoverBorderColor: [
                "#aed581",
                "#dce775",
                "#fff176",
                "#ffd54f",
                "#ffb74d",
                "#ff8a65",
                "#f06292",
                "#f44336",
                "#ec407a",
                "#ab47bc",
                "#ff1744",
                "#f50057",
                "#d500f9",
                "#673ab7",
                "#3f51b5",
                "#2196f3",
                "#03a9f4",
                "#00bcd4",
                "#009688",
                "#4caf50",
                "#8bc34a",
                "#cddc39",
                "#ffeb3b",
                "#ffc107",
                "#ff9800",
                "#ff5722",
                "#795548",
                "#9e9e9e",
                "#607d8b",
                "#8e24aa",
                "#ad1457",
              ],
            },
          ],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    chart();
  }, [dateformat, nameOffice]);

  return (
    <div className={classes.container}>
      {/**Se mandan los datos de la grafica */}
      <Grid
        container
        xs={8}
        sm={10}
        md={12}
        lg={12}
        justify="center"
        style={{ marginBottom: "15px" }}
      >
        <Hidden only={["md", "lg", "xl"]}>
          <Typography component="h4" variant="h4">
            Ventas por Sucursal
          </Typography>
        </Hidden>
        <Hidden only={["xs", "sm"]}>
          <Typography component="h2" variant="h2">
            Ventas por Sucursal
          </Typography>
        </Hidden>
      </Grid>
      <Paper className={classes.papercard}>
        <div className={classes.containerGraphic}>
          <Grid container xs={12} sm={12} md={12} lg={12} spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <SelectDate detectarcambioFecha={detectarcambioFecha} />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <SelectBranch detectarCambiosOffice={detectarCambiosOffice} />
              </FormControl>
            </Grid>
            <Grid style={{ marginLeft: "15%" }}>
              <Bar
                data={chartData}
                width={"1000%"}
                height={"300%"}
                options={options}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
}

export default GraphicSales;
