import React, { useState, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Button,
  TextField,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import MethodGet, { MethodPut } from "../../Config/Services";
import Swal from "sweetalert2";
import Spinner from "../../Complements/Spinner";
import { withRouter } from "react-router-dom";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import GridItem from "../../Components/Grid/GridItem";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardBody from "../../Components/Card/CardBody";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

function ClientEdit(props) {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);

  const { id } = props.match.params;

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  // const [operator, setOperator] = useState({});
  const [clients, guardarClient] = useState({});

  //Trabajar con el state de office
  const [officeId, saveOffice] = useState({
    id_branch_office: "",
  });

  const {
    name,
    number_phone,
    first_lastname,
    second_lastname,
    street,
    number_int,
    number_ext,
    postal_code,
    RFC,
  } = clients;

  //Funciones para almacenar el id de Office
  const detectarCambiosOffice = (e) => {
    saveOffice({ id_branch_office: e.target.value });
  };

  useEffect(() => {
    let url = `/clients/${id}`;

    MethodGet(url)
      .then((res) => {
        guardarClient(res.data.data);
        saveOffice({ id_branch_office: res.data.data.id_branch_office });
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();

    const dat = {
      ...data,
      ...officeId,
    };

    let url = `/clients/${id}`; //cambiar la url completa
    MethodPut(url, dat)
      .then((res) => {
        Swal.fire({
          title: "Cliente Editado Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        props.history.push("/Clientes");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 70 }}
        >
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>
                  Editar Cliente {name}
                </h2>
                <p className={classes.cardCategoryWhite}>
                  Completa correctamente los datos
                </p>
              </CardHeader>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} style={{ paddingTop: 16 }}>
                    <FormControl
                      disabled
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <SelectBranchOffice
                        officeId={officeId}
                        detectarCambiosOffice={detectarCambiosOffice}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="name"
                      label="Nombre(s)"
                      required
                      name="name"
                      autoComplete="name"
                      autoFocus
                      defaultValue={name}
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                        pattern: {
                          value:
                            /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                          message: "Unicamente carácteres alfabéticos",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="first_lastname"
                      label="Apellido Paterno"
                      name="first_lastname"
                      autoComplete="first_lastname"
                      defaultValue={first_lastname}
                      autoFocus
                      error={!!errors.first_lastname}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Apellido Paterno es requerido",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                        pattern: {
                          value:
                            /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                          message: "Unicamente carácteres alfabéticos",
                        },
                      })}
                    />
                    <p>{errors?.first_lastname?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="second_lastname"
                      label="Apellido Materno"
                      name="second_lastname"
                      autoComplete="second_lastname"
                      defaultValue={second_lastname}
                      autoFocus
                      error={!!errors.second_lastname}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Apellido Materno es requerido",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                        pattern: {
                          value:
                            /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                          message: "Unicamente carácteres alfabéticos",
                        },
                      })}
                    />
                    <p>{errors?.second_lastname?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="street"
                      label="Calle"
                      name="street"
                      autoComplete="street"
                      defaultValue={street}
                      autoFocus
                      error={!!errors.street}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre de la Calle es requerida",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.street?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="RFC"
                      label="RFC"
                      name="RFC"
                      autoComplete="RFC"
                      defaultValue={RFC}
                      autoFocus
                      error={!!errors.RFC}
                      inputRef={register({
                        minLength: {
                          value: 10,
                          message: "Minimo 10 caracteres",
                        },
                        maxLength: {
                          value: 13,
                          message: "Maximo 13 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.RFC?.message}</p>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="number_phone"
                      label="Teléfono"
                      name="number_phone"
                      autoComplete="phone"
                      type="number"
                      autoFocus
                      defaultValue={number_phone}
                      error={!!errors.number_phone}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Teléfono es requerido",
                        },
                        minLength: {
                          value: 10,
                          message: "Mínimo 10 digitos",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 digitos",
                        },
                      })}
                    />
                    <p>{errors?.number_phone?.message}</p>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="postal_code"
                      label="Código Postal"
                      name="postal_code"
                      autoComplete="postal_code"
                      type="number"
                      defaultValue={postal_code}
                      autoFocus
                      error={!!errors.postal_code}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El codigo postal es requerido",
                        },
                        minLength: {
                          value: 5,
                          message: "Mínimo 5 digitos",
                        },
                        maxLength: {
                          value: 5,
                          message: "Maximo 5 digitos",
                        },
                      })}
                    />
                    <p>{errors?.postal_code?.message}</p>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="number_ext"
                      label="Numero Exterior"
                      name="number_ext"
                      autoComplete="number_ext"
                      type="number"
                      defaultValue={number_ext}
                      autoFocus
                      error={!!errors.number_ext}
                      inputRef={register({
                        minLength: {
                          value: 1,
                          message: "Mínimo 1 digito",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 digitos",
                        },
                      })}
                    />
                    <p>{errors?.number_ext?.message}</p>
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="number_int"
                      label="Numero Interior"
                      name="number_int"
                      autoComplete="number_int"
                      type="number"
                      defaultValue={number_int}
                      autoFocus
                      error={!!errors.number_int}
                      inputRef={register({
                        minLength: {
                          value: 1,
                          message: "Mínimo 1 digito",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 digitos",
                        },
                      })}
                    />
                    <p>{errors?.number_int?.message}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justify="center">
                  <Grid
                    item
                    xs={6}
                    justify="center"
                    className={classes.ContainerSubmit}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.submit}
                    >
                      ACTUALIZAR
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </Grid>
      </form>
    </LayoutDashboard>
  );
}
export default withRouter(ClientEdit);
