import React, { useReducer, useState } from 'react';
import InscriptionsContext from './InscriptionsContext'
import InscriptionsReducer from './InscriptionsReducer';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../Config/Services";
import { ADD_NEW_INSCRIPTION, CURRENT_INSCRIPTION, DELETE_INSCRIPTION, GET_ALL_INDIVIDUAL_INSCRIPTIONS, ADD_NEW_PAYMENT,
  SHOW_ERRORS_API, UPDATE_INSCRIPTION, GET_INFO_INSCRIPTION, GET_ALL_GRADUATE_INSCRIPTIONS } from "../../types";
import Swal from "sweetalert2";
import headerConfig from "../../Config/imageHeaders";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorIcon from '@material-ui/icons/Error';

const ClassroomState = ({children}) => {
  // estado inicial
  const initialSate = {
    inscriptions: [],
    inscription: null,
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(InscriptionsReducer, initialSate);
  const [disabling, setDisabling] = useState(false);

  //obtener la lista de inscriptciones individuales
  const GetIndividualInscriptions = (id) => {
    sessionStorage.removeItem('inscription');
    let url = `schoolinscriptions/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_ALL_INDIVIDUAL_INSCRIPTIONS,
        payload: response.data.individual,
      });
    });
  };

  //obtener la lista de inscriptciones a diplomados
  const GetGraduateInscriptions = (id) => {
    sessionStorage.removeItem('inscription');
    let url = `schoolinscriptions/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_ALL_GRADUATE_INSCRIPTIONS,
        payload: response.data.graduates,
      });
    });
  };

  const GetInfoInscription = (id) => {
    let url = `inscriptions/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_INFO_INSCRIPTION,
        payload: response.data.data,
      });
    });
  };

  //función para crear un nuevo pago a una inscripcion
  const AddInPayment = (inscription) => {
    const formData = new FormData();
    formData.append("card_income" ,inscription.card_income);
    formData.append("cash_income" ,inscription.cash_income);
    formData.append("transfer_income" ,inscription.transfer_income);
    formData.append("bank_account_id" ,inscription.bank_account);
      if(inscription.card_income > 0)
      {
        formData.append("invoice" ,inscription.invoice);
        formData.append("image", inscription.image);
        formData.append("comission", inscription.comission);
      }
      if(inscription.transfer_income > 0)
      {
        formData.append("reference" ,inscription.reference);
        formData.append("imageReference", inscription.imageReference);
      }
      if(inscription.change > 0)
      {
        formData.append("change" ,inscription.change);
      }
    let url = `addPaymentInscription/${inscription.id}`;
    MethodPost(url, formData, { headerConfig })
      .then((response) => {
        Swal.fire({
          title: "Pago Registrado",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_PAYMENT,
        });
      })
      .catch((error) => {
        toast.error(<div><ErrorIcon/> {error.response.data.error} </div>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //función para crear una nueva inscripción
  const AddInscription = (inscription) => {
    const formData = new FormData();
    setDisabling(false);
		formData.append("client_id" ,inscription.client_id);
    formData.append("schedule_id" ,inscription.schedule_id);
    formData.append("total" ,inscription.total);
    formData.append("card_income" ,inscription.card_income);
    formData.append("cash_income" ,inscription.cash_income);
    formData.append("transfer_income" ,inscription.transfer_income);
    formData.append("bank_account_id" ,inscription.bank_account);
    formData.append("previous_balance" ,inscription.previous_balance);
      if(inscription.card_income > 0)
      {
        formData.append("invoice" ,inscription.invoice);
        formData.append("image", inscription.image);
        formData.append("comission", inscription.comission);
      }
      if(inscription.transfer_income > 0)
      {
        formData.append("reference" ,inscription.reference);
        formData.append("imageReference", inscription.imageReference);
      }
      if(inscription.cash_income > 0 && inscription.cash_income > inscription.total)
      {
        formData.append("change" ,inscription.change);
      }
      if(inscription.coupon_id)
	    {
	      formData.append("coupon_id" ,inscription.coupon_id);
	    }
    let url = "/inscriptions";
    MethodPost(url, formData, { headerConfig })
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_INSCRIPTION,
        });
      })
      .catch((error) => {
        toast.error(<div><ErrorIcon/> {error.response.data.error} </div>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setDisabling(true);
      });
  };

  //función para seleccionar un salon
  const SaveCurrentInscription = (inscription) => {
    sessionStorage.setItem('inscription',JSON.stringify(inscription));
    dispatch({
      type: CURRENT_INSCRIPTION,
      payload: inscription,
    });
  };

  //función para editar un salon
  const UpdateInscription = (inscription) => {
    const formData = new FormData();
    formData.append("schedule_id" ,inscription.schedule_id);
    formData.append("total" ,inscription.total);
		formData.append("_method", "PATCH");

    let url = `inscriptions/${inscription.id}`;
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_INSCRIPTION,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //Eliminar Proveedor por id
  const DeleteInscription = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La inscripción será cancelada",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `inscriptions/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_INSCRIPTION,
            payload: id,
          });
        });
      }
    });
  };

  return (
    <InscriptionsContext.Provider
      value={{
        inscriptions: state.inscriptions,
        inscription: state.inscription,
        ErrorsAPI: state.ErrorsAPI,
        disabling: disabling,
        success: state.success,
        GetIndividualInscriptions,
        GetGraduateInscriptions,
        AddInscription,
        AddInPayment,
        SaveCurrentInscription,
        GetInfoInscription,
        UpdateInscription,
        DeleteInscription,
      }}
    >
      {children}
    </InscriptionsContext.Provider>
  );
}

export default ClassroomState;