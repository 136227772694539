import React, { useState, useEffect,Fragment } from 'react';
import QuantityCourses from './QuantityCourses';
import { Grid, TableContainer, TableRow, TableCell, Table, TableHead, TableBody } from '@material-ui/core';
import MethodGet from '../../Config/Services';
import { makeStyles } from "@material-ui/core/styles";
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
	},
	paper: {
		//padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
		//paddingLeft: theme.spacing(2),
		//paddingRight: theme.spacing(2),
		//marginBlockEnd: theme.spacing(1),
	},
	submit: {
		//margin: theme.spacing(3, 0, 2),
	},
	input: {
		display: 'none',
	},
	typography: {
		//margin: theme.spacing(0, 1, 2, 2),
	},
	// Estilo de tablas Container
	titleTbl:{
		//backgroundColor:'#8115CF',
		background:'linear-gradient(50deg, #3f51b5 33%, indigo 100%)',
		color:'white',
		fontSize:'large',
		height:'60px',
		borderRadius:'5px',
		paddingTop:'10px',
		marginBottom:'20px',
		whiteSpace: 'nowrap',

	},
	btnPlus:{
		display:'absolute',
		marginTop:'10%',
		marginRight:'-5%',
		zIndex:2,
		position: 'relative',
	},
	fabGreen: {
		color: theme.palette.common.white,
		backgroundColor: green[500],
		'&:hover': {
		  backgroundColor: green[600],
		},
	  },
	  typography: {
		margin: theme.spacing(0, 1, 2, 2),
	},
  tblHeaderPurple: {
    backgroundColor: 'indigo',
    color: 'white',
    fontSize:'16px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
		  backgroundColor: '#4b0082c7',
      color:'white'
		},
},


}));
const CoursesList = (props) => {
    const {courses,
           saveCourses,} = props;

    const classes = useStyles();
    

  //Eliminar un curso del state
  const eliminarCurso = (id) => {
    const allCourses = courses.filter((course) => course.course_id !== id);
    saveCourses(allCourses);
  };
  //console.log(courses,'xd')

    return (
        <Grid container spacing={3} >
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.TableRow}>
                                <TableCell className={classes.tblHeaderPurple}  align="center">Nombre</TableCell>
                                <TableCell className={classes.tblHeaderPurple} align="center">Descripción</TableCell>
                                <TableCell className={classes.tblHeaderPurple} align="center">Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                !courses.length ? 
                                <TableRow>
                                <TableCell align="center" colSpan={6}>
                                  Aún no has agregado cursos
                                </TableCell>
                                 </TableRow>
                                 :
                                 courses.map((course , index)=>(
                                    <QuantityCourses
                                    key={course.course}
                                    course={course}
                                    eliminarCurso={eliminarCurso}
                                    index={index}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>

    );
}

export default CoursesList;