import React, { useEffect, useState, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import AuthContext from "../../Context/autenticacion/authContext";
import ComponentsBarcode from "./ComponentsBarcodeEdit";
import ProductInfo from "../Sales/ProductInfo";
import ProductsList from "../Sales/ProductsList";
import ModalPaymentMethod from "./ModalPaymentMethod";
import Button from "@material-ui/core/Button";
import MethodGet from "../../Config/Services";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import headerConfig from "../../Config/imageHeaders";
import { Grid, Box, Hidden, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "../../Components/Card/CardHeader";
import CardIcon from "../../Components/Card/CardIcon";
//import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../Components/Card/CardFooter";
/***Importo los ICONOS de la barra titulo */
import LoyaltyIcon from "@material-ui/icons/Loyalty";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
import { MethodPost } from "../../Config/Services";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    marginTop: theme.spacing(11),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  paperView: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  TableRow: {
    background: "Orange",
  },
  h2: {
    background: "#394ED1",
    color: "white",
  },
}));

function SalesAddProduct(props) {
  const classes = useStyles();
  const [productoID, guardarProductoID] = useState("");
  const [clienteID, guardarClienteID] = useState("");
  const [new_client_id, saveClientID] = useState("");
  const [subtotal, guardarTotal] = useState(0);
  const [subtotalBrand, SaveSubTotalBrand] = useState(0);
  const [subtotalWithoutBrand, SaveSubTotalWithoutBrand] = useState(0);
  const [subtotalSPA, saveSubTotalSPA] = useState(0);
  const [totalDescuento, guardarTotalDescuento] = useState(0);
  const [totalDiscountBrand, saveTotalDiscountBrand] = useState(0);
  const [totalDiscountWithoutBrand, saveTotalDiscountWithoutBrand] = useState(0);
  const [totalDiscountSPA, saveTotalDiscountSPA] = useState(0);
  const [disableButton, setDisabledButton] = useState(false);
  const [ comission , saveComission ] = useState(0);

  const [ cliente , saveCliente ] = useState('');

  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  const [imageTransfer, setImageTransfer] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  const [open, setOpen] = useState(false);
  const [descuentoporcentaje, guardarDescuento] = useState({});
  const [discountWithoutBrand, saveDiscountWithoutBrand] = useState({});
  const [discountSPA, saveDiscountSPA] = useState({});

  //TIPOS DE PRODUCTOS
  const [productsBranded, saveProductsBranded] = useState([]);
  const [productsWithoutBrand, saveProductsWithoutBrand] = useState([]);
  const [productsSPA, saveProductsSPA] = useState([]);

  useEffect(() => {
    let totalSubTotal = Number(subtotalBrand) + Number(subtotalWithoutBrand) + Number(subtotalSPA);
    guardarTotal(totalSubTotal);
  }, [subtotalBrand, subtotalWithoutBrand, subtotalSPA])
  
  
  const [informacionMetodoPago, guardarMetodoPago] = useState({
    amount: "0",
    change: "0",
    cash_income: "0",
    card_income: "0",
    transfer_income: "0",
  });
  const sale_id = props.match.params.id;
  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [box, setBox] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    guardarMetodoPago({
      amount: "",
      change: "0",
      cash_income: "0",
      card_income: "0",
      transfer_income: "0",
    });
    setOpen(false);
  };

  const [branch, guardarBranch] = useState({});
  const [couponInfo, saveCouponInfo] = useState("");
  const [id, setId] = useState("");

  const [type_sale, saveTypeSale] = useState("");

  const detectarCambiosTypeSale = (e) => {
		saveTypeSale( e.target.value );
	};

  useEffect(() => {
    let url = `/details/${sale_id}`;
    MethodGet(url)
      .then((res) => {
        setId(res.data.details.branch_office_id);
        saveProductsBranded(res.data.productsBranded)
        saveProductsWithoutBrand(res.data.productsWithoutBrand)
        saveDiscountSPA(res.data.productsSPA)
        guardarTotal(res.data.subtotal)
        guardarTotalDescuento(res.data.total)
        saveClientID(res.data.details.client_id);
        guardarMetodoPago({
          change: res.data.change,
          cash_income: res.data.paymentsCash.amount,
          card_income: res.data.paymentsCard.amount,
          transfer_income: res.data.paymentsTransfer.amount,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //Extraer valores de branch(destructuracion)
  const { name } = branch;

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    if (clienteID) {
      let url = `/discounts/${clienteID.value}/${subtotal}`;
        MethodGet(url).then((response) => {
          guardarDescuento(response.data.discount);
          saveCliente(response.data.client);
        });
        let urlWithoutBrand = `/discountsWithoutBrand/${clienteID.value}/${subtotal}`;
        MethodGet(urlWithoutBrand).then((response) => {
          saveDiscountWithoutBrand(response.data.data);
        });
        let urlSPA = `/discountsSPA/${clienteID.value}/${subtotal}`;
        MethodGet(urlSPA).then((response) => {
          saveDiscountSPA(response.data.data);
        });
    }
  }, [subtotal, clienteID, type_sale, new_client_id]);

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
    //console.log(image,'tarjeta')
  };

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImageTransfer = (e) => {
    setImageTransfer({
      ...imageTransfer,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
    //console.log(imageTransfer,'transferencia')
  };

  //Leer los datos del Modal
  const obtenerInformacionPago = (e) => {
    guardarMetodoPago({
      ...informacionMetodoPago,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    let totalSale = Number(totalDiscountBrand) + Number(totalDiscountWithoutBrand) + Number(totalDiscountSPA);
    let descuentoPorcentajeNew = 0;
    if(cliente){
      if(descuentoporcentaje.money_discount_min < totalSale){
        descuentoPorcentajeNew = descuentoporcentaje.discount;
      }
      const descuento = ((descuentoPorcentajeNew * totalSale) / 100);
      const TotalDescuento = totalSale - descuento;
      guardarTotalDescuento(Math.round(TotalDescuento));
    }
  }, [totalDiscountBrand, totalDiscountWithoutBrand, totalDiscountSPA, descuentoporcentaje, cliente]);

  //console.log('informacionMetodoPago',informacionMetodoPago.change);
  const generarVenta = (e) => {
    e.preventDefault();
    if (
      totalDescuento < informacionMetodoPago.amount &&
      informacionMetodoPago.cash_income < totalDescuento
    ) {
      return;
    } else if (totalDescuento > informacionMetodoPago.amount) {
      return;
    } else if (informacionMetodoPago.amount < 0) {
      return;
    }
    setDisabledButton(true);

    var products_list_branded = JSON.stringify(productsBranded);
    var products_list_withoutBrand = JSON.stringify(productsWithoutBrand);
    var products_list_SPA = JSON.stringify(productsSPA);

    const formData = new FormData();
    formData.append("client_id", clienteID.value);
    formData.append("user_id", usuario.id);
    
    formData.append("total", totalDescuento);
    formData.append("totalDiscountBrand", totalDiscountBrand);
    formData.append("totalDiscountWithoutBrand", totalDiscountWithoutBrand);
    formData.append("totalDiscountSPA", totalDiscountSPA);

    formData.append("subtotal", subtotal);
    formData.append("subtotalBrand", subtotalBrand);
    formData.append("subtotalWithoutBrand", subtotalWithoutBrand);
    formData.append("subtotalSPA", subtotalSPA);

    formData.append("products_list_branded", products_list_branded);
    formData.append("products_list_withoutBrand", products_list_withoutBrand);
    formData.append("products_list_SPA", products_list_SPA);
    formData.append("card_income", informacionMetodoPago.card_income);
    formData.append("cash_income", informacionMetodoPago.cash_income);
    formData.append("transfer_income", informacionMetodoPago.transfer_income);
    if (couponInfo != "") {
      formData.append("coupon_id", couponInfo.id);
    }
    if (informacionMetodoPago.card_income > 0) {
      formData.append("image", image.image);
      formData.append("comission", comission);
    }
    if (informacionMetodoPago.transfer_income > 0) {
      formData.append("reference", informacionMetodoPago.reference);
      formData.append("imageTransfer", imageTransfer.image);
    }
    if (
      informacionMetodoPago.cash_income > 0 &&
      informacionMetodoPago.cash_income > totalDescuento
    ) {
      formData.append("change", informacionMetodoPago.change);
    }
    
    if (totalDiscountBrand != subtotalBrand) {
      formData.append("discount", descuentoporcentaje.discount);
    }
    if (totalDiscountWithoutBrand != subtotalWithoutBrand) {
      formData.append("discountWithoutBrand", discountWithoutBrand.discount);
    }
    if (totalDiscountSPA != subtotalSPA) {
      formData.append("discountSPA", discountSPA.discount);
    }
		//formData.append("_method", "PATCH");

    let url = `/sales/update/${sale_id}`;
    MethodPost(url, formData, { headerConfig })
      .then(() => {
        Swal.fire({
          title: "Buen trabajo",
          text: "Venta registrada exitosamente",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        props.history.push("/Ventas");
      })
      .catch((error) => {
        if (error.response.data.error) {
          setOpen(false);
          setDisabledButton(false);
          Swal.fire({
            title: "Error",
            text: error.response.data.error,
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Hubo un error. Si el problema persiste contacta al administrador",
            icon: "error",
          });
        }
      });
  };
  //console.log(subtotal);
  return (
    <LayoutDashboard>
      <div className={classes.container}>
        <Grid container xs={12} sm={12} md={12}>
          <Grid xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" stats icon>
                <Grid container md={12} spacing={2}>
                  <Grid item md={2}>
                    <CardIcon color="info" style={{paddingTop:25}}>
                      <LoyaltyIcon />
                    </CardIcon>
                  </Grid>
                  <Grid
                    md={10}
                    container
                    item
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >

                    <Hidden only={["md", "lg", "xl"]}>
                      <Typography
                        component="h3"
                        variant="h3"
                        style={{ color: "#2f2f2f" }}
                      >
                        Edición De Venta Sucursal {name}
                      </Typography>
                    </Hidden>
                    <Hidden only={["xs", "sm"]}>
                      <Typography
                        component="h2"
                        variant="h2"
                        style={{ color: "#2f2f2f" }}
                      >
                        Edición De Venta Sucursal {name}
                      </Typography>
                    </Hidden>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardFooter stats>
              <Paper elevation={0}>
          <Card className={classes.rootCard}>
            <CardContent>
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                justify="center"
              ></Grid>
              <Grid container spacing={2} className={classes.paperView}>
                <Hidden only={["md", "lg", "xl"]}>
                  <Grid container xs={12} xl={12} justify="center">
                    <Box>
                      <Typography component="subtitle1" variant="h4">
                      Edición De Sucursal {name}
                      </Typography>
                    </Box>
                  </Grid>
                </Hidden>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <ComponentsBarcode
                      guardarProductoID={guardarProductoID}
                      clienteID={clienteID.value}
                      guardarClienteID={guardarClienteID}
                      new_client_id={new_client_id}
                      id={id}
                      setBox={setBox}
                      saveProductsWithoutBrand={saveProductsWithoutBrand}
                      saveProductsBranded={saveProductsBranded}
                      saveProductsSPA={saveProductsSPA}
                      productsBranded={productsBranded}
                      productsWithoutBrand={productsWithoutBrand}
                      productsSPA={productsSPA}
                      box={box}
                      type_sale={type_sale}
                      detectarCambiosTypeSale={detectarCambiosTypeSale}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <ProductInfo
                      productoID={productoID.value}
                      clienteID={clienteID.value}
                      saveProductsWithoutBrand={saveProductsWithoutBrand}
                      saveProductsBranded={saveProductsBranded}
                      saveProductsSPA={saveProductsSPA}
                      productsWithoutBrand={productsWithoutBrand}
                      productsSPA={productsSPA}
                      productsBranded={productsBranded}
                      guardarProductoID={guardarProductoID}
                      box={box}
                    />
                  </Grid>
                  <hr />
                  <Grid item xs={12}>
                    <ProductsList
                      saveProductsWithoutBrand={saveProductsWithoutBrand}
                      saveProductsBranded={saveProductsBranded}
                      saveProductsSPA={saveProductsSPA}
                      productsBranded={productsBranded}
                      productsWithoutBrand={productsWithoutBrand}
                      productsSPA={productsSPA}
                      id={id}
                      subtotalBrand={subtotalBrand}
                      SaveSubTotalBrand={SaveSubTotalBrand}
                      subtotalWithoutBrand={subtotalWithoutBrand}
                      subtotalSPA={subtotalSPA}
                      saveSubTotalSPA={saveSubTotalSPA}
                      SaveSubTotalWithoutBrand={SaveSubTotalWithoutBrand}
                      totalDescuento={totalDescuento}
                      descuentoporcentaje={descuentoporcentaje}
                      guardarTotalDescuento={guardarTotalDescuento}
                      discountWithoutBrand={discountWithoutBrand}
                      discountSPA={discountSPA}
                      totalDiscountBrand={totalDiscountBrand}
                      saveTotalDiscountBrand={saveTotalDiscountBrand}
                      totalDiscountWithoutBrand={totalDiscountWithoutBrand}
                      saveTotalDiscountWithoutBrand={saveTotalDiscountWithoutBrand}
                      totalDiscountSPA={totalDiscountSPA}
                      saveTotalDiscountSPA={saveTotalDiscountSPA}
                      subtotal={subtotal}
                      cliente={cliente}
                    />
                  </Grid>
                  <ModalPaymentMethod
                    open={open}
                    branch={branch}
                    saveCouponInfo={saveCouponInfo}
                    couponInfo={couponInfo}
                    setOpen={setOpen}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    totalDescuento={totalDescuento}
                    subtotal={subtotal}
                    guardarTotalDescuento={guardarTotalDescuento}
                    informacionMetodoPago={informacionMetodoPago}
                    image={image}
                    imageTransfer={imageTransfer}
                    comission={comission}
                    saveComission={saveComission}
                    clienteID={clienteID.value}
                    obtenerInformacionPago={obtenerInformacionPago}
                    generarVenta={generarVenta}
                    handleChangeImage={handleChangeImage}
                    handleChangeImageTransfer={handleChangeImageTransfer}
                    disableButton={disableButton}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Grid container spacing={3} justify="center">
              <Grid item spacing={3} xs={12} sm={12} md={6} justify="center">
                <Grid item xs={12}justify="center">
                  {subtotal > 0 ? (
                    <Button
                      type="submit"
                      fullWidth
                      className={classes.submit}
                      variant="contained"
                      color="primary"
                      onClick={handleClickOpen}
                      style={{ marginBottom: "2%" }}
                    >
                      Continuar
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Paper>
              </CardFooter>
            </Card>
          </Grid>
        </Grid>


      </div>
    </LayoutDashboard>
  );
}

export default SalesAddProduct;
