import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  Fab,
  Hidden,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";

import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import GroupIcon from "@material-ui/icons/Group";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import SchedulesContext from "../../Reducers/Schedules/SchedulesContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(18),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    //margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
}));

const TableGraduatesCourses = (props) => {
  const classes = useStyles();
  const [error, guardarError] = useState(false);

  const { name, SchoolStorageID } = props;

  const StateSchedulesContext = useContext(SchedulesContext);
  const {
    SaveCurrentSchedule,
    DeleteSchedule,
    schedules,
    GetSchedulesGraduates,
    success,
  } = StateSchedulesContext;

  //obtener los proveedores carga el componente
  useEffect(() => {
    GetSchedulesGraduates(SchoolStorageID);
  }, [success]);

  const generarReporte = (id, course, init, end) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Generar El Reporte Del Curso?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Generar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El reporte se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        //Cunsume API de Back-end en laravel para generar tiket de venta
        clienteAxios
          .get(`/schedule-course-report/${id}`, { responseType: "blob" })
          .then((response) => {
            fileDownload(
              response.data,
              "Reporte Curso " + course + " " + init + " - " + end + ".pdf"
            );
            guardarError(false);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              Swal.fire({
                icon: "error",
                title: "Algo sucedio",
                text: "No se cuenta con suficiente información para generar el reporte",
              });
            } else if (error.response.status === 422) {
              guardarError(true);
            }
          });
      }
    });
  };

  const SelectSchedule = (schedule) => {
    SaveCurrentSchedule(schedule);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            component="h5"
            variant="h5"
            className={classes.typography}
          >
            {"Diplomados Programados De La Escuela " + name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            href="/AgregarProgramacion/Diplomado"
          >
            <Tooltip
              title="Agregar Programacion"
              aria-label="Agregar Programacion"
            >
              <AddIcon size="large" />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title={""}
            columns={[
              { title: "Diplomado", field: "course_name" },
              { title: "Fecha Inicio", field: "date_init" },
              { title: "Fecha Final", field: "date_ending" },
              {
                title: "Capacidad Actual",
                field: "actual_capacity",
                render: (rowData) =>
                  rowData.capacity != 0 ? (
                    <p>
                      {rowData.actual_capacity}/{rowData.capacity} Personas{" "}
                    </p>
                  ) : (
                    <p>{rowData.actual_capacity}/Sin Límite </p>
                  ),
              },
              {
                title: "Estatus",
                field: "status",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.status == 2 ? (
                        <p
                          style={{
                            color: "white",
                            background: "red",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Cupo Agotado
                        </p>
                      ) : rowData.status == 3 ? (
                        <p
                          style={{
                            color: "black",
                            background: "yellow",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Diplomado En Progreso
                        </p>
                      ) : rowData.status == 4 ? (
                        <p
                          style={{
                            color: "white",
                            background: "red",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Diplomado Finalizado
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "white",
                            background: "#43a047",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Cupo Disponible
                        </p>
                      )}
                    </Fragment>
                  );
                },
              },
            ]}
            data={schedules}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "delete",
                tooltip: "Eliminar Programacion",
                onClick: () => DeleteSchedule(rowData.id),
              }),
            ]}
            components={{
              Action: (props) => (
                <Grid style={{ width: "140px" }}>
                  <Grid
                    container
                    xs={12}
                    xl={12}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item xs={6} xl={6}>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        xl={6}
                        style={{ marginBottom: "5px" }}
                      >
                        <Link
                          to={`/EditarProgramacion/Individual`}
                          style={{ color: "#3f51b5" }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              textTransform: "none",
                              background: "white",
                            }}
                            onClick={() => SelectSchedule(props.data.id)}
                            size="small"
                          >
                            <Tooltip
                              title="Editar Programacion"
                              aria-label="Editar Programacion"
                            >
                              <EditIcon style={{ color: "blue" }} />
                            </Tooltip>
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item xs={6} md={6} xl={6}>
                        <Button
                          variant="contained"
                          style={{ textTransform: "none", background: "white" }}
                          size="small"
                          onClick={() =>
                            generarReporte(
                              props.data.id,
                              props.data.course_name,
                              props.data.date_init,
                              props.data.date_ending
                            )
                          }
                        >
                          <Tooltip
                            title="Generar ticket de pagos"
                            aria-label="Generar ticket de pagos"
                          >
                            <PictureAsPdf style={{ color: "indigo" }} />
                          </Tooltip>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} xl={6} spacing={4}>
                      <Grid
                        item
                        xs={6}
                        md={6}
                        xl={6}
                        style={{ marginBottom: "5px" }}
                      >
                        <Button
                          variant="contained"
                          style={{ textTransform: "none", background: "white" }}
                          size="small"
                          onClick={() => DeleteSchedule(props.data.id)}
                        >
                          <Tooltip
                            title="Eliminar Calendario"
                            aria-label="Editar Calendario"
                          >
                            <DeleteIcon style={{ color: "red" }} />
                          </Tooltip>
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6} xl={6}>
                        <Link
                          to={`/DetalleCursoIndividual/` + props.data.id}
                          style={{ color: "#3f51b5" }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              textTransform: "none",
                              background: "white",
                            }}
                            //onClick={ () => SelectSchedule(props.data.id)}
                            size="small"
                          >
                            <Tooltip
                              title="Ver Alumnos Inscritos"
                              aria-label="Ver Alumnos Inscritos"
                            >
                              <GroupIcon style={{ color: "purple" }} />
                            </Tooltip>
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item xs={6} xl={6} spacing={4}>
                        <Grid item xs={6} md={6} xl={6}>
                          <Link
                            to={`/ProgramacionDiplomado`}
                            style={{ color: "#3f51b5" }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                              }}
                              onClick={() => SelectSchedule(props.data.id)}
                              size="small"
                            >
                              <Tooltip
                                title="Ver Programación De Clases"
                                aria-label="Ver Programación De Clases"
                              >
                                <CalendarTodayIcon style={{ color: "blue" }} />
                              </Tooltip>
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage:
                  "No Hay Programaciones de Cursos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Diplomado", field: "course_name" },
              {
                title: "Estatus",
                field: "status",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.status == 2 ? (
                        <p
                          style={{
                            color: "white",
                            background: "red",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Cupo Agotado
                        </p>
                      ) : rowData.status == 3 ? (
                        <p
                          style={{
                            color: "black",
                            background: "yellow",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Curso En Progreso
                        </p>
                      ) : rowData.status == 4 ? (
                        <p
                          style={{
                            color: "white",
                            background: "red",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Curso Finalizado
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "white",
                            background: "#43a047",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Cupo Disponible
                        </p>
                      )}
                    </Fragment>
                  );
                },
              },
            ]}
            data={schedules}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Fecha Inicio: {rowData.date_init}</p>
                      <p>Fecha Final: {rowData.date_ending}</p>
                      <p>
                        Capacidad Actual:{" "}
                        {rowData.capacity != 0 ? (
                          <>
                            {rowData.actual_capacity}/{rowData.capacity}{" "}
                            Personas{" "}
                          </>
                        ) : (
                          <>{rowData.actual_capacity}/Sin Límite </>
                        )}
                      </p>
                      <p>Opciones:</p>
                      <p>
                        {
                          <>
                            <Link to={`/EditarProgramacion/Individual`}>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                }}
                                onClick={() => SelectSchedule(rowData.id)}
                                size="small"
                              >
                                <Tooltip
                                  title="Editar Programacion"
                                  aria-label="Editar Programacion"
                                >
                                  <EditIcon style={{ color: "blue" }} />
                                </Tooltip>
                              </IconButton>
                            </Link>

                            <IconButton
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 20,
                              }}
                              size="small"
                              onClick={() =>
                                generarReporte(
                                  rowData.id,
                                  rowData.course_name,
                                  rowData.date_init,
                                  rowData.date_ending
                                )
                              }
                            >
                              <Tooltip
                                title="Generar ticket de pagos"
                                aria-label="Generar ticket de pagos"
                              >
                                <PictureAsPdf style={{ color: "indigo" }} />
                              </Tooltip>
                            </IconButton>

                            <IconButton
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 20,
                              }}
                              size="small"
                              onClick={() => DeleteSchedule(rowData.id)}
                            >
                              <Tooltip
                                title="Eliminar Calendario"
                                aria-label="Editar Calendario"
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </Tooltip>
                            </IconButton>

                            <Link to={`/DetalleCursoIndividual/` + rowData.id}>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 20,
                                }}
                                //onClick={ () => SelectSchedule(props.data.id)}
                                size="small"
                              >
                                <Tooltip
                                  title="Ver Alumnos Inscritos"
                                  aria-label="Ver Alumnos Inscritos"
                                >
                                  <GroupIcon style={{ color: "purple" }} />
                                </Tooltip>
                              </IconButton>
                            </Link>

                            <Link to={`/ProgramacionDiplomado`}>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 20,
                                }}
                                onClick={() => SelectSchedule(rowData.id)}
                                size="small"
                              >
                                <Tooltip
                                  title="Ver Programación De Clases"
                                  aria-label="Ver Programación De Clases"
                                >
                                  <CalendarTodayIcon
                                    style={{ color: "blue" }}
                                  />
                                </Tooltip>
                              </IconButton>
                            </Link>
                          </>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage:
                  "No Hay Programaciones de Cursos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </>
  );
};

export default TableGraduatesCourses;
