import React, { useState, useEffect, Fragment } from 'react';
import clienteAxios from '../../Config/Axios';
import { Grid } from '@material-ui/core';
import Select from 'react-select';

const SelectProducts = (props) => {
  const [products, saveProducts] = useState([]);
  useEffect(() => {
    
    clienteAxios
    .get(`/productsBranch/${props.id_branch}`)
    .then((res) => {
      saveProducts(res.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
    
    
  }, [props.id_branch]);

  const productArray = products.map(product => {
    return { key: product.id, value: product.id, label: product.tag + ' - ' + product.name }
  });
  const detectarCambiosProd = (value) => {

      props.detectarCambiosProd(value);

  }
  return (

    <Fragment>
      <Grid item sm={12} md={12} xs={12}>
        <Select
          autofocus
          placeholder="Selecciona un producto"
          options={productArray}
          onChange={(value) => detectarCambiosProd(value)}
          />
      </Grid>
    </Fragment>
  )
};

export default SelectProducts
