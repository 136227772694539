import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import AddIcon from "@material-ui/icons/Add";
import {
  Fab,
  Typography,
  Grid,
  Button,
  Tooltip,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CategoriesContext from "../../Reducers/Categories/CategoryContext";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import Spinner from "../../Complements/Spinner";
import CategoriesAdd from './AddAdmin';
import CategoriesEdit from "./Edit";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  // Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
  container: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  // Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    //margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container

  // Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #0288d1 33%, #26c6da 100%)",
    color: "white",
    fontSize: "large",
    height: "80px",
    borderRadius: "15px",
    paddingTop: "5px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "40px",
    zIndex: 2,
    position: "relative",
    marginRight: -20,
  },
  btnReverse: {
    display: "absolute",
    marginTop: "40px",
    zIndex: 2,
    position: "relative",
    marginLeft: -20,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  fabReverse: {
    color: "white",
    backgroundColor: "#dc6daf",
    "&:hover": {
      backgroundColor: "#bd5d96",
    },
  },
  // Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
  IconArrowBack: {
    paddingTop: "11px",
  },
}));

const Categories = (props) => {
  const classes = useStyles();

  const [cargando, spinnerCargando] = useState(false);

  const CategoriesList = useContext(CategoriesContext);
  const { categories, GetCategories, success } = CategoriesList;

  const StateCategoriesContext = useContext(CategoriesContext);
  const { date, SaveCurrentCategory, DeleteCategory } = StateCategoriesContext;

  const SelectCategories = (category) => {
    SaveCurrentCategory(category);
  };

  const [categoryId, saveCategoryId] = useState(null);

  //obtener los proveedores carga el componente
  useEffect(() => {
    GetCategories();
    spinnerCargando(true);
  }, [success]);

  const [openModalAdd, setOpenModalAdd] = React.useState(false);

  const handleClickOpenModalAdd = () => {
    setOpenModalAdd(true);
  };
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  const [openModalEdit, setOpenModalEdit] = React.useState(false);

  const handleClickOpenModalEdit = () => {
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
    saveCategoryId(null);
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            marginTop: 100,
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography component="h1" variant="h4">
            Categorias
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Registros de todas las categorias
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            onClick={() => handleClickOpenModalAdd()}
          >
            <Tooltip title="Agregar Categoria" aria-label="Agregar Categoria">
              <AddIcon />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>

      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Clave", field: "id" },
              {
                title: "Categoria",
                field: "name",
                render: (rowData) => <p>{rowData.name}</p>,
              },
            ]}
            data={categories}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "delete",
                tooltip: "Eliminar Categoria",
                onClick: () => DeleteCategory(rowData.id),
              }),
            ]}
            components={{
              Action: (props) => (
                <Fragment>

                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      background: "white",
                    }}
                    onClick={() => {
                      saveCategoryId(props.data.id);
                      handleClickOpenModalEdit();
                    }
                    }
                    size="small"
                  >
                    <Tooltip
                      title="Editar Categoria"
                      aria-label="Editar Categoria"
                    >
                      <EditIcon style={{ color: "blue" }} />
                    </Tooltip>
                  </Button>
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", background: "white" }}
                    size="small"
                    onClick={() => DeleteCategory(props.data.id)}
                  >
                    <Tooltip
                      title="Eliminar Categoria"
                      aria-label="Eliminar Categoria"
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Tooltip>
                  </Button>
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Categorias que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>

      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Clave", field: "id" },
              {
                title: "Categoria",
                field: "name",
                render: (rowData) => <p>{rowData.name}</p>,
              },
            ]}
            data={categories}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Ver más",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Opciones: </p>
                      <p>
                        <Fragment>
                          <Button
                            variant="contained"
                            style={{
                              textTransform: "none",
                              background: "white",
                            }}
                            onClick={() => {
                              saveCategoryId(rowData.id);
                              handleClickOpenModalEdit();
                            }
                            }
                            size="small"
                          >
                            <Tooltip
                              title="Editar Categoria"
                              aria-label="Editar Categoria"
                            >
                              <EditIcon style={{ color: "blue" }} />
                            </Tooltip>
                          </Button>
                          <Button
                            variant="contained"
                            style={{ textTransform: "none", background: "white" }}
                            size="small"
                            onClick={() => DeleteCategory(rowData.id)}
                          >
                            <Tooltip
                              title="Eliminar Categoria"
                              aria-label="Eliminar Categoria"
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </Tooltip>
                          </Button>
                        </Fragment>
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No Hay categorias que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>

      <CategoriesAdd
        modal={openModalAdd}
        handleClose={handleCloseModalAdd}
        setOpenModalAdd={setOpenModalAdd}
      />
      {categoryId !== null && (
        <CategoriesEdit
          modal={openModalEdit}
          handleClose={handleCloseModalEdit}
          setOpenModalEdit={setOpenModalEdit}
          id={categoryId}
        />
      )}
    </LayoutDashboard>
  );
};

export default Categories;
