import React, { useEffect, useState, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  Hidden,
} from "@material-ui/core";

import SelectTypeExpense from "../SelectOptions/SelectTypeExpense";
import SelectUser from "../SelectOptions/SelectUser";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import ComissionsSellers from "./ComissionsSellers";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**Importar metodo Post */
import MethodGet, { MethodPost } from "../../Config/Services";
import AuthContext from "../../Context/autenticacion/authContext";
import headerConfig from "../../Config/imageHeaders";
import { useForm } from "react-hook-form";
/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
/**
 * identificar Codigo de FORMS
 */
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import clienteAxios from '../../Config/Axios';                          

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
  },
  typography: {
    paddingTop: theme.spacing(4),
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#5902CF",
    },
    color: "white",
  },
  ContainerSubmit: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

function AddAdminExpenses(props) {
  const classes = useStyles();
  const id = props.id;

  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [nameUser, guardarUsuario] = useState({
    seller_id: "",
  });

  const [nameTypeExpenses, guardarTypeExpense] = useState({
    type: "",
  });

  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const detectarCambiosUser = (e) => {
    guardarUsuario({ seller_id: e.target.value });
  };

  const detectarCambiosTypeExpenses = (e) => {
    guardarTypeExpense({ type: e.target.value });
  };

  const [branch, guardarBranch] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name, direction, number_phone, state_id, municipality_id } = branch;


  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  // sd
  const [gtotal, saveTotal] = useState([]);

  const [error, guardarError] = useState(true);
  useEffect(() => {
    const getotals = async () => {
      await clienteAxios
        .get(`branchUtility/${id}`)
        .then((res) => {
          saveTotal(res.data);
        })
        .catch((error) => {
          console.log(error);
        });

      if (props.course) {
        guardarError(false);
      }
    };
    getotals();
  }, []);




  const onSubmit = (data, e) => {
    e.preventDefault();

    if (nameTypeExpenses.type !== "") {
      //crear un formdata
      const formData = new FormData();
      if (nameTypeExpenses.type == 1) {
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("total", data.total);
        formData.append("image", image.image);
        formData.append("branch_office_id", id);
        formData.append("user_id", usuario.id);
        formData.append("type", nameTypeExpenses.type);
        formData.append("previous_balance", gtotal.last_current_balance);

      } else {
        formData.append("user_id", usuario.id);
        formData.append("type", nameTypeExpenses.type);
        formData.append("total", data.total);
        formData.append("seller_id", nameUser.seller_id);
        formData.append("branch_office_id", id);
        formData.append("previous_balance", gtotal.last_current_balance);

      }
      //console.log(estadoId)

      let url = "/expenses";
      MethodPost(url, formData, { headerConfig })
        .then((res) => {
          Swal.fire({
            title: "Gasto Registrado Exitosamente",
            text: res.data.message,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          props.history.push("/SucursalGastos/" + id);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.error,
            icon: "error",
          });
        });
    } else {
      Swal.fire({
        title: "Error",
        text: "Todos los campos son Obligatorios",
        icon: "error",
      });
    }
  };


  return (
    <LayoutDashboard>
      <div onSubmit={handleSubmit(onSubmit)}>
        <form noValidate>
          {/**
           * identificar Codigo de FORMS
           */}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 100 }}
          >
            {/**
             * identificar Codigo de FORMS
             */}
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardHeader color="primary">
                  <h2 className={classes.cardTitleWhite}>
                    {"Agregar Nuevo Gasto A La Sucursal " + name}
                  </h2>
                  <p className={classes.cardCategoryWhite}>
                    Completa correctamente los datos
                  </p>
                </CardHeader>
                <CardBody>


                  <Grid justify="center" container spacing={2}>
                    <Grid item xs={12} sm={4} style={{ paddingTop: 16 }}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <SelectTypeExpense
                          detectarCambiosTypeExpenses={
                            detectarCambiosTypeExpenses
                          }
                        />
                      </FormControl>
                    </Grid>

                    {nameTypeExpenses.type == 1 ? (
                      <>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Nombre"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            error={!!errors.name}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "El Nombre es requerido",
                              },
                              minLength: {
                                value: 4,
                                message: "Minimo 4 caracteres",
                              },
                              maxLength: {
                                value: 255,
                                message: "Maximo 255 caracteres",
                              },
                              pattern: {
                                value:
                                  /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                                message: "Unicamente carácteres alfabéticos",
                              },
                            })}
                          />
                          <p>{errors?.name?.message}</p>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="description"
                            label="Descripción"
                            name="description"
                            autoComplete="name"
                            autoFocus
                            error={!!errors.description}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "La Descripción es requerida",
                              },
                              minLength: {
                                value: 4,
                                message: "Minimo 4 caracteres",
                              },
                              maxLength: {
                                value: 255,
                                message: "Maximo 255 caracteres",
                              },
                            })}
                          />
                          <p>{errors?.description?.message}</p>
                        </Grid>
                        <Hidden only={["xs", "sm", "xl", "md", "lg"]}>
                          <TextField

                          />
                        </Hidden>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="total"
                            label="Total De Gasto"
                            name="total"
                            autoComplete="total"
                            type="number"
                            autoFocus
                            error={!!errors.total}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "El total del gasto es requerido",
                              },
                              minLength: {
                                value: 1,
                                message: "Minimo 1 digito",
                              },
                            })}
                          />
                          <p>{errors?.total?.message}</p>
                        </Grid>


                      </>
                    ) : (
                      <>

                        <Grid item xs={12} sm={4} style={{ paddingTop: 16 }}>
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <SelectUser
                              detectarCambiosUser={detectarCambiosUser}
                              id={id}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="total"
                            label="Monto a Pagar"
                            name="total"
                            autoComplete="total"
                            type="number"
                            autoFocus
                            error={!!errors.total}
                            inputRef={register({
                              required: {
                                value: true,
                                message: "El total del pago es requerido",
                              },
                              minLength: {
                                value: 1,
                                message: "Minimo 1 digito",
                              },
                            })}
                          />
                          <p>{errors?.total?.message}</p>
                        </Grid>

                      </>
                    )}
                  </Grid>
                  <Card profile>
                    {nameTypeExpenses.type == 1 ? (
                      <>
                        <CardAvatar profile>
                          <img
                            src={image.urlPhoto}
                            alt="User"
                            width="100%"
                            height="100%"
                          />
                        </CardAvatar>
                        <CardBody profile>
                          <input
                            className={classes.input}
                            id="icon-button-file"
                            type="file"
                            name="image"
                            accept="image/png, image/jpg, image/jpeg"
                            onChange={handleChangeImage}
                          />
                          <label htmlFor="icon-button-file">
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <Tooltip
                                title="seleccionar imagen"
                                aria-label="seleccionar imagen"
                              >
                                <PhotoCamera />
                              </Tooltip>
                            </IconButton>
                          </label>
                          <InputLabel>Seleccionar Ticket del Gasto</InputLabel>
                          <h2 className={classes.cardTitle}>
                            Ticket del Gasto
                          </h2>
                          <h3 className={classes.cardTitle}></h3>
                        </CardBody>
                      </>
                    ) : (
                      <>
                        <ComissionsSellers id={nameUser.seller_id} />
                      </>
                    )}
                  </Card>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={3} className={classes.ContainerSubmit}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        className={classes.submit}
                      >
                        GUARDAR
                      </Button>
                    </Grid>
                  </Grid>
                  {/**
                   * identificar Codigo de FORMS
                   */}
                </CardBody>
              </Card>
            </GridItem>
            {/**
             * identificar Codigo de FORMS
             */}
            {/**
             * identificar Codigo de FORMS
             */}
          </Grid>
          {/**
           * identificar Codigo de FORMS 123456789
           */}
        </form>
      </div>
    </LayoutDashboard>
  );
}

export default withRouter(AddAdminExpenses);
