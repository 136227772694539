import React,{useEffect,useState,Fragment,useContext} from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import MethodGet, { MethodPut } from '../../Config/Services';
import ModalImage from 'react-modal-image';
import {Grid, Box} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clienteAxios from '../../Config/Axios';
import Spinner from '../../Complements/Spinner';
import SvjPayment from '../../Complements/Image/DetailSale/payment.svg';
import DataClient from '../../Complements/Image/DetailSale/DataClient.svg';
import ButtonBase from '@material-ui/core/ButtonBase';
import Swal from 'sweetalert2';
import { Button, Tooltip,Hidden } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import fileDownload from 'js-file-download';
import { Link } from 'react-router-dom';
import InscriptionsContext from '../../Reducers/Inscriptions/InscriptionsContext';

import ModalPayments from '../Inscriptions/ModalPayments';

const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(1),
    paddingInlineEnd: theme.spacing(3),
    paddingRight:theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: 'none',
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperDetalle: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  paperTitle: {
    background: '#9966cc'

  },
  formControl: {
		margin: theme.spacing(1),
		width:'100%'
	},
  papercard: {
    padding: theme.spacing(3),
    margin: 'auto',
    maxWidth: 'auto',
    backgroundColor:'#fff',
  },
  image: {
    width: '200px',
    height: '250px',
  },
  ticketimage: {
    width: 100,
    height: 120,
    maxWidth:100,
    maxHeight:120,
  },
  img: {
    margin: 'auto',
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  papercardtitle: {
   background: '#6f33ab',
   //background: '#9966cc',
    padding: 7,
    fontSize:'21pt',
    color:'white',
  },
  Tblpapercard: {
      padding: theme.spacing(3),
      margin: 'auto',
      Width: '100%',
      backgroundColor:'#fff',
  },
  textStructure:{
    borderBottom: '1px solid grey',
    whiteSpace:'nowrap',  
    textOverflow: 'ellipsis',
    overflow: 'hidden',

  }
}));


export default function StudentClientsDetails(props) {

  const { id } = props.match.params;
  const classes = useStyles();
  const [details, saveDetail] = useState([]);
  const [totalInscriptions, savetotalInscriptions] = useState('');
  const [totalPaidInscriptions, savetotalPaidInscriptions] = useState('');
  const [countInscriptions, saveCountInscriptions] = useState('');
  const [rest, saveRest] = useState('');
  const [client, saveClient] = useState([]);

  const [ open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        guardarMetodoPago({
        amount: '',
        change: '0',
		cash_income: '0',
		card_income: '0',
		transfer_income: '0',
        });
      setOpen(false);
      setDisabledButton(false);
  };
  const [error, guardarError] = useState(false);

  //Leer los datos del Modal
  const obtenerInformacionPago = e => {
    guardarMetodoPago({
    ...informacionMetodoPago,
    [e.target.name]: e.target.value
    })
}
    const [ informacionMetodoPago, guardarMetodoPago] = useState({
        amount: '0',
        change: '0',
		cash_income: '0',
		card_income: '0',
		transfer_income: '0',
        reference: '',
      });
      const [ totalDescuento, guardarTotalDescuento] = useState(0);
      const [ totalPagado, guardarTotalPagado] = useState(0);

	  const [image, setImage] = useState({
		urlPhoto: 'https://pisouniversitario.com/template/images/page-404.jpg',
		image: ''
  	});

    const [imageReference, setImageReference] = useState({
		urlPhotoReference: 'https://pisouniversitario.com/template/images/page-404.jpg',
		imageReference: ''
  	});

  const [data, setData] = useState({});

	//funcion para guardar la imagen y obtener la vista previa
    const handleChangeImage = (e) => {
        setImage({ ...image, urlPhoto: URL.createObjectURL(e.target.files[0]), 
                  image: e.target.files[0]
        });
    }

    const handleChangeImageReference = (e) => {
        setImageReference({ ...imageReference, urlPhotoReference: URL.createObjectURL(e.target.files[0]), 
			imageReference: e.target.files[0]
        });
    }

	const [bank_account, saveBankAccount] = useState('');

	const detectarCambiosBancos = (e) => {
		saveBankAccount(e.target.value);
	};

    const [ disableButton, setDisabledButton] = useState(false);

	const [cargando, spinnerCargando] = useState(false);

  const StateInscriptionsContext = useContext(InscriptionsContext);
  const { SaveCurrentInscription , DeleteInscription, inscriptions, success, AddInPayment} = StateInscriptionsContext;

  const SelectInscription = inscriptionID =>{
    SaveCurrentInscription(inscriptionID);
  }

  let InscriptionStorageID = JSON.parse(sessionStorage.getItem("inscription"));


  const SelectInscriptionPayment = (inscriptionID, total, total_paid) =>{
    SaveCurrentInscription(inscriptionID);
    guardarTotalDescuento(total);
    guardarTotalPagado(total_paid);
    setOpen(true);
  }

  const generarTicket = (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¿Desea Generar El Ticket De Pagos?',
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Generar',
      cancelButtonText: 'No, Cancelar',
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        //Cunsume API de Back-end en laravel para generar tiket de venta
        clienteAxios.get(`/ticketinscription/${id}`, { responseType: 'blob' }).then(response => {
          fileDownload(response.data, 'TicketInscripcion.pdf');
          guardarError(false);
        }).catch(error => {
          if (error.response.status === 409) {
            Swal.fire({
              icon: 'error',
              title: 'Algo sucedio',
              text: 'No se cuenta con suficiente información para generar el reporte'
            })
          } else if (error.response.status === 422) {
            guardarError(true)
          }
        });
      }
    });
  }

  const addPayment = (e) => {
    if(totalDescuento < informacionMetodoPago.amount && informacionMetodoPago.cash_income < totalDescuento)
    {
      return;
    } else if(informacionMetodoPago.amount < 0)
    {
      return;
    }
    setDisabledButton(true);
    data.id = InscriptionStorageID;
    data.cash_income = informacionMetodoPago.cash_income;
    data.card_income = informacionMetodoPago.card_income;
    data.transfer_income = informacionMetodoPago.transfer_income;
    data.image = image.image;
    data.imageReference = imageReference.imageReference;
    data.change = informacionMetodoPago.change;
    data.reference = informacionMetodoPago.reference;
	data.bank_account = bank_account;
    //console.log(data);
    AddInPayment(data);
    handleClose();
  }

	//query a la api
	useEffect(() => {
		let url = `/inscriptionsInfo/${id}`
		MethodGet(url)
			.then((res) => {
        savetotalInscriptions(res.data.total_inscriptions);
        savetotalPaidInscriptions(res.data.total_paid_inscriptions);
        saveCountInscriptions(res.data.count_inscriptions);
        saveRest(res.data.rest);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
	}, []);

	//query a la api
	useEffect(() => {
		let url = `/clients/${id}`
		MethodGet(url)
			.then((res) => {
        saveClient(res.data.data);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
	}, []);

  useEffect(() => {
    let url = `/clientsCoursesRegister/${id}`
    MethodGet(url)
        .then((res) => {
            saveDetail(res.data.data);
            spinnerCargando(true);
        })
        .catch((error) => {
            console.log(error);
            spinnerCargando(true);
        });
}, []);


  //  spinner de carga
	if (!cargando) return <Spinner />;
  return (
    <LayoutDashboard>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
{
/**
 * Inicia Contenedor de tarjetas Detalle de ventas y
 * datos del Cliente en contenedores cuadriculados
 *
 * @export Grid,Typography,Paper,Hidden 
 * @param detail
 */
}
{
/**
  ***** Tbl detalle venta
 */
}
<Container className={classes.container}>
  <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
    <Typography component="h2" variant="h2" style={{marginBottom:'1%'}}>
      Detalle de inscripciones
    </Typography>
  </Grid>
  <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="h1" variant="h4" align="center"
            className={classes.papercardtitle}>
             Información de inscripción
          </Typography>
        </Grid>
      <Paper className={classes.papercard}>
      <Grid container >
        <Hidden only={['xs','sm',]}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <ButtonBase className={classes.image}>
              <img className={classes.img} alt="Payment" src={SvjPayment} />
            </ButtonBase>          
        </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} container >
          <Grid item container>
            <Typography component="h6" variant="h6">
                Cantidad de Inscripciones Realizadas: {countInscriptions} inscripciones
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">

            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
                Total En Cursos: ${totalInscriptions}
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">

            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
              Total Pagado En Cursos: ${totalPaidInscriptions}
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">

            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
              Debe: ${rest}
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">

            </Typography>
          </Grid>
        </Grid>
      </Grid>
      </Paper>
    </Grid>
    {
    /**
      ***** Tbl Datos del Cliente
    */
    }
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="h1" variant="h4" align="center"
            className={classes.papercardtitle}
          >
              Datos del Alumno
          </Typography>
        </Grid>
      <Paper className={classes.papercard}>
      <Grid container>
        <Hidden only={['xs','sm',]}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <ButtonBase className={classes.image}>
              <img className={classes.img} alt="Clientdata" src={DataClient} />
            </ButtonBase>          
        </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} container>
          <Grid item container>
            <Typography component="h6" variant="h6">
              Nombre: {client.name} {client.first_lastname} {client.second_lastname}
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">

            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
                Teléfono: {client.number_phone}
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">

            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
              RFC: {client.RFC ? client.RFC : (<p>No hay RFC registrado</p>)}
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">

            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
             Dirección: {client.street}
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">

            </Typography>
          </Grid>
        </Grid>
      </Grid>
      </Paper>
    </Grid>
  </Grid>
</Container>



<Container className={classes.container}>
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography component="h1" variant="h4" align="center" className={classes.papercardtitle}>
           Inscripciones Realizadas
        </Typography>
      </Grid>
  <Paper className={classes.Tblpapercard}>
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item container  xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Typography component="h5" variant="h5">
            Curso
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
          <Typography component="h5" variant="h5" >
            Fecha Inscripción
          </Typography>
        </Grid>
        <Grid item xs={1} sm={1}  md={1} lg={1} xl={1}>
           <Typography component="h5" variant="h5">
             Total a Pagar
           </Typography>
         </Grid>
        <Grid item xs={1} sm={1}  md={1} lg={1} xl={1}>
           <Typography component="h5" variant="h5">
             Total Pagado
           </Typography>
         </Grid>
        <Grid item xs={2} sm={2}  md={2} lg={2} xl={2}>
           <Typography component="h5" variant="h5">
             Estatus
           </Typography>
         </Grid>
        <Grid item  xs={3} sm={3}  md={3} lg={3} xl={3}>
           <Typography component="h5" variant="h5">
             Opciones
           </Typography>
         </Grid>
  {details.map(datainfo => (
    <Grid key={datainfo.clave}
      container xs={12} sm={12} md={12} lg={12} xl={12}  className={classes.textStructure}>
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Typography component="h3" variant="p" color="textSecondary">
            {datainfo.Course_name}
            </Typography>
          </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Typography component="h3" variant="p" color="textSecondary">
              {datainfo.date_Insciption}
            </Typography>
          </Grid>
        <Grid item xs={1} sm={1}  md={1} lg={1} xl={1}>
            <Typography component="h3" variant="p" color="textSecondary">
              ${datainfo.total_course}
            </Typography>
          </Grid>
        <Grid item xs={1} sm={1}  md={1} lg={1} xl={1}>
            <Typography component="h3" variant="p" color="textSecondary">
              ${datainfo.total_paid}
            </Typography>
          </Grid>
          <Hidden only={['xs','sm',]}>
        <Grid item xs={2} sm={2}  md={2} lg={2} xl={2}>
              <Typography component="h3" variant="p" color="textSecondary">
              <Fragment>
						    { 
                  datainfo.status == 0 ? (<p style={{color:'white', background:'red',whiteSpace: 'nowrap', padding:'4px'}} align="center">Cancelado</p>)
						      :
                  datainfo.status == 1 ? (<p style={{color:'white', background:'#ffc400',  whiteSpace: 'nowrap', padding:'4px'}} align="center">No Liquidado</p>)
						      :
                  datainfo.status == 2 ? (<p style={{color:'white', background:'#43a047',whiteSpace: 'nowrap', padding:'4px'}} align="center">Liquidado</p>) 

						      : null
						    }
						</Fragment>
              </Typography>
            </Grid>
          </Hidden>
          <Grid item xs={3} sm={3}  md={3} lg={3} xl={3}>
            <Typography component="h3" variant="p" color="textSecondary">
            <Fragment>
							<Link
								to={`/HistorialPagos`}
								style={{ color: '#3f51b5' }}
							>
								<Button
									variant="contained"
									style={{
										textTransform: 'none',
										background: 'white',
									}}
                  onClick={ () => SelectInscription(datainfo.clave)}
									size="small"
								>
									<Tooltip
										title="Ver Historial De Pagos"
										aria-label="Ver Historial De Pagos"
									>
										<VisibilityIcon style={{ color: 'indigo' }} />
									</Tooltip>
								</Button>
							</Link>
                            {datainfo.total_course > datainfo.total_paid &&
							<Button
								variant="contained"
								style={{ textTransform: 'none', background: 'white' }}
								size="small"
                                type="submit"
								onClick={ () => SelectInscriptionPayment(datainfo.clave, datainfo.total_course, datainfo.total_paid)}
							>
								<Tooltip
									title="Agregar Pago"
									aria-label="Agregar Pago"
								>
									<MonetizationOnIcon style={{ color: 'indigo' }} />
								</Tooltip>
							</Button>
                            }
                        	<Button
								variant="contained"
								style={{ textTransform: 'none', background: 'white' }}
                        	    size="small"
                        	    onClick={() => generarTicket(datainfo.clave)}
                        	>
                        	    <Tooltip title="Generar ticket de pagos" aria-label="Generar ticket de pagos">
                        	        <PictureAsPdf style={{ color: '#01579b' }} />
                        	    </Tooltip>
                        	</Button>
							<Button
								variant="contained"
								style={{ textTransform: 'none', background: 'white' }}
								size="small"
								onClick={() => DeleteInscription(datainfo.clave)}
							>
								<Tooltip
									title="Cancelar Inscripción"
									aria-label="Cancelar Inscripción"
								>
									<DeleteIcon style={{ color: 'red' }} />
								</Tooltip>
							</Button>
						</Fragment>
            </Typography>
          </Grid>
        </Grid>
    </Grid>
  ))}
</Grid>

    </Grid>
    </Paper>
  </Grid>
</Container>


</main>
</div>
</LayoutDashboard>
  );
}