import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import AccountBalance from '@material-ui/icons/AccountBalance';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Tooltip } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

export default function ModalPayments(props) {
  const classes = useStyles();
  const { open,
    handleClose,
    obtenerInformacionPago,
    totalDescuento,
    totalPagado,
    informacionMetodoPago,
    addPayment,
    handleChangeImage,
    image,
    disableButton,
    couponInfo,
    guardarTotalDescuento,
  } = props;
  const [mensaje, guardarMensaje] = useState('');
  const [error, guardarError] = useState(false);
  const [totalSubDiscount, saveTotalSubDiscount] = useState(0);
  const [couponDiscount, saveCouponDiscount] = useState('');
  const [disableCouponButton, saveDisableCouponButton] = useState(false);

  useEffect(() => {
    if (couponDiscount != '' && couponInfo != '') {
      let descuentoPorcentajeNew = couponDiscount;
      saveTotalSubDiscount(totalDescuento);
      const descuento = ((descuentoPorcentajeNew * totalDescuento) / 100);
      const TotalDescuento = totalDescuento - descuento;
      guardarTotalDescuento(Math.round(TotalDescuento));
      saveDisableCouponButton(true);
    }
  }, [couponDiscount, couponInfo])

  informacionMetodoPago.amount = Number(informacionMetodoPago.cash_income) + Number(informacionMetodoPago.card_income) + Number(informacionMetodoPago.transfer_income);
  var totalRestante = Number(totalDescuento) - (Number(informacionMetodoPago.amount) + Number(totalPagado));

  if ((Number(informacionMetodoPago.cash_income) + Number(totalPagado)) > totalDescuento) {
    informacionMetodoPago.change = (Number(informacionMetodoPago.cash_income) + Number(totalPagado)) - totalDescuento;
  } else {
    informacionMetodoPago.change = 0
  }

  const total = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
  }).format(totalDescuento)

  const total_amount = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
  }).format(informacionMetodoPago.amount)

  const total_remaining = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 2
  }).format(totalRestante)

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>{"Realizar Pago"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid Container spacing={2}>
              <Grid item xs={12}>
                <h3>Total: {total} </h3>
                <h3>Pagado: {total_amount} </h3>
                <h3>Por pagar: {(totalRestante < 0) ? ('$0') : total_remaining} </h3>
                {

                }
                {

                }
                {
                  informacionMetodoPago.cash_income > totalRestante &&
                  <h3>Cambio: ${informacionMetodoPago.change}</h3>
                }
              </Grid>
              <ThemeProvider theme={theme}>
                <Grid container>
                  <Grid item xs={12} md={4} xl={4}>
                    <label>Monto en efectivo</label>
                    <TextField
                      className={classes.margin}
                      id="cash_income"
                      placeholder="Efectivo"
                      fullWidth
                      type="number"
                      name="cash_income"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MonetizationOn />
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => obtenerInformacionPago(e)}
                    />
                    {
                      error ?
                        <p style={{ color: 'red' }}>{mensaje}</p>
                        : null
                    }
                  </Grid>

                  <Grid item xs={12} md={4} xl={4}>
                    <label>Monto en tarjeta</label>
                    <TextField
                      className={classes.margin}
                      id="card_income"
                      placeholder="Tarjeta"
                      fullWidth
                      type="number"
                      name="card_income"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MonetizationOn />
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => obtenerInformacionPago(e)}
                    />
                    {
                      error ?
                        <p style={{ color: 'red' }}>{mensaje}</p>
                        : null
                    }

                    {
                      informacionMetodoPago.card_income > 0 &&
                      <Fragment>
                        <div>
                          <InputLabel>Selecciona ticket</InputLabel>
                          <input
                            className={classes.input}
                            id="icon-button-file"
                            type="file"
                            name="image"
                            accept="image/png, image/jpg, image/jpeg"
                            onChange={handleChangeImage}
                          />
                          <label htmlFor="icon-button-file">
                            <IconButton color="primary" aria-label="upload picture" component="span" >
                              <Tooltip title="SElecciona imágen" aria-label="seleccionar imagen">
                                <PhotoCamera />
                              </Tooltip>
                            </IconButton>
                          </label>
                        </div>
                        <img
                          src={image.urlPhoto}
                          alt="Ticket"
                          width="50px"
                          height="50px"
                        />
                      </Fragment>
                    }
                  </Grid>

                  <Grid item xs={12} md={4} xl={4}>
                    <label>Monto en transferencia</label>
                    <TextField
                      className={classes.margin}
                      id="transfer_income"
                      placeholder="Transferencia"
                      fullWidth
                      type="number"
                      name="transfer_income"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MonetizationOn />
                          </InputAdornment>
                        ),
                      }}
                      onChange={e => obtenerInformacionPago(e)}
                    />
                    {
                      error ?
                        <p style={{ color: 'red' }}>{mensaje}</p>
                        : null
                    }

                    {
                      informacionMetodoPago.transfer_income > 0 &&
                      <Fragment>
                        <label>Referencia</label>
                        <TextField
                          className={classes.margin}
                          id="reference"
                          placeholder="Referencia"
                          fullWidth
                          required
                          type="number"
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountBalance />
                              </InputAdornment>
                            ),
                          }}
                          name="reference"
                          onChange={e => obtenerInformacionPago(e)}
                        />
                      </Fragment>
                    }
                  </Grid>
                </Grid>  
              </ThemeProvider>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="secondary" variant="contained">
            Cancelar
          </Button>
          {!disableButton ?
            <Button type="submit" color="primary" autoFocus variant="contained" onClick={() => addPayment()}>
              Guardar
            </Button>
            :
            <Button type="submit" disabled color="primary" autoFocus variant="contained">
              Cargando...
            </Button>
          }
        </DialogActions>

      </Dialog>
    </Fragment>
  )
}
