import {REGISTRO_EXITOSO,
	OBTENER_USUARIO,
	LOGIN_EXITOSO,
	CERRAR_SESION,
	GET_SCHOOL_ID,
	VERIFY_RECAPTCHA,
	LOGIN_ERROR} from '../../types/index';

export default (state,action)=>{
switch(action.type){

   case VERIFY_RECAPTCHA:
           return{
               ...state,
               verificationRecaptcha: action.payload
   		}

  case REGISTRO_EXITOSO:
		  return {
			  ...state,

		  }
  case LOGIN_EXITOSO:
		  localStorage.setItem('token',action.payload)
		  localStorage.setItem('expires_at',action.expires_at)
		  return {
			  ...state,
			 autenticado:true,
			 cargando:false
			}
  case OBTENER_USUARIO:
		  return{
		  ...state,
		  autenticado:true,
		  usuario:action.payload,
		  cargando:false
		  }
  case GET_SCHOOL_ID:
	return {
		...state,
	   schoolID:action.payload,
	   cargando:false
	  }
  case CERRAR_SESION:
		  localStorage.removeItem('token');
		  localStorage.removeItem('expires_at');
		  return {
			...state,
			token: null,
			usuario:null,
			autenticado:null,
			cargando:false

		  }
   default:
		return state; 
}
}