import React, { useState, useEffect, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PanToolIcon from "@material-ui/icons/PanTool";
import {
  Button,
  Tooltip,
  Hidden,
  Grid,
  Box,
  Fab,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import BusinessIcon from "@material-ui/icons/Business";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import MaterialTable from "material-table";
/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "10px",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  // Ratingstyle: {
  // 	width: 200,
  // 	display: 'flex',
  // 	alignItems: 'center',
  // },
}));

const BusinessRulesIndex = (props) => {
  const classes = useStyles();

  //proveedores = state, guardarRules =funcion para guardar en el state;
  const [business, guardarRules] = useState([]);
  const [cargando, spinnerCargando] = useState(false);

  //query a la api
  useEffect(() => {
    let url = "/rules";
    MethodGet(url)
      .then((res) => {
        guardarRules(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  // const deleteBussinessRule = (id) => {
  //			Swal.fire({
  //				title: '¿Estás seguro?',
  //				text: 'Una regla de negocio eliminada no se puede recuperar',
  //				type: 'warning',
  //				showCancelButton: true,
  //				confirmButtonColor: '#3085d6',
  //				cancelButtonColor: '#d33',
  //				confirmButtonText: 'Si, Eliminar',
  //				cancelButtonText: 'No, Cancelar',
  //			}).then((result) => {
  //				if (result.value) {
  //					// eliminar en la rest api
  //					let url = `/rules/${id}`;
  //					MethodDelete(url).then((res) => {
  //						if (res.status === 200) {
  //							const CambioIndexBusiness = business.filter(
  //								(rule) => rule.id !== id
  //							);
  //							guardarRules(CambioIndexBusiness);
  //							Swal.fire('Regla Eliminada', res.data.mensaje, 'success');
  //						}
  //					}).catch((error) => {
  //						Swal.fire({
  //							title: 'Error',
  //							text: error.response.data.error,
  //							icon: 'error',
  //						});
  //					});
  //				}
  //			});
  //		};

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            marginTop: 85,
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 20,
          }}
        >
          <Typography
            className={classes.typography}
            component="h1"
            variant="h4"
          >
            Mis Reglas de Negocio
          </Typography>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 15 }}
        >
          <MaterialTable
            title=""
            columns={[
              {
                title: "DESCUENTO",
                field: "discount",
                render: (rowData) => <p>{rowData.discount} %</p>,
              },
              {
                title: "MINIMO PARA DESCUENTO",
                field: "money_discount_min",
                render: (rowData) =>
                  rowData.money_discount_min ? (
                    <p>$ {rowData.money_discount_min}</p>
                  ) : (
                    <p>$ 1</p>
                  ),
              },
              {
                title: "MAXIMO PARA DESCUENTO",
                field: "money_discount_max",
                render: (rowData) =>
                  rowData.money_discount_max <= 90000 &&
                  rowData.money_discount_max ? (
                    <p>$ {rowData.money_discount_max}</p>
                  ) : rowData.money_discount_max ? (
                    <p>No hay limite</p>
                  ) : (
                    <p>No Aplica</p>
                  ),
              },
              {
                title: "TIPO DE REGLA",
                field: "money_discount",
                render: (rowData) =>
                  rowData.type_rule == 1 ? (
                    <p>Descuentos Para Clientes</p>
                  ) : (
                    <p>Porcentaje De Descuentos Para Productos Mixtos</p>
                  ),
              },
            ]}
            data={business}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "OPCIONES",
              },
              body: {
                emptyDataSourceMessage: "No Hay Descuentos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              {
                title: "TIPO DE REGLA",
                field: "money_discount",
                render: (rowData) =>
                  rowData.type_rule == 1 ? (
                    <p>Descuentos Para Clientes</p>
                  ) : (
                    <p>Porcentaje de Comisión por Ventas</p>
                  ),
              },
            ]}
            data={business}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Descuento: {rowData.discount}%</p>
                      <p>
                        Minimo para Descuento:
                        {rowData.money_discount_min ? (
                          <>$ {rowData.money_discount_min}</>
                        ) : (
                          <>$ 1</>
                        )}
                      </p>
                      <p>
                        {" "}
                        Maximo para Descuento:
                        {rowData.money_discount_max <= 90000 &&
                        rowData.money_discount_max ? (
                          <>$ {rowData.money_discount_max}</>
                        ) : rowData.money_discount_max ? (
                          <>No hay limite</>
                        ) : (
                          <>No Aplica</>
                        )}
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "OPCIONES",
              },
              body: {
                emptyDataSourceMessage: "No Hay Descuentos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </LayoutDashboard>
  );
};

export default BusinessRulesIndex;
