import React, { useState, useEffect, Fragment, useContext} from 'react';
import MethodGet from '../../Config/Services';
import AddSale from './Add_SaleProduct';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import { Link } from 'react-router-dom';
import AuthContext from '../../Context/autenticacion/authContext';
/**importar spinner */
import Spinner from '../../Complements/Spinner';

export default function MiddlewareAddSales(props) {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

   const [products, saveProduct] = useState([]);
   const [clients, saveClient] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  
  const { id } = props.match.params;

	//query a la api
	useEffect(() => {
		let url = '/validate-sales';
		MethodGet(url)
			.then((res) => {
				saveProduct(res.data.products);
				saveClient(res.data.clients);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
    }, []);

    //  spinner de carga
	if (!cargando) return <Spinner />;

  let component;
  if(usuario){
  if(products != 0 && clients != 0)
  {
    component = <AddSale {...props} id={id} />
  }

  if(products == 0 && usuario.type == 1)
  {
    //Agrega producto un admin
    component = <AlertNotAuthorized mensaje={<p>No puedes realizar ventas hasta que registres al menos un producto! <Link to="/Productos" style={{ color: "#3f51b5" }}>Da Clic Aqui para registrar un Producto</Link></p>}/>
  } 
  if(products == 0 && usuario.type != 1) 
  {
    //Se le notifica al vendedor que no hay productos en su sucursal
    component = <AlertNotAuthorized mensaje={<p>No puedes realizar ventas hasta que haya al menos un producto registrado en tu sucursal!, notifica al gerente de tu sucursal</p>}/>
  } 

  // if(clients == 0 && usuario.type == 1)
  // {
  //   //Agrega un cliente en cualquier sucursal un admin
  //   component = <AlertNotAuthorized mensaje={<p>No puedes realizar ventas hasta que registres al menos un cliente! <Link to="/Clientes" style={{ color: "#3f51b5" }}>Da Clic Aqui para registrar un Cliente</Link></p>}/>
  // } 
  // if(clients == 0 && usuario.type != 1)
  // {
  //   //Agrega un cliente el vendedor en su sucursal
  //   component = <AlertNotAuthorized mensaje={<p>No puedes realizar ventas hasta que haya al menos un cliente registrado en tu sucursal! <Link to={"/Agregar_Cliente/" + usuario.id_branch_office} style={{ color: "#3f51b5" }}>Da Clic Aqui para registrar un Cliente</Link></p>}/>
  // }
}

  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
