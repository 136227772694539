import React, { useReducer } from "react";
import ProductContext from "./ProductContext";
import ProductReducer from "./ProductReducer";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../Config/Services";
import {
  GET_DATA_EXCEL_PRODUCTS,
  GET_ALL_PRODUCTS,
  ADD_PRODUCTS_EXCEL,
  DELETE_PRODUCT,
  UPDATE_PRODUCTS_EXCEL,
  EDIT_PRODUCT,
  GET_DATA_EXCEL_PRODUCTS_STOCK,
  GET_DATA_EXCEL_PRODUCTS_SALE,
} from "../../types";
import Swal from "sweetalert2";
import headerConfig from "../../Config/imageHeaders";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";

const ProductState = ({ children }) => {
  // estado inicial
  const initialSate = {
    productsDataExcel: [],
    products: [],
    errors: [],
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(ProductReducer, initialSate);

 const getProductsDataExcel = (id) => {
    let url = `download-products/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_DATA_EXCEL_PRODUCTS,
        payload: res.data.data,
      });
    });
  };
  const getProductsDataExcelSale = (id) => {
    let url = `download-products-sales/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_DATA_EXCEL_PRODUCTS_SALE,
        payload: res.data.data,
      });
    });
  };
  const getStockProductsDataExcel = (id) => {
    let url = `download-products-stock/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_DATA_EXCEL_PRODUCTS_STOCK,
        payload: res.data.data,
      });
    });
  };
  const getProducts = (id) => {
    let url = `sucursalproductos/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_PRODUCTS,
        payload: res.data.data,
      });
    });
  };

  const getSaleProducts = (id) => {
    let url = `productsBranch/${id}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_PRODUCTS,
        payload: res.data.data,
      });
    });
  };

  const getSaleProductsPerBrand = (branch, type) => {
    let url = `productsPerBrand/${branch}/${type}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_PRODUCTS,
        payload: res.data.data,
      });
    });
  };

  const importProductsExcel = (products) => {
    const formData = new FormData();
    formData.append("file", products.file);
    let url = `upload-products/${products.id}`;
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        toast.success(
          <div>
            <DoneIcon /> Productos Importados Exitosamente!{" "}
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch({
          type: ADD_PRODUCTS_EXCEL,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
        error.response.data.error.map((response) =>
          toast.error(
            <div>
              <ErrorIcon />
              Error En Celda {response.row} {response.errors[0]}
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
        );
      });
  };

  const UpdateProductsExcel = (products) => {
    const formData = new FormData();
    formData.append("file", products.file);
    let url = `update-products/${products.id}`;
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        toast.success(
          <div>
            <DoneIcon /> Productos Actualizados Exitosamente!{" "}
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch({
          type: UPDATE_PRODUCTS_EXCEL,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
        error.response.data.error.map((response) =>
          toast.error(
            <div>
              <ErrorIcon />
              Error En Celda {response.row} {response.errors[0]}
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
        );
      });
  };

  const UpdateProductsStock = (products) => {
    const formData = new FormData();
    formData.append("file", products.file);
    let url = `update-productsStock/${products.id}`;
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        toast.success(
          <div>
            <DoneIcon /> Stock Actualizado Exitosamente!{" "}
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch({
          type: UPDATE_PRODUCTS_EXCEL,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
        error.response.data.error.map((response) =>
          toast.error(
            <div>
              <ErrorIcon />
              Error En Celda {response.row} {response.errors[0]}
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
        );
      });
  };
  const EditProduct = (products) => {
    const formData = new FormData();
    formData.append("tag", products.tag);
    formData.append("name", products.name);
    formData.append("price", products.price);
    formData.append("price_purchase", products.price_purchase);
    formData.append("description", products.description);
    formData.append("quantity", products.quantity);
    formData.append("type", products.type);
    formData.append("id_branch_office", products.id_branch_office);
    if (products.image !== "") {
      formData.append("image", products.image);
    }
		formData.append("_method", "PATCH");
    let url = `/products/${products.idproduct}`; //cambiar la url completa
    MethodPost(url,formData)
      .then((res) => {
        Swal.fire({
          title: "Producto Editado Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };
  const DeleteProduct = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El producto ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `products/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_PRODUCT,
            payload: id,
          });
        });
      }
    });
  };

  return (
    <ProductContext.Provider
      value={{
        productsDataExcel: state.productsDataExcel,
        products: state.products,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        getProductsDataExcel,
        getProductsDataExcelSale,
        getSaleProducts,
        getSaleProductsPerBrand,
        importProductsExcel,
        getProducts,
        UpdateProductsExcel,
        UpdateProductsStock,
        DeleteProduct,
        EditProduct,
        getStockProductsDataExcel,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductState;
