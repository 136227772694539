import React, { useReducer } from 'react';
import CategoryContext from './CategoryContext'
import CategoryReducer from './CategoryReducer';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../Config/Services";
import { ADD_NEW_CATEGORY, CURRENT_CATEGORY, DELETE_CATEGORY, GET_ALL_CATEGORIES,
    SHOW_ERRORS_API, UPDATE_CATEGORY } from "../../types";
import Swal from "sweetalert2";

const CategoryState = ({children}) => {
  // estado inicial
  const initialSate = {
    categories: [],
    category: {},
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(CategoryReducer, initialSate);

  //funcion para obtener todas las categorias
  const GetCategories = () => {
    sessionStorage.removeItem('category');
    let url = `categories`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_ALL_CATEGORIES,
        payload: response.data.data,
      });
    });
  };

  //funcion para agregar categoria
  const AddCategory = (category) => {
    const formData = new FormData();
		formData.append("name", category.name);
    //console.log(school.image);
    let url = "/categories";
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Categoria Agregada",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_CATEGORY,
        });
      })
      .catch((error) => {
        Swal.fire({
		    title: 'Error',
          text: error.response.data.error,
    			icon: 'error'
				});
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //función para seleccionar una categoria
  const SaveCurrentCategory = (category) => {
    sessionStorage.setItem('account',JSON.stringify(category));
    dispatch({
      type: CURRENT_CATEGORY,
      payload: category,
    });
  };

  //función para editar una categoria
  const UpdateCategory = (category) => {
    const formData = new FormData();
    formData.append("name", category.name);
	formData.append("_method", "PATCH");
    let url = `categories/${category.id}`;
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Categoria Actualizada",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_CATEGORY,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //eliminar categoria
  const DeleteCategory = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La categoria ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `categories/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_CATEGORY,
            payload: id,
          });
        });
      }
    });
  };

  return (
    <CategoryContext.Provider
      value={{
        categories: state.categories,
        category: state.category,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        GetCategories,
        AddCategory,
        SaveCurrentCategory,
        UpdateCategory,
        DeleteCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
}

export default CategoryState;