import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import HomeIcon from "@material-ui/icons/Home";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GavelIcon from "@material-ui/icons/Gavel";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import MoneyIcon from "@material-ui/icons/Money";
import ClassIcon from "@material-ui/icons/Class";
import CategoryIcon from "@material-ui/icons/Category";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PictureAsPdfTwoToneIcon from "@material-ui/icons/PictureAsPdfTwoTone";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import CancelIcon from "@material-ui/icons/Cancel";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PaymentIcon from "@material-ui/icons/Payment";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import MonetizationOnTwoToneIcon from "@material-ui/icons/MonetizationOnTwoTone";
import PostAddTwoToneIcon from "@material-ui/icons/PostAddTwoTone";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  Collapse,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  Link: {
    textDecoration: "none",
    color: "white",
  },
  subMenu: {
    backgroundColor: "#ffffff22",
    color: "white",
  },
  Iconwhite: {
    color: "white",
  },
}));

export default function ListItems() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const [openSubMenuReports, setOpenMenuReports] = React.useState(false);
  const subMenuOpenReports = () => {
    setOpenMenuReports(!openSubMenuReports);
  };
  const [openSchoolAdministration, setOpenMenuSchoolAdministration] =
    React.useState(false);
  const subMenuOpenSchoolAdministration = () => {
    setOpenMenuSchoolAdministration(!openSchoolAdministration);
  };

  const [openSubMenuSales, setOpenMenuSales] = React.useState(false);
  const subMenuOpenSales = () => {
    setOpenMenuSales(!openSubMenuSales);
  };

  const [openSubMenuProductControl, setOpenMenuProductControl] =
    React.useState(false);
  const subMenuOpenProductControl = () => {
    setOpenMenuProductControl(!openSubMenuProductControl);
  };

  return (
    <div>
      <List component="nav" className={classes.Iconwhite}>
        <Link to="/" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Inicio" aria-label="Inicio">
                <HomeIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Inicio" />
          </ListItem>
        </Link>
        <Divider />
        {/**
         * @Comment Menu desplegable de Administracion escolar contenedor de Salones,Cursos,inscripciones,Programar Cursos
         * @Note none
         * @Author DanielRM
         * @export
         * @return {*}
         */}
        <ListItem
          button
          onClick={subMenuOpenSchoolAdministration}
          className={classes.Iconwhite}
        >
          <ListItemIcon>
            <ArtTrackIcon className={classes.Iconwhite} />
          </ListItemIcon>
          <ListItemText primary="Control Escolar" />
          {openSchoolAdministration ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSchoolAdministration} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            <Link to="/Salones" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Salones" aria-label="Salones">
                    <MeetingRoomIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Salones" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Cursos" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Cursos" aria-label="Cursos">
                    <ClassIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Cursos" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Inscripciones" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Inscripciones" aria-label="Inscripciones">
                    <AssignmentIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Inscripciones" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Programaciones" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Programaciones" aria-label="Programaciones">
                    <DateRangeIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Programar Cursos" />
              </ListItem>
            </Link>
            <Divider />
          </List>
        </Collapse>
        <Divider />

        <ListItem
          button
          onClick={subMenuOpenSales}
          className={classes.Iconwhite}
        >
          <ListItemIcon>
            <MonetizationOnTwoToneIcon className={classes.Iconwhite} />
          </ListItemIcon>
          <ListItemText primary="Gestor de Ventas" />
          {openSubMenuSales ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSubMenuSales} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            <Link to="/Ventas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Ventas" aria-label="Ventas">
                    <AttachMoneyIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Ventas" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Ventas/Canceladas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Ventas Canceladas"
                    aria-label="Ventas Canceladas"
                  >
                    <CancelIcon className={classes.subMenu} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Ventas Canceladas" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Reglas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Reglas de negocio"
                    aria-label="Reglas de negocio"
                  >
                    <LocalAtmIcon className={classes.subMenu} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Reglas de negocio" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Cupones" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Cupones de descuento"
                    aria-label="Cupones de descuento"
                  >
                    <MoneyIcon className={classes.subMenu} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Cupones de descuento" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Comisiones" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Comisiones" aria-label="Comisiones">
                    <PaymentIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Comisiones" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Utilidad/Tienda" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Corte De Caja" aria-label="Corte De Caja">
                    <MonetizationOnIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Corte De Caja Tiendas" />
              </ListItem>
            </Link>
            <Divider />
          </List>
        </Collapse>
        <Divider />
        {/**
         * @Comment Menu desplegable de Control de Productos contenedor de Sucursales y Escuelas
         * @Author DanielRM
         * @export
         * @return {*}
         */}
        <ListItem
          button
          onClick={subMenuOpenProductControl}
          className={classes.Iconwhite}
        >
          <ListItemIcon>
            <PostAddTwoToneIcon className={classes.Iconwhite} />
          </ListItemIcon>
          <ListItemText primary="Control de Producto" />
          {openSubMenuProductControl ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSubMenuProductControl} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            <Link to="/Productos" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="Mis Productos" aria-label="Mis Productos">
                    <ShoppingCartIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Mis Productos" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Salidas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Salida De Productos"
                    aria-label="Salida De Productos"
                  >
                    <RemoveShoppingCartIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Salida De Productos" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Inventarios" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Inventario De Productos"
                    aria-label="Inventario De Productos"
                  >
                    <CategoryIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Inventario De Productos" />
              </ListItem>
            </Link>
            <Divider />
            {/* <Link to="/Categorias" className={classes.Link}>
        <ListItem button>
          <ListItemIcon>
            <Tooltip
						title="Categorias"
						aria-label="Categorias"
						>
              <BookmarksIcon className={classes.Iconwhite}/>
            </Tooltip>
          </ListItemIcon>
            <ListItemText primary="Categorias"/>
        </ListItem>
      </Link> */}
            {/* <Divider /> */}
            <Link to="/Stock de Productos" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Stock de Productos"
                    aria-label="Stock de Productos"
                  >
                    <FormatColorFillIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Stock de Productos" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/MovimientosStock" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Movimientos Del Stock"
                    aria-label="Movimientos Del Stock"
                  >
                    <GavelIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Movimientos Del Stock" />
              </ListItem>
            </Link>
            <Divider />

            <Divider />
          </List>
        </Collapse>
        <Divider />

        <ListItem
          button
          onClick={subMenuOpenReports}
          className={classes.Iconwhite}
        >
          <ListItemIcon>
            <PictureAsPdfTwoToneIcon className={classes.Iconwhite} />
          </ListItemIcon>
          <ListItemText primary="Reportes PDF" />
          {openSubMenuReports ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openSubMenuReports} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.subMenu}>
            <Link to="/EntradaProductos" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Entrada de Productos"
                    aria-label="Entrada de Productos"
                  >
                    <PictureAsPdfIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Entrada de Productos" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Utilidad" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Reportes De Corte De Caja (Sucursales)"
                    aria-label="Reportes De Corte De Caja (Sucursales)"
                  >
                    <PictureAsPdfIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Reportes De Corte De Caja (Sucursales)" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/Reportes/Utilidad/Escuelas" className={classes.Link}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip
                    title="Reportes De Corte De Caja (Escuelas)"
                    aria-label="Reportes De Corte De Caja (Escuelas)"
                  >
                    <PictureAsPdfIcon className={classes.Iconwhite} />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="Reportes De Corte De Caja (Escuelas)" />
              </ListItem>
            </Link>
            <Divider />
          </List>
        </Collapse>
        <Divider />

        <Link to="/Clientes" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Clientes" aria-label="Clientes">
                <PeopleAltIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Clientes" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Productos" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Mis Productos" aria-label="Mis Productos">
                <ShoppingCartIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Mis Productos" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Utilidad/Escuela" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Corte De Caja" aria-label="Corte De Caja">
                <MonetizationOnIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Corte De Caja Escolar" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Stock de Productos" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip
                title="Stock de Productos"
                aria-label="Stock de Productos"
              >
                <FormatColorFillIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Stock de Productos" />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/Gastos/Tienda" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip title="Gastos" aria-label="Gastos">
                <MoneyOffIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Gastos" />
          </ListItem>
        </Link>
        <Link to="/EntradaProductos" className={classes.Link}>
          <ListItem button>
            <ListItemIcon>
              <Tooltip
                title="Entrada de Productos"
                aria-label="Entrada de Productos"
              >
                <PictureAsPdfIcon className={classes.Iconwhite} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Entrada de Productos" />
          </ListItem>
        </Link>
        <Divider />
      </List>
    </div>
  );
}
