import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  Dialog
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "../../Complements/Spinner";
import { withRouter } from "react-router-dom";
import MethodGet from "../../Config/Services";
import { useForm } from "react-hook-form";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SubCategoryContext from "../../Reducers/SubCategories/SubCategoryContext";
import GridItem from "../../Components/Grid/GridItem";
import SelectCategories from "../SelectOptions/SelectCategory";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    //Mantiene el margen entre la vista final a 2 espaciados de los margenes de la pantalla fisica
    //deben ser igual en dodas las vistar de card para evitar diferentes alineaciones entre contenedores
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingInlineEnd: theme.spacing(2),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#8e24aa",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
function SubCategoriesEdit({ modal, handleClose, id, setOpenModalEdit, id_category, }) {
  const classes = useStyles();
  const formSubCategoryContext = useContext(SubCategoryContext);

  const { success, updateSubcategory } = formSubCategoryContext;

  const [type, setType] = useState("");

  const ChangeType = (event) => {
    setType(event.target.value);
  };

  const [cargando, spinnerCargando] = useState(false);
  const { register, errors, handleSubmit } = useForm();

  const [subcategory, saveSubCategory] = useState(false);
  const [category, setCategory] = useState(null);

  //obtenemos el producto de session storage
  const detectarCambiosCategory = (e) => {
    setCategory(e.target.value);
  };
  //extraer las propiedades del objeto Desctructuracion
  const { name } = subcategory;

  useEffect(() => {
    let url = `/subcategories/${id}`;

    MethodGet(url)
      .then((res) => {
        saveSubCategory(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [id]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    data.id_categories = category;
    data.id = id;

    updateSubcategory(data);
  };
  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de escuelas
    if (success) {
      setOpenModalEdit(false);
    }
    //eslint-disable-next-line
  }, [success]);

  //spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modal}
        maxWidth={1200}
        fullWidth

      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ textAlign: "center", background: "#3F51B5", color: "white", }}>
          Editar subcategoría {name}
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden', overflowX: 'hidden' }} >
          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
            
              <GridItem  xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container style={{ marginTop: "-60px" }}>
                  <Grid xs={12} sm={12} >
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      defaultValue={name}
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 3,
                          message: "Minimo 3 caracteres",
                        },
                        maxLength: {
                          value: 45,
                          message: "Maximo 45 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justify="center">
                  <Grid item spacing={3} xs={12} sm={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.submit}
                    >
                      ACTUALIZAR
                    </Button>
                  </Grid>
                </Grid>
              </GridItem>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withRouter(SubCategoriesEdit);