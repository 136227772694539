import React,  { useReducer } from "react";
import ResetPasswordsContext from "./ResetPasswordsContext";
import ResetPasswordsReducer from "./ResetPasswordsReducer";
import { FORGOT_PASSWORD, RESET_PASSWORD } from "../../types";
import { MethodPost } from "../../Config/Services";
import Swal from "sweetalert2";

const ResetPasswordsState = (props) => {
  // estado inicial
  const initialSate = {
    sendLink: false,
    setUserPassword: false,
  };

  // dispatch que ejecta las funciones
  const [state, dispatch] = useReducer(ResetPasswordsReducer, initialSate);

  // funcion que envia el link  de restablecimiento por correo
  const sendResetLinkEmail = (email) => {
    let url = "/password/create";
    MethodPost(url, email)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Se ha enviado un link a tu correo",
          text: res.data.message,
        });
        dispatch({
          type: FORGOT_PASSWORD,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.error,
        });
      });
  };

  // funcion que se encarga del cambio de contraseña
  const resetPassword = (data) => {
    let url = "/password/reset";
    MethodPost(url, data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Contraseña Actualizada",
          text: res.data.message,
        });
        dispatch({
          type: RESET_PASSWORD,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.error,
        });
      });
  };

  return (
    <ResetPasswordsContext.Provider
      value={{
        sendLink: state.sendLink,
        setUserPassword: state.setUserPassword,
        sendResetLinkEmail,
        resetPassword,
      }}
    >
      {props.children}
    </ResetPasswordsContext.Provider>
  );
};

export default ResetPasswordsState;