import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import { Button, Hidden, Tooltip } from "@material-ui/core";
import MethodGet from "../../../Config/Services";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    padding: "16px",
    overflow: "hidden",
    flexDirection: "column",
  },
  typography: {
    fontSize: "20px",
    fontFamily: "Roboto",
    lineHeight: "1.6",
    marginBottom: "20px",
    marginTop: "20px",
    marginLeft: "0px",
    marginRight: "0px",
    color: "#1976d2",
  },
}));

const TableRecentComissionsBranch = ({ id }) => {
  const classes = useStyles();
  const [sales, saveSales] = useState([]);

  useEffect(() => {
    const QueryAPI = async () => {
      let url = `latestSalesBranch/${id}`;
      MethodGet(url).then((response) => {
        saveSales(response.data.data);
      });
    };
    QueryAPI();
  }, []);

  return (
    <Fragment>
      <Hidden only={["xs", "sm"]}>
        <Grid item xs={12} md={12} lg={12}>
          <MaterialTable
            title={"Ventas De Este Mes"}
            columns={[
              { title: "Fecha Venta", field: "fechaVenta" },
              { title: "Folio", field: "folio" },
              {
                title: "Nombre del Cliente",
                render: (rowData) => {
                  return (
                    <Fragment>
                      <p>
                        {rowData.client.name +
                          " " +
                          rowData.client.first_lastname +
                          " " +
                          rowData.client.second_lastname}
                      </p>{" "}
                    </Fragment>
                  );
                },
              },
              {
                title: "Total",
                field: "total",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.total),
              },
              {
                title: "Comisión",
                field: "comission",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.comission),
              },
            ]}
            data={sales}
            options={{
              headerStyle: {
                backgroundColor: "indigo",
                color: "#FFF",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: "search",
                tooltip: "Detalle de venta",
                onClick: (rowData) =>
                  (window.location.href = `/DetalleVenta/${rowData.id}`),
              },
            ]}
            components={{
              Action: (props) => (
                <Fragment>
                  <Link
                    to={`/DetalleVenta/${props.data.id}`}
                    style={{ color: "#3f51b5" }}
                  >
                    <Button style={{ textTransform: "none" }} size="small">
                      <Tooltip
                        title="Detalle de venta"
                        aria-label="Detalle de venta"
                      >
                        <VisibilityIcon style={{ color: "#01579b" }} />
                      </Tooltip>
                    </Button>
                  </Link>
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultíma Página",
              },
              toolbar: {
                searchTooltip: "Buscar ",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage:
                  "No hay ventas registradas durante este mes",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid item xs={12} md={12} lg={12}>
          <MaterialTable
            title={"V. Mes"}
            columns={[
              { title: "Fecha Venta", field: "fechaVenta" },
              { title: "Folio", field: "folio" },
            ]}
            data={sales}
            options={{
              headerStyle: {
                backgroundColor: "indigo",
                color: "#FFF",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>
                        Nombre del cliente:{" "}
                        {
                          <Fragment>
                            <>
                              {rowData.client.name +
                                " " +
                                rowData.client.first_lastname +
                                " " +
                                rowData.client.second_lastname}
                            </>{" "}
                          </Fragment>
                        }
                      </p>
                      <p>Total: ${rowData.total}</p>
                      <p>Comisión: $ {rowData.comission}</p>
                      <p>
                        Opciones:
                        {
                          <Fragment>
                            <Link
                              to={`/DetalleVenta/${rowData.id}`}
                              style={{ color: "#3f51b5" }}
                            >
                              <Button
                                style={{ textTransform: "none" }}
                                size="small"
                              >
                                <Tooltip
                                  title="Detalle de venta"
                                  aria-label="Detalle de venta"
                                >
                                  <VisibilityIcon
                                    style={{ color: "#01579b" }}
                                  />
                                </Tooltip>
                              </Button>
                            </Link>
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultíma Página",
              },
              toolbar: {
                searchTooltip: "Buscar ",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage:
                  "No hay ventas registradas durante este mes",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </Fragment>
  );
};

export default TableRecentComissionsBranch;
