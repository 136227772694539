import React, {useState, useEffect, Fragment, useRef, useContext} from 'react';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import BankAccountsContext from '../../Reducers/BankAccounts/BankAccountsContext';
import { useForm , Controller } from "react-hook-form";
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';

export default function SelectBankAccounts(props) {
    /**Extraer los proveedores del state inicial */
    const BankAccountsList = useContext(BankAccountsContext);
    const { accounts, GetBankAccounts, success } = BankAccountsList;
    const { register ,  errors , handleSubmit , control } = useForm();
    const BankAccountRef = useRef();
	const [error, guardarError] = useState(true);
    
      useEffect(() => {
          GetBankAccounts(); 
          //eslint-disable-next-line
        }, [success])
    
    const newArrayBankAccount = accounts.map(bank=>{
        const name = `${bank.owner_name} - ${bank.account_number}`;
        return {value:bank.id,label: name}
      });

	const detectarCambiosBancos = (e) => {
		if (e.target.value) {
			guardarError(false);
		}
		props.detectarCambiosBancos(e);
	};

	return (
		<Fragment>
			<InputLabel fullWidth>Cuentas Bancarias*</InputLabel>
			{props.bank_account ? (
				<Select
					label="Bancos"
					fullWidth
					onChange={detectarCambiosBancos}
					value={props.bank_account}
				>
					<MenuItem disabled>Selecciona La cuenta bancaria</MenuItem>
					{accounts.map((account) => (
						<MenuItem key={account.id} value={account.id}>
							{account.owner_name} - {account.account_number}
						</MenuItem>
					))}
				</Select>
			) : (
                <Select label="Bancos" fullWidth onChange={detectarCambiosBancos}>
					<MenuItem disabled>Selecciona La cuenta bancaria</MenuItem>
					{accounts.map((account) => (
						<MenuItem key={account.id} value={account.id}>
							{account.owner_name} - {account.account_number}
						</MenuItem>
					))}
				</Select>
			)}

            {error ? <Alert severity="error">La cuenta bancaria es un campo requerido</Alert> : null}

		</Fragment>
	);
}
