import React, { useEffect, useState, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import AuthContext from "../../Context/autenticacion/authContext";
import ProductInfo from "./ProductInfo";
import ProductsList from "./ProductsList";
import Button from "@material-ui/core/Button";
import MethodGet from "../../Config/Services";
import { useForm } from "react-hook-form";
import SelectUser from "../SelectOptions/SelectUser";
import useSelectProducts from "../SelectOptions/SelectProducts";
import ComponentsBarcode from "./ComponentsBarcode";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import headerConfig from "../../Config/imageHeaders";
import DeparturesContext from "../../Reducers/Departures/DeparturesContext";

import { Grid, Box, Hidden } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import { makeStyles } from "@material-ui/core/styles";

/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
import { MethodPost } from "../../Config/Services";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingRight: theme.spacing(2),
  },
  typography: {
    paddingTop: theme.spacing(7),
    //margin: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  TableRow: {
    background: "Orange",
  },
  h2: {
    background: "#394ED1",
    color: "white",
  },
}));

function SalesAddProduct2(props) {
  const classes = useStyles();
  const { id } = props;
  const [productoID, guardarProductoID] = useState("");
  const [typeProduct, saveTypeProduct] = useState();
  const [clienteID, guardarClienteID] = useState("");
  const [ventas, guardarVenta] = useState([]);
  const [product, SelectProduct] = useSelectProducts({
    stateInicial: "",
    branchID: id,
  });
  const [seller, saveSeller] = useState({
    seller_id: "",
  });
  //console.log(product,seller)
  const [subtotal, SaveSubTotal] = useState(0);

  const detectarCambiosUser = (e) => {
    saveSeller({ seller_id: e.target.value });
  };
  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const formDeparturesContext = useContext(DeparturesContext);
  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  const { success, AddDeparture } = formDeparturesContext;
  const [sub, guardarTotal] = useState(0);
  const [Desc, SaveDesc] = useState(0);
  const [Cantdesc, SaveCantdesc] = useState(0);

  const [branch, guardarBranch] = useState({});
  //Extraer valores de branch(destructuracion)
  const { name } = branch;

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de salones
    if (success) {
      //console.log("Retorna");
      props.history.push(`/Salidas/Productos/` + id);
    }
    //eslint-disable-next-line
  }, [success]);

  const onSubmit = (data, e) => {
    e.preventDefault();

    var products_list = JSON.stringify(ventas);

    data.branch_office_id = id;
    data.products_list = products_list;
    data.descuento = Desc;
    data.porcentaje = Cantdesc;


    AddDeparture(data);
  };
  //console.log(subtotal);
  useEffect(() => {
    let totalSubTotal =
      Number(subtotal)
    guardarTotal(totalSubTotal);
  }, [subtotal]);
  return (
    <LayoutDashboard>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
        noValidate
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 80,
            borderRadius: 15,
            paddingLeft: 25,
            paddingRight: 25,
            marginBottom: 20,
          }}
        >
          <Typography component="h4" variant="h4" align="center">
            Nueva Salida de Productos de la Sucursal {name}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item md={9}>
            <Grid item>
              <ProductsList ventas={ventas}
                savesubtotal={SaveSubTotal}
                guardarVenta={guardarVenta}
                subtotal={subtotal}
                Desc={Desc}
                SaveDesc={SaveDesc}
                SaveCantdesc={SaveCantdesc}
                Cantdesc={Cantdesc} />
            </Grid>
            <Grid item xs={12} justify="center" style={{ marginTop: 15 }}>
              {ventas.length === 0 ? (
                <Button
                  type="submit"
                  fullWidth
                  className={classes.submit}
                  variant="contained"
                  disabled
                  color="primary"
                  style={{ marginBottom: "2%" }}
                >
                  Debes Agregar Productos
                </Button>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: "2%" }}
                >
                  GUARDAR
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item md={3} spacing={2} style={{ padding: 10 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="authorized_person"
              label="Persona que autoriza"
              name="authorized_person"
              autoComplete="authorized_person"
              autoFocus
              error={!!errors.authorized_person}
              inputRef={register({
                required: {
                  value: true,
                  message:
                    "Introduce el nombre de la persona que autoriza la sálida",
                },
                minLength: {
                  value: 5,
                  message: "Minimo 5 caracteres",
                },
                maxLength: {
                  value: 255,
                  message: "Maximo 255 caracteres",
                },
              })}
            />
            <p>{errors?.authorized_person?.message}</p>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="destination_person"
              label="Persona que recibe los productos"
              name="destination_person"
              autoComplete="destination_person"
              autoFocus
              error={!!errors.destination_person}
              inputRef={register({
                required: {
                  value: true,
                  message:
                    "Introduce el nombre de la persona que recibe la sálida",
                },
                minLength: {
                  value: 5,
                  message: "Minimo 5 caracteres",
                },
                maxLength: {
                  value: 255,
                  message: "Maximo 255 caracteres",
                },
              })}
            />
            <p>{errors?.destination_person?.message}</p>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="description"
              label="Descripción de salida de productos"
              name="description"
              autoComplete="description"
              autoFocus
              error={!!errors.description}
              inputRef={register({
                required: {
                  value: true,
                  message: "La Descripción es requerida",
                },
                minLength: {
                  value: 5,
                  message: "Minimo 5 caracteres",
                },
                maxLength: {
                  value: 255,
                  message: "Maximo 255 caracteres",
                },
              })}
            />
            <p>{errors?.description?.message}</p>
            <ComponentsBarcode
              ventas={ventas}
              productoID={product.value}
              saveTypeProduct={saveTypeProduct}
              guardarProductoID={guardarProductoID}
              typeProduct={typeProduct}
              guardarVenta={guardarVenta}
              id={id}
            />
            <SelectProduct id={id} />
            <Grid item md={12}>
              <ProductInfo
                productoID={product.value}
                clienteID={seller.seller_id}
                ventas={ventas}
                guardarVenta={guardarVenta}
                guardarProductoID={guardarProductoID}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </LayoutDashboard>
  );
}

export default SalesAddProduct2;
