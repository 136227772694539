import React, { useEffect, useContext } from "react";
import clsx from "clsx";
import {
  makeStyles,
  fade,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ListItems from "./listItems";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AuthContext from "../../Context/autenticacion/authContext";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import {
  Box,
  Button,
  Grid,
  Avatar,
  Hidden,
  Paper,
  ButtonBase,
  Tooltip,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import { lightBlue } from "@material-ui/core/colors";
import WapizimaNewLogo from "../../Complements/Image/Dashboard/WapizimaNewLogo.png";
import EditIcon from "@material-ui/icons/Edit";
import ImageMenu from "../../Complements/Image/Dashboard/ImageMenu.png";
import LockIcon from "@material-ui/icons/Lock";
import Badge from "@material-ui/core/Badge";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex", //puede que esta no vaya
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
    background: "linear-gradient(50deg, #ce93d8 33%, #e1bee7 100%)",
    //background: "#9966cc",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 10px",
    ...theme.mixins.toolbar,
  },
  toolbarImage: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "0 410px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    //marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(4),
  },

  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  /*appBarSpacer: theme.mixins.toolbar*/

  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  paperDash: {
    padding: theme.spacing(2),
    display: "fixed",
    maxWidth: "900px",
    maxHeight: "50px",
    marginRight: "2%",
    border: "none",
    backgroundColor: "red",
    color: "white",
    textDecoration: "none",
    outline: "none",
    boxShadow: "none",
    fontSize: "large",
  },
  fixedHeight: {
    height: 240,
  },
  avatalarge: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginLeft: "10px",
    marginRight: "15px",
  },

  //  search: {
  //    position: 'relative',
  //    borderRadius: theme.shape.borderRadius,
  //    backgroundColor: fade(theme.palette.common.white, 0.15),
  //    '&:hover': {
  //      backgroundColor: fade(theme.palette.common.white, 0.25),
  //    },
  //    marginRight: theme.spacing(2),
  //    marginLeft: 0,
  //    width: '100%',
  //    [theme.breakpoints.up('sm')]: {
  //      marginLeft: theme.spacing(3),
  //      width: 'auto',
  //    },
  //  },
  //  searchIcon: {
  //    padding: theme.spacing(0, 2),
  //    height: '100%',
  //    position: 'absolute',
  //    pointerEvents: 'none',
  //    display: 'flex',
  //    alignItems: 'center',
  //    justifyContent: 'center',
  //  },
  //  inputRoot: {
  //    color: 'inherit',
  //  },
  //  inputInput: {
  //    padding: theme.spacing(1, 1, 1, 0),
  //    // vertical padding + font size from searchIcon
  //    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  //    transition: theme.transitions.create('width'),
  //    width: '100%',
  //    [theme.breakpoints.up('md')]: {
  //      width: '20ch',
  //    },
  //  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    outline: "0",
    boxShadow: theme.shadows[5],
  },
  paperModal: {
    backgroundImage: `url('https://i.pinimg.com/originals/61/54/a1/6154a13b5a3e7c9bff550b0aa880ff2b.gif')`,
    //backgroundImage:`url('https://i.pinimg.com/originals/27/94/7a/27947ac14af627677a43510cf7ccfe70.gif')`,
    maxWidth: "500px",
    maxHeight: "600px",
    margin: "auto",
    textAlign: "center",
    fontFamily: "arial",
    backgroundColor: "#ffffff",
    borderRadius: "2px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  titleuser: {
    color: "black",
    fontSize: "1.6rem",
    marginTop: "-9%",
  },
  buttoncerrar: {
    border: "none",
    outline: "0",
    display: "inline-block",
    padding: "10px",
    color: "white",
    backgroundColor: "#4b0082",
    textAlign: "center",
    cursor: "pointer",
    width: "100%",
    fontSize: "1.2rem",
  },
  datauser: {
    textDecoration: "none",
    color: "black",
    fontSize: "1.1rem",
  },
  orange: {
    color: theme.palette.getContrastText(lightBlue[50]),
    backgroundColor: lightBlue[50],
    position: "abolute",
    marginTop: "8px",
  },
  Dashnameuser: {
    fontSize: "13pt",
    position: "fixed",
    display: "inline-block",
  },
  papercardtitle: {
    background: "#4b0082",
    //background: '#9966cc',
    padding: 7,
    fontSize: "21pt",
    color: "white",
  },
  imageModal: {
    width: 340,
    height: 340,
  },
  imgModal: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    width: 310,
    height: 310,
  },
  papercard: {
    maxHeight: "90%",
  },
  textCardMenu: {
    color: "white",
    fontSize: "13pt",
  },
  avatarLarge: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  avatarLargeSpacing: {
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(0.2),
  },
  textformatdata: {
    marginTop: theme.spacing(0.6),
  },
  blackStyle: {
    height: "100%",
    //backgroundColor:'#00000036',
  },
  StyleCArd: {
    maxWidth: 480,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatarContainer: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "none",
    position: "initial",
    paddingLeft: 10,
    paddingTop: 5,
    maxWidth: 315,
    height: "100%",
    maxHeight: "100%",
    display: "block",
    //backgroundColor:'indigo',
    //backgroundImage: `url("https://i.pinimg.com/564x/ae/ce/a1/aecea1d21e166108a64e4cfdfdce55c6.jpg")` ,
    //backgroundImage: `url("https://wallpaperaccess.com/full/3779451.jpg")` ,
    //backgroundImage: `url("https://i.pinimg.com/originals/f3/0f/9f/f30f9fff7d606d4f46d3c4cba57362f1.jpg")` ,
    //opacity: 0.9,
    //backgroundImage: `url("http://www.newsshare.in/wp-content/uploads/2017/05/Nature-Wallpaper-iPhone-3.jpg")` ,
    //backgroundImage: `url("https://i.pinimg.com/originals/a0/6d/a3/a06da3527953e19733b63e097550651a.jpg")` ,
    //backgroundImage: `url("https://i.pinimg.com/474x/d6/54/75/d6547570f7ce600c6bdbc0a6fb34f633.jpg")` ,
    //backgroundColor:'linear-gradient(to bottom right, red, yellow)',
    background: "linear-gradient(55deg, indigo 33%, #3f51b5 100%)",
    backgroundAttachment: "fixed",
    borderRadius: "none",
    backgroundSize: "300px 100%",
    linearGradient: "rgba(0, 0, 0, 0.9)",
    WebkitAnimation: "AnimationName 5s ease infinite",
    animation: "AnimationName 5s ease infinite",
  },
})((props) => (
  <Menu
    style={{
      position: "absolute",
      display: "block",
      alignSelf: "flex-start",
    }}
    elevation={-9}
    getContentAnchorElFF={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "flex-start",
    }}
    transformOrigin={{
      vertical: "flex-start",
      horizontal: "flex-start",
    }}
    {...props}
  />
));

export default function Dashboard() {
  const classes = useStyles();

  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario, cerrarSesion } = authContext;

  //let SchoolStorageID = localStorage.getItem('school');
  //console.log(SchoolStorageID)

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleOpenModal}>Mi cuenta</MenuItem>
      <MenuItem onClick={() => cerrarSesion()}>Cerrar sesión</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton></IconButton>
      </MenuItem>
    </Menu>
  );

  //const openCards = Boolean(anchorEl);
  const [openCards, setOpenCard] = React.useState(null);

  const cardStatusOpen = () => {
    setOpenCard(true);
  };
  const cardStatusClose = () => {
    //setOpenCard(false);
    setOpenCard(null);
  };

  const [anchorElFF, setAnchorElFF] = React.useState(null);

  const MenuDRMClick = (event) => {
    setAnchorElFF(event.currentTarget);
  };

  const MenuDRMClose = () => {
    setAnchorElFF(null);
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "$ripple 1.0s infinite ease-in-out",
        border: "2px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.6)",
        opacity: 0,
      },
    },
  }))(Badge);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <Grid item>
            <IconButton
              style={{ color: "white" }}
              variant="contained"
              onClick={MenuDRMClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Grid>
              <StyledMenu
                anchorElFF={anchorElFF}
                open={Boolean(anchorElFF)}
                onClose={MenuDRMClose}
                style={{ marginLeft: -17, marginTop: -17 }}
              >
                <Grid className={classes.blackStyle}>
                  <Grid>
                    <Paper
                      style={{
                        width: "100%",
                        maxWidth: 310,
                        height: 150,
                        maxHeight: 150,
                        //backgroundColor:'#4acbd5',
                        paddingLeft: 20,
                        paddingTop: 15,
                        marginTop: -2,
                        //backgroundColor:'transparent',
                        //backgroundColor:'#00000012',
                        backgroundImage: `url("https://images8.alphacoders.com/416/416272.jpg")`,
                        backgroundRepeat: "no-repeat",
                        borderRadius: "none",
                        backgroundSize: "cover",
                      }}
                    >
                      <Grid container>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={3}
                            className={classes.avatarLargeSpacing}
                          >
                            <Avatar
                              src={usuario ? usuario.image : `No Encontrado`}
                              className={classes.avatarLarge}
                            />
                          </Grid>
                          <Grid item xs={8} className={classes.textformatdata}>
                            <Typography
                              className={classes.textCardMenu}
                              color="textPrimary"
                              variant="h5"
                            >
                              {usuario ? usuario.type_user : `No Encontrado`}
                            </Typography>
                            <Typography
                              className={classes.textCardMenu}
                              color="textSecondary"
                              variant="h5"
                            >
                              {usuario ? usuario.branch : `No Encontrado`}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container Justify="center">
                          <Grid item xs={12} Justify="center">
                            <Typography
                              color="primary"
                              variant="h6"
                              style={{
                                paddingTop: 15,
                                color: "white",
                                fontSize: "14pt",
                                whiteSpace: "nowrap",
                                overflow: "auto",
                              }}
                            >
                              {usuario ? usuario.name : `No Encontrado`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid>
                    <ListItems />
                  </Grid>
                </Grid>
              </StyledMenu>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            justify="flex-end"
            alignItems="center"
            style={{ marginLeft: "40%" }}
          >
            <Hidden only={["sm", "xs"]}>
              <Grid
                container
                direction="row"
                Justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid spacing={2}>
                  <img
                    src={require("../../assets/img/merry.png")}
                    width="100%"
                    height="65px"
                  />
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <Grid
            xs={12}
            lg={12}
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Hidden only={["xs"]}>
              <Grid noWrap>
                <Typography variant="subtitle1" component="subtitle1">
                  {usuario ? usuario.name : null}
                </Typography>
              </Grid>
            </Hidden>
            <Grid>
              <Avatar
                alt="User"
                src={usuario ? usuario.image : null}
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                className={classes.avatalarge}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div>
        <Modal
          disableEnforceFocus
          disableAutoFocus
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <Card className={classes.StyleCArd}>
              <CardHeader
                avatar={
                  <div className={classes.avatarContainer}>
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      variant="dot"
                    >
                      <Avatar
                        alt="Foto Usuario"
                        src={usuario ? usuario.image : null}
                      />
                    </StyledBadge>{" "}
                  </div>
                }
                action={
                  <Tooltip title="Cerrar">
                    <IconButton aria-label="Cerrar" onClick={handleCloseModal}>
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                }
                title={usuario ? usuario.name : null}
                subheader="Información de mi Cuenta."
              />
              <CardMedia
                className={classes.media}
                image={usuario ? usuario.image : null}
                title="Foto Usuario"
              />
              <CardContent style={{ marginBottom: "-15px" }}>
                <Typography paragraph variant="h5" color="textSecondary">
                  <b>Nombre: </b> {usuario ? usuario.name : null}
                </Typography>
                <Typography paragraph variant="h5" color="textSecondary">
                  <b>Tipo de Usuario: </b> {usuario ? usuario.type_user : null}
                </Typography>
              </CardContent>
              <CardActions disableSpacing style={{ marginTop: -5 }}>
                <Tooltip title="Cambiar Contraseña" href={"/EditarContraseña"}>
                  <IconButton aria-label="Cambiar Contraseña">
                    <LockOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Editar mi Cuenta" href={"/EditarPerfil"}>
                  <IconButton aria-label="Editar mi Cuenta">
                    <BorderColorOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent style={{ marginBottom: "-15px" }}>
                  <Typography paragraph variant="h5" color="textSecondary">
                    <b>Sucursal:</b> {usuario ? usuario.branch : null}
                  </Typography>
                  <Typography paragraph variant="h5" color="textSecondary">
                    <b>Email:</b> {usuario ? usuario.email : null}
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          </Fade>
        </Modal>
      </div>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
{
  /*
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          */
}
{
  /*
            <IconButton aria-label="show 3 new mails" color="inherit">
              <Badge badgeContent={3} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 21 new notifications" color="inherit">
              <Badge badgeContent={21} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            */
}
