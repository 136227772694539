import React, { useState, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  Dialog,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useForm } from "react-hook-form";
/**Importar funcion Barcode */
//import BarCode from "../../Complements/Barcode";
import headerConfig from "../../Config/imageHeaders";
import MethodGet, { MethodPost } from "../../Config/Services";
import Swal from "sweetalert2";
import Spinner from "../../Complements/Spinner";
import { withRouter } from "react-router-dom";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import SelectTypeProduct from "../SelectOptions/SelectTypeProduct";
/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
/**
 * identificar Codigo de FORMS
 */
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import ProductsContext from "../../Reducers/Products/ProductContext";
import { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function Editproduct({ modal, handleClose, idproduct }) {
  const classes = useStyles();

  const [cargando, spinnerCargando] = useState(true);
  //Funcion para validaciones
  const { register, handleSubmit, errors, setValue } = useForm();
  // const [operator, setOperator] = useState({});
  const [product, guardarProduct] = useState({});
  const [type_product, setTypeProduct] = useState(null);
  const detectarCambiosTypeProduct = (e) => {
    setTypeProduct(2);
  };
  //Trabajar con el state de office
  const [branch, saveBranch] = useState(null);
  const detectarCambiosBranch = (e) => {
    saveBranch(e.target.value);
  };
  const formClassroomContext = useContext(ProductsContext);

  const { ErrorsAPI, success, EditProduct } = formClassroomContext;

  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  useEffect(() => {
    let url = `/products/${idproduct}`;
    MethodGet(url)
      .then((res) => {
        guardarProduct(res.data.data);
        saveBranch(res.data.data.id_branch_office);
        setTypeProduct(res.data.data.type);
        if (res.data.data.image) {
          setImage({ urlPhoto: res.data.data.image, image: "" });
        }
        spinnerCargando(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [idproduct]);


  // const onSubmit = (data, e) => {
  //   e.preventDefault();
  //   data.school_id = officeId.id_branch_office;
  //   data.id = id;
  //   //console.log(data);
  //   if (officeId.id_branch_office !== "") {
  //     //enviamos la data a la funcion que se encarga de guardar los registros editados
  //     UpdateClassroom(data);
  //   } else {
  //     Swal.fire({
  //       title: "Error",
  //       text: "Todos los campos son Obligatorios",
  //       icon: "error",
  //     });
  //   }
  // };

  const onSubmit = (data, e) => {
    e.preventDefault();
    data.type = 2;
    data.id_branch_office = branch;
    data.idproduct = idproduct;
    data.image = image.image;
    //console.log(data);
    if (data.id_branch_office !== "") {
      //enviamos la data a la funcion que se encarga de guardar los registros editados
      EditProduct(data);
    } else {
      Swal.fire({
        title: "Error",
        text: "Todos los campos son Obligatorios",
        icon: "error",
      });
    }
 
    handleClose();

  };


  const { tag, name, description, price, price_purchase, quantity } = product;
  useEffect(() => {
    if (product) {
      saveBranch(product.id_branch_office);
      setValue("tag", product.tag);
      setValue("name", product.name);
      setValue("price_purchase", product.price_purchase);
      setValue("price", product.price);
      setValue("description", product.description);
      setValue("quantity", product.quantity);
      setTypeProduct(product.type);
    } else {
      setValue("tag", "");
      setValue("name", "");
      setValue("price_purchase", "");
      setValue("price", "");
      setValue("desciption", "");
      setValue("quantity", "");
      saveBranch(null);
      setTypeProduct(null);
    }
  }, [product]);
  return (
    <div>
      <Dialog
        onClose={() => {
          handleClose();
          guardarProduct({});
        }}
        aria-labelledby="customized-dialog-title"
        open={modal}
        maxWidth={1200}
        fullWidth
      >
        {cargando ? (
          <Spinner />
        ) : (
          <div>
            {" "}
            <DialogTitle
              id="customized-dialog-title"
              style={{
                textAlign: "center",
                background: "#3F51B5",
                color: "white",
              }}
              onClose={() => {
                handleClose();
                guardarProduct({});
              }}
            >
              Editar Producto
            </DialogTitle>
            <DialogContent dividers>
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Grid container justifyContent="center" alignItems="center">
                  {/**
                   * identificar Codigo de FORMS
                   */}
                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <CardBody>
                        <Grid container spacing={2}>
                          {branch && (
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              style={{ paddingTop: 16 }}
                            >
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <SelectBranchOffice
                                  officeId={branch}
                                  detectarCambiosOffice={detectarCambiosBranch}
                                />
                              </FormControl>
                            </Grid>
                          )}

                          <Grid item xs={12} sm={4}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="tag"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Clave"
                              name="tag"
                              autoComplete="tag"
                              autoFocus
                              defaultValue={tag}
                              error={!!errors.tag}
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: "La Clave es requerida",
                                },
                                minLength: {
                                  value: 1,
                                  message: "Minimo 1 caracteres",
                                },
                                maxLength: {
                                  value: 45,
                                  message: "Maximo 45 caracteres",
                                },
                              })}
                            />
                            <p>{errors?.tag?.message}</p>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="name"
                              label="Nombre"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              name="name"
                              autoComplete="name"
                              autoFocus
                              defaultValue={name}
                              error={!!errors.name}
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: "El Nombre es requerido",
                                },
                                minLength: {
                                  value: 1,
                                  message: "Minimo 1 caracter",
                                },
                                maxLength: {
                                  value: 255,
                                  message: "Maximo 255 caracteres",
                                },
                              })}
                            />
                            <p>{errors?.name?.message}</p>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="price_purchase"
                              label="Precio Costo"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              name="price_purchase"
                              autoComplete="name"
                              autoFocus
                              defaultValue={price_purchase}
                              error={!!errors.price_purchase}
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: "El Precio Costo es requerido",
                                },
                                maxLength: {
                                  value: 10,
                                  message: "Maximo 10 caracteres",
                                },
                              })}
                            />
                            <p>{errors?.price_purchase?.message}</p>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="quantity"
                              label="Cantidad"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled
                              name="quantity"
                              type="number"
                              defaultValue={quantity}
                              autoFocus
                              error={!!errors.quantity}
                              inputRef={register({
                                maxLength: {
                                  value: 5,
                                  message: "Maximo 5 digitos",
                                },
                              })}
                            />
                            <p>{errors?.quantity?.message}</p>
                          </Grid>

                          <Grid item xs={12} sm={4}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="price"
                              label="Precio venta"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              name="price"
                              type="number"
                              autoFocus
                              defaultValue={price}
                              error={!!errors.price}
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: "El precio venta es requerido",
                                },
                                maxLength: {
                                  value: 10,
                                  message: "Maximo 10 caracteres",
                                },
                              })}
                            />
                            <p>{errors?.price?.message}</p>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="description"
                              label="Descripción"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              name="description"
                              autoComplete="name"
                              autoFocus
                              defaultValue={description}
                              error={!!errors.description}
                              inputRef={register({
                                required: {
                                  value: true,
                                  message: "La Descripción es requerido",
                                },
                                minLength: {
                                  value: 1,
                                  message: "Minimo 1 caracter",
                                },
                                maxLength: {
                                  value: 255,
                                  message: "Maximo 255 caracteres",
                                },
                              })}
                            />
                            <p>{errors?.description?.message}</p>
                          </Grid>
                          {/* {type_product && (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{ paddingTop: 16 }}
                            >
                              <FormControl
                                variant="outlined"
                                className={classes.formControl}
                              >
                                <SelectTypeProduct
                                  detectarCambiosTypeProduct={
                                    detectarCambiosTypeProduct
                                  }
                                  type_product={type_product}
                                />
                              </FormControl>
                            </Grid>
                          )} */}
                        </Grid>
                        <Card profile>
                          <CardAvatar profile>
                            <img
                              src={image.urlPhoto}
                              alt="User"
                              width="100%"
                              height="100%"
                            />
                          </CardAvatar>
                          <CardBody profile>
                            <input
                              className={classes.input}
                              id="icon-button-file"
                              type="file"
                              name="image"
                              accept="image/png, image/jpg, image/jpeg"
                              onChange={handleChangeImage}
                            />
                            <label htmlFor="icon-button-file">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <Tooltip
                                  title="seleccionar imagen"
                                  aria-label="seleccionar imagen"
                                >
                                  <PhotoCamera />
                                </Tooltip>
                              </IconButton>
                            </label>
                            <InputLabel>
                              Selecciona Imagen del Producto
                            </InputLabel>
                            <h2 className={classes.cardTitle}>
                              Editando Producto:{" "}
                            </h2>
                            <h3 className={classes.cardTitle}></h3>
                            <p className={classes.description}>
                              Recuerda que la cantidad de este producto puede
                              ser modificada desde otros modulos
                            </p>
                          </CardBody>
                        </Card>
                        <Grid container spacing={3} justify="center">
                          <Grid item spacing={3} xs={12} sm={12} md={6}>
                            <Button
                              type="submit"
                              fullWidth
                              variant="outlined"
                              className={classes.submit}
                            >
                              ACTUALIZAR
                            </Button>
                          </Grid>
                        </Grid>
                        {/**
                         * identificar Codigo de FORMS
                         */}
                      </CardBody>
                    </Card>
                  </GridItem>
                </Grid>
              </form>
            </DialogContent>
          </div>
        )}
      </Dialog>
    </div>
  );
}
export default withRouter(Editproduct);
