import React, { useState, useEffect, useContext } from "react";

import useSelectProducts from "../SelectOptions/SelectProducts";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  Fab,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

import { useDebounce } from "use-debounce";
import ProductContext from "../../Reducers/Products/ProductContext";
import InventoriesContext from "../../Reducers/Inventories/InventoriesContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import Swal from "sweetalert2";
import CropFreeIcon from "@material-ui/icons/CropFree";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(1),
  },
  InputInfoSales: {
    width: "94%",
  },
}));

const SelectsComponent = ({
  guardarProductoID,
  id,
  branch_office_id,
  guardarVenta,
  ventas,
  status_report,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [productID, SelectProduct] = useSelectProducts({
    stateInicial: "",
    branchID: id,
  });
  const [barcode, setBarCode] = useState("");
  const [debouncedBarcode] = useDebounce(barcode, 500);
  const ProductContextList = useContext(ProductContext);
  const InventoriesContextList = useContext(InventoriesContext);
  const { getSaleProducts, success, products } = ProductContextList;
  const { AddProductInventory } = InventoriesContextList;
  const classes = useStyles();

  //productos
  useEffect(() => {
    getSaleProducts(branch_office_id);
  }, [success, branch_office_id]);

  const handleChangeCodeBar = (event) => {
    setBarCode(event.target.value);
  };

  useEffect(() => {
    filterProductExist();
    // eslint-disable-next-line
  }, [debouncedBarcode]);

  const filterProductExist = () => {
    if (debouncedBarcode != "") {
      var product = [];
      product = products.filter((p) => p.tag == debouncedBarcode);
      //console.log(debouncedBarcode, 'el debounced xd')

      if (!product.length) {
        Swal.fire({
          title: "¡Lo sentimos!",
          text: "El Código de barras no coincide con ningún producto de la lista",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        setBarCode("");
        return;
      }

      product[0].value = product[0].id;
      guardarProductoID(product[0]);
      const position = ventas
        .map(function (e) {
          return e.product_id;
        })
        .indexOf(product[0].id);
      let productoResultado = {};
      productoResultado.description = product[0].description;
      productoResultado.image = product[0].image;
      productoResultado.name = product[0].name;
      productoResultado.price = product[0].price;
      productoResultado.price_purchase = product[0].price_purchase;
      productoResultado.quantity = product[0].quantity;
      productoResultado.tag = product[0].tag;
      productoResultado.value = product[0].value;
      productoResultado.category = product[0].category;
      if (position == -1) {
        //generar una llave unica para cada producto
        productoResultado.product_id = product[0].id;
        //generar cantidad productos por adquirir
        productoResultado.cantidad = 1;
        //generar precio final por producto
        productoResultado.final_price =
          productoResultado.cantidad * Number(product[0].price);
        //guardarlo en el state
        guardarVenta([productoResultado, ...ventas]);
      } else {
        let quantity_product = ventas[position].cantidad;
        var new_quantity_product = (quantity_product += 1);
        ventas[position].cantidad = new_quantity_product;
        ventas[position].final_price =
          ventas[position].cantidad * Number(product[0].price);
        guardarVenta([...ventas]);
      }
      var data = {};
      data.inventory_id = id;
      data.product_id = product[0].id;
      AddProductInventory(data);

      setBarCode("");
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ paddingTop: 5 }}
      className={classes.root}
    >
      {products.length != 0 ? (
        <>
          <Typography component="subtitle1" variant="h5" style={{marginBottom:10}}>
            {status_report !== 3
              ? ""
              : "Inventario Finalizado"}
          </Typography>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            {status_report !== 3 && (
              <>
                <TextField
                  placeholder="Usa el lector de codigos de barra"
                  variant="outlined"
                  required
                  id="barcode"
                  label="Código De Barras"
                  name="barcode"
                  value={barcode}
                  onChange={handleChangeCodeBar}
                  autoComplete="barcode"
                  autoFocus
                  error={!!errors.barcode}
                  className={classes.InputInfoSales}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "El código de barras es requerido",
                    },
                    minLength: {
                      value: 4,
                      message: "Minimo 4 caracteres",
                    },
                    maxLength: {
                      value: 45,
                      message: "Maximo 45 caracteres",
                    },
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CropFreeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <p>{errors?.barcode?.message}</p>{" "}
              </>
            )}
          </Grid>
        </>
      ) : (
        <p>Cargando</p>
      )}
    </Grid>
  );
};

export default SelectsComponent;
