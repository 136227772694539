/**Usu de Alertas */
export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';

/**Restaurar Contraseñas */
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

/**Login e Inicio de Sesión */
export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CERRAR_SESION = 'CERRAR_SESION';
export const GET_SCHOOL_ID = 'GET_SCHOOL_ID';
export const VERIFY_RECAPTCHA = 'VERIFY_RECAPTCHA';

/** Data Selects */
export const GET_STATES_SELECT = 'GET_STATES_SELECT';
export const GET_MUNICIPALITIES_SELECT = 'GET_MUNICIPALITIES_SELECT';

//Errors API
export const SHOW_ERRORS_API = 'SHOW_ERRORS_API';

/** Productos */
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const ADD_PRODUCTS_EXCEL = 'ADD_PRODUCTS_EXCEL';
export const UPDATE_PRODUCTS_EXCEL = 'UPDATE_PRODUCTS_EXCEL';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const GET_DATA_EXCEL_PRODUCTS = 'GET_DATA_EXCEL_PRODUCTS';
export const GET_DATA_EXCEL_PRODUCTS_SALE = 'GET_DATA_EXCEL_PRODUCTS_SALE';
export const GET_DATA_EXCEL_PRODUCTS_STOCK = 'GET_DATA_EXCEL_PRODUCTS_STOCK';


/** Inventarios */
export const GET_ALL_INVENTORIES = 'GET_ALL_INVENTORIES';
export const ADD_NEW_INVENTORY = 'ADD_NEW_INVENTORY';
export const CURRENT_INVENTORY = 'CURRENT_INVENTORY';
export const UPDATE_INVENTORY = 'UPDATE_INVENTORY';
export const DELETE_INVENTORY = 'DELETE_INVENTORY';
export const ADD_PRODUCT_INVENTORY = 'ADD_PRODUCT_INVENTORY';
export const GET_ALL_PRODUCTS_INVENTORIES = 'GET_ALL_PRODUCTS_INVENTORIES';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';

/** Gastos */
export const GET_ALL_SCHOOL_EXPENSES = 'GET_ALL_SCHOOL_EXPENSES';
export const ADD_NEW_SCHOOL_EXPENSE = 'ADD_NEW_SCHOOL_EXPENSE';
export const CURRENT_SCHOOL_EXPENSE = 'CURRENT_SCHOOL_EXPENSE';
export const UPDATE_SCHOOL_EXPENSE = 'UPDATE_SCHOOL_EXPENSE';
export const DELETE_SCHOOL_EXPENSE = 'DELETE_SCHOOL_EXPENSE';

/** Salidas */
export const GET_ALL_BRANCH_DEPARTURES = 'GET_ALL_BRANCH_DEPARTURES';
export const GET_ALL_BRANCH_DEPARTURES_BRANCH = 'GET_ALL_BRANCH_DEPARTURES_BRANCH';
export const GET_ALL_BRANCH_DEPARTURES_PENDING = 'GET_ALL_BRANCH_DEPARTURES_PENDING';
export const SHOW_BRANCH_DEPARTURES_PENDING = 'SHOW_BRANCH_DEPARTURES_PENDING';
export const EDIT_DEPARTURES_PENDING = 'EDIT_DEPARTURES_PENDING';

export const GET_ALL_USER_DEPARTURES = 'GET_ALL_USER_DEPARTURES';
export const ADD_NEW_DEPARTURE = 'ADD_NEW_DEPARTURE';
export const ADD_NEW_DEPARTURE_BRANCH = 'ADD_NEW_DEPARTURE_BRANCH';
export const DELETE_PRODUCT_TRANSFER = 'DELETE_PRODUCT_TRANSFER';



/** Escuelas */
export const GET_ALL_SCHOOLS = 'GET_ALL_SCHOOLS';
export const ADD_NEW_SCHOOL = 'ADD_NEW_SCHOOL';
export const CURRENT_SCHOOL = 'CURRENT_SCHOOL';
export const UPDATE_SCHOOL = 'UPDATE_SCHOOL';
export const DELETE_SCHOOL = 'DELETE_SCHOOL';
export const UTILITY_SCHOOL = 'UTILITY_SCHOOL';
export const INCOME_SCHOOL = 'INCOME_SCHOOL';

/** Salones */
export const GET_ALL_CLASSROOMS = 'GET_ALL_CLASSROOMS';
export const ADD_NEW_CLASSROOM = 'ADD_NEW_CLASSROOM';
export const CURRENT_CLASSROOM = 'CURRENT_CLASSROOM';
export const GET_INFO_CLASSROOM = 'GET_INFO_CLASSROOM';
export const UPDATE_CLASSROOM = 'UPDATE_CLASSROOM';
export const DELETE_CLASSROOM = 'DELETE_CLASSROOM';

/** Cursos */
export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const ADD_NEW_COURSE = 'ADD_NEW_COURSE';
export const CURRENT_COURSE = 'CURRENT_COURSE';
export const GET_INFO_COURSE = 'GET_INFO_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const DELETE_COURSE = 'DELETE_COURSE';

/** Inscripciones */
export const GET_ALL_INDIVIDUAL_INSCRIPTIONS = 'GET_ALL_INDIVIDUAL_INSCRIPTIONS';
export const GET_ALL_GRADUATE_INSCRIPTIONS = 'GET_ALL_GRADUATE_INSCRIPTIONS';
export const ADD_NEW_INSCRIPTION = 'ADD_NEW_INSCRIPTION';
export const ADD_NEW_PAYMENT = 'ADD_NEW_PAYMENT';
export const CURRENT_INSCRIPTION = 'CURRENT_INSCRIPTION';
export const GET_INFO_INSCRIPTION = 'GET_INFO_INSCRIPTION';
export const UPDATE_INSCRIPTION = 'UPDATE_INSCRIPTION';
export const DELETE_INSCRIPTION = 'DELETE_INSCRIPTION';

/** Programacion De Cursos */
export const GET_ALL_SCHEDULES = 'GET_ALL_SCHEDULES';
export const GET_ALL_CLASSES = 'GET_ALL_CLASSES';
export const GET_ALL_SCHEDULES_STUDENTS = 'GET_ALL_SCHEDULES_STUDENTS';
export const ADD_NEW_SCHEDULE = 'ADD_NEW_SCHEDULE';
export const CURRENT_SCHEDULE = 'CURRENT_SCHEDULE';
export const GET_INFO_SCHEDULE = 'GET_INFO_SCHEDULE';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const UPDATE_CLASS = 'UPDATE_CLASS';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const GET_ALL_SCHEDULES_GRADUATES = 'GET_ALL_SCHEDULES_GRADUATES';
export const GET_ALL_OPENSCHEDULE = 'GET_ALL_OPENSCHEDULE';

/** Cuentas Bancarias */
export const GET_ALL_BANK_ACCOUNTS = 'GET_ALL_BANK_ACCOUNTS';
export const ADD_NEW_BANK_ACCOUNT = 'ADD_NEW_BANK_ACCOUNT';
export const CURRENT_BANK_ACCOUNT = 'CURRENT_BANK_ACCOUNT';
export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';

// categorias
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";
export const CURRENT_CATEGORY = "CURRENT_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

// Sub-categorias
export const GET_ALL_SUBCATEGORIES = "GET_ALL_SUBCATEGORIES";
export const ADD_NEW_SUBCATEGORY = "ADD_NEW_SUBCATEGORY";
export const CURRENT_SUBCATEGORY = "CURRENT_SUBCATEGORY";
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const GET_SUBCATEGORIES_CATEGORY = "GET_SUBCATEGORIES_CATEGORY";


/** Cupones Descuento */
export const GET_ALL_COUPONS = 'GET_ALL_COUPONS';
export const ADD_NEW_COUPON = 'ADD_NEW_COUPON';
export const CURRENT_COUPON = 'CURRENT_COUPON';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const DELETE_COUPON = 'DELETE_COUPON';
export const APPLY_COUPON = 'APPLY_COUPON';

/** Salida De Efectivo */
export const GET_ALL_CASH_OUTS = 'GET_ALL_CASH_OUTS';
export const ADD_NEW_CASH_OUT = 'ADD_NEW_CASH_OUT';
export const CURRENT_CASH_OUT = 'CURRENT_CASH_OUT';
export const GET_INFO_CASH_OUT = 'GET_INFO_CASH_OUT';

/** Clientes */
export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_ALL_STUDENTS = 'GET_ALL_STUDENTS';
export const GET_CLIENT_ALUMN = 'GET_CLIENT_ALUMN';
export const GET_CLIENT_MAYORIST = 'GET_CLIENT_MAYORIST';
export const GET_CLIENT_PUBLIC = 'GET_CLIENT_PUBLIC';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const GET_ALL_CLIENTS_SELLER = 'GET_ALL_CLIENTS_SELLER';
export const GET_ALL_TYPECLIENTS = 'GET_ALL_TYPECLIENTS';
export const CREATE_TYPECLIENT = 'CREATE_TYPECLIENT';
export const DELETE_TYPECLIENT = 'DELETE_TYPECLIENT';
export const UPDATE_TYPECLIENT = 'UPDATE_TYPECLIENT';
export const SHOW_TYPECLIENT = 'SHOW_TYPECLIENT';

