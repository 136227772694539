import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  Grid,
  TextField,
} from "@material-ui/core";
import GridItem from "../../Components/Grid/GridItem";
import MethodGet, { MethodPost } from "../../Config/Services";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ClientsContext from "../../Reducers/Clients/ClientsContext";
import { useContext } from "react";
import ClassroomContext from "../../Reducers/Classrooms/ClassroomContext";
import Spinner from "../../Complements/Spinner";
import CardHeader from "../../Components/Card/CardHeader";
import CardBody from "../../Components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ModalClassrooms({ modal, handleClose, id }) {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);
  const formClassroomContext = useContext(ClassroomContext);
  const { ErrorsAPI, success, AddClassroom } = formClassroomContext;
  const [school, saveSchool] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name } = school;

  //obtenemos la escuela de session storage
  let SchoolStorageID = JSON.parse(sessionStorage.getItem("school"));

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${SchoolStorageID}`;
    MethodGet(url)
      .then((res) => {
        saveSchool(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();
    data.school_id = SchoolStorageID;

    AddClassroom(data);
  };

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de salones
    if (success) {
      //console.log("Retorna");
      handleClose();
    }
    //eslint-disable-next-line
  }, [success]);

  if (!cargando) return <Spinner />;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modal}
        maxWidth={1200}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" style={{ textAlign: "center", background: "#3F51B5", color: "white", }}
          onClose={handleClose}>
          Agregar Nuevo Salon
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
              {/**
           * identificar Codigo de FORMS
           */}
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card>

                  <CardBody>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="name"
                          label="Nombre del Salon"
                          name="name"
                          autoComplete="name"
                          autoFocus
                          error={!!errors.name}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El Nombre del Salon es requerido",
                            },
                            minLength: {
                              value: 1,
                              message: "Minimo 5 caracteres",
                            },
                            maxLength: {
                              value: 45,
                              message: "Maximo 45 caracteres",
                            },
                          })}
                        />
                        <p>{errors?.name?.message}</p>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="capacity"
                          label="Capacidad del Salon"
                          name="capacity"
                          type="number"
                          min="1"
                          max="45"
                          autoComplete="capacity"
                          autoFocus
                          error={!!errors.capacity}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "La Capacidad del Salon es requerida",
                            },
                            minLength: {
                              value: 1,
                              message: "Minimo 1 digito",
                            },
                            maxLength: {
                              value: 6,
                              message: "Maximo 6 digitos",
                            },
                          })}
                        />
                        <p>{errors?.capacity?.message}</p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} justify="center">
                      <Grid item spacing={3} xs={12} sm={12} md={6}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="outlined"
                          className={classes.submit}
                        >
                          GUARDAR
                        </Button>
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </GridItem>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default ModalClassrooms;
