import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import {
  Button,
  Tooltip,
  Box,
  Fab,
  Typography,
  Grid,
  Hidden,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { green } from "@material-ui/core/colors";
import ModalAdd from "../Branch_Offices/ModalAdd";
import ModalRemove from "../Branch_Offices/ModalRemove";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Icon from "@material-ui/icons/PictureAsPdf";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import FormControl from "@material-ui/core/FormControl";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import DateFnsUtils from "@date-io/date-fns";
import PrintIcon from "@material-ui/icons/Print";
import InventoriesContext from "../../Reducers/Inventories/InventoriesContext";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import AuthContext from "../../Context/autenticacion/authContext";

/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  Link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  Table: {
    background: "Orange",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  iconOptionblue: {
    color: "#0d47a1",
  },
  reportmodal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reportpaper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    width: "80%",
    height: "60%",
    padding: theme.spacing(2, 1, 1, 3),
  },
}));

const OfficeInventoriesAdmin = (props) => {
  const classes = useStyles();

  const [cargando, spinnerCargando] = useState(false);
  const [inventories, saveInventories] = useState([]);

  const [school, saveSchool] = useState({});

  const authContext = useContext(AuthContext);
  const { usuario, usuarioAutenticado } = authContext;

  useEffect(() => {
    usuarioAutenticado();
  }, []);

  //Extraer valores de branch(destructuracion)
  const { name } = school;

  const StateInventoriesContext = useContext(InventoriesContext);
  const { SaveCurrentInventory, AddInventory, success } =
    StateInventoriesContext;

  const SaveInventory = (inventory) => {
    AddInventory(inventory);
  };

  useEffect(() => {
    let url = "/inventories";
    MethodGet(url)
      .then((res) => {
        saveInventories(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [success]);

  const generarReporte = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Generar El Reporte De Inventario?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Generar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        //Cunsume API de Back-end en laravel para generar tiket de venta
        clienteAxios
          .get(`/inventory/report/${id}`, { responseType: "blob" })
          .then((response) => {
            fileDownload(response.data, "Reporte De Inventario.pdf");
          })
          .catch((error) => {
            if (error.response.status === 409) {
              Swal.fire({
                icon: "error",
                title: "Algo sucedio",
                text: "No se cuenta con suficiente información para generar el reporte",
              });
            }
          });
      }
    });
  };
  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* titulo */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 100,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            className={classes.typography}
          >
            {"Inventarios de mi sucursal"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            onClick={() => SaveInventory(usuario.id_branch_office)}
          >
            <Tooltip title="Iniciar Inventario" aria-label="Iniciar Inventario">
              <AddIcon size="large" />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginRight: 10, marginLeft: 10, marginTop: 20 }}
      >
        <MaterialTable
          title=""
          columns={[
            { title: "Fecha De Inicio", field: "fecha" },
            {
              title: "Fecha De Término",
              field: "end_date",
              render: (rowData) =>
                !rowData.end_date ? <p>No Finalizado</p> : rowData.fecha_end,
            },
            {
              title: "Estatus Actual",
              field: "status_report",
              render: (rowData) =>
                rowData.status_report == 1 ? (
                  <p>No Iniciado</p>
                ) : rowData.status_report == 2 ? (
                  <p>En proceso</p>
                ) : (
                  <p>Finalizado</p>
                ),
            },
          ]}
          data={inventories}
          options={{
            headerStyle: {
              backgroundColor: "none",
              color: "#000",
              fontSize: "16px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },

            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: "search",
              tooltip: "Detalle de inventario",
              onClick: (rowData) =>
                (window.location.href = `/Inventario/Productos/${rowData.id}`),
            },
          ]}
          components={{
            Action: (props) => (
              <Fragment>
                <Link
                  to={`/Inventario/Productos/${props.data.id}`}
                  style={{ color: "#3f51b5" }}
                >
                  <Button style={{ textTransform: "none" }} size="small">
                    <Tooltip
                      title="Detalle de inventario"
                      aria-label="Detalle de inventario"
                    >
                      <VisibilityIcon className={classes.iconOptionblue} />
                    </Tooltip>
                  </Button>
                </Link>
                <Button
                  style={{ textTransform: "none" }}
                  size="small"
                  onClick={() => generarReporte(props.data.id)}
                >
                  <Tooltip
                    title="Generar ticket de venta"
                    aria-label="Generar ticket de venta"
                  >
                    <PictureAsPdf style={{ color: "indigo" }} />
                  </Tooltip>
                </Button>
              </Fragment>
            ),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsSelect: "Columnas",
              firstTooltip: "Primera Pagina",
              previousTooltip: "Anterior",
              nextTooltip: "Siguiente",
              lastTooltip: "Ultima Página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Opciones",
            },
            body: {
              emptyDataSourceMessage: "No Hay Inventarios Que Mostrar",
              filterRow: {
                filterTooltip: "Buscar",
              },
            },
          }}
        />
      </Grid>
    </LayoutDashboard>
  );
};

export default OfficeInventoriesAdmin;
