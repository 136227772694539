import React, { useState, useEffect, Fragment } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table';
import { Button, Tooltip } from '@material-ui/core';
import ModalAdd from './ModalAdd';
import ModalRemove from './ModalRemove';
/**Importar metodo Get */
import MethodGet from '../../Config/Services';
/**importar spinner */
import Spinner from '../../Complements/Spinner';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	Link: {
		textDecoration: 'none',
		color: theme.palette.text.primary
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(18),
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
	},
}));

const OfficeStockSubA = (props) => {
	const classes = useStyles();

	//sucursales = state, saveStocks =funcion para guardar en el state;
    const [stocks, saveStocks] = useState([]);
    const { id } = props.match.params; //Se toma el ID de la Sucursal para mostrar sus stocks
	const [cargando, spinnerCargando] = useState(false);

	//abrirModal
	const [ modalAdd, abrirModalAdd ] = useState(false);
	const [ modalRemove, abrirModalRemove ] = useState(false);
    const [ stockid , editarStockId ] = useState('');
    
    const [branch, guardarBranch] = useState({});

    //Extraer valores de branch(destructuracion)
	const { name } = branch;

	const handleClickOpenRemove = (id) => {
		editarStockId(id);
		abrirModalRemove(true);
	  };

	const handleClickOpenAdd = (id) => {
		editarStockId(id);
		abrirModalAdd(true);
	  };
	
	  const handleCloseRemove = () => {
		abrirModalRemove(false);
	  };

	  const handleCloseAdd = () => {
		abrirModalAdd(false);
	  };

	  const [ actualizar , actualizarStock ] = useState(false);

	  const actualizarCambios = () =>{
		editarStockId('');
		actualizarStock(true);
	  }

	//query a la api
	useEffect(() => {
		let url = `/sucursalstocks/${id}`
		MethodGet(url)
			.then((res) => {
				saveStocks(res.data.data);
				spinnerCargando(true);
				actualizarStock(false);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
    }, [actualizar]);
    
    //obtener los campos de la sucursal
	useEffect(() => {
		let url = `/branches/${id}`;
		MethodGet(url)
			.then((res) => {
				guardarBranch(res.data.data);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
    }, []);
/*
	const deleteStock = (id) => {
		Swal.fire({
			title: '¿Estás seguro?',
			text: 'El Stock del Producto Seleccionado no se Prodrá Recuperar',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, Eliminar',
			cancelButtonText: 'No, Cancelar',
		}).then((result) => {
			if (result.value) {
				// eliminar en la rest api
				let url = `/stocks/${id}`;
				MethodDelete(url).then((res) => {
					if (res.status === 200) {
						const CambioIndexStocks = stocks.filter((stock) => stock.id !== id);
						saveStocks(CambioIndexStocks);
						Swal.fire(
							'Stock de Producto Eliminado',
							res.data.mensaje,
							'success'
						);
					}
				}).catch((error) => {
					Swal.fire({
						title: 'Error',
						text: error.response.data.error,
						icon: 'error',
					});
				});
			}
		});
	};
*/
	//  spinner de carga
	if (!cargando) return <Spinner />;

	return (
		<LayoutDashboard>
			<div className={classes.root}>
				<CssBaseline />
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Container className={classes.container}>
						<Grid Container spacing={3}>
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
							        <Box align="left">
							        	<Fab
							        		color="primary"
							        		aria-label="back"
							        		size="small"
							        		href="/Sucursales"
							        	>
											<Tooltip
												title="Regresar"
												aria-label="Regresar"
											>
							        			<ArrowBackIcon />
											</Tooltip>
							        	</Fab>
							        </Box>
                                </Grid>
                            </Grid>
							<MaterialTable
								title={"Stocks De La Sucursal " + name}
								columns={[
									{ title: 'Clave', field: 'Clave' },
									{ title: 'Producto', field: 'products_name' },
									{title: 'E', 
            						 field: 'quantity', 
            						 render: rowData =>{ 
            						     return (
            						                 <p className={classes.Link} style={{color:'blue'}}>
            						                     {rowData.quantity}
            						                 </p>
										 )}},
									{title:'Estatus', field: 'quantity', render: rowData =>{ return (
										<Fragment>
										  { rowData.quantity == 0 ? (<p style={{color:'white', background:'red'}} align="center">Productos Agotados</p>)
										  :
										  rowData.quantity <= 200 ? (<p style={{color:'white', background:'#ffc400'}} align="center">Productos Por Agotarse</p>)
										  :
										  rowData.quantity >= 201 ? (<p style={{color:'white', background:'#43a047'}} align="center">Productos Suficientes En Existencia</p>) 
										  : null
										}
										</Fragment>) } } 
								]}
								data={stocks}
								options={{
									headerStyle: {
										backgroundColor: 'orange',
										color: '#FFF',
									},

									actionsColumnIndex: -1,
                                }}
                                /*
								actions={[
									(rowData) => ({
										icon: 'add',
										tooltip: 'Agregar Existencias',
										onClick: () => handleClickOpenAdd(rowData.id),
									}),
									(rowData) => ({
										icon: 'remove',
										tooltip: 'Remover Existencias',
										onClick: () => handleClickOpenRemove(rowData.id),
									}),
								]}
								*/
                                /*
								localization={{
									pagination: {
										labelDisplayedRows: '{from} - {to} de {count}',
										labelRowsSelect: 'Columnas',
										firstTooltip: 'Primera Pagina',
										previousTooltip: 'Anterior',
										nextTooltip: 'Siguiente',
										lastTooltip: 'Ultima Página',
									},
									toolbar: {
										searchTooltip: 'Buscar',
										searchPlaceholder: 'Buscar',
									},
									header: {
										actions: 'Opciones',
									},
									body: {
										emptyDataSourceMessage:
											'No Hay Stock de productos que Mostrar',
										filterRow: {
											filterTooltip: 'Buscar',
										},
									},
                                }}
                                */
							/>
						</Grid>
					</Container>
				</main>
			</div>
			<ModalAdd
               modal = {modalAdd}
               handleClickOpen = {handleClickOpenAdd }
               handleClose = {handleCloseAdd }
               stockid = {stockid}
               actualizarCambios = {actualizarCambios}
             />

			<ModalRemove
               modal = {modalRemove}
               handleClickOpen = {handleClickOpenRemove }
               handleClose = {handleCloseRemove }
               stockid = {stockid}
               actualizarCambios = {actualizarCambios}
             />
		</LayoutDashboard>
	);
};

export default OfficeStockSubA;
