import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import Spinner from "../../../Complements/Spinner";
///Importacion de TABS
import "react-tabs/style/react-tabs.css";

import MethodGet, { MethodDelete } from "../../../Config/Services";
import { Button, Hidden, IconButton, Tooltip } from "@material-ui/core";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import fileDownload from "js-file-download";
import clienteAxios from "../../../Config/Axios";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  table: {
    minWidth: 650,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  iconOptionblue: {
    color: "#0d47a1",
  },
}));

function TableCanceledSalesAdmin(props) {
  const classes = useStyles();

  const { id } = props;
  const [sales, saveSale] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  const [error, guardarError] = useState(false);

  useEffect(() => {
    let url = `/canceled-sales/${id}`;
    MethodGet(url)
      .then((res) => {
        saveSale(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  const generarTicket = (id, folio) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Generar El Ticket De Venta?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Generar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        //Cunsume API de Back-end en laravel para generar tiket de venta
        clienteAxios
          .get(`/ticket/${id}`, { responseType: "blob" })
          .then((response) => {
            fileDownload(response.data, "Ticket Folio " + folio + ".pdf");
            guardarError(false);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              Swal.fire({
                icon: "error",
                title: "Algo sucedio",
                text: "No se cuenta con suficiente información para generar el ticket",
              });
            } else if (error.response.status === 422) {
              guardarError(true);
            }
          });
      }
    });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <>
          <Hidden only={["xs", "sm"]}>

      <MaterialTable
        title=""
        columns={[
          { title: "Folio", field: "folio" },
          { title: "Nombre del Cliente", field: "full_name" },
          { title: "Vendedor", field: "seller" },
          {
            title: "Total",
            field: "total",
            render: (rowData) =>
              new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
                minimumFractionDigits: 0,
              }).format(rowData.total),
          },
          {
            title: "Total Pagado",
            field: "total_paid",
            render: (rowData) =>
              new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
                minimumFractionDigits: 0,
              }).format(rowData.total_paid),
          },
          {
            title: "Cambio",
            field: "change",
            render: (rowData) => {
              return (
                <Fragment>
                  {rowData.change ? "$" + rowData.change : <p>No Aplica</p>}
                </Fragment>
              );
            },
          },
          {
            title: "Cupón",
            field: "coupon",
            render: (rowData) => {
              return (
                <Fragment>
                  {rowData.coupon ? rowData.coupon.code : <p>No Aplica</p>}
                </Fragment>
              );
            },
          },
        ]}
        data={sales}
        options={{
          headerStyle: {
            backgroundColor: "none",
            color: "#000",
            fontSize: "16px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          },

          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: "search",
            tooltip: "Detalle de venta",
            onClick: (rowData) =>
              (window.location.href = `/DetalleVenta/${rowData.id}`),
          },
        ]}
        components={{
          Action: (props) => (
            <Fragment>
              <Link
                to={`/DetalleVenta/${props.data.id}`}
                style={{ color: "#3f51b5" }}
              >
                <Button style={{ textTransform: "none" }} size="small">
                  <Tooltip
                    title="Detalle de venta"
                    aria-label="Detalle de venta"
                  >
                    <VisibilityIcon className={classes.iconOptionblue} />
                  </Tooltip>
                </Button>
              </Link>
              <Button
                style={{ textTransform: "none" }}
                size="small"
                onClick={() => generarTicket(props.data.id, props.data.folio)}
              >
                <Tooltip
                  title="Generar ticket de venta"
                  aria-label="Generar ticket de venta"
                >
                  <PictureAsPdf style={{ color: "indigo" }} />
                </Tooltip>
              </Button>
            </Fragment>
          ),
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "Columnas",
            firstTooltip: "Primera Página",
            previousTooltip: "Anterior",
            nextTooltip: "Siguiente",
            lastTooltip: "Ultíma Página",
          },
          toolbar: {
            searchTooltip: "Buscar ",
            searchPlaceholder: "Buscar",
          },
          header: {
            actions: "Opciones",
          },
          body: {
            emptyDataSourceMessage: "No hay Ventas Canceladas que Mostrar",
            filterRow: {
              filterTooltip: "Buscar",
            },
          },
        }}
      />
      </Hidden>
      {/* separacion */}
      <Hidden only={["md", "lg", "xl"]}>

      <MaterialTable
        title=""
        columns={[
          { title: "Folio", field: "folio" },
          { title: "Nombre del Cliente", field: "full_name" },
        ]}
        data={sales}
        options={{
          headerStyle: {
            backgroundColor: "none",
            color: "#000",
            fontSize: "16px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          },

          actionsColumnIndex: -1,
        }}
        detailPanel={[
          {
            tooltip: "Show",
            render: (rowData) => {
              return (
                <div
                  style={{
                    color: "black",
                    marginLeft: 50,
                  }}
                >
                  <p>Vendedor: {rowData.seller}</p>
                  <p>
                    Total:{" "}
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(rowData.total)}
                  </p>
                  <p>
                    Total Pagado:{" "}
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(rowData.total_paid)}
                  </p>
                  <p>
                    Cambio:{" "}
                    {
                      <Fragment>
                        {rowData.change ? "$" + rowData.change : <>No Aplica</>}
                      </Fragment>
                    }
                  </p>
                  <p>
                    Cupón:{" "}
                    {
                      <Fragment>
                        {rowData.coupon ? rowData.coupon.code : <>No Aplica</>}
                      </Fragment>
                    }
                  </p>
                  <p>Opciones:</p>
                  <p>
                    {
                      <Fragment>
                        <Link
                          to={`/DetalleVenta/${rowData.id}`}
                          style={{ color: "#3f51b5" }}
                        >
                          <IconButton
                            style={{ textTransform: "none", marginLeft: 25 }}
                            size="small"
                          >
                            <Tooltip
                              title="Detalle de venta"
                              aria-label="Detalle de venta"
                            >
                              <VisibilityIcon
                                className={classes.iconOptionblue}
                              />
                            </Tooltip>
                          </IconButton>
                        </Link>
                        <IconButton
                          style={{ textTransform: "none", marginLeft: 60 }}
                          size="small"
                          onClick={() =>
                            generarTicket(rowData.id, rowData.folio)
                          }
                        >
                          <Tooltip
                            title="Generar ticket de venta"
                            aria-label="Generar ticket de venta"
                          >
                            <PictureAsPdf style={{ color: "indigo" }} />
                          </Tooltip>
                        </IconButton>
                      </Fragment>
                    }
                  </p>
                </div>
              );
            },
          },
        ]}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "Columnas",
            firstTooltip: "Primera Página",
            previousTooltip: "Anterior",
            nextTooltip: "Siguiente",
            lastTooltip: "Ultíma Página",
          },
          toolbar: {
            searchTooltip: "Buscar ",
            searchPlaceholder: "Buscar",
          },
          header: {
            actions: "Opciones",
          },
          body: {
            emptyDataSourceMessage: "No hay Ventas Canceladas que Mostrar",
            filterRow: {
              filterTooltip: "Buscar",
            },
          },
        }}
      />
      </Hidden>
    </>
  );
}

export default TableCanceledSalesAdmin;
