import { ADD_NEW_COURSE, CURRENT_COURSE, DELETE_COURSE, GET_ALL_COURSES,
    SHOW_ERRORS_API, UPDATE_COURSE, GET_INFO_COURSE } from "../../types";

export default function CoursesReducer(state, action) {

switch (action.type) {
case GET_ALL_COURSES: 
    return{
        ...state,
        courses: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case GET_INFO_COURSE:
    return{
        ...state,
        course: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case ADD_NEW_COURSE:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }

case SHOW_ERRORS_API:

    return{
        ...state,
        ErrorsAPI: action.payload
    }

case CURRENT_COURSE:

    //sessionStorage.setItem('selectedSchools',JSON.stringify(action.payload))
    return{
        ...state,
        course:action.payload,
    }

case UPDATE_COURSE:

    //sessionStorage.removeItem('selectedSchools');
    return{
        ...state,
        success: true,
        ErrorsAPI: []
    }

case DELETE_COURSE:

    return{
        ...state,
        courses: state.courses.filter(course => course.id !== action.payload)
    } 

default:
    return state; 
}
}