import React, { useReducer } from 'react';
import CashOutsContext from './CashOutsContext'
import CashOutsReducer from './CashOutsReducer';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../Config/Services";
import { GET_ALL_CASH_OUTS, ADD_NEW_CASH_OUT, CURRENT_CASH_OUT,
    GET_INFO_CASH_OUT } from "../../types";
import Swal from "sweetalert2";
import clienteAxios from '../../Config/Axios';
import fileDownload from 'js-file-download';

const CashOutsState = ({children}) => {
  // estado inicial
  const initialSate = {
    cash_outs: [],
    cash_out: null,
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(CashOutsReducer, initialSate);

  //obtener la lista de proveedores
  const GetCashOuts = (id) => {
    sessionStorage.removeItem('cash_out');
    let url = `school-cash-outs/${id}`;
    MethodGet(url).then((response) => {
        //console.log(response);
      dispatch({
        type: GET_ALL_CASH_OUTS,
        payload: response.data.data,
      });
    });
  };

  const GetInfoCashOut = (id) => {
    let url = `cash-out/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_INFO_CASH_OUT,
        payload: response.data.data,
      });
    });
  };

  const GenerateTicket = (id) => {
    Swal.fire({
			title: '¿Estás seguro?',
			text: '¿Desea Generar El Ticket De Salida?',
			type: 'info',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, Generar',
			cancelButtonText: 'No, Cancelar',
		}).then((result) => {
			if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
                //Cunsume API de Back-end en laravel para generar tiket de venta
				clienteAxios.get(`/ticket-cash-out/${id}`, { responseType: 'blob' }).then(response => {
					fileDownload(response.data, 'Ticket Salida De Efectivo.pdf');
					
				}).catch(error => {
					if (error.response.status === 500) {
						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: 'Por Favor, Contacta Con Soporte Técnico'
						})
					}
				});
			}
		});
    }

  //función para crear una nueva escuela
  const AddCashOut = (cash_out) => {
    //console.log(school, image, municipality, state);
    const formData = new FormData();
		formData.append("previous_balance", cash_out.previous_balance);
		formData.append("total", cash_out.total);
		formData.append("description", cash_out.description);
		formData.append("type", cash_out.type);
		formData.append("payment_method_id", cash_out.method);
		formData.append("destination_person_id", cash_out.destination_person_id);
		formData.append("authorized_person_id", cash_out.authorized_person_id);
		formData.append("branch_office_id", cash_out.school_id);
    //console.log(school.image);
    let url = "/cash-out";
    MethodPost(url, cash_out)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_CASH_OUT,
        });
      })
      .catch((error) => {
        Swal.fire({
			title: 'Error',
            text: error.response.data.error,
    	    icon: 'error'
		});
      });
  };

  //función para seleccionar un salon
  const SaveCurrentCashOut = (cash_out) => {
    sessionStorage.setItem('cash_out',JSON.stringify(cash_out));
    dispatch({
      type: CURRENT_CASH_OUT,
      payload: cash_out,
    });
  };

  return (
    <CashOutsContext.Provider
      value={{
        cash_outs: state.cash_outs,
        cash_out: state.cash_out,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        GetCashOuts,
        AddCashOut,
        SaveCurrentCashOut,
        GetInfoCashOut,
        GenerateTicket,
      }}
    >
      {children}
    </CashOutsContext.Provider>
  );
}

export default CashOutsState;