import React, { useState, useEffect, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalOffer from "@material-ui/icons/LocalOffer";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import ModalImage from "react-modal-image";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import { green } from "@material-ui/core/colors";
import Hidden from "@material-ui/core/Hidden";
/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  ImgTblDetail: {
    display: "flex",
    width: "150px",
    height: "150px",
  },
}));

export default function BranchOfficesAdmin(props) {
  const classes = useStyles();

  //sucursales = state, guardarBranch =funcion para guardar en el state;
  const [offices, guardarBranch] = useState([]);
  const [cargando, spinnerCargando] = useState(false);

  //query a la api
  useEffect(() => {
    let url = "/branches";
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  const eliminarSucursal = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "La sucursal seleccionada no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        // eliminar en la rest api
        let url = `/branches/${id}`;
        MethodDelete(url)
          .then((res) => {
            if (res.status === 200) {
              const CambioIndexOffices = offices.filter(
                (office) => office.id !== id
              );
              guardarBranch(CambioIndexOffices);
              Swal.fire("Sucursal Eliminada", res.data.mensaje, "success");
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error,
              icon: "error",
            });
          });
      }
    });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>

              {/**
               * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
               *
               */}
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    marginTop: 100,
                    background:
                      "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
                    color: "white",
                    borderRadius: 15,
                    marginLeft: 25,
                    marginRight: 25,
                  }}
                >
                  <Typography component="h1" variant="h4">
                    Mis Sucursales
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Todos los registros de Sucursales
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  align="right"
                  style={{ marginRight: 30, marginTop: -30 }}
                >
                  <Fab
                    className={classes.fabGreen}
                    color="primary"
                    aria-label="add"
                    size="medium"
                    href="/Agregar_Sucursal"
                  >
                    <Tooltip
                      title="Agregar Sucursal"
                      aria-label="Agregar Sucursal"
                    >
                      <AddIcon />
                    </Tooltip>
                  </Fab>
                </Grid>
              </Grid>
              {/**
               * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
               *
               */}
<Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
              <MaterialTable
                title=""
                columns={[
                  { title: "Nombre", field: "name" },
                  {
                    title: "Imagen",
                    field: "image",
                    render: (rowData) =>
                      rowData.image ? (
                        <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      //// dimenciones de imagen de registros de las tablas
                      className={classes.ImgTblDetail}
                      alt="Sucursal"
                    />
                      ) : (
						<ModalImage
						small={
						  "https://pisouniversitario.com/template/images/page-404.jpg"
						}
						large={
						  "https://pisouniversitario.com/template/images/page-404.jpg"
						}
						className={classes.ImgTblDetail}
						alt="Sucursal"
					  />
                      ),
                  },
                  { title: "Dirección", field: "direction" },
                  { title: "Teléfono", field: "number_phone" },
                  { title: "Estado", field: "state" },
                  { title: "Municipio", field: "municipality" },
                ]}
                data={offices}
                options={{
                  headerStyle: {
                    backgroundColor: "none",
                    color: "#000",
                    fontSize: "16px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  },

                  actionsColumnIndex: -1,
                }}
                actions={[
                  (rowData) => ({
                    icon: "delete",
                    tooltip: "Eliminar Sucursal",
                    onClick: () => eliminarSucursal(rowData.id),
                  }),
                ]}
                components={{
                  Action: (props) => (
                    <Fragment>
                      <Link
                        to={`Editar_Sucursal/${props.data.id}`}
                        style={{ color: "#3f51b5" }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            textTransform: "none",
                            background: "white",
                          }}
                          size="small"
                        >
                          <Tooltip
                            title="Editar sucursal"
                            aria-label="Editar sucursal"
                          >
                            <EditIcon style={{ color: "blue" }} />
                          </Tooltip>
                        </Button>
                      </Link>
                      <Link
                        to={`SucursalProductos/${props.data.id}`}
                        style={{ color: "#3f51b5" }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            textTransform: "none",
                            background: "white",
                          }}
                          size="small"
                        >
                          <Tooltip
                            title="Ver Productos De La Sucursal"
                            aria-label="Ver Productos De La sucursal"
                          >
                            <LocalOffer style={{ color: "orange" }} />
                          </Tooltip>
                        </Button>
                      </Link>
                      <Link
                        to={`SucursalStocks/${props.data.id}`}
                        style={{ color: "#3f51b5" }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            textTransform: "none",
                            background: "white",
                          }}
                          size="small"
                        >
                          <Tooltip
                            title="Ver Stocks De La Sucursal"
                            aria-label="Ver Stocks De La sucursal"
                          >
                            <FormatColorFillIcon style={{ color: "indigo" }} />
                          </Tooltip>
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        style={{ textTransform: "none", background: "white" }}
                        size="small"
                        onClick={() => eliminarSucursal(props.data.id)}
                      >
                        <Tooltip
                          title="Eliminar sucursal"
                          aria-label="Editar sucursal"
                        >
                          <DeleteIcon style={{ color: "red" }} />
                        </Tooltip>
                      </Button>
                    </Fragment>
                  ),
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: "{from} - {to} de {count}",
                    labelRowsSelect: "Columnas",
                    firstTooltip: "Primera Pagina",
                    previousTooltip: "Anterior",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Ultima Página",
                  },
                  toolbar: {
                    searchTooltip: "Buscar",
                    searchPlaceholder: "Buscar",
                  },
                  header: {
                    actions: "Opciones",
                  },
                  body: {
                    emptyDataSourceMessage: "No Hay Gastos que Mostrar",
                    filterRow: {
                      filterTooltip: "Buscar",
                    },
                  },
                }}
              />
			  </Grid>
</Hidden>


   <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Nombre", field: "name" },
              {
                title: "Imagen",
                field: "image",
                render: (rowData) =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      //// dimenciones de imagen de registros de las tablas
                      className={classes.ImgTblDetail}
                      alt="Sucursal"
                    />
                  ) : (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      //// dimenciones de imagen de registros de las tablas
                      className={classes.ImgTblDetail}
                      alt="Sucursal"
                    />
                  ),
              },
            ]}
            data={offices}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Dirección: {rowData.direction}</p>
                      <p>Teléfono: {rowData.number_phone}</p>
                      <p>Estado: {rowData.state}</p>
                      <p>Municipio: {rowData.municipality}</p>
                      <p>Opciones:</p>
                      <p>
                        {
                          <Fragment>
                            <Link to={`Editar_Sucursal/${rowData.id}`}>
                              <IconButton
                                to={`Editar_Sucursal/${rowData.id}`}
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                }}
                                size="small"
                              >
                                <Tooltip
                                  title="Editar sucursal"
                                  aria-label="Editar sucursal"
                                >
                                  <EditIcon style={{ color: "blue" }} />
                                </Tooltip>
                              </IconButton>
                            </Link>
                            <Link to={`SucursalProductos/${rowData.id}`}>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 30,
                                }}
                                size="small"
                              >
                                <Tooltip
                                  title="Ver Productos De La Sucursal"
                                  aria-label="Ver Productos De La sucursal"
                                >
                                  <LocalOffer style={{ color: "orange" }} />
                                </Tooltip>
                              </IconButton>
                            </Link>
                            <Link to={`SucursalStocks/${rowData.id}`}>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 30,
                                }}
                                size="small"
                              >
                                <Tooltip
                                  title="Ver Stocks De La Sucursal"
                                  aria-label="Ver Stocks De La sucursal"
                                >
                                  <FormatColorFillIcon
                                    style={{ color: "indigo" }}
                                  />
                                </Tooltip>
                              </IconButton>
                            </Link>
                            <IconButton
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 30,
                              }}
                              size="small"
                              onClick={() => eliminarSucursal(rowData.id)}
                            >
                              <Tooltip
                                title="Eliminar sucursal"
                                aria-label="Editar sucursal"
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </Tooltip>
                            </IconButton>
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Sucursales que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
	  
    </LayoutDashboard>
  );
}
