import React, { Fragment, useState } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

function SelectDate(props) {
    const [optionsSales, guardarOptionSale] = useState([]);
    const options = [
        { id: 1 ,value: 'week', label: 'Semana' },
        { id: 2 ,value: 'month', label: 'Mes' }
    ]
    //console.log(options)
    const nuevoArreglo = optionsSales.map(sale => {
        return { value: sale.date_format, label: sale.date_format }
    });
    const detectarcambioFecha = (value) => {
        props.detectarcambioFecha(value)
    }
    return (
        <Fragment>
            <InputLabel fullWidth>Seleccionar Mes o Semana</InputLabel>
            <Select
                label="Seleccionar Mes o Semana" 
                fullWidth 
                onChange={detectarcambioFecha}
            >
            <MenuItem disabled>Seleccionar Mes o Semana</MenuItem>
					{options.map((option) => (
						<MenuItem key={option.id} value={option.value}>
							{option.label}
						</MenuItem>
					))}
            </Select>
        </Fragment>
    )
}

export default SelectDate;