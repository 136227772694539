import React, { useState, useEffect, Fragment } from 'react';
/**Importar configuracion axios */
import clienteAxios from '../../Config/Axios';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function SelectMethodsPayments(props) {
	const [payments, savePayments] = useState([]);
	const [error, guardarError] = useState(true);
	useEffect(() => {
		const getPayments = async () => {
			await clienteAxios
				.get('/methods')
				.then((res) => {
					savePayments(res.data.data);
				})
				.catch((error) => {
					console.log(error);
				});

			if (props.method) {
				guardarError(false);
			}
		};
		getPayments();
	}, []);

	const detectarCambiosMethods = (e) => {
		if (e.target.value) {
			guardarError(false);
		}
		props.detectarCambiosMethods(e);
	};

	return (
		<Fragment>
			<InputLabel fullWidth>Método de pago*</InputLabel>
			{props.method ? (
				<Select
					label="Método de pago"
					fullWidth
					onChange={detectarCambiosMethods}
					value={props.method.id_payment_method}
				>
					<MenuItem disabled>Selecciona el método de pago</MenuItem>
					{payments.map((payment) => (
						<MenuItem key={payment.id} value={payment.id}>
							{payment.name}
						</MenuItem>
					))}
				</Select>
			) : (
				<Select label="Método de pago" fullWidth onChange={detectarCambiosMethods}>
					<MenuItem disabled>Selecciona el método de pago</MenuItem>
					{payments.map((payment) => (
						<MenuItem key={payment.id} value={payment.id}>
							{payment.name}
						</MenuItem>
					))}
				</Select>
			)}

			{error ? (
				<p style={{ color: 'red' }}>El método de pago es un Campo Requerido</p>
			) : null}
		</Fragment>
	);
}
