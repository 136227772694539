import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MethodGet from "../../../Config/Services";
//import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import { Button, Tooltip, Container, Paper, Hidden } from "@material-ui/core";
import LayoutDashboard from "../../../Components/Layout/LayoutDashboard";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    marginTop: "80px",
  },
  container: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  widgetContent: {
    marginBottom: "30px !important",
    backgroundImage:
      "linear-gradient(0deg, #C274AB 0%, #E07ECA 100%) !important",
    padding: "20px",
    flexDirection: "row",
    alignItems: "center",
  },
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    paddingLeft: "1%",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
    marginRight: "-2%",
  },
  fabBlue: {
    color: theme.palette.common.white,
    backgroundColor: "#0d47a1",
    "&:hover": {
      backgroundColor: "#0A3678",
    },
  },
  btnAgregar: {
    color: "white",
    marginLeft: 20,
    fontSize: "small",
    height: "60px",
    borderRadius: "5px",
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "indigo",
    "&:hover": {
      backgroundColor: "#5C009E",
    },
  },
}));

const TableRecentComissions = () => {
  const classes = useStyles();
  const [sales, saveSales] = useState([]);

  useEffect(() => {
    const QueryAPI = async () => {
      let url = `latestSales`;
      MethodGet(url).then((response) => {
        saveSales(response.data.data);
      });
    };
    QueryAPI();
  }, []);

  return (
    <Fragment>
      <Hidden only={["xs", "sm"]}>
        <Grid item xs={12} md={12} lg={12}>
          <MaterialTable
            title={`Mis Ventas De Este Mes`}
            columns={[
              { title: "Fecha Venta", field: "fechaVenta" },
              { title: "Folio", field: "folio" },
              {
                title: "Nombre del Cliente",
                render: (rowData) => {
                  return (
                    <Fragment>
                      <p>
                        {rowData.client.name +
                          " " +
                          rowData.client.first_lastname +
                          " " +
                          rowData.client.second_lastname}
                      </p>{" "}
                    </Fragment>
                  );
                },
              },
              {
                title: "Total",
                field: "total",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.total),
              },
              {
                title: "Comisión",
                field: "comission",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.comission),
              },
            ]}
            data={sales}
            options={{
              headerStyle: {
                backgroundColor: "#4b0082",
                color: "#fff",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                paddingRight: "15px",
              },
              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: "search",
                tooltip: "Detalle de venta",
                onClick: (rowData) =>
                  (window.location.href = `/DetalleVenta/${rowData.id}`),
              },
            ]}
            components={{
              Action: (props) => (
                <Fragment>
                  <Link
                    to={`/DetalleVenta/${props.data.id}`}
                    style={{ color: "#3f51b5" }}
                  >
                    <Button style={{ textTransform: "none" }} size="small">
                      <Tooltip
                        title="Detalle de venta"
                        aria-label="Detalle de venta"
                      >
                        <VisibilityIcon style={{ color: "#01579b" }} />
                      </Tooltip>
                    </Button>
                  </Link>
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultíma Página",
              },
              toolbar: {
                searchTooltip: "Buscar ",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage:
                  "No hay ventas registradas durante este mes",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid item xs={12} md={12} lg={12}>
          <MaterialTable
            title={"V. Mes"}
            columns={[
              { title: "Fecha Venta", field: "fechaVenta" },
              { title: "Folio", field: "folio" },
            ]}
            data={sales}
            options={{
              headerStyle: {
                backgroundColor: "indigo",
                color: "#FFF",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>
                        Nombre del cliente:{" "}
                        {
                          <Fragment>
                            <>
                              {rowData.client.name +
                                " " +
                                rowData.client.first_lastname +
                                " " +
                                rowData.client.second_lastname}
                            </>{" "}
                          </Fragment>
                        }
                      </p>
                      <p>Total: ${rowData.total}</p>
                      <p>Comisión: $ {rowData.comission}</p>
                      <p>
                        Opciones:
                        {
                          <Fragment>
                            <Link
                              to={`/DetalleVenta/${rowData.id}`}
                              style={{ color: "#3f51b5" }}
                            >
                              <Button
                                style={{ textTransform: "none" }}
                                size="small"
                              >
                                <Tooltip
                                  title="Detalle de venta"
                                  aria-label="Detalle de venta"
                                >
                                  <VisibilityIcon
                                    style={{ color: "#01579b" }}
                                  />
                                </Tooltip>
                              </Button>
                            </Link>
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from}-{to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Página",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultíma Página",
              },
              toolbar: {
                searchTooltip: "Buscar ",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage:
                  "No hay ventas registradas durante este mes",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </Fragment>
  );
};

export default TableRecentComissions;
