import React, { useState, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from "@material-ui/core";

/**importar spinner */
import Spinner from "../../Complements/Spinner";
import headerConfig from "../../Config/imageHeaders";
/**Importar MethodPut */
import MethodGet, { MethodPost } from "../../Config/Services";

/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
/**
 * identificar Codigo de FORMS
 */
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

function ExpensesEdit(props) {
  //Trabajar con el Spinner
  const [cargando, spinnerCargando] = useState(false);

  const { id } = props.match.params; //Se toma el ID de la Sucursal a editar

  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const [nameOffice, saveOffice] = useState({
    id_branch_office: "",
  });

  const detectarCambiosOffice = (e) => {
    saveOffice({ id_branch_office: e.target.value });
  };

  const classes = useStyles();

  // const [operator, setOperator] = useState({});
  const [expense, guardarExpense] = useState({});

  const [typeBranch, saveTypeBranch] = useState("");

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  //Extraer valores de branch(destructuracion)
  const { name, description, total } = expense;

  //Guardar los camios de la sucursal
  const onSubmit = (data, e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("total", data.total);
    formData.append("branch_office_id", nameOffice.id_branch_office);
    if (image.image !== "") {
      formData.append("image", image.image);
    }
    formData.append("_method", "PATCH");

    let url = `/expenses/${id}`;
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Gasto editado exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        if (typeBranch == 1){
          props.history.push(`/GastosEscolares/${nameOffice.id_branch_office}`)
        }else{
          props.history.push(`/SucursalGastos/${nameOffice.id_branch_office}`)
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/expenses/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarExpense(res.data.data);
        saveOffice({ id_branch_office: res.data.data.branch_office_id });
        if (res.data.data.image) {
          setImage({ urlPhoto: res.data.data.image, image: "" });
        }
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });

    let uri = `/branches/${id}`;
    MethodGet(uri)
      .then((res) => {
        saveTypeBranch(res.data.data.type);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <div onSubmit={handleSubmit(onSubmit)}>
        <form noValidate>
          {/**
           * identificar Codigo de FORMS
           */}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 70 }}
          >
            {/**
             * identificar Codigo de FORMS
             */}
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardHeader color="primary">
                  <h2 className={classes.cardTitleWhite}>{"Editar Gasto "}</h2>
                  <p className={classes.cardCategoryWhite}>
                    Completa correctamente los datos
                  </p>
                </CardHeader>
                <CardBody>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} style={{ paddingTop: 16 }}>
                      <FormControl
                        variant="outlined"
                        disabled
                        className={classes.formControl}
                      >
                        <SelectBranchOffice
                          officeId={nameOffice}
                          detectarCambiosOffice={detectarCambiosOffice}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Nombre"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        defaultValue={name}
                        error={!!errors.name}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "El Nombre es requerido",
                          },
                          minLength: {
                            value: 4,
                            message: "Minimo 4 caracteres",
                          },
                          maxLength: {
                            value: 45,
                            message: "Maximo 45 caracteres",
                          },
                          pattern: {
                            value:
                              /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                            message: "Unicamente carácteres alfabéticos",
                          },
                        })}
                      />
                      <p>{errors?.name?.message}</p>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="description"
                        label="Descripción"
                        name="description"
                        autoComplete="description"
                        autoFocus
                        defaultValue={description}
                        error={!!errors.description}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "La Descripción es requerida",
                          },
                          minLength: {
                            value: 4,
                            message: "Minimo 4 caracteres",
                          },
                          maxLength: {
                            value: 255,
                            message: "Maximo 255 caracteres",
                          },
                        })}
                      />
                      <p>{errors?.description?.message}</p>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="total"
                        label="Total Del Gasto"
                        name="total"
                        autoComplete="total"
                        type="number"
                        autoFocus
                        defaultValue={total}
                        error={!!errors.total}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "El total del gasto es requerido",
                          },
                          minLength: {
                            value: 1,
                            message: "Minimo 1 digito",
                          },
                        })}
                      />
                      <p>{errors?.total?.message}</p>
                    </Grid>
                  </Grid>
                  <Card profile>
                    <CardAvatar profile>
                      <img
                        src={image.urlPhoto}
                        alt="User"
                        width="100%"
                        height="100%"
                      />
                    </CardAvatar>
                    <CardBody profile>
                      <input
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        name="image"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={handleChangeImage}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Tooltip
                            title="seleccionar imagen"
                            aria-label="seleccionar imagen"
                          >
                            <PhotoCamera />
                          </Tooltip>
                        </IconButton>
                      </label>
                      <InputLabel>Selecciona el Ticket del Gasto</InputLabel>
                      <h2 className={classes.cardTitle}>Editar Gasto </h2>
                      <h3 className={classes.cardTitle}></h3>
                      <p className={classes.description}>
                        Recuerda que la cantidad de este gasto puede ser
                        modificada desde otros modulos
                      </p>
                    </CardBody>
                  </Card>
                  <Grid container spacing={3} justify="center">
                    <Grid item spacing={3} xs={12} sm={12} md={6}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        className={classes.submit}
                      >
                        ACTUALIZAR
                      </Button>
                    </Grid>
                  </Grid>
                  {/**
                   * identificar Codigo de FORMS
                   */}
                </CardBody>
              </Card>
            </GridItem>
            {/**
             * identificar Codigo de FORMS
             */}
            {/**
             * identificar Codigo de FORMS
             * Pagos imagen* Clases
             *
             *
             */}
          </Grid>
          {/**
           * identificar Codigo de FORMS 123456789
           */}
        </form>
      </div>
    </LayoutDashboard>
  );
}

export default withRouter(ExpensesEdit);
