import React, { useReducer } from 'react';
import CoursesContext from './CoursesContext'
import CoursesReducer from './CoursesReducer';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../Config/Services";
import { ADD_NEW_COURSE, CURRENT_COURSE, DELETE_COURSE, GET_ALL_COURSES,
    SHOW_ERRORS_API, UPDATE_COURSE, GET_INFO_COURSE } from "../../types";
import Swal from "sweetalert2";

const CoursesState = ({children}) => {
  // estado inicial
  const initialSate = {
    courses: [],
    course: null,
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(CoursesReducer, initialSate);

  //obtener la lista de proveedores
  const GetCourses = (id) => {
    sessionStorage.removeItem('course');
    let url = `schoolcourses/${id}`;
    MethodGet(url).then((response) => {
        //console.log(response);
      dispatch({
        type: GET_ALL_COURSES,
        payload: response.data.data,
      });
    });
  };

  const GetInfoCourse = (id) => {
    let url = `courses/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_INFO_COURSE,
        payload: response.data.data,
      });
    });
  };

  //función para crear una nueva escuela
  const AddCourse = (course) => {
    //console.log(school, image, municipality, state);
    const formData = new FormData();
		formData.append("name", course.name);
		formData.append("cost", course.cost);
		formData.append("description", course.description);
		formData.append("branch_office_id", course.school_id);
		formData.append("type_course", course.type_course);
    if(course.type_course == 2)
    {
		  let courses_list = JSON.stringify(course.courses);
      formData.append("courses_list", courses_list);
    }
    //console.log(school.image);
    let url = "/courses";
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_COURSE,
        });
      })
      .catch((error) => {
        Swal.fire({
					title: 'Error',
                    text: error.response.data.error,
    			    icon: 'error'
				});
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //función para seleccionar un salon
  const SaveCurrentCourse = (course) => {
    sessionStorage.setItem('course',JSON.stringify(course));
    dispatch({
      type: CURRENT_COURSE,
      payload: course,
    });
  };

  //función para editar un salon
  const UpdateCourse = (course) => {
    const formData = new FormData();
        formData.append("name", course.name);
        formData.append("cost", course.cost);
        formData.append("type_course", course.type_course);
        formData.append("branch_office_id", course.school_id);
        formData.append("description", course.description);
		    formData.append("_method", "PATCH");
        if(course.type_course == 2)
        {
		      let courses_list = JSON.stringify(course.courses);
          formData.append("courses_list", courses_list);
        }
      
    let url = `courses/${course.id}`;
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_COURSE,
        });
      })
      .catch((error) => {
        Swal.fire({
					title: 'Error',
          text: error.response.data.error,
    			icon: 'error'
				});
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //Eliminar Proveedor por id
  const DeleteCourse = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El curso ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `courses/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_COURSE,
            payload: id,
          });
        }).catch((error) => {
          Swal.fire({
            title: 'Error',
            text: error.response.data.error,
            icon: 'error'
          });
        });
      }
    });
  };

  return (
    <CoursesContext.Provider
      value={{
        courses: state.courses,
        course: state.course,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        GetCourses,
        AddCourse,
        SaveCurrentCourse,
        GetInfoCourse,
        UpdateCourse,
        DeleteCourse,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
}

export default CoursesState;