import React, { useState, useContext, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { MethodPost } from "../../Config/Services";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Moment from "moment";
import CouponsContext from "../../Reducers/Coupons/CouponsContext";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
/**
 * identificar Codigo de FORMS
 */
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import CupoPhoto from "../../Complements/Image/FormClass/CupoPhoto.svg";
import AuthContext from "../../Context/autenticacion/authContext";
/**
 *
 */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(10),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  ContainerSubmit: {
    marginLeft: theme.spacing(30),
    marginTop: theme.spacing(1),
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  /**
   * identificar Codigo de FORMS
   */
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  /**
   *
   */
}));

function CouponsAdd(props) {
  const classes = useStyles();
  const [expiration_date, saveExpirationDate] = useState(
    Moment().format("YYYY-MM-DD")
  );
  const [selectedDateStart, handleDateChangeStart] = useState(new Date());
  const formCouponsContext = useContext(CouponsContext);

  const { success, addCoupons } = formCouponsContext;
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const changeDate = (date) => {
    const DateFormat = Moment(date).format("YYYY-MM-DD");
    saveExpirationDate(DateFormat);
    handleDateChangeStart(date);
  };

  const [type, setType] = useState("1");

  const ChangeType = (event) => {
    setType(event.target.value);
  };

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    data.expiration_date = expiration_date;
    data.type = type;

    data.branch_office_id = usuario.id_branch_office;

    addCoupons(data);
  };

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de cupones
    if (success) {
      props.history.push(`/Cupones`);
    }
  }, [success]);

  return (
    <LayoutDashboard>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {/**
         * identificar Codigo de FORMS
         */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 70 }}
        >
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>
                  Agregar Cupón De Descuento
                </h2>
                <p className={classes.cardCategoryWhite}>
                  Completa correctamente los datos
                </p>
              </CardHeader>
              <CardBody>
                {/**
                 * identificar Codigo de FORMS
                 */}
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="code"
                      label="Código para aplicar el descuento"
                      name="code"
                      autoComplete="code"
                      autoFocus
                      error={!!errors.money_discount_min}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El código de descuento es requerido",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 1 carácter",
                        },
                      })}
                    />
                    <p>{errors?.code?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="discount"
                      label="Porcentaje de descuento"
                      name="discount"
                      autoComplete="discount"
                      autoFocus
                      type="number"
                      error={!!errors.money_discount_max}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El porcentaje de descuento es requerido",
                        },
                        min: {
                          value: 1,
                          message: "Minimo 1% para aplicar un descuento",
                        },
                        max: {
                          value: 50,
                          message: "Maximo 50% de descuento",
                        },
                      })}
                    />
                    <p>{errors?.discount?.message}</p>
                  </Grid>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        inputVariant="outlined"
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        disablePast
                        label="Fecha De Expiración"
                        value={selectedDateStart}
                        onChange={changeDate}
                        InputAdornmentProps={{ position: "start" }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Selecciona El Tipo De Cupón
                      </FormLabel>

                      <RadioGroup
                        row
                        aria-label="type"
                        name="type"
                        value={type}
                        onChange={ChangeType}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Ventas"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Inscripciones"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="Ambos"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container xs={12} xl={12} spacing={2} justify="center">
                  <Grid item xs={12} sm={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.submit}
                    >
                      GUARDAR
                    </Button>
                  </Grid>
                </Grid>
                {/**
                 * identificar Codigo de FORMS
                 */}
              </CardBody>
            </Card>
          </GridItem>
          {/**
           * identificar Codigo de FORMS
           */}
          {/**
           * identificar Codigo de FORMS
           */}
        </Grid>
        {/**
 
 */}
      </form>
    </LayoutDashboard>
  );
}

export default CouponsAdd;
