import React, { useState, useEffect, Fragment } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';

/**Importar metodo Get */
import MethodGet, { MethodDelete } from '../../Config/Services';
/**Importar Sweetalert2 * */
import Swal from 'sweetalert2';
/**importar spinner */
import Spinner from '../../Complements/Spinner';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
    }, 
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(18),
        paddingBottom: theme.spacing(4),
        paddingInlineEnd: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBlockEnd: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    input: {
        display: 'none',
    },
    typography:{
      margin: theme.spacing(0,1,2,2),
    }
}));

const Payment_MethodsIndex = props => {
    const classes = useStyles();
//proveedores = state, guardarmethodsPayment =funcion para guardar en el state;
   const [ methodsPayment, guardarMethodsPayment ] = useState([]);
   const [ cargando, spinnerCargando ] = useState(false);
   
     //query a la api
	useEffect(() => {
			let url = '/methods'
			MethodGet(url).then(res=>{
				guardarMethodsPayment(res.data.data);
				spinnerCargando(true);
			}).catch(error=>{
				console.log(error);
				spinnerCargando(true);
			})
	}, []);
    
     const deleteMethodPayment = (id) => {
				Swal.fire({
					title: '¿Estás seguro?',
					text: 'El método de pago seleccionado no se podrá recuperar',
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Si, Eliminar',
					cancelButtonText: 'No, Cancelar',
				}).then((result) => {
					if (result.value) {
						// eliminar en la rest api
						let url = `/methods/${id}`;
						MethodDelete(url).then((res) => {
							if (res.status === 200) {
								const CambioIndexMethod = methodsPayment.filter(
									(method) => method.id !== id
								);
								guardarMethodsPayment(CambioIndexMethod);
								Swal.fire('Método de Pago Eliminado', res.data.mensaje, 'success');
							}
						});
					}
				});
			};

    //  spinner de carga 
   if(!cargando) return <Spinner/>
  
    return (
			<LayoutDashboard>
				<div className={classes.root}>
					<CssBaseline />
					<main className={classes.content}>
						<div className={classes.appBarSpacer} />
						<Container className={classes.container}>
							<Grid Container spacing={3}>
								<Typography
									component="h1"
									variant="h4"
									align="center"
									className={classes.typography}
								>
									<Box align="right">
										<Fab
											color="primary"
											aria-label="add"
											size="small"
											href="/Agregar_Metodo"
										>
											<AddIcon />
										</Fab>
									</Box>
								</Typography>
								<MaterialTable
									title="Mis Métodos de Pago"
									columns={[
										{ title: 'Clave', field: 'id' },
										{ title: 'METODO', field: 'name' },
										{ title: 'REFERENCIA', field: 'reference' },
										{ title: 'Descripción', field: 'description' },
									]}
									data={methodsPayment}
									options={{
										headerStyle: {
											backgroundColor: 'orange',
											color: '#FFF',
										},

										actionsColumnIndex: -1,
									}}
									actions={[
										(rowData) => ({
											icon: 'delete',
											tooltip: 'Eliminar Método de Pago',
											onClick: () => deleteMethodPayment(rowData.id),
										}),
									]}
									components={{
										Action: (props) => (
											<Fragment>
												<Link
													to={`Editar_Metodo/${props.data.id}`}
													style={{ color: '#3f51b5' }}
												>
													<Button
														variant="contained"
														style={{
															textTransform: 'none',
															background: 'white',
														}}
														size="small"
													>
														<Tooltip
															title="Editar Metodo de Pago"
															aria-label="Editar Metodo de Pago"
														>
															<EditIcon style={{ color: 'blue' }} />
														</Tooltip>
													</Button>
												</Link>
												<Button
													variant="contained"
													style={{ textTransform: 'none', background: 'white' }}
													size="small"
													onClick={() => deleteMethodPayment(props.data.id)}
												>
													<Tooltip
														title="Eliminar Metodo de Pago"
														aria-label="Editar Metodo de Pago"
													>
														<DeleteIcon style={{ color: 'red' }} />
													</Tooltip>
												</Button>
											</Fragment>
										),
									}}
									localization={{
										pagination: {
											labelDisplayedRows: '{from} - {to} de {count}',
											labelRowsSelect: 'Columnas',
											firstTooltip: 'Primera Pagina',
											previousTooltip: 'Anterior',
											nextTooltip: 'Siguiente',
											lastTooltip: 'Ultima Página',
										},
										toolbar: {
											searchTooltip: 'Buscar',
											searchPlaceholder: 'Buscar',
										},
										header: {
											actions: 'Opciones',
										},
										body: {
											emptyDataSourceMessage:
												'No Hay Métodos de Pago que Mostrar',
											filterRow: {
												filterTooltip: 'Buscar',
											},
										},
									}}
								/>
							</Grid>
						</Container>
					</main>
				</div>
			</LayoutDashboard>
		);
}

export default Payment_MethodsIndex;