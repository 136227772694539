import React, { useEffect, useState, useContext, Fragment } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Grid, Box, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from "@material-ui/core/styles";

import { useForm } from 'react-hook-form';

import FormControl from '@material-ui/core/FormControl';

/**Habilitar redirecciones */
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { MethodPost } from '../../Config/Services';
import SelectClient from '../SelectOptions/SelectClient';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	container: {
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(30),
	},
	typography: {
		paddingTop: theme.spacing(4),
		margin: theme.spacing(1),
		paddingLeft: theme.spacing(30),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(9),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		background: '#3537DB',
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			background: '#5902CF',
		},
		color: 'white',
	},
	ContainerSubmit: {
		marginLeft: theme.spacing(30),
		marginTop: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paperModal: {
		position: 'absolute',
		width: 500,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	iconButton: {
		'& > svg': {
			margin: theme.spacing(2),
		},
	},
	h2: {
		background: '#394ED1',
		color: 'white',
	},
}));

const type_client = [{ title: 'Cliente General' }, { title: 'Cliente Alumno' }];

function ServiceAdd(props) {
	const classes = useStyles();

	{
		/**Este es para abrir el modal de agregar cliente */
	}
	const [openaddClient, setOpenaddClient] = useState(false);

	{
		/**Este Es para abrir el Modal de Metodos de pago */
	}
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleOpenaddClient = () => {
		setOpenaddClient(true);
	};

	const handleCloseaddClient = () => {
		setOpenaddClient(false);
	};

	// -------------------------------------------------------------
	const [operator, setOperator] = React.useState({});

	const [client, saveClient] = useState({
		id_client: '',
	});

	const detectarCambioClient = (e) => {
		saveClient({ id_client: e.target.value });
	};

	//Funcion para validaciones
	const { register, handleSubmit, errors } = useForm();

	const onSubmit = (data, e) => {
		e.preventDefault();

		if (client.id_client !== '') {
			const dat = {
				...data,
				...client,
				id_service: '1',
			};

			let url = '/service_schedules';
			MethodPost(url, dat)
				.then((res) => {
					Swal.fire({
						title: 'Cita Registrado Exitosamente',
						text: res.data.message,
						icon: 'success',
						timer: 2000,
						showConfirmButton: false,
					});

					props.history.push('/Citas');
				})
				.catch((error) => {
					Swal.fire({
						title: 'Error',
						text: error.response.data.error,
						icon: 'error',
					});
				});
		} else {
			Swal.fire({
				title: 'Error',
				text: 'Todos los campos son Obligatorios',
				icon: 'error',
			});
		}
	};

	return (
		<LayoutDashboard>
			<div onSubmit={handleSubmit(onSubmit)} className={classes.root}>
				<form className={classes.form} noValidate>
					<Box>
						<Typography
							component="h1"
							variant="h4"
							align="center"
							className={classes.typography}
						>
							Agendar Cita
						</Typography>
					</Box>
					<Grid container spacing={3} className={classes.container}>
						<Grid container spacing={1}>
							<Grid item xs={6}>
								<label>Tipo de Servicio</label>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id=""
									disabled
									value="Aplicación de Uñas"
									name=""
									autoComplete=""
									autoFocus
								/>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={5}>
								<FormControl variant="outlined" className={classes.formControl}>
									<SelectClient detectarCambioClient={detectarCambioClient} />
								</FormControl>
							</Grid>
							<Grid item xs={1}>
								<Modal
									aria-labelledby="add_client"
									aria-describedby="transition-modal-description"
									className={classes.modal}
									open={openaddClient}
									closeAfterTransition
									BackdropComponent={Backdrop}
									BackdropProps={{
										timeout: 500,
									}}
								>
									<Fade in={openaddClient}>
										<div className={classes.paperModal}>
											<Typography>
												<Box align="right">
													<Fab aria-label="cancel" size="small">
														<CloseIcon onClick={handleCloseaddClient} />
													</Fab>
												</Box>

												<h2
													id="add_client"
													align="Center"
													className={classes.h2}
												>
													<hr /> Nuevo Cliente <hr />
												</h2>
											</Typography>

											<form onSubmit={handleSubmit(onSubmit)} noValidate>
												<Grid Container spacing={2}>
													<Grid item xs={12}>
														<TextField
															variant="outlined"
															margin="normal"
															fullWidth
															id="name"
															label="Nombre Completo"
															name="name"
															autoComplete="name"
															autoFocus
															error={!!errors.name}
															inputRef={register({
																required: {
																	value: true,
																	message: 'El Nombre es requerido',
																},
																minLength: {
																	value: 4,
																	message: 'Minimo 4 caracteres',
																},
																maxLength: {
																	value: 255,
																	message: 'Maximo 255 caracteres',
																},
																pattern: {
																	value: /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
																	message: 'Unicamente carácteres alfabéticos',
																},
															})}
														/>
														<p>{errors?.name?.message}</p>
													</Grid>
													<Grid>
														<TextField
															variant="outlined"
															margin="normal"
															required
															fullWidth
															id="number_phone"
															label="Teléfono"
															name="number_phone"
															autoComplete="phone"
															type="number"
															autoFocus
															error={!!errors.number_phone}
															inputRef={register({
																required: {
																	value: true,
																	message: 'El Teléfono es requerido',
																},
																maxLength: {
																	value: 10,
																	message: 'Maximo 10 digitos',
																},
															})}
														/>
														<p>{errors?.number_phone?.message}</p>
													</Grid>
													<Grid item xs={12}>
														<Button
															type="submit"
															fullWidth
															variant="outlined"
															className={classes.submit}
														>
															<label>GUARDAR</label>
														</Button>
													</Grid>
												</Grid>
											</form>
										</div>
									</Fade>
								</Modal>
								<div className={classes.iconButton}>
									<Tooltip title="Agregar Cliente" aria-label="Agregar Cliente">
										<PersonAddIcon
											style={{ fontSize: 45 }}
											onClick={handleOpenaddClient}
										/>
									</Tooltip>
								</div>
							</Grid>

							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="description"
									label="Descripción"
									name="description"
									autoComplete="name"
									autoFocus
									error={!!errors.description}
									inputRef={register({
										required: {
											value: true,
											message: 'La Descripción es requerido',
										},
										minLength: {
											value: 4,
											message: 'Minimo 4 caracteres',
										},
										maxLength: {
											value: 45,
											message: 'Maximo 45 caracteres',
										},
									})}
								/>
								<p>{errors?.description?.message}</p>
							</Grid>

							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="date"
									label="Fecha"
									type="date"
									defaultValue="2017-05-24"
									name="date"
									autoComplete="name"
									autoFocus
									error={!!errors.date}
									inputRef={register({
										required: {
											value: true,
											message: 'La Fecha es requerida',
										},
									})}
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<p>{errors?.date?.message}</p>
							</Grid>

							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="start_time"
									label="Hora de Inicio"
									name="start_time"
									autoComplete="name"
									type="time"
									defaultValue="07:30"
									autoFocus
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{
										step: 300, // 5 min
									}}
									error={!!errors.start_time}
									inputRef={register({
										required: {
											value: true,
											message: 'La Hora de Inicio es requerido',
										},
									})}
								/>
								<p>{errors?.start_time?.message}</p>
							</Grid>

							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="end_time"
									label="Hora que Terminó"
									name="end_time"
									autoComplete="name"
									type="time"
									defaultValue="08:30"
									autoFocus
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{
										step: 300, // 5 min
									}}
									error={!!errors.end_time}
									inputRef={register({
										required: {
											value: true,
											message: 'La Hora que Terminó es requerido',
										},
									})}
								/>
								<p>{errors?.end_time?.message}</p>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={3} className={classes.ContainerSubmit}>
								<Button
									type="submit"
									fullWidth
									variant="outlined"
									className={classes.submit}
								>
									GUARDAR
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</form>
			</div>
		</LayoutDashboard>
	);
}

export default withRouter(ServiceAdd);
