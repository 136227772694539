import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import StockMovementsAdmin from './StockMovementsAdmin';
import StockMovementsSeller from './StockMovementsSeller';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import StockMovementsSubAdmin from './StockMovementsSubAdmin';

export default function StockMovementsIndex() {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		  case '1':
			component = <StockMovementsAdmin />
			break;
		  case '2': 
			component = <StockMovementsSeller />
			break;
		  case '3': 
			component = <StockMovementsAdmin />
			break;
		  default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
