import React, { useState, useEffect, Fragment } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MaterialTable from 'material-table';

/**Importar metodo Get */
import MethodGet, { MethodDelete } from '../../Config/Services';
/**Importar Sweetalert2 * */
import Swal from 'sweetalert2';
/**importar spinner */
import Spinner from '../../Complements/Spinner';
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	Link: {
		textDecoration: 'none',
		color: theme.palette.text.primary
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(18),
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	input: {
		display: 'none',
	},
	Table: {
		background: 'Orange',
	},
	typography: {
		margin: theme.spacing(0, 1, 2, 2),
	},
}));

const StockMovementsSubAdmin = (props) => {
	const classes = useStyles();

	//sucursales = state, saveStocks =funcion para guardar en el state;
	const [stocks, saveStocks] = useState([]);
	const [cargando, spinnerCargando] = useState(false);


	//query a la api
	useEffect(() => {
		let url = '/stock-detail';
		MethodGet(url)
			.then((res) => {
				saveStocks(res.data.data);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
	}, []);

	//  spinner de carga
	if (!cargando) return <Spinner />;

	return (
		<LayoutDashboard>
			<div className={classes.root}>
				<CssBaseline />
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Container className={classes.container}>
						<Grid Container spacing={3}>
                        <MaterialTable
								title={"Movimientos De Stock De Mi Sucursal"}
								columns={[
									{ title: 'Clave', field: 'clave' },
									{ title: 'PRODUCTO', field: 'product_name' },
									{title: 'Existencia Anterior', field: 'old_quantity' },
									{title: 'Diferencia', render:  rowData => { return (rowData.operator + ' ' + rowData.difference) }, },
									{title: 'Existentes', field: 'new_quantity' },
									{title: 'Fecha Del Movimiento', field: 'start_date' },
								]}
								data={stocks}
								options={{
									headerStyle: {
										backgroundColor: 'orange',
										color: '#FFF',
									},

									actionsColumnIndex: -1,
								}}
								localization={{
									pagination: {
										labelDisplayedRows: '{from} - {to} de {count}',
										labelRowsSelect: 'Columnas',
										firstTooltip: 'Primera Pagina',
										previousTooltip: 'Anterior',
										nextTooltip: 'Siguiente',
										lastTooltip: 'Ultima Página',
									},
									toolbar: {
										searchTooltip: 'Buscar',
										searchPlaceholder: 'Buscar',
									},
									header: {
										actions: 'Opciones',
									},
									body: {
										emptyDataSourceMessage:
											'No Hay Ningun Movimiento Por El Momento',
										filterRow: {
											filterTooltip: 'Buscar',
										},
									},
								}}
							/>
						</Grid>
					</Container>
				</main>
			</div>
		</LayoutDashboard>
	);
};

export default StockMovementsSubAdmin;
