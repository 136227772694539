import React,{useEffect,useState,Fragment, useContext} from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import MethodGet, { MethodPut } from '../../Config/Services';
import ModalImage from 'react-modal-image';
import { Link } from 'react-router-dom';
import { Button, Tooltip,Hidden, Grid, ButtonBase } from '@material-ui/core';
import Spinner from '../../Complements/Spinner';
import EditIcon from '@material-ui/icons/Edit';
import SchedulesContext from '../../Reducers/Schedules/SchedulesContext';
import CourseContext from "../../Reducers/Courses/CoursesContext";
import DetalleCurso1 from '../../Complements/Image/DetailCourses/DetalleCurso1.svg';
import DetalleCurso2 from '../../Complements/Image/DetailCourses/DetalleCurso2.svg';
import DetalleCurso3 from '../../Complements/Image/DetailCourses/DetalleCurso3.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(1),
    paddingInlineEnd: theme.spacing(3),
    paddingRight:theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: 'none',
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperDetalle: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  paperTitle: {
    background: '#9966cc'

  },
  formControl: {
		margin: theme.spacing(1),
		width:'100%'
	},
  papercard: {
    padding: theme.spacing(3),
    margin: 'auto',
    maxWidth: 'auto',
    backgroundColor:'#fff',
  },
  image: {
    width: '200px',
    height: '250px',
  },
  ticketimage: {
    width: 100,
    height: 120,
    maxWidth:100,
    maxHeight:120,
  },
  img: {
    margin: 'auto',
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  papercardtitle: {
   background: '#6f33ab',
   //background: '#9966cc',
    padding: 7,
    fontSize:'21pt',
    color:'white',
  },
  Tblpapercard: {
      padding: theme.spacing(3),
      margin: 'auto',
      Width: '100%',
      backgroundColor:'#fff',
  },
  textStructure:{
    borderBottom: '1px solid grey',
    whiteSpace:'nowrap',  
    textOverflow: 'ellipsis',
    overflow: 'hidden',

  }

}));


export default function ScheduleGraduateDetail(props) {

  //obtenemos el producto de session storage
	let ScheduleStorageID = JSON.parse(sessionStorage.getItem("schedule"));

  const classes = useStyles();
  const StateSchedulesContext = useContext(SchedulesContext);
  const [error, guardarError] = useState(false);
  const { SaveCurrentClass , classesSchedule, GetClasses, success} = StateSchedulesContext;

  const SelectSchedule = schedule =>{
    sessionStorage.removeItem('classSchedule');
    SaveCurrentClass(schedule);
  }

  const [courses, saveCourses] = useState([]);
  const [info, saveInfo] = useState({});
  const [total, saveTotal] = useState('');
	const [cargando, spinnerCargando] = useState(false);

  useEffect(() => {
		let url = `/scheduleinfo/${ScheduleStorageID}`;
		MethodGet(url)
			.then((res) => {
				saveInfo(res.data.program);
        saveTotal(res.data.totals)
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
  }, []);

  const {course_name} = info;
  const PersonIn = (parseInt(info.capacity) - parseInt(info.actual_capacity));

	useEffect(() => {
    GetClasses(ScheduleStorageID);
  }, [success])

  //  spinner de carga
	if (!cargando) return <Spinner />;

 const TotalCourse = (parseInt(info.capacity) * parseInt(info.course_cost));

 const EstimedTotal = (parseInt(info.actual_capacity) * parseInt(info.course_cost));

 const {office_name} = info;

  return (
    <LayoutDashboard>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container className={classes.container}>
  <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
    <Typography component="h3" variant="h4" style={{marginBottom:'1%'}}>
        Detalle De Programación
    </Typography>
  </Grid>
  <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="h1" variant="h4" align="center"
            className={classes.papercardtitle}>
             Información del Curso
          </Typography>
        </Grid>
      <Paper className={classes.papercard}>
      <Grid container >
        <Hidden only={['xs','sm',]}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <ButtonBase className={classes.image}>
              <img className={classes.img} alt="DetalleCard" src={DetalleCurso1} />
            </ButtonBase>          
        </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} container>
        <Grid item container>
            <Typography component="h6" variant="h6">
               Diplomado: 
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">
              {course_name}
            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
                Costo:
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">
              ${info.course_cost}
            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
              Capacidad del Curso:
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">
              {info.capacity == 0 ? "No establecido" : info.capacity}
            </Typography>
          </Grid>
            <Grid item container>
              <Typography component="h6" variant="h6">
                  Inscritos:
              </Typography>
              <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">
               {info.actual_capacity}
              </Typography>
            </Grid>
            <Grid item container>
              <Typography component="h6" variant="h6">
                  Cupo Disponible:
              </Typography>
              <Typography component="h6" variant="h6" color="textSecondary" >
              {PersonIn < 6 ? <b style={{color:'red'}}>{PersonIn}</b> :  <b style={{color:'green'}}>{PersonIn}</b>}
              </Typography> 
            </Grid>
        </Grid>
      </Grid>
      </Paper>
    </Grid>
    {
    /**
      ***** Tbl Datos del Cliente
    */
    }
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="h1" variant="h4" align="center"
            className={classes.papercardtitle}
          >
              Datos de este Curso
          </Typography>
        </Grid>
      <Paper className={classes.papercard}>
      <Grid container>
        <Hidden only={['xs','sm',]}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <ButtonBase className={classes.image}>
              <img className={classes.img} alt="DetalleCard" src={DetalleCurso3} />
            </ButtonBase>          
        </Grid>
        </Hidden>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} container>
          <Grid item container>
            <Typography component="h6" variant="h6">
              Descripcion del curso:
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">
              {info.course_description ?  info.course_description : "No hay descripcion" }
            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
              Sucursal:
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">
            {info.office_name}
            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
             Estimado del Curso:
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">
               ${TotalCourse}
            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
             Estimado por inscritos:
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">
               ${EstimedTotal}
            </Typography>
          </Grid>
          <Grid item container>
            <Typography component="h6" variant="h6">
             Recaudado Total:
            </Typography>
            <Typography component="h6" variant="h6" color="textSecondary" alignItems="stretch">
               ${total}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      </Paper>
    </Grid>
  </Grid>
</Container>



<Container className={classes.container}>
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography component="h1" variant="h4" align="center" className={classes.papercardtitle}>
           Programaciones Del Diplomado
        </Typography>
      </Grid>
  <Paper className={classes.Tblpapercard}>
    <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
      <Grid item container  xs={12} sm={12} md={6} lg={6} xl={6}>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <Typography component="h5" variant="h5">
            Curso
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
          <Typography component="h5" variant="h5" >
            Clase
          </Typography>
        </Grid>
        <Hidden only={['xs','sm',]}>
          <Grid item  xs={3} sm={3}  md={3} lg={3} xl={3}>
            <Typography component="h5" variant="h5">
              Hora Inicio
            </Typography>
          </Grid>
        </Hidden>
        <Grid item item xs={3} sm={3}  md={3} lg={3} xl={3}>
    <Typography component="h5" variant="h5">
      Hora Fin
    </Typography>
  </Grid>
  {classesSchedule.map(course => (
    <Grid key={course.id}
      container xs={12} sm={12} md={12} lg={12} xl={12}  className={classes.textStructure}>
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={4} sm={4}  md={4} lg={4} xl={4}>
            <Typography component="h3" variant="p" color="textSecondary">
            {course.individual_course}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4}  md={2} lg={2} xl={2}>
            <Typography component="h3" variant="p" color="textSecondary">
              {course.class}
            </Typography>
          </Grid>
          <Hidden only={['xs','sm',]}>
            <Grid item  xs={3} sm={3}  md={3} lg={3} xl={3}>
              <Typography component="h3" variant="p" color="textSecondary">
                {course.start_time}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item xs={3} sm={3}  md={3} lg={3} xl={3}>
            <Typography component="h3" variant="p" color="textSecondary">
              {course.end_time}
            </Typography>
          </Grid>
        </Grid>
    </Grid>
  ))}
</Grid>


<Grid item container xs={12} sm={12} md={6} lg={6} xl={6}>
  <Grid item xs={3} sm={3}  md={3} lg={3} xl={3}>
    <Typography component="h5" variant="h5">
      Fecha Inicio
    </Typography>
  </Grid>
  <Grid item xs={3} sm={3}  md={3} lg={3} xl={3} >
    <Typography component="h5" variant="h5">
      Fecha Final
    </Typography>
  </Grid>
  <Grid item xs={4} sm={4}  md={4} lg={4} xl={4} >
    <Typography component="h5" variant="h5">
      Salón
    </Typography>
  </Grid>
  <Grid item xs={2} sm={2}  md={2} lg={2} xl={2} >
    <Typography component="h5" variant="h5">
      Opciones
    </Typography>
  </Grid>
    {classesSchedule.map(course => (
    <Grid key={course.id}
      container xs={12} sm={12} md={12} lg={12} xl={12} style={{borderBottom: '1px solid grey', whiteSpace:'nowrap'}}>
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid item xs={3} sm={3}  md={3} lg={3} xl={3}>
            <Typography component="h3" variant="p" color="textSecondary">
                {course.date_init}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={3}  md={3} lg={3} xl={3}>
            <Typography component="h3" variant="p" color="textSecondary">
                {course.date_ending}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4}  md={4} lg={4} xl={4}>
            <Typography component="h3" variant="p" color="textSecondary">
                {course.classroom_name}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2}  md={2} lg={2} xl={2}>
            <Link
							to={`/EditarProgramacion/Clase`}
							style={{ color: '#3f51b5' }}
						>
							<Button
								variant="contained"
								style={{
									textTransform: 'none',
									background: 'white',
								}}
              	onClick={ () => SelectSchedule(course.id)}
								size="small"
							>
								<Tooltip
									title="Editar Programacion"
									aria-label="Editar Programacion"
								>
									<EditIcon style={{ color: 'blue' }} />
								</Tooltip>
							</Button>
						</Link>
          </Grid>
        </Grid>
    </Grid>
  ))}
      </Grid>
    </Grid>
    </Paper>
  </Grid>
</Container>
        </main>
    </div>
</LayoutDashboard>
  );
}