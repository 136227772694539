import React, { useContext, useEffect, useState } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import Spinner from "../../Complements/Spinner";
import Graphics from "../Graphics/Graphics";
import AuthContext from "../../Context/autenticacion/authContext";
import {
  Grid,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//import '../Principal/style.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(13),
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingInlineEnd: theme.spacing(2),
    paddingRight: theme.spacing(6),
  },
  welcomeView: {
    height: "auto",
    backgroundImage: `url('https://fondosmil.com/fondo/18704.jpg')`,
    position: "relative",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "5px",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 11px 15px -9px rgba(0,0,0,0.63)",
  },
  imageWapizima: {
    paddingTop: theme.spacing(4),
    maxWidth: "90%",
    height: "auto",
    aling: "center",
  },
  containerButtons: {
    paddingTop: theme.spacing(4.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
  },
  textWelcome: {
    fontSize: "4vw",
  },
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.2,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
    color: "white",
    fontSize: 25,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

const ViewGraphic = (props) => {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);

  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuarioAutenticado, usuario } = authContext;

  useEffect(() => {
    usuarioAutenticado();
    spinnerCargando(true);
  }, []);

  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <div className={classes.root}>
        <CssBaseline />
        <Container className={classes.container}>
          <Grid Container spacing={3}>
            <Graphics />
          </Grid>
        </Container>
      </div>
    </LayoutDashboard>
  );
};

export default ViewGraphic;
