import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import EditAdmin from './EditAdmin';
import EditSeller from './EditSeller';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';

export default function ClientIndex() {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		  case '1':
			component = <EditAdmin />
			break;
		  case '2': 
			component = <EditSeller />
      		break;
      	  case '3': 
			component = <EditAdmin />
			break;
		  default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
