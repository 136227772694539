import {
  ADD_NEW_CLIENT,
  DELETE_CLIENT,
  GET_ALL_CLIENTS,
  GET_ALL_CLIENTS_SELLER,
  GET_CLIENT_ALUMN,
  GET_CLIENT_MAYORIST,
  GET_CLIENT_PUBLIC,
  UPDATE_CLIENT,
  GET_ALL_STUDENTS,
  GET_ALL_TYPECLIENTS,
  CREATE_TYPECLIENT,
  DELETE_TYPECLIENT,
  UPDATE_TYPECLIENT,
  SHOW_TYPECLIENT,
  } from "../../types";

export default function ClientsReducer(state, action) {
  switch (action.type) {
    case SHOW_TYPECLIENT:
      return {
        ...state,
        typeclient: action.payload,
        success: false,
        ErrorsAPI: [],
      };
      case GET_ALL_CLIENTS:
        return {
          ...state,
          clients: action.payload,
          success: false,
          ErrorsAPI: [],
        };
    case GET_ALL_STUDENTS:
      return {
        ...state,
        clients: action.payload,
        success: false,
        ErrorsAPI: [],
      };
      case GET_ALL_TYPECLIENTS:
        return {
          ...state,
          clients: action.payload,
          success: false,
          ErrorsAPI: [],
        };
    case GET_ALL_CLIENTS_SELLER:
      return {
        ...state,
        clients: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case GET_CLIENT_ALUMN:
      return {
        ...state,
        clients: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case GET_CLIENT_PUBLIC:
      return {
        ...state,
        clients: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case GET_CLIENT_MAYORIST:
      return {
        ...state,
        clients: action.payload,
        success: false,
        ErrorsAPI: [],
      };
    case ADD_NEW_CLIENT:
      return {
        ...state,
        clients: [action.payload, ...state.clients],
        success: true,
        ErrorsAPI: [],
      };
      case CREATE_TYPECLIENT:
      return {
        ...state,
        clients: [action.payload, ...state.clients],
        success: true,
        ErrorsAPI: [],
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        clients: action.payload,
        success: true,
        ErrorsAPI: [],
      };
      case UPDATE_TYPECLIENT:
        return {
          ...state,
          clients: action.payload,
          success: true,
          ErrorsAPI: [],
        };
      case DELETE_TYPECLIENT:
        return {
          ...state,
          clients: state.clients.filter((client) => client.id !== action.payload),
        };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter((client) => client.id !== action.payload),
      };

    default:
      return state;
  }
}
