import { ADD_NEW_BANK_ACCOUNT, CURRENT_BANK_ACCOUNT, DELETE_BANK_ACCOUNT, GET_ALL_BANK_ACCOUNTS,
     SHOW_ERRORS_API, UPDATE_BANK_ACCOUNT } from "../../types";

export default function BankAccountsReducer(state, action) {

switch (action.type) {
case GET_ALL_BANK_ACCOUNTS: 
    //sessionStorage.removeItem('selectedSchools');
    return{
        ...state,
        accounts: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case ADD_NEW_BANK_ACCOUNT:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }

case SHOW_ERRORS_API:

    return{
        ...state,
        ErrorsAPI: action.payload
    }

case CURRENT_BANK_ACCOUNT:

    //sessionStorage.setItem('selectedSchools',JSON.stringify(action.payload))
    return{
        ...state,
        account:action.payload,
    }

case UPDATE_BANK_ACCOUNT:

    //sessionStorage.removeItem('selectedSchools');
    return{
        ...state,
        success: true,
        ErrorsAPI: []
    }

case DELETE_BANK_ACCOUNT:

    return{
        ...state,
        accounts: state.accounts.filter(account => account.id !== action.payload)
    } 

default:
    return state; 
}
}