import React,{useEffect,useState,Fragment} from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import MethodGet, { MethodPut } from '../../Config/Services';
import ModalImage from 'react-modal-image';
import {Grid, Box} from '@material-ui/core';
import Spinner from '../../Complements/Spinner';
import CourseContext from "../../Reducers/Courses/CoursesContext";
import DetailsGraduate from '../../Complements/Image/DetailGraduate/GraduateDetail.svg'

const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    paddingInlineEnd: theme.spacing(3),
    paddingRight:theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: 'none',
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperDetalle: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  paperTitle: {
    background: '#9966cc'

  },
  formControl: {
		margin: theme.spacing(1),
		width:'100%'
	},
  papercard: {
    padding: theme.spacing(3),
    margin: 'auto',
    maxWidth: 'auto',
    backgroundColor:'#fff',
  },
  image: {
    width: '200px',
    height: '250px',
  },
  ticketimage: {
    width: 100,
    height: 120,
    maxWidth:100,
    maxHeight:120,
  },
  img: {
    margin: 'auto',
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  papercardtitle: {
   background: '#6f33ab',
   //background: '#9966cc',
    padding: 7,
    fontSize:'21pt',
    color:'white',
  },
  Tblpapercard: {
      padding: theme.spacing(3),
      margin: 'auto',
      Width: '100%',
      backgroundColor:'#fff',
  },
  textStructure:{
    borderBottom: '1px solid grey',
    whiteSpace:'nowrap',  
    textOverflow: 'ellipsis',
    overflow: 'hidden',

  }

}));


export default function GraduateDetail(props) {

  //obtenemos el producto de session storage
	let CourseStorageID = JSON.parse(sessionStorage.getItem("course"));
  const classes = useStyles();
  
  const [courses, saveCourses] = useState([]);
	const [cargando, spinnerCargando] = useState(false);

	useEffect(() => {
		let url = `/coursesDetails/${CourseStorageID}`;
		MethodGet(url)
			.then((res) => {
				saveCourses(res.data.data);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
  }, []);

  //  spinner de carga
	if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
            <Container className={classes.container}>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} justify="center">
              <Typography component="h2" variant="h2" style={{marginBottom:'2%'}}>
                Detalle del Diplomado
              </Typography>
            </Grid>
            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} spacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper className={classes.Tblpapercard}>
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{marginBottom:'2%', }} >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography component="h1" variant="h4" align="center"
                     className={classes.papercardtitle}
                     style={{marginBlockStart:'-5%'}}
                     >
                        Cursos Dentro Del Diplomado
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={9} lg={9} xl={9} >
                    <Typography component="h5" variant="h6">
                      Nombre
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3} xl={3} >
                    <Typography component="h5" variant="h6">
                      Costo Individual
                    </Typography>
                  </Grid>
                </Grid>
              {courses.map(course => (
                <Grid key={course.id}
                  container xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid item xs={8} sm={8} md={9} lg={9} xl={9} >
                    <Typography component="h3" variant="p" color="textSecondary">
                      {course.name} <hr/>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3} xl={3} >
                    <Typography component="h3" variant="p" color="textSecondary">
                        {
                          new Intl.NumberFormat('es-MX', {
                            style: 'currency',
                            currency: 'MXN',
                            minimumFractionDigits: 2
                          }).format(course.cost)
                        } <hr/>
                    </Typography>
                  </Grid>
                </Grid>
              ))}
                </Paper>
              </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Paper className={classes.Tblpapercard}>
                        <Grid item>
                          <img atl="Detalle Diplomado" src={DetailsGraduate} width="100%" height="50%" />
                        </Grid>
                      </Paper>
                    </Grid>
                </Grid>

            </Container>
        </main>
    </div>
</LayoutDashboard>
  );
}