import React, { useState, useEffect, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CardBranchInventory from "../../Components/CardsBranches/CardBranchInventory";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import {
  Button,
  Tooltip,
  Box,
  Fab,
  Typography,
  Grid,
  Hidden,
} from "@material-ui/core";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Icon from "@material-ui/icons/PictureAsPdf";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import FormControl from "@material-ui/core/FormControl";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import DateFnsUtils from "@date-io/date-fns";
import PrintIcon from "@material-ui/icons/Print";

/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  Link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  Table: {
    background: "Orange",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  fabGreen: {
    color: theme.palette.common.white,

    backgroundColor: "indigo",
    "&:hover": {
      backgroundColor: "#4d67f7",
    },
  },
  reportmodal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reportpaper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    width: "80%",
    height: "60%",
    padding: theme.spacing(2, 1, 1, 3),
  },
  btnPlus: {
    position: "relative",
  },
}));

const InventoryAdmin = (props) => {
  const classes = useStyles();

  return (
    <LayoutDashboard>
      {/**
       *
       * Aqui es donde aplica el cambio de cards que renderizan las sucursales para los clientes
       */}
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: "15px" }}
      >
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 70 }}>
          <Typography align="center" component="h2" variant="h2">
            Selecciona una Sucursal
          </Typography>
        </Grid>

        <CardBranchInventory />
      </Grid>
      {/* separacion */}
    </LayoutDashboard>
  );
};

export default InventoryAdmin;
