import React, { useState, useEffect, Fragment } from 'react';
/**Importar configuracion axios */ 
import clienteAxios from '../../Config/Axios';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';


export default function SelectState(props){
    const [states, saveStates] = useState([]);
    const [error, guardarError] =  useState(true);
    useEffect(() => {
        const obtenerEstados = async () => {
           await clienteAxios.get('/states')
           .then(res=>{
           saveStates(res.data.data)
           })
           .catch(error=>{
              console.log(error);
           })

           if(props.estadoId){
               guardarError(false);
           }
        }
        obtenerEstados();
    }, []);


    const detectarCambiosEstado = (e) => {
        if(e.target.value){
          guardarError(false)
        }
        props.detectarCambiosEstado(e)
    }

    return (
        <Fragment>
            <InputLabel  fullWidth>Estados*</InputLabel>
            {
                props.estadoId ? (

                <Select
                    label="Estados"
                    fullWidth
                    onChange={detectarCambiosEstado}
                    value={props.estadoId.state_id}
                  >
                    <MenuItem disabled>Selecciona un estado de la lista</MenuItem>
                    {
                    states.map(estado=><MenuItem key={estado.id} value={estado.id}>{estado.name}</MenuItem>)
                    }
                 </Select>

                ):(

                <Select
                    label="Estados"
                    fullWidth
                    onChange={detectarCambiosEstado}
                >
                <MenuItem disabled>Selecciona un estado de la lista</MenuItem>
                    {
                     states.map(estado=><MenuItem key={estado.id} value={estado.id}>{estado.name}</MenuItem>)
                    }
                </Select>
                

                )
            }
        
                {
                    error ? (
                         <p style={{color:'red'}}>El estado es un campo requerido</p>
                    ) : null
                }
               
        </Fragment>

    )

}

