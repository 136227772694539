import React, { useEffect, useState, useRef, useContext } from "react";
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Swal from 'sweetalert2';
import Spinner from '../../Complements/Spinner';
import headerConfig from "../../Config/imageHeaders";
import MethodGet, { MethodPost } from "../../Config/Services";
import { useForm } from "react-hook-form";
import AuthContext from '../../Context/autenticacion/authContext';
import { 
	Grid, 
	Box, 
	Paper,
	Container, 
	Tooltip, 
	Button, 
	TextField, 
	Typography, 
	IconButton,
	InputLabel,
	FormControl
} from "@material-ui/core";

import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import CustomInput from "../../Components/CustomInput/CustomInput";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import CardFooter from "../../Components/Card/CardFooter";


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingLeft: theme.spacing(4),
		//marginBottom: theme.spacing(10),
	},
	typography: {
		margin: theme.spacing(1),
		//paddingLeft: theme.spacing(30),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(9),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		background: 'indigo',
		'&:hover': {
			background: '#5902CF',
		},
		color: 'white',
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	input: {
		display: 'none',
	},
	containerImageProfile:{
		display:'flex',
		flexDirection:'row',
		flexWrap:'wrap',
		justifyContent:'space-around',

	},
	PaperFormAdd:{
	background:'white',
	color:'black',
	boxShadow: '2px 4px 4px #cacaca',
	},
	cardCategoryWhite: {
		color: "rgba(255,255,255,.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0"
	  },
	  cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none"
	  }
}));

const type = [
	{ title: 'Administrador' },
	{ title: 'Vendedor' },
];

export default function ChangePassword(props) {
    const classes = useStyles();
    const [cargando, spinnerCargando] = useState(false);
  
    const authContext = useContext(AuthContext);
    const { usuario, cerrarSesion } = authContext;
  
    //Funcion para validaciones
    const { register, errors, handleSubmit, watch } = useForm({});
    const password = useRef({});
    password.current = watch("password", "");
  
      const [image, setImage] = useState({
          urlPhoto: 'https://www.adl-logistica.org/wp-content/uploads/2019/07/imagen-perfil-sin-foto-300x300.png',
          image: ''
      });
      const imageDefault = 'https://www.adl-logistica.org/wp-content/uploads/2019/07/imagen-perfil-sin-foto-300x300.png';
      
      //funcion para guardar la imagen y obtener la vista previa
      const handleChangeImage = (e) => {
          setImage({ ...image, urlPhoto: URL.createObjectURL(e.target.files[0]), 
                image: e.target.files[0]
          });
      }
  
      useEffect(() => {
          if(usuario)
          {
              spinnerCargando(true);
              setImage({urlPhoto: usuario.image});
          }
      }, [usuario]);

  const onSubmit = (data, e) => {
    e.preventDefault();

		//crear un formdata
		const formData = new FormData();
		formData.append("password", data.password);
		formData.append("c_password", data.c_password);
		formData.append("_method", "PATCH");

		let url = `/users/${usuario.id}`; //cambiar la url completa
		MethodPost(url, formData, { headerConfig })
		.then((res) => {
			Swal.fire({
				title: 'Contraseña Editada Exitosamente',
				text: res.data.message,
				icon: 'success',
				timer: 2000,
				showConfirmButton: false,
			});
			props.history.push('/');
		})
		.catch((error) => {
			Swal.fire({
				title: 'Error',
				text: error.response.data.error,
				icon: 'error',
			});
		});
  };


  //  spinner de carga
if (!cargando) return <Spinner />;

  return (
		<LayoutDashboard>
			<div className={classes.root}>
			<Container className={classes.container}>
				<Paper className={classes.PaperFormAdd}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className={classes.form}
					noValidate
				>
				<GridContainer>
					<GridItem xs={12} sm={12} md={8}>
						<Card>
							<CardHeader color="primary">
            					<h2 className={classes.cardTitleWhite}>Editar Usuario</h2>
            					<p className={classes.cardCategoryWhite}>Completa correctamente los datos</p>
            				</CardHeader>
							<CardBody>
								<Grid container spacing={2}>

									<Grid item xs={12} sm={6}>
			              			  <TextField
			              			    margin="normal"
			              			    placeholder="Ingresa Tu Nueva Contraseña"
			              			    fullWidth
			              			    variant="outlined"
			              			    name="password"
			              			    type="password"
			              			    autoComplete="name"
			              			    autoFocus
			              			    InputLabelProps={{
			              			      shrink: true,
			              			    }}
			              			    error={!!errors.password}
			              			    inputRef={register({
			              			      required: {
			              			        value: false,
			              			      },
			              			    })}
			              			  />
			              			<p style={{ color: 'red' }}>{errors?.password?.message}</p>
			              		</Grid>
									<Grid item xs={12} sm={6}>
			              				<TextField
			              				  margin="normal"
			              				  variant="outlined"
			              				  placeholder="Confirma La Nueva Contraseña"
			              				  fullWidth
			              				  name="confirmpassword"
			              				  type="password"
			              				  autoComplete="name"
			              				  autoFocus
			              				  error={!!errors.confirmpassword}
			              				  InputLabelProps={{
			              				    shrink: true,
			              				  }}
			              				  inputRef={register({
			              				    validate: value =>
			              				      value === password.current || "Las contraseñas no coinciden"
			              				  })}
			              				/>
			              				<p style={{ color: 'red' }}>{errors?.confirmpassword?.message}</p>
			              			</Grid>
								</Grid>
            				</CardBody>

							<CardFooter>
								<Grid container spacing={3} justify="center">
									<Grid item spacing={3} xs={12} sm={12} md={6}>
										<Button
											type="submit"
											fullWidth
											variant="outlined"
											className={classes.submit}
										>
											ACTUALIZAR CONTRASEÑA
										</Button>
									</Grid>
								</Grid>
            				</CardFooter>
							
						</Card>
					</GridItem>
					<GridItem xs={12} sm={12} md={4}>
        			  	<Card profile style={{marginTop:'16%'}}>
        			    	<CardAvatar profile>
									<img src={image.urlPhoto} alt="User" width="100%" height="100%"/>
        			    	</CardAvatar>
        			    	<CardBody profile>
						  		<h2 className={classes.cardTitle}>Editando Contraseña De: </h2>
        			      		<h3 className={classes.cardTitle}> {usuario.name}</h3>
        			      		<p className={classes.description}>
								  Recuerda que la nueva contraseña será con la que iniciarás sesión en el futuro
        			      		</p>
        			    	</CardBody>
        			  	</Card>
        			</GridItem>
				</GridContainer>
			</form>
			</Paper>
		</Container>
	</div>
		</LayoutDashboard>
	);
}									 
