import React, { useState, useEffect, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from "@material-ui/core";

/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
/**
 *
 */

import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
/**Importar Select State */
import SelectState from "../Branch_Offices/SelectState";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
/**Importar Select Municipalities */
import SelectMunicipalities from "../Branch_Offices/SelectMunicipality";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
import SchoolContext from "../../Reducers/Schools/SchoolContext";
import { useForm } from "react-hook-form";
import Barcode from "react-barcode";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  /**
   * identificar Codigo de FORMS estilo para formularios
   */
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

function AddSchool(props) {
  const classes = useStyles();

  const formSchoolContext = useContext(SchoolContext);

  const { ErrorsAPI, success, AddSchool } = formSchoolContext;

  const { register, errors, handleSubmit } = useForm();

  const [estadoId, guardarPaisId] = useState({
    state_id: "",
  });
  const [municipality, guardarMunicipioId] = useState({
    municipality_id: "",
  });

  const detectarCambiosEstado = (e) => {
    guardarPaisId({ state_id: e.target.value });
  };

  const detectarCambiosMunicipios = (e) => {
    guardarMunicipioId({ municipality_id: e.target.value });
  };

  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const [office, saveOffice] = useState();

  const detectarCambiosOffice = (e) => {
    saveOffice(e.target.value);
  };

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de escuelas
    if (success) {
      //console.log("Retorna");
      props.history.push("/Escuelas");
    }
    //eslint-disable-next-line
  }, [success]);

  //funcion que agrega una escuela
  const onSubmit = (data, e) => {
    e.preventDefault();
    data.image = image.image;
    data.municipality_id = municipality.municipality_id;
    data.state_id = estadoId.state_id;
    data.shop_id = office;

    if (estadoId.state_id !== "" && municipality.municipality_id !== "") {
      //enviamos la data a la funcion que se encarga de guardar los nuevos registros
      AddSchool(data);
    } else {
      Swal.fire({
        title: "Error",
        text: "Todos los campos son Obligatorios",
        icon: "error",
      });
    }
  };

  return (
    <LayoutDashboard>
      <div onSubmit={handleSubmit(onSubmit)}>
        <form noValidate>
          {/**
           * identificar Codigo de FORMS
           */}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 100 }}
          >
            {/**
             * identificar Codigo de FORMS
             */}
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardHeader color="primary">
                  <h2 className={classes.cardTitleWhite}>
                    Agregar Nueva Escuela
                  </h2>
                  <p className={classes.cardCategoryWhite}>
                    Completa correctamente los datos
                  </p>
                </CardHeader>
                <CardBody>
                  {/**
                   * identificar Codigo de FORMS
                   */}{" "}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Nombre"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        error={!!errors.name}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "El Nombre es requerido",
                          },
                          minLength: {
                            value: 4,
                            message: "Minimo 4 caracteres",
                          },
                          maxLength: {
                            value: 255,
                            message: "Maximo 255 caracteres",
                          },
                          pattern: {
                            value:
                              /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                            message: "Unicamente carácteres alfabéticos",
                          },
                        })}
                      />
                      <p>{errors?.name?.message}</p>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="direction"
                        label="Direccion"
                        name="direction"
                        autoComplete="name"
                        autoFocus
                        error={!!errors.direction}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "La Direccion es requerida",
                          },
                          minLength: {
                            value: 4,
                            message: "Minimo 4 caracteres",
                          },
                          maxLength: {
                            value: 255,
                            message: "Maximo 255 caracteres",
                          },
                        })}
                      />
                      <p>{errors?.direction?.message}</p>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="number_phone"
                        label="Teléfono"
                        name="number_phone"
                        autoComplete="phone"
                        type="number"
                        autoFocus
                        error={!!errors.number_phone}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "El Teléfono es requerido",
                          },
                          minLength: {
                            value: 10,
                            message: "Solo puedes introducir 10 digitos",
                          },
                          maxLength: {
                            value: 10,
                            message: "Solo puedes introducir 10 digitos",
                          },
                        })}
                      />
                      <p>{errors?.number_phone?.message}</p>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <SelectState
                          detectarCambiosEstado={detectarCambiosEstado}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <SelectMunicipalities
                          estadoId={estadoId}
                          detectarCambiosMunicipios={detectarCambiosMunicipios}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                      >
                        <SelectBranchOffice
                          detectarCambiosOffice={detectarCambiosOffice}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Card profile>
                    <CardAvatar profile>
                      <img
                        src={image.urlPhoto}
                        alt="User"
                        width="100%"
                        height="100%"
                      />
                    </CardAvatar>
                    <CardBody profile>
                      <input
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        name="image"
                        accept="image/png, image/jpg, image/jpeg"
                        onChange={handleChangeImage}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <Tooltip
                            title="seleccionar imagen"
                            aria-label="seleccionar imagen"
                          >
                            <PhotoCamera />
                          </Tooltip>
                        </IconButton>
                      </label>
                      <InputLabel>Selecciona Imagen de la Escuela</InputLabel>
                      <h2 className={classes.cardTitle}>Nueva Escuela: </h2>
                      <h3 className={classes.cardTitle}></h3>
                      <p className={classes.description}>
                        Recuerda que la informacion que registres de esta
                        Escuela puedes editarla en un futuro.
                      </p>
                    </CardBody>
                  </Card>
                  <Grid container spacing={3} justify="center">
                    <Grid item spacing={3} xs={12} sm={12} md={6}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        className={classes.submit}
                      >
                        GUARDAR
                      </Button>
                    </Grid>
                  </Grid>
                  {/**
                   * identificar Codigo de FORMS
                   */}
                </CardBody>
              </Card>
            </GridItem>
            {/**
             * identificar Codigo de FORMS
             */}
            {/**
             * identificar Codigo de FORMS
             */}
          </Grid>
          {/**
           * identificar Codigo de FORMS 123456789
           */}
        </form>
      </div>
    </LayoutDashboard>
  );
}

export default withRouter(AddSchool);
