import {
    ADD_NEW_CATEGORY,
    CURRENT_CATEGORY,
    DELETE_CATEGORY,
    GET_ALL_CATEGORIES,
    SHOW_ERRORS_API,
    UPDATE_CATEGORY
} from "../../types";

export default function CategoryReducer(state, action) {

    switch (action.type) {
        case GET_ALL_CATEGORIES:
            //sessionStorage.removeItem('selectedSchools');
            return {
                ...state,
                categories: action.payload,
                success: false,
                ErrorsAPI: [],
            }

        case ADD_NEW_CATEGORY:
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }

        case SHOW_ERRORS_API:

            return {
                ...state,
                ErrorsAPI: action.payload
            }

        case CURRENT_CATEGORY:

            return {
                ...state,
                category: action.payload,
            }

        case UPDATE_CATEGORY:

            //sessionStorage.removeItem('selectedSchools');
            return {
                ...state,
                success: true,
                ErrorsAPI: []
            }

        case DELETE_CATEGORY:

            return {
                ...state,
                categories: state.categories.filter(category => category.id !== action.payload)
            }

        default:
            return state;
    }
}