import React, { Fragment, useState, useEffect } from "react";
/**Importar configuracion axios */
import clienteAxios from "../../Config/Axios";
import { Grid } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useSelectUser = (props) => {
  //state del custom hook
  const [error, guardarError] = useState(true);
  const id = props.id;
  const [users, saveUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      await clienteAxios
        .get("/sellers/" + id)
        .then((res) => {
          saveUsers(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      if (props.nameUser) {
        guardarError(false);
      }
    };
    getUsers();
  }, [id]);
  const detectarCambiosUser = (e) => {
    if (e.target.value) {
      guardarError(false);
    }
    props.detectarCambiosUser(e);
  };
  return (
    <Fragment>
      <InputLabel fullWidth>Vendedor*</InputLabel>
      {props.nameTypeClients ? (
        <Select
          label="Vendedor"
          fullWidth
          variant="outlined"
          onChange={detectarCambiosUser}
        >
          <MenuItem disabled>Selecciona el Vendedor</MenuItem>
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          label="Vendedores"
          fullWidth
          variant="outlined"
          onChange={detectarCambiosUser}
        >
          <MenuItem disabled>Selecciona el Vendedor</MenuItem>
          {users.map((user) => (
            <MenuItem key={user.id} value={user.id}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      )}

      {error ? (
        <p style={{ color: "red" }}>El Vendedor es un Campo Requerido</p>
      ) : null}
    </Fragment>
  );
};

export default useSelectUser;
