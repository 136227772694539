import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import LocalOffer from "@material-ui/icons/LocalOffer";
import { Button, Tooltip } from "@material-ui/core";
/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import imagedefault from "../images/default_image.png";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
}));

export default function CardBranchClients(props) {
  const classes = useStyles();

  //sucursales = state, guardarBranch =funcion para guardar en el state;
  const [offices, guardarBranch] = useState([]);
  const [cargando, spinnerCargando] = useState(false);

  //query a la api
  useEffect(() => {
    let url = "/branches";
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <>
      {offices.map((office) => (
        <Grid
          style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 20 }}
          xs={12}
          sm={4}
          md={3}
          lg={3}
          xl={2}
        >
          <Card>
            <CardActionArea
              component={RouterLink}
              to={`SucursalVentas/${office.id}`}
            >
              <CardMedia
                component="img"
                height="200"
                alt={office.name}
                image={office.image ? office.image : imagedefault}
                title={office.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {office.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Direccion: {office.direction}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Telefono: {office.number_phone}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </>
  );
}
