import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import ClientsAdmin from './ClientsAdmin';
import ClientsSeller from './ClientsSeller';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import ClientsSubAdmin from './ClientsSubAdmin';

export default function ClientIndex() {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		  case '1':
			component = <ClientsAdmin />
			break;
		  case '2': 
			component = <ClientsSeller />
      break;
      case '3': 
			component = <ClientsAdmin />
			break;
		  default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
