import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useContext,
} from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  Button,
  Tooltip,
  Hidden,
  Grid,
  Typography,
  Box,
  ButtonBase,
  Paper,
  Fab,
} from "@material-ui/core";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import AddIcon from "@material-ui/icons/Add";
import { green } from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import Icon from "@material-ui/icons/PictureAsPdf";
//BOTON DE AGREGAR
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

//IMPORTACION DE TABS MATERIAL UI
/**Importacion de tabs */
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import { Link } from "react-router-dom";

// importar MethodGet
import MethodGet from "../../Config/Services";
import { MethodDelete } from "../../Config/Services";

/**importar spinner */
import Spinner from "../../Complements/Spinner";
import Swal from "sweetalert2";
import { Link as RouterLink } from "react-router-dom";

/**Importar Componentes Tablas */
import MisInscripciones from "./tablaInscripciones";
//import MisAplicaciones from './tablaAplicaciones';
import MisProductos from "./tablaVentaProductoAdmin";
import ClientsContext from "../../Reducers/Clients/ClientsContext";
import AlertNotAuthorized from "../../Complements/AlertNotAuthorized";

import { useForm } from "react-hook-form";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import PictureAsPdfTwoToneIcon from "@material-ui/icons/PictureAsPdfTwoTone";
import InfoIcon from "@material-ui/icons/Info";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    padding: theme.spacing(5),
  },
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperTitle: {
    background: "#424242",
  },
  rootTab: {
    backgroundColor: theme.palette.background.paper,
  },
  Button: {
    background: "#3537DB",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#5902CF",
    },
    color: "white",
    width: theme.spacing(16),
  },
  Button2: {
    background: "#3537DB",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#5902CF",
    },
    color: "white",
    width: theme.spacing(5),
  },
  formControl: {
    width: "100%",
  },
  listButton: {
    position: "relative",
    backgroundColor: "#5B009C",
    color: "white",
    height: "35px",
    marginTop: "3%",
    "&:hover": {
      background: "#6800B3",
      color: "white",
    },
    zIndex: 3,
  },
  // Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "80px",
    borderRadius: "15px",
    paddingTop: "5px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "40px",
    zIndex: 2,
    position: "relative",
    marginRight: -20,
  },
  btnReverse: {
    display: "absolute",
    marginTop: "40px",
    zIndex: 2,
    position: "relative",
    marginLeft: -20,
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  fabReverse: {
    color: "white",
    backgroundColor: "#dc6daf",
    "&:hover": {
      backgroundColor: "#bd5d96",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function SalesSeller(props) {
  const { id } = props.match.params; //Se toma el ID de la Sucursal para mostrar sus productos

  const formTypeclientscontext = useContext(ClientsContext);

  const { clients, ErrorsAPI, success, gettypeclients } =
    formTypeclientscontext;
  const classes = useStyles();
  useEffect(() => {
    gettypeclients(id);
  }, [success]);
  const [business, guardarRules] = useState([]);
  useEffect(() => {
    let url = `/rulesperbranch/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarRules(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#f44336",
      },
      primary: {
        main: "#eeeeee",
      },
    },
  });

  const [value, setValue] = React.useState(0);
  const [error, guardarError] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data, e) => {
    e.preventDefault();
  };
  //acciones para abrir el bogton de agregar
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const [branch, guardarBranch] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name, direction, number_phone, state_id, municipality_id } = branch;

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const generarReporte = (e) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Generar El Reporte De Caja Diario?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Generar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        clienteAxios
          .get(`/reporte-caja-diario/${id}`, { responseType: "blob" })
          .then((response) => {
            fileDownload(response.data, "Reporte Corte de Caja Diario.pdf");
            guardarError(false);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              Swal.fire({
                icon: "error",
                title: "Algo sucedio",
                text: "No cuentas con ventas suficientes el dia de hoy para generar el corte de caja!!",
              });
            } else if (error.response.status === 422) {
              guardarError(true);
            }
          });
      }
    });
  };
    if (Object.keys(business).length === 0) {
      return (
        <AlertNotAuthorized
          mensaje={
            <p>
              No puedes Generar ventas almenos que tengas una regla de negocio{" "}
              <Link to={"/ReglasNegocio/" + id} style={{ color: "#3f51b5" }}>
                Da Clic Aqui para registrar una regla de negocio
              </Link>
            </p>
          }
        />
      );
    } else {
      return (
        <LayoutDashboard>
          {/**
           * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
           *
           */}
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                marginTop: 85,
                background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
                color: "white",
                borderRadius: 15,
                marginLeft: 25,
                marginRight: 20,
              }}
            >
              <Typography component="h1" variant="h4">
                {"Ventas de la sucursal " + name}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Todos los registros de Ventas de la Sucursal
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              align="right"
              style={{ marginRight: 30, marginTop: -30 }}
            >
              <Fab
                className={classes.fabReverse}
                aria-label="PDF"
                size="large"
                onClick={() => generarReporte()}
                endIcon={<Icon>send</Icon>}
              >
                <Tooltip
                  title="Generar corte de caja diario"
                  aria-label="Generar corte de caja diario"
                >
                  <PictureAsPdfOutlinedIcon size="large" />
                </Tooltip>
              </Fab>
              <Fab
                className={classes.fabGreen}
                color="primary"
                aria-label="add"
                size="medium"
                href={"/Agregar_VentaProducto/" + id}
              >
                <Tooltip title="Venta de Tienda" aria-label="Venta de Tienda">
                  <AddIcon />
                </Tooltip>
              </Fab>
            </Grid>
          </Grid>
          {/**
           * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
           *
           */}

          <Grid Container spacing={3}>
            <div>
              <div className={classes.rootTab}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <MisProductos id={id} />
                </TabPanel>
              </div>
            </div>
          </Grid>
        </LayoutDashboard>
      );
    }
}
