import React, { useEffect, useState, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import ProductInfo from "./ProductInfo";
import ProductsList from "./ProductsList";
import Button from "@material-ui/core/Button";
import MethodGet from "../../Config/Services";
import { useForm } from "react-hook-form";
import useSelectProducts from "../SelectOptions/SelectProducts";
import DeparturesContext from "../../Reducers/Departures/DeparturesContext";
import ComponentsBarcode from "./ComponentsBarcode";
import { Grid, Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
}));

function SalesAddProduct(props) {
  const classes = useStyles();
  const { id } = props;
  const [productoID, guardarProductoID] = useState("");
  const [ventas, guardarVenta] = useState([]);
  const [product, SelectProduct] = useSelectProducts({
    stateInicial: "",
    branchID: id,
  });
  const [seller, saveSeller] = useState({
    seller_id: "",
  });
  //console.log(product,seller)
  const [subtotal, SaveSubTotal] = useState(0);

  const formDeparturesContext = useContext(DeparturesContext);
  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  const { success, AddDeparture } = formDeparturesContext;
  const [sub, guardarTotal] = useState(0);
  const [Desc, SaveDesc] = useState(0);
  const [Cantdesc, SaveCantdesc] = useState(0);

  const [branch, guardarBranch] = useState({});
  //Extraer valores de branch(destructuracion)
  const { name } = branch;
  const [open, setOpen] = useState(false);

  const [typeProduct, saveTypeProduct] = useState();

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de salones
    if (success) {
      //console.log("Retorna");
      props.history.push(`/Salidas/Productos/` + id);
    }
    //eslint-disable-next-line
  }, [success]);

  const onSubmit = (data, e) => {
    e.preventDefault();

    var products_list = JSON.stringify(ventas);

    data.branch_office_id = id;
    data.products_list = products_list;
    data.descuento = Desc;
    data.porcentaje = Cantdesc;

    AddDeparture(data);
  };
  //console.log(subtotal);
  useEffect(() => {
    let totalSubTotal =
      Number(subtotal)
    guardarTotal(totalSubTotal);
  }, [subtotal]);
  return (
    <LayoutDashboard>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
        noValidate
      >
        <Grid item xs={12}>
          <Paper
            style={{
              background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
              color: "white",
              borderRadius: 15,
              padding: 20,
              textAlign: "center",
            }}
          >
            <Typography variant="h4" component="h4">
              Nueva Salida de Productos de la Sucursal {name}
            </Typography>
          </Paper>
        </Grid>
        <Grid container spacing={2} style={{ padding: 10 }}>
          <Grid item xs={12} sm={12} md={4} xl={2}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="authorized_person"
              label="Persona que autoriza"
              name="authorized_person"
              autoComplete="authorized_person"
              autoFocus
              error={!!errors.authorized_person}
              inputRef={register({
                required: true,
                minLength: 5,
                maxLength: 255,
              })}
            />
            <p>{errors?.authorized_person?.message}</p>
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={2}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="destination_person"
              label="Persona que recibe los productos"
              name="destination_person"
              autoComplete="destination_person"
              autoFocus
              error={!!errors.destination_person}
              inputRef={register({
                required: true,
                minLength: 5,
                maxLength: 255,
              })}
            />
            <p>{errors?.destination_person?.message}</p>
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={2}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="description"
              label="Descripción de salida de productos"
              name="description"
              autoComplete="description"
              autoFocus
              error={!!errors.description}
              inputRef={register({
                required: true,
                minLength: 5,
                maxLength: 255,
              })}
            />
            <p>{errors?.description?.message}</p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} xl={2} style={{marginTop:"10px"}}>
            <ComponentsBarcode
              ventas={ventas}
              productoID={product.value}
              saveTypeProduct={saveTypeProduct}
              guardarProductoID={guardarProductoID}
              typeProduct={typeProduct}
              guardarVenta={guardarVenta}
              id={id}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={2} style={{marginTop:"10px"}}>
            <SelectProduct id={id} />
          </Grid>
          <Grid item xs={12} sm={12} md={2} xl={2} style={{marginTop:"10px"}}>
            <ProductInfo
              productoID={product.value}
              clienteID={seller.seller_id}
              ventas={ventas}
              guardarVenta={guardarVenta}
              guardarProductoID={guardarProductoID}
            />
          </Grid>
        </Grid>
        
        <Grid item xs={12} md={12}>
          <ProductsList
            ventas={ventas}
            savesubtotal={SaveSubTotal}
            guardarVenta={guardarVenta}
            subtotal={subtotal}
            Desc={Desc}
            SaveDesc={SaveDesc}
            SaveCantdesc={SaveCantdesc}
            Cantdesc={Cantdesc}
          />
          <Grid item xs={12} style={{ marginTop: 15 }}>
            {ventas.length === 0 ? (
              <Button
                type="submit"
                fullWidth
                className={classes.submit}
                variant="contained"
                disabled
                color="primary"
                style={{ marginBottom: "2%" }}
              >
                Debes Agregar Productos
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                className={classes.submit}
                variant="contained"
                color="primary"
                style={{ marginBottom: "2%" }}
              >
                GUARDAR
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </LayoutDashboard>
  );
}

export default SalesAddProduct;
