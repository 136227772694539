import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../Context/autenticacion/authContext';

const RutaPrivada = ({ component: Component, ...props  }) => {

    const authContext = useContext(AuthContext);
    const { autenticado, cargando, usuarioAutenticado } = authContext;

    useEffect(() => {
        usuarioAutenticado();
      
    }, []);
    const token = localStorage.getItem('token');

    return ( 
        <>
            <Route { ...props } render={ props => !token &&  (
                <Redirect to="/login" />
            ) } />

            <Route { ...props } render={ props => !autenticado && !cargando ?  (
                <Redirect to="/login" />
            )  : (
                <Component {...props} />
            ) } />
        </>
     );
}
 
export default RutaPrivada;