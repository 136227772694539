import React, { useEffect, useState, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import MethodGet from "../../Config/Services";
import ModalImage from "react-modal-image";
import { Grid, Box } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import clienteAxios from "../../Config/Axios";
import Spinner from "../../Complements/Spinner";
import InfoCourses from "../../Complements/Image/DetailCourses/InfoCourses.svg";
import Savings from "../../Complements/Image/DetailCourses/Savings.svg";
import ButtonBase from "@material-ui/core/ButtonBase";
import Swal from "sweetalert2";
import { Button, Tooltip, Hidden } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import fileDownload from "js-file-download";
import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorIcon from "@material-ui/icons/Error";

import InscriptionsContext from "../../Reducers/Inscriptions/InscriptionsContext";
import SchedulesContext from "../../Reducers/Schedules/SchedulesContext";
import ModalPayments from "../Inscriptions/ModalPayments";
import MaterialTable from "material-table";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(1),
    paddingInlineEnd: theme.spacing(3),
    paddingRight: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperDetalle: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  paperTitle: {
    background: "#9966cc",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  papercard: {
    padding: theme.spacing(3),
    maxWidth: "auto",
    backgroundColor: "#fff",
  },
  image: {
    width: "200px",
    height: "250px",
  },
  ticketimage: {
    width: 100,
    height: 120,
    maxWidth: 100,
    maxHeight: 120,
  },
  img: {
    margin: "auto",
    display: "flex",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  papercardtitle: {
    background: "#6f33ab",
    //background: '#9966cc',
    padding: 7,
    fontSize: "21pt",
    color: "white",
  },
  Tblpapercard: {
    padding: theme.spacing(3),
    Width: "100%",
    backgroundColor: "#fff",
  },
  textStructure: {
    borderBottom: "1px solid grey",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export default function IndividualSheduleDetail(props) {
  const { id } = props.match.params;
  const classes = useStyles();
  const [error, guardarError] = useState(false);
  const [course, saveCourse] = useState([]);
  const [PaidTransfer, savetotalPaidTransfer] = useState("");
  const [PaidCredit, savetotalPaidCredit] = useState("");
  const [PaidCash, savetotalPaidCash] = useState("");
  const [CoursePaid, savetotalCoursePaid] = useState("");
  const [School, saveSchool] = useState([]);
  const [TotalCourse, saveTotalCourse] = useState("");
  const [cargando, spinnerCargando] = useState(false);

  const [open, setOpen] = useState(false);

  const [disableButton, setDisabledButton] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    guardarMetodoPago({
      amount: "",
      change: "0",
      cash_income: "0",
      card_income: "0",
      transfer_income: "0",
    });
    setOpen(false);
    setDisabledButton(false);
  };

  //Leer los datos del Modal
  const obtenerInformacionPago = (e) => {
    guardarMetodoPago({
      ...informacionMetodoPago,
      [e.target.name]: e.target.value,
    });
  };
  const [informacionMetodoPago, guardarMetodoPago] = useState({
    amount: "0",
    change: "0",
    cash_income: "0",
    card_income: "0",
    transfer_income: "0",
    reference: "",
  });
  const [totalDescuento, guardarTotalDescuento] = useState(0);
  const [totalPagado, guardarTotalPagado] = useState(0);

  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  const [imageReference, setImageReference] = useState({
    urlPhotoReference:
      "https://pisouniversitario.com/template/images/page-404.jpg",
    imageReference: "",
  });

  const [data, setData] = useState({});
  const [couponInfo, saveCouponInfo] = useState("");

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const handleChangeImageReference = (e) => {
    setImageReference({
      ...imageReference,
      urlPhotoReference: URL.createObjectURL(e.target.files[0]),
      imageReference: e.target.files[0],
    });
  };

  const [bank_account, saveBankAccount] = useState("");
  const [disableCoupon, setDisabled] = useState(true);

  const detectarCambiosBancos = (e) => {
    saveBankAccount(e.target.value);
  };

  const StateSchedulesContext = useContext(SchedulesContext);
  const { GetStudentsSchedule, students, success } = StateSchedulesContext;

  const StateInscriptionsContext = useContext(InscriptionsContext);
  const { SaveCurrentInscription, DeleteInscription, AddInPayment } =
    StateInscriptionsContext;

  useEffect(() => {
    GetStudentsSchedule(id);
    setDisabledButton(false);
  }, [success]);

  const SelectInscription = (inscriptionID) => {
    SaveCurrentInscription(inscriptionID);
  };

  let InscriptionStorageID = JSON.parse(sessionStorage.getItem("inscription"));

  const SelectInscriptionPayment = (inscriptionID, total, total_paid) => {
    SaveCurrentInscription(inscriptionID);
    guardarTotalDescuento(total);
    guardarTotalPagado(total_paid);
    setOpen(true);
  };

  const addPayment = (e) => {
    if (
      totalDescuento < informacionMetodoPago.amount &&
      informacionMetodoPago.cash_income < totalDescuento
    ) {
      toast.warn(
        <div>
          <ErrorIcon /> No puedes pagar mas del monto total
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.amount < 0) {
      toast.warn(
        <div>
          <ErrorIcon /> No puedes ingresar valores negativos
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.amount == 0) {
      toast.warn(
        <div>
          <ErrorIcon /> Debes ingresar al menos un monto de pago
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (bank_account == "") {
      toast.warn(
        <div>
          <ErrorIcon /> Debes seleccionar una cuenta bancaria
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    setDisabledButton(true);
    data.id = InscriptionStorageID;
    data.cash_income = informacionMetodoPago.cash_income;
    data.card_income = informacionMetodoPago.card_income;
    data.transfer_income = informacionMetodoPago.transfer_income;
    data.image = image.image;
    data.imageReference = imageReference.imageReference;
    data.change = informacionMetodoPago.change;
    data.reference = informacionMetodoPago.reference;
    data.invoice = informacionMetodoPago.invoice;
    data.bank_account = bank_account;
    handleClose();
    //console.log(data);
    AddInPayment(data);
  };

  const generarTicket = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Generar El Ticket De Pagos?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Generar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        //Cunsume API de Back-end en laravel para generar tiket de venta
        clienteAxios
          .get(`/ticketinscription/${id}`, { responseType: "blob" })
          .then((response) => {
            fileDownload(response.data, "TicketInscripcion.pdf");
            guardarError(false);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              Swal.fire({
                icon: "error",
                title: "Algo sucedio",
                text: "No se cuenta con suficiente información para generar el reporte",
              });
            } else if (error.response.status === 422) {
              guardarError(true);
            }
          });
      }
    });
  };

  useEffect(() => {
    let url = `/scheduleCourseDetais/${id}`;
    MethodGet(url)
      .then((res) => {
        saveCourse(res.data.course);
        savetotalPaidTransfer(res.data.total_paid_transfer);
        savetotalPaidCredit(res.data.total_paid_credit);
        savetotalPaidCash(res.data.total_paid_cash);
        savetotalCoursePaid(res.data.total_course_paid);
        saveSchool(res.data.school);
        saveTotalCourse(res.data.total_course);
        spinnerCargando(res.data.course);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  const CourseCost = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(course.cost);

  const TotalPagadoActual = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(CoursePaid);

  const totalPaidTransfer = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(PaidTransfer);

  const totalPaidCredit = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(PaidCredit);

  const totalPaidCash = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(PaidCash);

  const totalCourse = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(TotalCourse);
  //console.log(`el pago es ` + (CoursePaid))

  //const IncompletePaid = (parseInt(students.total) + parseInt(students.total_paid))
  //  spinner de carga
  if (!cargando) return <Spinner />;
  return (
    <LayoutDashboard>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          justify="center"
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 80,
            borderRadius: 15,
            paddingLeft: 25,
            paddingRight: 25,
            marginBottom: 10,
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            className={classes.typography}
          >
            Detalle de Curso Individual
          </Typography>
        </Grid>
      </Grid>
      <Container>
      <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:10}}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              className={classes.papercardtitle}
            >
              Información de Curso
            </Typography>
          </Grid>
          <Paper className={classes.papercard}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Grid item container>
                <Typography component="h6" variant="h6">
                  Nombre del Curso:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  color="textSecondary"
                  alignItems="stretch"
                >
                  {course.name ? course.name : "No Registrado"}
                </Typography>
              </Grid>
              <Grid item container>
                <Typography component="h6" variant="h6">
                  Costo del Curso:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  color="textSecondary"
                  alignItems="stretch"
                  style={{ color: "green" }}
                >
                  {CourseCost ? CourseCost : "No Registrado"}
                </Typography>
              </Grid>
              <Grid item container>
                <Typography component="h6" variant="h6">
                  Descripción:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  color="textSecondary"
                  alignItems="stretch"
                >
                  {course.description
                    ? course.description
                    : `No hay Descripción`}
                </Typography>
              </Grid>
              <Grid item container>
                <Typography component="h6" variant="h6">
                  Programado en Escuela:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  color="textSecondary"
                  alignItems="stretch"
                >
                  {School.name ? School.name : "No registrada"}
                </Typography>
              </Grid>
              <Grid item container>
                <Typography component="h6" variant="h6">
                  Contacto Escolar:
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  color="textSecondary"
                  alignItems="stretch"
                >
                  {School.number_phone ? School.number_phone : `No registrado`}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/**
         ***** Tbl Datos del Cliente
         */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{padding:10}}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              className={classes.papercardtitle}
            >
              Capital financiero
            </Typography>
          </Grid>
          <Paper className={classes.papercard}>
            <Grid container>
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7} container>
                <Grid item container>
                  <Typography component="h6" variant="h6">
                    Total Ingreso Del Curso:
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    color="textSecondary"
                    alignItems="stretch"
                  >
                    {TotalPagadoActual}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography component="h6" variant="h6">
                    Total Efectivo:
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    color="textSecondary"
                    alignItems="stretch"
                  >
                    {totalPaidCash}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography component="h6" variant="h6">
                    Total En Tarjeta:
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    color="textSecondary"
                    alignItems="stretch"
                  >
                    {totalPaidCredit}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography component="h6" variant="h6">
                    Total En Transferencia:
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    color="textSecondary"
                    alignItems="stretch"
                  >
                    {totalPaidTransfer}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Typography component="h6" variant="h6">
                    Total por Curso:
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    color="textSecondary"
                    alignItems="stretch"
                    style={{ color: "green" }}
                  >
                    {totalCourse}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            className={classes.papercardtitle}
          >
            Alumnos Inscritos al Curso
          </Typography>
        </Grid>

        <MaterialTable
          title=""
          columns={[
            {
              title: "Nombre del Cliente",
              field: "name",
              render: (rowData) => {
                return (
                  <Fragment>
                    {rowData.name +
                      " " +
                      rowData.first_lastname +
                      " " +
                      rowData.second_lastname}
                  </Fragment>
                );
              },
            },
            { title: "Celular", field: "number_phone" },
            {
              title: "Total A Pagar",
              field: "total",
              render: (rowData) =>
                new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                  minimumFractionDigits: 0,
                }).format(rowData.total),
            },
            {
              title: "Pagado",
              field: "total_paid",
              render: (rowData) =>
                new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                  minimumFractionDigits: 0,
                }).format(rowData.total_paid),
            },
            {
              title: "Cambio",
              field: "change",
              render: (rowData) => {
                return (
                  <Fragment>
                    {rowData.change ? "$" + rowData.change : <p>No Aplica</p>}
                  </Fragment>
                );
              },
            },
            {
              title: "Cupón",
              field: "coupon",
              render: (rowData) => {
                return (
                  <Fragment>
                    {rowData.coupon ? (
                      <p>
                        {rowData.coupon.code} - {rowData.coupon.discount}%
                      </p>
                    ) : (
                      <p>No Aplica</p>
                    )}
                  </Fragment>
                );
              },
            },
            {
              title: "Estatus",
              field: "status",
              render: (rowData) => {
                return (
                  <Fragment>
                    {rowData.status == 0 ? (
                      <p
                        style={{
                          color: "white",
                          background: "red",
                          whiteSpace: "nowrap",
                          padding: "4px",
                        }}
                        align="center"
                      >
                        Cancelado
                      </p>
                    ) : rowData.status == 1 ? (
                      <p
                        style={{
                          color: "white",
                          background: "#ffc400",
                          whiteSpace: "nowrap",
                          padding: "4px",
                        }}
                        align="center"
                      >
                        No Liquidado
                      </p>
                    ) : rowData.status == 2 ? (
                      <p
                        style={{
                          color: "white",
                          background: "#43a047",
                          whiteSpace: "nowrap",
                          padding: "4px",
                        }}
                        align="center"
                      >
                        Liquidado
                      </p>
                    ) : null}
                  </Fragment>
                );
              },
            },
          ]}
          data={students}
          options={{
            headerStyle: {
              backgroundColor: "none",
              color: "#000",
              fontSize: "16px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
            actionsColumnIndex: -1,
          }}
          actions={[
            (rowData) => ({
              icon: "delete",
              tooltip: "Cancelar Inscripción",
              onClick: () => DeleteInscription(rowData.inscription_id),
            }),
          ]}
          components={{
            Action: (props) => (
              <Fragment>
                <Link to={`/HistorialPagos`} style={{ color: "#3f51b5" }}>
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      background: "white",
                    }}
                    onClick={() => SelectInscription(props.data.inscription_id)}
                    size="small"
                  >
                    <Tooltip
                      title="Ver Historial De Pagos"
                      aria-label="Ver Historial De Pagos"
                    >
                      <VisibilityIcon style={{ color: "indigo" }} />
                    </Tooltip>
                  </Button>
                </Link>
                {props.data.total > props.data.total_paid && (
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      background: "white",
                    }}
                    size="small"
                    type="submit"
                    onClick={() =>
                      SelectInscriptionPayment(
                        props.data.inscription_id,
                        props.data.total,
                        props.data.total_paid
                      )
                    }
                  >
                    <Tooltip title="Agregar Pago" aria-label="Agregar Pago">
                      <MonetizationOnIcon style={{ color: "indigo" }} />
                    </Tooltip>
                  </Button>
                )}
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    background: "white",
                  }}
                  size="small"
                  onClick={() => generarTicket(props.data.inscription_id)}
                >
                  <Tooltip
                    title="Generar ticket de pagos"
                    aria-label="Generar ticket de pagos"
                  >
                    <PictureAsPdf style={{ color: "#01579b" }} />
                  </Tooltip>
                </Button>
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    background: "white",
                  }}
                  size="small"
                  onClick={() => DeleteInscription(props.data.inscription_id)}
                >
                  <Tooltip
                    title="Cancelar Inscripción"
                    aria-label="Cancelar Inscripción"
                  >
                    <DeleteIcon style={{ color: "red" }} />
                  </Tooltip>
                </Button>
              </Fragment>
            ),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsSelect: "Columnas",
              firstTooltip: "Primera Pagina",
              previousTooltip: "Anterior",
              nextTooltip: "Siguiente",
              lastTooltip: "Ultima Página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Opciones",
            },
            body: {
              emptyDataSourceMessage: "No Hay Inscripciones que Mostrar",
              filterRow: {
                filterTooltip: "Buscar",
              },
            },
          }}
        />
        <ModalPayments
          open={open}
          setOpen={setOpen}
          saveCouponInfo={saveCouponInfo}
          couponInfo={couponInfo}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          totalDescuento={totalDescuento}
          totalPagado={totalPagado}
          informacionMetodoPago={informacionMetodoPago}
          image={image}
          obtenerInformacionPago={obtenerInformacionPago}
          addPayment={addPayment}
          handleChangeImage={handleChangeImage}
          disableButton={disableButton}
          detectarCambiosBancos={detectarCambiosBancos}
          imageReference={imageReference}
          handleChangeImageReference={handleChangeImageReference}
          disableCoupon={disableCoupon}
        />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Grid>
      </Container>
    </LayoutDashboard>
  );
}
