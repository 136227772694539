import React, { Fragment, useState, useEffect, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useForm } from "react-hook-form";
import SchedulesContext from "../../Reducers/Schedules/SchedulesContext";
import SelectIndividualCourses from "../SelectOptions/SelectIndividualCourses";
import IndividualCoursesList from "./IndividualCoursesList";
import IndividualClassesList from "./IndividualClassesList";

import MethodGet from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";

/**importar spinner */
import Spinner from "../../Complements/Spinner";
/**componente DatePicker */
import DatePickerComponent from "./DatePicker";

/** Importar Moment */
import Moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
}));

export default function ScheduleAddIndividual(props) {
  const classes = useStyles();

  const [Fecha, guardarFecha] = useState(Moment().format("YYYY-MM-DD"));
  const [FechaFinal, guardarFechaFinal] = useState(
    Moment().format("YYYY-MM-DD")
  );

  const [cargando, spinnerCargando] = useState(false);

  const [capacity, saveCapacity] = useState(0);

  const [courseInfo, setCourseInfo] = React.useState("");

  const [disableButton, saveDisableButton] = useState(false);

  const handleChangeCapacity = (e) => {
    saveCapacity(e.target.value);
  };

  const formScheduleContext = useContext(SchedulesContext);

  const { ErrorsAPI, success, AddSchedule } = formScheduleContext;

  const [school, saveSchool] = useState({});

  const [nameTypeCourses, guardarTypeCourse] = useState({
    type: "",
  });

  const [courses, saveCourses] = useState([]);

  const detectarCambiosTypeCourses = (e) => {
    guardarTypeCourse({ type: e.target.value });
  };

  //Extraer valores de branch(destructuracion)
  const { name } = school;

  const [individualCourse, saveIndividualCourse] = useState("");

  const detectarCambiosIndividualCourses = (value) => {
    saveIndividualCourse(value.key);
  };

  const [graduateCourse, saveGraduateCourse] = useState("");

  const detectarCambiosGraduateCourses = (e) => {
    saveGraduateCourse(e.target.value);
  };

  const [classroom, saveClassroom] = useState("");

  const detectarCambiosClassrooms = (e) => {
    saveClassroom(e.target.value);
  };

  //obtenemos la escuela de session storage
  let SchoolStorageID = JSON.parse(sessionStorage.getItem("school"));
  //console.log(SchoolStorageID)

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${SchoolStorageID}`;
    MethodGet(url)
      .then((res) => {
        saveSchool(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //obtener los cursos de un diplomado
  useEffect(() => {
    let url = `/coursesDetails/${graduateCourse}`;
    MethodGet(url)
      .then((res) => {
        saveCourses(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [graduateCourse]);

  const onSubmit = (data, e) => {
    var classes_list = JSON.stringify(courseInfo);
    data.classes_list = classes_list;
    data.course = individualCourse;
    data.type = 1;
    //console.log(data);
    if (data.course != "") {
      AddSchedule(data);
    } else {
      Swal.fire({
        title: "Error",
        text: "Todos los campos son Obligatorios",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de programaciones
    if (success) {
      props.history.push(`/ProgramacionesCursos`);
    }
  }, [success]);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <div className={classes.root}>
          <Paper className={classes.PaperFormAdd}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={classes.form}
              noValidate            >
              <Box>
                <Typography
                  component="h1"
                  variant="h5"
                  align="center"
                  className={classes.typography}
                >
                  Agregar Programacion de Curso En La Escuela {name}
                </Typography>
              </Box>
              <Grid container spacing={2} >
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    style={{ marginTop: "16px" }}
                  >
                    <SelectIndividualCourses
                      detectarCambiosIndividualCourses={
                        detectarCambiosIndividualCourses
                      }
                      SchoolStorageID={SchoolStorageID}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="capacity"
                    label="Capacidad Del Modulo"
                    name="capacity"
                    onChange={handleChangeCapacity}
                    autoComplete="capacity"
                    type="number"
                    defaultValue="0"
                    autoFocus
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    error={!!errors.end_time}
                    inputRef={register({
                      required: {
                        value: true,
                        message: "La Capacidad es requerida",
                      },
                    })}
                  />
                  <p>{errors?.capacity?.message}</p>
                </Grid>

                <Grid item xs={12}>
                  <IndividualClassesList
                    individualCourse={individualCourse}
                    saveIndividualCourse={saveIndividualCourse}
                    courseInfo={courseInfo}
                    setCourseInfo={setCourseInfo}
                    SchoolStorageID={SchoolStorageID}
                    capacity={capacity}
                    saveDisableButton={saveDisableButton}
                  />
                </Grid>
                {!disableButton ? (
                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    style={{ marginBottom: 10 }}
                  >
                    <Grid item spacing={3} xs={12} sm={12} md={6}>
                      <Button
                        style={{ color: "white", background: "grey" }}
                        type="submit"
                        fullWidth
                        disabled
                        variant="outlined"
                        className={classes.submit}
                      >
                        GUARDAR CURSO
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    style={{ marginBottom: 10 }}
                  >
                    <Grid item spacing={3} xs={12} sm={12} md={6}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        className={classes.submit}
                      >
                        GUARDAR CURSO
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </form>
          </Paper>
      </div>
    </LayoutDashboard>
  );
}
