import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {Button, Grid, Tooltip, Typography,Fab,Zoom,Box} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextField from "@material-ui/core/TextField";
/**Importar componentes de Iconos */
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  TableRow: {
    background: 'Orange'
    },
  container: {
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(30),
  },
  typography: {
    paddingTop: theme.spacing(4),
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#3f51b5",
    },
    color: "inherit",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 615,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  fabDelete: {
    color: theme.palette.common.white,
    backgroundColor: 'Red',
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "red",
      color:'white'
    },
    },
}));

function QuantityCourses(props) {
  const classes = useStyles();
  const {
    course,
    eliminarCurso,
    index,
  } = props;

  return (
    <TableRow>
      <TableCell align="center">{course.name}</TableCell>
      <TableCell align="center">{course.description ? course.description : (<p>Sin Descripción</p>)}</TableCell>
      <TableCell align="center">
        <Grid container justify="center" spacing="2">
            <Grid item xs={6} md={6} xl={6} justify="center" style={{marginBottom:'5px'}}>
                    <Grid item xs={2} xl={2} justify="center"
                    >
								    	<Typography
								    		component="h1"
								    		variant="h4"
								    		align="center"
								    	>
								    		<Box>
								    			<Fab
								    				className={classes.fabDelete}
								    				aria-label="add"
                            size="small"
                            onClick={() => eliminarCurso(course.course_id)}
                            //variant="extended"
                            >
                            <DeleteIcon size="small"/>
								    			</Fab>
								    		</Box>
								    	</Typography>
								    </Grid>
						</Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
}

export default QuantityCourses;
