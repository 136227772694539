import React, { useState, useEffect, Fragment } from 'react';
/**Importar configuracion axios */
import clienteAxios from '../../Config/Axios';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function SelectBank(props) {
	const [banks, saveBanks] = useState([]);
	const [error, guardarError] = useState(true);
	useEffect(() => {
		const getBanks = async () => {
			await clienteAxios
				.get('/banks')
				.then((res) => {
					saveBanks(res.data);
				})
				.catch((error) => {
					console.log(error);
				});

			if (props.bankId) {
				guardarError(false);
			}
		};
		getBanks();
	}, []);

	const detectarCambiosBancos = (e) => {
		if (e.target.value) {
			guardarError(false);
		}
		props.detectarCambiosBancos(e);
	};

	return (
		<Fragment>
			<InputLabel fullWidth>Bancos*</InputLabel>
			{props.bankId ? (
				<Select
					label="Bancos"
					fullWidth
					onChange={detectarCambiosBancos}
					value={props.bankId}
				>
					<MenuItem disabled>Seleccionael Banco</MenuItem>
					{banks.map((bank) => (
						<MenuItem key={bank.id} value={bank.id}>
							{bank.name}
						</MenuItem>
					))}
				</Select>
			) : (
				<Select label="Bancos" fullWidth onChange={detectarCambiosBancos}>
					<MenuItem disabled>Selecciona el Banco</MenuItem>
					{banks.map((bank) => (
						<MenuItem key={bank.id} value={bank.id}>
							{bank.name}
						</MenuItem>
					))}
				</Select>
			)}

			{error ? (
				<p style={{ color: 'red' }}>El Banco es un Campo Requerido</p>
			) : null}
		</Fragment>
	);
}
