import React, { useState, useEffect, Fragment } from 'react';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function SelectTypeCourse(props) {
	const [error, guardarError] = useState(true);
	const [tipos, saveType] = useState([]);

	const typeCourses = [
		{ type_course: 1, name: 'Taller' },
		{ type_course: 2, name: 'Diplomado' },
		
	];

	useEffect(() => {
		saveType(typeCourses);
		if (props.nameTypeCourses) {
			guardarError(false);
		}
	}, [error]);
	const detectarCambiosTypeCourses = (e) => {
		if (e.target.value) {
			guardarError(false);
		}
		props.detectarCambiosTypeCourses(e);
	};
	return (
		<Fragment>
			<InputLabel fullWidth>Tipo de Curso*</InputLabel>

			{props.nameTypeCourses ? (
				<Select
					label="Tipo de Curso"
					fullWidth
					onChange={detectarCambiosTypeCourses}
					value={props.nameTypeCourses.type_course}
				>
					<MenuItem disabled>Selecciona el Tipo de Curso</MenuItem>
					{tipos.map((tipo) => (
						<MenuItem key={tipo.type_course} value={tipo.type_course}>
							{tipo.name}
						</MenuItem>
					))}
				</Select>
			) : (
				<Select label="Tipo de Curso" fullWidth onChange={detectarCambiosTypeCourses}>
					<MenuItem disabled>Selecciona el Tipo de Curso</MenuItem>
					{tipos.map((tipo) => (
						<MenuItem key={tipo.type_course} value={tipo.type_course}>
							{tipo.name}
						</MenuItem>
					))}
				</Select>
			)}

			{error ? (
				<p style={{ color: 'red' }}>El Tipo de Curso es un Campo Requerido</p>
			) : null}
		</Fragment>
	);
}
