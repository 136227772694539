import React, { useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import BankAccountsContext from "../../Reducers/BankAccounts/BankAccountsContext";
import ModalBank from "./Add";

const useStyles = makeStyles((theme) => ({
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

export default function BankAccounts() {
  const classes = useStyles();

  /**Extraer los proveedores del state inicial */
  const BankAccountsList = useContext(BankAccountsContext);
  const { accounts, GetBankAccounts, success } = BankAccountsList;

  const BankAccountsState = useContext(BankAccountsContext);
  const { SaveCurrentBankAccount, DeleteBankAccount } = BankAccountsState;

  const SelectBankAccount = (account) => {
    SaveCurrentBankAccount(account);
  };

  //obtener los proveedores carga el componente
  useEffect(() => {
    GetBankAccounts();
    //eslint-disable-next-line
  }, [success]);
 //modal sALONES
 const [openModalBank, setOpenModalBank] = React.useState(false);

 const handleClickOpenModalBank = () => {
   setOpenModalBank(true);
 };
 const handleCloseModalBank = () => {
   setOpenModalBank(false);
 };
  return (
    <LayoutDashboard>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            marginTop: 85,
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            className={classes.typography}
          >
            Mis Cuentas Bancarias
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            onClick={() => handleClickOpenModalBank()}
          >
            <Tooltip
              title="Agregar Cuenta Bancaria"
              aria-label="Agregar Cuenta Bancaria"
            >
              <AddIcon size="large" />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginLeft: 25, marginRight: 25, marginTop: 10 }}
      >
        <MaterialTable
          title=""
          columns={[
            { title: "Propietario", field: "owner_name" },
            { title: "Numero De Cuenta", field: "account_number" },
            { title: "Banco", field: "bank.name" },
          ]}
          data={accounts}
          options={{
            headerStyle: {
              backgroundColor: "none",
              color: "#000",
              fontSize: "17px",
            },

            actionsColumnIndex: -1,
          }}
          actions={[
            (rowData) => ({
              icon: "delete",
              tooltip: "Eliminar Cuenta Bancaria",
              onClick: () => DeleteBankAccount(rowData.id),
            }),
          ]}
          components={{
            Action: (props) => (
              <Fragment>
                <Link to={`EditarCuentaBancaria`} style={{ color: "#3f51b5" }}>
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      background: "white",
                    }}
                    size="small"
                    onClick={() => SelectBankAccount(props.data.id)}
                  >
                    <Tooltip
                      title="Editar Cuenta Bancaria"
                      aria-label="Editar Cuenta Bancaria"
                    >
                      <EditIcon style={{ color: "blue" }} />
                    </Tooltip>
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  style={{ textTransform: "none", background: "white" }}
                  size="small"
                  onClick={() => DeleteBankAccount(props.data.id)}
                >
                  <Tooltip
                    title="Eliminar Cuenta Bancaria"
                    aria-label="Eliminar Cuenta Bancaria"
                  >
                    <DeleteIcon style={{ color: "red" }} />
                  </Tooltip>
                </Button>
              </Fragment>
            ),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsSelect: "Columnas",
              firstTooltip: "Primera Pagina",
              previousTooltip: "Anterior",
              nextTooltip: "Siguiente",
              lastTooltip: "Ultima Página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Opciones",
            },
            body: {
              emptyDataSourceMessage: "No Hay Cuentas Bancarias Que Mostrar",
              filterRow: {
                filterTooltip: "Buscar",
              },
            },
          }}
        />
      </Grid>
      <ModalBank
        modal={openModalBank}
        handleClose={handleCloseModalBank}
        setOpenModalClassrooms={setOpenModalBank}
      />
    </LayoutDashboard>
  );
}
