import React from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import './style.css';


export default function Notfoundpage() {
    return (
        <LayoutDashboard>
        <section className="page_404">
            <div className="container">
                <div className="row">	
                <div className="col-sm-12 ">
                <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                
                <h1 align="center">404</h1>
                
                
                </div>
                
                <div className="contant_box_404">
                <h2 align="center">
                ¿Te encuentras perdido?
                </h2>
                
                <p align="center">No podemos encontrar la página que estas solicitando</p>
                </div>
                {/* <div className="boton">
                   <Link to={"/"} className="button">
                      Ir a Inicio
                    </Link>

                </div> */}
                </div>
                </div>
                </div>
            </div>
        </section>
        </LayoutDashboard>
    )
}
