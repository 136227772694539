import React, { useState, useEffect,Fragment } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Course from './Course';
import { Grid } from '@material-ui/core';
import MethodGet from '../../Config/Services';
import Swal from "sweetalert2";

const CourseInfo = ({individualCourse, courses, saveCourses, saveIndividualCourse, saveDisableButton}) => {
    
    const [ cargandoAnimacion, guardarAnimacion ] = useState(false);
    const [ course, saveCourse ] = useState({});
    const [ errorDuplicado, guardarError ] = useState(false)

    useEffect(() => {
     guardarAnimacion(true);
     setTimeout(() => {
        guardarAnimacion(false);  
     }, 3000);
    }, []);
    //console.log(individualCourse, course)
    let url =`/courses/${individualCourse.course}`;
    useEffect(() => {
        if(individualCourse.course){
            MethodGet(url).then(response=>{
            saveCourse(response.data.data);
        }).catch(error=>{
         console.log(error);
        });
       }
        
    }, [individualCourse.course]);
    const AddCoursesState = () => {
        guardarError(false);
        let resultCourse = course;
        let course_exist = courses.filter(c => c.id == course.id)[0];
        if(course_exist)
        {
            guardarError(true);
            return;
        }
        //generar una llave unica para cada curso
        resultCourse.course_id = course.id;
        //guardarlo en el state
        saveCourses([...courses, resultCourse]);
        //recetear el state del producto
        saveIndividualCourse('');
        saveCourse({});
        saveDisableButton(true);
    }

    return (
        <Grid container spacing={3} >
            <Grid item xs={12} sm={5}>
                {
                    cargandoAnimacion &&
                    <Fragment>
                    <Skeleton variant="text" />
                    <Skeleton variant="circle" width={40} height={40} />
                    <Skeleton variant="rect" width={'100%'} height={'100%'} />  
                    </Fragment>
                }

            </Grid>
            <Grid item xs={12} sm={10}>
               {
                   cargandoAnimacion ?
                   <Fragment>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" /> 
                   </Fragment>
                   :
                   <Course
                   course={course}
                   AddCoursesState={AddCoursesState}
                   errorDuplicado={errorDuplicado}
                   guardarError={guardarError}
                   />

                }
            
            </Grid>
        </Grid>

    );
}

export default CourseInfo;