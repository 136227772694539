import React, { useState, useContext, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import CourseInfo from "./CourseInfo";
import CoursesList from "./CoursesList";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";

import Swal from "sweetalert2";

import MethodGet from "../../Config/Services";

/**importar spinner */
import Spinner from "../../Complements/Spinner";
import CourseContext from "../../Reducers/Courses/CoursesContext";
import SelectTypeCourse from "../SelectOptions/SelectTypeCourse";
import CheckboxIndividualCourses from "./CheckboxIndividualCourses";
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import CoursePhoto from "../../Complements/Image/FormClass/CoursePhoto.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

function AddSellerForm(props) {
  const classes = useStyles();

  const [cargando, spinnerCargando] = useState(false);
  const [disableButton, saveDisableButton] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const formClassroomContext = useContext(CourseContext);

  const { ErrorsAPI, success, AddCourse } = formClassroomContext;

  const [nameTypeCourses, guardarTypeCourse] = useState({
    type: "",
  });

  const detectarCambiosTypeCourses = (e) => {
    guardarTypeCourse({ type: e.target.value });
  };

  const [individualCourse, saveIndividualCourse] = useState({
    course: "",
  });

  const detectarCambiosIndividualCourses = (value) => {
    saveIndividualCourse({ course: value.key });
  };

  const [school, saveSchool] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name } = school;

  const { SchoolStorageID } = props;

  //console.log(SchoolStorageID)

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${SchoolStorageID}`;
    MethodGet(url)
      .then((res) => {
        saveSchool(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [SchoolStorageID]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    data.school_id = SchoolStorageID;
    data.type_course = nameTypeCourses.type;
    data.courses = selectedCourses;

    if (nameTypeCourses.type !== "") {
      //enviamos la data a la funcion que se encarga de guardar los nuevos registros
      AddCourse(data);
    } else {
      Swal.fire({
        title: "Error",
        text: "Todos los campos son Obligatorios",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de cursos
    if (success) {
      //console.log("Retorna");
      props.history.push(`/Cursos`);
    }
    //eslint-disable-next-line
  }, [success]);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 70 }}
        >
          {/**
           * identificar Codigo de FORMS
           */}
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>
                  Agregar Nuevo Curso A La Escuela {name}
                </h2>
                <p className={classes.cardCategoryWhite}>
                  Completa correctamente los datos
                </p>
              </CardHeader>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} style={{marginTop:8}}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <SelectTypeCourse
                        detectarCambiosTypeCourses={detectarCambiosTypeCourses}
                      />
                    </FormControl>
                  </Grid>
                  {nameTypeCourses.type != 2 ? (
                    <>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="name"
                          label="Nombre Curso"
                          name="name"
                          autoComplete="name"
                          autoFocus
                          error={!!errors.name}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El Nombre del curso es requerido",
                            },
                            minLength: {
                              value: 4,
                              message: "Minimo 4 caracteres",
                            },
                            maxLength: {
                              value: 45,
                              message: "Maximo 45 caracteres",
                            },
                          })}
                        />
                        <p>{errors?.name?.message}</p>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="description"
                          label="Descripción"
                          name="description"
                          autoComplete="description"
                          autoFocus
                          error={!!errors.description}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "La Descripcion es requerida",
                            },
                            minLength: {
                              value: 4,
                              message: "Minimo 4 caracteres",
                            },
                            maxLength: {
                              value: 200,
                              message: "Maximo 200 caracteres",
                            },
                          })}
                        />
                        <p>{errors?.description?.message}</p>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="cost"
                          label="Costo"
                          name="cost"
                          autoComplete="name"
                          type="number"
                          error={!!errors.capacity}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El Costo es requerido",
                            },
                            maxLength: {
                              value: 5,
                              message: "Maximo 5 caracteres",
                            },
                          })}
                        />
                        <p>{errors?.cost?.message}</p>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="name"
                          label="Nombre Diplomado"
                          name="name"
                          autoComplete="name"
                          autoFocus
                          error={!!errors.name}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El Nombre del curso es requerido",
                            },
                            minLength: {
                              value: 4,
                              message: "Minimo 4 caracteres",
                            },
                            maxLength: {
                              value: 45,
                              message: "Maximo 45 caracteres",
                            },
                            pattern: {
                              value:
                                /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                              message: "Unicamente carácteres alfabéticos",
                            },
                          })}
                        />
                        <p>{errors?.name?.message}</p>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="description"
                          label="Descripción"
                          name="description"
                          autoComplete="description"
                          autoFocus
                          error={!!errors.description}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "La Descripcion es requerida",
                            },
                            minLength: {
                              value: 4,
                              message: "Minimo 4 caracteres",
                            },
                            maxLength: {
                              value: 200,
                              message: "Maximo 200 caracteres",
                            },
                          })}
                        />
                        <p>{errors?.description?.message}</p>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="cost"
                          label="Costo"
                          name="cost"
                          autoComplete="name"
                          type="number"
                          error={!!errors.capacity}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El Costo es requerido",
                            },
                            maxLength: {
                              value: 5,
                              message: "Maximo 5 caracteres",
                            },
                          })}
                        />
                        <p>{errors?.cost?.message}</p>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <CheckboxIndividualCourses
                            saveDisableButton={saveDisableButton}
                            selectedCourses={selectedCourses}
                            setSelectedCourses={setSelectedCourses}
                            SchoolStorageID={SchoolStorageID}
                          />
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid container spacing={3} justify="center">
                  <Grid item spacing={3} xs={12} sm={12} md={6}>
                    {!disableButton && nameTypeCourses.type == 2 ? (
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        disabled
                        className={classes.submit}
                      >
                        GUARDAR
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        className={classes.submit}
                      >
                        GUARDAR
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
			<Card profile>
              <CardBody profile>
                <img
                  src={CoursePhoto}
                  alt="Foto Editar"
                  width="35%"
                  height="35%"
                />
              </CardBody>
            </Card>
          </GridItem>
          {/**
           * identificar Codigo de FORMS
           */}
          {/**
           * identificar Codigo de FORMS
           */}
            
        </Grid>
      </form>
    </LayoutDashboard>
  );
}

export default withRouter(AddSellerForm);
