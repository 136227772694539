import React, { useState } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import FormControl from "@material-ui/core/FormControl";
import { MethodPost } from "../../Config/Services";
/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import EditPhoto from "../../Complements/Image/FormClass/EditPhoto.svg";
import AddFile from "../../Complements/Image/FormClass/AddFile.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(10),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  ContainerSubmit: {
    marginLeft: theme.spacing(30),
    marginTop: theme.spacing(1),
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

const BusinessRulesAdd = (props) => {
  const classes = useStyles();


let idbranche = props.match.params.id;
  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data, e) => {
    e.preventDefault();

    const dat = {
      ...data,
      id_branch_office: idbranche,
    };

    let url = "/rules";
    MethodPost(url, dat)
      .then((res) => {
        Swal.fire({
          title: "Regla de Negocio Registrada Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        props.history.push(`/ReglasNegocio/${idbranche}`);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  return (
    <LayoutDashboard>
      <div>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 70 }}
          >
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card>
                <CardHeader color="primary">
                  <h2 className={classes.cardTitleWhite}>
                    Agregar Regla de Negocio
                  </h2>
                  <p className={classes.cardCategoryWhite}>
                    Completa correctamente los datos
                  </p>
                </CardHeader>
                <CardBody>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 3,
                          message: "Minimo 3 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="discount"
                        label="Agrega Porcentaje de Descuento"
                        name="discount"
                        autoComplete="name"
                        autoFocus
                        type="number"
                        error={!!errors.discount}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "El porcentaje de descuento es requerido",
                          },
                          max: {
                            value: 50,
                            message: "Maximo 50% de descuento",
                          },
                        })}
                      />
                      <p>{errors?.discount?.message}</p>
                    </Grid>
                  </Grid>

                  <Grid container xs={12} xl={12} spacing={2} justify="center">
                    <Grid item xs={12} sm={12} md={6}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        className={classes.submit}
                      >
                        GUARDAR
                      </Button>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
              <Card profile>
                <CardBody profile>
                  <img
                    src={AddFile}
                    alt="Foto Editar"
                    width="20%"
                    height="20%"
                  />
                </CardBody>
              </Card>
            </GridItem>
            {/**
             * identificar Codigo de FORMS
             */}
            {/**
             * identificar Codigo de FORMS
             */}
          </Grid>
        </form>
      </div>
    </LayoutDashboard>
  );
};

export default BusinessRulesAdd;
