import React, { useState, useEffect, Fragment } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardBranchStocks from '../../Components/CardsBranches/CardBranchStocks';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { Button, Tooltip, Box, Fab, Typography, Grid, Hidden} from '@material-ui/core';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Icon from '@material-ui/icons/PictureAsPdf';
import fileDownload from 'js-file-download';
import clienteAxios from '../../Config/Axios';
import FormControl from '@material-ui/core/FormControl';
import SelectBranchOffice from '../SelectOptions/SelectBranchOffice';
import DateFnsUtils from '@date-io/date-fns';
import PrintIcon from '@material-ui/icons/Print';


/**Importar metodo Get */
import MethodGet, { MethodDelete } from '../../Config/Services';
/**Importar Sweetalert2 * */
import Swal from 'sweetalert2';
/**importar spinner */
import Spinner from '../../Complements/Spinner';
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	Link: {
		textDecoration: 'none',
		color: theme.palette.text.primary
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	input: {
		display: 'none',
	},
	Table: {
		background: 'Orange',
	},
	typography: {
		margin: theme.spacing(0, 1, 2, 2),
	},
	fabGreen: {
		color: theme.palette.common.white,
		
		backgroundColor: 'indigo',
		'&:hover': {
		  backgroundColor: '#4d67f7',
		},
	},
	reportmodal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	  },
	reportpaper: {
		backgroundColor: theme.palette.background.paper,
		border: 'none',
		width:'80%',
		height:'60%',
		padding: theme.spacing(2, 1, 1, 3),
	  },
	  btnPlus:{
		position: 'relative',
	},


}));

const StocksAdmin = (props) => {
	const classes = useStyles();

	//sucursales = state, saveStocks =funcion para guardar en el state;
	const [stocks, saveStocks] = useState([]);
	const [cargando, spinnerCargando] = useState(false);

	//abrirModal
	const [ modal, abrirModal ] = useState(false);
	const [ stockid , editarStockId ] = useState('');

	const handleClickOpen = (id) => {
		editarStockId(id);
		abrirModal(true);
	  };
	
	  const handleClose = () => {
		abrirModal(false);
	  };

	  const [ actualizar , actualizarStock ] = useState(false);

	  const actualizarCambios = () =>{
		editarStockId('');
		actualizarStock(true);
	  }

	//query a la api
	useEffect(() => {
		let url = '/stocks';
		MethodGet(url)
			.then((res) => {
				saveStocks(res.data.data);
				spinnerCargando(true);
				actualizarStock(false);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
	}, [actualizar]);

    const [error, guardarError] = useState(false);

    const [nameOffice, guardarSucursal] = useState({
		id_branch_office: '',
	});


    const GenerarReporteGlobal = e => {
        e.preventDefault();
        clienteAxios.get('/reporte-productos-general', { responseType: 'blob' }).then(response => {
            fileDownload(response.data, 'Reporte General de Stock.pdf');
            guardarError(false);
        }).catch(error => {
			if (error.response.status === 200){
				let timerInterval
				Swal.fire({
				  title: 'Auto close alert!',
				  html: 'I will close in <b></b> milliseconds.',
				  timer: 2000,
				  timerProgressBar: true,
				  didOpen: () => {
					Swal.showLoading()
					timerInterval = setInterval(() => {
					  const content = Swal.getContent()
					  if (content) {
						const b = content.querySelector('b')
						if (b) {
						  b.textContent = Swal.getTimerLeft()
						}
					  }
					}, 100)
				  },
				  willClose: () => {
					clearInterval(timerInterval)
				  }
				}).then((result) => {
				  if (result.dismiss === Swal.DismissReason.timer) {
					console.log('I was closed by the timer')
				  }
				})
			}
            else if (error.response.status === 409) {
                Swal.fire({
                    icon: 'error',
                    title: 'Algo sucedio',
                    text: 'No cuentas con ventas suficientes el dia de hoy para generar el corte de caja'
                })
            } else if (error.response.status === 422) {
                guardarError(true)
            }
        });
    }


    const detectarCambiosOffice = (e) => {
		guardarSucursal({ id_branch_office: e.target.value });
	};

    const GenerarReporteOffice = e => {
        e.preventDefault();

        if (nameOffice.id_branch_office !== '') {

            const data = {
                'branch_office_id': nameOffice.id_branch_office
            }
            clienteAxios.post('/reporte-productos-sucursal', data, { responseType: 'blob' }).then(response => {
                fileDownload(response.data, 'Reporte de Stock por sucursal.pdf');
                guardarError(false);
            }).catch(error => {
                if (error.response.status === 409) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Algo sucedio',
                        text: 'No se cuenta con suficiente información para generar el reporte'
                    })
                } else if (error.response.status === 422) {
                    guardarError(true)
                }
            });

        }
    }

		  const [open, setOpen] = React.useState(false);

		  const reportmodalOpen = () => {
		    setOpen(true);
		  };
	  
		  const reportmodalClose = () => {
		    setOpen(false);
		  };
	return (
		<LayoutDashboard>
			      {/**
       *
       * Aqui es donde aplica el cambio de cards que renderizan las sucursales para los clientes
       */}
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: "15px" }}
      >
        <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 70 }}>
          <Typography align="center" component="h2" variant="h2">
            Selecciona una Sucursal
          </Typography>
        </Grid>

		<CardBranchStocks />
      </Grid>
      {/* separacion */}
		</LayoutDashboard>
	);
};

export default StocksAdmin;
