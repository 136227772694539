import { GET_ALL_CASH_OUTS, ADD_NEW_CASH_OUT, CURRENT_CASH_OUT,
    GET_INFO_CASH_OUT } from "../../types";

export default function CashOutsReducer(state, action) {

switch (action.type) {
case GET_ALL_CASH_OUTS: 
    return{
        ...state,
        cash_outs: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case GET_INFO_CASH_OUT:
    return{
        ...state,
        cash_out: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case ADD_NEW_CASH_OUT:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }

case CURRENT_CASH_OUT:

    //sessionStorage.setItem('selectedSchools',JSON.stringify(action.payload))
    return{
        ...state,
        cash_out:action.payload,
    }

default:
    return state; 
}
}