import React, { useEffect, useState, useRef, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Swal from "sweetalert2";
import Spinner from "../../Complements/Spinner";
import headerConfig from "../../Config/imageHeaders";
import MethodGet, { MethodPost } from "../../Config/Services";
import { useForm } from "react-hook-form";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import SelectTypeUser from "../SelectOptions/SelectTypeUser";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from "@material-ui/core";

import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import CustomInput from "../../Components/CustomInput/CustomInput";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import CardFooter from "../../Components/Card/CardFooter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

const type = [{ title: "Administrador" }, { title: "Vendedor" }];

export default function UserEdit(props) {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);

  const { id } = props.match.params;

  //Funcion para validaciones
  const { register, errors, handleSubmit, watch } = useForm({});
  const password = useRef({});
  password.current = watch("password", "");

  const [user, guardarUser] = useState({});

  const [officeId, saveOffice] = useState({
    id_branch_office: "",
  });

  const [nameTypeUsers, saveType] = useState({
    type_user: "",
  });

  const detectarCambiosOffice = (e) => {
    saveOffice({ id_branch_office: e.target.value });
  };

  const detectarCambiosTypeUser = (e) => {
    saveType({ type_user: e.target.value });
  };

  const { name, number_phone, salary, comission_percentage, email } = user;

  const [image, setImage] = useState({
    urlPhoto:
      "https://www.adl-logistica.org/wp-content/uploads/2019/07/imagen-perfil-sin-foto-300x300.png",
    image: "",
  });
  const imageDefault =
    "https://www.adl-logistica.org/wp-content/uploads/2019/07/imagen-perfil-sin-foto-300x300.png";

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  useEffect(() => {
    let url = `/users/${id}`;

    MethodGet(url)
      .then((res) => {
        guardarUser(res.data.data);
        saveOffice({ id_branch_office: res.data.data.id_branch_office });
        saveType({ type_user: res.data.data.type });
        if (res.data.data.image) {
          setImage({
            urlPhoto: res.data.data.image,
            image: res.data.data.image,
          });
        }
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //console.log(name)

  const onSubmit = (data, e) => {
    e.preventDefault();

    //crear un formdata
    const formData = new FormData();
    if (nameTypeUsers.type_user != 1) {
      formData.append("name", data.name);
      if (image !== "") {
        formData.append("image", image.image);
      }
      formData.append("email", data.email);
      formData.append("number_phone", data.number_phone);
      formData.append("salary", data.salary);
      formData.append("password", data.password);
      formData.append("c_password", data.c_password);
      formData.append("comission_percentage", data.comission_percentage);
      formData.append("type", nameTypeUsers.type_user);
      formData.append("id_branch_office", officeId.id_branch_office);
    } else {
      formData.append("name", data.name);
      if (image !== "") {
        formData.append("image", image.image);
      }
      formData.append("email", data.email);
      formData.append("number_phone", data.number_phone);
      formData.append("password", data.password);
      formData.append("c_password", data.c_password);
      formData.append("type", nameTypeUsers.type_user);
    }
    formData.append("_method", "PATCH");

    let url = `/users/${id}`; //cambiar la url completa
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Usuario Editado Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        props.history.push("/Usuarios");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 70 }}
        >
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>Editar Usuario</h2>
                <p className={classes.cardCategoryWhite}>
                  Completa correctamente los datos
                </p>
              </CardHeader>
              <CardBody>
                <Grid container spacing={2}>
                  {nameTypeUsers.type_user != 1 && (
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <SelectBranchOffice
                        officeId={officeId}
                        detectarCambiosOffice={detectarCambiosOffice}
                      />
                    </FormControl>
                  )}
                  <Grid item xs={12} sm={4} style={{ paddingTop: 16 }}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <SelectTypeUser
                        nameTypeUsers={nameTypeUsers}
                        detectarCambiosTypeUser={detectarCambiosTypeUser}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre Completo"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      defaultValue={name}
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                        pattern: {
                          value:
                            /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                          message: "Unicamente carácteres alfabéticos",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="number_phone"
                      label="Teléfono"
                      name="number_phone"
                      autoComplete="phone"
                      type="number"
                      autoFocus
                      defaultValue={number_phone}
                      error={!!errors.number_phone}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Teléfono es requerido",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 digitos",
                        },
                      })}
                    />
                    <p>{errors?.number_phone?.message}</p>
                  </Grid>

                  {nameTypeUsers.type_user != 1 && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="salary"
                          label="Sueldo"
                          name="salary"
                          autoComplete="salary"
                          autoFocus
                          defaultValue={salary}
                          type="number"
                          error={!!errors.salary}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El Salario es requerido",
                            },
                          })}
                        />
                        <p>{errors?.salary?.message}</p>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="comission_percentage"
                          label="Porcentaje De Comisión"
                          name="comission_percentage"
                          autoComplete="comission_percentage"
                          autoFocus
                          defaultValue={comission_percentage}
                          type="number"
                          error={!!errors.comission_percentage}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El porcentaje de comisión es requerido",
                            },
                            maxLength: {
                              value: 5,
                              message: "Máximo 5 digitos",
                            },
                            minLength: {
                              value: 1,
                              message: "Minimo 1 Digito",
                            },
                          })}
                        />
                        <p>{errors?.comission_percentage?.message}</p>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Correo Electronico"
                      name="email"
                      autoComplete="name"
                      autoFocus
                      defaultValue={email}
                      error={!!errors.email}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El correo es requerido",
                        },
                        type: "email",
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    <p>{errors?.email?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      placeholder="Ingresa Nueva Contraseña"
                      fullWidth
                      variant="outlined"
                      name="password"
                      type="password"
                      autoComplete="name"
                      autoFocus
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.password}
                      inputRef={register({
                        required: {
                          value: false,
                        },
                      })}
                    />
                    <p style={{ color: "red" }}>{errors?.password?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      placeholder="Confirma la nueva Contraseña"
                      fullWidth
                      name="confirmpassword"
                      type="password"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.confirmpassword}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputRef={register({
                        validate: (value) =>
                          value === password.current ||
                          "Las contraseñas no coinciden",
                      })}
                    />
                    <p style={{ color: "red" }}>
                      {errors?.confirmpassword?.message}
                    </p>
                  </Grid>
                </Grid>
              </CardBody>
              <Card profile>
                <CardAvatar profile>
                  <img
                    src={image.urlPhoto}
                    alt="User"
                    width="100%"
                    height="100%"
                  />
                </CardAvatar>
                <CardBody profile>
                  <input
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    name="image"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={handleChangeImage}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <Tooltip
                        title="seleccionar imagen"
                        aria-label="seleccionar imagen"
                      >
                        <PhotoCamera />
                      </Tooltip>
                    </IconButton>
                  </label>
                  <InputLabel>Selecciona Imagen de Pefil</InputLabel>
                  <h2 className={classes.cardTitle}>Editando Perfil de: </h2>
                  <h3 className={classes.cardTitle}> {name}</h3>
                  <p className={classes.description}>
                    Recuerda que la informacion que cambies o elimines en este
                    perfil afectara los datos del usuario con esta cuenta,
                    verifica que tu usuario este al tanto de los nuevos cambios.
                  </p>
                </CardBody>
              </Card>
              <CardFooter>
                <Grid container spacing={3} justify="center">
                  <Grid item spacing={3} xs={12} sm={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.submit}
                    >
                      ACTUALIZAR
                    </Button>
                  </Grid>
                </Grid>
              </CardFooter>
            </Card>
          </GridItem>
        </Grid>
      </form>
    </LayoutDashboard>
  );
}
