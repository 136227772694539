import React, { useState, useEffect, Fragment } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';

import MethodGet, { MethodDelete } from '../../Config/Services';
/**Importar Sweetalert2 * */
import Swal from 'sweetalert2';
/**importar spinner */
import Spinner from '../../Complements/Spinner';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(18),
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	input: {
		display: 'none',
	},
	typography: {
		margin: theme.spacing(0, 1, 2, 2),
	},
}));

export default function ClientsAdmin() {
	const classes = useStyles();
	//reglas negocio = state, guardarClient =funcion para guardar en el state;
	const [clients, guardarClient] = useState([]);
	const [cargando, spinnerCargando] = useState(false);

	//query a la api
	useEffect(() => {
		let url = '/clients';
		MethodGet(url)
			.then((res) => {
				guardarClient(res.data.data);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
	}, []);

	const deleteClient = (id) => {
		Swal.fire({
			title: '¿Estás seguro?',
			text: 'El cliente no se podrá recuperar',
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, Eliminar',
			cancelButtonText: 'No, Cancelar',
		}).then((result) => {
			if (result.value) {
				// eliminar en la rest api
				let url = `/clients/${id}`;
				MethodDelete(url).then((res) => {
					if (res.status === 200) {
						const CambioIndexUsers = clients.filter(
							(usuario) => usuario.id !== id
						);
						guardarClient(CambioIndexUsers);
						Swal.fire('Cliente Eliminado', res.data.mensaje, 'success');
					}
				}).catch((error) => {
					Swal.fire({
						title: 'Error',
						text: error.response.data.error,
						icon: 'error',
					});
				});
			}
		});
	};

	// spinner de carga
	if (!cargando) return <Spinner />;

	return (
		<LayoutDashboard>
			<div className={classes.root}>
				<CssBaseline />
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Container className={classes.container}>
						<Grid Container spacing={3}>
							<div>
								<Typography
									component="h1"
									variant="h4"
									align="center"
									className={classes.typography}
								>
									<Box align="right">
										<Fab
											color="primary"
											aria-label="add"
											size="small"
											href="/Agregar_Cliente"
										>
											<Tooltip
												title="Agregar Cliente"
												aria-label="Agregar Cliente"
											>
												<AddIcon />
											</Tooltip>
										</Fab>
									</Box>
								</Typography>
								<MaterialTable
									title="Mis Clientes"
									columns={[
										{ title: 'Nombre Completo', field: 'full_name' },
										{ title: 'Teléfono', field: 'number_phone' },
										{ title: 'Calle', field: 'street' },
										{ 
											title: 'Numero Exterior', field: 'number_ext', render:rowData =>
									
											rowData.number_ext ? (<p> {rowData.number_ext} </p>)
											: (<p> S/N </p>)

										},
										{ 
											title: 'Numero Interior', field: 'number_int', render:rowData =>

											rowData.number_int ? (<p> {rowData.number_int} </p>)
											: (<p> S/N </p>)

										},
										{ title: 'C.P', field: 'postal_code' },
										{ title: 'RFC', field: 'RFC' },
									]}
                                    data={clients}
									options={{
										headerStyle: {
											backgroundColor: 'orange',
											color: '#FFF',
										},

										actionsColumnIndex: -1,
                                    }}
                                    /**
									actions={[
										(rowData) => ({
											icon: 'delete',
											tooltip: 'Eliminar cliente',
											onClick: () => deleteClient(rowData.id),
										}),
                                    ]}
                                    
									components={{
										Action: (props) => (
											<Fragment>
												<Link
													to={`Editar_Cliente/${props.data.id}`}
													style={{ color: '#3f51b5' }}
												>
													<Button
														variant="contained"
														style={{
															textTransform: 'none',
															background: 'white',
														}}
														size="small"
													>
														<Tooltip
															title="Editar Cliente"
															aria-label="Editar Cliente"
														>
															<EditIcon style={{ color: 'blue' }} />
														</Tooltip>
													</Button>
												</Link>
												<Button
													variant="contained"
													style={{ textTransform: 'none', background: 'white' }}
													size="small"
													onClick={() => deleteClient(props.data.id)}
												>
													<Tooltip
														title="Eliminar Cliente"
														aria-label="Editar Cliente"
													>
														<DeleteIcon style={{ color: 'red' }} />
													</Tooltip>
												</Button>
											</Fragment>
										),
                                    }}
                                    

                                    **/
                                   /**
									localization={{
										pagination: {
											labelDisplayedRows: '{from}-{to} de {count}',
											labelRowsSelect: 'Columnas',
											firstTooltip: 'Primera Página',
											previousTooltip: 'Anterior',
											nextTooltip: 'Siguiente',
											lastTooltip: 'Ultíma Página',
										},
										toolbar: {
											searchTooltip: 'Buscar ',
											searchPlaceholder: 'Buscar',
										},
										header: {
											actions: 'Opciones',
										},
										body: {
											emptyDataSourceMessage: 'No hay Reglas que Mostrar',
											filterRow: {
												filterTooltip: 'Buscar',
											},
										},
                                    }}
                                    */
								/>
							</div>
						</Grid>
					</Container>
				</main>
			</div>
		</LayoutDashboard>
	);
}
