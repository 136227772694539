import React, { useState, useEffect, Fragment, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Grid, IconButton } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import MaterialTable from "material-table";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-router-dom";
import { Button, Tooltip, Hidden } from "@material-ui/core";
/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import SchoolContext from "../../Reducers/Schools/SchoolContext";
import CashOutsContext from "../../Reducers/CashOuts/CashOutsContext";
import { green } from "@material-ui/core/colors";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import ModalAddCashOut from "./ModalAddCashOut";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import authContext from "../../Context/autenticacion/authContext";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 275,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  widgetContent: {
    height: "135px",
    maxWidth: "100%",
    marginLeft: 10,
    marginRight: 10,
    background: "linear-gradient(to right, #3f51b5 15%, indigo 55%) !important",
    flexDirection: "row",
    alignItems: "center",
    color: "white",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
    marginRight: "-2%",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingInlineEnd: theme.spacing(2),
  },
}));

export default function CardsUtility(props) {
  const classes = useStyles();

  const [cargando, spinnerCargando] = useState(false);
  const { usuario } = useContext(authContext);

  const { schoolID } = props;

  /**Extraer las escuelas del state inicial */
  const SchoolsList = useContext(SchoolContext);
  const {
    utility,
    GetSchoolUtility,
    incomes,
    GetBranchIncomes,
    GenerateSchoolIncome,
  } = SchoolsList;
  const CashOutsList = useContext(CashOutsContext);
  const { cash_outs, GetCashOuts, success, GenerateTicket, AddCashOut } =
    CashOutsList;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    GetSchoolUtility(schoolID);
    GetCashOuts(schoolID);
    GetBranchIncomes(schoolID);
    //spinnerCargando(true);
  }, [success, schoolID]);

  //  spinner de carga
  //if (!cargando) return <Spinner />;

  return (
    <>
      <Grid xs={12} md={4} style={{ padding: 10 }}>
        <Box style={{ backgroundColor: "#F3927E" }} borderRadius={20}>
          <Typography
            align="center"
            variant="h4"
            style={{ color: "white", fontWeight: "bold", marginBottom: 10 }}
          >
            Ventas
          </Typography>
          <Grid container style={{ marginBottom: 15 }}>
            <Grid md={6} xs={6}>
              <Typography
                align="left"
                variant="subtitle1"
                style={{ color: "white", paddingLeft: 10, fontWeight: "bold" }}
              >
                En caja hoy
              </Typography>
            </Grid>
            <Grid md={6} xs={6}>
              <Typography
                align="right"
                variant="subtitle1"
                style={{ color: "white", paddingRight: 10 }}
              >
                {Object.keys(utility).length != 0 ? (
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(utility.encajahoy)
                ) : (
                  <LinearProgress />
                )}
              </Typography>
            </Grid>
          </Grid>
          {usuario && usuario.type !== "2" && (

          <Grid container style={{ marginBottom: 15 }}>
            <Grid md={6} xs={6}>
              <Typography
                align="left"
                variant="subtitle1"
                style={{ color: "white", paddingLeft: 10, fontWeight: "bold" }}
              >
                Total Vendido
              </Typography>
            </Grid>
            <Grid md={6} xs={6}>
              <Typography
                align="right"
                variant="subtitle1"
                style={{ color: "white", paddingRight: 10 }}
              >
                {Object.keys(utility).length != 0 ? (
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(utility.total_money-utility.money_change)
                ) : (
                  <LinearProgress />
                )}
              </Typography>
            </Grid>
          </Grid>
          )}
          {usuario && usuario.type !== "2" && (

          <Grid container style={{ marginBottom: 15 }}>
            <Grid md={6} xs={6}>
              <Typography
                align="left"
                variant="subtitle1"
                style={{ color: "white", paddingLeft: 10, fontWeight: "bold" }}
              >
                Efectivo
              </Typography>
            </Grid>
            <Grid md={6} xs={6}>
              <Typography
                align="right"
                variant="subtitle1"
                style={{ color: "white", paddingRight: 10 }}
              >
                {Object.keys(utility).length != 0 ? (
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(utility.total_money_cash)
                ) : (
                  <LinearProgress />
                )}
              </Typography>
            </Grid>
          </Grid>
          )}
          {usuario && usuario.type !== "2" && (

          <Grid container style={{ marginBottom: 15 }}>
            <Grid md={6} xs={6}>
              <Typography
                align="left"
                variant="subtitle1"
                style={{ color: "white", paddingLeft: 10, fontWeight: "bold" }}
              >
                Tarjeta
              </Typography>
            </Grid>
            <Grid md={6} xs={6}>
              <Typography
                align="right"
                variant="subtitle1"
                style={{ color: "white", paddingRight: 10 }}
              >
                {Object.keys(utility).length != 0 ? (
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(utility.total_money_card)
                ) : (
                  <LinearProgress />
                )}
              </Typography>
            </Grid>
          </Grid>
          )}
          {usuario && usuario.type !== "2" && (

          <Grid container style={{ marginBottom: 25 }}>
            <Grid md={6} xs={6}>
              <Typography
                align="left"
                variant="subtitle1"
                style={{ color: "white", paddingLeft: 10, fontWeight: "bold" }}
              >
                Transferencia
              </Typography>
            </Grid>
            <Grid md={6} xs={6}>
              <Typography
                align="right"
                variant="subtitle1"
                style={{ color: "white", paddingRight: 10 }}
              >
                {Object.keys(utility).length != 0 ? (
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(utility.total_money_transfer)
                ) : (
                  <LinearProgress />
                )}
              </Typography>
            </Grid>
          </Grid>
          )}
          <Grid container style={{ marginBottom: 25 }}>
            <Grid md={12} xs={12}>
              <Typography
                style={{ marginBottom: 10, padding: 10 }}
                align="center"
              >
                {usuario && usuario.type !== "2" && (
                  <Button
                    href={`GraficasVentasSchool/${schoolID}`}
                    fullWidth
                    variant="contained"
                    style={{ color: "white", backgroundColor: "#f25a3c" }}
                  >
                    Ver Gráficas
                  </Button>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {/* separacion */}
      <Grid xs={12} md={4} style={{ padding: 10 }}>
        <Box style={{ backgroundColor: "#C57CCE" }} borderRadius={20}>
          <Typography
            align="center"
            variant="h4"
            style={{ color: "white", fontWeight: "bold", marginBottom: 10 }}
          >
            Ventas del día
          </Typography>
          <Grid container style={{ marginBottom: 15 }}>
            <Grid md={12} xs={12}>
              <Typography
                align="center"
                variant="h1"
                style={{ color: "white", marginTop: 61, marginBottom: 40 }}
              >
                {Object.keys(utility).length != 0 ? (
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(utility.total_money_today)
                ) : (
                  <LinearProgress />
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 25 }}>
            <Grid md={12} xs={12}>
              <Typography
                style={{ marginBottom: 10, padding: 10 }}
                align="center"
              >
                {usuario && usuario.type !== "2" && (
                  <Button
                    href={`GraficasVentasDiasSchool/${schoolID}`}
                    fullWidth
                    variant="contained"
                    style={{ color: "white", backgroundColor: "#b71acc" }}
                  >
                    Ver Gráficas
                  </Button>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* separacion */}
      <Grid xs={12} md={4} style={{ padding: 10 }}>
        <Box style={{ backgroundColor: "#EE6A9B" }} borderRadius={20}>
          <Typography
            align="center"
            variant="h5"
            style={{ color: "white", fontWeight: "bold", marginBottom: 5 }}
          >
            Gastos del día
          </Typography>
          <Grid container>
            <Grid md={12} xs={12}>
              <Typography
                align="center"
                variant="h2"
                style={{ color: "white", marginTop: 15, marginBottom: 15 }}
              >
                {Object.keys(utility).length != 0 ? (
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(utility.total_expenses_today)
                ) : (
                  <LinearProgress />
                )}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            align="center"
            variant="h5"
            style={{ color: "white", fontWeight: "bold", marginBottom: 5 }}
          >
            Salidas del día
          </Typography>
          <Grid container>
            <Grid md={12} xs={12}>
              <Typography
                align="center"
                variant="h2"
                style={{ color: "white", marginTop: 15, marginBottom: 15 }}
              >
                {Object.keys(utility).length != 0 ? (
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(utility.total_cash_outs_today)
                ) : (
                  <LinearProgress />
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: 25 }}>
            <Grid md={12} xs={12}>
              <Typography
                style={{ marginBottom: 10, padding: 10 }}
                align="center"
              >
                {usuario && usuario.type !== "2" && (
                  <Button
                    href={`Movimientos/${schoolID}`}
                    fullWidth
                    variant="contained"
                    style={{ color: "white", backgroundColor: "#ed1765" }}
                  >
                    Ver Movimientos de Caja
                  </Button>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {/* separacios */}
      {/* separacios */}
      {/* separacios */}
      {/* separacios */}
      <Grid container spacing={2}>
        {/* separacion */}
        <Grid item spacing={2} sm={12} md={6} style={{ padding: 20 }}>
          {/* seaparacion */}
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
                color: "white",
                borderRadius: 15,
                paddingLeft: 25,
                paddingRight: 25,
                marginTop: 20,
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                className={classes.typography}
              >
                Salidas De Efectivo
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              align="right"
              style={{ marginRight: 30, marginTop: -30 }}
            >
              <Fab
                className={classes.fabGreen}
                aria-label="add"
                size="medium"
                onClick={() => handleClickOpen()}
              >
                <Tooltip
                  title="Agregar Salida De Dinero"
                  aria-label="Agregar Salida De Dinero"
                >
                  <AddIcon size="large" />
                </Tooltip>
              </Fab>
            </Grid>
          </Grid>

          {/**
           * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
           *
           */}

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: 10 }}
          >
            <MaterialTable
              title=""
              columns={[
                { title: "Fecha", field: "date" },
                { title: "Descripción", field: "description" },
              ]}
              data={cash_outs}
              options={{
                headerStyle: {
                  backgroundColor: "none",
                  color: "#000",
                  fontSize: "16px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                },

                actionsColumnIndex: -1,
              }}
              detailPanel={[
                {
                  tooltip: "Show",
                  render: (rowData) => {
                    return (
                      <div
                        style={{
                          color: "black",
                          marginLeft: 50,
                        }}
                      >
                        <p>Emitido Por: {rowData.destination_person}</p>
                        <p>Autorizado Por: {rowData.authorized_person}</p>
                        <p>Tipo de Pago: {rowData.payment_method}</p>
                        <p>
                          Total:
                          {new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                            minimumFractionDigits: 0,
                          }).format(rowData.total)}
                        </p>
                        <p>Opciones:</p>
                        <p>
                          {
                            <Fragment>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                }}
                                size="small"
                                onClick={() => GenerateTicket(rowData.id)}
                              >
                                <Tooltip
                                  title="Generar Ticket"
                                  aria-label="Generar Ticket"
                                >
                                  <PictureAsPdf style={{ color: "indigo" }} />
                                </Tooltip>
                              </IconButton>
                            </Fragment>
                          }
                        </p>
                      </div>
                    );
                  },
                },
              ]}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from} - {to} de {count}",
                  labelRowsSelect: "Columnas",
                  firstTooltip: "Primera Pagina",
                  previousTooltip: "Anterior",
                  nextTooltip: "Siguiente",
                  lastTooltip: "Ultima Página",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                header: {
                  actions: "Opciones",
                },
                body: {
                  emptyDataSourceMessage: "No Hay Salidas Que Mostrar",
                  filterRow: {
                    filterTooltip: "Buscar",
                  },
                },
              }}
            />
          </Grid>
          {/* sek */}
        </Grid>
        {/* separacion */}
        <Grid item xs={12} sm={12} md={6} style={{ padding: 20 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
                color: "white",
                marginTop: 20,
                borderRadius: 15,
                paddingLeft: 25,
                paddingRight: 25,
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                className={classes.typography}
              >
                Corte De Caja General
              </Typography>
            </Grid>
          </Grid>
          {/**
           * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
           *
           */}

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: 45 }}
          >
            <MaterialTable
              title=""
              columns={[
                { title: "Fecha", field: "date" },
                {
                  title: "Total En Inscripciones",
                  field: "total_income",
                  render: (rowData) =>
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(rowData.total_income),
                },
              ]}
              data={incomes}
              options={{
                headerStyle: {
                  backgroundColor: "none",
                  color: "#000",
                  fontSize: "16px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                },

                actionsColumnIndex: -1,
              }}
              detailPanel={[
                {
                  tooltip: "Show",
                  render: (rowData) => {
                    return (
                      <div
                        style={{
                          color: "black",
                          marginLeft: 50,
                        }}
                      >
                        <p>
                          Total en Gastos:{" "}
                          {new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                            minimumFractionDigits: 0,
                          }).format(rowData.total_expense)}
                        </p>
                        <p>
                          Ganancia / Perdida:{" "}
                          {new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                            minimumFractionDigits: 0,
                          }).format(rowData.profit)}
                        </p>
                        <p>Opciones:</p>
                        <p>
                          {
                            <Fragment>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                }}
                                size="small"
                                onClick={() => GenerateSchoolIncome(rowData.id)}
                              >
                                <Tooltip
                                  title="Generar Reporte"
                                  aria-label="Generar Reporte"
                                >
                                  <PictureAsPdf style={{ color: "indigo" }} />
                                </Tooltip>
                              </IconButton>
                            </Fragment>
                          }
                        </p>
                      </div>
                    );
                  },
                },
              ]}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from} - {to} de {count}",
                  labelRowsSelect: "Columnas",
                  firstTooltip: "Primera Pagina",
                  previousTooltip: "Anterior",
                  nextTooltip: "Siguiente",
                  lastTooltip: "Ultima Página",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                header: {
                  actions: "Opciones",
                },
                body: {
                  emptyDataSourceMessage: "No Hay Cortes Existentes",
                  filterRow: {
                    filterTooltip: "Buscar",
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* separacios */}
      {/* separacios */}
      {/* separacios */}
      {/* separacion */}

      <ModalAddCashOut
        open={open}
        setOpen={setOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        AddCashOut={AddCashOut}
        SchoolStorageID={schoolID}
        success={success}
      />
    </>
  );
}
