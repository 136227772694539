import React, { useReducer } from 'react';
import ExpensesContext from './ExpensesContext'
import ExpensesReducer from './ExpensesReducer';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../Config/Services";
import { ADD_NEW_SCHOOL_EXPENSE, CURRENT_SCHOOL_EXPENSE, DELETE_SCHOOL_EXPENSE, 
    GET_ALL_SCHOOL_EXPENSES, UPDATE_SCHOOL_EXPENSE } from "../../types";
import Swal from "sweetalert2";

const EXpensesState = ({children}) => {
  // estado inicial
  const initialSate = {
    expenses: [],
    expense: {},
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(ExpensesReducer, initialSate);

  //obtener la lista de proveedores
  const GetExpensesSchool = (id) => {
    sessionStorage.removeItem('expense');
    let url = `sucursalgastos/${id}`;
    MethodGet(url).then((response) => {
        //console.log(response);
      dispatch({
        type: GET_ALL_SCHOOL_EXPENSES,
        payload: response.data.data,
      });
    });
  };

  //función para crear una nueva escuela
  const AddSchoolExpense = (course) => {
    //console.log(school, image, municipality, state);
    const formData = new FormData();
		formData.append("name", course.name);
		formData.append("cost", course.cost);
		formData.append("description", course.description);
		formData.append("branch_office_id", course.school_id);
		formData.append("type_course", course.type_course);
    if(course.type_course == 2)
    {
		  let courses_list = JSON.stringify(course.courses);
      formData.append("courses_list", courses_list);
    }
    //console.log(school.image);
    let url = "/courses";
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_SCHOOL_EXPENSE,
        });
      })
      .catch((error) => {
        Swal.fire({
					title: 'Error',
                    text: error.response.data.error,
    			    icon: 'error'
				});
        dispatch({
          payload: error.response.data.error,
        });
      });
  };

  //función para seleccionar un salon
  const SaveCurrentSchoolExpense = (expense) => {
    sessionStorage.setItem('course',JSON.stringify(expense));
    dispatch({
      type: CURRENT_SCHOOL_EXPENSE,
      payload: expense,
    });
  };

  //función para editar un salon
  const UpdateSchoolExpense = (course) => {
    const formData = new FormData();
        formData.append("name", course.name);
        formData.append("cost", course.cost);
        formData.append("type_course", course.type_course);
        formData.append("branch_office_id", course.school_id);
        formData.append("description", course.description);
		    formData.append("_method", "PATCH");
        if(course.type_course == 2)
        {
		      let courses_list = JSON.stringify(course.courses);
          formData.append("courses_list", courses_list);
        }
      
    let url = `courses/${course.id}`;
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_SCHOOL_EXPENSE,
        });
      })
      .catch((error) => {
        Swal.fire({
					title: 'Error',
          text: error.response.data.error,
    			icon: 'error'
				});
        dispatch({
          payload: error.response.data.error,
        });
      });
  };

  //Eliminar Proveedor por id
  const DeleteSchoolExpense = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El gasto ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `expenses/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_SCHOOL_EXPENSE,
            payload: id,
          });
        });
      }
    });
  };

  return (
    <ExpensesContext.Provider
      value={{
        expenses: state.expenses,
        expense: state.expense,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        GetExpensesSchool,
        AddSchoolExpense,
        SaveCurrentSchoolExpense,
        UpdateSchoolExpense,
        DeleteSchoolExpense,
      }}
    >
      {children}
    </ExpensesContext.Provider>
  );
}

export default EXpensesState;