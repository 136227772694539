import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, Tooltip } from "@material-ui/core";
import Icon from "@material-ui/icons/PictureAsPdf";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Moment from "moment";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import FormControl from "@material-ui/core/FormControl";

/**Import Sweet Alert2 */
import Swal from "sweetalert2";
import Alert from "@material-ui/lab/Alert";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import { useState } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ProductsEntry() {
  const classes = useStyles();
  const [fechaInicio, GuardarFechaInicio] = useState("");
  const [fechaFin, GuardarFechaFin] = useState("");
  const [selectedDateStart, handleDateChangeStart] = useState(new Date());
  const [selectedDateEnd, handleDateChangeEnd] = useState(new Date());
  const [error, guardarError] = useState(false);
  const [nameOffice, guardarSucursal] = useState({
    id_branch_office: "",
  });
  const cambiarFechaInicio = (date) => {
    var fecha = Moment(date).format("DD-MM-YYYY");
    GuardarFechaInicio(fecha);
    handleDateChangeStart(date);
  };
  const cambiarFechaFin = (date) => {
    var fecha = Moment(date).format("DD-MM-YYYY");
    GuardarFechaFin(fecha);
    handleDateChangeEnd(date);
  };

  const detectarCambiosOffice = (e) => {
    guardarSucursal({ id_branch_office: e.target.value });
  };
  const GenerarReporte = (e) => {
    e.preventDefault();
    let timerInterval;
    Swal.fire({
      title: "Generando",
      html: "El reporte se está generando",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      willClose: () => {
        clearInterval(timerInterval);
      },
    })
    const data = {
      start_date: fechaInicio,
      end_date: fechaFin,
    };
    clienteAxios
      .post("/reporte-entrada-general", data, { responseType: "blob" })
      .then((response) => {
        fileDownload(
          response.data,
          "Reporte Entrada De Productos General " +
            fechaInicio +
            " - " +
            fechaFin +
            ".pdf"
        );
        guardarError(false);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          Swal.fire({
            icon: "error",
            title: "Algo sucedio",
            text: "No se cuenta con suficiente información para generar el reporte",
          });
        } else if (error.response.status === 422) {
          guardarError(true);
        }
      });
  };
  const GenerarReporteSucursal = (e) => {
    e.preventDefault();
    let timerInterval;
    Swal.fire({
      title: "Generando",
      html: "El reporte se está generando",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      willClose: () => {
        clearInterval(timerInterval);
      },
    })
    if (nameOffice.id_branch_office !== "") {
      const data = {
        start_date: fechaInicio,
        end_date: fechaFin,
        branch_office_id: nameOffice.id_branch_office,
      };
      clienteAxios
        .post("/reporte-entrada-sucursal", data, { responseType: "blob" })
        .then((response) => {
          fileDownload(
            response.data,
            "Reporte Entrada De Productos " +
              fechaInicio +
              " - " +
              fechaFin +
              ".pdf"
          );
          guardarError(false);
        })
        .catch((error) => {
          if (error.response.status === 409) {
            Swal.fire({
              icon: "error",
              title: "Algo sucedio",
              text: "No se cuenta con suficiente información para generar el reporte",
            });
          } else if (error.response.status === 422) {
            guardarError(true);
          }
        });
    }
  };
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading} fontWeight="bold">
            Entrada de productos General
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form onSubmit={GenerarReporte} noValidate>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    inputVariant="outlined"
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Fecha Inicio"
                    disableFuture
                    value={selectedDateStart}
                    onChange={cambiarFechaInicio}
                    InputAdornmentProps={{ position: "start" }}
                  />
                  {!fechaInicio ? (
                    <p style={{ color: "red" }}>
                      La Fecha inicial aún no ha sido seleccionada
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    inputVariant="outlined"
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    disableFuture
                    label="Fecha Fin"
                    value={selectedDateEnd}
                    onChange={cambiarFechaFin}
                    InputAdornmentProps={{ position: "start" }}
                  />
                  {!fechaFin ? (
                    <p style={{ color: "red" }}>
                      La Fecha inicial aún no ha sido seleccionada
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Tooltip title="Generar Reporte" aria-label="Generar Reporte">
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      endIcon={<Icon>send</Icon>}
                    >
                      Generar Reporte
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </MuiPickersUtilsProvider>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading} fontWeight="bold">
            Entrada de productos sucursal
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {error ? (
              <Alert severity="warning">
                Todos los campos son obligatorios!
              </Alert>
            ) : null}
            <form
              onSubmit={GenerarReporteSucursal}
              className={classes.form}
              noValidate
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      inputVariant="outlined"
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Fecha Inicio"
                      disableFuture
                      value={selectedDateStart}
                      onChange={cambiarFechaInicio}
                      InputAdornmentProps={{ position: "start" }}
                    />
                    {!fechaInicio ? (
                      <p style={{ color: "red" }}>
                        La Fecha inicial aún no ha sido seleccionada
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <KeyboardDatePicker
                      autoOk
                      fullWidth
                      inputVariant="outlined"
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      disableFuture
                      label="Fecha Fin"
                      value={selectedDateEnd}
                      onChange={cambiarFechaFin}
                      InputAdornmentProps={{ position: "start" }}
                    />
                    {!fechaFin ? (
                      <p style={{ color: "red" }}>
                        La Fecha inicial aún no ha sido seleccionada
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ paddingTop: 16 }}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <SelectBranchOffice
                        detectarCambiosOffice={detectarCambiosOffice}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Tooltip
                      title="Generar Reporte"
                      aria-label="Generar Reporte"
                    >
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<Icon>send</Icon>}
                      >
                        Generar Reporte
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </form>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
