import React, {useEffect,useState,useContext,Fragment} from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Grid, Box, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";
/**Componentes Tabla */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
//import AuthContext from '../../Context/autenticacion/authContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';

import { useForm } from 'react-hook-form';
/**Habilitar redirecciones */
import { withRouter } from 'react-router-dom';

import Swal from 'sweetalert2';

import { MethodPost } from '../../Config/Services';

import SelectClient from '../SelectOptions/SelectClient';
const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex'
    },
    container: {
        paddingBottom: theme.spacing(4),
        paddingInlineEnd: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(30),
      },
    typography: {
        paddingTop: theme.spacing(4),
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(30)

    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(9),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBlockEnd: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: "#3537DB",
        "&:hover": {
          //you want this to be the same as the backgroundColor above
          background: "#5902CF"
      },
      color: 'white'
      },
    formControl: {
		margin: theme.spacing(1),
		width:'100%'
	},
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paperModal: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    iconButton: {
        '& > svg': {
            margin: theme.spacing(2),
        },
    },
    TableRow: {
        background: 'Orange'
    },
    h2: {
        background: '#394ED1',
        color: 'white'
    }
}));


const name_method = [
    { title: 'BBVA Bancomer' },
    { title: 'Santander' },
    { title: 'Scotiabank' },
    { title: 'Banco Azteca' },
    { title: 'Banjercito' },
    { title: 'CitiBanamex' },
    { title: 'Afirme' },
    { title: 'Banorte' },
    { title: 'Farmacias del Ahorro' },
    { title: 'Farmacias Guadalajara' },
    { title: 'HSBC' },
    { title: 'Telecomm' },
    { title: 'Oxxo' },
    { title: 'Efectivo' },
]

const name_client = [
    { title: 'Jimena Tovar Hernandez'}
  ]
  const type_client = [
    { title: 'Cliente General'},
    { title: 'Cliente Alumno'},
  ]

  const name = [
    { title: 'Aplicacion de Uñas'}
  ] 

  const name_user = [
    { title: 'Maria Robles Robles'},
  ]
  const name_branch = [
    { title: 'Studio GlamOur'},
  ]

function SalesAddApplication(props) {
    const classes = useStyles();
    {/**Este Es para abrir el Modal de Metodos de pago */ }
    const [open, setOpen] = React.useState(false);
    
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    {/**Este es para abrir el modal de agregar cliente */ }
    const [openaddClient, setOpenaddClient] = useState(false);

    const handleOpenaddClient = () => {
        setOpenaddClient(true);
    };

    const handleCloseaddClient = () => {
        setOpenaddClient(false);
    };
 
    //------------ Venta de aplicacion de uñas ----------

  	const [client, saveClient] = useState({
			id_client: '',
		});

		const detectarCambioClient = (e) => {
			saveClient({ id_client: e.target.value });
		};

		//Funcion para validaciones
		const { register, handleSubmit, errors } = useForm();

		const onSubmit = (data, e) => {
			e.preventDefault();

			if (client.id_client !== '') {
				const dat = {
					...data,
					...client,
				};

				let url = '/sales';
				MethodPost(url, dat)
					.then((res) => {
						Swal.fire({
							title: 'Venta de Producto Registrado Exitosamente',
							text: res.data.message,
							icon: 'success',
							timer: 2000,
							showConfirmButton: false,
						});

						props.history.push('/Ventas');
					})
					.catch((error) => {
						Swal.fire({
							title: 'Error',
							text: error.response.data.error,
							icon: 'error',
						});
					});
			} else {
				Swal.fire({
					title: 'Error',
					text: 'Todos los campos son Obligatorios',
					icon: 'error',
				});
			}
		};

    return (
			<LayoutDashboard>
				<div className={classes.root}>
					<form className={classes.form} noValidate>
						<Box>
							<Typography
								component="h1"
								variant="h4"
								align="center"
								className={classes.typography}
							>
								Agregar Venta de Aplicación de Uñas
							</Typography>
						</Box>
						<Grid container spacing={3} className={classes.container}>
							<Grid container spacing={1}>
								<Grid item xs={4}>
									<label>Tipo de Servicio</label>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="name"
										disabled
										value="Aplicación de Uñas"
										name="name"
										autoComplete="name"
										autoFocus
									/>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={3}>
									<FormControl
										variant="outlined"
										className={classes.formControl}
									>
										<SelectClient detectarCambioClient={detectarCambioClient} />
									</FormControl>
								</Grid>
								<Grid item xs={1}>
									<Modal
										aria-labelledby="add_client"
										aria-describedby="transition-modal-description"
										className={classes.modal}
										open={openaddClient}
										closeAfterTransition
										BackdropComponent={Backdrop}
										BackdropProps={{
											timeout: 500,
										}}
									>
										<Fade in={openaddClient}>
											<div className={classes.paperModal}>
												<Typography>
													<Box align="right">
														<Fab aria-label="cancel" size="small">
															<CloseIcon onClick={handleCloseaddClient} />
														</Fab>
													</Box>

													<h2
														id="add_client"
														align="Center"
														className={classes.h2}
													>
														<hr /> Nuevo Cliente <hr />
													</h2>
												</Typography>

												<form onSubmit={handleSubmit(onSubmit)} noValidate>
													<Grid Container spacing={2}>
														<Grid item xs={12}>
															<Autocomplete
																id="combo-box-demo"
																fullWidth
																options={name_branch}
																getOptionLabel={(option) => option.title}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label="Sucursal"
																		variant="outlined"
																	/>
																)}
															/>
														</Grid>
														<Grid item xs={12}>
															<TextField
																variant="outlined"
																margin="normal"
																fullWidth
																id="name"
																label="Nombre Completo"
																name="name"
																autoComplete="name"
																autoFocus
																error={!!errors.name}
																inputRef={register({
																	required: {
																		value: true,
																		message: 'El Nombre es requerido',
																	},
																	minLength: {
																		value: 4,
																		message: 'Minimo 4 caracteres',
																	},
																	maxLength: {
																		value: 255,
																		message: 'Maximo 255 caracteres',
																	},
																	pattern: {
																		value: /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
																		message:
																			'Unicamente carácteres alfabéticos',
																	},
																})}
															/>
															<p>{errors?.name?.message}</p>
														</Grid>
														<Grid>
															<TextField
																variant="outlined"
																margin="normal"
																required
																fullWidth
																id="number_phone"
																label="Teléfono"
																name="number_phone"
																autoComplete="phone"
																autoFocus
																type="number"
																error={!!errors.number_phone}
																inputRef={register({
																	required: {
																		value: true,
																		message: 'El Teléfono es requerido',
																	},
																	maxLength: {
																		value: 10,
																		message: 'Maximo 10 digitos',
																	},
																})}
															/>
															<p>{errors?.number_phone?.message}</p>
														</Grid>
														<Grid item xs={12}>
															<Button
																type="submit"
																fullWidth
																variant="outlined"
																className={classes.submit}
															>
																<label>GUARDAR</label>
															</Button>
														</Grid>
													</Grid>
												</form>
											</div>
										</Fade>
									</Modal>
									<div className={classes.iconButton}>
										<Tooltip
											title="Agregar Cliente"
											aria-label="Agregar Cliente"
										>
											<PersonAddIcon
												style={{ fontSize: 45 }}
												onClick={handleOpenaddClient}
											/>
										</Tooltip>
									</div>
								</Grid>
								<Grid item xs={2}></Grid>
								<Grid item xs={4}>
									<label>Seleccionar Manicurista</label>
									<Autocomplete
										id="combo-box-demo"
										fullWidth
										options={name_user}
										getOptionLabel={(option) => option.title}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Manicurista"
												variant="outlined"
											/>
										)}
									/>
								</Grid>
								<Grid item xs={1}>
									<div className={classes.iconButton}>
										<Tooltip title="Agregar" aria-label="Agregar">
											<AddBoxOutlinedIcon style={{ fontSize: 45 }} />
										</Tooltip>
									</div>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<TableContainer component={Paper}>
									<Table className={classes.table} aria-label="spanning table">
										<TableHead>
											<TableRow className={classes.TableRow}>
												<TableCell align="center">Clave</TableCell>
												<TableCell align="center">Nombre</TableCell>
												<TableCell align="center">Manicurista</TableCell>
												<TableCell align="center">Fecha</TableCell>
												<TableCell align="center">Hora Inicial</TableCell>
												<TableCell align="center">Hora Final</TableCell>
												<TableCell align="center">Total</TableCell>
												<TableCell align="center">Opciones</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
												<TableRow >
													<TableCell align="center">--</TableCell>
													<TableCell align="center">--</TableCell>
													<TableCell align="center">--</TableCell>
													<TableCell align="center"><TextField type="date" style={{width:140}}/></TableCell>
													<TableCell align="center"><TextField type="time" style={{width:115}}/></TableCell>
													<TableCell align="center"><TextField type="time" style={{width:115}}/></TableCell>
													<TableCell align="center">--</TableCell>
													<TableCell align="center"></TableCell>
												</TableRow> 
												<TableRow>
													<TableCell align="center" colSpan={8}>
														No hay Aplicaciones de Uñas Agregados
													</TableCell>
												</TableRow>
											
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
							<Grid item xs={9}></Grid>

							<Grid item xs={3}>
								<Button
									type="button"
									fullWidth
									color="primary"
									className={classes.submit}
									onClick={handleOpen}
								>
									<label>Métodos de Pago</label>
								</Button>

								<Modal
									aria-labelledby="transition-modal-title"
									aria-describedby="transition-modal-description"
									className={classes.modal}
									open={open}
									closeAfterTransition
									BackdropComponent={Backdrop}
									BackdropProps={{
										timeout: 500,
									}}
								>
									<Fade in={open}>
										<div className={classes.paperModal}>
											<Typography>
												<Box align="right">
													<Fab aria-label="cancel" size="small">
														<CloseIcon onClick={handleClose} />
													</Fab>
												</Box>
												<h2
													id="transition-modal-title"
													align="Center"
													className={classes.h2}
												>
													<hr /> Método de Pago
													<hr />
												</h2>
											</Typography>

											<form onSubmit={handleSubmit(onSubmit)} noValidate>
												<Grid Container spacing={2}>
													<Grid item xs={12}>
														<label>Selecciona Método de Pago</label>
														<Autocomplete
															id="combo-box-demo"
															fullWidth
															options={name_method}
															getOptionLabel={(option) => option.title}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label="Metodos de Pago"
																	variant="outlined"
																/>
															)}
														/>
													</Grid>
													<Grid>
														<label>Por favor Ingresa la Referencia</label>
														<TextField
															variant="outlined"
															margin="normal"
															required
															fullWidth
															id="reference"
															label="Referencia"
															name="reference"
															autoComplete="name"
															autoFocus
															error={!!errors.reference}
															inputRef={register({
																required: {
																	value: true,
																	message: 'La referencia es requerida',
																},
																maxLength: {
																	value: 45,
																	message: 'Maximo 45 caracteres',
																},
															})}
														/>
														<p>{errors?.reference?.message}</p>
													</Grid>

													<Grid>
														<label>Por favor Ingresa una descripción</label>
														<TextField
															variant="outlined"
															margin="normal"
															required
															fullWidth
															id="description"
															label="Descripción"
															name="description"
															autoComplete="name"
															autoFocus
															error={!!errors.description}
															inputRef={register({
																required: {
																	value: true,
																	message: 'La Descripción es requerida',
																},
																minLength: {
																	value: 5,
																	message: 'Minimo 5 caracteres',
																},
																maxLength: {
																	value: 45,
																	message: 'Maximo 45 caracteres',
																},
															})}
														/>
														<p>{errors?.description?.message}</p>
													</Grid>

													<Grid item xs={12}>
														<Button
															type="submit"
															fullWidth
															variant="outlined"
															className={classes.submit}
														>
															<label>Enviar Venta</label>
														</Button>
													</Grid>
												</Grid>
											</form>
										</div>
									</Fade>
								</Modal>
							</Grid>
						</Grid>
					</form>
				</div>
			</LayoutDashboard>
		);
}

export default withRouter(SalesAddApplication)