import React, { useState, useEffect, useContext, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import { green } from "@material-ui/core/colors";
import {
  Button,
  Tooltip,
  Hidden,
  Grid,
  Box,
  Fab,
  Typography,
  Container,
} from "@material-ui/core";
/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import InfoIcon from "@material-ui/icons/Info";
import DeparturesContext from "../../Reducers/Departures/DeparturesContext";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  whiteSpace: "nowrap",

  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
    marginRight: "-2%",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  customWidth: {
    fontSize: "12px",
    maxWidth: 200,
    justifyContent: "center",
    padding: "5px",
  },
}));

const BranchDepartures = (props) => {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);
  const { id } = props.match.params;
  const [branch, guardarBranch] = useState({});
  const { name, direction, number_phone, state_id, municipality_id } = branch;

  const ClassroomsList = useContext(DeparturesContext);
  const { departures, GetDepartures, success } = ClassroomsList;
  const [error, guardarError] = useState(false);

  useEffect(() => {
    GetDepartures(id);
    spinnerCargando(true);
  }, [success]);

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const generarTicket = (id, folio) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Generar El Ticket De Productos Salientes?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Generar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        //Cunsume API de Back-end en laravel para generar tiket de venta
        clienteAxios
          .get(`/ticketsalientes/${id}`, { responseType: "blob" })
          .then((response) => {
            fileDownload(response.data, "Ticket Productos Salientes.pdf");
            guardarError(false);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              Swal.fire({
                icon: "error",
                title: "Algo sucedio",
                text: "No se cuenta con suficiente información para generar el reporte",
              });
            } else if (error.response.status === 422) {
              guardarError(true);
            }
          });
      }
    });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* titulo */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 100,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            className={classes.typography}
            component="h1"
            variant="h4"
          >
            {"Productos Salientes Sucursal " + name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            href={"/Salidas/Agregar/" + id}
          >
            <Tooltip
              title={"Agregar Salida Para La Sucursal " + name}
              aria-label={"Agregar Salida Para La Sucursal " + name}
            >
              <AddIcon size="large" />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
   		  <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 20 }}
        >
        <MaterialTable
          title=""
          columns={[
            { title: "Descripción", field: "description" },
            { title: "Usuario Destino", field: "seller_name" },
            { title: "Autoriza", field: "owner_name" },
            { title: "Fecha Realizada", field: "created_at", type: "date", },

          ]}
          data={departures}
          options={{
            headerStyle: {
              backgroundColor: "none",
              color: "#000",
              fontSize: "16px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },

            actionsColumnIndex: -1,
          }}
          actions={[{}]}
          components={{
            Action: (props) => (
              <Fragment>
                <Button
                  style={{ textTransform: "none" }}
                  size="small"
                  onClick={() => generarTicket(props.data.id)}
                >
                  <Tooltip
                    title="Generar ticket de productos salientes"
                    aria-label="Generar ticket de productos salientes"
                  >
                    <PictureAsPdf style={{ color: "indigo" }} />
                  </Tooltip>
                </Button>
              </Fragment>
            ),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsSelect: "Columnas",
              firstTooltip: "Primera Pagina",
              previousTooltip: "Anterior",
              nextTooltip: "Siguiente",
              lastTooltip: "Ultima Página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Opciones",
            },
            body: {
              emptyDataSourceMessage: "No Hay Productos que Mostrar",
              filterRow: {
                filterTooltip: "Buscar",
              },
            },
          }}
        />
      </Grid>
	  </Hidden>
	  <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 30 }}
        >
        <MaterialTable
          title=""
          columns={[
            { title: "Descripción", field: "description" },
            { title: "Fecha Realizada", field: "created_at", type: "date", },
          ]}
          data={departures}
          options={{
            headerStyle: {
              backgroundColor: "none",
              color: "#000",
              fontSize: "16px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },

            actionsColumnIndex: -1,
          }}
          detailPanel={[
            {
              tooltip: "Show",
              render: (rowData) => {
                return (
                  <div
                    style={{
                      color: "black",
                      marginLeft: 50,
                    }}
                  >
                    <p>Usuario Destino: {rowData.seller_name}</p>
                    <p>Autoriza: {rowData.owner_name}</p>
                    <p>Opciones:</p>
                    <p>
                      {" "}
                      {
                        <Fragment>
                          <Button
                            style={{ textTransform: "none", marginLeft: 100 }}
                            size="small"
                            onClick={() => generarTicket(rowData.id)}
                          >
                            <Tooltip
                              title="Generar ticket de productos salientes"
                              aria-label="Generar ticket de productos salientes"
                            >
                              <PictureAsPdf style={{ color: "indigo" }} />
                            </Tooltip>
                          </Button>
                        </Fragment>
                      }
                    </p>
                  </div>
                );
              },
            },
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsSelect: "Columnas",
              firstTooltip: "Primera Pagina",
              previousTooltip: "Anterior",
              nextTooltip: "Siguiente",
              lastTooltip: "Ultima Página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Opciones",
            },
            body: {
              emptyDataSourceMessage: "No Hay Productos que Mostrar",
              filterRow: {
                filterTooltip: "Buscar",
              },
            },
          }}
        />
      </Grid>
	  </Hidden>
    </LayoutDashboard>
  );
};

export default BranchDepartures;
