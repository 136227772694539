import { Grid, Typography, Hidden, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2'
import { MethodPost } from '../../Config/Services';
import MethodGet from '../../Config/Services'
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import SelectBranch from '../Graphics/SelectBranch';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(1),
        //marginLeft: theme.spacing(-1),
        paddingBottom: theme.spacing(1),
        paddingInlineEnd: theme.spacing(1),
        paddingRight:theme.spacing(0),
        marginLeft:'-50px'
      },
	formControl: {
		width:'100%',
		heigth:'100%',
        margin: theme.spacing(1),
        maxHeight:400,
	},
    papercard: {
        padding: theme.spacing(2),
        margin:'auto',
        maxHeight:'470px',
        backgroundColor:'transparent',
        flexWrap: 'nowrap',
        display: 'relative',
        boxSizing: 'border-box',

    },
    containerGraphic:{
            width: '100%',
            height: '440px',
            boxSizing: 'border-box',
            position:'relative',
            display: 'absolute',
    },
    GraphicDetails: {
        display: 'absolute',
        width: '100%',
        height: '440px',
        maxHeight: '450px',
        position:'absolute',
        boxSizing: 'border-box',
      },

}));

function GraphicStockUser() {

    const classes = useStyles();

    const options = {
        maintainAspectRatio: false	// Don't maintain w/h ratio
    }
    const [chartData, setChartData] = useState({});

    const chart = () => {
        let stkQuantity = [];
        let stkProduct = [];
        //Query a la api 
        let url = '/graphics/stocks-user'
        MethodPost(url)
            .then(res => {
                for (const dataObj of res.data.data) {
                    stkQuantity.push(parseInt(dataObj.quantity));
                    stkProduct.push(String(dataObj.name))
                }
                 //propiedades de la grafica
                 setChartData({
                    labels: stkProduct,
                    datasets: [
                        {
                            label: 'Stock Total de mi Sucursal',
                            data: stkQuantity,
                            responsive: false,
                            display: true,
                            fill: false,
                            fullwidth: false,
                            fullHeight:true,
                            lineTension: 0.1,
                            backgroundColor: [
                                '#aed581', '#dce775', '#fff176', '#ffd54f', '#ffb74d',
                                '#ff8a65', '#f06292', '#f44336', '#ec407a', '#ab47bc',
                                '#ff1744', '#f50057', '#d500f9', '#673ab7', '#3f51b5',
                                '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
                                '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800',
                                '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#8e24aa', '#ad1457'

                            ],
                            borderColor: [
                                '#aed581', '#dce775', '#fff176', '#ffd54f', '#ffb74d',
                                '#ff8a65', '#f06292', '#f44336', '#ec407a', '#ab47bc',
                                '#ff1744', '#f50057', '#d500f9', '#673ab7', '#3f51b5',
                                '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
                                '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800',
                                '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#8e24aa', '#ad1457'

                            ],
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderColor: [
                                '#aed581', '#dce775', '#fff176', '#ffd54f', '#ffb74d',
                                '#ff8a65', '#f06292', '#f44336', '#ec407a', '#ab47bc',
                                '#ff1744', '#f50057', '#d500f9', '#673ab7', '#3f51b5',
                                '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
                                '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800',
                                '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#8e24aa', '#ad1457'

                            ],
                            pointBackgroundColor: [
                                '#aed581', '#dce775', '#fff176', '#ffd54f', '#ffb74d',
                                '#ff8a65', '#f06292', '#f44336', '#ec407a', '#ab47bc',
                                '#ff1744', '#f50057', '#d500f9', '#673ab7', '#3f51b5',
                                '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
                                '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800',
                                '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#8e24aa', '#ad1457'

                            ],
                            pointBorderWidth: 5,
                            pointHoverRadius: 5,
                            pointHoverBackgroundColor: [
                                '#aed581', '#dce775', '#fff176', '#ffd54f', '#ffb74d',
                                '#ff8a65', '#f06292', '#f44336', '#ec407a', '#ab47bc',
                                '#ff1744', '#f50057', '#d500f9', '#673ab7', '#3f51b5',
                                '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
                                '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800',
                                '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#8e24aa', '#ad1457'

                            ],
                            pointHoverBorderColor: [
                                '#aed581', '#dce775', '#fff176', '#ffd54f', '#ffb74d',
                                '#ff8a65', '#f06292', '#f44336', '#ec407a', '#ab47bc',
                                '#ff1744', '#f50057', '#d500f9', '#673ab7', '#3f51b5',
                                '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50',
                                '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800',
                                '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#8e24aa', '#ad1457'

                            ],
                            pointHoverBorderWidth: 2,
                            pointRadius: 1,
                            pointHitRadius: 10,
                        }
                    ]
                });

            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        chart();
    }, []);

    return (
        <div className={classes.container}>

            <Grid 
            container xs={12} sm={12} md={12} lg={12} 
            justify="center" 
            style={{ marginBottom:'15px'}}
            >
                <Hidden only={['md', 'lg','xl']} >
                    <Typography component="h4" variant="h4">
                        Stock en mi Sucursal
                    </Typography>
                </Hidden>
                <Hidden only={['xs', 'sm',]}>
                    <Typography component="h2" variant="h2">
                        Stock en mi Sucursal
                     </Typography>
                </Hidden>
            </Grid> 
            <Paper className={classes.papercard}>
                <div className={classes.containerGraphic}>
                    <Grid container xs={12} sm={12} md={12} lg={12} className={classes.formControl}>
                        <Bar data={chartData} options={options} className={classes.GraphicDetails} />
                    </Grid>
                </div>
            </Paper>
        </div>
    )
}

export default GraphicStockUser