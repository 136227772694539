import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { Button, Tooltip, Hidden, IconButton, Fab } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { green } from "@material-ui/core/colors";
import MethodGet from "../../Config/Services";
import Spinner from "../../Complements/Spinner";
import AddIcon from "@material-ui/icons/Add";
import CoursesContext from "../../Reducers/Courses/CoursesContext";

const useStyles = makeStyles((theme) => ({
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const SchoolSellerCourses = (props) => {
  const classes = useStyles();

  const { schoolID } = props;

  const [cargando, spinnerCargando] = useState(false);

  const [school, saveSchool] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name } = school;

  /**Extraer los cursos del state inicial */
  const CoursesList = useContext(CoursesContext);
  const { courses, GetCourses, success } = CoursesList;

  const StateCoursesContext = useContext(CoursesContext);
  const { SaveCurrentCourse, DeleteCourse } = StateCoursesContext;

  const SelectCourse = (course) => {
    SaveCurrentCourse(course);
  };

  //obtener los proveedores carga el componente
  useEffect(() => {
    GetCourses(schoolID);
    spinnerCargando(true);
  }, [success, schoolID]);

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${schoolID}`;
    MethodGet(url)
      .then((res) => {
        saveSchool(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [schoolID]);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* inicio */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            marginTop: 100,
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 15,
            marginRight: 5,
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            className={classes.typography}
          >
            {"Cursos de la Sucursal " + name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            href="/Agregar_Curso"
          >
            <Tooltip title="Agregar Curso" aria-label="Agregar Curso">
              <AddIcon size="large" />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Clave", field: "id" },
              { title: "Nombre", field: "name" },
              {
                title: "Costo",
                field: "cost",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.cost),
              },
              {
                title: "Descripción",
                field: "description",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.description ? (
                        rowData.description
                      ) : (
                        <p>Sin Descripción</p>
                      )}
                    </Fragment>
                  );
                },
              },
              {
                title: "Tipo De Curso",
                field: "type_course",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.type_course == 1 ? (
                        <p>Taller </p>
                      ) : (
                        <p>Diplomado</p>
                      )}
                    </Fragment>
                  );
                },
              },
            ]}
            data={courses}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "17px",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "delete",
                tooltip: "Eliminar Curso",
                onClick: () => DeleteCourse(rowData.id),
              }),
            ]}
            components={{
              Action: (props) => (
                <Fragment>
                  {props.data.type_course == 2 ? (
                    <>
                      <Link to={`Editar_Curso`} style={{ color: "#3f51b5" }}>
                        <Button
                          variant="contained"
                          style={{
                            textTransform: "none",
                            background: "white",
                          }}
                          onClick={() => SelectCourse(props.data.id)}
                          size="small"
                        >
                          <Tooltip
                            title="Editar Diplomado"
                            aria-label="Editar Diplomado"
                          >
                            <EditIcon style={{ color: "blue" }} />
                          </Tooltip>
                        </Button>
                      </Link>
                      <Link to={`Diplomado`} style={{ color: "#3f51b5" }}>
                        <Button
                          variant="contained"
                          style={{
                            textTransform: "none",
                            background: "white",
                          }}
                          onClick={() => SelectCourse(props.data.id)}
                          size="small"
                        >
                          <Tooltip
                            title="Ver Diplomado"
                            aria-label="Ver Diplomado"
                          >
                            <VisibilityIcon style={{ color: "indigo" }} />
                          </Tooltip>
                        </Button>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to={`Editar_Curso`} style={{ color: "#3f51b5" }}>
                        <Button
                          variant="contained"
                          style={{
                            textTransform: "none",
                            background: "white",
                          }}
                          onClick={() => SelectCourse(props.data.id)}
                          size="small"
                        >
                          <Tooltip
                            title="Editar Curso"
                            aria-label="Editar Curso"
                          >
                            <EditIcon style={{ color: "blue" }} />
                          </Tooltip>
                        </Button>
                      </Link>
                    </>
                  )}
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Cursos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Clave", field: "id" },
              { title: "Nombre", field: "name" },
            ]}
            data={courses}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "17px",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Costo: ${rowData.cost}</p>
                      <p>Descripcion: {rowData.description}</p>
                      <p>
                        Tipo de curso:{" "}
                        {
                          <Fragment>
                            {rowData.type_course == 1 ? (
                              <>Taller </>
                            ) : (
                              <>Diplomado</>
                            )}
                          </Fragment>
                        }
                      </p>
                      <p>Opciones: </p>
                      <p>
                        {
                          <Fragment>
                            {rowData.type_course == 2 ? (
                              <>
                                <Link
                                  to={`Editar_Curso`}
                                  style={{ color: "#3f51b5" }}
                                >
                                  <IconButton
                                    variant="contained"
                                    style={{
                                      textTransform: "none",
                                      background: "white",
                                    }}
                                    onClick={() => SelectCourse(rowData.id)}
                                    size="small"
                                  >
                                    <Tooltip
                                      title="Editar Diplomado"
                                      aria-label="Editar Diplomado"
                                    >
                                      <EditIcon style={{ color: "blue" }} />
                                    </Tooltip>
                                  </IconButton>
                                </Link>
                                <Link
                                  to={`Diplomado`}
                                  style={{ color: "#3f51b5" }}
                                >
                                  <IconButton
                                    variant="contained"
                                    style={{
                                      textTransform: "none",
                                      background: "white",
                                      marginLeft: 50,
                                    }}
                                    onClick={() => SelectCourse(rowData.id)}
                                    size="small"
                                  >
                                    <Tooltip
                                      title="Ver Diplomado"
                                      aria-label="Ver Diplomado"
                                    >
                                      <VisibilityIcon
                                        style={{ color: "indigo" }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                </Link>
                              </>
                            ) : (
                              <>
                                <Link
                                  to={`Editar_Curso`}
                                  style={{ color: "#3f51b5" }}
                                >
                                  <IconButton
                                    variant="contained"
                                    style={{
                                      textTransform: "none",
                                      background: "white",
                                    }}
                                    onClick={() => SelectCourse(rowData.id)}
                                    size="small"
                                  >
                                    <Tooltip
                                      title="Editar Curso"
                                      aria-label="Editar Curso"
                                    >
                                      <EditIcon style={{ color: "blue" }} />
                                    </Tooltip>
                                  </IconButton>
                                </Link>
                              </>
                            )}
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Cursos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </LayoutDashboard>
  );
};

export default SchoolSellerCourses;
