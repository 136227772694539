import React, { useState, useEffect, useContext } from "react";
import FormControl from "@material-ui/core/FormControl";
import {
	Grid,
	Button,
	TextField,
	Typography,
	IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
/**Importar Select State */
import SelectBank from "../SelectOptions/SelectBank";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
import BankAccountContext from "../../Reducers/BankAccounts/BankAccountsContext";
import { useForm } from "react-hook-form";
import GridItem from "../../Components/Grid/GridItem";
import Card from "../../Components/Card/Card";
import CardBody from "../../Components/Card/CardBody";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
	root: {
	  margin: 0,
	  padding: theme.spacing(2),
	},
	closeButton: {
	  position: "absolute",
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  color: theme.palette.grey[500],
	},
  });
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingLeft: theme.spacing(10),
	},
	typography: {
		paddingTop: theme.spacing(4),
		margin: theme.spacing(1),
		paddingLeft: theme.spacing(30),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(9),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		background: 'indigo',
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			background: '#5902CF',
		},
		color: 'white',
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	input: {
		display: 'none',
	},
	containerImageProfile: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-around'
	},
	cardCategoryWhite: {
		color: "rgba(255,255,255,.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0"
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none"
	},


}));
const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
	  <MuiDialogTitle disableTypography className={classes.root} {...other}>
		<Typography variant="h6">{children}</Typography>
		{onClose ? (
		  <IconButton
			aria-label="close"
			className={classes.closeButton}
			onClick={onClose}
		  >
			<CloseIcon />
		  </IconButton>
		) : null}
	  </MuiDialogTitle>
	);
  });
  
  const DialogContent = withStyles((theme) => ({
	root: {
	  padding: theme.spacing(2),
	},
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
	root: {
	  margin: 0,
	  padding: theme.spacing(1),
	},
  }))(MuiDialogActions);

function AddBankAccount({modal, handleClose, id }) {
	const classes = useStyles();

	const formBankAccountContext = useContext(BankAccountContext);

	const { ErrorsAPI, success, AddBankAccount } = formBankAccountContext;

	const { register, errors, handleSubmit } = useForm();

	const [bankId, saveBank] = useState('');

	const detectarCambiosBancos = (e) => {
		saveBank(e.target.value);
	};

	useEffect(() => {
		//si success retorna verdadero la pagina redireccionara al index de escuelas
		if (success) {
			//console.log("Retorna");
			handleClose();
				}
		//eslint-disable-next-line
	}, [success]);

	//funcion que agrega una escuela
	const onSubmit = (data, e) => {
		e.preventDefault();
		data.bank_id = bankId;

		if (
			bankId !== ""
		) {
			//enviamos la data a la funcion que se encarga de guardar los nuevos registros
			AddBankAccount(data);
		} else {
			Swal.fire({
				title: 'Error',
				text: "Todos los campos son Obligatorios",
				icon: 'error'
			});
		}
	};

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={modal}
				maxWidth={1200}
				fullWidth
			>
				<DialogTitle id="customized-dialog-title" style={{ textAlign: "center", background: "#3F51B5", color: "white", }}
					onClose={handleClose}>
					Agregar Nueva Cuenta Bancaria
				</DialogTitle>
				<DialogContent dividers>
					<form onSubmit={handleSubmit(onSubmit)} noValidate>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
						>
							{/**
             * identificar Codigo de FORMS
             */}
							<GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
								<Card>

									<CardBody>

										<Grid container spacing={2}>
											<Grid item xs={12} sm={4}>
												<TextField
													variant="outlined"
													margin="normal"
													required
													fullWidth
													id="account_number"
													label="Numero de Cuenta"
													name="account_number"
													type="number"
													autoComplete="account_number"
													autoFocus
													error={!!errors.account_number}
													inputRef={register({
														required: {
															value: true,
															message: 'El Numero De Cuenta Es Requerido',
														},
														minLength: {
															value: 16,
															message: 'Minimo 16 caracteres',
														},
														maxLength: {
															value: 16,
															message: 'Maximo 16 caracteres',
														},
													})}
												/>
												<p>{errors?.account_number?.message}</p>
											</Grid>

											<Grid item xs={12} sm={4}>
												<TextField
													variant="outlined"
													margin="normal"
													required
													fullWidth
													id="owner_name"
													label="Nombre Del Propietario"
													name="owner_name"
													autoComplete="owner_name"
													autoFocus
													error={!!errors.owner_name}
													inputRef={register({
														required: {
															value: true,
															message: 'El nombre del propietario es requerida',
														},
														minLength: {
															value: 4,
															message: 'Minimo 4 caracteres',
														},
														maxLength: {
															value: 255,
															message: 'Maximo 255 caracteres',
														},
														pattern: {
															value: /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
															message: 'Unicamente carácteres alfabéticos',
														},
													})}
												/>
												<p>{errors?.owner_name?.message}</p>
											</Grid>
											<Grid item xs={12} sm={4} style={{ marginTop: 8 }}>
												<FormControl variant="outlined" className={classes.formControl} fullWidth style={{ marginLeft: -2 }}>
													<SelectBank detectarCambiosBancos={detectarCambiosBancos} />
												</FormControl>
											</Grid>
										</Grid>
										<Grid container spacing={2} justify="center">
											<Grid item spacing={3} xs={12} sm={12} md={6}>
												<Button
													type="submit"
													fullWidth
													variant="outlined"
													className={classes.submit}
												>
													GUARDAR
												</Button>
											</Grid>
										</Grid>
									</CardBody>
								</Card>
							</GridItem>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</div>
	);
}

export default withRouter(AddBankAccount);
