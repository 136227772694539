import {
    GET_DATA_EXCEL_PRODUCTS, GET_ALL_PRODUCTS, ADD_PRODUCTS_EXCEL,
    DELETE_PRODUCT, UPDATE_PRODUCTS_EXCEL, EDIT_PRODUCT,GET_DATA_EXCEL_PRODUCTS_STOCK,GET_DATA_EXCEL_PRODUCTS_SALE
} from "../../types";

export default function ProductReducer(state, action) {

    switch (action.type) {
        case GET_DATA_EXCEL_PRODUCTS:
            return {
                ...state,
                productsDataExcel: action.payload,
                success: false,
                ErrorsAPI: [],
            }
            case GET_DATA_EXCEL_PRODUCTS_SALE:
                return {
                    ...state,
                    productsDataExcel: action.payload,
                    success: false,
                    ErrorsAPI: [],
                }
            case GET_DATA_EXCEL_PRODUCTS_STOCK:
                return {
                    ...state,
                    productsDataExcel: action.payload,
                    success: false,
                    ErrorsAPI: [],
                }
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                success: false,
                ErrorsAPI: [],
            }

        case ADD_PRODUCTS_EXCEL:
            return {
                ...state,
                products: action.payload,
                success: true,
                ErrorsAPI: [],
            }

        case UPDATE_PRODUCTS_EXCEL:
            return {
                ...state,
                products: action.payload,
                success: true,
                ErrorsAPI: [],
            }
        case EDIT_PRODUCT:
            return {
                ...state,
                products: state.products.map(product => product.id === action.payload.id ?{...action.payload} : product),
                success: true,
                ErrorsAPI: [],
            }

        case DELETE_PRODUCT:

            return {
                ...state,
                products: state.products.filter(product => product.id !== action.payload)
            }

        default:
            return state;
    }
}