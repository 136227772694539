import React, { useState, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Tooltip,
  Button,
  TextField,
  IconButton,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MethodGet from "../../Config/Services";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useForm } from "react-hook-form";
import headerConfig from "../../Config/imageHeaders";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { MethodPost } from "../../Config/Services";
import Spinner from "../../Complements/Spinner";
/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
import GridItem from "../../Components/Grid/GridItem";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import SelectCategories from "../SelectOptions/SelectCategory";
import SelectSubCategory from "../SelectOptions/SelectSubCategory";
/**
 *
 */
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
  },
  typography: {
    paddingTop: theme.spacing(4),
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#5902CF",
    },
    color: "white",
  },
  ContainerSubmit: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

function ProductAdd(props) {
  const classes = useStyles();

  const { id } = props;

  const [branch, guardarBranch] = useState({});
  const [cargando, spinnerCargando] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [type_product, setTypeProduct] = useState(false);
  //Extraer valores de branch(destructuracion)
  const { name, direction, number_phone, state_id, municipality_id } = branch;

  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  const detectarCambiosTypeProduct = (e) => {
    setTypeProduct(2);
  };
  const [category, setCategory] = useState(null);

  const detectarCambiosCategory = (e) => {
    setCategory(e.target.value);
  };
  const [subcategory, setSubCategory] = useState(null);
  const detectarCambiosSubCategory = (e) => {
    setSubCategory(e.target.value);
  };

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();
    data.category = category;
    data.subcategory = subcategory;
    setDisabled(true);
    //crear un formdata
    const formData = new FormData();
    formData.append("tag", data.tag);
    formData.append("name", data.name);
    formData.append("price", data.price);
    formData.append("price_purchase", data.price_purchase);
    formData.append("description", data.description);
    formData.append("ubication", data.ubication);
    formData.append("quantity", data.quantity);
    formData.append("image", image.image);
    formData.append("type", 2);
    formData.append("id_branch_office", id);

    let url = "/products";
    MethodPost(url, formData, { headerConfig })
      .then((res) => {
        Swal.fire({
          title: "Producto Registrado Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });

        props.history.push("/SucursalProductos/" + id);
      })
      .catch((error) => {
        setDisabled(false);
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {/**
         * identificar Codigo de FORMS
         */}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 70 }}
        >
          {/**
           * identificar Codigo de FORMS
           */}
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>
                  {"Agregar Nuevo Producto A La Sucursal " + name}
                </h2>
                <p className={classes.cardCategoryWhite}>
                  Completa correctamente los datos
                </p>
              </CardHeader>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="tag"
                      label="Clave"
                      name="tag"
                      autoComplete="tag"
                      autoFocus
                      error={!!errors.tag}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La Clave es requerida",
                        },
                      })}
                    />
                    <p>{errors?.tag?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="description"
                      label="Descripción"
                      name="description"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.description}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La Descripción es requerido",
                        },
                        minLength: {
                          value: 1,
                          message: "Minimo 1 caracter",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.description?.message}</p>
                  </Grid>
                  {/* <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="ubication"
                      label="Ubicacion"
                      name="ubication"
                      autoComplete="ubication"
                      autoFocus
                      error={!!errors.ubication}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La ubicacion es requerido",
                        },
                      })}
                    />
                    <p>{errors?.ubication?.message}</p>
                  </Grid> */}
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="quantity"
                      label="Cantidad en stock"
                      name="quantity"
                      type="number"
                      autoFocus
                      error={!!errors.quantity}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La cantidad en stock es requerida",
                        },
                        maxLength: {
                          value: 5,
                          message: "Maximo 5 digitos",
                        },
                      })}
                    />
                    <p>{errors?.quantity?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="price_purchase"
                      label="Precio Costo"
                      name="price_purchase"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.price_purchase}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Precio Costo es requerido",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.price_purchase?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="price"
                      label="Precio Venta"
                      name="price"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.price}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Precio venta es requerido",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.price?.message}</p>
                  </Grid>

                  {/* <Grid item xs={12} sm={4} style={{ paddingTop: 16 }}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <SelectTypeProduct
                        detectarCambiosTypeProduct={detectarCambiosTypeProduct}
                      />
                    </FormControl>
                  </Grid> */}
                </Grid>
                <Card profile>
                  <CardAvatar profile>
                    <img
                      src={image.urlPhoto}
                      alt="User"
                      width="100%"
                      height="100%"
                    />
                  </CardAvatar>
                  <CardBody profile>
                    <input
                      className={classes.input}
                      id="icon-button-file"
                      type="file"
                      name="image"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleChangeImage}
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <Tooltip
                          title="seleccionar imagen"
                          aria-label="seleccionar imagen"
                        >
                          <PhotoCamera />
                        </Tooltip>
                      </IconButton>
                    </label>
                    <InputLabel>Selecciona Imagen del Producto</InputLabel>
                    <h2 className={classes.cardTitle}>Nuevo Producto: </h2>
                    <h3 className={classes.cardTitle}></h3>
                    <p className={classes.description}>
                      Recuerda que la cantidad de este producto puede ser
                      modificada desde otros modulos
                    </p>
                  </CardBody>
                </Card>
                <Grid container xs={12} xl={12} spacing={2} justify="center">
                  <Grid
                    item
                    spacing={3}
                    xs={12}
                    sm={12}
                    md={6}
                    className={classes.ContainerSubmit}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      disabled={disabled}
                      variant="outlined"
                      className={classes.submit}
                    >
                      GUARDAR
                    </Button>
                  </Grid>
                </Grid>
                {/**
                 * identificar Codigo de FORMS
                 */}
              </CardBody>
            </Card>
          </GridItem>
          {/**
           * identificar Codigo de FORMS
           */}
          {/**
           * identificar Codigo de FORMS
           */}
        </Grid>
        {/**
         * identificar Codigo de FORMS 123456789
         */}
      </form>
    </LayoutDashboard>
  );
}
export default withRouter(ProductAdd);
