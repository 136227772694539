import React,{useEffect,useState,Fragment} from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';


import { useForm } from "react-hook-form";


const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(26),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: 'none',
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperDetalle: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  paperTitle: {
    background: 'teal'

  },
  formControl: {
		margin: theme.spacing(1),
		width:'100%'
	},
}));


export default function DetailsAplication(props) {
  const { id } = props.match.params;

  const classes = useStyles();
  
  const [ ventaCliente, guardarVentaCliente ] = useState({});
  const [ ventaProductos, guardarVentaProductos ] = useState([]);
  const [ ventaAlmacen, guardarVentaAlmacen ] = useState({});
  //Trabajar con el Spinner
  const [cargando, spinnerCargando] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 



  return (
    <LayoutDashboard>
      <div className={classes.root}>
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container className={classes.container}>
            <Typography component="h1" variant="h4" align="center" className={classes.paperTitle} style={{ color: 'white' }}>
              Detalle de Aplicación
            </Typography>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Clave</TableCell>
                    <TableCell align="center">Manicurista</TableCell>
                    <TableCell align="center">Hora Inicio</TableCell>
                    <TableCell align="center">Hora Final</TableCell>
                    <TableCell align="center">Descripción</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                
                  <TableRow >
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                  </TableRow>
               
             </TableBody>
              </Table>
            </TableContainer>
          </Container>

          <Container className={classes.container}>
            <Typography component="h1" variant="h4" align="center" className={classes.paperTitle} style={{ color: 'white' }}>
              Detalle de Venta
            </Typography>
            
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Folio Venta</TableCell>
                    <TableCell align="center">Sucursal</TableCell>
                    <TableCell align="center">Nombre</TableCell>
                    <TableCell align="center">Teléfono</TableCell>
                    <TableCell align="center">Fecha</TableCell>
                    <TableCell align="center">Total</TableCell>
                    <TableCell align="center">Método de Pago</TableCell>
                    <TableCell align="center">Referencia</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                
                  <TableRow >
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                    <TableCell align="center">--</TableCell>
                  </TableRow>
               
             </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </main>
      </div >
     
    </LayoutDashboard>
  );
}