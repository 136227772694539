import React, { useState, useEffect, useContext, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import AuthContext from "../../Context/autenticacion/authContext";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import { green } from "@material-ui/core/colors";
import {
  Button,
  Tooltip,
  Hidden,
  Grid,
  Box,
  Fab,
  Typography,
  Container,
} from "@material-ui/core";
/**Importar metodo Get */
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import DeparturesContext from "../../Reducers/Departures/DeparturesContext";
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  whiteSpace: "nowrap",

  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
    marginRight: "-2%",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  customWidth: {
    fontSize: "12px",
    maxWidth: 200,
    justifyContent: "center",
    padding: "5px",
  },
}));

const ViewPendingBranchDepartures = (props) => {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);
  const { id } = props.match.params;
  let iddetalle = props.match.params;

  const authContext = useContext(AuthContext);
  const { usuario, usuarioAutenticado } = authContext;
  const { errors, handleSubmit } = useForm();

  const ClassroomsList = useContext(DeparturesContext);
  const { departures,DeleteProductTransfer, ShowPendingDeparture, EditPendingDeparture, success } =
    ClassroomsList;
  const [error, guardarError] = useState(false);
  useEffect(() => {
    usuarioAutenticado();
    ShowPendingDeparture(id);
    spinnerCargando(true);
  }, [success]);
  //obtener los campos de la sucursal
  const onSubmit1 = (data, e) => {
    e.preventDefault(); // Evita el envío del formulario
    // Muestra el SweetAlert
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡Estás a punto de aceptar el traspaso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, aceptar!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Lógica para aceptar el traspaso
        data.id = id;
        data.authorized_person = usuario.name;
        data.status = 1;
        EditPendingDeparture(data);
        Swal.fire("¡Aceptado!", "El traspaso ha sido aceptado.", "success");
      }
    });
  };
  const onSubmit2 = (data, e) => {
    e.preventDefault(); // Evita el envío del formulario
    // Muestra el SweetAlert
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡Estás a punto de negar el traspaso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, negar!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Lógica para negar el traspaso
        data.id = id;
        data.authorized_person = usuario.name;
        data.status = 2;
        EditPendingDeparture(data);
        Swal.fire("Negado", "El traspaso ha sido negado.", "success");
      }
    });
  };
  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de escuelas
    if (success) {
      props.history.push("/Traspaso");
    }
    //eslint-disable-next-line
  }, [success]);

  const EliminarProduct = (id) => {


    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Eliminar el producto de este traspaso?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        const data = {
          id_product: id,
          departure_id: iddetalle.id,
        };
        //Cunsume API de Back-end en laravel para generar tiket de venta
        DeleteProductTransfer(data)
      }
    });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* titulo */}
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 90,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
            marginBottom: 10,
          }}
        >
          <Typography
            style={{ marginLeft: 20, marginTop: 10 }}
            className={classes.typography}
            component="h1"
            variant="h4"
          >
            {"Detalles del Traspaso "}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={6} style={{ padding: 5 }}>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit1)}
          >
            <Button
              type="submit"
              variant="outlined"
              startIcon={<DoneIcon />}
              fullWidth
              color="primary"
              style={{ margin: 2 }}
            >
              Aceptar Traspaso
            </Button>
          </form>
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 5 }}>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit2)}
          >
            <Button
              variant="outlined"
              type="submit"
              startIcon={<CloseIcon />}
              fullWidth
              color="secondary"
              style={{ margin: 2 }}
            >
              Negar Traspaso
            </Button>
          </form>
        </Grid>
      </Grid>

      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginRight: 30, marginLeft: 30, marginTop: 20 }}
      >
        <MaterialTable
          title=""
          columns={[
            { title: "Codigo P.", field: "tag" },
            { title: "Nombre P.", field: "name" },
            { title: "Cantidad Entrante.", field: "quantity" },
          ]}
          data={departures}
          options={{
            headerStyle: {
              backgroundColor: "none",
              color: "#000",
              fontSize: "16px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },

            actionsColumnIndex: -1,
          }}
          actions={[{}]}
          components={{
            Action: (props) => (
              <Fragment>
                <Button
                  style={{ textTransform: "none" }}
                  size="small"
                  onClick={() => EliminarProduct(props.data.id)}
                >
                  <Tooltip
                    title="Eliminar producto del traspaso"
                    aria-label="Eliminar producto del traspaso"
                  >
                    <DeleteIcon className={classes.iconOptionblue} />
                  </Tooltip>
                </Button>
              </Fragment>
            ),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsSelect: "Columnas",
              firstTooltip: "Primera Pagina",
              previousTooltip: "Anterior",
              nextTooltip: "Siguiente",
              lastTooltip: "Ultima Página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Opciones",
            },
            body: {
              emptyDataSourceMessage: "No Hay Productos que Mostrar",
              filterRow: {
                filterTooltip: "Buscar",
              },
            },
          }}
        />
      </Grid>
    </LayoutDashboard>
  );
};

export default ViewPendingBranchDepartures;
