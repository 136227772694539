import { ADD_NEW_SCHOOL_EXPENSE, CURRENT_SCHOOL_EXPENSE, DELETE_SCHOOL_EXPENSE, 
    GET_ALL_SCHOOL_EXPENSES, UPDATE_SCHOOL_EXPENSE } from "../../types";

export default function ExpensesReducer(state, action) {

switch (action.type) {
case GET_ALL_SCHOOL_EXPENSES: 
    return{
        ...state,
        expenses: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case ADD_NEW_SCHOOL_EXPENSE:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }

case CURRENT_SCHOOL_EXPENSE:
    return{
        ...state,
        expense:action.payload,
    }

case UPDATE_SCHOOL_EXPENSE:
    return{
        ...state,
        success: true,
        ErrorsAPI: []
    }

case DELETE_SCHOOL_EXPENSE:
    return{
        ...state,
        expenses: state.expenses.filter(expense => expense.id !== action.payload)
    }

default:
    return state; 
}
}