import React, { useState, useRef } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useForm } from "react-hook-form";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
import headerConfig from "../../Config/imageHeaders";

import Swal from "sweetalert2";

import { MethodPost } from "../../Config/Services";

import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import SelectTypeUser from "../SelectOptions/SelectTypeUser";
/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
/**
 * identificar Codigo de FORMS
 */
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    //margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

const type = [
  { title: "Administrador" },
  { title: "Vendedor" },
  { title: "SubAdministrador" },
];

function UserAdd(props) {
  const classes = useStyles();

  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const [nameOffice, guardarSucursal] = useState({
    id_branch_office: "",
  });

  const [nameTypeUsers, guardarType] = useState({
    type_user: "",
  });

  const detectarCambiosOffice = (e) => {
    guardarSucursal({ id_branch_office: e.target.value });
  };

  const detectarCambiosTypeUser = (e) => {
    guardarType({ type_user: e.target.value });
  };

  //Funcion para validaciones
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = async (data, e) => {
    e.preventDefault();

    if (nameTypeUsers.type_user !== "") {
      //crear un formdata
      const formData = new FormData();
      if (nameTypeUsers.type_user != 1) {
        formData.append("name", data.name);
        if (image != undefined) {
          formData.append("image", image.image);
        }
        formData.append("email", data.email);
        formData.append("number_phone", data.number_phone);
        formData.append("salary", data.salary);
        formData.append("password", data.password);
        formData.append("c_password", data.c_password);
        formData.append("comission_percentage", data.comission_percentage);
        formData.append("type", nameTypeUsers.type_user);
        formData.append("id_branch_office", nameOffice.id_branch_office);
      } else {
        formData.append("name", data.name);
        if (image != undefined) {
          formData.append("image", image.image);
        }
        formData.append("email", data.email);
        formData.append("number_phone", data.number_phone);
        formData.append("password", data.password);
        formData.append("c_password", data.c_password);
        formData.append("type", nameTypeUsers.type_user);
      }

      let url = "/users";
      MethodPost(url, formData, { headerConfig })
        .then((res) => {
          Swal.fire({
            title: "Usuario Registrado Exitosamente",
            text: res.data.message,
            icon: "success",
            timer: 2000,
            showConfirmButton: false,
          });

          props.history.push("/Usuarios");
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.error,
            icon: "error",
          });
        });
    } else {
      Swal.fire({
        title: "Error",
        text: "Todos los campos son Obligatorios",
        icon: "error",
      });
    }
  };

  return (
    <LayoutDashboard>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 70 }}
        >
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>
                  {"Agregar Nuevo Usuario"}
                </h2>
                <p className={classes.cardCategoryWhite}>
                  Completa correctamente los datos
                </p>
              </CardHeader>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} style={{ marginTop: 18 }}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <SelectTypeUser
                        detectarCambiosTypeUser={detectarCambiosTypeUser}
                      />
                    </FormControl>
                  </Grid>
                  {nameTypeUsers.type_user != 1 && (
                    <Grid item xs={12} sm={4} style={{ marginTop: 18 }}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <SelectBranchOffice
                          detectarCambiosOffice={detectarCambiosOffice}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre Completo"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 4,
                          message: "Minimo 4 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                        pattern: {
                          value:
                            /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                          message: "Unicamente carácteres alfabéticos",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="number_phone"
                      label="Teléfono"
                      name="number_phone"
                      autoComplete="phone"
                      autoFocus
                      type="number"
                      error={!!errors.number_phone}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Teléfono es requerido",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 digitos",
                        },
                      })}
                    />
                    <p>{errors?.number_phone?.message}</p>
                  </Grid>

                  {nameTypeUsers.type_user != 1 && (
                    <>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="salary"
                          label="Sueldo"
                          name="salary"
                          autoComplete="salary"
                          autoFocus
                          type="number"
                          error={!!errors.salary}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El Salario es requerido",
                            },
                          })}
                        />
                        <p>{errors?.salary?.message}</p>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="comission_percentage"
                          label="Porcentaje De Comisión"
                          name="comission_percentage"
                          autoComplete="comission_percentage"
                          autoFocus
                          type="number"
                          error={!!errors.comission_percentage}
                          inputRef={register({
                            required: {
                              value: true,
                              message: "El porcentaje de comisión es requerido",
                            },
                            maxLength: {
                              value: 5,
                              message: "Máximo 5 digitos",
                            },
                            minLength: {
                              value: 1,
                              message: "Minimo 1 Digito",
                            },
                          })}
                        />
                        <p>{errors?.comission_percentage?.message}</p>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Correo Electronico"
                      name="email"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.email}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El correo es requerido",
                        },
                        type: "email",
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    />
                    <p>{errors?.email?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="password"
                      label="Contraseña"
                      name="password"
                      type="password"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.password}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "La contraseña es requerida",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimo 8 caracteres",
                        },
                        maxLength: {
                          value: 16,
                          message: "Maximo 16 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.password?.message}</p>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="c_password"
                      label="Confirmar Contraseña"
                      name="c_password"
                      type="password"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.c_password}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Confirmar Contraseña es requerida",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimo 8 caracteres",
                        },
                        maxLength: {
                          value: 16,
                          message: "Maximo 16 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.c_password?.message}</p>
                  </Grid>
                </Grid>
                <Card profile>
                  <CardAvatar profile>
                    <img
                      src={image.urlPhoto}
                      alt="User"
                      width="100%"
                      height="100%"
                    />
                  </CardAvatar>
                  <CardBody profile>
                    <input
                      className={classes.input}
                      id="icon-button-file"
                      type="file"
                      name="image"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleChangeImage}
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <Tooltip
                          title="seleccionar imagen"
                          aria-label="seleccionar imagen"
                        >
                          <PhotoCamera />
                        </Tooltip>
                      </IconButton>
                    </label>
                    <InputLabel>Selecciona Foto de Perfil</InputLabel>
                    <h2 className={classes.cardTitle}>
                      Nuevo Perfil de Usuario:{" "}
                    </h2>
                    <h3 className={classes.cardTitle}></h3>
                    <p className={classes.description}>
                      Recuerda que los datos registrados seran los que tu
                      usuario vera en su perfil, recuerda que que los usuarios
                      que registres en este apartado tendran acceso al sistema,
                      los datos podran ser modificados en un futuro.
                    </p>
                  </CardBody>
                </Card>
                <Grid container spacing={3} justify="center">
                  <Grid item spacing={3} xs={12} sm={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.submit}
                    >
                      GUARDAR
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
          {/**
           * identificar Codigo de FORMS
           */}
          {/**
           * identificar Codigo de FORMS
           */}
        </Grid>
      </form>
    </LayoutDashboard>
  );
}

export default withRouter(UserAdd);
