import React , { useReducer } from 'react';
import CouponsContext from './CouponsContext';
import CouponsReducer from './CouponsReducer';
import MethodGet , { MethodDelete, MethodPost , MethodPut} from '../../Config/Services';
import { GET_ALL_COUPONS ,  ADD_NEW_COUPON , CURRENT_COUPON ,
    UPDATE_COUPON ,  DELETE_COUPON, APPLY_COUPON } from '../../types/index';/**Importar Sweetalert2 * */
import Swal from "sweetalert2";

const CouponsState = props => {

    const initialState = {
        coupons: [],
        discountRate: 0,
        coupon: {},
        applyCoupon: false,
        success:false,
        date:null
    }

    //dispatch para ejecutar las funciones
    const [ state , dispatch ] = useReducer( CouponsReducer , initialState);

    //funcion para obtener los cupones de descuento
    const getCoupons =  () => {
       let url = `/coupons`;
       MethodGet(url).then(res=> {
           dispatch({
               type:GET_ALL_COUPONS,
               payload: res.data.data
           });

       });

    }

    //función para crear nuevos cupones de descuento
    const addCoupons = coupon => {
        let url = '/coupons';
        MethodPost(url , coupon).then(res=>{
            Swal.fire({
                title: 'Buen trabajo',
                text:  res.data.message,
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            });
            dispatch({
                type: ADD_NEW_COUPON,
            });
        }).catch(error=>{
            Swal.fire({
                title: 'Error',
                text: error.response.data.error,
                icon: 'error'
            });
        });
    }

    //función para seleccionar un proyecto a editar
    const saveCurrentCoupon = coupon => {
        dispatch({
            type:CURRENT_COUPON,
            payload: coupon
        })
    }

    //función para editar un cupon de descuento
    const updateCoupon = coupon =>{
        let url = `coupons/${coupon.id}`;
        MethodPut(url , coupon ).then(response=>{
            Swal.fire({
                title: 'Buen trabajo',
                text:  response.data.message,
                icon: 'success',
                timer: 3000,
                showConfirmButton: false
            });
            dispatch({
                type: UPDATE_COUPON,
            });
        }).catch(error=>{
            Swal.fire({
                title: 'Error',
                text: error.response.data.error,
                icon: 'error'
            });
        });

    }

    //funcion para eliminar un cupón de descuento
    const deleteCoupon = id =>{
        Swal.fire({
            title: '¿Estas seguro?',
            text: 'Un Cupon Eliminado no se puede Recuperar',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'No, cancelar'
          }).then((result) => {
            if (result.value) {
              ///Eliminar en la Api
              let url = `coupons/${id}`
              MethodDelete(url).then(res => {
                    Swal.fire(
                      'Eliminado',
                      res.data.message,
                      'success'
                    );
                    dispatch({
                        type: DELETE_COUPON,
                        payload: id
                     }); 
                });
            }
          });
    }


    return (
        <CouponsContext.Provider
          value={{
            coupons: state.coupons,
            success: state.success,
            date: state.date,
            coupon: state.coupon,
            getCoupons,
            addCoupons,
            saveCurrentCoupon,
            updateCoupon,
            deleteCoupon
          }}>
              {props.children}

        </CouponsContext.Provider>
    )

}

export default CouponsState;

