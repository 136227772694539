import React, { useState, useEffect, Fragment } from 'react';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function SelectStatusProductStock(props) {
	const [error, guardarError] = useState(true);
	const [stados, saveStatu] = useState([]);
	const estados = [
		{ statusId: '1', name: 'Disponible' },
		{ statusId: '2', name: 'Pocas Piezas' },
		{ statusId: '3', name: 'Agotado' },
	];

	useEffect(() => {
		saveStatu(estados);
		if (props.statusStockId) {
			guardarError(false);
		}
	}, [error]);
	const detectarCambioStatusProductStock = (e) => {
		if (e.target.value) {
			guardarError(false);
		}
		props.detectarCambioStatusProductStock(e);
	};

	return (
		<Fragment>
			<InputLabel fullWidth>Status*</InputLabel>
			{props.statusStockId ? (
				<Select
					label="Status"
					fullWidth
					onChange={detectarCambioStatusProductStock}
					value={props.statusStockId.status}
				>
					<MenuItem disabled>Selecciona el Status</MenuItem>
					{stados.map((stado) => (
						<MenuItem key={stado.statusId} value={stado.statusId}>
							{stado.name}
						</MenuItem>
					))}
				</Select>
			) : (
				<Select label="Status" fullWidth onChange={detectarCambioStatusProductStock}>
					<MenuItem disabled>Selecciona el Status</MenuItem>
					{stados.map((stado) => (
						<MenuItem key={stado.statusId} value={stado.statusId}>
							{stado.name}
						</MenuItem>
					))}
				</Select>
			)}

			{error ? (
				<p style={{ color: 'red' }}>El Status es un Campo Requerido</p>
			) : null}
		</Fragment>
	);
}
