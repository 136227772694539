import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import MethodGet from "../../Config/Services";
import { useState } from "react";
import clienteAxios from "../../Config/Axios";
const SalesGraphicDays = (props) => {
  const { id } = props.match.params;
  const [salesWeek, SaveSalesWeek] = useState([]);
  useEffect(() => {
    let url = `/SalesWeek/${id}`;
    MethodGet(url)
      .then((res) => {
        SaveSalesWeek(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  const [sale, setSale] = useState({
    labels: salesWeek.map((s) => s.fecha),
    datasets: [
      {
        label: "Cantidad Vendida",
        data: salesWeek.map((s) => s.monto),
        backgroundColor: ["#ffcdd2"],
      },
    ],
  });

  useEffect(() => {
    setSale({
      labels: salesWeek.map((p) => p.fecha),
      datasets: [
        {
          label: "Efectivo",
          data: salesWeek.map((p) => p.monto_efectivo - p.cambio_efectivo),
          backgroundColor: [
            "#9f72e8",
            "#9f72e8",
            "#9f72e8",
            "#9f72e8",
            "#9f72e8",
            "#9f72e8",
            "#9f72e8",
          ],
        },
        {
          label: "Tarjeta",
          data: salesWeek.map((p) => p.monto_transferencia),
          backgroundColor: [
            "#C57CCE",
            "#C57CCE",
            "#C57CCE",
            "#C57CCE",
            "#C57CCE",
            "#C57CCE",
            "#C57CCE",
          ],
        },
        {
          label: "Transferencia",
          data: salesWeek.map((p) => p.monto_tarjeta),
          backgroundColor: [
            "#EE6A9B",
            "#EE6A9B",
            "#EE6A9B",
            "#EE6A9B",
            "#EE6A9B",
            "#EE6A9B",
            "#EE6A9B",
          ],
        },
        {
          label: "Total",
          data: salesWeek.map((p) => p.monto_total - p.cambio_efectivo),
          backgroundColor: [
            "#F3927E",
            "#F3927E",
            "#F3927E",
            "#F3927E",
            "#F3927E",
            "#F3927E",
            "#F3927E",
          ],
        },
      ],
    });
  }, [salesWeek]);
  const [gbranch, saveBranch] = useState([]);
  const [error, guardarError] = useState(true);
  useEffect(() => {
    const getotals = async () => {
      await clienteAxios
        .get(`schools/${id}`)
        .then((res) => {
          saveBranch(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      if (props.course) {
        guardarError(false);
      }
    };
    getotals();
  }, []);
  return (
    <LayoutDashboard>
      <center>
        <Button
          variant="contained"
          style={{
            color: "white",
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            marginTop: 90,
            width: "98%",
            borderRadius: 10,
          }}
        >
          Ventas por día de Sucursal {gbranch.name}
        </Button>
      </center>
      {/* Gráfica */}
      <Grid>
        <Bar
          data={sale}
          height={"400%"}
          options={{
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }}
        />
      </Grid>
    </LayoutDashboard>
  );
};

export default SalesGraphicDays;
