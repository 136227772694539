import React , {Fragment, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  contentWrapper:{
     display:'flex',
     position:'relative',
     alignItems:'center',
     color:'#fff'
  },
  contentLeft:{
     display:'block'
 },
  contentRigth:{
      marginLeft:'auto'
  },
  widgetHeading:{
      opacity:'.',
      fontWeight:'bold'
  },
  widgetSubheading:{
      opacity:'.6'
  },
  widgetNumber:{
      fontWeight:'bold',
      fontSize:'1.9rem',
      color: '#fff !important;'
  },
}));

const BoxTotalSale = ({comissions, lastComissionStartHour, lastComissionStartDate}) => {
    const classes = useStyles();

    const comisionSinCobrar =  new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2
    }).format(comissions);
    //console.log('comision', comissions)

    return (
        <Fragment>
            <div className={classes.contentWrapper}>
                <div className={classes.contentLeft}>
                  <div className={classes.widgetHeading}>
                    Total
                  </div>
                  <div className={classes.widgetSubheading}>
                    Comisiones totales sin cobrar a partir del {lastComissionStartDate} - {lastComissionStartHour}
                  </div>
                </div>
                <div className={classes.contentRigth}>
                  <div className={classes.widgetNumber}> 
                    <span>{comisionSinCobrar}</span>
                  </div> 
                </div>
            </div>
        </Fragment>
        
    );
};

export default BoxTotalSale;