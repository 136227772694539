import React, { useState, useEffect, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import {
  Button,
  Tooltip,
  Box,
  Fab,
  Typography,
  Grid,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { green } from "@material-ui/core/colors";
import ModalAddReactivate from "../Branch_Offices/ModalAddReactivate";
import ModalRemove from "../Branch_Offices/ModalRemove";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Icon from "@material-ui/icons/PictureAsPdf";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import FormControl from "@material-ui/core/FormControl";
import SelectBranchOffice from "../SelectOptions/SelectBranchOffice";
import DateFnsUtils from "@date-io/date-fns";
import PrintIcon from "@material-ui/icons/Print";

/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  Link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  Table: {
    background: "Orange",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  reportmodal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  reportpaper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    width: "80%",
    height: "60%",
    padding: theme.spacing(2, 1, 1, 3),
  },
}));

const OfficeProductsDescAdmin = (props) => {
  const classes = useStyles();

  //sucursales = state, saveStocks =funcion para guardar en el state;
  const [productsdesact, saveProductsDesc] = useState([]);
  const id = props.id; //Se toma el ID de la Sucursal para mostrar sus stocks
  const [cargando, spinnerCargando] = useState(false);

  //abrirModal
  const [modalAdd, abrirModalAdd] = useState(false);
  const [modalRemove, abrirModalRemove] = useState(false);
  const [stockid, editarStockId] = useState("");

  const [branch, guardarBranch] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name } = branch;

  const handleClickOpenRemove = (id) => {
    editarStockId(id);
    abrirModalRemove(true);
  };
  const [id_product, saveIdProduct] = useState(null)
  const handleClickOpenAdd = (id) => {
    editarStockId(id);
    saveIdProduct(id);
    abrirModalAdd(true);
  };

  const handleCloseRemove = () => {
    abrirModalRemove(false);
  };

  const handleCloseAdd = () => {
    abrirModalAdd(false);
  };

  const [actualizar, actualizarStock] = useState(false);

  const actualizarCambios = () => {
    editarStockId("");
    actualizarStock(true);
  };

  //query a la api
  useEffect(() => {
    let url = `/productsdeleted/${id}`;
    MethodGet(url)
      .then((res) => {
        saveProductsDesc(res.data.data);
        spinnerCargando(true);
        actualizarStock(false);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, [actualizar]);

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  //  spinner de carga
  if (!cargando) return <Spinner />;
  return (
    <>
      {/* titulo */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 10,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            className={classes.typography}
            component="h1"
            variant="h4"
          >
            {"Productos Desactivados de la sucursal " + name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        ></Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
      >
        <MaterialTable
          title=""
          columns={[
            { title: "Clave", field: "tag" },
            { title: "Producto", field: "name" },
          ]}
          data={productsdesact}
          options={{
            headerStyle: {
              backgroundColor: "none",
              color: "#000",
              fontSize: "16px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },

            actionsColumnIndex: -1,
          }}
          actions={[
            (rowData) => ({
              icon: "add",
              tooltip: "Agregar Existencias",
              onClick: () => handleClickOpenAdd(rowData.id),
            }),
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsSelect: "Columnas",
              firstTooltip: "Primera Pagina",
              previousTooltip: "Anterior",
              nextTooltip: "Siguiente",
              lastTooltip: "Ultima Página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Opciones",
            },
            body: {
              emptyDataSourceMessage: "No Hay Stock de productos que Mostrar",
              filterRow: {
                filterTooltip: "Buscar",
              },
            },
          }}
        />
      </Grid>
        {id_product !== null && id_product && (

          <ModalAddReactivate
          modal={modalAdd}
          handleClickOpen={handleClickOpenAdd}
          handleClose={handleCloseAdd}
          stockid={stockid}
          id_product={id_product}
          actualizarCambios={actualizarCambios}
          />
          )}
    </>
  );
};

export default OfficeProductsDescAdmin;
