import React, {useState, useEffect,Fragment} from 'react';
/**Importar configuracion axios */ 
import MethodGet from '../../Config/Services';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';


function SelectMunicipality(props){
    const {state_id} = props.estadoId;
    const [municipalities, saveMunicipalities] = useState([]);
    const [error, guardarError] =  useState(true);
    useEffect(() => {
          if(!props.estadoId.state_id){
              saveMunicipalities([]);
              return;
          }
            let url = `/municipalities/${state_id}`; 
            MethodGet(url)
             .then(res=>{
               saveMunicipalities(res.data.data)
             }).catch(error=>{
               console.log(error)
             });

             if(props.municipality){
               guardarError(false);
             }
       
    }, [state_id]);

    const detectarCambiosMunicipios = (e) => {
      if(e.target.value){
        guardarError(false)
      }
        props.detectarCambiosMunicipios(e)
    }

    return(
        <Fragment>
        <InputLabel  fullWidth>Municipios*</InputLabel>
        {
          props.municipality ? (
           
            <Select
            onChange={detectarCambiosMunicipios}
            label="municipios"
            fullWidth
            value={props.municipality.municipality_id}
          >
          <MenuItem disabled>Selecciona un municipio de la lista</MenuItem>
          {
            municipalities.map(municipio=><MenuItem key={municipio.id} value={municipio.id}>{municipio.name}</MenuItem>)
          } 
          </Select>
            
          ):(

        <Select
                  onChange={detectarCambiosMunicipios}
                  label="municipios"
                  fullWidth
                >
                <MenuItem disabled>Selecciona un municipio de la lista</MenuItem>
                {
                  municipalities.map(municipio=><MenuItem key={municipio.id} value={municipio.id}>{municipio.name}</MenuItem>)
                } 
          </Select>

          )
        }
        
        {
           error ? (
               <p style={{color:'red'}}>El Municipio es un campo requerido</p>
            ) : null
        }
        </Fragment>
    )



}

export default SelectMunicipality;