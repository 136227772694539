import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import SchoolContext from "../../Reducers/Schools/SchoolContext";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import MethodGet from "../../Config/Services";
import { useState } from "react";
import clienteAxios from "../../Config/Axios";
export default function SalesGraphic(props) {
  const { id } = props.match.params;
  const { utility, GetBranchUtility, GetSchools } = useContext(SchoolContext);
  useEffect(() => {
    GetBranchUtility(id);
    GetSchools(id);
  }, [id]);
  const [salesWeek, SaveSalesWeek] = useState([]);
  useEffect(() => {
    let url = `/SalesWeek/${id}`;
    MethodGet(url)
      .then((res) => {
        SaveSalesWeek(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);
  const [sale, setSale] = useState({
    labels: salesWeek.map((s) => s.fecha),
    datasets: [
      {
        label: "Cantidad Vendida",
        data: salesWeek.map((s) => s.monto),
        backgroundColor: ["#ffcdd2"],
      },
    ],
  });
  const [gbranch, saveBranch] = useState([]);
  const [error, guardarError] = useState(true);
  useEffect(() => {
    const getotals = async () => {
      await clienteAxios
        .get(`schools/${id}`)
        .then((res) => {
          saveBranch(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      if (props.course) {
        guardarError(false);
      }
    };
    getotals();
  }, []);

  useEffect(() => {
    setSale({
      labels: salesWeek.map((p) => p.fecha),
      datasets: [
        {
          label: "Efectivo",
          data: salesWeek.map((p) => p.monto_efectivo - p.cambio_efectivo),
          backgroundColor: [
            "#9f72e8",
            "#9f72e8",
            "#9f72e8",
            "#9f72e8",
            "#9f72e8",
            "#9f72e8",
            "#9f72e8",
          ],
        },
        {
          label: "Tarjeta",
          data: salesWeek.map((p) => p.monto_transferencia),
          backgroundColor: [
            "#C57CCE",
            "#C57CCE",
            "#C57CCE",
            "#C57CCE",
            "#C57CCE",
            "#C57CCE",
            "#C57CCE",
          ],
        },
        {
          label: "Transferencia",
          data: salesWeek.map((p) => p.monto_tarjeta),
          backgroundColor: [
            "#EE6A9B",
            "#EE6A9B",
            "#EE6A9B",
            "#EE6A9B",
            "#EE6A9B",
            "#EE6A9B",
            "#EE6A9B",
          ],
        },
        {
          label: "Total",
          data: salesWeek.map((p) => p.monto_total - p.cambio_efectivo),
          backgroundColor: [
            "#F3927E",
            "#F3927E",
            "#F3927E",
            "#F3927E",
            "#F3927E",
            "#F3927E",
            "#F3927E",
          ],
        },
      ],
    });
  }, [salesWeek]);
  return (
    <LayoutDashboard>
      <center>
        <Button
          variant="contained"
          style={{
            color: "white",
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            marginTop: 90,
            width: "98%",
            borderRadius: 10,
          }}
        >
          Ventas de hoy de Sucursal {gbranch.name}
        </Button>
      </center>
      <Grid container>
        <Grid item xs={12} md={3} style={{ padding: 10 }}>
          <Box style={{ backgroundColor: "#9f72e8" }} borderRadius={15}>
            <Typography
              align="center"
              variant="h5"
              style={{ color: "white", fontWeight: "bold", marginBottom: 10 }}
            >
              Efectivo
            </Typography>
            <Grid container style={{ marginBottom: 15 }}>
              <Grid md={12} xs={12}>
                <Typography
                  align="center"
                  variant="h3"
                  style={{ color: "white", marginTop: 10, marginBottom: 10 }}
                >
                  {Object.keys(utility).length != 0 ? (
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(utility.total_money_cash_today)
                  ) : (
                    <LinearProgress />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* separacion */}
        <Grid item xs={12} md={3} style={{ padding: 10 }}>
          <Box style={{ backgroundColor: "#C57CCE" }} borderRadius={15}>
            <Typography
              align="center"
              variant="h5"
              style={{ color: "white", fontWeight: "bold", marginBottom: 10 }}
            >
              Transferencia
            </Typography>
            <Grid container style={{ marginBottom: 15 }}>
              <Grid md={12} xs={12}>
                <Typography
                  align="center"
                  variant="h3"
                  style={{ color: "white", marginTop: 10, marginBottom: 10 }}
                >
                  {Object.keys(utility).length != 0 ? (
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(utility.total_money_transfer_today)
                  ) : (
                    <LinearProgress />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} style={{ padding: 10 }}>
          <Box style={{ backgroundColor: "#EE6A9B" }} borderRadius={15}>
            <Typography
              align="center"
              variant="h5"
              style={{ color: "white", fontWeight: "bold", marginBottom: 10 }}
            >
              Tarjeta
            </Typography>
            <Grid container style={{ marginBottom: 15 }}>
              <Grid md={12} xs={12}>
                <Typography
                  align="center"
                  variant="h3"
                  style={{ color: "white", marginTop: 10, marginBottom: 10 }}
                >
                  {Object.keys(utility).length != 0 ? (
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(utility.total_money_card_today)
                  ) : (
                    <LinearProgress />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} style={{ padding: 10 }}>
          <Box style={{ backgroundColor: "#F3927E" }} borderRadius={15}>
            <Typography
              align="center"
              variant="h5"
              style={{ color: "white", fontWeight: "bold", marginBottom: 10 }}
            >
              Total
            </Typography>
            <Grid container style={{ marginBottom: 15 }}>
              <Grid md={12} xs={12}>
                <Typography
                  align="center"
                  variant="h3"
                  style={{ color: "white", marginTop: 10, marginBottom: 10 }}
                >
                  {Object.keys(utility).length != 0 ? (
                    new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 0,
                    }).format(utility.total_money_today)
                  ) : (
                    <LinearProgress />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <center>
        <Button
          variant="contained"
          style={{
            color: "white",
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            width: "98%",
            marginTop: -15,
            borderRadius: 10,
          }}
        >
          Ventas de la semana de Sucursal {gbranch.name}
        </Button>
      </center>

      {/* Gráfica */}
      <Grid>
        <Bar
          data={sale}
          height={"350%"}
          options={{
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }}
        />
      </Grid>
    </LayoutDashboard>
  );
}
