import React, { Fragment, useState, useEffect } from "react";
import "./Product.css";

export default function Product({
  producto,
  AgregarProductosState,
}) {
  const { name, price, quantity } = producto;

  const [cargando, spinnerCargando] = useState(false);

  useEffect(() => {
    if (Object.keys(producto).length !== 0) {
      spinnerCargando(true);
      setTimeout(() => {
        spinnerCargando(false);
      }, 3000);
    }
  }, [producto]);

  const priceProduct = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(price);

  return (
    <Fragment>
      {quantity ? (
        <>
          <button className="boton-agregar" onClick={AgregarProductosState}>
            Agregar
          </button>
        </>
      ) : null}
    </Fragment>
  );
}
