import React, { useState, useEffect } from "react";
import MethodGet from "../../Config/Services";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import DoneIcon from "@material-ui/icons/Done";
import { useDebounce } from "use-debounce";
import InputAdornment from "@material-ui/core/InputAdornment";
import Swal from "sweetalert2";
import CropFreeIcon from "@material-ui/icons/CropFree";

const SelectsComponent = ({
  productoID,
  ventas,
  guardarVenta,
  id,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [barcode, setBarCode] = useState("");
  const [debouncedBarcode] = useDebounce(barcode, 500);
  const [products, guardarProducto] = useState({});

  const handleChangeCodeBar = (event) => {
    setBarCode(event.target.value);
  };

  useEffect(() => {
    filterProductExist();
  }, [debouncedBarcode, productoID]);

  let url = `/productsBranch/${id}`;
  useEffect(() => {
      MethodGet(url)
        .then((response) => {
          guardarProducto(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [debouncedBarcode]);
  const filterProductExist = () => {
    if (debouncedBarcode != "" ) {
      var product = [];
      if (debouncedBarcode == "") {
        return;
      }
      product = products.filter((p) => p.tag == debouncedBarcode);
      if (!product.length) {
        Swal.fire({
          title: "¡Lo sentimos!",
          text: "El Código de barras no coincide con ningún producto de la lista",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        setBarCode("");
        return;
      }
      product[0].value = product[0].id;
      const position = ventas
      .map(function (e) {
        return e.product_id;
      })
      .indexOf(product[0].id);
      let productoResultado = {};
      productoResultado.description = product[0].description;
      productoResultado.image = product[0].image;
      productoResultado.name = product[0].name;
      productoResultado.price = product[0].price;
      productoResultado.price_purchase = product[0].price_purchase;
      productoResultado.quantity = product[0].quantity;
      productoResultado.cantidad = 1;
      productoResultado.tag = product[0].tag;
      productoResultado.value = product[0].value;
      productoResultado.type = product[0].type;
      productoResultado.final_price = Number(productoResultado.cantidad) * Number(productoResultado.price) 
      if(productoResultado.quantity === 0){
        Swal.fire({
          icon: "error",
          title: `¡Lo sentimos!`,
          text: "El producto no cuenta con stock",
          timer:2000,
          showConfirmButton:false
        });
        setBarCode("");
        return;
      }
      if(position === -1){
        productoResultado.product_id = product[0].id;
        productoResultado.cantidad =1;
        guardarVenta([productoResultado, ...ventas]);
        setBarCode("");
        Swal.fire({
          icon: "success",
          title: `El producto se agregó correctamente `,
          timer: 1200,
          showConfirmButton:false
        });
      }else{
        let quantityProduct = ventas[position].cantidad;
        var new_quantity_product = (quantityProduct += 1);
        if (product[0].quantity < new_quantity_product) {
          Swal.fire({
            icon: "error",
            title: `¡Lo sentimos!`,
            text: "No queda más productos en stock",
            timer: 2000,
            showConfirmButton:false
          });
          setBarCode("");
          return;
        }
        ventas[position].cantidad = new_quantity_product;
        ventas[position].final_price = ventas[position].cantidad * ventas[position].price 
        guardarVenta([...ventas]);
        setBarCode("");
        toast.success(
          <div>
            <DoneIcon />
            El producto se agregó correctamente
          </div>,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

      setBarCode("");
    }
  };

  // if (!cargando) return <Spinner />;

  return (
    <>
      <TextField
        placeholder="Usa el lector de codigos de barra"
        variant="outlined"
        required
        id="barcode"
        label="Código De Barras"
        name="barcode"
        value={barcode}
        onChange={handleChangeCodeBar}
        autoComplete="barcode"
        autoFocus
        fullWidth
        error={!!errors.barcode}
        margin="dense"
        style={{marginTop:"-2px"}}
        inputRef={register({
          required: {
            value: true,
            message: "El código de barras es requerido",
          },
          minLength: {
            value: 4,
            message: "Minimo 4 caracteres",
          },
          maxLength: {
            value: 45,
            message: "Maximo 45 caracteres",
          },
        })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CropFreeIcon />
            </InputAdornment>
          ),
        }}
      />
      <p>{errors?.barcode?.message}</p>{" "}
    </>
  );
};

export default SelectsComponent;
