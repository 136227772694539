import React, { useState, useEffect, Fragment } from 'react';
/**Importar configuracion axios */
import clienteAxios from '../../Config/Axios';
/**Importar componentes select */
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
export default function SelectIndividualCourses(props) {
	const [courses, saveCourses] = useState([]);
	const [error, guardarError] = useState(true);
	useEffect(() => {
		const getCourses = async () => {
			await clienteAxios
				.get(`/individualcourses/${props.SchoolStorageID}`)
				.then((res) => {
					saveCourses(res.data.data);
				})
				.catch((error) => {
					console.log(error);
				});

			if (props.course) {
				guardarError(false);
			}
		};
		getCourses();
	}, []);

	const detectarCambiosIndividualCourses = (value) => {
		if (value.key) {
			guardarError(false);
		}
		props.detectarCambiosIndividualCourses(value);
	};

	const coursesArray = courses.map(course=>{
		return {key:course.id , value:course.id , label: course.name}
	});

	return (
		<Fragment>
			{props.course ? (
				<Select
					autofocus
					placeholder="Cursos"
					fullWidth
      				components={animatedComponents}
					options={coursesArray}
					onChange={ value => detectarCambiosIndividualCourses(value)}
					value={props.course}
				>
				</Select>
			) : (
				<Select
					autofocus
					placeholder="Cursos"
					fullWidth
      				components={animatedComponents}
					options={coursesArray}
					onChange={ value => detectarCambiosIndividualCourses(value)}
				>
				</Select>
			)}

			{error ? (
				<p style={{ color: 'red' }}>El Curso Es Un Campo Requerido</p>
			) : null}
		</Fragment>
	);
}
