import React, {Fragment,useState,useEffect} from 'react';
import './Course.css';
import SpinnerLoading from '../../Complements/SpinnerLoading';


export default function Course({course, AddCoursesState, errorDuplicado, guardarError}) {

    const { name, description } = course;

    const [ cargando , spinnerCargando ] = useState(false);
    
    useEffect(() => {
       if(Object.keys(course).length !== 0){

        spinnerCargando(true);
        
        guardarError(false);
        setTimeout(() => {
            spinnerCargando(false);
        }, 3000);


       } 
    }, [course])
    
    return (
        <Fragment>
         {   cargando ? <SpinnerLoading/>
             :
             name ?
             <Fragment>
            <h4 className="title-product">{name}</h4>
            <span className="price-product">Descripción: {description ? description : (<p>Sin Descripción</p>)}</span>
            <span className="price-product">Taller</span>
           
	        <div>
                {
                    errorDuplicado ? 
                    <p style={{color:'red'}}>No se puede duplicar el curso</p>
                    : null
                }
            </div>
            
             <br/>
             <button className="boton-agregar" onClick={AddCoursesState}>Agregar</button>
             </Fragment>
            : <h4 align="center">Selecciona Un Curso</h4>
         }
        
         </Fragment>
    )
}
