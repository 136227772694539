import { ADD_NEW_INSCRIPTION, CURRENT_INSCRIPTION, DELETE_INSCRIPTION, GET_ALL_INDIVIDUAL_INSCRIPTIONS, ADD_NEW_PAYMENT,
    SHOW_ERRORS_API, UPDATE_INSCRIPTION, GET_INFO_INSCRIPTION, GET_ALL_GRADUATE_INSCRIPTIONS } from "../../types";

export default function InscriptionsReducer(state, action) {

switch (action.type) {
case GET_ALL_INDIVIDUAL_INSCRIPTIONS: 
    return{
        ...state,
        inscriptions: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case GET_ALL_GRADUATE_INSCRIPTIONS: 
    return{
        ...state,
        inscriptions: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case GET_INFO_INSCRIPTION:
    return{
        ...state,
        inscription: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case ADD_NEW_INSCRIPTION:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }

case ADD_NEW_PAYMENT:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }

case SHOW_ERRORS_API:

    return{
        ...state,
        ErrorsAPI: action.payload,
        disabling: true,
    }

case CURRENT_INSCRIPTION:

    //sessionStorage.setItem('selectedSchools',JSON.stringify(action.payload))
    return{
        ...state,
        inscription:action.payload,
    }

case UPDATE_INSCRIPTION:

    //sessionStorage.removeItem('selectedSchools');
    return{
        ...state,
        success: true,
        ErrorsAPI: []
    }

case DELETE_INSCRIPTION:

    return{
        ...state,
        inscriptions: state.inscriptions.filter(inscription => inscription.id !== action.payload)
    } 

default:
    return state; 
}
}