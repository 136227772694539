import * as React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MethodGet from "../Config/Services";
import Spinner from "../Complements/Spinner";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#4a148c",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function TableBranches() {
  //sucursales = state, guardarBranch =funcion para guardar en el state;
  const [offices, guardarBranch] = React.useState([]);
  const [cargando, spinnerCargando] = React.useState(false);

  //query a la api
  React.useEffect(() => {
    let url = "/branches";
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        spinnerCargando(true);
      });
  }, []);

  //  spinner de carga
  if (!cargando) return <Spinner />;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Imagen</StyledTableCell>
            <StyledTableCell align="center">Sucursal</StyledTableCell>
            <StyledTableCell align="center">Direccion</StyledTableCell>
            <StyledTableCell align="center">Telefono</StyledTableCell>
            <StyledTableCell align="center">Opciones</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offices.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="center">
                <img
                  src={row.image}
                  style={{ width: "120px", height: "120px" }}
                />
              </StyledTableCell>
              <StyledTableCell align="center">{row.name}</StyledTableCell>
              <StyledTableCell align="center">{row.direction}</StyledTableCell>
              <StyledTableCell align="center">
                {row.number_phone}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Link to={`SucursalVentas/${row.id}`}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#4a148c", color: "white" }}
                  >
                    Ver Ventas
                  </Button>
                </Link>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
