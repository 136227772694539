import React, { useState, useContext, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import {
  Grid,
  Paper,
  Container,
  Dialog,
  Button,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import SubCategoryContext from "../../Reducers/SubCategories/SubCategoryContext";
import CardHeader from "../../Components/Card/CardHeader";
import CardBody from "../../Components/Card/CardBody";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import GridItem from "../../Components/Grid/GridItem";
import SelectCategories from "../SelectOptions/SelectCategory";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    //Mantiene el margen entre la vista final a 2 espaciados de los margenes de la pantalla fisica
    //deben ser igual en dodas las vistar de card para evitar diferentes alineaciones entre contenedores
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingInlineEnd: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: "#8e24aa",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  ContainerSubmit: {
    marginLeft: theme.spacing(30),
    marginTop: theme.spacing(1),
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  /**
   * identificar Codigo de FORMS
   */
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  /**
   *
   */
}));
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
function SubCategoriesAdd({ modal, handleClose, setOpenModalAdd }) {

  const classes = useStyles();

  const { success, addSubcategories } = useContext(SubCategoryContext);
  const [category, setCategory] = useState(null);

  const detectarCambiosCategory = (e) => {
    setCategory(e.target.value);
  };
  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data, e) => {
    e.preventDefault();
    addSubcategories(data);
  };

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de cupones
    if (success) {
      setOpenModalAdd(false);
    }
  }, [success]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modal}
        maxWidth={1200}
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ textAlign: "center", background: "#3F51B5", color: "white", }}>
          Registrar subcategoría
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden', overflowX: 'hidden' }} >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
            noValidate
          >

            <Grid
              container
              justifyContent="center"
              alignItems="center"

            >

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container style={{ marginTop: "-60px" }}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 3,
                          message: "Minimo 3 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>
                </Grid>
                <Grid container xs={12} xl={12} spacing={2} justify="center">
                  <Grid item xs={12} sm={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.submit}
                    >
                      GUARDAR
                    </Button>
                  </Grid>
                </Grid>
              </GridItem>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SubCategoriesAdd;