import React, { Fragment, useState, useEffect } from "react";
import "./Product.css";
import SpinnerLoading from "../../Complements/SpinnerLoading";
import { Button, TextField, Typography } from "@material-ui/core";

export default function ProductInventory({
  producto,
  AgregarProductosState,
  guardarCantidad,
  errorCantidad,
}) {
  const { name, price } = producto;

  const [cargando, spinnerCargando] = useState(false);

  useEffect(() => {
    if (Object.keys(producto).length !== 0) {
      spinnerCargando(true);
      setTimeout(() => {
        spinnerCargando(false);
      }, 3000);
    }
  }, [producto]);

  const priceProduct = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(price);

  return (
    <Fragment>
        <>
          <button className="boton-agregar" onClick={AgregarProductosState}>
            Agregar Producto
          </button>
        </>
    </Fragment>
  );
}
