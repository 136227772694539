import React, { useState, useEffect, Fragment } from 'react';
/**Importar componentes select */
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

export default function SelectTypeExpense(props) {
	const [error, guardarError] = useState(true);
	const [tipos, saveType] = useState([]);

	const typeExpenses = [
		{ type_expense: '1', name: 'Gastos Varíados' },
		{ type_expense: '2', name: 'Pagos de Comisiones' },
		
	];

	useEffect(() => {
		saveType(typeExpenses);
		if (props.nameTypeExpenses) {
			guardarError(false);
		}
	}, [error]);
	const detectarCambiosTypeExpenses = (e) => {
		if (e.target.value) {
			guardarError(false);
		}
		props.detectarCambiosTypeExpenses(e);
	};
	return (
		<Fragment>
			<InputLabel fullWidth>Tipo de Gasto*</InputLabel>

			{props.nameTypeExpenses ? (
				<Select
					label="Tipo de Gasto"
					fullWidth
					onChange={detectarCambiosTypeExpenses}
					value={props.nameTypeExpenses.type_expense}
				>
					<MenuItem disabled>Selecciona el Tipo de Gasto</MenuItem>
					{tipos.map((tipo) => (
						<MenuItem key={tipo.type_expense} value={tipo.type_expense}>
							{tipo.name}
						</MenuItem>
					))}
				</Select>
			) : (
				<Select label="Tipo de Gasto" fullWidth onChange={detectarCambiosTypeExpenses}>
					<MenuItem disabled>Selecciona el Tipo de Gasto</MenuItem>
					{tipos.map((tipo) => (
						<MenuItem key={tipo.type_expense} value={tipo.type_expense}>
							{tipo.name}
						</MenuItem>
					))}
				</Select>
			)}

			{error ? (
				<p style={{ color: 'red' }}>El Tipo de Gasto es un Campo Requerido</p>
			) : null}
		</Fragment>
	);
}
