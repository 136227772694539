import React from 'react';
import Dashboard from './Dashboard';
import Style from './style.css';


const LayoutDashboard = ({children})=>(
    <>
     {children}
     <Dashboard />
        
    </>
);

export default LayoutDashboard;