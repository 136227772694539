import React, { useState, useEffect } from 'react';
import LayoutDashboard from '../../Components/Layout/LayoutDashboard';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { Grid, Box, Paper } from '@material-ui/core';

/**importar spinner */
import Spinner from '../../Complements/Spinner';
/**Importar MethodPut */
import MethodGet, { MethodPut } from '../../Config/Services';

/**Importar Sweetalert2 * */
import Swal from 'sweetalert2';
/**Habilitar redirecciones */
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		paddingBottom: theme.spacing(4),
		paddingInlineEnd: theme.spacing(2),
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(30),
	},
	typography: {
		paddingTop: theme.spacing(4),
		margin: theme.spacing(1),
		paddingLeft: theme.spacing(30),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(9),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		background: '#3537DB',
		'&:hover': {
			//you want this to be the same as the backgroundColor above
			background: '#5902CF',
		},
		color: 'white',
	},
	ContainerSubmit: {
		marginLeft: theme.spacing(30),
		marginTop: theme.spacing(1),
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
}));

function ServiceEdit(props) {
	//Trabajar con el Spinner
	const [cargando, spinnerCargando] = useState(false);

	const { id } = props.match.params; //toma el id de la URL

	const classes = useStyles();

	// const [operator, setOperator] = useState({});
	const [service, saveService] = useState({});

	//Funcion para validaciones
	const { register, handleSubmit, errors } = useForm();

	//Extraer valores de service(destructuracion)
	const { name, description } = service;

	//Guardar los camios de la sucursal
	const onSubmit = (data, e) => {
		e.preventDefault();

		const dat = {
			...data,
		};

		let url = `/services/${id}`;
		MethodPut(url, dat)
			.then((res) => {
				Swal.fire({
					title: 'Servicio Editado Exitosamente',
					text: res.data.message,
					icon: 'success',
					timer: 2000,
					showConfirmButton: false,
				});
				props.history.push('/Servicios');
			})
			.catch((error) => {
				Swal.fire({
					title: 'Error',
					icon: error.response.data.error,
					title: error,
				});
			});
	};

	//obtener los campos de la sucursal
	useEffect(() => {
		let url = `/services/${id}`;
		MethodGet(url)
			.then((res) => {
				saveService(res.data.data);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	//spinner de carga
	if (!cargando) return <Spinner />;

	return (
		<LayoutDashboard>
			<div className={classes.root}>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className={classes.form}
					noValidate
				>
					<Box>
						<Typography
							component="h1"
							variant="h5"
							align="center"
							className={classes.typography}
						>
							Editar Servicio
						</Typography>
					</Box>
					<Grid container spacing={2} className={classes.container}>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="name"
								label="Nombre"
								name="name"
								autoComplete="name"
								autoFocus
								defaultValue={name}
								error={!!errors.name}
								inputRef={register({
									required: {
										value: true,
										message: 'El Nombre  requerido',
									},
									minLength: {
										value: 4,
										message: 'Minimo 4 caracteres',
									},
									maxLength: {
										value: 45,
										message: 'Maximo 45 caracteres',
									},
									pattern: {
										value: /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
										message: 'Unicamente carácteres alfabéticos',
									},
								})}
							/>
							<p>{errors?.name?.message}</p>
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="description"
								label="Descripción"
								name="description"
								autoComplete="name"
								defaultValue={name}
								autoFocus
								error={!!errors.description}
								inputRef={register({
									required: {
										value: true,
										message: 'La Descripción es requerido',
									},
									minLength: {
										value: 4,
										message: 'Minimo 4 caracteres',
									},
									maxLength: {
										value: 45,
										message: 'Maximo 45 caracteres',
									},
								})}
							/>
							<p>{errors?.description?.message}</p>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item xs={3} className={classes.ContainerSubmit}>
							<Button
								type="submit"
								fullWidth
								variant="outlined"
								className={classes.submit}
							>
								ACTUALIZAR
							</Button>
						</Grid>
					</Grid>
				</form>
			</div>
		</LayoutDashboard>
	);
}

export default withRouter(ServiceEdit);
