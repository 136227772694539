import React, { useState, useEffect, Fragment } from "react";
import CantidadProductos from "../Sales/CantidadProductos";
import {
  Grid,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import MethodGet from "../../Config/Services";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  TableRow: {
    background: "indigo",
  },
  TableTxt: {
    color: "white",
    fontSize: "13pt",
    fontFamily: "arial",
    "&:hover": {
      color: "whitesmoke",
      fontSize: "14pt",
      fontFamily: "arial",
    },
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(30),
  },
  typography: {
    paddingTop: theme.spacing(4),
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#3f51b5",
    },
    color: "inherit",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 615,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
}));

const ProductsList = (props) => {
  const {
    ventas,
    guardarVenta,
    subtotal,
    savesubtotal,
    Desc,
    SaveDesc,
    SaveCantdesc,
  } = props;

  const classes = useStyles();

  //Actualizar la cantidad de Productos
  const restarProductos = (i) => {
    //copiar el arreglo original de productos
    const todosProducto = [...ventas];

    //validar si esta en 0
    if (todosProducto[i].cantidad === 1) return;

    //decremento
    todosProducto[i].cantidad--;
    //final-price
    todosProducto[i].final_price =
      todosProducto[i].cantidad * todosProducto[i].price;

    //almacenarlo en el state
    guardarVenta(todosProducto);
  };
  useEffect(() => {
    actualizarTotal();
  }, [subtotal, ventas]);

  const aumentarProductos = (i) => {
    //copiar el arreglo para no mutar el original
    const todosProducto = [...ventas];

    //validar si es menor al stock actual
    if (todosProducto[i].cantidad >= ventas[i].quantity) return;

    //incremento
    todosProducto[i].cantidad++;

    //almacenar en el state
    guardarVenta(todosProducto);
  };

  //Eliminar un producto del state
  const eliminarProductoVenta = (id) => {
    const todosProductos = ventas.filter((venta) => venta.product_id !== id);
    guardarVenta(todosProductos);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const handleChangeQuantity = (e, i) => {
    const newQuantity = Number(e.target.value);
    const todosProducto = [...ventas];
    if (newQuantity > todosProducto[i].quantity) {
      Swal.fire({
        title: "Error",
        text:
          "La cantidad no puede ser mayor al stock disponible, unicamente hay " +
          todosProducto[i].quantity +
          " productos Disponibles",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    todosProducto[i].cantidad = newQuantity;
    todosProducto[i].final_price =
      todosProducto[i].cantidad * todosProducto[i].price;
    guardarVenta(todosProducto);
  };
  const [descuento, setDescuento] = useState('');
  const actualizarTotal = () => {
    if (ventas.length === 0) {
      savesubtotal(0);
      return;
    }
    //calcular el nuevo total
    let nuevoTotal = 0;
    //recorrer todos los productos
    ventas.map((venta) => (nuevoTotal += venta.cantidad * venta.price));
    savesubtotal(nuevoTotal);
    if (Number(descuento) > 0) {
      let finalTotal = (Number(descuento) * subtotal) / 100;
      let final = subtotal - finalTotal;
      SaveDesc(final);
    } else if (Number(descuento) === 0) {
      SaveDesc(subtotal);
    }
  };

  useEffect(() => {
    actualizarTotal();
        SaveCantdesc(descuento);
  }, [descuento]);

  const subtotalProduct = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(subtotal);
  const descuentoProduct = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 0,
  }).format(Desc);

  const handleChange = (event) => {
    let valor = parseInt(event.target.value, 10);

    // Chequea si el valor es un número. Si no, asume 0 para evitar NaN
    if (isNaN(valor)) {
      valor = 0;
    }

    // Ajusta el valor dentro del rango 0-100
    valor = Math.max(0, Math.min(100, valor));

    // Actualiza el estado con el valor corregido
    setDescuento(valor.toString()); // Convierte a string para el TextField
  };
  return (
    <>
      <Grid item xs={12}>
        <div class="scrollbar" id="style-1">
          <div class="force-overflow">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow className={classes.TableRow}>
                    <TableCell align="center" className={classes.TableTxt}>
                      Clave
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Producto
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Cantidad
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Stock
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Precio
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Total
                    </TableCell>
                    <TableCell align="center" className={classes.TableTxt}>
                      Opciones
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!ventas.length ? (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={6}
                        style={{
                          color: "black",
                          fontSize: "16pt",
                          fontFamily: "arial",
                        }}
                      >
                        Aún no has agregado productos
                      </TableCell>
                    </TableRow>
                  ) : (
                    ventas.map((venta, index) => (
                      <CantidadProductos
                        handleChangeQuantity={handleChangeQuantity}
                        key={venta.producto}
                        venta={venta}
                        restarProductos={restarProductos}
                        aumentarProductos={aumentarProductos}
                        eliminarProductoVenta={eliminarProductoVenta}
                        index={index}
                      />
                    ))
                  )}
                  <TableRow>
                    <TableCell rowSpan={3} />
                    <TableCell rowSpan={3} />
                    <TableCell rowSpan={3} />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Grid>
      <Grid container>
        <Fragment xs={12} md={12}>
          <Grid item xs={12} md={4}>
            <Box style={{ background: "#ececec91" }}>
              <Typography
                align="left"
                style={{ paddingLeft: 20, paddingTop: 20 }}
              >
                Subtotal
              </Typography>
              <Typography
                align="right"
                style={{
                  paddingRight: 20,
                  paddingBottom: 20,
                  marginTop: -20,
                }}
              >
                {subtotalProduct}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box style={{ background: "#ececec91" }}>
              <Typography
                align="center"
                style={{
                  // paddingRight: -5,
                  paddingTop: 1,
                  paddingBottom: 10,
                  marginTop: 1,
                }}
              >
                <TextField
                  label="Descuento a aplicar"
                  id="outlined-start-adornment"
                  type="number"
                  onChange={handleChange}
                  value={descuento} // Controla el valor del TextField con el estado
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={4}>
            <Box style={{ background: "#ececec91" }}>
              <Typography
                align="left"
                style={{ paddingLeft: 20, paddingTop: 20 }}
              >
                Total
              </Typography>
              <Typography
                align="right"
                style={{
                  paddingRight: 20,
                  paddingBottom: 20,
                  marginTop: -20,
                }}
              >
                {descuentoProduct}
              </Typography>
            </Box>
          </Grid>
        </Fragment>
      </Grid>
    </>
  );
};

export default ProductsList;
