import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalOffer from "@material-ui/icons/LocalOffer";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import FormatColorFillIcon from "@material-ui/icons/FormatColorFill";
import AuthContext from "../../Context/autenticacion/authContext";
import {
  Button,
  Tooltip,
  Hidden,
  Grid,
  Box,
  Fab,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
    marginRight: "-2%",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  customWidth: {
    fontSize: "12px",
    maxWidth: 150,
    justifyContent: "center",
    padding: "5px",
  },
}));

export default function ExpensesIndex() {
  const classes = useStyles();

  //sucursales = state, guardarBranch =funcion para guardar en el state;
  const [expenses, saveExpenses] = useState([]);
  const [comissions, saveComissions] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  const [error, guardarError] = useState(false);

  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario, usuarioAutenticado, cerrarSesion } = authContext;
  useEffect(() => {
    usuarioAutenticado();
  }, []);

  //query a la api
  useEffect(() => {
    let url = "/expenses";
    MethodGet(url)
      .then((res) => {
        saveExpenses(res.data.expenses);
        saveComissions(res.data.comissions);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  const generarTicket = (id, folio) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Generar El Ticket De Comisión?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Generar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        //Cunsume API de Back-end en laravel para generar tiket de venta
        clienteAxios
          .get(`/ticket-comision/${id}`, { responseType: "blob" })
          .then((response) => {
            fileDownload(response.data, "Ticket Comisión Pagada.pdf");
            guardarError(false);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              Swal.fire({
                icon: "error",
                title: "Algo sucedio",
                text: "No se cuenta con suficiente información para generar el reporte",
              });
            } else if (error.response.status === 422) {
              Swal.fire({
                icon: "error",
                title: "Algo sucedio",
                text: "No se pudo generar el reporte",
              });
            }
          });
      }
    });
  };

  const eliminarGasto = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "El gasto seleccionado no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        // eliminar en la rest api
        let url = `/expenses/${id}`;
        MethodDelete(url)
          .then((res) => {
            if (res.status === 200) {
              const CambioIndexExpenses = expenses.filter(
                (expense) => expense.id !== id
              );
              saveExpenses(CambioIndexExpenses);
              Swal.fire("Gasto Eliminado", res.data.mensaje, "success");
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error,
              icon: "error",
            });
          });
      }
    });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            marginTop: 100,
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            className={classes.typography}
            component="h1"
            variant="h4"
          >
            {"Gastos de la Sucursal"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="small"
            href={"/AgregarGasto/" + usuario.id_branch_office}
          >
            <Tooltip title="Agregar Gasto" aria-label="Agregar Gasto">
              <AddIcon />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/* sepa */}
	  <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
        <MaterialTable
          title="Gastos en mi Sucursal"
          columns={[
            { title: "Nombre", field: "name" },
            { title: "Descripción", field: "description" },
            {
              title: "Total",
              field: "total",
              render: (rowData) =>
                new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                  minimumFractionDigits: 0,
                }).format(rowData.total),
            },
            {
              title: "Imagen",
              field: "image",
              render: (rowData) =>
                rowData.image ? (
                  <ModalImage
                    small={rowData.image}
                    large={rowData.image}
                    alt="Ticket Gasto"
                  />
                ) : (
                  <ModalImage
                    small={
                      "https://pisouniversitario.com/template/images/page-404.jpg"
                    }
                    large={
                      "https://pisouniversitario.com/template/images/page-404.jpg"
                    }
                    alt="Ticket Gasto"
                  />
                ),
            },
            { title: "Usuario", field: "username" },
          ]}
          data={expenses}
          options={{
            headerStyle: {
              backgroundColor: "#4b0082",
              color: "#fff",
              fontSize: "16px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              paddingRight: "15px",
              alignContent: "center",
            },

            actionsColumnIndex: -1,
          }}
          actions={[
            (rowData) => ({
              icon: "delete",
              tooltip: "Eliminar Gasto",
              onClick: () => eliminarGasto(rowData.id),
            }),
          ]}
          components={{
            Action: (props) => (
              <Fragment>
                <Link
                  to={`EditarGasto/${props.data.id}`}
                  style={{ color: "#3f51b5" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      background: "white",
                    }}
                    size="small"
                  >
                    <Tooltip title="Editar gasto" aria-label="Editar gasto">
                      <EditIcon style={{ color: "blue" }} />
                    </Tooltip>
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  style={{ textTransform: "none", background: "white" }}
                  size="small"
                  onClick={() => eliminarGasto(props.data.id)}
                >
                  <Tooltip title="Eliminar gasto" aria-label="Editar gasto">
                    <DeleteIcon style={{ color: "red" }} />
                  </Tooltip>
                </Button>
              </Fragment>
            ),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsSelect: "Columnas",
              firstTooltip: "Primera Pagina",
              previousTooltip: "Anterior",
              nextTooltip: "Siguiente",
              lastTooltip: "Ultima Página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Opciones",
            },
            body: {
              emptyDataSourceMessage: "No Hay Gastos que Mostrar",
              filterRow: {
                filterTooltip: "Buscar",
              },
            },
          }}
        />
      </Grid>

      <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
        <MaterialTable
          title="Comisiones Pagadas de la Sucursal"
          columns={[
            { title: "Vendedor", field: "seller" },
            {
              title: "Total Pagado",
              field: "total",
              render: (rowData) =>
                new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                  minimumFractionDigits: 0,
                }).format(rowData.total),
            },
          ]}
          data={comissions}
          options={{
            headerStyle: {
              backgroundColor: "#4b0082",
              color: "#fff",
              fontSize: "16px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              paddingRight: "25px",
              alignContent: "center",
            },

            actionsColumnIndex: -1,
          }}
          actions={[
            (rowData) => ({
              icon: "delete",
              tooltip: "Eliminar Gasto",
              onClick: () => eliminarGasto(rowData.id),
            }),
          ]}
          components={{
            Action: (props) => (
              <Fragment>
                <Button
                  variant="contained"
                  style={{ textTransform: "none", background: "white" }}
                  size="small"
                  onClick={() => generarTicket(props.data.id, props.data.folio)}
                >
                  <Tooltip
                    title="Generar ticket de comisión"
                    aria-label="Generar ticket de comisión"
                  >
                    <PictureAsPdf style={{ color: "indigo" }} />
                  </Tooltip>
                </Button>
                <Button
                  variant="contained"
                  style={{ textTransform: "none", background: "white" }}
                  size="small"
                  onClick={() => eliminarGasto(props.data.id)}
                >
                  <Tooltip title="Eliminar gasto" aria-label="Editar gasto">
                    <DeleteIcon style={{ color: "red" }} />
                  </Tooltip>
                </Button>
              </Fragment>
            ),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsSelect: "Columnas",
              firstTooltip: "Primera Pagina",
              previousTooltip: "Anterior",
              nextTooltip: "Siguiente",
              lastTooltip: "Ultima Página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Opciones",
            },
            body: {
              emptyDataSourceMessage: "No Hay Gastos que Mostrar",
              filterRow: {
                filterTooltip: "Buscar",
              },
            },
          }}
        />
      </Grid>
    </LayoutDashboard>
  );
}
