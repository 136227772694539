import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { Button, Tooltip, Hidden } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import ClassroomContext from "../../Reducers/Classrooms/ClassroomContext";
/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import AddSellerModal from "./AddSellerForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(12),
    paddingBottom: theme.spacing(4),
    //paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    //marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const SchoolSellerClassrooms = (props) => {
  const classes = useStyles();

  const { schoolID } = props;

  const [cargando, spinnerCargando] = useState(false);

  const [school, saveSchool] = useState({});

  //Extraer valores de branch(destructuracion)
  const { name } = school;
  //console.log(SchoolStorageID);

  /**Extraer los proveedores del state inicial */
  const ClassroomsList = useContext(ClassroomContext);
  const { classrooms, GetClassrooms, success } = ClassroomsList;

  const StateClassroomsContext = useContext(ClassroomContext);
  const { SaveCurrentClassroom, DeleteClassroom } = StateClassroomsContext;

  const SelectClassroom = (classroom) => {
    SaveCurrentClassroom(classroom);
  };

  //obtener los proveedores carga el componente
  useEffect(() => {
    GetClassrooms(schoolID);
    spinnerCargando(true);
  }, [success, schoolID]);
  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${schoolID}`;
    MethodGet(url)
      .then((res) => {
        saveSchool(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [schoolID]);


  //modal sALONES
  const [openModalClassroomsSeller, setOpenModalClassroomsSeller] = React.useState(false);

  const handleClickOpenModalClassroomsSeller = () => {
    setOpenModalClassroomsSeller(true);
  };
  const handleCloseModalClassroomsSeller = () => {
    setOpenModalClassroomsSeller(false);
  };
  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            marginTop: 100,
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            className={classes.typography}
          >
            {"Salones de la escuela " + name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            //href="/Agregar_Salon"
            onClick={() => handleClickOpenModalClassroomsSeller()}
          >
            <Tooltip title="Agregar Salon" aria-label="Agregar Salon">
              <AddIcon size="large" />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
      >
        <MaterialTable
          title=""
          columns={[
            { title: "Nombre", field: "name" },
            {
              title: "Capacidad",
              field: "capacity",
              render: (rowData) => <p>{rowData.capacity} Personas </p>,
            },
          ]}
          data={classrooms}
          options={{
            headerStyle: {
              backgroundColor: "none",
              color: "#000",
              fontSize: "17px",
            },

            actionsColumnIndex: -1,
          }}
          actions={[
            (rowData) => ({
              icon: "delete",
              tooltip: "Eliminar Salón",
              onClick: () => DeleteClassroom(rowData.id),
            }),
          ]}
          components={{
            Action: (props) => (
              <Fragment>
                <Button
                  variant="contained"
                  style={{ textTransform: "none", background: "white" }}
                  size="small"
                  onClick={() => DeleteClassroom(props.data.id)}
                >
                  <Tooltip title="Eliminar Salón" aria-label="Editar Salón">
                    <DeleteIcon style={{ color: "red" }} />
                  </Tooltip>
                </Button>
              </Fragment>
            ),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from} - {to} de {count}",
              labelRowsSelect: "Columnas",
              firstTooltip: "Primera Pagina",
              previousTooltip: "Anterior",
              nextTooltip: "Siguiente",
              lastTooltip: "Ultima Página",
            },
            toolbar: {
              searchTooltip: "Buscar",
              searchPlaceholder: "Buscar",
            },
            header: {
              actions: "Opciones",
            },
            body: {
              emptyDataSourceMessage: "No Hay Salones que Mostrar",
              filterRow: {
                filterTooltip: "Buscar",
              },
            },
          }}
        />
      </Grid>
      <AddSellerModal
        modal={openModalClassroomsSeller}
          handleCloseSeller={handleCloseModalClassroomsSeller}
          setOpenModalClassroomsSeller={setOpenModalClassroomsSeller}
          schoolID={schoolID}

      />
    </LayoutDashboard>
  );
};

export default SchoolSellerClassrooms;
