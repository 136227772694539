import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {
  Grid,
  InputLabel,
  TextField,
} from "@material-ui/core";
import GridItem from "../../Components/Grid/GridItem";
import MethodGet from "../../Config/Services";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ClientsContext from "../../Reducers/Clients/ClientsContext";
import { useContext } from "react";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ModalClient({ modal, handleClose, id }) {
  const { register, handleSubmit, errors } = useForm();
  const { CreateClient } = useContext(ClientsContext);
  const [nameOffice, guardarSucursal] = useState({
    id_branch_office: id,
  });
  const [branch, guardarBranch] = useState({});
  useEffect(() => {
    let url = `/branches/${nameOffice.id_branch_office}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // const { name, direction, number_phone, state_id, municipality_id } = branch;
  const onSubmit = (data, e) => {
    // e.preventDefault();
    data.id_branch_office = nameOffice.id_branch_office;
    CreateClient(data);
    handleClose();
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modal}
        maxWidth={1200}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Registrar cliente
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <h3>Datos Personales</h3>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Nombre: *</InputLabel>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="name"
                      label="Nombre(s)"
                      required
                      name="name"
                      autoComplete="name"
                      autoFocus
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 3,
                          message: "Minimo 3 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                        pattern: {
                          value: /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                          message: "Unicamente carácteres alfabéticos",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Apellido Paterno: *</InputLabel>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="first_lastname"
                      label="Apellido Paterno"
                      name="first_lastname"
                      autoComplete="first_lastname"
                      autoFocus
                      error={!!errors.first_lastname}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Apellido Paterno es requerido",
                        },
                        minLength: {
                          value: 3,
                          message: "Minimo 3 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                        pattern: {
                          value: /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                          message: "Unicamente carácteres alfabéticos",
                        },
                      })}
                    />
                    <p>{errors?.first_lastname?.message}</p>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Apellido Materno: *</InputLabel>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                      id="second_lastname"
                      label="Apellido Materno"
                      name="second_lastname"
                      autoComplete="second_lastname"
                      autoFocus
                      error={!!errors.second_lastname}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Apellido Materno es requerido",
                        },
                        minLength: {
                          value: 3,
                          message: "Minimo 3 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                        pattern: {
                          value: /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
                          message: "Unicamente carácteres alfabéticos",
                        },
                      })}
                    />
                    <p>{errors?.second_lastname?.message}</p>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Número Telefónico: *</InputLabel>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="number_phone"
                      label="Teléfono"
                      name="number_phone"
                      autoComplete="phone"
                      type="number"
                      autoFocus
                      error={!!errors.number_phone}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Teléfono es requerido",
                        },
                        minLength: {
                          value: 10,
                          message: "Mínimo 10 digitos",
                        },
                        maxLength: {
                          value: 10,
                          message: "Maximo 10 digitos",
                        },
                      })}
                    />
                    <p>{errors?.number_phone?.message}</p>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel>Ingresa su RFC:</InputLabel>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="RFC"
                      label="RFC"
                      name="RFC"
                      autoComplete="RFC"
                      autoFocus
                      error={!!errors.RFC}
                      inputRef={register({
                        minLength: {
                          value: 10,
                          message: "Minimo 10 caracteres",
                        },
                        maxLength: {
                          value: 13,
                          message: "Maximo 13 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.RFC?.message}</p>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Grid item xs={12} md={12} lg={12}>
                    <h3>Dirección</h3>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3} lg={3}>
                      <InputLabel>Calle:</InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="street"
                        label="Calle"
                        name="street"
                        autoComplete="street"
                        autoFocus
                        error={!!errors.street}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "El Nombre de la Calle es requerida",
                          },
                          minLength: {
                            value: 4,
                            message: "Minimo 4 caracteres",
                          },
                          maxLength: {
                            value: 255,
                            message: "Maximo 255 caracteres",
                          },
                        })}
                      />
                      <p>{errors?.street?.message}</p>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <InputLabel>Código Postal: *</InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="postal_code"
                        label="Código Postal"
                        name="postal_code"
                        autoComplete="postal_code"
                        type="number"
                        autoFocus
                        error={!!errors.postal_code}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "El codigo postal es requerido",
                          },
                          minLength: {
                            value: 5,
                            message: "Mínimo 5 digitos",
                          },
                          maxLength: {
                            value: 5,
                            message: "Maximo 5 digitos",
                          },
                        })}
                      />
                      <p>{errors?.postal_code?.message}</p>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <InputLabel>Número Exterior:</InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="number_ext"
                        label="Numero Exterior"
                        name="number_ext"
                        autoComplete="number_ext"
                        type="number"
                        autoFocus
                        error={!!errors.number_ext}
                        inputRef={register({
                          minLength: {
                            value: 1,
                            message: "Mínimo 1 digito",
                          },
                          maxLength: {
                            value: 10,
                            message: "Maximo 10 digitos",
                          },
                        })}
                      />
                      <p>{errors?.number_ext?.message}</p>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                      <InputLabel>Número Interior:</InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="number_int"
                        label="Numero Interior"
                        name="number_int"
                        autoComplete="number_int"
                        type="number"
                        autoFocus
                        error={!!errors.number_int}
                        inputRef={register({
                          minLength: {
                            value: 1,
                            message: "Mínimo 1 digito",
                          },
                          maxLength: {
                            value: 10,
                            message: "Maximo 10 digitos",
                          },
                        })}
                      />
                      <p>{errors?.number_int?.message}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: "#66bb6a",
                    color: "white",
                  }}
                >
                  GUARDAR
                </Button>
              </GridItem>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default ModalClient;
