import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Button, Grid, Fab, Box } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextField from "@material-ui/core/TextField";
/**Importar componentes de Iconos */
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  TableRow: {
    background: "Orange",
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(30),
  },
  typography: {
    paddingTop: theme.spacing(4),
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#3f51b5",
    },
    color: "inherit",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 615,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  buttonQuantity: {
    color: "indigo",
    fontSize: 25,
    paddingTop: -20,
  },
  fabDelete: {
    paddingTop: "-25px",
    color: theme.palette.common.white,
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "#b30000",
    },
  },
}));

function ProductsRow(props) {
  const classes = useStyles();
  const {
    venta,
    handleChangeQuantity,
    index,
    handleDeleteProduct,
    status_report,
  } = props;

  return (
    <TableRow>
      <TableCell align="center">{venta.tag}</TableCell>
      <TableCell align="center">{venta.name}</TableCell>
      <TableCell align="center">{venta.description}</TableCell>
      {status_report !== 3 ? (
        <TableCell align="center">
          <TextField
            type="text"
            value={venta.cantidad}
            onChange={(e) => {
              // Utiliza una expresión regular para verificar si la entrada es un número positivo
              const value = e.target.value;
              if (/^[0-9]*$/.test(value)) {
                // Si la entrada es un número positivo (o vacío para permitir borrado), actualiza el estado
                handleChangeQuantity(e, index);
              }
              // Si no, simplemente no hace nada (ignora la entrada no deseada)
            }}
            style={{ width: "25%", borderRadius: "15px" }}
            align="center"
            inputProps={{
              min: "0", // Establece un mínimo de 0 para evitar números negativos
              // Estos atributos aseguran que solo se puedan introducir números positivos
            }}
          />
        </TableCell>
      ) : (
        <TableCell align="center">{venta.cantidad}</TableCell>
      )}
      {status_report !== 3 ? (
        <TableCell align="center">
          <Button
            startIcon={<DeleteIcon />}
            onClick={() => handleDeleteProduct(index)}
            color="secondary"
          >
            Eliminar
          </Button>
        </TableCell>
      ) : null}
    </TableRow>
  );
}

export default ProductsRow;
