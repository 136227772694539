import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ArrowUpwardTwoToneIcon from "@material-ui/icons/ArrowUpwardTwoTone";
import Typography from "@material-ui/core/Typography";
import BackupIcon from "@material-ui/icons/Backup";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MethodGet, { MethodPost } from "../../Config/Services";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const ExcelReader = ({ saveProductsBranded }) => {
  const classes = useStyles();

  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (excelData.length > 0) {
      // Asegúrate de que excelData no esté vacío.
      saveProductsBranded(excelData);
      localStorage.setItem("EnterProducts", JSON.stringify(excelData));
      // Aquí puedes añadir más lógica que se debe ejecutar después de actualizar excelData.
    }
  }, [excelData]); // Dependencias: se ejecuta cada vez que excelData cambia.

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });

        // Obtener la primera hoja
        const firstSheetName = workbook.SheetNames[0];
        const firstSheet = workbook.Sheets[firstSheetName];

        // Convertir la hoja en un objeto con las llaves de la primera fila
        const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        // Obtener las llaves de la primera fila
        const keys = jsonData[0];

        // Eliminar la primera fila del array (contiene las llaves)
        jsonData.shift();

        // Convertir las filas restantes en un array de objetos usando las llaves
        const formattedData = jsonData.map((row) => {
          const obj = {};
          keys.forEach((key, index) => {
            obj[key] = row[index];
          });
          return obj;
        });

        const filter = formattedData.filter(function (objeto) {
          return objeto.cantidad !== "0";
        });
        const filterWithNewKey = filter.map((objeto) => {
          const final_price = Number(objeto.price) * Number(objeto.cantidad);
          return { ...objeto, final_price };
        });
        const newData = filterWithNewKey.map((objeto) => {
          const { stock_disponible, ...resto } = objeto; // Desestructuramos el nombreViejo y tomamos el resto de propiedades
          return { quantity: stock_disponible, ...resto }; // Creamos un nuevo objeto con la llave cambiada
        });

        let valid = true; // Asumimos que los datos son válidos inicialmente

        for (let objeto of newData) {
          if (Number(objeto.cantidad) < 0) {
            Swal.fire({
              title: "Error",
              text: `Los números negativos en cantidad no son posibles de analizar para el producto "${objeto.name}". Favor de cambiar la cantidad.`,
              icon: "error",
            });
            valid = false; // Marcar los datos como inválidos
            break; // Salir del bucle for
          }

          if (Number(objeto.cantidad) > Number(objeto.quantity)) {
            Swal.fire({
              title: "No es posible cargar el archivo!",
              html: `La cantidad ingresada para el producto "<b> ${objeto.name} </b>". es mayor al stock disponible`,
              icon: "error",
            });
            valid = false; // Marcar los datos como inválidos
            break; // Salir del bucle for
          }
        }

        if (valid) {
          let url = `/productstoexcel`;
          MethodPost(url, newData)
            .then((res) => {
              setExcelData(res.data);
              // Aquí podrías incluir más lógica después de una respuesta exitosa
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error,
                icon: "error",
              });
            });
        }
      };

      reader.readAsBinaryString(file);
    }
    e.target.value = null;
  };

  return (
    <>
      <input
        onChange={handleFileUpload}
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
      />
      <label htmlFor="contained-button-file">
        <Tooltip title="Subir venta">
          <IconButton
            color="primary"
            component="span"
            variant="outlined"
            style={{ marginTop: 10, marginBottom: 10 }}
            fullWidth
          >
            <BackupIcon />
          </IconButton>
        </Tooltip>
      </label>
    </>
  );
};

export default ExcelReader;
