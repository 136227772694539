import { ADD_NEW_CLASSROOM, CURRENT_CLASSROOM, DELETE_CLASSROOM, GET_ALL_CLASSROOMS,
    SHOW_ERRORS_API, UPDATE_CLASSROOM, GET_INFO_CLASSROOM } from "../../types";

export default function ClassroomReducer(state, action) {

switch (action.type) {
case GET_ALL_CLASSROOMS: 
    return{
        ...state,
        classrooms: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case GET_INFO_CLASSROOM:
    return{
        ...state,
        classroom: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case ADD_NEW_CLASSROOM:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
        }

case SHOW_ERRORS_API:

    return{
        ...state,
        ErrorsAPI: action.payload
    }

case CURRENT_CLASSROOM:

    //sessionStorage.setItem('selectedSchools',JSON.stringify(action.payload))
    return{
        ...state,
        classroom:action.payload,
    }

case UPDATE_CLASSROOM:

    //sessionStorage.removeItem('selectedSchools');
    return{
        ...state,
        success: true,
        ErrorsAPI: []
    }

case DELETE_CLASSROOM:

    return{
        ...state,
        classrooms: state.classrooms.filter(classroom => classroom.id !== action.payload)
    } 

default:
    return state; 
}
}