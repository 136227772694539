import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import DeparturesAdmin2 from './DeparturesAdmin2';
import DeparturesSeller2 from './DeparturesSeller2';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import SchoolContext from '../../Reducers/Schools/SchoolContext';

export default function DeparturesIndex2(props) {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;
   const { id } = props.match.params;
   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		case '1':
			component = <DeparturesAdmin2 id={id}  />
			break;
		case '2':
			component = <DeparturesSeller2 {...props} id={usuario.id_branch_office} />
			break;
		case '3':
			component = <DeparturesSeller2 {...props}  id={usuario.id_branch_office} />
			break;
		  default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
