import React, { useState, useEffect, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import { Button, Tooltip, Hidden, Box, IconButton } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
    marginRight: "-2%",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  ImgTblDetail: {
    display: "flex",
    width: "50px",
    height: "50px",
  },
}));

const UsersIndex = (props) => {
  const classes = useStyles();

  //usuarios = state, guardarusuario =funcion para guardar en el state;
  const [usuarios, guardarUsuario] = useState([]);
  const [cargando, spinnerCargando] = useState(false);

  //query a la api

  useEffect(() => {
    let url = "/users";
    MethodGet(url)
      .then((res) => {
        guardarUsuario(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const eliminarUsuario = (id) => {
    Swal.fire({
      title: "¿Estas Seguro?",
      text: "El usuario seleccionado no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      comfirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        //Eliminar en la rest Api
        let url = `/users/${id}`;
        MethodDelete(url).then((res) => {
          if (res.status === 200) {
            const CambioIndexUsers = usuarios.filter(
              (usuario) => usuario.id !== id
            );
            guardarUsuario(CambioIndexUsers);
            Swal.fire("Usuario Eliminado", res.data.mensaje, "success");
          }
        });
      }
    });
  };

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* titulo */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 100,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            className={classes.typography}
            component="h1"
            variant="h4"
          >
            Mis Usuarios
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
          <Fab
            className={classes.fabGreen}
            aria-label="add"
            size="medium"
            href="/Agregar_Usuario"
          >
            <Tooltip title={"Agregar Usuario "} aria-label={"Agregar Usuario "}>
              <AddIcon size="large" />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              {
                title: "Foto de Perfil",
                field: "image",
                render: (rowData) =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      alt="producto"
                    />
                  ) : (
                    <ModalImage
                      small={
                        "https://cdni.iconscout.com/illustration/premium/thumb/404-7304110-5974976.png?f=webp"
                      }
                      large={
                        "https://cdni.iconscout.com/illustration/premium/thumb/404-7304110-5974976.png?f=webp"
                      }
                      className={classes.ImgTblDetail}
                      alt="usuario"
                    />
                  ),
              },
              { title: "Nombre", field: "name" },
              { title: "E-mail", field: "email" },
              {
                title: "Teléfono",
                field: "number_phone",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.number_phone ? (
                        <p> {rowData.number_phone} </p>
                      ) : (
                        <p>No tiene un numero telefonico registrado</p>
                      )}
                    </Fragment>
                  );
                },
              },
              {
                title: "Tipo de Usuario",
                field: "type",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.type == 1 ? (
                        <p>Administrador </p>
                      ) : rowData.type == 2 ? (
                        <p>Vendedor </p>
                      ) : rowData.type == 3 ? (
                        <p>Sub Administrador </p>
                      ) : (
                        <p>No tiene asigando un tipo de usuario</p>
                      )}
                    </Fragment>
                  );
                },
              },
              {
                title: "Sueldo",
                field: "salary",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.salary ? rowData.salary : <p>No Aplica</p>}
                    </Fragment>
                  );
                },
              },
              {
                title: "Comisión",
                field: "comission_percentage",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.comission_percentage ? (
                        <p>{rowData.comission_percentage} %</p>
                      ) : (
                        <p>No Aplica</p>
                      )}
                    </Fragment>
                  );
                },
              },
              {
                title: "Sucursal",
                field: "branch_name",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.branch_name ? (
                        <b style={{ color: "#4caf50" }}>
                          {" "}
                          {rowData.branch_name}{" "}
                        </b>
                      ) : (
                        <b style={{ color: "red" }}>No asignada</b>
                      )}
                    </Fragment>
                  );
                },
              },
            ]}
            data={usuarios}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "delete",
                tooltip: "Eliminar Usuario",
              }),
            ]}
            components={{
              Action: (props) => (
                <Fragment>
                  <Link
                    to={`Editar_Usuario/${props.data.id}`}
                    style={{ color: "#3f51b5" }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "none",
                        background: "white",
                      }}
                      size="small"
                    >
                      <Tooltip
                        title="Editar Usuario"
                        aria-label="Editar Usuario"
                      >
                        <EditIcon style={{ color: "blue" }} />
                      </Tooltip>
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", background: "white" }}
                    size="small"
                    onClick={() => eliminarUsuario(props.data.id)}
                  >
                    <Tooltip
                      title="Eliminar Usuario"
                      aria-label="Editar Usuario"
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Tooltip>
                  </Button>
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Usuarios que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              {
                title: "Foto de Perfil",
                field: "image",
                render: (rowData) =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      alt="producto"
                    />
                  ) : (
                    <ModalImage
                    small={
                      "https://cdni.iconscout.com/illustration/premium/thumb/404-7304110-5974976.png?f=webp"
                    }
                    large={
                      "https://cdni.iconscout.com/illustration/premium/thumb/404-7304110-5974976.png?f=webp"
                    }
                      className={classes.ImgTblDetail}
                      alt="usuario"
                    />
                  ),
              },
              { title: "Nombre", field: "name" },
            ]}
            data={usuarios}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Email: {rowData.email}</p>
                      <p>
                        Teléfono:{" "}
                        {
                          <Fragment>
                            {rowData.number_phone ? (
                              <> {rowData.number_phone} </>
                            ) : (
                              <>No tiene un numero telefonico registrado</>
                            )}
                          </Fragment>
                        }
                      </p>
                      <p>
                        Tipo de Usuario:{" "}
                        {
                          <Fragment>
                            {rowData.type == 1 ? (
                              <>Administrador </>
                            ) : rowData.type == 2 ? (
                              <>Vendedor </>
                            ) : rowData.type == 3 ? (
                              <>Sub Administrador </>
                            ) : (
                              <>No tiene asigando un tipo de usuario</>
                            )}
                          </Fragment>
                        }
                      </p>
                      <p>
                        Sueldo:{" "}
                        {
                          <Fragment>
                            {rowData.salary ? rowData.salary : <>No Aplica</>}
                          </Fragment>
                        }
                      </p>
                      <p>
                        Comisión:{" "}
                        {
                          <Fragment>
                            {rowData.comission_percentage ? (
                              <>{rowData.comission_percentage} %</>
                            ) : (
                              <>No Aplica</>
                            )}
                          </Fragment>
                        }
                      </p>
                      <p>
                        Sucursal:{" "}
                        {
                          <Fragment>
                            {rowData.branch_name ? (
                              <b style={{ color: "#4caf50" }}>
                                {" "}
                                {rowData.branch_name}{" "}
                              </b>
                            ) : (
                              <b style={{ color: "red" }}>No asignada</b>
                            )}
                          </Fragment>
                        }
                      </p>

                      <p>Opciones: </p>
                      <p>
                        {
                          <Fragment>
                            <Link to={`Editar_Usuario/${rowData.id}`}>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 50,
                                }}
                                size="small"
                              >
                                <Tooltip
                                  title="Editar Usuario"
                                  aria-label="Editar Usuario"
                                >
                                  <EditIcon style={{ color: "blue" }} />
                                </Tooltip>
                              </IconButton>
                            </Link>
                            <IconButton
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 100,
                              }}
                              size="small"
                              onClick={() => eliminarUsuario(rowData.id)}
                            >
                              <Tooltip
                                title="Eliminar Usuario"
                                aria-label="Editar Usuario"
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </Tooltip>
                            </IconButton>
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Usuarios que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </LayoutDashboard>
  );
};

export default UsersIndex;
