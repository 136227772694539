import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import BrandSales from "./BrandSales";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ProductsList = (props) => {
  const {
    productsBranded,
    saveProductsBranded,
    totalDiscountBrand,
    saveTotalDiscountBrand,
    id,
    descuentoporcentaje,
    subtotalBrand,
    SaveSubTotalBrand,
    cliente,
    productsExcel,
  } = props;

  return (
    <>
      {/* <AppBar position="static" className={classes.AppBarrProps}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.OpctionTbl}
        >
          <Tab label="Productos Wapizima" {...a11yProps(0)} />
          <Tab label="Productos Mixtos" {...a11yProps(1)} />
          <Tab label="Productos Extra" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}> */}
        <BrandSales
        productsExcel={productsExcel}
          productsBranded={productsBranded}
          saveProductsBranded={saveProductsBranded}
          totalDiscountBrand={totalDiscountBrand}
          id={id}
          subtotal={subtotalBrand}
          guardarTotal={SaveSubTotalBrand}
          totalDescuento={totalDiscountBrand}
          guardarTotalDescuento={saveTotalDiscountBrand}
          descuentoporcentaje={descuentoporcentaje}
          cliente={cliente}
        />
      {/* </TabPanel>
      <TabPanel value={value} index={1}>
        <WithoutBrandSales
          id={id}
          productsWithoutBrand={productsWithoutBrand}
          saveProductsWithoutBrand={saveProductsWithoutBrand}
          subtotal={subtotalWithoutBrand}
          guardarTotal={SaveSubTotalWithoutBrand}
          totalDescuento={totalDiscountWithoutBrand}
          guardarTotalDescuento={saveTotalDiscountWithoutBrand}
          discountWithoutBrand={discountWithoutBrand}
          cliente={cliente}
          index={1}
          setValue={setValue}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SPASales
          id={id}
          productsSPA={productsSPA}
          saveProductsSPA={saveProductsSPA}
          subtotal={subtotalSPA}
          guardarTotal={saveSubTotalSPA}
          discountSPA={discountSPA}
          totalDescuento={totalDiscountSPA}
          guardarTotalDescuento={saveTotalDiscountSPA}
          cliente={cliente}
          index={2}
          setValue={setValue}
        />
      </TabPanel> */}
    </>
  );
};

export default ProductsList;
