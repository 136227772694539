import React, { useState, useEffect, Fragment } from 'react';
import MethodGet from '../../Config/Services';
import Add from './Add';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';
import { Link } from 'react-router-dom';

export default function BranchMiddlewareProducts(props) {
   //Extraer la informacion de autenticación
   const [offices, guardarBranch] = useState([]);
  const [cargando, spinnerCargando] = useState(false);
  
  const { id } = props.match.params;

	//query a la api
	useEffect(() => {
		let url = '/validate-branches';
		MethodGet(url)
			.then((res) => {
				guardarBranch(res.data.branches);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
    }, []);

  let component;
  if(offices){
    switch (offices) {
      case 0:
    component = <AlertNotAuthorized mensaje={<p>No puedes agregar productos hasta que registres al menos una sucursal! <Link to="/Agregar_Sucursal" style={{ color: "#3f51b5" }}>Da Clic Aqui para registrar una Sucursal</Link></p>}/>
        break;
      default:
        component = <Add
                    id={id}
                    />
        break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
