import React, { useState, useEffect, Fragment, useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MethodGet, { MethodPost } from "../../Config/Services";
import Spinner from "../../Complements/Spinner";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import ProductContext from "../../Reducers/Products/ProductContext";

export default function ModalAdd(props) {
  const { id, modal, handleClose, stockid, actualizarCambios } = props;
  const [stock, guardarStock] = useState({});
  const [cargando, spinnerCargando] = useState(false);
  const { register, errors, handleSubmit } = useForm({});
  const { getStockProductsDataExcel } = useContext(ProductContext);

  const cancelar = () => {
    guardarStock({});
    handleClose();
    spinnerCargando(false);
  };
  let url = `/stocks/${stockid}`;
  useEffect(() => {
    if (stockid) {
      MethodGet(url)
        .then((response) => {
          guardarStock(response.data.data);
          spinnerCargando(true);
        })
        .catch((error) => {
          console.log(error.response.data.error);
        });
    }
  }, [stockid]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    let url = `/add-quantity/${stockid}`;
    MethodPost(url, data)
      .then((response) => {
        Swal.fire({
          title: "Buen Trabajo",
          text: "Stock Actualizado",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        handleClose();
        guardarStock({});
        actualizarCambios();
        getStockProductsDataExcel(id);
        spinnerCargando(false);
      })
      .catch((error) => {
        handleClose();
        guardarStock({});
        spinnerCargando(false);
        Swal.fire({
          icon: "error",
          title: error.response.data.error,
        });
      });
  };

  return (
    <Fragment>
      <Dialog
        open={modal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Agregar Existencias</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            {!cargando ? (
              <Spinner />
            ) : (
              <TextField
                defaultValue="0"
                autoFocus
                margin="dense"
                type="number"
                fullWidth
                name="quantity"
                error={!!errors.quantity}
                inputRef={register({
                  min: {
                    value: 1,
                    message: "La cantidad a agregar debe ser mayor a 1",
                  },
                  required: {
                    value: true,
                    message: "La cantidad es requerida",
                  },
                })}
              />
            )}
            <p style={{ color: "red" }}>{errors?.quantity?.message}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelar} color="secondary" variant="contained">
              Cancelar
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
}
