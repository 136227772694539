import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LayoutDashboard from '../Components/Layout/LayoutDashboard';
import { Paper, Card, CardContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    rootCard: {
      minWidth: 200,
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(10),
    },
    container: {
      paddingInlineEnd: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBlockEnd: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    input: {
      display: 'none',
    },
    paperTitle: {
      background: '#424242'
    },
  }));

export default function WarningAlert({mensaje}){
    const classes = useStyles();
    return (
      <LayoutDashboard>
      <Card className={classes.rootCard} variant="outlined">
        <CardContent>
          <Container className={classes.container}>
            <Grid Container spacing={3}>
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={9} sm={11}>
                    <Paper className={classes.paperTitle}>
                      <div className={classes.root}>
                      <Alert variant="filled" severity="warning">
                      {mensaje}
                      </Alert>
                    </div>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Container>
        </CardContent>
      </Card>
      </LayoutDashboard>
    )
}
