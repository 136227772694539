import React, { useState, useEffect, Fragment } from "react";
import TableCourses from "./TableCourses";
import {
  Grid,
  TableContainer,
  Container,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Box,
  Zoom,
} from "@material-ui/core";
import MethodGet from "../../Config/Services";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { green } from "@material-ui/core/colors";
import { Button, Tooltip } from "@material-ui/core";
import Moment from "moment";
import DoneIcon from "@material-ui/icons/Done";

import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },

  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  TableRow: {
    background: "Orange",
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(15),
  },
  typography: {
    paddingTop: theme.spacing(4),
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(30),
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#3f51b5",
    },
    color: "inherit",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 615,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  TblHeader: {
    backgroundColor: "indigo",
    color: "white",
    fontSize: "11pt",
    fontFamily: "arial",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#2d8631",
    },
  },
  fabBlueExtend: {
    color: theme.palette.common.white,
    backgroundColor: "indigo",
    "&:hover": {
      backgroundColor: "#4b0082d4",
    },
  },
}));
const defaultState = {
  start_time: "07:30",
  date_init: Moment().format("YYYY-MM-DD"),
  date_end: Moment().format("YYYY-MM-DD"),
  classroom_id: "",
  end_time: "08:30",
};

function Row({
  index,
  capacity,
  onChange,
  onRemove,
  start_time,
  date_init,
  date_end,
  classroom_id,
  end_time,
  SchoolStorageID,
}) {
  return (
    <TableCourses
      end_time={end_time}
      date_init={date_init}
      date_end={date_end}
      onChange={onChange}
      index={index}
      onRemove={onRemove}
      SchoolStorageID={SchoolStorageID}
      start_time={start_time}
      classroom_id={classroom_id}
      capacity={capacity}
    />
  );
}

const IndividualClassesList = (props) => {
  const {
    individualCourse,
    saveIndividualCourse,
    SchoolStorageID,
    saveDisableButton,
    courseInfo,
    setCourseInfo,
    capacity,
  } = props;

  const [value, setValue] = React.useState(0);
  const [end, setEnd] = React.useState(false);

  useEffect(() => {
    let url = `/courses/${individualCourse}`;
    MethodGet(url)
      .then((res) => {
        setCourseInfo(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [individualCourse]);

  const handleChange = (event) => {
  };

  const [rows, setRows] = useState([defaultState]);

  const handleOnChange = (index, name, value) => {
    const copyRows = [...rows];
    copyRows[index] = {
      ...copyRows[index],
      [name]: value,
    };
    setRows(copyRows);
  };

  const handleOnRemove = (index) => {
    const copyRows = [...rows];
    copyRows.splice(index, 1);
    if (copyRows.length == 0) {
      Swal.fire({
        title: "Error",
        text: "Debes registrar al menos una clase",
        icon: "warning",
      });
      return;
    } else {
      setRows(copyRows);
    }
  };

  const handleOnAdd = () => {
    setRows(rows.concat(defaultState));
  };

  const handleOnEnd = () => {
    //saveCourses(courses);
    let i = 0;

    for (i = 0; i < rows.length; i++) {
      if (rows[i].classroom_id == "") {
        Swal.fire({
          title: "Error",
          text: "Aún no has seleccionado un salón para la clase " + (i + 1),
          icon: "warning",
        });
        return;
      }
      if (rows[i].date_init > rows[i].date_end) {
        Swal.fire({
          title: "Error",
          text:
            "La fecha inicio debe ser menor a la fecha final en la clase " +
            (i + 1),
          icon: "warning",
        });
        return;
      }
      if (rows[i].start_time >= rows[i].end_time) {
        Swal.fire({
          title: "Error",
          text:
            "La hora inicio debe ser menor a la hora final en la clase " +
            (i + 1),
          icon: "warning",
        });
        return;
      }
    }

    for (i = 0; i < rows.length; i++) {
      courseInfo.info.push(rows[i]);
    }

    saveDisableButton(true);
    //console.log(courses[index]);
    setEnd(true);
    toast.success(
      <div>
        <DoneIcon />
        El modulo {courseInfo.name} ha sido programado exitosamente
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    //console.log(courses);
  };

  const classes = useStyles();


  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        {end ? null : (
          <Grid
            item
            xs={2}
            xl={2}
            style={{ marginBottom: -20, marginLeft: -20 }}
          >
            <Grid item xs={2} xl={2}>
              <Fab
                className={classes.fabGreen}
                aria-label="add"
                size="medium"
                onClick={() => handleOnAdd()}
              >
                <Tooltip
                  title="Agregar Programacion"
                  aria-label="Agregar Programacion"
                >
                  <AddIcon size="large" />
                </Tooltip>
              </Fab>
            </Grid>
          </Grid>
        )}
        <TableContainer>
          <Table>
            {end ? null : (
              <TableHead>
                <TableRow className={classes.TableRow}>
                  <TableCell className={classes.TblHeader} align="center">
                    Clase
                  </TableCell>
                  <TableCell className={classes.TblHeader} align="center">
                    Fecha Inicio
                  </TableCell>
                  <TableCell className={classes.TblHeader} align="center">
                    Fecha Término
                  </TableCell>
                  <TableCell className={classes.TblHeader} align="center">
                    Hora De Inicio
                  </TableCell>
                  <TableCell className={classes.TblHeader} align="center">
                    Hora De Término
                  </TableCell>
                  <TableCell className={classes.TblHeader} align="center">
                    Salón
                  </TableCell>
                  <TableCell className={classes.TblHeader} align="center">
                    Eliminar
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {end
                ? null
                : rows.map((row, index) => (
                    <Row
                      {...row}
                      onChange={(name, value) =>
                        handleOnChange(index, name, value)
                      }
                      onRemove={() => handleOnRemove(index)}
                      SchoolStorageID={SchoolStorageID}
                      index={index}
                      capacity={capacity}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} xl={12}>
        <Grid item xs={2} xl={2}>
          {end ? null : (
            <Grid item xs={2} xl={2}>
              <Typography component="h1" variant="h4" align="center">
                <Box align="center">
                  <Fab
                    className={classes.fabBlueExtend}
                    aria-label="add"
                    size="medium"
                    onClick={() => handleOnEnd()}
                    variant="extended"
                  >
                    <DoneIcon size="large" />
                    <b> Terminar </b>
                  </Fab>
                </Box>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {end && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className={classes.TableRow}>
                <TableCell className={classes.TblHeader} align="center">
                  Fecha Inicio
                </TableCell>
                <TableCell className={classes.TblHeader} align="center">
                  Fecha Término
                </TableCell>
                <TableCell className={classes.TblHeader} align="center">
                  Hora De Inicio
                </TableCell>
                <TableCell className={classes.TblHeader} align="center">
                  Hora De Término
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {end &&
                rows.map((row, key) => (
                  <TableRow>
                    <TableCell align="center">{row.date_init}</TableCell>
                    <TableCell align="center">{row.date_end}</TableCell>
                    <TableCell align="center">{row.start_time}</TableCell>
                    <TableCell align="center">{row.end_time}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default IndividualClassesList;
