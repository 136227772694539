import React, { useReducer } from 'react';
import BankAccountsContext from './BankAccountsContext'
import BankAccountsReducer from './BankAccountsReducer';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../Config/Services";
import { ADD_NEW_BANK_ACCOUNT, CURRENT_BANK_ACCOUNT, DELETE_BANK_ACCOUNT, GET_ALL_BANK_ACCOUNTS,
    SHOW_ERRORS_API, UPDATE_BANK_ACCOUNT } from "../../types";
import Swal from "sweetalert2";

const BankAccountsState = ({children}) => {
  // estado inicial
  const initialSate = {
    accounts: [],
    account: {},
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(BankAccountsReducer, initialSate);

  //obtener la lista de cuentas bancarias
  const GetBankAccounts = () => {
    sessionStorage.removeItem('account');
    let url = `bankAccounts`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_ALL_BANK_ACCOUNTS,
        payload: response.data.data,
      });
    });
  };

  //función para crear una nueva cuenta bancaria
  const AddBankAccount = (account) => {
    //console.log(school, image, municipality, state);
    const formData = new FormData();
		formData.append("account_number", account.account_number);
		formData.append("owner_name", account.owner_name);
		formData.append("bank_id", account.bank_id);
    //console.log(school.image);
    let url = "/bankAccounts";
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_BANK_ACCOUNT,
        });
      })
      .catch((error) => {
        Swal.fire({
					title: 'Error',
          text: error.response.data.error,
    			icon: 'error'
				});
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //función para seleccionar una cuenta bancaria
  const SaveCurrentBankAccount = (account) => {
    sessionStorage.setItem('account',JSON.stringify(account));
    dispatch({
      type: CURRENT_BANK_ACCOUNT,
      payload: account,
    });
  };

  //función para editar una cuenta bancaria
  const UpdateBankAccount = (account) => {
    const formData = new FormData();
    formData.append("account_number", account.account_number);
    formData.append("owner_name", account.owner_name);
    formData.append("bank_id", account.bank_id);
	formData.append("_method", "PATCH");
    let url = `bankAccounts/${account.id}`;
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_BANK_ACCOUNT,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_ERRORS_API,
          payload: error.response.data.error,
        });
      });
  };

  //Eliminar una cuenta bancaria
  const DeleteBankAccount = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "La cuenta bancaria ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `bankAccounts/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_BANK_ACCOUNT,
            payload: id,
          });
        });
      }
    });
  };

  return (
    <BankAccountsContext.Provider
      value={{
        accounts: state.accounts,
        account: state.account,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        GetBankAccounts,
        AddBankAccount,
        SaveCurrentBankAccount,
        UpdateBankAccount,
        DeleteBankAccount,
      }}
    >
      {children}
    </BankAccountsContext.Provider>
  );
}

export default BankAccountsState;