import React, { useReducer } from 'react';
import InventoriesContext from './InventoriesContext'
import InventoriesReducer from './InventoriesReducer';
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../Config/Services";
import { ADD_NEW_INVENTORY, CURRENT_INVENTORY, DELETE_INVENTORY, GET_ALL_PRODUCTS_INVENTORIES,
    GET_ALL_INVENTORIES, UPDATE_INVENTORY, ADD_PRODUCT_INVENTORY } from "../../types";
import Swal from "sweetalert2";

const InventoriesState = ({children}) => {
  // estado inicial
  const initialSate = {
    inventories: [],
    products: [],
    inventory: {},
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(InventoriesReducer, initialSate);

  //obtener la lista de proveedores
  const GetInventories = (id) => {
    sessionStorage.removeItem('inventory');
    let url = `inventories/branch/${id}`;
    MethodGet(url).then((response) => {
        //console.log(response);
      dispatch({
        type: GET_ALL_INVENTORIES,
        payload: response.data.data,
      });
    });
  };

  const GetProductsInventory = (id) => {
    let url = `inventories/products/${id}`;
    MethodGet(url).then((response) => {
        //console.log(response);
      dispatch({
        type: GET_ALL_PRODUCTS_INVENTORIES,
        payload: response.data.data,
      });
    });
  };

  const AddInventory = (id) => {
    const formData = new FormData();
	formData.append("branch_office_id", id);
  Swal.fire({
    title: "¿Estas seguro?",
    text: "El inventario será iniciado",
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, iniciar",
    cancelButtonText: "No, cancelar",
  }).then((result) => {
    if (result.value) {
      let url = "/inventories";
      MethodPost(url, formData)
        .then((response) => {
          Swal.fire({
            title: "Inventario Creado",
            text: response.data.message,
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
          dispatch({
            type: ADD_NEW_INVENTORY,
          });
        })
        .catch((error) => {
          Swal.fire({
        title: 'Error',
              text: error.response.data.error,
          icon: 'error'
      });
          dispatch({
            payload: error.response.data.error,
          });
        });
    }
  });
  };

  const AddProductInventory = (inventory) => {
    // console.log(inventory,"inventoryyyyyyyyy");
  // const formData = new FormData();
	// formData.append("inventory_id", inventory.inventory_id);
	// formData.append("product_id", inventory.product_id);
	// formData.append("products", [inventory.products]);
  // console.log(formData,"la form dataaaaaaaa");
  let url = "/inventories/product";
  MethodPost(url, inventory)
    .then((response) => {
      Swal.fire({
        title: "Producto Agregado",
          text: response.data.message,
          icon: "success",
          timer: 400,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_PRODUCT_INVENTORY,
        });
      }).catch((error) => {
          Swal.fire({
        title: 'Error',
              text: error.response.data.error,
          icon: 'error'
      });
        dispatch({
          payload: error.response.data.error,
        });
      });
  };

  //función para seleccionar un salon
  const SaveCurrentInventory = (inventory) => {
    sessionStorage.setItem('inventory',JSON.stringify(inventory));
    dispatch({
      type: CURRENT_INVENTORY,
      payload: inventory,
    });
  };

  //función para editar un salon
  const UpdateInventory = (inventory) => {
    const formData = new FormData();
        formData.append("branch_office_id", inventory.branch_office_id);
    let url = `inventories/${inventory.id}`;
    MethodPost(url, formData)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_INVENTORY,
        });
      })
      .catch((error) => {
        Swal.fire({
					title: 'Error',
          text: error.response.data.error,
    			icon: 'error'
				});
        dispatch({
          payload: error.response.data.error,
        });
      });
  };

  //Eliminar Proveedor por id
  const DeleteInventory = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "El inventario ya no se podrá recuperar",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `inventories/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire("Eliminado", response.data.message, "success");
          dispatch({
            type: DELETE_INVENTORY,
            payload: id,
          });
        });
      }
    });
  };

  return (
    <InventoriesContext.Provider
      value={{
        inventories: state.inventories,
        inventory: state.inventory,
        products: state.products,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        GetInventories,
        AddInventory,
        SaveCurrentInventory,
        UpdateInventory,
        AddProductInventory,
        GetProductsInventory,
        DeleteInventory,
      }}
    >
      {children}
    </InventoriesContext.Provider>
  );
}

export default InventoriesState;