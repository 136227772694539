import React, { useState, useEffect, Fragment } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Tooltip,
  Hidden,
  Grid,
  Box,
  Fab,
  Typography,
  Container,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import InfoIcon from "@material-ui/icons/Info";

/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  Link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  Table: {
    background: "Orange",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
    marginRight: "-2%",
  },
  customWidth: {
    fontSize: "12px",
    maxWidth: 200,
    justifyContent: "center",
    padding: "5px",
  },
}));

const StocksSeller = (props) => {
  const classes = useStyles();

  //sucursales = state, saveStocks =funcion para guardar en el state;
  const [stocks, saveStocks] = useState([]);
  const [cargando, spinnerCargando] = useState(false);

  //query a la api
  useEffect(() => {
    let url = "/stocks";
    MethodGet(url)
      .then((res) => {
        saveStocks(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
        spinnerCargando(true);
      });
  }, []);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* titulo */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 100,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            className={classes.typography}
            component="h1"
            variant="h4"
          >
            {"Stock de Productos en mi Sucursal"}
          </Typography>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Clave", field: "clave" },
              { title: "Producto", field: "products_name" },
              { title: "Cantidad existente", field: "quantity" },
              {
                title: "Estatus",
                field: "quantity",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.quantity == 0 ? (
                        <p
                          style={{
                            color: "white",
                            background: "red",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Productos Agotados
                        </p>
                      ) : rowData.quantity <= 200 ? (
                        <p
                          style={{
                            color: "white",
                            background: "#ffc400",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Productos Por Agotarse
                        </p>
                      ) : rowData.quantity >= 201 ? (
                        <p
                          style={{
                            color: "white",
                            background: "#43a047",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Productos Suficientes En Existencia
                        </p>
                      ) : null}
                    </Fragment>
                  );
                },
              },
            ]}
            data={stocks}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Stock de productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Clave", field: "clave" },
              { title: "Producto", field: "products_name" },
            ]}
            data={stocks}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>
                        Cantidad Existente: <>{rowData.quantity}</>{" "}
                      </p>
                      <p>
                        Estatus:{" "}
                        {
                          <Fragment>
                            {rowData.quantity == 0 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "red",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align="center"
                              >
                                Productos Agotados
                              </p>
                            ) : rowData.quantity <= 200 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "#ffc400",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align="center"
                              >
                                Productos Por Agotarse
                              </p>
                            ) : rowData.quantity >= 201 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "#43a047",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align="center"
                              >
                                Productos Suficientes En Existencia
                              </p>
                            ) : null}
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Stock de productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </LayoutDashboard>
  );
};

export default StocksSeller;
