import { Grid, Typography, Hidden,Paper, } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2'
import { MethodPost } from '../../Config/Services';
import MethodGet from '../../Config/Services'
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import SelectBranch from '../Graphics/SelectBranch';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(2),
        //marginLeft: theme.spacing(-1),
        paddingBottom: theme.spacing(1),
        paddingInlineEnd: theme.spacing(1),
        paddingRight:theme.spacing(0),
        marginLeft:'-50px'
      },
	formControl: {
		margin: theme.spacing(1),
		width:'100%',
		heigth:'100%',
        maxHeight:400,
	},
    papercard: {
        padding: theme.spacing(2),
        margin:'auto',
        height:'570px',
        backgroundColor:'transparent',
        flexWrap: 'nowrap',
        display: 'relative',
        boxSizing: 'border-box',

    },
    containerGraphic:{
            width: '100%',
            height: '540px',
            boxSizing: 'border-box',
            position:'relative',
            display: 'absolute',
    },
    GraphicDetails: {
        display: 'flex',
        width: '100%',
        height: '100%',
        maxHeight: '450px',
        position:'absolute',
        boxSizing: 'border-box',
      },

}));

function GraphicStock() {

    const classes = useStyles();

    const [nameOffice, guardarSucursal] = useState({
		branch_office_id: '',
	});

    const detectarCambiosOffice = (e) => {
		guardarSucursal({ id_branch_office: e.target.value });
	};

    const data = {
        'branch_office_id': nameOffice.id_branch_office
    }

    const options = {
        maintainAspectRatio: false	// Don't maintain w/h ratio
    }
    const [chartData, setChartData] = useState({});

    const chart = () => {
        let stkQuantity = [];
        let stkProduct = [];
        //Query a la api 
        let url = '/graphics/stocks'
        MethodPost(url, data)
            .then(res => {
                for (const dataObj of res.data.data) {
                    stkQuantity.push(parseInt(dataObj.quantity));
                    stkProduct.push(String(dataObj.name))
                }
                //propiedades de la grafica
                setChartData({
                    labels: stkProduct,
                    datasets: [
                        {
                            label: 'Cantidad disponible',
                            data: stkQuantity,
                            backgroundColor: [
                                '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb',
                                '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784',
                                '#c62828', '#ad1457', '#6a1b9a', '#4527a0', '#283593',
                                '#1565c0', '#0277bd', '#00838f', '#00695c', '#64ffda',
                            ],
                            borderColor: [
                                '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb',
                                '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784',
                                '#c62828', '#ad1457', '#6a1b9a', '#4527a0', '#283593',
                                '#1565c0', '#0277bd', '#00838f', '#00695c', '#64ffda',
                            ],
                            borderDash: [
                                '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb',
                                '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784',
                                '#c62828', '#ad1457', '#6a1b9a', '#4527a0', '#283593',
                                '#1565c0', '#0277bd', '#00838f', '#00695c', '#64ffda',
                            ],
                            borderJoinStyle: [
                                '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb',
                                '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784',
                                '#c62828', '#ad1457', '#6a1b9a', '#4527a0', '#283593',
                                '#1565c0', '#0277bd', '#00838f', '#00695c', '#64ffda',
                            ],
                            pointBackgroundColor: [
                                '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb',
                                '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784',
                                '#c62828', '#ad1457', '#6a1b9a', '#4527a0', '#283593',
                                '#1565c0', '#0277bd', '#00838f', '#00695c', '#64ffda',
                            ],
                            pointHoverBackgroundColor:[
                                '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb',
                                '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784',
                                '#c62828', '#ad1457', '#6a1b9a', '#4527a0', '#283593',
                                '#1565c0', '#0277bd', '#00838f', '#00695c', '#64ffda',
                            ],
                            pointHoverBorderColor: [
                                '#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb',
                                '#64b5f6', '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784',
                                '#c62828', '#ad1457', '#6a1b9a', '#4527a0', '#283593',
                                '#1565c0', '#0277bd', '#00838f', '#00695c', '#64ffda',
                            ],
                        }
                    ]
                });

            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        chart();
    }, [nameOffice]);

    return (
        <div className={classes.container}>
            <Grid 
            container xs={12} sm={12} md={12} lg={12} 
            justify="center" 
            style={{ marginBottom:'15px'}}
            >
                <Hidden only={['md', 'lg','xl']} >
                    <Typography component="h4" variant="h4">
                        Stock por Sucursal
                    </Typography>
                </Hidden>
                <Hidden only={['xs', 'sm',]}>
                    <Typography component="h2" variant="h2">
                        Stock por Sucursal
                     </Typography>
                </Hidden>
            </Grid>
            <Paper className={classes.papercard}>
                <div className={classes.containerGraphic}>
                    <Grid container xs={12} sm={12} md={12} lg={12} spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <SelectBranch
					    	        detectarCambiosOffice={detectarCambiosOffice}
					            />
                            </FormControl>
                        </Grid>
                        <Grid container >
                            <Bar height={'400%'} data={chartData} options={options}  className={classes.GraphicDetails}/>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </div>
    )
}

export default GraphicStock