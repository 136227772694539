import React , {Fragment , useEffect , useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CommentSharp } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    contentWrapper:{
       display:'flex',
       position:'relative',
       alignItems:'center',
       color:'#fff'
    },
    contentLeft:{
       display:'block'
   },
    contentRigth:{
        marginLeft:'auto'
    },
    widgetHeading:{
        opacity:'.',
        fontWeight:'bold'
    },
    widgetSubheading:{
        opacity:'.6'
    },
    widgetNumber:{
        fontWeight:'bold',
        fontSize:'1.8rem',
        color: '#fff !important;'
    },
 }));

const BoxCommisionsGenerated = ({totalSales , month_end, month_start}) => {
    const classes = useStyles();
    
    const ComisionVendedor =  new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2
      }).format(totalSales);

    return (
        <Fragment>
            <div className={classes.contentWrapper}>
                <div className={classes.contentLeft}>
                  <div className={classes.widgetHeading}>
                    Comisiones
                  </div>
                  <div className={classes.widgetSubheading}>
                   Comisiones por ventas {month_start} - {month_end}
                  </div>
                </div>
                <div className={classes.contentRigth}>
                  <div className={classes.widgetNumber}>
                     <span> {ComisionVendedor} </span>
                  </div> 
                </div>
            </div>
        </Fragment>
    );
};

export default BoxCommisionsGenerated;