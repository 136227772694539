import React, { useState, useEffect, useContext, Fragment } from 'react';
import {
	Tooltip,
	Button,
	IconButton,
	InputLabel,
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ProductContext from '../../Reducers/Products/ProductContext';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	buttonProgress: {
		color: 'indigo',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		paddingLeft: theme.spacing(10),
		//marginBottom: theme.spacing(10),
	},
	typography: {
		margin: theme.spacing(1),
		//paddingLeft: theme.spacing(30),
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(9),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		marginBlockEnd: theme.spacing(1),
	},
	submit: {
		background: 'indigo',
		'&:hover': {
			background: '#5902CF',
		},
		color: 'white',
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	input: {
		display: 'none',
	},
	containerImageProfile: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-around',

	},
	PaperFormAdd: {
		background: 'white',
		color: 'black',
		boxShadow: '2px 4px 4px #cacaca',
	},
}));

export default function ModalUpdateProducts(props) {
	const classes = useStyles();
	const { handleClose, openImp, id } = props;
	const { register, errors, handleSubmit } = useForm({});
	const [loading, setLoading] = React.useState(false);
	const timer = React.useRef();

	React.useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	const ProductsList = useContext(ProductContext);
	const { products, success, UpdateProductsStock } = ProductsList;
	const [archivo, guardarArchivo] = useState('');
	const leerArchivo = (e) => {
		guardarArchivo(e.target.files[0]);
	};

	const cancelar = () => {
		handleClose();
	}

	useEffect(() => {
		if (success) {
			handleClose();
			setLoading(false);
		}
	}, [success]);

	const onSubmit = async (data, e) => {
		e.preventDefault();
		data.file = archivo;
		data.id = id;
		setLoading(true);

		if (
			archivo !== ""
		) {
			//spinnerCargando(false);
			//enviamos la data a la funcion que se encarga de guardar los nuevos registros
			UpdateProductsStock(data);
		} else {
			setLoading(false);
			toast.error(<div><ErrorIcon />Introduce un archivo de excel válido</div>, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			})
		}

	}

	return (
		<Fragment>
			<Dialog open={openImp} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Actualizar Stock</DialogTitle>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogContent>
						<InputLabel>Selecciona El Archivo A Actualizar</InputLabel>
						<input
							className={classes.input}
							id="icon-button-file"
							type="file"
							name="file"
							onChange={leerArchivo}
						/>
						<label htmlFor="icon-button-file">
							<IconButton color="primary" aria-label="upload picture" component="span" >
								<Tooltip style={{ fontSize: '70px' }} title="seleccionar archivo excel a importar" aria-label="seleccionar imagen">
									<CloudUploadIcon />
								</Tooltip>
							</IconButton>
						</label>
					</DialogContent>
					<DialogActions>
						<Button onClick={cancelar} color="secondary" variant="contained">
							Cancelar
						</Button>
						<Button type="submit" color="primary" disabled={loading} variant="contained">
							{loading ? "Actualizando" : "Guardar"}
							{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</Fragment>
	)
}
