import React, { useState, useEffect } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import Spinner from "../../Complements/Spinner";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Swal from "sweetalert2";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MethodGet, { MethodPut } from "../../Config/Services";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
} from "@material-ui/core";
/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
import GridItem from "../../Components/Grid/GridItem";
import GridContainer from "../../Components/Grid/GridContainer";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardAvatar from "../../Components/Card/CardAvatar";
import CardBody from "../../Components/Card/CardBody";
import EditPhoto from "../../Complements/Image/FormClass/EditPhoto.svg";
import AddFile from "../../Complements/Image/FormClass/AddFile.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(10),
    //marginBottom: theme.spacing(10),
  },
  typography: {
    margin: theme.spacing(1),
    //paddingLeft: theme.spacing(30),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    background: "indigo",
    "&:hover": {
      background: "#5902CF",
    },
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  containerImageProfile: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  PaperFormAdd: {
    background: "white",
    color: "black",
    boxShadow: "2px 4px 4px #cacaca",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

const BusinessRulesEdit = (props) => {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);



  //console.log(nameTypeClients)
  const { id } = props.match.params;
  const [rules, saveRule] = useState({});
  const { descuento, nombre } = rules;

  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    let url = `/rules/${id}`;

    MethodGet(url)
      .then((res) => {
        saveRule(res.data.data[0]);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // const {idbranch } = rulesbrnach
  const onSubmit = (data, e) => {
    e.preventDefault();

    const dat = {
      ...data,
    };

    let url = `/rules/${id}`; //cambiar la url completa
    MethodPut(url, dat)
      .then((res) => {
        Swal.fire({
          title: "Regla Editada Exitosamente",
          text: res.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        props.history.push("/Reglas");
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };


  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 70 }}
        >
          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardHeader color="primary">
                <h2 className={classes.cardTitleWhite}>
                  Editar Regla de Negocio
                </h2>
                <p className={classes.cardCategoryWhite}>
                  Completa correctamente los datos
                </p>
              </CardHeader>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Nombre"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      defaultValue={nombre}
                      error={!!errors.name}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El Nombre es requerido",
                        },
                        minLength: {
                          value: 3,
                          message: "Minimo 3 caracteres",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximo 255 caracteres",
                        },
                      })}
                    />
                    <p>{errors?.name?.message}</p>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="discount"
                      label="Porcentaje de Descuento"
                      name="discount"
                      autoFocus
                      defaultValue={descuento}
                      type="number"
                      error={!!errors.discount}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "El porcentaje de descuento es requerido",
                        },
                      })}
                    />
                    <p>
                      {errors.discount &&
                        "El porcentaje de descuento es Requerido"}
                    </p>
                  </Grid>
                </Grid>

                <Grid container spacing={3} justify="center">
                  <Grid item spacing={3} sm={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.submit}
                    >
                      ACTUALIZAR
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
            <Card profile>
              <CardBody profile>
                <img
                  src={EditPhoto}
                  alt="Foto Editar"
                  width="20%"
                  height="20%"
                />
              </CardBody>
            </Card>
          </GridItem>
          {/**
           * identificar Codigo de FORMS
           */}
          {/**
           * identificar Codigo de FORMS
           */}
        </Grid>
      </form>
    </LayoutDashboard>
  );
};

export default BusinessRulesEdit;
