import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import ProductsAdmin from './ProductsAdmin';
import ProductsSeller from './ProductsSeller';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';

export default function ProductIndex() {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		case '1':
			component = <ProductsAdmin />
			break;
		case '2': 
			component = <ProductsSeller id={usuario.id_branch_office} />
      break;
    case '3': 
			component = <ProductsAdmin />
			break;
		  default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
