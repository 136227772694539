import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ReactExport from "react-export-excel";
import ProductContext from "../../Reducers/Products/ProductContext";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonExcel: {
    margin: theme.spacing(1),
    backgroundColor: "green",
    borderColor: "#green",
    color: "#fff",
    "&:hover": {
      backgroundColor: "green",
      borderColor: "#green",
    },
  },
  fill: { patternType: "solid", fgColor: { rgb: "FFD3D3D3" } },
}));

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ProductsExportExcel = (props) => {
  const classes = useStyles();
  const { id } = props;

  //Extraer los productos del state inicial
  const ProductFunctions = useContext(ProductContext);
  const { getProductsDataExcel, productsDataExcel } = ProductFunctions;
  useEffect(() => {
    getProductsDataExcel(id);
  }, [id]);
  let prod = [];
  productsDataExcel.map((p) => {
    prod.push({
      id: p.id,
      name: p.name,
      codigo: p.tag,
      descripcion: p.description,
      /* ubicacion: p.ubication, */
      precio_publico: p.price,
      precio_costo: p.price_purchase,
      cantidad: p.quantity,
      type: p.type,
    });
  });
  return (
    <ExcelFile
      element={
        <Button
          variant="outlined"
          startIcon={<CloudDownloadIcon />}
          fullWidth
          color="secondary"
          style={{ margin: 5 }}
        >
          Exportar P. Existentes
        </Button>
      }
      filename={"Productos Sucursal Existentes"}
    >
      <ExcelSheet data={prod} name="Productos">
        <ExcelColumn label="codigo" value="codigo" className={classes.fill} />
        <ExcelColumn label="producto" value="name" className={classes.fill} />
        <ExcelColumn
          label="descripcion"
          value="descripcion"
          className={classes.fill}
        />
        {/* <ExcelColumn
          label="ubicacion"
          value="ubicacion"
          className={classes.fill}
        /> */}
        <ExcelColumn
          label="precio_publico"
          value="precio_publico"
          className={classes.fill}
        />
        <ExcelColumn
          label="precio_costo"
          value="precio_costo"
          className={classes.fill}
        />
        <ExcelColumn
          label="cantidad"
          value="cantidad"
          className={classes.fill}
        />
        <ExcelColumn label="tipo" value="type" className={classes.fill} />

        {/* <ExcelColumn label="imagen" value="image" className={classes.fill} /> */}
        <ExcelColumn label="id" value="id" className={classes.fill} />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ProductsExportExcel;