import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../../Context/autenticacion/authContext';
import Schedule from './Schedule';
import ScheduleSeller from './ScheduleSeller';
import AlertNotAuthorized from '../../Complements/AlertNotAuthorized';

export default function ScheduleIndex() {
   //Extraer la informacion de autenticación
   const authContext = useContext(AuthContext);
   const { usuario, usuarioAutenticado } = authContext;

   useEffect(() => {
    usuarioAutenticado();
  }, []);

  let component;
  if(usuario){
    switch (usuario.type) {
		case '1':
			component = <Schedule />
			break;
		case '2':
			component = <ScheduleSeller />
			break;
		case '3':
			component = <ScheduleSeller />
			break;
		default:
			component = <AlertNotAuthorized mensaje={"No tienes los suficientes permisos para ver esta página"}/>
			break;
    }
  }
  return (
    <Fragment>
      {component}
    </Fragment>
  )
}
