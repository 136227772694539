import React, { Fragment } from "react";

import Select from "react-select";
export default function ChangeTypeSale(props) {
  const typeProducts = [
    { value: 1, name: "Codigo de barras" },
    { value: 2, name: "Seleccionar Productos" },
  ];
  const detectarCambiosTypeProduct = (value) => {
    props.detectarCambiosTypeProduct(value);
  };
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  return (
    <Fragment>
      <Select
        onChange={(value) => detectarCambiosTypeProduct(value)}
        className="basic-single"
        classNamePrefix="select"
        styles={selectStyles}
        name="client"
        placeholder="Selecciona una opción"
        options={typeProducts.map((typeProduct) => {
          let attribute = {
            label: typeProduct?.name,
            value: typeProduct?.value,
          };
          return attribute;
        })}
      />
    </Fragment>
  );
}
