import {
  GET_ALL_SUBCATEGORIES,
  ADD_NEW_SUBCATEGORY,
  CURRENT_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
  DELETE_SUBCATEGORY,
  GET_SUBCATEGORIES_CATEGORY,
} from "../../types";

export default function SubcategoryReducer(state, action) {
  switch (action.type) {
    case GET_ALL_SUBCATEGORIES:
      return {
        ...state,
        subcategories: action.payload.data,
        success: false,
        ErrorsAPI: [],
      };

    case ADD_NEW_SUBCATEGORY:
      return {
        ...state,
        subcategories: action.payload,
        success: true,
        ErrorsAPI: [],
      };
    case CURRENT_SUBCATEGORY:
      sessionStorage.setItem("subcategory", action.payload);
      return {
        ...state,
      };
    case UPDATE_SUBCATEGORY:
      return {
        ...state,
        subcategories: action.payload,
        success: true,
        ErrorsAPI: [],
      };

    case DELETE_SUBCATEGORY:
      return {
        ...state,
        subcategories: state.subcategories.filter(
          (subcategory) => subcategory.id !== action.payload
        ),
      };

    case GET_SUBCATEGORIES_CATEGORY:
      return {
        ...state,
        subcategories: action.payload,
        success: false,
        ErrorsAPI: [],
      };

    default:
      return state;
  }
}