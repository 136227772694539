import React, { useState, useEffect, Fragment } from 'react';
/**Importar configuracion axios */
import clienteAxios from '../../Config/Axios';
import { Grid } from '@material-ui/core';
import Select from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';

const useSelectProducts = (stateInicial) => {    //state del custom hook
    const [ state , actualizarstate ] = useState(stateInicial.stateInicial);
    const [ products , saveProducts ] = useState([]);
    const id = stateInicial.branchID;
    useEffect(() => {
		const getProducts = async () => {
			await clienteAxios
			.get(`/productsBranch/${id}`)
			.then((res) => {
				saveProducts(res.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
		};
		getProducts();
	}, []);
	
  const productArray = products.map(product=>{
    return {key:product.id , value:product.id , label: product.tag + ' - ' + product.name}
});
    const SelectProduct = () =>(
        <Fragment>
          <Grid item  sm={12} md={12} xs={12}>
          <Select
             autofocus
             value={state}
             placeholder="Selecciona un producto"
             options={productArray}
             onChange={ value => actualizarstate(value)}
            //  maxMenuHeight={100} 

            />
            {/* { state === '' ? <p style={{color:'red'}}>El campo producto es requerido</p> : null} */}
          </Grid>
          
        </Fragment>
    )

    return [ state , SelectProduct ];
};

export default useSelectProducts
