import React, { useState, useEffect, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { 
	Grid, 
	Button, 
	TextField, 
	FormControl
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from '../../Complements/Spinner';
import Swal from "sweetalert2";
/**Importar Select State */
import SelectBank from "../SelectOptions/SelectBank";
/**Habilitar redirecciones */
import { withRouter } from "react-router-dom";
import MethodGet from "../../Config/Services";
import BankAccountContext from "../../Reducers/BankAccounts/BankAccountsContext";
import { useForm } from "react-hook-form";
/** Importamos los componentes de estilos del formulario lado izquierdo FORM y ado Derecho Image y descripcion* */
import GridItem from "../../Components/Grid/GridItem";
import Card from "../../Components/Card/Card";
import CardHeader from "../../Components/Card/CardHeader";
import CardBody from "../../Components/Card/CardBody";
import EditPhoto from "../../Complements/Image/FormClass/EditPhoto.svg"

const useStyles = makeStyles((theme) => ({
	submit: {
		background: 'indigo',
		'&:hover': {
			background: '#5902CF',
		},
		color: 'white',
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
	cardCategoryWhite: {
		color: "rgba(255,255,255,.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0"
	  },
	  cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none"
	  },

}));

function EditBankAccount(props) {
    const classes = useStyles();
    
  const formBankAccountContext = useContext(BankAccountContext);
  
  const { ErrorsAPI, success, UpdateBankAccount } = formBankAccountContext;
  const [cargando, spinnerCargando] = useState(false);
  
      const [bankId, saveBank] = useState('');
      const [bankAccount, saveBankAccount] = useState({});

  const { register, errors, handleSubmit } = useForm();

  //Extraer valores de branch(destructuracion)
	const { owner_name, account_number } = bankAccount;

	  const detectarCambiosBancos = (e) => {
		saveBank(e.target.value);
	  };

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de escuelas
    if (success) {
      //console.log("Retorna");
      props.history.push("/CuentasBancarias"); 
    }
    //eslint-disable-next-line
  }, [success]);

  //obtenemos la cuenta bancaria de session storage
  let AccountStorageID = JSON.parse(sessionStorage.getItem("account"));

  useEffect(() => {
    let url = `/bankAccounts/${AccountStorageID}`;

    MethodGet(url)
        .then((res) => {
            saveBankAccount(res.data.data);
            saveBank(res.data.data.bank_id);
            spinnerCargando(true);
        })
        .catch((error) => {
            console.log(error);
            spinnerCargando(true);
        });
}, []);

  //funcion que agrega una escuela
  const onSubmit = (data, e) => {
    e.preventDefault();
    data.bank_id = bankId;
    data.id = AccountStorageID;
    if (
		bankId !== ""
	  ) {
            //enviamos la data a la funcion que se encarga de guardar los nuevos registros
            UpdateBankAccount(data);
        } else {
            Swal.fire({
                title: 'Error',
                text: "Todos los campos son Obligatorios",
                icon: 'error'
            });
        }
  };

	//spinner de carga
	if (!cargando) return <Spinner />;

	return (
		<LayoutDashboard>
			<div onSubmit={handleSubmit(onSubmit)} >
				<form  noValidate>
				<Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 70 }}
          >
            {/**
             * identificar Codigo de FORMS
             */}
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
						<Card>
							<CardHeader color="primary">

            					<h2 className={classes.cardTitleWhite}>Editar Cuenta Bancaria</h2>
            					<p className={classes.cardCategoryWhite}>Completa correctamente los datos</p>
            				</CardHeader>
							<CardBody>

					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="account_number"
								label="Numero de Cuenta"
								name="account_number"
                                defaultValue={account_number}
                                type="number"
								autoComplete="account_number"
								autoFocus
								error={!!errors.account_number}
								inputRef={register({
									required: {
										value: true,
										message: 'El Numero De Cuenta Es Requerido',
									},
									minLength: {
										value: 16,
										message: 'Minimo 16 caracteres',
									},
									maxLength: {
										value: 16,
										message: 'Maximo 16 caracteres',
									},
								})}
							/>
							<p>{errors?.account_number?.message}</p>
						</Grid>

						<Grid item xs={12} sm={4}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="owner_name"
								label="Nombre Del Propietario"
								name="owner_name"
                                defaultValue={owner_name}
								autoComplete="owner_name"
								autoFocus
								error={!!errors.owner_name}
								inputRef={register({
									required: {
										value: true,
										message: 'El nombre del propietario es requerida',
									},
									minLength: {
										value: 4,
										message: 'Minimo 4 caracteres',
									},
									maxLength: {
										value: 255,
										message: 'Maximo 255 caracteres',
									},
									pattern: {
										value: /^[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]+[A-Záéíóúñ ]$/i,
										message: 'Unicamente carácteres alfabéticos',
									},
								})}
							/>
							<p>{errors?.owner_name?.message}</p>
						</Grid>

						<Grid item xs={12} sm={4} style={{marginLleft:-2, marginTop:8}}>
                		  <FormControl variant="outlined" className={classes.formControl} fullWidth >
                		    <SelectBank  
                            bankId={bankId}
                            detectarCambiosBancos={detectarCambiosBancos} />
                		  </FormControl>
                		</Grid>
					</Grid>
					<Grid container spacing={3} justify="center">
						<Grid item spacing={3} xs={12} sm={12} md={6}>
							<Button
								type="submit"
								fullWidth
								variant="outlined"
								className={classes.submit}
							>
								ACTUALIZAR
							</Button>
						</Grid>
					</Grid>
					</CardBody>
					</Card> 
					<Card profile >
        			    	<CardBody profile>
								<img src={EditPhoto} alt="Foto Editar" width="35%" height="35%"/>
        			    	</CardBody>
        			  	</Card>
					</GridItem>
					{			/**
 * identificar Codigo de FORMS
 */}
  {			/**
 * identificar Codigo de FORMS
 */}
        			  	
				</Grid>

				</form>

			</div>
		</LayoutDashboard>
	);
}

export default withRouter(EditBankAccount);
