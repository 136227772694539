import React, { useState, useEffect, Fragment } from "react";
import clienteAxios from "../../Config/Axios";
import Select from "react-select";

export default function SelectBussinesRule(props) {
  const [error, guardarError] = useState(true);
  const [tipos, saveType] = useState([]);
  let id = props.idbranch;

  useEffect(() => {
    const getOffices = async () => {
      await clienteAxios
        .get(`/rulesperbranch/${id}`)
        .then((res) => {
          saveType(res.data);
        })
        .catch((error) => {
          console.log(error);
        });

      if (props.nameTypeClients) {
        guardarError(false);
      }
    };
    getOffices();
  }, []);

  const detectarCambiosRuleBranch = (selectedOption) => {
    if (selectedOption) {
      guardarError(false);
    }
    props.detectarCambiosRuleBranch(selectedOption); // Enviamos la opción seleccionada
  };

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  return (
    <Fragment>
      <Select
        className="basic-single"
        classNamePrefix="select"
        fullWidth
        styles={selectStyles}
        onChange={detectarCambiosRuleBranch}
        options={tipos.map((tipo) => ({
          label: `${tipo.nombre}--${tipo.descuento}%`,
          value: tipo.id,
        }))}
        placeholder="Selecciona un descuento"
      />
      
      {/* {error ? (
        <p style={{ color: "red" }}>
          El descuento es un campo requerido
        </p>
      ) : null} */}
    </Fragment>
  );
}
