import React from 'react';
import './Spinner.css';

function Spinner() {
	return (
		<div className="div_contenedor">
			<div className="div_centrado">
				<div className="preloader"></div>
			</div>
		</div>
	);
}
export default Spinner;
