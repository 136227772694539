import React, {useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Enlace from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import ResetPasswordsContext from '../../Reducers/ResetPasswords/ResetPasswordsContext';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Enlace color="inherit" href="https://www.digital-pineapple.com.mx" style={{color: "#3f51b5"}}>
        By Digital-Pineapple
      </Enlace>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const UpdatePassword = (props) => {
  const classes = useStyles();

  const { resetPassword, setUserPassword } = useContext(ResetPasswordsContext);
  //recuperamos el token de la url
  const { token } = useParams();

  const [state, seState] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });

  const handleChangeData = (e) => {
    seState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.history.push("/");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al login
    if (setUserPassword) {
      props.history.push("/login");
    }
    //eslint-disable-next-line
  }, [setUserPassword]);

  //pasamos la data para el cambio de contraseña
  const handleResetPassword = (e) => {
    e.preventDefault();
    const data = { ...state, token: token };
    resetPassword(data);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Nueva Contraseña
          </Typography>
          <form className={classes.form} onSubmit={handleResetPassword}>
            <TextField
              variant="outlined"
              margin="normal"
              type="email"
              required
              fullWidth
              id="email"
              label="Correo Electronico"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => handleChangeData(e)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              type="password"
              required
              fullWidth
              id="password"
              label="Nueva Contraseña"
              name="password"
              autoComplete="password"
              autoFocus
              onChange={(e) => handleChangeData(e)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              type="password"
              required
              fullWidth
              id="password_confirmation"
              label="Confirmar Contraseña"
              name="password_confirmation"
              autoComplete="password_confirmation"
              autoFocus
              onChange={(e) => handleChangeData(e)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Guardar Nueva Contraseña
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  to={"/login"}
                  variant="body2"
                  style={{ color: "#3f51b5" }}
                >
                  Recordaste tu contraseña? Iniciar Sesión
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default UpdatePassword;