import { ADD_NEW_INVENTORY, CURRENT_INVENTORY, DELETE_INVENTORY, GET_ALL_PRODUCTS_INVENTORIES, 
    GET_ALL_INVENTORIES, UPDATE_INVENTORY, ADD_PRODUCT_INVENTORY } from "../../types";

export default function InventoriesReducer(state, action) {

switch (action.type) {
case GET_ALL_INVENTORIES: 
    return{
        ...state,
        inventories: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case GET_ALL_PRODUCTS_INVENTORIES: 
    return{
        ...state,
        products: action.payload,
        success:false,
        ErrorsAPI: [],
    }

case ADD_NEW_INVENTORY:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
    }

case ADD_PRODUCT_INVENTORY:
    return{
            ...state,
            success: true,
            ErrorsAPI: []
    }

case CURRENT_INVENTORY:
    return{
        ...state,
        inventory:action.payload,
    }

case UPDATE_INVENTORY:
    return{
        ...state,
        success: true,
        ErrorsAPI: []
    }

case DELETE_INVENTORY:
    return{
        ...state,
        inventories: state.inventories.filter(inventory => inventory.id !== action.payload)
    }

default:
    return state; 
}
}