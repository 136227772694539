import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextField from "@material-ui/core/TextField";
import { Grid, Box, Zoom, Fab, } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';

/**Importar componentes de Iconos */
import DoneIcon from "@material-ui/icons/Done";
import { makeStyles } from "@material-ui/core/styles";
import DatePickerComponent from './DatePicker';
/** Importar Moment */
import Moment from 'moment';
import { useForm } from "react-hook-form";
import FormControl from '@material-ui/core/FormControl';
import SelectClassroom from '../SelectOptions/SelectClassroom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  TableRow: {
    background: 'Orange'
    },
  container: {
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(30),
  },
  typography: {
    paddingTop: theme.spacing(4),
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(30),
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(9),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#3f51b5",
    },
    color: "inherit",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 615,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  iconButton: {
    "& > svg": {
      margin: theme.spacing(2),
    },
  },
  fabDelete: {
		color: theme.palette.common.white,
		backgroundColor: '#d60000',
		'&:hover': {
		  backgroundColor: '#c30000',
		},
	  },
    btnPlus:{
      display:'absolute',
      //marginTop:'-42%',
      //marginLeft:'-45%',
      //zIndex:2,
      position: 'relative',
    },
}));

function TableCourses(props) {
  const classes = useStyles();

  const {
    classroom_id,
    SchoolStorageID,
    onRemove,
    onChange,
    start_time,
    end_time,
    date_end,
    date_init,
    key,
    capacity} = props;
  //Funcion para validaciones
  const { register, handleSubmit, errors } = useForm();

  return (
    <>
    <TableRow>
      <TableCell align="center">Clase{key}</TableCell>
      <TableCell align="center">
        <DatePickerComponent
          guardarFecha={e => onChange("date_init", e)}
          label={"Fecha Inicio"}
          error={!!errors.date}
          inputRef={register({
            required:{
              value:true,
              message: 'La Fecha es requerida',
            },
          })}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <p>{errors?.date?.message}</p>
      </TableCell>
      <TableCell align="center">
        <DatePickerComponent
          guardarFecha={e => onChange("date_end", e)}
          label={"Fecha Término"}
          error={!!errors.date}
          inputRef={register({
            required:{
              value:true,
              message: 'La Fecha es requerida',
            },
          })}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <p>{errors?.date?.message}</p>
      </TableCell>
      <TableCell align="center">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="start_time"
          label="Hora Inicial"
          name="start_time"
          autoComplete="name"
          type="time"
          value={start_time}
          onChange={e => onChange("start_time", e.target.value)}
          defaultValue="07:30"
          autoFocus
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          error={!!errors.start_time}
          inputRef={register({
            required:{
              value:true,
              message: 'La Hora de Inicio es requerido',
            },
          })}
        />
        <p>{errors?.start_time?.message}</p>
      </TableCell>
      <TableCell align="center">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="end_time"
          label="Hora De Término"
          name="end_time"
          autoComplete="name"
          type="time"
          value={end_time}
          onChange={e => onChange("end_time", e.target.value)}
          defaultValue="08:30"
          autoFocus
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          error={!!errors.end_time}
          inputRef={register({
            required:{
              value:true,
              message: 'La Hora De Término es requerida',
            },
          })}
        />
        <p>{errors?.end_time?.message}</p>
      </TableCell>
      <TableCell align="center">
		    <SelectClassroom
			   detectarCambiosClassrooms={value => onChange("classroom_id", value.key)}
			    SchoolStorageID={SchoolStorageID}
          capacity={capacity}
		    />
      </TableCell>
      <TableCell align="center">
          <Grid item xs={2} xl={2}
          >
				  	<Typography
				  		component="h1"
				  		variant="h4"
				  		align="center"
				  	>
				  		<Box align="right">
				  			<Fab
				  				className={classes.fabDelete}
				  				aria-label="add"
				  				size="small"
                  onClick={onRemove}
                  >
                  <DeleteIcon size="small"/>
				  			</Fab>
				  		</Box>
				  	</Typography>
				  </Grid>
      </TableCell>
    </TableRow>
    </>
  );
}

export default TableCourses;
