import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Box, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField";
import ImageProduct from "./ImageProduct";

function ListadoCantidadProductos(props) {
  const {
    venta,
    eliminarProductoVenta,
    handleChangeQuantity,
    index,
  } = props;
  const price = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(venta.price);

  const totalProduct = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
  }).format(venta.final_price);
  return (
    <TableRow>
      <TableCell align="center">{venta.tag}</TableCell>
      <ImageProduct width={"100%"} height={"100%"} producto={venta} />
      <TableCell align="center">
        <TextField
          type="text"
          value={venta.cantidad}
          onChange={(e) => handleChangeQuantity(e, index)}
          style={{ width: "25%", borderRadius: "15px" }}
          align="center"
        />
      </TableCell>
      <TableCell align="center">{venta.quantity}</TableCell>

      <TableCell align="center">{price}</TableCell>
      <TableCell align="center">
        <b style={{ fontSize: "12pt" }}>{totalProduct}</b>
      </TableCell>
      <TableCell align="center">
        <Box align="center">
          <IconButton
            color="secondary"
            aria-label="delete"
            size="medium"
            onClick={() => eliminarProductoVenta(venta.product_id)}
          >
            <CloseIcon style={{ fontSize: 40, marginTop: -5 }} />
          </IconButton>
        </Box>
        {/**ret
 *         <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<DeleteIcon />}
          onClick={() => eliminarProductoVenta(venta.product_id)}
        ></Button>
 */}
      </TableCell>
    </TableRow>
  );
}

export default ListadoCantidadProductos;
