import React, { useState, useEffect, Fragment, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import { Button, Tooltip, Hidden, IconButton } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ModalPayments from "./ModalPayments";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import VisibilityIcon from "@material-ui/icons/Visibility";
import fileDownload from "js-file-download";
import clienteAxios from "../../Config/Axios";
import { green } from "@material-ui/core/colors";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorIcon from "@material-ui/icons/Error";

/**Importar metodo Get */
import MethodGet, { MethodDelete } from "../../Config/Services";
/**Importar Sweetalert2 * */
import Swal from "sweetalert2";
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import InscriptionsContext from "../../Reducers/Inscriptions/InscriptionsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {},
  paper: {
    //padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(2),
    //marginBlockEnd: theme.spacing(1),
  },
  submit: {
    //margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    //margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
}));

const TableGraduateInscriptions = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    guardarMetodoPago({
      amount: "",
      change: "0",
      cash_income: "0",
      card_income: "0",
      transfer_income: "0",
    });
    setOpen(false);
    setDisabledButton(false);
  };

  //Leer los datos del Modal
  const obtenerInformacionPago = (e) => {
    guardarMetodoPago({
      ...informacionMetodoPago,
      [e.target.name]: e.target.value,
    });
  };
  const [informacionMetodoPago, guardarMetodoPago] = useState({
    amount: "0",
    change: "0",
    cash_income: "0",
    card_income: "0",
    transfer_income: "0",
    reference: "",
    invoice: "",
  });
  const [totalDescuento, guardarTotalDescuento] = useState(0);
  const [totalPagado, guardarTotalPagado] = useState(0);
  const [error, guardarError] = useState(false);

  const [image, setImage] = useState({
    urlPhoto: "https://pisouniversitario.com/template/images/page-404.jpg",
    image: "",
  });

  const [imageReference, setImageReference] = useState({
    urlPhotoReference:
      "https://pisouniversitario.com/template/images/page-404.jpg",
    imageReference: "",
  });

  const [data, setData] = useState({});
  const [couponInfo, saveCouponInfo] = useState("");

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangeImage = (e) => {
    setImage({
      ...image,
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const handleChangeImageReference = (e) => {
    setImageReference({
      ...imageReference,
      urlPhotoReference: URL.createObjectURL(e.target.files[0]),
      imageReference: e.target.files[0],
    });
  };

  const [disableButton, setDisabledButton] = useState(false);
  const [disableCoupon, setDisabled] = useState(true);
  const { name, SchoolStorageID } = props;

  const StateInscriptionsContext = useContext(InscriptionsContext);
  const {
    SaveCurrentInscription,
    DeleteInscription,
    inscriptions,
    GetGraduateInscriptions,
    success,
    AddInPayment,
  } = StateInscriptionsContext;

  //obtener los proveedores carga el componente
  useEffect(() => {
    GetGraduateInscriptions(SchoolStorageID);
  }, [success]);

  const SelectInscription = (inscriptionID) => {
    SaveCurrentInscription(inscriptionID);
  };

  const [bank_account, saveBankAccount] = useState("");

  const detectarCambiosBancos = (e) => {
    saveBankAccount(e.target.value);
  };

  let InscriptionStorageID = JSON.parse(sessionStorage.getItem("inscription"));

  const SelectInscriptionPayment = (inscriptionID, total, total_paid) => {
    SaveCurrentInscription(inscriptionID);
    guardarTotalDescuento(total);
    guardarTotalPagado(total_paid);
    setOpen(true);
  };
  //console.log(totalDescuento, 'inscription');
  const addPayment = (e) => {
    if (
      totalDescuento < informacionMetodoPago.amount &&
      informacionMetodoPago.cash_income < totalDescuento
    ) {
      toast.warn(
        <div>
          <ErrorIcon /> No puedes pagar mas del monto total
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.amount < 0) {
      toast.warn(
        <div>
          <ErrorIcon /> No puedes ingresar valores negativos
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.amount == 0) {
      toast.warn(
        <div>
          <ErrorIcon /> Debes ingresar al menos un monto de pago
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (bank_account == "") {
      toast.warn(
        <div>
          <ErrorIcon /> Debes seleccionar una cuenta bancaria
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (informacionMetodoPago.card_income > 0) {
      if (image.image == "") {
        toast.warn(
          <div>
            <ErrorIcon /> Debes seleccionar la imagen del ticket de pago por
            tarjeta
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      if (informacionMetodoPago.invoice == "") {
        toast.warn(
          <div>
            <ErrorIcon /> Debes introducir un folio válido
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
    } else if (informacionMetodoPago.transfer_income > 0) {
      if (imageReference.imageReference == "") {
        toast.warn(
          <div>
            <ErrorIcon /> Debes seleccionar la imagen del ticket de la
            transferencia
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      if (informacionMetodoPago.reference == "") {
        toast.warn(
          <div>
            <ErrorIcon /> Debes introducir una referencia válida
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
    }
    setDisabledButton(true);
    data.id = InscriptionStorageID;
    data.cash_income = informacionMetodoPago.cash_income;
    data.card_income = informacionMetodoPago.card_income;
    data.transfer_income = informacionMetodoPago.transfer_income;
    data.image = image.image;
    data.imageReference = imageReference.imageReference;
    data.change = informacionMetodoPago.change;
    data.reference = informacionMetodoPago.reference;
    data.bank_account = bank_account;
    data.invoice = informacionMetodoPago.invoice;
    //console.log(data);
    AddInPayment(data);
    handleClose();
  };

  const generarTicket = (id, name, course) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¿Desea Generar El Ticket De Pagos?",
      type: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Generar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.value) {
        let timerInterval;
        Swal.fire({
          title: "Generando",
          html: "El ticket se está generando",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          willClose: () => {
            clearInterval(timerInterval);
          },
        })
        //Cunsume API de Back-end en laravel para generar tiket de venta
        clienteAxios
          .get(`/ticketinscription/${id}`, { responseType: "blob" })
          .then((response) => {
            fileDownload(
              response.data,
              "Ticket De Inscripcion " + name + " Curso " + course + ".pdf"
            );
            guardarError(false);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              Swal.fire({
                icon: "error",
                title: "Algo sucedio",
                text: "No se cuenta con suficiente información para generar el reporte",
              });
            } else if (error.response.status === 422) {
              guardarError(true);
            }
          });
      }
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            className={classes.typography}
          >
            {"Diplomados   De La Escuela " + name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          align="right"
          style={{ marginRight: 30, marginTop: -30 }}
        >
            <Fab
              className={classes.fabGreen}
              aria-label="add"
              size="medium"
              href="/AgregarInscripcion"
            >
              <Tooltip
                title="Agregar Inscripcion"
                aria-label="Agregar Inscripcion"
              >
                <AddIcon size="large" />
              </Tooltip>
            </Fab>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Nombre del Cliente", field: "full_name" },
              { title: "Diplomado", field: "course_name" },
              { title: "Fecha De Inscripción", field: "date" },
              {
                title: "Total A Pagar",
                field: "total",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.total),
              },
              {
                title: "Total Pagado",
                field: "total_paid",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.total_paid),
              },
              {
                title: "Cambio",
                field: "change",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.change ? "$" + rowData.change : <p>No Aplica</p>}
                    </Fragment>
                  );
                },
              },
              {
                title: "Cupón",
                field: "coupon",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.coupon ? (
                        <p>
                          {rowData.coupon.code} - {rowData.coupon.discount}%
                        </p>
                      ) : (
                        <p>No Aplica</p>
                      )}
                    </Fragment>
                  );
                },
              },
              {
                title: "Estatus",
                field: "status",
                render: (rowData) => {
                  return (
                    <Fragment>
                      {rowData.status == 0 ? (
                        <p
                          style={{
                            color: "white",
                            background: "red",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Cancelado
                        </p>
                      ) : rowData.status == 1 ? (
                        <p
                          style={{
                            color: "white",
                            background: "#ffc400",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          No Liquidado
                        </p>
                      ) : rowData.status == 2 ? (
                        <p
                          style={{
                            color: "white",
                            background: "#43a047",
                            whiteSpace: "nowrap",
                            padding: "4px",
                          }}
                          align="center"
                        >
                          Liquidado
                        </p>
                      ) : null}
                    </Fragment>
                  );
                },
              },
            ]}
            data={inscriptions}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
              actionsColumnIndex: -1,
            }}
            actions={[
              (rowData) => ({
                icon: "delete",
                tooltip: "Cancelar Inscripción",
                onClick: () => DeleteInscription(rowData.id),
              }),
            ]}
            components={{
              Action: (props) => (
                <Fragment>
                  {/*
							<Link
								to={`EditarInscripcion`}
								style={{ color: '#3f51b5' }}
							>
								<Button
									variant="contained"
									style={{
										textTransform: 'none',
										background: 'white',
									}}
                                    onClick={ () => SelectInscription(props.data.id)}
									size="small"
								>
									<Tooltip
										title="Editar Inscripción"
										aria-label="Editar Inscripción"
									>
										<EditIcon style={{ color: 'blue' }} />
									</Tooltip>
								</Button>
							</Link>
							*/}
                  <Link to={`HistorialPagos`} style={{ color: "#3f51b5" }}>
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "none",
                        background: "white",
                      }}
                      onClick={() => SelectInscription(props.data.id)}
                      size="small"
                    >
                      <Tooltip
                        title="Ver Historial De Pagos"
                        aria-label="Ver Historial De Pagos"
                      >
                        <VisibilityIcon style={{ color: "indigo" }} />
                      </Tooltip>
                    </Button>
                  </Link>
                  {props.data.total > props.data.total_paid && (
                    <Button
                      variant="contained"
                      style={{ textTransform: "none", background: "white" }}
                      size="small"
                      type="submit"
                      onClick={() =>
                        SelectInscriptionPayment(
                          props.data.id,
                          props.data.total,
                          props.data.total_paid
                        )
                      }
                    >
                      <Tooltip title="Agregar Pago" aria-label="Agregar Pago">
                        <MonetizationOnIcon style={{ color: "indigo" }} />
                      </Tooltip>
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", background: "white" }}
                    size="small"
                    onClick={() =>
                      generarTicket(
                        props.data.id,
                        props.data.client_name,
                        props.data.course_name
                      )
                    }
                  >
                    <Tooltip
                      title="Generar ticket de pagos"
                      aria-label="Generar ticket de pagos"
                    >
                      <PictureAsPdf style={{ color: "#01579b" }} />
                    </Tooltip>
                  </Button>
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", background: "white" }}
                    size="small"
                    onClick={() => DeleteInscription(props.data.id)}
                  >
                    <Tooltip
                      title="Cancelar Inscripción"
                      aria-label="Cancelar Inscripción"
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Tooltip>
                  </Button>
                </Fragment>
              ),
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Inscripciones que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
          <ModalPayments
            open={open}
            setOpen={setOpen}
            saveCouponInfo={saveCouponInfo}
            couponInfo={couponInfo}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            totalDescuento={totalDescuento}
            totalPagado={totalPagado}
            informacionMetodoPago={informacionMetodoPago}
            image={image}
            obtenerInformacionPago={obtenerInformacionPago}
            addPayment={addPayment}
            handleChangeImage={handleChangeImage}
            disableButton={disableButton}
            detectarCambiosBancos={detectarCambiosBancos}
            imageReference={imageReference}
            handleChangeImageReference={handleChangeImageReference}
            disableCoupon={disableCoupon}
          />

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Grid>
      </Hidden>
      {/* tabla responsiva */}
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Nombre del Cliente", field: "full_name" },
              { title: "Diplomado", field: "course_name" },
            ]}
            data={inscriptions}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },
              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Fecha de Inscripcion: {rowData.data}</p>
                      <p>Total A Pagar: ${rowData.total}</p>
                      <p>Total Pagado: ${rowData.total_paid}</p>
                      <p>
                        Cambio:{" "}
                        {
                          <Fragment>
                            {rowData.change ? (
                              "$" + rowData.change
                            ) : (
                              <>No Aplica</>
                            )}
                          </Fragment>
                        }
                      </p>
                      <p>
                        Cupón:{" "}
                        {
                          <Fragment>
                            {rowData.coupon ? (
                              <>
                                {rowData.coupon.code} -{" "}
                                {rowData.coupon.discount}%
                              </>
                            ) : (
                              <>No Aplica</>
                            )}
                          </Fragment>
                        }
                      </p>
                      <p>
                        Estatus:
                        {
                          <Fragment>
                            {rowData.status == 0 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "red",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align="center"
                              >
                                Cancelado
                              </p>
                            ) : rowData.status == 1 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "#ffc400",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align="center"
                              >
                                No Liquidado
                              </p>
                            ) : rowData.status == 2 ? (
                              <p
                                style={{
                                  color: "white",
                                  background: "#43a047",
                                  whiteSpace: "nowrap",
                                  padding: "4px",
                                }}
                                align="center"
                              >
                                Liquidado
                              </p>
                            ) : null}
                          </Fragment>
                        }
                      </p>
                      <p>Opciones:</p>
                      <p>
                        {
                          <Fragment>
                            <Link to={`HistorialPagos`}>
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 30,
                                }}
                                onClick={() => SelectInscription(rowData.id)}
                                size="small"
                              >
                                <Tooltip
                                  title="Ver Historial De Pagos"
                                  aria-label="Ver Historial De Pagos"
                                >
                                  <VisibilityIcon style={{ color: "indigo" }} />
                                </Tooltip>
                              </IconButton>
                            </Link>
                            {rowData.total > rowData.total_paid && (
                              <IconButton
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  background: "white",
                                  marginLeft: 50,
                                }}
                                size="small"
                                type="submit"
                                onClick={() =>
                                  SelectInscriptionPayment(
                                    rowData.id,
                                    rowData.total,
                                    rowData.total_paid
                                  )
                                }
                              >
                                <Tooltip
                                  title="Agregar Pago"
                                  aria-label="Agregar Pago"
                                >
                                  <MonetizationOnIcon
                                    style={{ color: "indigo" }}
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                            <IconButton
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 50,
                              }}
                              size="small"
                              onClick={() =>
                                generarTicket(
                                  rowData.id,
                                  rowData.client_name,
                                  rowData.course_name
                                )
                              }
                            >
                              <Tooltip
                                title="Generar ticket de pagos"
                                aria-label="Generar ticket de pagos"
                              >
                                <PictureAsPdf style={{ color: "#01579b" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              variant="contained"
                              style={{
                                textTransform: "none",
                                background: "white",
                                marginLeft: 50,
                              }}
                              size="small"
                              onClick={() => DeleteInscription(rowData.id)}
                            >
                              <Tooltip
                                title="Cancelar Inscripción"
                                aria-label="Cancelar Inscripción"
                              >
                                <DeleteIcon style={{ color: "red" }} />
                              </Tooltip>
                            </IconButton>
                          </Fragment>
                        }
                      </p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Inscripciones que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
          <ModalPayments
            open={open}
            setOpen={setOpen}
            saveCouponInfo={saveCouponInfo}
            couponInfo={couponInfo}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            totalDescuento={totalDescuento}
            totalPagado={totalPagado}
            informacionMetodoPago={informacionMetodoPago}
            image={image}
            obtenerInformacionPago={obtenerInformacionPago}
            addPayment={addPayment}
            handleChangeImage={handleChangeImage}
            disableButton={disableButton}
            detectarCambiosBancos={detectarCambiosBancos}
            imageReference={imageReference}
            handleChangeImageReference={handleChangeImageReference}
            disableCoupon={disableCoupon}
          />

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Grid>
      </Hidden>
    </>
  );
};

export default TableGraduateInscriptions;
