import React, { useState, useEffect, useContext } from "react";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import ModalImage from "react-modal-image";
import {
  Hidden,
  Grid,
  Typography,
} from "@material-ui/core";
import ProductContext from "../../Reducers/Products/ProductContext";
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(11),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBlockEnd: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none",
  },
  typography: {
    margin: theme.spacing(0, 1, 2, 2),
  },
  // Estilo de tablas Container
  titleTbl: {
    //backgroundColor:'#8115CF',
    background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
    color: "white",
    fontSize: "large",
    height: "60px",
    borderRadius: "5px",
    paddingTop: "10px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  whiteSpace: "nowrap",

  btnPlus: {
    display: "absolute",
    marginTop: "10%",
    marginRight: "-5%",
    zIndex: 2,
    position: "relative",
  },
  IconArrowBack: {
    paddingTop: "8px",
    marginRight: "-2%",
  },
  customWidth: {
    fontSize: "12px",
    maxWidth: 200,
    justifyContent: "center",
    padding: "5px",
  },
}));

const ProductsSeller = ({id}) => {
  const classes = useStyles();
  const [cargando, spinnerCargando] = useState(false);
  const ProductsList = useContext(ProductContext);
  const { products, getProducts, success } = ProductsList;
  //query a la api
  useEffect(() => {
    getProducts(id);
    console.log(id);
    spinnerCargando(true);
  }, [success]);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      {/* titulo */}
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background: "linear-gradient(50deg, #3f51b5 33%, indigo 100%)",
            color: "white",
            marginTop: 100,
            borderRadius: 15,
            marginLeft: 25,
            marginRight: 25,
          }}
        >
          <Typography
            className={classes.typography}
            component="h1"
            variant="h4"
          >
            {"Productos de mi Sucursal"}
          </Typography>
        </Grid>
      </Grid>
      {/**
       * Se renderiza el titulo y los botones de las vista de tabla asi como el ajuste de mobile *-
       *
       */}
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginRight: 30, marginLeft: 30, marginTop: 10 }}
        >
          <MaterialTable
            title=""
            columns={[
              { title: "Clave", field: "tag" },
              {
                title: "Imagen",
                field: "image",
                render: (rowData) =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      alt="producto"
                    />
                  ) : (
                    <ModalImage
                      small={
                        "https://pisouniversitario.com/template/images/page-404.jpg"
                      }
                      large={
                        "https://pisouniversitario.com/template/images/page-404.jpg"
                      }
                      alt="producto"
                    />
                  ),
              },
              { title: "Nombre", field: "name" },
              { title: "Descripción", field: "description" },
              /* { title: "Ubicacion", field: "ubication" }, */
              { title: "Categoria", field: "categoria" },
              { title: "Subategoria", field: "subcategoria" },
              {
                title: "Precio P.",
                field: "price",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price),
              },
              {
                title: "Precio C.",
                field: "price_purchase",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price_purchase),
              },
              {
                title: "Precio M.",
                field: "price_retail",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price_retail),
              },
              {
                title: "Precio 1",
                field: "price1",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price1),
              },
              {
                title: "Precio 2",
                field: "price2",
                render: (rowData) =>
                  new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    minimumFractionDigits: 0,
                  }).format(rowData.price2),
              },
              { title: "Tipo", field: "type" },
              { title: "Cantidad", field: "quantity" },
            ]}
            data={products}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              header: {
                actions: "Opciones",
              },
              body: {
                emptyDataSourceMessage: "No Hay Productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginTop: 10 }}
        >
          <MaterialTable
            title={""}
            columns={[
              { title: "Clave", field: "tag" },
              {
                title: "Imagen",
                field: "image",
                render: (rowData) =>
                  rowData.image ? (
                    <ModalImage
                      small={rowData.image}
                      large={rowData.image}
                      alt="producto"
                    />
                  ) : (
                    <ModalImage
                      small={
                        "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png"
                      }
                      large={
                        "https://cdn.pngsumo.com/index-of-areaedu-wp-content-uploads-2016-02-default-png-600_600.png"
                      }
                      alt="producto"
                    />
                  ),
              },
            ]}
            data={products}
            options={{
              headerStyle: {
                backgroundColor: "none",
                color: "#000",
                fontSize: "16px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              },

              actionsColumnIndex: -1,
            }}
            detailPanel={[
              {
                tooltip: "Show",
                render: (rowData) => {
                  return (
                    <div
                      style={{
                        color: "black",
                        marginLeft: 50,
                      }}
                    >
                      <p>Nombre: {rowData.name}</p>
                      <p>Descripcion: {rowData.description}</p>
                      {/* <p>Ubicacion: {rowData.ubication}</p> */}
                      <p>Categoria: {rowData.categoria}</p>
                      <p>Subcategoria: {rowData.subcategoria}</p>
                      <p>Precio Publico: ${rowData.price}</p>
                      <p>Precio Costo: ${rowData.price_purchase}</p>
                      <p>Precio Menudeo: ${rowData.price_retail}</p>
                      <p>Precio 1: ${rowData.price1}</p>
                      <p>Precio 2: ${rowData.price2}</p>
                      <p>Tipo: {rowData.type}</p>
                      <p>Cantidad: {rowData.quantity}</p>
                    </div>
                  );
                },
              },
            ]}
            localization={{
              pagination: {
                labelDisplayedRows: "{from} - {to} de {count}",
                labelRowsSelect: "Columnas",
                firstTooltip: "Primera Pagina",
                previousTooltip: "Anterior",
                nextTooltip: "Siguiente",
                lastTooltip: "Ultima Página",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
              body: {
                emptyDataSourceMessage: "No Hay Productos que Mostrar",
                filterRow: {
                  filterTooltip: "Buscar",
                },
              },
            }}
          />
        </Grid>
      </Hidden>
    </LayoutDashboard>
  );
};

export default ProductsSeller;
