import React, { useContext, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ReactExport from "react-export-excel";
import ProductContext from "../../Reducers/Products/ProductContext";
import { Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonExcel: {
    margin: theme.spacing(1),
    backgroundColor: "green",
    borderColor: "#green",
    color: "#fff",
    "&:hover": {
      backgroundColor: "green",
      borderColor: "#green",
    },
  },
  fill: { patternType: "solid", fgColor: { rgb: "FFD3D3D3" } },
}));

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ProductsExportExcelStock = (props) => {
  const classes = useStyles();
  const { id } = props;

  //Extraer los productos del state inicial
  const ProductFunctions = useContext(ProductContext);
  const { getStockProductsDataExcel, productsDataExcel } = ProductFunctions;
  useEffect(() => {
    getStockProductsDataExcel(id);
  }, [id]);
  let prod = [];

  // const handleClick = () => {
  //   if (productsDataExcel.length === 0) {
  //     console.log("Está vacío!");
  //     setTimeout(() => {
  //       peti.current.click();
  //       getStockProductsDataExcel(id);
  //     }, 1500);
  //   }
  // };
  // console.log(peti, "esto es pety");

  productsDataExcel.map((p) => {
    prod.push({
      id: p.id,
      name: p.name,
      codigo: p.tag,
      cantidadsuma: 0,
    });
  });
  return (
    <>
      <Grid >
        <ExcelFile
          element={
            <Button
              variant="outlined"
              startIcon={<CloudDownloadIcon />}
              fullWidth
              color="secondary"
            >
              Exportar P. Existentes
            </Button>
          }
          filename={"Productos Sucursal"}
        >
          <ExcelSheet data={prod} name="Productos">
            <ExcelColumn
              label="codigo"
              value="codigo"
              className={classes.fill}
            />
            <ExcelColumn
              label="producto"
              value="name"
              className={classes.fill}
            />
            <ExcelColumn
              label="cantidadsuma"
              value="cantidadsuma"
              className={classes.fill}
            />
            <ExcelColumn label="id" value="id" className={classes.fill} />
          </ExcelSheet>
        </ExcelFile>
      </Grid>
    </>
  );
};

export default ProductsExportExcelStock;
