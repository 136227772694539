
import React, { useContext, useEffect } from "react";
import Select from "react-select";
import ClientsContext from "../../Reducers/Clients/ClientsContext";
import { InputLabel } from "@material-ui/core";

const SelectClient = (props) => {
  const { id_branch_office } = props;
  const { getclients, clients } = useContext(ClientsContext);
  useEffect(() => {
    getclients(id_branch_office);
  }, []);
  const detectarCambiosClient = (value) => {
    props.detectarCambiosClient(value);
  };
  const clientsArray = clients.map((client) => {
    const nameClient = `${client.name} ${client.first_lastname} ${client.second_lastname}`;
    return { value: client.id, label: nameClient };
  });
  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };
  return (
    <>
      <Select
        label="Selecciona un cliente"
        autofocus
        placeholder="Selecciona un Cliente"
        options={clientsArray}
        styles={selectStyles}
        onChange={(value) => detectarCambiosClient(value)}
      />
    </>
  );
};

export default SelectClient;
