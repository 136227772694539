import React, { useState, useEffect, useContext } from "react";

import useSelectClient from "../SelectOptions/SelectClient";
import SelectTypeSale from "../SelectOptions/SelectTypeSale";

import ChangeTypeSale from './ChangeTypeSale';
import useSelectProducts from '../SelectOptions/SelectProducts';
import useSelectProductsPerBrand from '../SelectOptions/SelectProductsPerBrand';
/**importar spinner */
import Spinner from "../../Complements/Spinner";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  Fab,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

import { useDebounce } from "use-debounce";
import ProductContext from "../../Reducers/Products/ProductContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import Swal from "sweetalert2";
import CropFreeIcon from "@material-ui/icons/CropFree";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(1),
  },
	formControl: {
		margin: theme.spacing(1),
		width:'100%'
	},
  InputInfoSales: {
    width: "94%",
  },
}));

const SelectsComponent = ({
  guardarProductoID,
  guardarClienteID,
  id,
  clienteID,
  productsBranded,
  productsWithoutBrand,
  productsSPA,
  saveProductsSPA,
  saveProductsWithoutBrand,
  saveProductsBranded,
  new_client_id,
  detectarCambiosTypeSale,
  type_sale,
  box,
  setBox,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [ productID , SelectProduct] = useSelectProductsPerBrand({stateInicial: '', branchID: id, typeSale: type_sale});
  const [client, SelectClient] = useSelectClient({
    stateInicial: new_client_id,
    branchID: id,
  });
  const [cargando, spinnerCargando] = useState(false);
  const [barcode, setBarCode] = useState("");
  const [debouncedBarcode] = useDebounce(barcode, 500);
  const ProductContextList = useContext(ProductContext);
  const { getSaleProductsPerBrand, success, products } = ProductContextList;
  const classes = useStyles();

  const handleChange = (event) => {
    setBox(event.target.value);
  };

  //cliente
  useEffect(() => {
    spinnerCargando(true);
    guardarClienteID(client);
  }, [client, new_client_id]);
  
  useEffect(() => {
    getSaleProductsPerBrand(id, type_sale);
  }, [type_sale]);

  const handleChangeCodeBar = (event) => {
    setBarCode(event.target.value);
  };

  useEffect(() => {
    filterProductExist();
    // eslint-disable-next-line
  }, [debouncedBarcode, productID]);

  const filterProductExist = () => {
    if (debouncedBarcode != "" || productID) {
      //console.log(box, 'el bots')
      var product = [];
      if(box == 1 && debouncedBarcode == ""){
        return;
      }
      if(box == 1)
      {
        product = products.filter((p) => p.tag == debouncedBarcode);
      } else {
        product = products.filter((p) => p.id == productID.value);
      }


      if (!product.length) {
        Swal.fire({
          title: "¡Lo sentimos!",
          text: "El Código de barras no coincide con ningún producto de la lista",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
        setBarCode("");
        return;
      }

      if (clienteID == undefined) {
        Swal.fire({
          title: "Antes de continuar",
          text: "Selecciona un cliente",
          icon: "error",
          timer: 2500,
          showConfirmButton: false,
        });
        setBarCode("");
        return;
      }

      product[0].value = product[0].id;
      guardarProductoID(product[0]);
      let productoResultado = {};
      productoResultado.description = product[0].description;
      productoResultado.image = product[0].image;
      productoResultado.name = product[0].name;
      productoResultado.price = product[0].price;
      productoResultado.price_purchase = product[0].price_purchase;
      productoResultado.quantity = product[0].quantity;
      productoResultado.tag = product[0].tag;
      productoResultado.value = product[0].value;
      productoResultado.type = Number(product[0].type);
      var position = -1;
      if(productoResultado.type === 1 && productsWithoutBrand.length !== 0)
      {
        position = productsWithoutBrand
          .map(function (e) {
            return e.product_id;
          })
          .indexOf(product[0].id);
      }
      if(productoResultado.type === 2 && productsBranded.length !== 0)
      {
        position = productsBranded
          .map(function (e) {
            return e.product_id;
          })
          .indexOf(product[0].id);
      }
      if(productoResultado.type === 3 && productsSPA.length !== 0)
      {
        position = productsSPA
          .map(function (e) {
            return e.product_id;
          })
          .indexOf(product[0].id);
      }
      if (position == -1 && box == 1) {
        //generar una llave unica para cada producto
        productoResultado.product_id = product[0].id;
        //generar cantidad productos por adquirir
        productoResultado.cantidad = 1;
        //generar precio final por producto
        productoResultado.final_price =
          productoResultado.cantidad * Number(product[0].price);
        //guardarlo en el state
        if(productoResultado.type === 1)
        {
          saveProductsWithoutBrand([...productsWithoutBrand, productoResultado]);
        }
        if(productoResultado.type === 2)
        {
          saveProductsBranded([...productsBranded, productoResultado]);
        }
        if(productoResultado.type === 3)
        {
          saveProductsSPA([...productsSPA, productoResultado]);
        }
      } else if(box == 1) {
        if(productoResultado.type === 1)
        {
          let quantity_product = productsWithoutBrand[position].cantidad;
          let new_quantity_product = (quantity_product += 1);
          if (product[0].quantity < new_quantity_product) {
            Swal.fire({
              title: "¡Lo sentimos!",
              text: "No quedán mas productos en stock",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            setBarCode("");
            return;
          }
          productsWithoutBrand[position].cantidad = new_quantity_product;
          productsWithoutBrand[position].final_price =
          productsWithoutBrand[position].cantidad * Number(product[0].price);
          saveProductsWithoutBrand([...productsWithoutBrand]);
        }
        if(productoResultado.type === 2)
        {
          let quantity_product = productsBranded[position].cantidad;
          let new_quantity_product = (quantity_product += 1);
          if (product[0].quantity < new_quantity_product) {
            Swal.fire({
              title: "¡Lo sentimos!",
              text: "No quedán mas productos en stock",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            setBarCode("");
            return;
          }
          productsBranded[position].cantidad = new_quantity_product;
          productsBranded[position].final_price =
          productsBranded[position].cantidad * Number(product[0].price);
          saveProductsBranded([...productsBranded]);
        }
        if(productoResultado.type === 3)
        {
          let quantity_product = productsSPA[position].cantidad;
          let new_quantity_product = (quantity_product += 1);
          if (product[0].quantity < new_quantity_product) {
            Swal.fire({
              title: "¡Lo sentimos!",
              text: "No quedán mas productos en stock",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            setBarCode("");
            return;
          }
          productsSPA[position].cantidad = new_quantity_product;
          productsSPA[position].final_price =
          productsSPA[position].cantidad * Number(product[0].price);
          saveProductsSPA([...productsSPA]);
        }
      }

      setBarCode("");
    }
  };

  if (!cargando) return <Spinner />;

  return (
    <Grid
      spacing={4}
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ paddingTop: "2%" }}
      className={classes.root}
    >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            spacing={1}
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <Grid
              item
              container
              xs={1}
              sm={1}
              md={1}
              justify="center"
              alignContent="center"
              alignItems="center"
            >
              <Box>
                <Fab disabled style={{ fontSize: 100, color:'white', backgroundColor:'indigo' }} >
                  <MonetizationOnOutlinedIcon style={{ fontSize: '100px',marginTop:-5 }}/>
                </Fab>
              </Box>
            </Grid>{" "}
            <Grid
              item
              container
              xs={11}
              sm={11}
              md={11}
              justify="center"
              alignContent="center"
              alignItems="center"
            >
              <Box>
                <Typography component="subtitle1" variant="h4">
                  Selecciona Datos Para La Venta
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            justify="center"
            alignContent="center"
            alignItems="center"
          >
            <FormControl variant="outlined" className={classes.formControl}>	
              <SelectTypeSale 
              detectarCambiosTypeSale={detectarCambiosTypeSale}
              />
						</FormControl>
            <SelectClient className={classes.InputInfoSales} />
            <ChangeTypeSale
              box={box}
              handleChange={handleChange} 
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            justify="center"
            alignContent="center"
            alignItems="center"
          >
          {box == 1 ?
          <>
            <TextField
              placeholder="Usa el lector de codigos de barra"
              variant="outlined"
              required
              id="barcode"
              label="Código De Barras"
              name="barcode"
              value={barcode}
              onChange={handleChangeCodeBar}
              autoComplete="barcode"
              autoFocus
              error={!!errors.barcode}
              className={classes.InputInfoSales}
              inputRef={register({
                required: {
                  value: true,
                  message: "El código de barras es requerido",
                },
                minLength: {
                  value: 4,
                  message: "Minimo 4 caracteres",
                },
                maxLength: {
                  value: 45,
                  message: "Maximo 45 caracteres",
                },
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CropFreeIcon />
                  </InputAdornment>
                ),
              }}
            />
            <p>{errors?.barcode?.message}</p>{" "}
          </>
            :
            box == 2 &&
            <>
			        <SelectProduct id={id} />
            </>
          }
          </Grid>
    </Grid>
  );
};

export default SelectsComponent;
