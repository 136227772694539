import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {Link} from 'react-router-dom';
//COMPONENTES PARA EL MODAL
import Modal from '@material-ui/core/Modal';
import { useForm } from 'react-hook-form';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
///Importacion de TABS
import 'react-tabs/style/react-tabs.css';

/**Componentes Tabla */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { Button, Tooltip, Grid, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    table: {
        minWidth: 650,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(18),
        paddingBottom: theme.spacing(4),
        paddingInlineEnd: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBlockEnd: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
        background: "#3537DB",
        "&:hover": {
          //you want this to be the same as the backgroundColor above
          background: "#5902CF"
      },
      color: 'white'
      },
      submit2: {
        margin: theme.spacing(3, 0, 2),
        background: "#f44336",
        "&:hover": {
          //you want this to be the same as the backgroundColor above
          background: "#e53935"
      },
      color: 'white',
      
      },
    input: {
        display: 'none',
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paperModal: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    formControl: {
		margin: theme.spacing(1),
		width:'100%'
    },
    h2: {
        background: '#394ED1',
        color: 'white'
    }
}));
const name_client = [
    { title: 'Jimena Tovar Hernandez' },
    { title: 'Jesus' },
]
const name_course = [
    { title: 'Uñas Acrilicas' },
]
const name_method = [
    { title: 'BBVA Bancomer' },
    { title: 'Santander' },
    { title: 'Scotiabank' },
    { title: 'Banco Azteca' },
    { title: 'Banjercito' },
    { title: 'CitiBanamex' },
    { title: 'Afirme' },
    { title: 'Banorte' },
    { title: 'Farmacias del Ahorro' },
    { title: 'Farmacias Guadalajara' },
    { title: 'HSBC' },
    { title: 'Telecomm' },
    { title: 'Oxxo' },
    { title: 'Efectivo' },
]
function MisInscripciones() {
    const classes = useStyles();
    const { register, handleSubmit, errors } = useForm();
    const [open, setOpen] = React.useState(false);
    
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    {/**Este es para abrir el modal de agregar cliente */ }
    const [openaddClient, setOpenaddClient] = useState(false);

    const handleOpenaddClient = () => {
        setOpenaddClient(true);
    };

    const handleCloseaddClient = () => {
        setOpenaddClient(false);
    };
    const onSubmit = (data, e) => {
        e.preventDefault();
    };
    return (
        <Fragment>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow >
                            <TableCell align="center" style={{color:"#0d47a1"}} >Clave</TableCell>
                            <TableCell align="center" style={{color:"#0d47a1"}} >Nombre</TableCell>
                            <TableCell align="center" style={{color:"#0d47a1"}} >CURSO</TableCell>
                            <TableCell align="center" style={{color:"#0d47a1"}} >TIPO INSCRIPCION</TableCell>
                            <TableCell align="center" style={{color:"#0d47a1"}} >PAGO INICIAL</TableCell>
                            <TableCell align="center" style={{color:"#0d47a1"}} >PENDIENTE</TableCell>
                            <TableCell align="center" style={{color:"#0d47a1"}} >TOTAL</TableCell>
                            <TableCell align="center" style={{color:"#0d47a1"}} >METODO DE PAGO</TableCell>
                            <TableCell align="center" style={{color:"#0d47a1"}} >Opciones</TableCell>
                            <TableCell align="center" style={{color:"#0d47a1"}} >ABONAR</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center"  >--</TableCell>
                            <TableCell align="center"  >--</TableCell>
                            <TableCell align="center"  >--</TableCell>
                            <TableCell align="center"  >--</TableCell>
                            <TableCell align="center"  >--</TableCell>
                            <TableCell align="center"  >--</TableCell>
                            <TableCell align="center"  >--</TableCell>
                            <TableCell align="center"  >--</TableCell>
                            <TableCell align="center"  >
                        <Fragment>
                            <Link to={`Editar_VentaInscripcion/`}>
                                <Button
                                    style={{ textTransform: 'none' }}
                                    size='small'
                                >
                                    <Tooltip title="Editar Venta" aria-label="Editar Venta">
                                        <EditIcon style={{ color: 'blue' }} />
                                    </Tooltip>
                                </Button>
                            </Link>  
                            <Link to={`Detalle_Inscripcion/`}>
                                <Button
                                    style={{ textTransform: 'none' }}
                                    size='small'
                                >
                                    <Tooltip title="Detalle de Venta" aria-label="Detalle de Venta">
                                        <VisibilityIcon style={{ color: 'indigo' }} />
                                    </Tooltip>
                                </Button>
                            </Link>              
                                <Button
                                    style={{ textTransform: 'none' }}
                                    size='small'
                                >
                                    <Tooltip title="Eliminar Venta" aria-label="Eliminar Venta">
                                        <DeleteIcon style={{ color: 'red' }} />
                                    </Tooltip>
                                </Button>
                        </Fragment>
                            </TableCell>
                            <TableCell align="center">
                                <Fragment>
                                
								<Button
									type="button"
									fullWidth
									color="primary"
									className={classes.submit2}
									onClick={handleOpen}
								>
									<label>Abonar a Inscripción</label>
								</Button>

								<Modal
									aria-labelledby="transition-modal-title"
									aria-describedby="transition-modal-description"
									className={classes.modal}
									open={open}
									closeAfterTransition
									BackdropComponent={Backdrop}
									BackdropProps={{
										timeout: 500,
									}}
								>
									<Fade in={open}>
										<div className={classes.paperModal}>
											<Typography>
												<Box align="right">
													<Fab aria-label="cancel" size="small">
														<CloseIcon onClick={handleClose} />
													</Fab>
												</Box>
												<h2
													id="transition-modal-title"
													align="Center"
													className={classes.h2}
												>
													<hr /> Abonar
													<hr />
												</h2>
											</Typography>

											<form onSubmit={handleSubmit(onSubmit)} noValidate>
												<Grid Container spacing={2}>
													<Grid item xs={12}>
														
														<Autocomplete 
															id="combo-box-demo"
															fullWidth
															options={name_client}
															getOptionLabel={(option) => option.title}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label="Nombre de Alumno"
																	variant="outlined"
																/>
															)}
														/>
													</Grid>
                                                    <Grid item xs={12}>
														
														<Autocomplete style={{marginTop: 16}}
															id="combo-box-demo"
															fullWidth
															options={name_course}
															getOptionLabel={(option) => option.title}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label="Nombre de Curso"
																	variant="outlined"
																/>
															)}
														/>
													</Grid>
                                                    <Grid>
														
														<TextField style={{marginTop: 15}}
															variant="outlined"
															margin="normal"
															required
															fullWidth
															id="debs_to_pay"
															label="Saldo Deudor"
															name="debs_to_pay"
															autoComplete="name"
															autoFocus
															
														/>
														<p>{errors?.debs_to_pay?.message}</p>
													</Grid>
                                                    <Grid item xs={12}>
														
														<Autocomplete style={{marginTop: 2}}
															id="combo-box-demo"
															fullWidth
															options={name_method}
															getOptionLabel={(option) => option.title}
															renderInput={(params) => (
																<TextField
																	{...params}
																	label="Metodos de Pago"
																	variant="outlined"
																/>
															)}
														/>
													</Grid>
													<Grid>
														
														<TextField style={{marginTop: 13}}
															variant="outlined"
															margin="normal"
															required
															fullWidth
															id="reference"
															label="Referencia"
															name="reference"
															autoComplete="name"
															autoFocus
															error={!!errors.reference}
															inputRef={register({
																required: {
																	value: true,
																	message: 'La referencia es requerida',
																},
																maxLength: {
																	value: 45,
																	message: 'Maximo 45 caracteres',
																},
															})}
														/>
														<p>{errors?.reference?.message}</p>
													</Grid>

													<Grid>
														
														<TextField style={{marginTop: 2}}
															variant="outlined"
															margin="normal"
															required
															fullWidth
															id="quantity"
															label="Cantidad"
                                                            name="quantity"
                                                            type="number"
															autoComplete="name"
															autoFocus
															error={!!errors.quantity}
															inputRef={register({
																required: {
																	value: true,
																	message: 'La Cantidad es requerida',
																},
																maxLength: {
																	value: 5,
																	message: 'Maximo 5 caracteres',
																},
															})}
														/>
														<p>{errors?.quantity?.message}</p>
													</Grid>

													<Grid item xs={12}>
														<Button
															type="submit"
															fullWidth
															variant="outlined"
															className={classes.submit}
														>
															<label>GUARDAR</label>
														</Button>
													</Grid>
												</Grid>
											</form>
										</div>
									</Fade>
								</Modal>
							 
                                </Fragment>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    );
}

export default MisInscripciones;