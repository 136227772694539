import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../Context/autenticacion/authContext";
import LayoutDashboard from "../../Components/Layout/LayoutDashboard";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Paper,
  Container,
  Tooltip,
  Button,
  TextField,
  Typography,
  IconButton,
  InputLabel,
  FormControl,
  Hidden,
} from "@material-ui/core";
import BoxMySales from "./seller/BoxMySales";
import BoxTotalSale from "./seller/BoxTotalSale";
import BoxCommisionsGenerated from "./seller/BoxCommisionsGenerated";
import ChartMySales from "./seller/ChartMySales";
import TableInfoSales from "./seller/TableInfoSales";
import TableRecentComissionsBranch from "./seller/TableRecentComissionsBranch";
import TableRecentPaymentsBranch from "./seller/TableRecentPaymentsBranch";
import TableSellersComissions from "./seller/TableSellersComissions";
import MethodGet from "../../Config/Services";

/**importar spinner */
import Spinner from "../../Complements/Spinner";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    marginTop: "80px",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(15),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
  },
  widgetContent: {
    display: "relative",
    height: "80px",
    marginBottom: "30px !important",
    //background:'linear-gradient(70deg, #3f51b5 30%, indigo 100%)',
    background: "linear-gradient(to right, #3f51b5 15%, indigo 55%) !important",
    padding: "16px",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export default function AdminComissions(props) {
  const classes = useStyles();

  const [cargando, spinnerCargando] = useState(false);
  //Extraer la informacion de autenticación
  const authContext = useContext(AuthContext);
  const { usuario } = authContext;

  const [branch, guardarBranch] = useState({});
  const { id } = props.match.params; //Se toma el ID de la Sucursal para mostrar las comisiones

  //Extraer valores de branch(destructuracion)
  const { name, direction, number_phone, state_id, municipality_id } = branch;

  const [noSales, saveNoSales] = useState("");
  const [totalSales, saveTotalSales] = useState("");
  const [commisionRate, saveCommisionRate] = useState({});
  const [comissions, saveComissions] = useState("");

  //obtener los campos de la sucursal
  useEffect(() => {
    let url = `/branches/${id}`;
    MethodGet(url)
      .then((res) => {
        guardarBranch(res.data.data);
        spinnerCargando(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const APIQuery = async () => {
      let url = `SalesWeekBranchCount/${id}`;
      MethodGet(url).then((response) => {
        saveNoSales(response.data.countSalesWeekSeller);
        saveTotalSales(response.data.totalComissions);
        saveCommisionRate(response.data.commisionRate);
        saveComissions(response.data.comissions);
      });
    };
    APIQuery();
  }, []);

  //  spinner de carga
  if (!cargando) return <Spinner />;

  return (
    <LayoutDashboard>
      <main>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          justify="center"
          style={{ marginBottom: "15px", marginTop: 70 }}
        >
          <Hidden only={["md", "lg", "xl"]}>
            <Typography
              component="h4"
              variant="h4"
              style={{ marginLeft: "35px", alignItems: "center" }}
            >
              Sucursal {name}
            </Typography>
          </Hidden>
          <Hidden only={["xs", "sm"]}>
            <Typography component="h2" variant="h2">
              Sucursal {name}
            </Typography>
          </Hidden>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={classes.widgetContent}>
              <BoxMySales noSales={noSales} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={classes.widgetContent}>
              <BoxTotalSale comissions={comissions} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={classes.widgetContent}>
              <BoxCommisionsGenerated
                totalSales={totalSales}
                commisionRate={commisionRate}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <TableRecentComissionsBranch id={id} />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <TableSellersComissions id={id} />
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <TableRecentPaymentsBranch id={id} />
            </Paper>
          </Grid>
        </Grid>
      </main>
    </LayoutDashboard>
  );
}
