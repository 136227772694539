import React, { useReducer } from "react";
import SubCategoryContext from "./SubCategoryContext";
import MethodGet, {
  MethodDelete,
  MethodPost,
  MethodPut,
} from "../../Config/Services";
import {
  GET_ALL_SUBCATEGORIES,
  ADD_NEW_SUBCATEGORY,
  CURRENT_SUBCATEGORY,
  UPDATE_SUBCATEGORY,
  DELETE_SUBCATEGORY,
  GET_SUBCATEGORIES_CATEGORY,
} from "../../types";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import SubCategoryReducer from "./SubCategoryReducer";

const SubcategoryState = ({ children }) => {
  // estado inicial
  const initialSate = {
    subcategories: [],
    errors: [],
    ErrorsAPI: [],
    success: false,
  };

  // dispatch que ejecuta las funciones
  const [state, dispatch] = useReducer(SubCategoryReducer, initialSate);

  //funcion para obtener las categorias
  const getSubcategories = () => {
    let url = `/subcategories`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_ALL_SUBCATEGORIES,
        payload: res.data,
      });
    });
  };

  //función para crear
  const addSubcategories = (subcategory) => {
    let url = "/subcategories";
    MethodPost(url, subcategory)
      .then((res) => {
        Swal.fire({
          title: "Buen trabajo",
          text: res.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: ADD_NEW_SUBCATEGORY,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  //función para seleccionar un proyecto a editar
  const saveCurrentSubcategory = (subcategory) => {
    dispatch({
      type: CURRENT_SUBCATEGORY,
      payload: subcategory,
    });
  };

  //función para editar
  const updateSubcategory = (subcategory) => {
    let url = `subcategories/${subcategory.id}`;
    MethodPut(url, subcategory)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_SUBCATEGORY,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
        });
      });
  };

  //funcion para eliminar un
  const deleteSubcategory = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar",
    }).then((result) => {
      if (result.value) {
        ///Eliminar en la Api
        let url = `subcategories/${id}`;
        MethodDelete(url).then((res) => {
          Swal.fire("Eliminado", res.data.message, "success");
          dispatch({
            type: DELETE_SUBCATEGORY,
            payload: id,
          });
        });
      }
    });
  };

  //SUBCATEGORIAS
  const getSubcategoriesCategory = (CategoryStorageID) => {
    let url = `subcategoriescategory/${CategoryStorageID}`;
    MethodGet(url).then((res) => {
      dispatch({
        type: GET_SUBCATEGORIES_CATEGORY,
        payload: res.data.data,
      });
    });
  };

  return (
    <SubCategoryContext.Provider
      value={{
        subcategories: state.subcategories,
        ErrorsAPI: state.ErrorsAPI,
        success: state.success,
        getSubcategories,
        addSubcategories,
        saveCurrentSubcategory,
        updateSubcategory,
        deleteSubcategory,
        getSubcategoriesCategory,
      }}
    >
      {children}
    </SubCategoryContext.Provider>
  );
};

export default SubcategoryState;
