import React, { useEffect, useState, useRef } from 'react'
import MethodGet from '../../Config/Services';
import ReactExport from "react-export-excel";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { Fab, Tooltip } from '@material-ui/core';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const MoreSaledProds = ({ id, onDownloadComplete }) => {
    const [productsDataExcel, setProductsDataExcel] = useState([]);
    const [cargando, spinnerCargando] = useState(false);
    const excelFileRef = useRef(null);
    const handleDownload = () => {
        if (excelFileRef.current) {
          excelFileRef.current.download();  // Descargar el archivo Excel
          if (onDownloadComplete) onDownloadComplete();  // Llamar a callback 
        }
      };
    useEffect(() => {
        let url = `/moresaled/${id}`;
        MethodGet(url)
        .then((res) => {
            setProductsDataExcel(res.data);
            spinnerCargando(true);
        })
        .catch((error) => {
            spinnerCargando(true);
        });
    }, [])

    let prod = productsDataExcel.map((p) => ({
        name: p.name,
        codigo: p.tag,
        cantidad: p.current_stock, 
        saled: p.total_quantity_sold, 
    }));

  return (
    <ExcelFile
      element={
        <Fab
            variant="outlined"
            startIcon={<TrendingUpIcon />}
            fullWidth
            color="primary"
            style={{ margin: 5 }}
        >
          <Tooltip
            title={"Productos más vendidos "}
            aria-label={"Productos más vendidos "}
          >
            <TrendingUpIcon size="large" />
          </Tooltip>
        </Fab>
      }
    filename={"ProductosMasVendidos"}
    >
      <ExcelSheet data={prod} name="Productos">
        <ExcelColumn
          label="tag"
          value="codigo"
          isBold
        />
        <ExcelColumn
          label="name"
          value="name"
          isBold
        />
        <ExcelColumn
          label="stock_disponible"
          value="cantidad"
          isBold
        />
        <ExcelColumn
          label="cantidad_vendida"
          value="saled"
          isBold
        />
      </ExcelSheet>
    </ExcelFile>
  );
}

export default MoreSaledProds;
