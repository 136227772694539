import React, { Fragment, useState, useEffect, useContext } from "react";
/**Importar configuracion axios */
import clienteAxios from "../../Config/Axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SchedulesContext from "../../Reducers/Schedules/SchedulesContext";

const animatedComponents = makeAnimated();
const useSelectSchedule = (props) => {
  //state del custom hook
  const [error, guardarError] = useState(true);

  let id = props.SchoolStorageID;
  const { getsschedule, schedules } = useContext(SchedulesContext);
  
  useEffect(() => {
    getsschedule(id);
  }, [id]);

  const detectarCambiosSchedule = (value) => {
    if (value.key) {
      guardarError(false);
    }
    props.detectarCambiosSchedule(value);
  };
  const schedulesArray = schedules.map((schedule) => {
    return {
      
      key: schedule.id,
      value: schedule.id,
      label:
      "curso: " +
      schedule.course_name +
      " , costo: $ " +
      schedule.cost +
      " F. Inicio: " +
      schedule.date +
      " F. Fin: " +
      schedule.date_end,
    };
  });
  return (
    <Fragment>
      {props.scheduleID ? (
        <Select
          autofocus
          placeholder="Programaciones"
          fullWidth
          components={animatedComponents}
          options={schedulesArray}
          onChange={(value) => detectarCambiosSchedule(value)}
          value={props.scheduleID}
        ></Select>
      ) : (
        <Select
          autofocus
          placeholder="Programaciones"
          fullWidth
          components={animatedComponents}
          options={schedulesArray}
          onChange={(value) => detectarCambiosSchedule(value)}
        ></Select>
      )}

      {error ? (
        <p style={{ color: "red" }}>El Curso es un Campo Requerido</p>
      ) : null}
    </Fragment>
  );
};

export default useSelectSchedule;
