import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Grid } from "@material-ui/core";
import { Link } from 'react-router-dom';
import LocalOffer from '@material-ui/icons/LocalOffer';
import { Button, Tooltip } from '@material-ui/core';
/**Importar metodo Get */
import MethodGet, { MethodDelete } from '../../Config/Services';
/**importar spinner */
import Spinner from '../../Complements/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(18),
    paddingBottom: theme.spacing(4),
    paddingInlineEnd: theme.spacing(2),
    },
}));

export default function ComissionsSellers(props) {
  const classes = useStyles();

  //sucursales = state, guardarBranch =funcion para guardar en el state;
	const [user, saveUser] = useState([]);
	const [comissions, saveComissions] = useState('');
	const [cargando, spinnerCargando] = useState(false);
    const id = props.id;
  //query a la api
	useEffect(() => {
		let url = `comissionsInfo/${id}`;
		MethodGet(url)
			.then((res) => {
				saveUser(res.data.user);
				saveComissions(res.data.comissions);
				spinnerCargando(true);
			})
			.catch((error) => {
				console.log(error);
				spinnerCargando(true);
			});
    }, [id]);
    
    //  spinner de carga
	if (!cargando) return <Spinner />;

  return (
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              component="img"
              alt={user.name}
              height="140"
              image={user.image ? user.image : 'https://pisouniversitario.com/template/images/page-404.jpg'}
              title={user.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {user.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Tipo De Usuario: {user.type == 3 ? 'SubAdministrador' : 'Vendedor'}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Comisiones Sin Cobrar: ${comissions}
              </Typography>
            </CardContent>
          </CardActionArea>
          <Grid container justify="center" xs={12} xl={12}>
                <Grid item justify="center">
                <CardActions>
            <Link
		    	to={`/DetalleComision/${user.id}`}
		    	style={{ color: 'indigo',backgroundColor:'indigo',
        }}
		    >
		    	<Button
		    		variant="contained"
		    		style={{
              color:'white',
              backgroundColor:'indigo',
		    		}}
		    		size="medium" >

		    		Ver Comisiones
		    	</Button>
		    </Link>
          </CardActions>
                </Grid>
              </Grid>
        </Card>
  );
}